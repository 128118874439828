import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class NotizEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "notiz-edit";
        this.title = "notiz-edit.notiz-edit_caption";
        this.addModel({
            "id": "$m_Notiz",
            "webApiAction": "ERP/Stammdaten/Notiz",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addRadioGroup(this, {
            "idSelect": "notiztyp",
            "filters": [],
            "caption": "notiz-edit.r_notiztyp_caption",
            "binding": {
                "dataContext": "$m_Notiz",
                "bindTo": "IdNotiztyp",
                "bindToFQ": "models.data.$m_Notiz.IdNotiztyp"
            },
            "validationRules": [],
            "id": "r_notiztyp",
            "options": {
                "optionsName": "r_notiztypOptions",
                "optionsNameFQ": "r_notiztypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 200,
            "caption": "notiz-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Notiz",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_Notiz.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "!models.data.$m_Notiz.Text"
                    }]
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 200
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "notiz-edit.r_text_caption",
            "binding": {
                "dataContext": "$m_Notiz",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_Notiz.Text"
            },
            "validationRules": [],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "notiz-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Notiz",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Notiz.GueltigBis"
            },
            "validationRules": [],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "notiz-edit.r_iswichtig_caption",
            "binding": {
                "dataContext": "$m_Notiz",
                "bindTo": "IsWichtig",
                "bindToFQ": "models.data.$m_Notiz.IsWichtig"
            },
            "validationRules": [],
            "id": "r_isWichtig",
            "options": {
                "optionsName": "r_isWichtigOptions",
                "optionsNameFQ": "r_isWichtigOptions"
            }
        });
        super.onConstructionFinished();
    }
}