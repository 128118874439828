import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    AufgabeService
} from "../../../services/aufgabe-service";
import {
    AufgabeListUtils
} from "./aufgabe-list-utils";

@fwx.autoinject
export class AufgabeListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_AufgabeService: AufgabeService,
        private $f_AufgabeListUtils: AufgabeListUtils) {
        super(element, formBaseImport);
        this.id = "aufgabe-list";
        this.title = "aufgabe-list.aufgabe-list_caption";
        this.addModel({
            "id": "$m_Aufgabe",
            "webApiAction": "ERP/Stammdaten/Aufgabe",
            "webApiExpand": {
                'Sachbearbeiter': null,
                'Status': null,
                'Geschaeftspartner': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'IdAdresse'
                    ],
                    'expand': {
                        'Adresse': {
                            'columns': [
                                'Id',
                                'Firmenbez1',
                                'Ort'
                            ]
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_aufgabeGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AufgabeService", $f_AufgabeService, "functions.$f_AufgabeService");
        this.addFunction("$f_AufgabeListUtils", $f_AufgabeListUtils, "functions.$f_AufgabeListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufgIsPrioritaet",
                "caption": "aufgabe-list.r_aufgisprioritaet_caption",
                "bindTo": "IsPrioritaet",
                "width": "80px",
                "onCellPrepared": "functions.$f_AufgabeService.onIsPrioritaetCellPrepared"
            }, {
                "id": "r_aufgSachbearbeiter",
                "caption": "aufgabe-list.r_aufgsachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_aufgFaelligAm",
                "caption": "aufgabe-list.r_aufgfaelligam_caption",
                "bindTo": "FaelligAm",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_AufgabeListUtils.onFaelligAmCellPrepared"
            }, {
                "id": "r_aufgErinnernAm",
                "caption": "aufgabe-list.r_aufgerinnernam_caption",
                "bindTo": "ErinnernAm",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufgBetreff",
                "caption": "aufgabe-list.r_aufgbetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_aufgText",
                "caption": "aufgabe-list.r_aufgtext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_aufgGPNummer",
                "caption": "aufgabe-list.r_aufggpnummer_caption",
                "bindTo": "Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufgGPFirmenbez1",
                "caption": "aufgabe-list.r_aufggpfirmenbez1_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_aufgGPOrt",
                "caption": "aufgabe-list.r_aufggport_caption",
                "bindTo": "Geschaeftspartner.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_aufgStatus",
                "caption": "aufgabe-list.r_aufgstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_aufgabeGridToolbarOptions",
                "optionsNameFQ": "r_aufgabeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Aufgabe"
            },
            "dataModel": "$m_Aufgabe",
            "editUrl": "ERP/Stammdaten/Aufgabe",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_aufgabeGrid",
            "options": {
                "optionsName": "r_aufgabeGridOptions",
                "optionsNameFQ": "r_aufgabeGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}