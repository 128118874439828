import { 
  Aurelia,
  PLATFORM
} from "aurelia-framework"
import { 
  AuthorizationService 
} from "./framework/base/services/authorization-service";
import {
  ModuleLoader
} from "./framework-data/modules";
import environment from "./environment";
import "whatwg-fetch";
import "../resources/grid.less";
import "../node_modules/font-awesome/css/font-awesome.css";
import { AlertService } from "./framework/base/services/alert-service";
import { AppInsightsService } from './erp/services/app-insights-service';
import { LoginUrlService } from "./framework/base/services/login-url-service";

export async function configure(aurelia: Aurelia) {
  aurelia.use
    .basicConfiguration()
    .plugin(PLATFORM.moduleName("aurelia-dashboard-grid"))
    .feature(PLATFORM.moduleName("framework/base/index"))
    .feature(PLATFORM.moduleName("framework/dx/index"))
    .feature(PLATFORM.moduleName("framework/forms/index"))
    .feature(PLATFORM.moduleName("framework/default-ui/index"))
    .feature(PLATFORM.moduleName("framework/security/index"))
    .feature(PLATFORM.moduleName("erp/index"));

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  const moduleLoader = new ModuleLoader();

  const loginUrlService: LoginUrlService = aurelia.container.get(LoginUrlService);
  await loginUrlService.init();

  await aurelia.start();

  const appInsightService: AppInsightsService = aurelia.container.get(AppInsightsService);
  const alertService: AlertService = aurelia.container.get(AlertService);
  const authorization: AuthorizationService = aurelia.container.get(AuthorizationService);

  authorization.openApp();
}
