import { 
  FrameworkConfiguration,
  PLATFORM
} from "aurelia-framework";
import * as DxLoader from "./dx-loader";

import "../../../resources/dx.common.css";
import "../../../resources/dx.custom.css";
import "../../../node_modules/devextreme/dist/js/localization/dx.messages.de.js";

export function configure(config: FrameworkConfiguration) {
  const dxLoader = DxLoader.initialize();

  DevExpress.localization.locale(navigator.language || "de");
  
  DxLoader.getDefinition("dxChart").defaultOptions({
    options: {
      palette: "Office"
    }
  });
  DxLoader.getDefinition("dxPieChart").defaultOptions({
    options: {
      palette: "Office"
    }
  });
  DxLoader.getDefinition("dxPopover").defaultOptions({
    options: {
      animation: { show: { type: 'fade', from: 0, to: 1 }, hide: { type: 'fade', to: 0 } },
      position: "bottom"
    }
  });
  DxLoader.getDefinition("dxCalendar").defaultOptions({
    options: {
      firstDayOfWeek: 1
    }
  });
  DxLoader.getDefinition("dxPopup").defaultOptions({
    options: {
      animation: {
        show: {
          //type: "slide",
          // from: { opacity: 0, left: "+=15" },
          // to: { opacity: 1 },
          type: "fade",
          from: 0.1,
          to: 1,
          duration: 300,
          easing: "cubic-bezier(.62,.28,.23,.99)"
        }
      },
      position: { my: "center", at: "center", of: window }
    }
  });

  config
    .globalResources(PLATFORM.moduleName("./elements/dx-widget"));
}
