import { autoinject, bindable, observable } from "aurelia-framework";
import { WebEventService } from '../../../../framework/base/export';
import { OnProjektRequestStatistik, OnProjektRequestStatistikEvent } from '../../../../framework-data/events';

@autoinject
export class StatistikProjekt {
  constructor(
    private webEventService: WebEventService
  ) {}

  @bindable @observable idProjekt: number;
  
  isVisible: boolean = false;
  data: OnProjektRequestStatistik;

  bind() {
    this.loadData();
  }

  idProjektChanged() {
    this.loadData();
  }

  private async loadData() {
    if (!this.idProjekt) {
      this.isVisible = false;
      return;
    }

    const r: OnProjektRequestStatistik = await this.webEventService.execute(new OnProjektRequestStatistikEvent({
      IdProjekt: this.idProjekt
    }));

    this.isVisible = r.HasData;
    this.data = r;
  }
}