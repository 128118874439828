import { autoinject, computedFrom } from "aurelia-framework";
import { OverrideContext, Scope } from '../../../../node_modules/aurelia-framework';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { LocalizationService, RestService } from '../../../framework/base/export';
import { AsyncService } from '../../../framework/base/services/async-service';
import { IdxPopoverComponent } from '../../interfaces/dx-components';
import * as Interfaces from "../../interfaces/export";
import { HeaderCommandService, StartupService } from '../../services/export';

@autoinject
export class ArbeitszeitStempelnCommand {

  constructor(
    private _asyncService: AsyncService,
    private _localizationService: LocalizationService,
    private _headerCommandService: HeaderCommandService,
    private _restService: RestService,
    private _startupService: StartupService) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  benutzerStatus: any;
  saldo: any;
  zeitartList: any[];
  idZeitartKommen: number;
  idZeitartGehen: number;
  idZeitart: number;
  kommentar: string;

  isGewaehlteZeitartVormerk: boolean;

  popover: IdxPopoverComponent;
  popoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: "300",
    height: "auto",
    maxHeight: "75vh"
  };

  kommenButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("arbeitszeit-stempeln-command.kommen"),
    width: "100%",
    bindingOptions: {
      disabled: "idZeitart != null || (benutzerStatus != null && benutzerStatus.IdZeitart == idZeitartKommen)"
    },
    onClick: (e) => {
      this.postStempeln(this.idZeitartKommen, false, null);
    }
  }
  gehenButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("arbeitszeit-stempeln-command.gehen"),
    width: "100%",
    bindingOptions: {
      disabled: "idZeitart != null || benutzerStatus == null || benutzerStatus.IdZeitart == null"
    },
    onClick: (e) => {
      this.postStempeln(this.idZeitartGehen, false, null);
    }
  }

  zeitartBox: Interfaces.IdxSelectBoxComponent;
  zeitartBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    showClearButton: true,
    placeholder: "Andere Zeitart auswählen...",
    bindingOptions: {
      dataSource: "zeitartList",
      value: "idZeitart"
    }
  }

  kommentarTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "kommentar"
    }
  };

  jetztButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("arbeitszeit-stempeln-command.jetzt"),
    width: "100%",
    onClick: async (e) => {
      await this.postStempeln(this.idZeitart, false, this.kommentar);
      this.idZeitart = null;
      this.kommentar = null;
    }
  }
  vormerkenButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("arbeitszeit-stempeln-command.vormerken"),
    width: "100%",
    bindingOptions: {
      disabled: "disableVormerkButton"
    },
    onClick: async (e) => {
      await this.postStempeln(this.idZeitart, true, this.kommentar);
      this.idZeitart = null;
      this.kommentar = null;
    }
  }

  @computedFrom("idZeitart")
  get disableVormerkButton() {
    const zeitart = this.zeitartList
      .filter(c => c.Id == this.idZeitart)[0] || null;

    if (zeitart == null) {
      return true;
    }

    if (zeitart.IsVormerk) {
      return false;
    }

    return true;
  }

  @computedFrom("idZeitart")
  get canAddKommentar() {
    if (this.idZeitart == null) {
      this.kommentar = null;
      return false;
    }

    const zeitart = this.zeitartList
      .filter(c => c.Id == this.idZeitart)[0] || null;

    if (zeitart == null) {
      this.kommentar = null;
      return false;
    }

    if (!zeitart.CanAddKommentar) {
      this.kommentar = null;
      return false;
    }

    return true;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }
  attached() {
    this._headerCommandService.arbeitszeitStempeln = this;
  }
  detached() {
  }

  async showPopover(targetElement: Element) {
    await this._asyncService.convertToPromise(this.popover.instance.show(targetElement));

    this.loadBenutzerStatus();
    this.loadSaldo();
    this.loadZeitartList();
    this.loadZeitartKommen();
    this.loadZeitartGehen();
  }

  private async loadBenutzerStatus() {
    const idBenutzer = this._startupService.startupInfo.Benutzer.Id;

    this.benutzerStatus = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitBenutzerstatus/benutzerstatus/${idBenutzer}`),
      errorCallback: () => {
        this.benutzerStatus = null;
        return true;
      }
    });
  }

  private async loadSaldo() {
    const idBenutzer = this._startupService.startupInfo.Benutzer.Id;

    this.saldo = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitSaldo/${idBenutzer}`),
      errorCallback: () => {
        this.saldo = null;
        return true;
      }
    });
  }

  private async loadZeitartList() {
    this.zeitartList = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitBuchung/zeitartstemplen`)
    });
  }

  private async loadZeitartKommen() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitBuchung/zeitartkommen`)
    });

    this.idZeitartKommen = r.Id;
  }

  private async loadZeitartGehen() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitBuchung/zeitartgehen`)
    });
    this.idZeitartGehen = r.Id;
  }

  private async postStempeln(idZeitart: number, isVormerk: boolean, kommentar?: string) {
    const r = await this._restService.post({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitBuchung/stempeln`),
      data: {
        IdBenutzer: this._startupService.startupInfo.Benutzer.Id,
        IdZeitart: idZeitart,
        IsVormerk: isVormerk,
        Kommentar: kommentar
      }
    });

    this.loadBenutzerStatus();
  }
}
