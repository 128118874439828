import { autoinject, bindable, observable, PLATFORM, Disposable, BindingEngine, Scope } from 'aurelia-framework';
import { IToolbarOptions } from './toolbar-options';
import { ToolbarService } from '../../services/toolbar-service';
import { IToolbarModel } from './toolbar-model';
import { IItem } from './item';
import { BindingService } from '../../../base/export';
import { ScopeContainer } from '../../../base/classes/scope-container';

PLATFORM.moduleName("../ribbon-toolbar/ribbon-toolbar");

@autoinject
export class Toolbar {
  private _itemSubscriptionDisposable: Disposable[];
  private _scope: Scope;
  private _scopeContainer: ScopeContainer;
  private _hasModifiedOptionsScopeContainer: boolean = false;

  constructor(
    public toolbar: ToolbarService,
    private bindingEngine: BindingEngine,
    private binding: BindingService
  ) { }

  @bindable @observable options: IToolbarOptions;

  toolbarModel: IToolbarModel = {
    options: null,
    optionsChanged: () => { }
  }

  bind(bindingContext, overrideContext) {
    this._scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };
    this._scopeContainer = new ScopeContainer(this._scope);

    if (this.options && !this.options.scopeContainer) {
      this.options.scopeContainer = this._scopeContainer;
      this._hasModifiedOptionsScopeContainer = true;
    }

    this.refreshToolbarModel();
  }
  unbind() {
    this.disposeOldSubscriber();
    this._scopeContainer.disposeAll();
    this._scope = null;

    if (this._hasModifiedOptionsScopeContainer) {
      this.options.scopeContainer = null;
      this._hasModifiedOptionsScopeContainer = false;
    }
  }

  optionsChanged(newVal) {
    this.refreshToolbarModel();
  }

  private refreshToolbarModel() {
    if (this.options && this.options.items) {
      this.checkExpressions(this.options.items);
    }

    this.toolbarModel.options = this.options;
    this.toolbarModel.optionsChanged();
  }
  private checkExpressions(items: IItem[]) {
    this.disposeOldSubscriber();
    this._itemSubscriptionDisposable = [];

    items.forEach(item => {
      const scope = this.getItemScope(item);

      if (item.isEnabledExpression) {
        this.observe(this.binding.getBindingContext(scope, item.isEnabledExpression), item.isEnabledExpression, (newVal) => {
          item.isEnabled = newVal;
        });

        item.isEnabled = this.bindingEngine.parseExpression(item.isEnabledExpression).evaluate(scope);
      }
      if (item.isVisibleExpression) {
        this.observe(this.binding.getBindingContext(scope, item.isVisibleExpression), item.isVisibleExpression, (newVal) => {
          item.isVisible = newVal;
        });

        item.isVisible = this.bindingEngine.parseExpression(item.isVisibleExpression).evaluate(scope);
      }
      if (item.classNameExpression) {
        this.observe(this.binding.getBindingContext(scope, item.classNameExpression), item.classNameExpression, (newVal) => {
          item.className = newVal;
        });

        item.className = this.bindingEngine.parseExpression(item.classNameExpression).evaluate(scope);
      }

      if (item.isEnabled == void (0)) {
        item.isEnabled = true;
      }
      if (item.isVisible == void (0)) {
        item.isVisible = true;
      }
    });
  }
  private getItemScope(item: IItem): Scope {
    if (item.expressionScope) {
      return item.expressionScope;
    }
    if (this.options.scopeContainer) {
      return this.options.scopeContainer.scope;
    }

    return this._scope;
  }
  private observe(item: IItem, propertyName: string, action: { (newVal): void }) {
    const disposable = this.bindingEngine
      .expressionObserver(item, propertyName)
      .subscribe((newVal) => action(newVal));

    this._itemSubscriptionDisposable.push(disposable);
  }
  private disposeOldSubscriber() {
    if (!this._itemSubscriptionDisposable) {
      return;
    }

    this._itemSubscriptionDisposable.forEach(c => c.dispose());
    this._itemSubscriptionDisposable = [];
  }
}
