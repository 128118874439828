import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, OverrideContext, PLATFORM, Scope } from "aurelia-framework";
import { OnProjektDokumentRequest, OnProjektDokumentRequestEvent } from "../../../framework-data/events";
import { FileService, ScopeContainer, WebEventService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/interfaces/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { NotificationTyp } from "../../enumerations/notification-typ";
import * as Interfaces from "../../interfaces/export";
import { BriefService, DmsViewerService, DruckenService, StartupService } from "../../services/export";
import { AnzAusdrucke } from "../anz-ausdrucke/anz-ausdrucke";
import { DataSourceService } from "./../../../framework/base/services/data-source-service";
import { GlobalizationService } from "./../../../framework/base/services/globalization-service";
import { MailImportService } from "./../../services/mail-import-service";

@autoinject
export class ProjektDmsViewer {
  private _isDokumentVersion: boolean = false;
  private _selectAfterContentReady: boolean = false;

  constructor(
    private _briefService: BriefService,
    private _dataSourceService: DataSourceService,
    private _dmsViewerService: DmsViewerService,
    private _druckenService: DruckenService,
    private _eventAggregator: EventAggregator,
    private _fileService: FileService,
    private _globalizationService: GlobalizationService,
    private _mailImportService: MailImportService,
    private _startupService: StartupService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _webEventService: WebEventService) { }

  idProjekt: number;
  dokumentList: any[] = [];

  currentDokument: Interfaces.IDMSDokument = { DMSLink: null, MimeType: "application/pdf" };
  currentVersionDokument: Interfaces.IDMSDokument = { DMSLink: null, MimeType: "application/pdf" };

  hasSelectedDokument: boolean = false;
  anzAusdrucke: AnzAusdrucke;

  projektDmsViewerPopupCommands: ICommandData[] = [
    {
      id: "downloadDocument",
      title: "projekt-dms-viewer.download-dokument",
      icon: "download",
      isEnabledExpression: "hasSelectedDokument",
      execute: this.downloadSelectedDokumente.bind(this)
    }, {
      id: "sendDocument",
      title: "projekt-dms-viewer.send-dokument",
      icon: "share-alt",
      badgeText: null,
      isEnabled: false,
      isVisibleExpression: "!_isDokumentVersion",
      execute: this.sendSelectedDokumente.bind(this)
    }, {
      id: "anzAusdrucke",
      title: "projekt-dms-viewer.anzahl-ausdrucke",
      isVisible: this.isDruckJobAktiv(),
      template: {
        moduleId: PLATFORM.moduleName("erp/elements/anz-ausdrucke/anz-ausdrucke"),
        options: {
          options: {
            onInitialized: (e) => {
              this.anzAusdrucke = e.component;
              e.options.value = 1;
            }
          }
        }
      }
    }, {
      id: "sendDruckJob",
      title: "projekt-dms-viewer.send-druck-job",
      icon: "print",
      isEnabledExpression: "hasSelectedDokument",
      isVisible: this.isDruckJobAktiv(),
      execute: this.onSendDruckJob.bind(this)
    }
  ];

  projektDmsViewerPopup: Interfaces.IdxPopupComponent;
  projektDmsViewerPopupOptions: DevExpress.ui.dxPopupOptions = {
    onHidden: () => {
      this.dokumentGrid.instance.deselectAll();

      this.idProjekt = null;
    }
  };

  scope: Scope;
  scopeContainer: ScopeContainer;

  dokumentGridDataSource: DevExpress.data.DataSource;
  dokumentGrid: Interfaces.IdxDataGridComponent;
  dokumentGridOptions = this.getDmsGridOptions([
    { dataField: "AnlageDatum", caption: "Anlagedatum", width: "90px", format: this._globalizationService.getFormatterParser("d") },
    { dataField: "Dateiname" },
    { dataField: "Dokumentenart", caption: "Dokumentenart" },
    { dataField: "Gruppe", caption: "Verknüpfung", groupIndex: 0 }
  ]);

  dokumentenartSelectBox: Interfaces.IdxSelectBoxComponent;
  dokumentenartSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    onValueChanged: async (e) => {
      if (this.currentDokument == void 0) {
        return;
      }

      if (this.currentDokument.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.MailImportItem") {
        await this._mailImportService.saveDokumentartToMailImportItemAnhang(this.currentDokument.Id, this.currentDokument.IdDokumentenart);
      } else {
        await this._dmsViewerService.saveDokumentartToDokument(this.currentDokument.Id, this.currentDokument.IdDokumentenart);
      }

      this.loadData();
    },
    bindingOptions: {
      value: "currentDokument.IdDokumentenart",
      readOnly: "!currentDokument"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._eventAggregator.subscribe(NotificationTyp.startupInfoLoaded, async () => {
      this.projektDmsViewerPopupCommands.forEach((cmd) => {
        if (cmd.id == "anzAusdrucke" || cmd.id == "sendDruckJob") {
          cmd.isVisible = this.isDruckJobAktiv();
        }
      });
    });

    this.updatePopupOptions();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  show(idProjekt: number) {
    this._selectAfterContentReady = true;
    this.idProjekt = idProjekt;

    this.loadData();
    this.projektDmsViewerPopup.instance.show();
  }

  private loadDokumentartData() {
    this.dokumentenartSelectBoxOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        keyProperty: "Id",
        webApiAction: "ERP/Stammdaten/Dokumentenart",
        webApiWhere: ["DokumentenartZuEntitaeten", ["TypeName", { expression: "currentDokument.TypeName", isBound: true }]]
      }
    );
  }

  private async loadData() {
    const r: OnProjektDokumentRequest = await this._webEventService.execute(new OnProjektDokumentRequestEvent({
      IdProjekt: this.idProjekt
    }), true);

    this.dokumentList = r.DokumentList;
  }

  private onSelectionChanged(e) {
    const length = e.selectedRowKeys.length;
    const currentDokument = length === 1
      ? e.selectedRowsData[0]
      : null;

    this.hasSelectedDokument = length > 0;
    this.setCurrentDokument(currentDokument);
    this.updateSendDokumentCommand(length);
    this.loadDokumentartData();
  }
  private downloadSelectedDokumente() {
    const dmsLinkList = this.getSelectedDMSLinkList();
    dmsLinkList
      .forEach((dmsLInk, index) => {
        const url = this._fileService.getDownloadUrl(dmsLInk);
        window.open(url, "_blank");
      });
  }
  private async sendSelectedDokumente() {
    const dmsLinkList = this.getSelectedDMSLinkList();
    if (dmsLinkList == void 0) {
      return;
    }
    await this._briefService.createAndGoToBrief(this.idProjekt, dmsLinkList);

    const popup = this.projektDmsViewerPopup;
    popup.instance.hide();
  }

  private getSelectedDMSLinkList(): string[] {
    const dmsLinkList: any[] = this.dokumentGrid.instance.getSelectedRowsData();

    return dmsLinkList.length
      ? dmsLinkList.map((dokument) => {
        return dokument.DMSLink;
      })
      : null;
  }

  private updateSendDokumentCommand(count: number) {
    const cmdId = "sendDocument";
    const cmd = this.projektDmsViewerPopupCommands.find((cmd) =>
      cmd.id == cmdId);

    if (cmd == void 0) {
      return;
    }

    if (count == 0) {
      cmd.badgeText = null;
      cmd.isEnabled = false;
      return;
    }

    cmd.isEnabled = true;

    cmd.badgeText = {
      key: "base.param_1",
      parameters: [count.toString()]
    };
  }

  private setCurrentDokument(dokument: any) {
    if (this._isDokumentVersion) {
      this.currentVersionDokument = dokument;
    } else {
      this.currentDokument = dokument;
    }
  }
  private updatePopupOptions() {
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "projektDmsViewerPopupToolbar",
      caption: "projekt-dms-viewer.popup-titel",
      options: this.projektDmsViewerPopupOptions,
      commands: this.projektDmsViewerPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  private getDmsGridOptions(columns: DevExpress.ui.dxDataGridColumn[]): DevExpress.ui.dxDataGridOptions {
    return {
      height: "100%",
      columns: columns,
      selection: {
        mode: "multiple"
      },
      grouping: {
        autoExpandAll: true,
        allowCollapsing: false
      },
      searchPanel: {
        visible: true,
        width: 350,
        placeholder: "Suchen...",
      },
      keyExpr: "Id",
      onContentReady: () => {
        if (!this._selectAfterContentReady) {
          return;
        }
        this._selectAfterContentReady = false;

        if (this.dokumentList.length == 0) {
          return;
        }

        this.dokumentGrid.instance.selectRowsByIndexes([0]);
      },
      onCellPrepared: (e) => {
        if (e.rowType != "group") {
          return;
        }
        if (!e.column || e.column.dataField != "Gruppe") {
          return;
        }
        if (!e.text) {
          return;
        }

        e.cellElement.innerHTML = null;

        const items = e.data.items || e.data.collapsedItems;
        if (items.length == 0) {
          return;
        }

        const item = items[0];

        const el = document.createElement("div");
        el.innerText = item.Gruppe;
        e.cellElement.appendChild(el);
      },
      onSelectionChanged: this.onSelectionChanged.bind(this),
      bindingOptions: {
        dataSource: "dokumentList"
      }
    };
  }

  private isDruckJobAktiv() {
    return this._startupService.startupInfo.Mandant.IsDruckJobAktiv;
  }

  private async onSendDruckJob() {
    const dmsLinkList = this.getSelectedDMSLinkList();

    for (const dmsLink of dmsLinkList) {
      await this._druckenService.executeDruckJob(dmsLink, this.anzAusdrucke.getValue());
    }
  }
}
