import { autoinject } from "aurelia-framework";
import { RestService } from "./rest-service";
import { AuthorizationService } from "./authorization-service";

@autoinject
export class LoginUrlService {

  constructor(
    private _authorizationService: AuthorizationService,
    private _restService: RestService) { }

  async init() {
    const suffix = this.getSuffixOfLocalStorage();
    let baseUrl = location.href;

    if (suffix != null) {
      baseUrl = await this.getUrlOfSuffix(suffix);

      this._restService.setBaseUrl(baseUrl);
    }else{
      this._restService.setBaseUrl("https://clue-web.azurewebsites.net");
    }

    this._authorizationService.onBeforeLogin = async (data) => {
      const usernameSplit = data.Username.split("@");

      if (usernameSplit.length > 1) {
        baseUrl = await this.getUrlOfSuffix(usernameSplit[1]);
      }else{
        baseUrl = await this.getUrlOfSuffix("supermandant");
      }

      this._restService.setBaseUrl(baseUrl);
    }

    this._authorizationService.onAfterLoginCompleted = async (data) => {
      const usernameSplit = data.Username.split("@");
      if (usernameSplit.length > 1) {
        localStorage.setItem("clue_suffix", usernameSplit[1]);
      }
    }
  }

  private getSuffixOfLocalStorage() {
    return localStorage.getItem("clue_suffix");
  }

  private async getUrlOfSuffix(suffix) {
    const url = location.origin;
    this._restService.setBaseUrl(url);

    const baseUrl = await this._restService.get({ url: location.origin + "/api-frontend/base-url/" + suffix });
    return baseUrl.baseUrl;
  }
}
