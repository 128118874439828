export enum TicketKategorie{
  Dashboard = 0,
  Stammdaten = 1,
  Einkauf = 2,
  Verkauf = 3,
  Produktion = 4,
  Lager = 5,
  Statistik = 6,
  Konfiguration = 7,
  Andere = 8
}
