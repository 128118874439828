import {
  autoinject,
  computedFrom
} from "aurelia-framework";
import { RouterService } from "../forms/export";

import * as background01 from "./images/background01.jpg";
import * as background02 from "./images/background02.jpg";
import * as background03 from "./images/background03.jpg";
import * as background04 from "./images/background04.jpg";
import * as background05 from "./images/background05.jpg";
import * as background06 from "./images/background06.jpg";
import * as background07 from "./images/background07.jpg";
import * as background08 from "./images/background08.jpg";
import * as background09 from "./images/background09.jpg";
import * as background10 from "./images/background10.jpg";
import * as background11 from "./images/background11.jpg";
import * as background12 from "./images/background12.jpg";

@autoinject
export class Login {
  constructor(
    private router: RouterService
  ) {
    const getUrl = (c) => c.default ? c.default : c;

    const imageList = [
      getUrl(background01), 
      getUrl(background02), 
      getUrl(background03), 
      getUrl(background04), 
      getUrl(background05), 
      getUrl(background06), 
      getUrl(background07), 
      getUrl(background08), 
      getUrl(background09), 
      getUrl(background10), 
      getUrl(background11), 
      getUrl(background12)];

    const imageIndex = Math.round(Math.random() * 11);

    this.loginImageStyle = {
      "background-image": `url('${imageList[imageIndex]}')`
    };
  }

  loginImageStyle: any;

  @computedFrom("router.currentViewItem.controller.currentViewModel.title")
  get title(): string {
    if (!this.router.currentViewItem || !this.router.currentViewItem.controller) {
      return null;
    }

    const currentViewModel = this.router.currentViewItem.controller["currentViewModel"];
    if (!currentViewModel) {
      return;
    }

    return currentViewModel.title;
  }

  attached() {
    this.router.registerRoutes([{
      moduleId: "framework/login/views/login/login-form",
      caption: "base.login",
      route: "login"
    }, {
      moduleId: "framework/login/views/password-reset/password-reset-form",
      caption: "base.reset_password",
      route: "reset-password"
    }, {
      moduleId: "framework/login/views/activate-account/activate-account-form",
      caption: "base.account_activate",
      route: "activate-account"
    }
    ], "login");
  }
}
