import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class LohnexportEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "lohnexport-edit";
        this.title = "lohnexport-edit.lohnexport-edit_caption";
        this.addModel({
            "id": "$m_ArbeitszeitLohnexport",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitLohnexport",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": false,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArbeitszeitLohnexportInfoNichtKalkuliertRel",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitLohnexportInfo",
            "webApiWhere": ["CanKalkulieren", "false"],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArbeitszeitLohnexportInfoKalkuliertRel",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitLohnexportInfo",
            "webApiWhere": ["CanKalkulieren", "true"],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lohnexport-edit.r_arblohnexportdatumvon_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport",
                "bindTo": "DatumVon",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexport.DatumVon"
            },
            "validationRules": [],
            "id": "r_arbLohnexportDatumVon",
            "options": {
                "optionsName": "r_arbLohnexportDatumVonOptions",
                "optionsNameFQ": "r_arbLohnexportDatumVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lohnexport-edit.r_arblohnexportdatumbis_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport",
                "bindTo": "DatumBis",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexport.DatumBis"
            },
            "validationRules": [],
            "id": "r_arbLohnexportDatumBis",
            "options": {
                "optionsName": "r_arbLohnexportDatumBisOptions",
                "optionsNameFQ": "r_arbLohnexportDatumBisOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "lohnexport-edit.r_arblohnexportbezeichnung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexport.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_arbLohnexportBezeichnung",
            "options": {
                "optionsName": "r_arbLohnexportBezeichnungOptions",
                "optionsNameFQ": "r_arbLohnexportBezeichnungOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 4,
            "showClearButton": false,
            "caption": "lohnexport-edit.r_arblohnexportdmslink_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexport.DMSLink"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_arbLohnexportDmslink",
            "options": {
                "optionsName": "r_arbLohnexportDmslinkOptions",
                "optionsNameFQ": "r_arbLohnexportDmslinkOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_angpPosNr",
                "caption": "lohnexport-edit.r_angpposnr_caption",
                "bindTo": "Bezeichnung"
            }, {
                "id": "r_angpArtikelNr",
                "caption": "lohnexport-edit.r_angpartikelnr_caption",
                "bindTo": "PersonalNummer"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_arbeitszeitLohnexportInfoNichtKalkuliertGridToolbarOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportInfoNichtKalkuliertGridToolbarOptions"
            },
            "caption": "lohnexport-edit.r_arbeitszeitlohnexportinfonichtkalkuliertgrid_caption",
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArbeitszeitLohnexportInfoNichtKalkuliertRel",
                "bindTo": "IdArbeitszeitLohnexport",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexportInfoNichtKalkuliertRel.IdArbeitszeitLohnexport"
            },
            "dataModel": "$m_ArbeitszeitLohnexport",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_arbeitszeitLohnexportInfoNichtKalkuliertGrid",
            "options": {
                "optionsName": "r_arbeitszeitLohnexportInfoNichtKalkuliertGridOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportInfoNichtKalkuliertGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_angpPosNr",
                "caption": "lohnexport-edit.r_angpposnr_caption",
                "bindTo": "Bezeichnung"
            }, {
                "id": "r_angpArtikelNr",
                "caption": "lohnexport-edit.r_angpartikelnr_caption",
                "bindTo": "PersonalNummer"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_arbeitszeitLohnexportInfoKalkuliertGridToolbarOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportInfoKalkuliertGridToolbarOptions"
            },
            "caption": "lohnexport-edit.r_arbeitszeitlohnexportinfokalkuliertgrid_caption",
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArbeitszeitLohnexportInfoKalkuliertRel",
                "bindTo": "IdArbeitszeitLohnexport",
                "bindToFQ": "models.data.$m_ArbeitszeitLohnexportInfoKalkuliertRel.IdArbeitszeitLohnexport"
            },
            "dataModel": "$m_ArbeitszeitLohnexport",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_arbeitszeitLohnexportInfoKalkuliertGrid",
            "options": {
                "optionsName": "r_arbeitszeitLohnexportInfoKalkuliertGridOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportInfoKalkuliertGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}