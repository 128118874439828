import { autoinject } from "../../framework/forms/form-export";
import { RestService } from "./../../framework/base/services/rest-service";

@autoinject
export class MailImportService {

  constructor(
    private _restService: RestService) { }

  saveDokumentartToMailImportItemAnhang(idMailImportItemAnhang, idDokumentenart): Promise<any> {
    return this._restService.post({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/MailImportItemAnhang"),
      data: {
        Id: idMailImportItemAnhang,
        IdDokumentenart: idDokumentenart
      }
    });
  }
}
