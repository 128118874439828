import * as fwx from "../../../../framework/forms/form-export";
import {
    BenutzerEinstellungenEditUtils
} from "./benutzer-einstellungen-edit-utils";

@fwx.autoinject
export class BenutzerEinstellungenEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BenutzerEinstellungenEditUtils: BenutzerEinstellungenEditUtils) {
        super(element, formBaseImport);
        this.id = "benutzer-einstellungen-edit";
        this.title = "benutzer-einstellungen-edit.benutzer-einstellungen-edit_caption";
        this.addModel({
            "id": "$m_Benutzer",
            "webApiAction": "ERP/Stammdaten/Benutzer",
            "webApiExpand": {
                'BenutzerAufgabeBenachrichtigung': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BenutzerEinstellungenEditUtils.office365AuthCommand",
                "bindToFQ": "functions.$f_BenutzerEinstellungenEditUtils.office365AuthCommand"
            }
        });
        this.addFunction("$f_BenutzerEinstellungenEditUtils", $f_BenutzerEinstellungenEditUtils, "functions.$f_BenutzerEinstellungenEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "benutzer-einstellungen-edit.r_benutzername_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Benutzername",
                "bindToFQ": "models.data.$m_Benutzer.Benutzername"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_benutzername",
            "options": {
                "optionsName": "r_benutzernameOptions",
                "optionsNameFQ": "r_benutzernameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "benutzer-einstellungen-edit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Vorname",
                "bindToFQ": "models.data.$m_Benutzer.Vorname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "benutzer-einstellungen-edit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Nachname",
                "bindToFQ": "models.data.$m_Benutzer.Nachname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "benutzer-einstellungen-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Email",
                "bindToFQ": "models.data.$m_Benutzer.Email"
            },
            "validationRules": [{
                "item": {
                    "type": "email",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "benutzer-einstellungen-edit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Telefon",
                "bindToFQ": "models.data.$m_Benutzer.Telefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "benutzer-einstellungen-edit.r_fax_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Fax",
                "bindToFQ": "models.data.$m_Benutzer.Fax"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_fax",
            "options": {
                "optionsName": "r_faxOptions",
                "optionsNameFQ": "r_faxOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_bildTab",
                "caption": "benutzer-einstellungen-edit.r_bildtab_caption"
            }, {
                "id": "r_2faTab",
                "caption": "benutzer-einstellungen-edit.r_2fatab_caption"
            }, {
                "id": "r_benachrTab",
                "caption": "benutzer-einstellungen-edit.r_benachrtab_caption"
            }],
            "id": "r_tabs",
            "options": {
                "optionsName": "r_tabsOptions",
                "optionsNameFQ": "r_tabsOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "maxFileSizeMB": 1,
            "showClearButton": false,
            "caption": "benutzer-einstellungen-edit.r_bild_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "Bild",
                "bindToFQ": "models.data.$m_Benutzer.Bild"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_bild",
            "options": {
                "optionsName": "r_bildOptions",
                "optionsNameFQ": "r_bildOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "benutzer-einstellungen-edit.r_iszweifaaktiv_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "IsZweiFAAktiv",
                "bindToFQ": "models.data.$m_Benutzer.IsZweiFAAktiv"
            },
            "validationRules": [],
            "id": "r_isZweiFAAktiv",
            "options": {
                "optionsName": "r_isZweiFAAktivOptions",
                "optionsNameFQ": "r_isZweiFAAktivOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_benutzerinfohinz_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.BenutzerinfoHinz",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.BenutzerinfoHinz",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_benutzerinfoHinz",
            "options": {
                "optionsName": "r_benutzerinfoHinzOptions",
                "optionsNameFQ": "r_benutzerinfoHinzOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_benutzerinfoentf_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.BenutzerinfoEntf",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.BenutzerinfoEntf",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_benutzerinfoEntf",
            "options": {
                "optionsName": "r_benutzerinfoEntfOptions",
                "optionsNameFQ": "r_benutzerinfoEntfOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_erinnerungsdatumerreicht_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.ErinnerungsdatumErreicht",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.ErinnerungsdatumErreicht",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_erinnerungsdatumErreicht",
            "options": {
                "optionsName": "r_erinnerungsdatumErreichtOptions",
                "optionsNameFQ": "r_erinnerungsdatumErreichtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_kommentarneu_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.KommentarNeu",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.KommentarNeu",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_kommentarNeu",
            "options": {
                "optionsName": "r_kommentarNeuOptions",
                "optionsNameFQ": "r_kommentarNeuOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_aufgabeneu_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.AufgabeNeu",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.AufgabeNeu",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_aufgabeNeu",
            "options": {
                "optionsName": "r_aufgabeNeuOptions",
                "optionsNameFQ": "r_aufgabeNeuOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzerAufgabeBenachrichtigungTyp",
            "filters": [],
            "caption": "benutzer-einstellungen-edit.r_aufgabeaend_caption",
            "binding": {
                "dataContext": "$m_Benutzer",
                "bindTo": "BenutzerAufgabeBenachrichtigung.AufgabeAend",
                "bindToFQ": "models.data.$m_Benutzer.BenutzerAufgabeBenachrichtigung.AufgabeAend",
                "propertyPrefix": "BenutzerAufgabeBenachrichtigung"
            },
            "validationRules": [],
            "id": "r_aufgabeAend",
            "options": {
                "optionsName": "r_aufgabeAendOptions",
                "optionsNameFQ": "r_aufgabeAendOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabsSelected = 0;
}