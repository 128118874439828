import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../../framework/forms/export";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class AbschlagfakturaEditUtils {

  constructor(
    private _startupService: StartupService) { }

  form: FormBase;

  onWertProzentValueChanged(e): void {
    if (!this.form.models.data.$m_Auftrag) {
      return;
    }
    if (e.value == null) {
      return;
    }
    const wert = this.form.models.data.$m_Auftrag.WertNetto;
    const wertNetto = parseFloat(((wert / 100) * e.value).toFixed(2));
    this.form.models.data.$m_Abschlagsfaktura.WertNetto = wertNetto;
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register((r) => {
      if (r == void 0 || r.model.id != "$m_Abschlagsfaktura") {
        return;
      }

      const data = this.form.models.data.$m_Abschlagsfaktura;
      if (!data) {
        return;
      }
      data.IsVorausinkasso = this._startupService.startupInfo.Mandant.IsVorauskasseAbschlagfakturaVorbelegt;

      return Promise.resolve();
    });
  }
}
