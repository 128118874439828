import { autoinject, valueConverter } from "aurelia-framework";
import { EntitaetInfoService } from '../services/export';

@autoinject
@valueConverter("entitaetFullNameZuName")
export class EntitaetFullNameZuNameValueConverter{
  constructor(
    private _entitaetInfoService: EntitaetInfoService
  ) { }

  
  toView(value: string) {
    if (!value) {
      return "";
    }

    return this._entitaetInfoService.getBezeichnung(value) || value;
  }
}
