import { HtmlEditor } from './../../../framework/forms/elements/html-editor/html-editor';
import { SimpleWidgetCreatorService } from './../../../framework/forms/widget-services/simple-widget-creator-service';
import { LocalizationService } from './../../../framework/base/services/localization-service';
import { IdxPopupComponent, IdxDataGridComponent } from './../../interfaces/dx-components';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { Scope, createOverrideContext } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { DataSourceService } from '../../../framework/base/export';
import { DynFelderService } from '../../services/export';

@autoinject
export class DynFeldWertVorlage {
  private _centerPopup: boolean = false;

  constructor(
    private dataSourceService: DataSourceService,
    private localizationService: LocalizationService,
    private simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private dynFelderService: DynFelderService
  ) {
    this.dynFelderService.dynFeldWertVorlage = this;
  }

  scope: Scope;
  scopeContainer: ScopeContainer;

  dynFeldId: number;
  htmlEditor: DevExpress.ui.dxHtmlEditor;
  indexCursorHtmlEditor: number;

  wertVorlagePopup: IdxPopupComponent;
  wertVorlagePopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "500px",
    height: "auto"
  };

  wertVorlageGridDataSource: DevExpress.data.DataSource;
  wertVorlageDataGrid: IdxDataGridComponent;
  wertVorlageDataGridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    allowColumnResizing: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columns: [{
      caption: this.localizationService.translateOnce("dyn_feld_wert_vorlage.bezeichnung"),
      dataField: "Bezeichnung",
      minWidth: 100
    }],
    onContentReady: () => {
      if (!this._centerPopup) {
        return;
      }

      this._centerPopup = false;
      this.wertVorlagePopup.instance.repaint();
    },
    onRowClick: (e) => {
      const editor = this.htmlEditor.getQuillInstance();
      editor.clipboard.dangerouslyPasteHTML(this.indexCursorHtmlEditor, e.data.WertText);

      this.htmlEditor.focus();
      this.htmlEditor = null;

      this.wertVorlagePopup.instance.hide();
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "wertVorlagePopupToolbar",
      caption: "dyn_feld_wert_vorlage.popup_titel",
      scopeContainer: this.scopeContainer,
      options: this.wertVorlagePopupOptions
    });
  }

  unbind() {
    this.scopeContainer.disposeAll();
  }

  async showPopup(dynFeldId: number, htmlEditor: any) {
    this.dynFeldId = dynFeldId;
    this.htmlEditor = htmlEditor;

    const index = this.htmlEditor.getSelection();

    if (index == null) {
      this.indexCursorHtmlEditor = 0;
    }
    else {
      this.indexCursorHtmlEditor = index.index;
    }

    this.setGridDataSources();
    this.wertVorlagePopup.instance.show();
  }

  private setGridDataSources() {
    this._centerPopup = true;

    this.wertVorlageGridDataSource = this.dataSourceService.createDataSource(
      this.scopeContainer,
      {
        keyProperty: "Id",
        webApiAction: "ERP/DynamischesFeld/DynamischesFeldWertVorlage",
        webApiExpand: {
          DynamischesFeld: {
            columns: ["Id"]
          }
        },
        webApiWhere: ["DynamischesFeld.Id", this.dynFeldId]
      }
    );

    if (this.wertVorlageDataGrid && this.wertVorlageDataGrid.instance) {
      this.wertVorlageDataGrid.setOption({
        dataSource: this.wertVorlageGridDataSource
      });
    } else {
      this.wertVorlageDataGridOptions.dataSource = this.wertVorlageGridDataSource;
    }
  }
}
