import { autoinject, transient } from "aurelia-framework";
import { FormBase } from '../../framework/forms/form-export';
import { ICommandData } from '../../framework/forms/export';
import { DynFelderService } from '../services/dyn-felder-service';
import { EntitaetInfoService } from '../services/entitaet-info-service';

@autoinject
@transient()
export class UpdateManuelleTexteUtils {
  constructor(
    private dynFelder: DynFelderService,
    private entitaetInfo: EntitaetInfoService
  ) { }

  form: FormBase;

  updateKopftextCommand: ICommandData = {
    id: "updateKopftext",
    idCategory: "text",
    title: "update-manuelle-texte.update-kopftext",
    icon: "",
    sortIndex: 10,
    isEnabled: false,
    isVisible: false,
    execute: async () => {
      this.updateTexte(true, false);
    }
  }
  updateFusstextCommand: ICommandData = {
    id: "updateFusstext",
    idCategory: "text",
    title: "update-manuelle-texte.update-fusstext",
    icon: "",
    sortIndex: 20,
    isEnabled: false,
    isVisible: false,
    execute: async () => {
      this.updateTexte(false, true);
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.callOnBind(() => {
      this.checkUpdateText();
    })
    form.models.onLoaded.register(r => {
      if (r.model == this.form.models.modelWithKeyId) {
        this.checkEnabled(r.data);
      }

      return Promise.resolve();
    })
  }

  private async checkUpdateText(): Promise<any> {
    const mainModel = this.form.models.modelWithKeyId;
    if (!mainModel) {
      return;
    }
    if (!mainModel.webApiAction) {
      return;
    }

    var info = this.entitaetInfo.getEntitaetInfoByWebApiAction(mainModel.webApiAction);
    if (!info) {
      return;
    }
    if (!info.FullName) {
      return;
    }

    const result = await this.dynFelder.hasManuellerText(info.FullName);

    this.updateKopftextCommand.isVisible = result.HasKopftext;
    this.updateFusstextCommand.isVisible = result.HasFusstext;
  }
  private checkEnabled(data) {
    const enabled = data && data.CanSave;

    this.updateKopftextCommand.isEnabled = enabled;
    this.updateFusstextCommand.isEnabled = enabled;
  }
  private async updateTexte(kopf: boolean, fuss: boolean) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const mainModel = this.form.models.modelWithKeyId;
    const id = this.form.models.data[mainModel.id].Id;

    if (!id) {
      return;
    }

    await this.dynFelder.updateManuelleTexte(id, kopf, fuss);
    return this.form.models.reloadAll();
  }
}
