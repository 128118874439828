import { autoinject, computedFrom, createOverrideContext, Scope, TaskQueue } from "aurelia-framework";
import { OnStatistikVerkaufDetailRequest, OnStatistikVerkaufDetailRequestEvent, UmsatzWhereTyp } from '../../../framework-data/events';
import { GlobalizationService, LocalizationService, LocationService, ScopeContainer, WebEventService } from '../../../framework/base/export';
import { PopupInfoService, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { ExcelExportService } from "../../../framework/forms/services/excel-export-service";
import { IdxDataGridComponent, IdxPopupComponent } from '../../interfaces/dx-components';
import { StartupService } from '../../services/export';
import { StatistikService } from '../../services/statistik-service';
import { ICommandData } from './../../../framework/forms/interfaces/command-data';

@autoinject
export class StatistikVerkaufDetail {
  constructor(
    private _statistikVerkaufService: StatistikService,
    private _webEventService: WebEventService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService,
    private _taskQueue: TaskQueue,
    private _locationService: LocationService,
    private _popupInfoService: PopupInfoService,
    private _startupService: StartupService,
    private _localizationService: LocalizationService,
    private _excelExportService: ExcelExportService
  ) {
    this._statistikVerkaufService.verkaufElement = this;
  }

  viewTyp: StatistikVerkaufDetailViewTyp;
  where: IUmsatzWhereItem;
  doGruppieren = true;
  data: any[];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate"
  };

  doGruppierenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Daten auf Auftrag/Lieferschein gruppieren",
    bindingOptions: {
      value: "doGruppieren"
    },
    onValueChangedByUser: () => {
      this._taskQueue.queueMicroTask(() => {
        this.loadDataAndSetDataSource();
      });
    }
  }

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    allowColumnResizing: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columnResizingMode: "widget",
    onCellClick: (e) => {
      if (!e.data) {
        return;
      }

      if (!e.column || !e.column.dataField) {
        return;
      }

      const canShow = (field: string) => {
        return e.column.dataField.indexOf(field) >= 0
          && !!e.data["Id".concat(field)];
      };

      if (canShow("Faktura")) {
        this.open("Faktura", e.data);
      } else if (canShow("Lieferschein")) {
        this.open("Lieferschein", e.data);
      } else if (canShow("Auftrag")) {
        this.open("Auftrag", e.data);
      } else if (canShow("KassaBeleg")) {
        this.open("KassaBeleg", e.data);
      }
    }
  };

  doExportGridCommand: ICommandData[] = [{
    id: "doExportGrid",
    title: "statistik_verkauf_detail.export-grid",
    icon: "file-excel-o",
    sortIndex: 101,
    execute: () => {
      this._excelExportService.exportDataGrid({
        component: this.grid.instance,
        selectedRowsOnly: false
      });
    }
  }];

  scope: Scope;
  scopeContainer: ScopeContainer;

  @computedFrom("viewTyp")
  get showGruppieren() {
    return this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik;
  }

  @computedFrom("viewTyp")
  get titel() {
    switch (this.viewTyp) {
      case StatistikVerkaufDetailViewTyp.Auftrag: {
        return this._localizationService.translateOnce("statistik_verkauf_detail.auftragseingang");
      }
      case StatistikVerkaufDetailViewTyp.Forecast: {
        return this._localizationService.translateOnce("statistik_verkauf_detail.umsatz-mit-forecast");
      }
      default: {
        return this._localizationService.translateOnce("statistik_verkauf_detail.statistik");
      }
    }
  }

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: "statistik_verkauf_detail.detail",
      options: this.popupOptions,
      commands: this.doExportGridCommand,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  async show(typ: StatistikVerkaufDetailViewTyp, where: IUmsatzWhereItem) {
    this.viewTyp = typ;
    this.doGruppieren = true;
    this.where = where;

    await this.loadDataAndSetDataSource();

    this.popup.instance.show();
  }

  private async loadDataAndSetDataSource() {
    const r: OnStatistikVerkaufDetailRequest = await this._webEventService.execute(new OnStatistikVerkaufDetailRequestEvent({
      ViewTyp: this.viewTyp,
      DoGruppieren: this.doGruppieren,
      Where: this.where
    }), true);

    this.data = <any>r.Data;

    const options = {
      dataSource: this.data,
      columns: this.getColumns(),
      summary: this.getSummaryStatistik(),
      paging: {
        pageIndex: 0
      }
    };

    if (this.grid && this.grid.instance) {
      this.grid.setOption(options);
    } else {
      Object.assign(this.gridOptions, options);
    }
  }

  private getColumns() {
    const result: DevExpress.ui.dxDataGridColumn[] = []

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Forecast) {
      result.push({
        dataField: "Q",
        caption: "Quelle",
        width: "90px"
      });
    }

    result.push({
      dataField: "KundeNr",
      caption: "Kunden-Nr.",
      width: "80px"
    }, {
      dataField: "KundeFirmenbez1",
      caption: "Kunde Firmenbez. 1",
      minWidth: 100
    }, {
      dataField: "ArtikelNr",
      caption: "Artikel-Nr.",
      width: "80px"
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    });

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Auftrag || this.viewTyp == StatistikVerkaufDetailViewTyp.Forecast) {
      result.push({
        dataField: "MengeLagEH",
        caption: "Mge. LE",
        width: "80px",
        format: this._globalizationService.getFormatterParser("n2")
      });
    }
    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik || this.viewTyp == StatistikVerkaufDetailViewTyp.Forecast) {
      result.push({
        dataField: "MengeLieferscheinLagEH",
        caption: "Mge. LS LE",
        width: "80px",
        format: this._globalizationService.getFormatterParser("n2")
      }, {
        dataField: "MengeFakturaLagEH",
        caption: "Mge. FA LE",
        width: "80px",
        format: this._globalizationService.getFormatterParser("n2")
      });
    }

    result.push({
      dataField: "Einheit",
      caption: "Einheit",
      width: "120px"
    }, {
      dataField: "WertNettoBereinigt",
      caption: "Nettoumsatz bereinigt",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    });

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik) {
      result.push({
        dataField: "Einstandswert",
        caption: "Einstandswert",
        width: "80px",
        format: this._globalizationService.getFormatterParser("n2")
      }, {
        dataField: "DB",
        caption: "DB",
        width: "80px",
        format: this._globalizationService.getFormatterParser("n2")
      });
    }

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik && this.doGruppieren) {
      result.push({
        dataField: "AnzahlGruppiert",
        caption: "Anz. gruppiert",
        format: this._globalizationService.getFormatterParser("n0"),
        width: "60px"
      });
    }

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik || this.viewTyp == StatistikVerkaufDetailViewTyp.Forecast) {
      result.push({
        dataField: "FakturaNr",
        caption: "Faktura-Nr.",
        width: "80px"
      }, {
        dataField: "FakturaDatum",
        caption: "Faktura-Datum",
        width: "90px",
        format: this._globalizationService.getFormatterParser("d")
      });

      if (this._startupService.startupInfo.Lizenz.HasKassa) {
        result.push({
          dataField: "KassaBelegNr",
          caption: "Kassa-Beleg-Nr.",
          width: "80px"
        }, {
          dataField: "KassaBelegDatum",
          caption: "Kassa-Beleg-Datum",
          width: "90px",
          format: this._globalizationService.getFormatterParser("d")
        });
      }

      result.push({
        dataField: "LieferscheinNr",
        caption: "Lieferschein-Nr.",
        width: "80px"
      }, {
        dataField: "LieferscheinDatum",
        caption: "Lieferschein-Datum",
        width: "90px",
        format: this._globalizationService.getFormatterParser("d")
      });
    }

    result.push({
      dataField: "AuftragNr",
      caption: "Auftrag-Nr.",
      width: "80px"
    }, {
      dataField: "AuftragDatum",
      caption: "Auftrag-Datum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Auftragsart",
      caption: "Auftragsart",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Filiale",
      caption: "Filiale",
      minWidth: 100
    }, {
      dataField: "Projekt",
      caption: "Projekt",
      minWidth: 100
    });

    return result;
  }
  private getSummaryStatistik() {
    const result = [{
      column: "WertNettoBereinigt",
      summaryType: "sum",
      displayFormat: "{0}",
      valueFormat: this._globalizationService.getFormatterParser("n2")
    }];

    if (this.viewTyp == StatistikVerkaufDetailViewTyp.Statistik) {
      result.push({
        column: "Einstandswert",
        summaryType: "sum",
        displayFormat: "{0}",
        valueFormat: this._globalizationService.getFormatterParser("n2")
      }, {
        column: "DB",
        summaryType: "sum",
        displayFormat: "{0}",
        valueFormat: this._globalizationService.getFormatterParser("n2")
      });
    }

    return {
      totalItems: result
    };
  }

  private open(property: string, data: any) {
    const keyProperty = "Id".concat(property);

    const scrollList = this.data
      .map(c => c[keyProperty])
      .filter((c, i, a) => c && a.indexOf(c) == i);

    const current = scrollList.indexOf(data[keyProperty]);
    this._popupInfoService.closeAllPopups();

    const modul = property == "KassaBeleg"
      ? "Kassa"
      : "Verkauf";

    this._locationService.goTo({
      url: `ERP/${modul}/${property}/${data[keyProperty]}`,
      viewScrollInfo: {
        index: current,
        maxCount: scrollList.length,
        getNextKey: (index) => scrollList[index]
      }
    });
  }
}

export interface IUmsatzWhereItem {
  Typ: UmsatzWhereTyp,
  Data: any;
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdProjekt?: number;
  IdFirma?: number;
}
export enum StatistikVerkaufDetailViewTyp {
  Statistik = 0,
  Auftrag = 1,
  Forecast = 2
}
