import {
  autoinject
} from "aurelia-framework";
import {
  AuthorizationService
} from "./authorization-service";
import {
  RestService
} from "./rest-service";

@autoinject
export class FileService {
  constructor(
    private _authorizationService: AuthorizationService,
    private _restService: RestService
  ) {}

  getDownloadUrl(key: string) {
    if (!key) {
      return null;
    }
    
    let authKey = this._authorizationService.getAuthorizationKey();
    if (authKey) {
      authKey = `&auth-token=${encodeURIComponent(authKey)}`;
    }

    return this._restService.getApiUrl(`base/File/Download/${key}${authKey}`);
  }
  getInlineUrl(key: string) {
    if (!key) {
      return null;
    }
    
    let authKey = this._authorizationService.getAuthorizationKey();
    if (authKey) {
      authKey = `&auth-token=${encodeURIComponent(authKey)}`;
    }

    return this._restService.getApiUrl(`base/File/Inline/${key}${authKey}`);
  }
  download(key: string) {
    const downloadUrl = this.getDownloadUrl(key);
    window.open(downloadUrl, "_blank");
  }
  inline(key: string) {
    const downloadUrl = this.getInlineUrl(key);
    window.open(downloadUrl, "_blank");
  }
  async upload(file: File, increaseLoadingCount: boolean = true): Promise<string> {
    const formData = new FormData();
    formData.append("file", file);

    const r = await this._restService.post({
      url: this._restService.getApiUrl("base/File/Upload"),
      data: formData, 
      increaseLoadingCount: increaseLoadingCount
    });

    if (!r || !r.length) {
      return null;
    }

    return r[0];
  }
}
