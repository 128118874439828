import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitKostenstelleZuteilungEdit {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IArbeitszeitKostenstelleZuteilungOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  arbeitszeitKostenstelle: IArbeitszeitKostenstelle;
  kostenstelleList: any[];

  arbeitszeitKostenstelleZuteilungEditPopup: Interfaces.IdxPopupComponent;
  arbeitszeitKostenstelleZuteilungEditPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitKostenstelleZuteilungEditPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitKostenstelleZuteilungEdit",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitKostenstelleZuteilungEditPopup.instance.hide();
      this._options.callback(this.arbeitszeitKostenstelle);
    }
  }];

  arbeitszeitKostenstelleZuteilungEditValidation: any;
  arbeitszeitKostenstelleZuteilungEditValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  gueltigVonDateBox: Interfaces.IdxDateBoxComponent;
  gueltigVonDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitKostenstelle.GueltigVon",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  kostenstelleSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "kostenstelleList",
      value: "arbeitszeitKostenstelle.IdKostenstelle",
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitMitarbeiterstatusToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.kostenstelle",
      options: this.arbeitszeitKostenstelleZuteilungEditPopupOptions,
      commands: this.arbeitszeitKostenstelleZuteilungEditPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.loadKostenstelleDatasource();
  }


  async show(options: IArbeitszeitKostenstelleZuteilungOptions) {
    this._options = options;
    this.arbeitszeitKostenstelle = null;
    this.arbeitszeitKostenstelleZuteilungEditPopup.instance.show();
  }


  private isDataValid() {
    const result = this.arbeitszeitKostenstelleZuteilungEditValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }
    if (this.arbeitszeitKostenstelle.GueltigVon < this._options.mitarbeiterstatus.Eintrittsdatum) {
      DevExpress.ui.notify(
        "Gültig von Datum darf nicht kleiner als Eintrittsdatum sein",
        "error",
        this.NOTIFY_TIMEOUT);
      return false;
    }
    return true;
  }

  private async loadKostenstelleDatasource() {
    this.kostenstelleList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/kostenstelle")
    });
  }
}

export interface IArbeitszeitKostenstelleZuteilungOptions {
  mitarbeiterstatus: IArbeitszeitMitarbeiterstatus,
  callback: { (arbeitszeitKostenstelle: IArbeitszeitKostenstelle): void };
}

export interface IArbeitszeitKostenstelle {
  IdKostenstelle: number;
  GueltigVon: Date;
}
