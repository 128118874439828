import { autoinject, TemplatingEngine, bindable, TaskQueue } from "aurelia-framework";
import UI from "./ui";
import { DataService } from '../../services/data-service';
import { DataGridService } from '../../services/data-grid-service';
import { EditorService } from '../../services/editor-service';
import { IReport } from '../../interfaces/report';
import { IState } from '../../interfaces/state';
import { IDataSource } from '../../interfaces/data-source';
import { IViewModel } from '../../interfaces/view-model';
import { VariableService } from '../../services/variable-service';
import { ChartService } from '../../services/chart-service';
import { PivotService } from '../../services/pivot-services';
import { GlobalizationService, LocationService } from '../../../base/export';
import Nav from './nav';
import { ExcelExportService } from "../../../forms/services/excel-export-service";
import Excel from "./excel";

@autoinject
export class Report {
  constructor(
    private _element: Element,
    private _templatingEngine: TemplatingEngine,
    private _dataService: DataService,
    private _dataGridService: DataGridService,
    private _chartService: ChartService,
    private _pivotService: PivotService,
    private _editorService: EditorService,
    private _variableService: VariableService,
    private _globalizationService: GlobalizationService,
    private _locationService: LocationService,
    private _excelExportService: ExcelExportService,
    private _taskQueue: TaskQueue) { }

  @bindable id: string;

  reportDef: IReport;
  viewModel: IViewModel;

  async attached() {
    if (!this.id) {
      return;
    }

    const report = await this._dataService.getReport(this.id);
    if (!report) {
      return;
    }

    this.reportDef = report.definition;
    const container = this._element.querySelector(".report-container");

    this.viewModel = this.createViewModel(report.initialState);
    this.registerDataSourceChanged(null, this.onDataSourceChanged.bind(this));
    this.registerDataSourceSelectionChanged(null, this.onDataSourceSelectionChanged.bind(this));
    this.registerVariableChanged(null, this.onVariableChanged.bind(this));

    const view = this.createView();
    container.innerHTML = view;

    this._templatingEngine.enhance({
      element: container,
      bindingContext: this.viewModel,
      resources: (<any>this._element).au.controller.view.resources
    });

    this.dispatchLoaded(report);
    this.loadInitialData();
  }

  createState(): IState {
    const state: IState = {
      dataSourceSelected: [],
      variables: []
    };

    for (let key in this.viewModel.dataSourceSelected) {
      if (!this.hasDataSourceSelected(key)) {
        continue;
      }

      state.dataSourceSelected.push({
        id: key,
        items: this.viewModel.dataSourceSelected[key]
      });
    }

    for (let key in this.viewModel.variable) {
      if (!this._variableService.hasVariableValue(this.viewModel.variable[key])) {
        continue;
      }

      state.variables.push({
        id: key,
        operator: this.viewModel.variable[key].operator,
        value: this.viewModel.variable[key].value
      });
    }

    return state;
  }
  createViewModel(initialState: IState): any {
    const text = `class ViewModel { ${this.reportDef.javaScript} }; return new ViewModel();`

    const func = new Function(text);
    const viewModel: IViewModel = func();

    viewModel.ui = new UI(
      viewModel, 
      this._editorService, 
      this._dataGridService, 
      this._chartService, 
      this._pivotService);

    viewModel.nav = new Nav(
      this._element,
      this._locationService
    );
    viewModel.excel = new Excel(
      this._excelExportService
    );

    viewModel.report = this;

    viewModel.dataSource = {};
    viewModel.dataSourceSelected = {};
    viewModel.variable = {};
    viewModel.dataSourceChanged = [];
    viewModel.dataSourceSelectionChanged = [];
    viewModel.selectionHandled = {};
    viewModel.variableChanged = [];
    viewModel.registerDataSourceChanged = this.registerDataSourceChanged.bind(this);
    viewModel.registerDataSourceSelectionChanged = this.registerDataSourceSelectionChanged.bind(this);
    viewModel.registerVariableChanged = this.registerVariableChanged.bind(this);
    viewModel.fireDataSourceChanged = this.fireDataSourceChanged.bind(this);
    viewModel.fireDataSourceSelectionChanged = this.fireDataSourceSelectionChanged.bind(this);
    viewModel.fireVariableChanged = this.fireVariableChanged.bind(this);

    viewModel.format = this._globalizationService.format;
    viewModel.getFormatterParser = this._globalizationService.getFormatterParser;
    viewModel.getNumberFormat = this._globalizationService.getNumberFormat;

    if (viewModel.loadOptions) {
      viewModel.loadOptions();
    }

    if (initialState && initialState.variables) {
      for (let variable of initialState.variables) {
        viewModel.variable[variable.id] = variable;
      }
    }

    return viewModel;
  }
  createView() {
    let result = "";

    if (this.reportDef.style) {
      result = result.concat("<style>\n").concat(this.reportDef.style).concat("\n</style>\n");
    }

    result = result.concat(this.reportDef.html);
    return result;
  }
  dispatchLoaded(report) {
    const event = new CustomEvent("report-loaded", {
      detail: {
        sender: this,
        value: report
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }

  fireDataSourceChanged(idDataSource: string, data: any[]) {
    this.viewModel.dataSource[idDataSource] = data;

    for (let item of this.viewModel.dataSourceChanged) {
      if (item.idDataSource && item.idDataSource != idDataSource) {
        continue;
      }

      item.callback(idDataSource, data);

      const invokerMethod = `${idDataSource}Loaded`;
      if (this.viewModel[invokerMethod]) {
        this.viewModel[invokerMethod](data);
      }
    }
  }
  fireDataSourceSelectionChanged(idDataSource: string, data: any[]) {
    this.viewModel.dataSourceSelected[idDataSource] = data;

    for (let item of this.viewModel.dataSourceSelectionChanged) {
      if (item.idDataSource && item.idDataSource != idDataSource) {
        continue;
      }

      item.callback(idDataSource, data);
    }
  }
  fireVariableChanged(idVariable: string) {
    for (let item of this.viewModel.variableChanged) {
      if (item.idVariable && item.idVariable != idVariable) {
        continue;
      }

      item.callback(idVariable);
    }
  }
  registerDataSourceChanged(idDataSource: string, callback: { (idDataSource: string, data: any[]): void }, handlesSelection: boolean = false) {
    const info = {
      idDataSource,
      callback
    };
    
    this.viewModel.dataSourceChanged.push(info);

    if (idDataSource && handlesSelection) {
      this.viewModel.selectionHandled[idDataSource] = true;
    }

    return () => {
      const index = this.viewModel.dataSourceChanged.indexOf(info);
      if (!index) {
        return;
      }

      this.viewModel.dataSourceChanged.splice(index, 1);
    };
  }
  registerDataSourceSelectionChanged(idDataSource: string, callback: { (idDataSource: string, data: any[]): void }) {
    const info = {
      idDataSource,
      callback
    }
    
    this.viewModel.dataSourceSelectionChanged.push(info);

    return () => {
      const index = this.viewModel.dataSourceSelectionChanged.indexOf(info);
      if (!index) {
        return;
      }

      this.viewModel.dataSourceSelectionChanged.splice(index, 1);
    };
  }
  registerVariableChanged(idVariable: string, callback: { (idVariable: string): void }): {(): void} {
    const info = {
      idVariable,
      callback
    };

    this.viewModel.variableChanged.push(info);

    return () => {
      const index = this.viewModel.variableChanged.indexOf(info);
      if (!index) {
        return;
      }

      this.viewModel.variableChanged.splice(index, 1);
    };
  }
  async onDataSourceChanged(idDataSource: string, data: any[]) {
    if (data.length == 0) {
      return;
    }

    if (!this.viewModel.selectionHandled[idDataSource]) {
      this.fireDataSourceSelectionChanged(idDataSource, [data[0]]);
    }
  }
  async onDataSourceSelectionChanged(idDataSource: string) {
    for (let dataSource of this.reportDef.dataSources) {
      const hasRelation = this.hasRelation(idDataSource, dataSource.id);
      if (!hasRelation) {
        continue;
      }

      await this.loadDataSource(dataSource.id);
    }
  };
  async onVariableChanged(idVariable: string) {
    const refresh: IDataSource[] = [];

    for (let dataSource of this.reportDef.dataSources) {
      if (!dataSource.variables) {
        continue;
      }

      const hasVariable = dataSource.variables.some(v => v == idVariable);
      if (!hasVariable) {
        continue;
      }

      refresh.push(dataSource);
    }

    for (let i = 0; i < refresh.length; i++) {
      const hasRelation = refresh.filter(r => r != refresh[i]).some(r => this.hasRelation(r.id, refresh[i].id, true));
      if (!hasRelation) {
        continue;
      }

      refresh.splice(i, 1);
      i--;
    }

    for (let item of refresh) {
      await this.loadDataSource(item.id);
    }
  }

  async loadInitialData() {
    for (let dataSource of this.reportDef.dataSources) {
      if (dataSource.parentIds) {
        continue;
      }

      await this.loadDataSource(dataSource.id);
    }
  }
  async loadDataSource(idDataSource: string) {
    this.fireDataSourceSelectionChanged(idDataSource, []);
    this.fireDataSourceChanged(idDataSource, []);

    let allParentsHere = true;
    for (let item of this.reportDef.dataSources) {
      if (!this.hasRelation(item.id, idDataSource)) {
        continue;
      }

      if (!this.hasDataSourceSelected(item.id)) {
        allParentsHere = false;
        break;
      }
    }

    if (allParentsHere) {
      const r = await this._dataService.getData(this.id, idDataSource, this.createState());
  
      const start = new Date().getTime().toString();
      r.forEach((item, index) => {
        item["_id"] = start.concat(index.toString());
      });
  
      this.fireDataSourceChanged(idDataSource, r);
    }
  }
  
  hasDataSourceSelected(idDataSource: string): boolean {
    return this.viewModel.dataSourceSelected[idDataSource]
      && Array.isArray(this.viewModel.dataSourceSelected[idDataSource])
      && this.viewModel.dataSourceSelected[idDataSource].length > 0;
  }
  hasRelation(idDatasourceParent: string, idDataSourceChild: string, parentsRecurive: boolean = false): boolean {
    const child = this.reportDef.dataSources.find(d => d.id == idDataSourceChild);
    if (!child.parentIds) {
      return false;
    }

    const parents = child.parentIds;

    const isParentChild = parents.some(p => p == idDatasourceParent);

    if (isParentChild) {
      return true;
    } else if (parentsRecurive) {
      const parent = this.reportDef.dataSources.find(d => d.id == idDatasourceParent);
      if (!parent.parentIds) {
        return false;
      }

      return parent.parentIds.some(p => this.hasRelation(p, idDataSourceChild, true));
    } else {
      return false;
    }
  }
}
