import { autoinject } from "aurelia-framework";
import { StartupService } from '../../services/export';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject
export class HeaderCommands {
  private _startupInfoLoaded: Subscription;

  constructor(
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator) { }

  hasProjektzeiterfassung: boolean;
  hasMailImport: boolean;
  hasArbeitszeit: boolean;

  bind() {
    this.update();
    this._startupInfoLoaded = this._eventAggregator.subscribe("startupInfo:loaded", () => this.update());
  }
  unbind() {
    this._startupInfoLoaded.dispose();
    this._startupInfoLoaded = null;
  }

  private update() {
    const lizenz = this._startupService.startupInfo.Lizenz;

    this.hasProjektzeiterfassung = lizenz.HasProjektzeiterfassung;
    this.hasMailImport = lizenz.HasMailImport;
    this.hasArbeitszeit = lizenz.HasArbeitszeit;
  }
}
