import { autoinject, transient } from 'aurelia-framework';
import { ICommandData } from '../../../../framework/forms/interfaces/command-data';
import { FormBase } from '../../../../framework/forms/classes/form-base';
import { IdxDataGridComponent } from '../../../interfaces/export';
import { AsyncService } from '../../../../framework/base/services/async-service';
import { EximService } from '../../../services/exim-service';

@autoinject
@transient()
export class GeschaeftspartnerListUtils {
  constructor(
    private asyncService: AsyncService,
    private eximService: EximService
  ) {}
  
  form: FormBase;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    title: "erp.export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_geschaeftspartnerGrid"];
      await this.asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this.asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this.eximService.export("GeschaeftspartnerExim", keys);
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
