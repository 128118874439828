import {
  autoinject,
  OverrideContext,
  Scope,
  createOverrideContext
} from "aurelia-framework";

import {
  DataSourceService,
  ScopeContainer,
  LocalizationService,
  IDataSourceOptionFilter
} from "../../../framework/base/export";
import {
  SimpleWidgetCreatorService
} from "../../../framework/forms/widget-services/simple-widget-creator-service";

import {
  ProjektSearchService
} from "../../services/export";
import * as Interfaces from "../../interfaces/export";
import { GridLayoutService, IGridColumnExtenderResult } from '../../../framework/forms/services/grid-layout-service';

@autoinject
export class ProjektSearchResult {
  private _onProjektSelected: { (idProjekt: number): void };
  private _onProjektSelectedCancel: { (): void };

  constructor (
    private _element: Element,
    private _dataSourceService: DataSourceService,
    private _localizationService: LocalizationService,
    private _projektSearchService: ProjektSearchService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _gridLayoutService: GridLayoutService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  searchValue: string = null;
  searchListDataSource: DevExpress.data.DataSource;
  gridColumnExtenderResult: IGridColumnExtenderResult;

  searchResultDataGrid: Interfaces.IdxDataGridComponent;
  searchResultDataGridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    columns: [{
      caption: this._localizationService.translateOnce("projekt_search_result.nummer"),
      dataField: "Nummer",
      width: "80px"
    }, {
      caption: this._localizationService.translateOnce("projekt_search_result.projekt_art"),
      dataField: "Projektart.Bezeichnung",
      width: "120px"
    }, {
      caption: this._localizationService.translateOnce("projekt_search_result.bezeichnung"),
      dataField: "Bezeichnung"
    }, {
      caption: this._localizationService.translateOnce("projekt_search_result.sachbearbeiter"),
      dataField: "Sachbearbeiter.Name"
    }, {
      caption: this._localizationService.translateOnce("projekt_search_result.status"),
      dataField: "Status.Bezeichnung"
    }],
    onRowClick: (e) => {
      this._onProjektSelected(e.key.Id);
      this.searchResultPopup.instance.hide();
    },
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    remoteOperations: {
      filtering: true,
      paging: true,
      sorting: true
    }
  }

  searchResultPopup: Interfaces.IdxPopupComponent;
  searchResultPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    onHidden: () => {
      this.searchValue = null;
      this._onProjektSelectedCancel();
    }
  };

  searchTextBox: Interfaces.IdxTextBoxComponent;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this._localizationService.translateOnce("base.search"),
    onValueChanged: (e) => {
      this.reloadData();
      this.focusSearchTextBox();
    },
    bindingOptions: {
      value: "searchValue"
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._projektSearchService.projektSearchResult = this;

    this.gridColumnExtenderResult = this._gridLayoutService.addGridColumnExtender({
      webApiAction: "ERP/Stammdaten/Projekt",
      gridOptions: this.searchResultDataGridOptions,
      viewUrl: this._element["au"].controller.view.resources.viewUrl,
      id: "searchResultDataGrid"
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: "base.search",
      scopeContainer: this.scopeContainer,
      options: this.searchResultPopupOptions
    });

    this.searchListDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Projekt",
        webApiExpand: {
          Projektart: null,
          Sachbearbeiter: null,
          Status: null
        }
      },
      {
        getSearchText: () => {
          this;
          return this.searchValue;
        },
        getCustomFilters: () => {
          const filter: IDataSourceOptionFilter[] = [];

          const dynamicColumns = this.gridColumnExtenderResult.getDynamicColumns();
          if (dynamicColumns.length > 0) {
            filter.push({
              webApiCustomKey: "ExtendedLoadProperties",
              webApiCustomValue: JSON.stringify(dynamicColumns)
            });
          }

          return filter;
        }
      }
    );

    this.searchResultDataGridOptions.dataSource = this.searchListDataSource;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup(onProjektSelected: { (idProjekt: number): void },
    onProjektSelectedCancel: { (): void },
    options: Interfaces.ISearchOptions, ) {

    this._onProjektSelected = onProjektSelected;
    this._onProjektSelectedCancel = onProjektSelectedCancel;
    this.searchValue = options.searchValue;

    if (this.searchResultDataGrid && this.searchResultDataGrid.instance) {
      const filter = this.searchResultDataGrid.instance.getCombinedFilter();
      if (filter) {
        this.searchResultDataGrid.instance.clearFilter();
      }
    }

    this.searchResultPopup.instance.show()
      .then(() => {
        this.focusSearchTextBox();
      });
  }

  private focusSearchTextBox() {
    const element: Element = this.searchTextBox.instance.element();
    const input = element.querySelector("input");
    
    input.focus();
    input.select();
  }
  private reloadData() {
    if (this.searchListDataSource.pageIndex() > 0) {
      this.searchListDataSource.pageIndex(0);
    }

    this.searchListDataSource.reload();
  }
}
