import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  OnBelegImportFreigebenEvent, OnDatabaseRunningQueryRequestEvent, OnDatabaseRunningQueryRequest, OnDatabaseUpdateStatisticsEvent, OnDatabaseKillSessionEvent
} from "../../../../framework-data/events";
import { WebEventService } from '../../../../framework/base/services/web-event-service';

@autoinject
@transient()
export class DatenbankInfoListUtils {
  constructor(
    private webEvent: WebEventService
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.onAttached.register(r => {
      this.loadQueries();
      return Promise.resolve();
    })
  }

  doAktualisierenCommand: ICommandData = {
    id: "doAktualisieren",
    idCategory: "datenbank-info",
    title: "datenbank-info.aktualisieren",
    sortIndex: 10,
    icon: "refresh",
    execute: () => {
      this.loadQueries();
    }
  }
  doKillSessionsCommand: ICommandData = {
    id: "doKillSessions",
    idCategory: "datenbank-info",
    title: "datenbank-info.kill-sessions",
    icon: "ban",
    sortIndex: 15,
    execute: async () => {
      const grid: DevExpress.ui.dxDataGrid = this.form["r_queryGrid"].instance;
      const sessionIdList = grid.getSelectedRowsData().map(c => c.SessionId);

      await this.webEvent.execute(
        new OnDatabaseKillSessionEvent({
          SessionIdList: sessionIdList
        }), 
        true);

      this.loadQueries();
    }
  }
  doStatistikenAktualisierenCommand: ICommandData = {
    id: "doStatistikenAktualisieren",
    idCategory: "datenbank-info",
    title: "datenbank-info.statistik-aktualisieren",
    icon: "calculator",
    sortIndex: 20,
    execute: async () => {
      await this.webEvent.execute(
        new OnDatabaseUpdateStatisticsEvent({}), 
        true);

      this.loadQueries();
    }
  }

  private async loadQueries() {
    const r: OnDatabaseRunningQueryRequest = await this.webEvent.execute(
      new OnDatabaseRunningQueryRequestEvent({}), 
      true);

    const grid: DevExpress.ui.dxDataGrid = this.form["r_queryGrid"].instance;
    grid.option("dataSource", r.DatabaseQueryList);
  }
}
