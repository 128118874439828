import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IArbeitszeitBenutzerZuteilung } from "../../interfaces/arbeitszeit-benutzer-zuteilung";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitMitarbeiterstatus {
  private _options: IMitarbeiterstatusErstellenOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  arbeitszeitBenutzerzuteilung: IArbeitszeitBenutzerZuteilung;

  arbeitszeitmusterList: any[];
  abrechnungsmodellList: any[];
  anspruchsgruppeList: any[];
  gehaltsSchemaList: any[];
  zeitartList: any[];
  kalenderList: any[];
  kostenstelleList: any[];

  arbeitszeitMitarbeiterstatusPopup: Interfaces.IdxPopupComponent;
  arbeitszeitMitarbeiterstatusPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitMitarbeiterstatusPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitMitarbeiterstatusDetail",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitMitarbeiterstatusPopup.instance.hide();
      this._options.callback(this.arbeitszeitBenutzerzuteilung);
    }
  }];

  arbeitszeitBenutzerZuteilungValidation: any;
  arbeitszeitMitarbeiterstatusValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  personalnummerNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    bindingOptions: {
      value: "arbeitszeitBenutzerzuteilung.Personalnummer"
    }
  }

  vordienstzeitenMonateNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    bindingOptions: {
      value: "arbeitszeitBenutzerzuteilung.VordienstzeitenMonate"
    }
  }

  vordienstzeitenTageNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    bindingOptions: {
      value: "arbeitszeitBenutzerzuteilung.VordienstzeitenTage"
    }
  }

  eintrittsDatumBox: Interfaces.IdxDateBoxComponent;
  eintrittsDatumBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitBenutzerzuteilung.Eintrittsdatum",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  arbeitszeitmusterSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "arbeitszeitmusterList",
      value: "arbeitszeitBenutzerzuteilung.IdArbeitszeitmuster",
    }
  };

  abrechnungsmodellSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "abrechnungsmodellList",
      value: "arbeitszeitBenutzerzuteilung.IdAbrechnungsmodell",
    }
  };

  beschaeftigungsausmassNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n4"),
    bindingOptions: {
      value: "arbeitszeitBenutzerzuteilung.BeschaeftigungsausmassProzent"
    }
  }

  anspruchsgruppeSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "anspruchsgruppeList",
      value: "arbeitszeitBenutzerzuteilung.IdAnspruchsgruppe",
    }
  };

  gehaltsSchemaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "gehaltsSchemaList",
      value: "arbeitszeitBenutzerzuteilung.IdGehaltsSchema",
    }
  };

  kalenderSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "kalenderList",
      value: "arbeitszeitBenutzerzuteilung.IdKalender",
    }
  };

  kostenstelleSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "kostenstelleList",
      value: "arbeitszeitBenutzerzuteilung.IdKostenstelle",
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitMitarbeiterstatusToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.mitarbeiterstatus",
      options: this.arbeitszeitMitarbeiterstatusPopupOptions,
      commands: this.arbeitszeitMitarbeiterstatusPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  async show(options: IMitarbeiterstatusErstellenOptions) {
    this._options = options;
    this.arbeitszeitBenutzerzuteilung = {};
    
    await this.loadArbeitszeitmusterDatasource();
    await this.loadAbrechnungsmodellDatasource();
    await this.loadAnspruchsgruppeDatasource();
    await this.loadGehaltsSchemaDatasource();
    await this.loadKalenderDatasource();
    await this.loadKostenstelleDatasource();

    this.arbeitszeitMitarbeiterstatusPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitBenutzerZuteilungValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }

    return true;
  }
  private async loadArbeitszeitmusterDatasource() {
    this.arbeitszeitmusterList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/arbeitszeitmuster")
    });
  }
  private async loadAbrechnungsmodellDatasource() {
    this.abrechnungsmodellList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/abrechnungsmodell")
    });
  }
  private async loadAnspruchsgruppeDatasource() {
    this.anspruchsgruppeList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/anspruchsgruppe")
    });
  }
  private async loadGehaltsSchemaDatasource() {
    this.gehaltsSchemaList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/gehaltsschema")
    });
  }
  private async loadKalenderDatasource() {
    this.kalenderList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/kalender")
    });
  }
  private async loadKostenstelleDatasource() {
    this.kostenstelleList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/kostenstelle")
    });
  }
}

export interface IMitarbeiterstatusErstellenOptions {
  idArbeitszeitBenutzerZuteilung: number;
  callback: { (arbeitszeitBenutzerZuteilung: IArbeitszeitBenutzerZuteilung): void };
}
