import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ArbeitszeitKostenstelleEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "arbeitszeit-kostenstelle-edit";
        this.title = "arbeitszeit-kostenstelle-edit.arbeitszeit-kostenstelle-edit_caption";
        this.addModel({
            "id": "$m_ArbeitszeitKostenstelle",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitKostenstelle",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "arbeitszeit-kostenstelle-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_ArbeitszeitKostenstelle",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_ArbeitszeitKostenstelle.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "arbeitszeit-kostenstelle-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_ArbeitszeitKostenstelle",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_ArbeitszeitKostenstelle.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}