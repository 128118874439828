import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, transient } from "aurelia-framework";
import { OnAuftragAddLieferscheinpositionEvent, OnAuftragBestaetigt, OnAuftragBestaetigtEvent, OnAuftragGewichtAktualisieren, OnAuftragGewichtAktualisierenEvent, OnAuftragKondAktualisieren, OnAuftragKondAktualisierenEvent, OnAuftragPositionenSortierenEvent, OnAuftragRecalcLieferdatumEvent, OnAuftragReservieren, OnAuftragReservierenEvent, OnAuftragVorauskasseEvent, OnAuftragspositionenInNeuenAuftragUebernehmen, OnAuftragspositionenInNeuenAuftragUebernehmenEvent, OnBestellungIntercompanyErstellen, OnBestellungIntercompanyErstellenEvent, OnBestellungStreckengeschaeftErstellen, OnBestellungStreckengeschaeftErstellenEvent, OnDeleteIdAuftragspositionListEvent, OnErstelleLieferscheinAusAuftrag, OnErstelleLieferscheinAusAuftragEvent, OnEvalLieferRechnungsort, OnEvalLieferRechnungsortEvent, OnImportAngebotspositionenInAuftrag, OnImportAngebotspositionenInAuftragEvent, OnKopierenRequest, OnKopierenRequestEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { LocationService } from "../../../../framework/base/services/location-service";
import { ContextMenu, FormBase, ICommandData, ModelUtilsService } from "../../../../framework/forms/export";
import { PopupInfoService } from "../../../../framework/forms/services/popup-info-service";
import { Geschaeftspartner } from "../../../elements/geschaeftspartner/geschaeftspartner";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent, IdxDateBoxComponent } from "../../../interfaces/export";
import { BelegExportService } from "../../../services/beleg-export-service";
import { BestellungService } from "../../../services/bestellung-service";
import { AuftragService, DynFelderDataService, DynFelderService, FilialeService, FirmaService, GeschaeftspartnerSearchService, GeschaeftspartnerService, KalkulationRefreshService, LagerService, LieferscheinService, StartupService, ValidationExService } from "../../../services/export";
import { PersonService } from "../../../services/person-service";
import { RestService } from "./../../../../framework/base/services/rest-service";
import { DynFeldLogikTyp } from "../../../enumerations/dyn-feld-logik-typ";

@autoinject
@transient()
export class AuftragEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;
  private _infobarZuletztArtikelAddSubscription: Subscription;

  constructor(
    public dynFelderData: DynFelderDataService,
    public startupService: StartupService,
    private _auftragService: AuftragService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _bestellungService: BestellungService,
    private _dynFelderService: DynFelderService,
    private _eventAggregator: EventAggregator,
    private _filialeService: FilialeService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerSearchService: GeschaeftspartnerSearchService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _lagerService: LagerService,
    private _lieferscheinService: LieferscheinService,
    private _localizationService: LocalizationService,
    private _locationService: LocationService,
    private _popupInfoService: PopupInfoService,
    private _personService: PersonService,
    private _modelUtilsService: ModelUtilsService,
    private _restService: RestService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  hasStreckengeschaeftBestellung: boolean;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 999,
    isVisibleExpression: "functions.$f_AuftragEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_AuftragEditUtils.isBelegExportMandantAktiv && models.data.$m_Auftrag.Id > 0 && !models.data.$m_Auftrag.IsIcAuftrag",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Auftrag.Id;
      this._belegExportService.export(BelegImportTyp.Auftrag, [id]);
    }
  };
  showImportAngebotspositionenPopupCommand: ICommandData = {
    id: "showImportAngebotspositionenPopup",
    icon: "plus",
    idCategory: "auftrag",
    sortIndex: 0,
    title: "auftrag.angebotspos_importieren",
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
    execute: async () => {
      const validationResult = await this.form.saveIfDirty();
      if (!validationResult.isValid) {
        return;
      }

      const angebotspositionenGrid: IdxDataGridComponent = this.form["r_angebotspositionenGrid"];
      if (angebotspositionenGrid && angebotspositionenGrid.instance) {
        await this._asyncService.convertToPromise(angebotspositionenGrid.instance.deselectAll());
        angebotspositionenGrid.instance.refresh();
      }

      this.form["r_angebotspositionenPopup"].instance.show();
    }
  };
  importAngebotspositionenCommand: ICommandData = {
    id: "importAngebotspositionen",
    title: "auftrag.mark_pos_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_angebotspositionenGrid"];

      const webEvent = new OnImportAngebotspositionenInAuftragEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id,
        IdAngebotspositionList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportAngebotspositionenInAuftrag = await this._webEventService.execute(webEvent, true);

      this.form["r_angebotspositionenPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  };
  bestaetigenCommand: ICommandData = {
    id: "bestaetigen",
    idCategory: "auftrag",
    title: "",
    icon: "",
    sortIndex: 10,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const args: OnAuftragBestaetigt = {
        IdAuftrag: this.form.models.data.$m_Auftrag.Id,
        IsBestaetigt: false
      };

      if (!this.form.models.data.$m_Auftrag.IsBestaetigt) {
        args.IsBestaetigt = true;
      }

      const webEventResult: OnAuftragBestaetigt = await this._webEventService.execute(new OnAuftragBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };
  reservierenCommand: ICommandData = {
    id: "reservieren",
    idCategory: "reservierung",
    icon: "plus-square",
    title: "auftrag.reservieren",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.IsBestaetigt == true",
    execute: () => {
      return this.doReservieren(true);
    }
  };
  reservierungEntfernenCommand: ICommandData = {
    id: "reservierungEntfernen",
    idCategory: "reservierung",
    icon: "minus-square",
    title: "auftrag.reservierung_entfernen",
    sortIndex: 1,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.IsBestaetigt == true",
    execute: () => {
      return this.doReservieren(false);
    }
  };
  showLieferscheinErstellenCommand: ICommandData = {
    id: "doLieferscheinErstellen",
    idCategory: "auftrag",
    icon: "plus-circle",
    title: "auftrag.lieferschein_erstellen",
    sortIndex: 999,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Auftrag.TypeName,
        this.form.models.data.$m_Auftrag.Id,
        "LIEFERSCHEIN_ERSTELLEN"
      );

      if (!validationResult) {
        return;
      }

      const lieferscheinGrid: IdxDataGridComponent = this.form["r_lieferscheinGrid"];
      if (lieferscheinGrid && lieferscheinGrid.instance) {
        lieferscheinGrid.instance.refresh();
      }

      this._lieferscheinService.canAuftragMerge(this.form.models.data.$m_Auftrag)
        .then((r) => {
          if (r) {
            this.form["r_lieferscheinErstellenPopup"].instance.show();
          } else {
            this.doInLieferscheinUebernehmen(false, ctrlKeyPressed);
          }
        });
    }
  };
  bestellungErstellenCommand: ICommandData = {
    id: "doBestellungErstellen",
    idCategory: "auftrag",
    icon: "plus-circle",
    title: "auftrag.bestellung_erstellen",
    sortIndex: 999,
    isEnabledExpression: "!functions.$f_AuftragEditUtils.hasStreckengeschaeftBestellung",
    isVisibleExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0 && models.data.$m_Auftrag.IsStreckengeschaeft",
    execute: async (e: any) => {
      const webEvent = new OnBestellungStreckengeschaeftErstellenEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id
      });

      const webEventResult: OnBestellungStreckengeschaeftErstellen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdBestellung) {
        return;
      }

      this.hasStreckengeschaeftBestellung = true;
      const navigationUrl = `ERP/Einkauf/Bestellung/${webEventResult.IdBestellung}`;

      this._locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  };
  icBestellungErstellenCommand: ICommandData = {
    id: "doIcBestellungErstellen",
    idCategory: "auftrag",
    icon: "plus-circle",
    title: "auftrag.ic_ablauf_ausloesen",
    sortIndex: 999,
    isEnabledExpression: "!models.data.$m_Auftrag.HasIntercompanyBestellung",
    isVisibleExpression: "functions.$f_AuftragEditUtils.startupService.startupInfo.Lizenz.HasIntercompany && models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0 && models.data.$m_Auftrag.IsIntercompany",
    execute: async (e: any) => {
      const webEvent = new OnBestellungIntercompanyErstellenEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id
      });

      const webEventResult: OnBestellungIntercompanyErstellen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdBestellung) {
        return;
      }

      this.form.models.reloadAll();
    }
  };
  zuMarkiertemLieferscheinHinzufuegenCommand: ICommandData = {
    id: "zuMarkiertemLieferscheinHinzufuegen",
    title: "auftrag.zu_mark_lieferschein_hinzufuegen",
    execute: async () => {
      await this.doInLieferscheinUebernehmen(true);
    }
  };
  neuenLieferscheinErstellenCommand: ICommandData = {
    id: "neuenLieferscheinErstellen",
    title: "auftrag.neuen_lieferschein_erstellen",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      await this.doInLieferscheinUebernehmen(false, ctrlKeyPressed);
    }
  };
  refreshGewichtCommand: ICommandData = {
    id: "refreshGewichte",
    idCategory: "gewicht",
    title: "auftrag.gewicht_aktualisieren",
    icon: "cubes",
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0",
    sortIndex: 10,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const onAuftragGewichtAktualisierenEvent = new OnAuftragGewichtAktualisierenEvent({
        IdAuftrag: this.form.models.data["$m_Auftrag"].Id
      });

      const webEventResult: OnAuftragGewichtAktualisieren = await this._webEventService.execute(
        onAuftragGewichtAktualisierenEvent,
        true);

      return this.form.models.reloadAll();
    }
  };
  neueKonditionenLaden: ICommandData = {
    id: "neueKonditionenLaden",
    idCategory: "konditionen",
    icon: "refresh",
    title: "auftrag.konditionen_laden",
    sortIndex: 1,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const args: OnAuftragKondAktualisieren = {
        IdAuftrag: this.form.models.data.$m_Auftrag.Id,
        AllePositionen: selectedKeys.length === 0,
        IdAuftragPosList: selectedKeys
      };

      await this._webEventService.execute(new OnAuftragKondAktualisierenEvent(args), true);
      return this.form.models.reloadAll();
    }
  };
  auftragKopieErstellenCommand: ICommandData = {
    id: "auftragKopieErstellen",
    idCategory: "split",
    icon: "plus-circle",
    title: "auftrag.auftrag_erstellen",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0 && models.data.$m_Auftrag.IsStreckengeschaeft == false && !models.data.$m_Auftrag.IsIcAuftrag",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnAuftragspositionenInNeuenAuftragUebernehmenEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id,
        IdAuftragPosList: selectedKeys
      });

      const webEventResult: OnAuftragspositionenInNeuenAuftragUebernehmen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdAuftrag) {
        return;
      }

      this._locationService.goTo({
        url: `ERP/Verkauf/Auftrag/${webEventResult.IdAuftragKopie}`,
        clearStack: false
      });

      this.form.models.reloadAll();
    }
  };
  positionenSortierenCommand: ICommandData = {
    id: "positionenSortieren",
    idCategory: "positionen",
    icon: "sort-alpha-asc",
    title: "erp.positionen-sortieren",
    sortIndex: 10,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && models.data.$m_Auftrag.Id > 0 && !models.data.$m_Auftrag.IsIcAuftrag",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const sort = async (typ) => {
        const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
        await this._webEventService.execute(new OnAuftragPositionenSortierenEvent({
          IdAuftrag: this.form.models.data.$m_Auftrag.Id,
          Typ: typ
        }), true);

        grid.instance.refresh();
      };

      const contextMenu = new ContextMenu();
      contextMenu.items.push({
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelnr"),
        execute: () => {
          sort(0);
        }
      }, {
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelbezeichnung"),
        execute: () => {
          sort(1);
        }
      });

      contextMenu.show(e.event.target);
    }
  };
  vorauskasseCommand: ICommandData = {
    id: "vorauskasseCommand",
    icon: "plus",
    title: "auftrag.vorauskasse",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      await this._webEventService.execute(new OnAuftragVorauskasseEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id
      }), true);

      this.form.models.reloadAll();
    }
  };
  recalcLieferdatumCommand: ICommandData = {
    id: "recalcLieferdatumCommand",
    icon: "calculator",
    idCategory: "artikel_bestellvorschlag",
    title: "auftrag.recalc-lieferdatum",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Auftrag.Id && models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
    isVisible: this.startupService.startupInfo.Mandant.IsBerechnungLieferdatumAktiv,
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      await this._webEventService.execute(new OnAuftragRecalcLieferdatumEvent({
        IdAuftrag: this.form.models.data.$m_Auftrag.Id
      }), true);

      this.form.models.reloadAll();

      DevExpress.ui.notify(
        this._localizationService.translateOnce("auftrag.lieferdatum-neu-berechnet"),
        "success",
        this.NOTIFY_TIMEOUT
      );
    }
  };
  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
      const auftragPosGridRowData = grid.instance.getSelectedRowsData();

      if (auftragPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("auftrag.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const auftragsposition of auftragPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          auftragsposition.TypeName,
          auftragsposition.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const auftragsposition of auftragPosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: auftragsposition.Id,
          CustomData: { "PosNrUeberschreiben": true }
        }), true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Auftrag.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
      const auftragPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (auftragPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("auftrag.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (auftragPosGridRowData.some(c => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("auftrag.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const auftragsposition of auftragPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          auftragsposition.TypeName,
          auftragsposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = auftragPosGridRowData.map(c => c.Id);

      await this._webEventService.execute(new OnDeleteIdAuftragspositionListEvent({
        IdAuftragspositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this._infobarZuletztArtikelAddSubscription = this._eventAggregator.subscribe("infobar:zuletzt-verkaufte-artikel:addArtikel", (e) => {
      if (this._popupInfoService.isPopupOpen()) {
        return;
      }
      if (!this.form.isCurrentForm) {
        return;
      }

      this.addLieferscheinposition(e);
    });

    form.focusFirstEnabledEditor = () => {
      const kunde = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_kunde'] .dx-searchbox input");
      if (!kunde) {
        return;
      }

      kunde.focus();
    };

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Auftrag") {
        return;
      }

      if (this.form.models.data.$m_Auftrag.IsBestaetigt) {
        this.bestaetigenCommand.title = "auftrag.nicht_bestaetigen";
        this.bestaetigenCommand.icon = "star";
      } else {
        this.bestaetigenCommand.title = "auftrag.bestaetigen";
        this.bestaetigenCommand.icon = "star-o";
      }

      this.hasStreckengeschaeftBestellungBereitsErstellt();

      this.updateLieferdatumFarbe(r.data);
      this.updateBerechnLieferdatumFarbe(r.data);
      this.updateBerechnLieferdatumButtons(r.data);

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Auftrag);

      return Promise.resolve();
    });
    form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        if (this.startupService.startupInfo.Lizenz.HasIntercompany && !this.form.models.data.$m_Auftrag.IsIcAuftrag) {
          const dynFeldIntercompany = this.dynFelderData.dynFeldList
            .filter(c => c.LogikTyp == DynFeldLogikTyp.Intercompany)[0] || null;

          if (dynFeldIntercompany == null) {
            return Promise.resolve();
          }

          const dynFeldEintragIntercompany = this.form.models.data.$m_Auftrag.DynamischesFeldContainer.DynamischesFeldEintraege
            .filter(c => c.IdDynamischesFeld == dynFeldIntercompany.Id)[0] || null;

            if (!dynFeldEintragIntercompany) {
              return Promise.resolve();
            }

          if (dynFeldEintragIntercompany.WertNummer == 0 && this.form.models.data.$m_Auftrag.HasIntercompanyBestellung) {
            const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
              this._localizationService.translateOnce("auftrag.sure_intercompany"),
              this._localizationService.translateOnce("base.question")));

            if (!doExecute) {
              return Promise.reject();
            }
            return Promise.resolve();
          }
        }
      }
      return Promise.resolve();
    });
    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_AuftragspositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Auftrag",
      idArtPropertyName: "IdAuftragsart",
      idVorgEntitaetPropertyName: "IdKunde"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Auftrag",
      kalkulationRef: "r_kalkulation",
      editPopups: ["auftragspositionEditPopup", "auftragsgruppeEditPopup"]
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "auftragspositionEditPopup") {
        form["r_auftragsgruppeGrid"].instance.refresh();
      } else if (r.editPopup.id == "auftragsgruppeEditPopup") {
        form["r_auftragspositionGrid"].instance.refresh();
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Auftrag") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Auftrag"];
          if (model) {
            model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);

            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Auftrag",
                bindTo: "IdLieferfiliale"
              },
              value: model.IdLieferfiliale
            });
          }
        }

        if (args.binding.bindTo === "IdLieferfiliale") {
          const model = form.models.data["$m_Auftrag"];
          if (model) {
            model.IdLieferlager = await this._lagerService.getIdLagerVorbelegung(args.value);

            const idWaehrung = await this._filialeService.getWaehrungIdOfFiliale(args.value);
            if (idWaehrung != null) {
              model.IdWaehrung = idWaehrung;
            } else {
              model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Auftrag.IdFirma);
            }
          }
        }

        if (args.binding.bindTo === "IdAuftragsart") {
          const data = this.form.models.data.$m_Auftrag;
          if (!data) {
            return;
          }

          data.Lieferdatum = await this._auftragService.getLieferdatum(
            data.IdAuftragsart,
            data.Lieferdatum);
        }
      }
    });

    this.form.callOnBind(() => {
      if (!this.startupService.startupInfo.Mandant.IsBerechnungLieferdatumAktiv) {
        const posGridOptions: DevExpress.ui.dxDataGridOptions = this.form["r_auftragspositionGridOptions"];

        const berechnLieferdatumIndex = posGridOptions.columns.findIndex((c: DevExpress.ui.dxDataGridColumn) => c.dataField == "BerechnetLieferdatum");
        if (berechnLieferdatumIndex >= 0) {
          posGridOptions.columns.splice(berechnLieferdatumIndex, 1);
        }
      }
    });
  }
  unbind() {
    if (this._infobarZuletztArtikelAddSubscription) {
      this._infobarZuletztArtikelAddSubscription.dispose();
      this._infobarZuletztArtikelAddSubscription = null;
    }
  }
  async doReservieren(doReservieren: boolean) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
    const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

    const args: OnAuftragReservieren = {
      IdAuftrag: this.form.models.data.$m_Auftrag.Id,
      DoReservieren: doReservieren,
      AllePositionen: selectedKeys.length === 0,
      IdAuftragPosList: selectedKeys
    };

    const webEventResult: OnAuftragReservieren = await this._webEventService.execute(new OnAuftragReservierenEvent(args), true);
    return this.form.models.reloadAll();
  }

  async doInLieferscheinUebernehmen(mergeMitLieferschein: boolean, openInNewWindow: boolean = false) {
    const selectedAuftragspositionKeys: number[] = this.getSelectedAuftragspositionen();

    let idMergeMitLieferschein = null;

    if (mergeMitLieferschein) {
      idMergeMitLieferschein = this.getSelectedLieferschein();

      if (!idMergeMitLieferschein) {
        DevExpress.ui.notify(this._localizationService.translateOnce("auftrag.kein_lieferschein_ausgewaehlt"), "error", this.NOTIFY_TIMEOUT);
        return;
      }
    }

    const webEvent = new OnErstelleLieferscheinAusAuftragEvent({
      IdAuftrag: this.form.models.data.$m_Auftrag.Id,
      UebernehmeAllePositionen: selectedAuftragspositionKeys.length === 0,
      IdAuftragPosList: selectedAuftragspositionKeys,
      IdMergeMitLieferschein: mergeMitLieferschein ? idMergeMitLieferschein : null
    });

    this.form["r_lieferscheinErstellenPopup"].instance.hide();

    const webEventResult: OnErstelleLieferscheinAusAuftrag = await this._webEventService.execute(webEvent, true);
    if (!webEventResult || !webEventResult.IdLieferschein) {
      return;
    }

    const navigationUrl = `ERP/Verkauf/Lieferschein/${webEventResult.IdLieferschein}`;

    if (openInNewWindow) {
      this._locationService.openWindow(navigationUrl);
      this.form.models.reloadAll();
    } else {
      this._locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  }
  async addLieferscheinposition(item) {
    const validationResult = await this.form.saveIfDirty();
    if (!validationResult.isValid) {
      return;
    }

    let args = new OnAuftragAddLieferscheinpositionEvent({
      IdAuftrag: this.form.models.data.$m_Auftrag.Id,
      IdLieferscheinposition: item.idLieferscheinposition
    });

    args = await this._webEventService.execute(args, true);
    item.isHandled = true;

    this.form.models.reloadAll();
  }

  onMengeReserviertCellPrepared(e): void {
    if (e.rowType != "data" || this.form.models.data.$m_Auftrag.IsBestaetigt == false || e.data.Status.Typ === 1) {
      return;
    }

    const rowData = e.data;
    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = rowData.MengeReserviertVerpEH < rowData.MengeOffenVerpEH
      ? "#C1392D"
      : (rowData.HasLagerfuehrung ? "#2DCC70" : "#566473");
  }
  onLieferdatumValueChanged() {
    this.updateLieferdatumFarbe(null);
  }
  onBerechnLieferdatumCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.HasFehlendeBeschaffungsperiode) {
      return;
    }

    const cell: HTMLTableDataCellElement = e.cellElement;

    cell.style.backgroundColor = "#D35400";
    cell.style.color = "white";
  }
  async onKundeChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    const onEvalLieferRechnungsortEvent = new OnEvalLieferRechnungsortEvent({ IdKunde: e.detail.value });
    const onEvalLieferRechnungsortData: OnEvalLieferRechnungsort = await this._webEventService.execute(onEvalLieferRechnungsortEvent);

    this.form["r_lieferort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdLieferort);
    this.form["r_rechnungsort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdRechnungsort);

    if (onEvalLieferRechnungsortData.HasMoreThanOneLieferort) {
      this._geschaeftspartnerSearchService.show((r) => {
        this.form["r_lieferort"].setGeschaeftpartnerById(r);
      }, () => { }, {
        caption: "erp.select_lieferort",
        filters: [{
          webApiCustomKey: "IdLieferortFuer",
          webApiCustomValue: e.detail.value
        }]
      });
    }

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }
  onKundeAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    const idKunde = this.form.models.data.$m_Auftrag.IdKunde;
    if (idKunde == this.form.models.data.$m_Auftrag.IdLieferort) {
      if (this.form.models.data.$m_Auftrag.IdLieferortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Auftrag.IdLieferortAnsprechperson = idPerson;
      }

      this.onLieferortAnsprechpersonChanged({
        detail: {
          value: idPerson
        }
      });
    }

    if (idKunde == this.form.models.data.$m_Auftrag.IdRechnungsort) {
      if (this.form.models.data.$m_Auftrag.IdRechnungsortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Auftrag.IdRechnungsortAnsprechperson = idPerson;
      }
    }
  }
  async onLieferortAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    this.form.models.data.$m_Auftrag.LieferortTelefon = await this._personService.getPersonTelefon(idPerson);
  }
  onKundeAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onLieferortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onRechnungsortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }

  private getSelectedLieferschein(): number {
    const lieferscheinGrid: IdxDataGridComponent = this.form["r_lieferscheinGrid"];
    const selectedLieferscheinKeys: number[] = lieferscheinGrid.instance.getSelectedRowKeys();

    return selectedLieferscheinKeys[0];
  }
  private getSelectedAuftragspositionen(): number[] {
    const grid: IdxDataGridComponent = this.form["r_auftragspositionGrid"];
    const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

    return selectedKeys;
  }
  private updateLieferdatumFarbe(data: any) {
    const berechnLieferdatum: IdxDateBoxComponent = this.form["r_lieferdatum"];
    if (!berechnLieferdatum) {
      return;
    }

    const element = <any>berechnLieferdatum.instance.element();
    if (!element) {
      return;
    }

    const input = <HTMLInputElement>element.querySelector(".dx-texteditor-container input");
    if (!input) {
      return;
    }

    if (!data) {
      data = this.form.models.data.$m_Auftrag;
    }

    if (input) {
      if (data && data.Lieferdatum && data.BerechnetLieferdatum && data.Lieferdatum.getTime() < data.BerechnetLieferdatum.getTime()) {
        input.style.backgroundColor = "#C0392B";
        input.style.color = "white";
      } else {
        input.style.backgroundColor = "";
        input.style.color = "";
      }
    }
  }
  private updateBerechnLieferdatumFarbe(data: any) {
    const berechnLieferdatum: IdxDateBoxComponent = this.form["r_berechnLieferdatum"];
    if (!berechnLieferdatum) {
      return;
    }

    const element = <any>berechnLieferdatum.instance.element();
    if (!element) {
      return;
    }

    const input = <HTMLInputElement>element.querySelector(".dx-texteditor-container input");
    if (!input) {
      return;
    }

    if (input) {
      if (data && data.HasFehlendeBeschaffungsperiode) {
        input.style.backgroundColor = "#D35400";
        input.style.color = "white";
      } else {
        input.style.backgroundColor = "";
        input.style.color = "";
      }
    }
  }
  private updateBerechnLieferdatumButtons(auftrag: any) {
    const editor: IdxDateBoxComponent = this.form["r_berechnLieferdatum"];
    if (!editor || !editor.instance) {
      return;
    }

    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    const showButton = auftrag.CanSave
      && auftrag.BerechnetLieferdatum;

    if (showButton) {
      buttons.push({
        location: "after",
        name: "useBerechnetLieferdatum",
        options: {
          disabled: false,
          icon: "fa fa-arrow-right",
          hint: "Berechnetes Lieferdatum in tatsächliches Lieferdatum übernehmen",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            auftrag.Lieferdatum = auftrag.BerechnetLieferdatum;
            this._modelUtilsService.setDirty(auftrag);
          }
        }
      });
    }

    editor.instance.option("buttons", buttons);
  }

  private async hasStreckengeschaeftBestellungBereitsErstellt() {
    if (this.form.models.data.$m_Auftrag.IsStreckengeschaeft) {
      const bestellungList = await this._restService.get({
        url: this._restService.getWebApiUrl("ERP/Einkauf/Bestellung"),
        getOptions: {
          where: ["IdAuftrag", this.form.models.data.$m_Auftrag.Id]
        }
      });
      this.hasStreckengeschaeftBestellung = bestellungList.length > 0
        ? true
        : false;
    } else {
      this.hasStreckengeschaeftBestellung = false;
    }
  }
  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Auftrag.CanSave && this.form.models.data.$m_Auftrag.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Auftrag.IdKunde);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Auftrag.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Auftrag",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
