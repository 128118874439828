import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";

@autoinject
export class LagerService {
  constructor(
    private rest: RestService
  ) { }

  async getIdLagerVorbelegung(idFiliale: number): Promise<number> {
    const getOptions = {
      columns: ["Id"],
      where: [["IsVorbelegung", true], ["IdFiliale", idFiliale]]
    };

    const r = await this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Stammdaten/Lager"),
      getOptions
    });
    
    if (r && r.length > 0) {
      return r[0].Id;
    } else {
      return null;
    }
  }
}