import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonListUtils
} from "./person-list-utils";

@fwx.autoinject
export class PersonListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonListUtils: PersonListUtils) {
        super(element, formBaseImport);
        this.id = "person-list";
        this.title = "person-list.person-list_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ERP/Stammdaten/Person",
            "webApiExpand": {
                'Geschaeftspartner': {
                    'expand': {
                        'Adresse': {
                            'expand': {
                                'Land': null
                            }
                        }
                    }
                },
                'Anrede': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_personGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonListUtils.doExport",
                "bindToFQ": "functions.$f_PersonListUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonListUtils.newsletterEmpfErstellenCommand",
                "bindToFQ": "functions.$f_PersonListUtils.newsletterEmpfErstellenCommand"
            }
        });
        this.addFunction("$f_PersonListUtils", $f_PersonListUtils, "functions.$f_PersonListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_perAnrede",
                "caption": "person-list.r_peranrede_caption",
                "bindTo": "Anrede.Bezeichnung",
                "width": "80px"
            }, {
                "id": "r_perTitelVorne",
                "caption": "person-list.r_pertitelvorne_caption",
                "bindTo": "TitelVorne",
                "width": "80px"
            }, {
                "id": "r_perVorname",
                "caption": "person-list.r_pervorname_caption",
                "bindTo": "Vorname",
                "minWidth": 100
            }, {
                "id": "r_perNachname",
                "caption": "person-list.r_pernachname_caption",
                "bindTo": "Nachname",
                "minWidth": 100
            }, {
                "id": "r_perTitelHinten",
                "caption": "person-list.r_pertitelhinten_caption",
                "bindTo": "TitelHinten",
                "width": "80px"
            }, {
                "id": "r_perEmail",
                "caption": "person-list.r_peremail_caption",
                "bindTo": "Email",
                "minWidth": 100
            }, {
                "id": "r_perTelefon",
                "caption": "person-list.r_pertelefon_caption",
                "bindTo": "Telefon",
                "width": "160px"
            }, {
                "id": "r_perMobiltelefon",
                "caption": "person-list.r_permobiltelefon_caption",
                "bindTo": "Mobiltelefon",
                "width": "160px"
            }, {
                "id": "r_perNummer",
                "caption": "person-list.r_pernummer_caption",
                "bindTo": "Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_perFirmenbez1",
                "caption": "person-list.r_perfirmenbez1_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_perFirmenbez2",
                "caption": "person-list.r_perfirmenbez2_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez2",
                "minWidth": 100
            }, {
                "id": "r_perFirmenbez3",
                "caption": "person-list.r_perfirmenbez3_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez3",
                "minWidth": 100
            }, {
                "id": "r_perStrasse",
                "caption": "person-list.r_perstrasse_caption",
                "bindTo": "Geschaeftspartner.Adresse.Strasse",
                "minWidth": 100
            }, {
                "id": "r_perLand",
                "caption": "person-list.r_perland_caption",
                "bindTo": "Geschaeftspartner.Adresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_perPlz",
                "caption": "person-list.r_perplz_caption",
                "bindTo": "Geschaeftspartner.Adresse.PLZ",
                "width": "80px"
            }, {
                "id": "r_perOrt",
                "caption": "person-list.r_perort_caption",
                "bindTo": "Geschaeftspartner.Adresse.Ort",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_personGridToolbarOptions",
                "optionsNameFQ": "r_personGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Person"
            },
            "dataModel": "$m_Person",
            "editUrl": "ERP/Stammdaten/Person",
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_personGrid",
            "options": {
                "optionsName": "r_personGridOptions",
                "optionsNameFQ": "r_personGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_newsletterEmpf",
            "options": {
                "optionsName": "r_newsletterEmpfOptions",
                "optionsNameFQ": "r_newsletterEmpfOptions"
            },
            "height": "auto",
            "width": "400px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_PersonListUtils.newsletterEmpfErstellenExCommand",
                    "bindToFQ": "functions.$f_PersonListUtils.newsletterEmpfErstellenExCommand"
                }
            }]
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "r_newsletterEmpfValidationGroup",
            "options": {
                "optionsName": "r_newsletterEmpfValidationGroupOptions",
                "optionsNameFQ": "r_newsletterEmpfValidationGroupOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "person-list.r_newsletterempfbezeichnung_caption",
            "binding": {},
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_newsletterEmpfBezeichnung",
            "options": {
                "optionsName": "r_newsletterEmpfBezeichnungOptions",
                "optionsNameFQ": "r_newsletterEmpfBezeichnungOptions"
            }
        });
        super.onConstructionFinished();
    }
}