import { 
  FrameworkConfiguration,
  PLATFORM
} from "aurelia-framework";
import "./styles/styles.less";

export function configure(config: FrameworkConfiguration) {
  config
    .globalResources(PLATFORM.moduleName("./attributes/icon/fa-icon-attribute"))
    .globalResources(PLATFORM.moduleName("./attributes/animation/animation-attribute"))
    .globalResources(PLATFORM.moduleName("./attributes/translation/translation-attribute"))
    .globalResources(PLATFORM.moduleName("./attributes/grid-size/grid-size-attribute"))
    .globalResources(PLATFORM.moduleName("./attributes/animation-tracker/animation-tracker-attribute"))
    .globalResources(PLATFORM.moduleName("./value-converters/translation/translation-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/max-text-length/max-text-length-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/sort/sort-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/format/format-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/file-download/file-download-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/background-image/background-image-value-converter"));
}
