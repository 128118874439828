import { autoinject, bindable } from "aurelia-framework";
import { GlobalizationService } from '../../../framework/base/export';
import { IdxNumberBoxComponent } from '../../interfaces/export';

@autoinject
export class AnzAusdrucke {
  constructor(
    private globalizationService: GlobalizationService
  ) {}

  @bindable options: any;
  anzAusdrucke: IdxNumberBoxComponent;

  anzNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 1,
    max: 5,
    value: 1,
    showSpinButtons: true,
    width: "100px",
    format: this.globalizationService.getFormatterParser("n0"),
    onValueChanged: (e) => {
      if (!this.options || !this.options.onValueChanged) {
        return;
      }

      this.options.onValueChanged({
        value: e.component.option("value")
      });
    }
  }

  activate(e) {
    if (e && e.options) {
      this.options = e.options;
    }
  }
  bind() {
    if (this.options && this.options.onInitialized) {
      this.options.onInitialized({
        component: this,
        options: this.options
      });
    }
  }
  unbind() {
    if (this.options && this.options.onDisposed) {
      this.options.onDisposed({
        component: this
      });
    }
  }

  getValue(): number{
    if (!this.anzAusdrucke) {
      return 1;
    }

    return this.anzAusdrucke.instance.option("value");
  }
  setValue(value) {
    if (!this.anzAusdrucke) {
      return;
    }

    this.anzAusdrucke.setOption({
      value: value
    });
  }
}
