import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  OnLagerkorrekturVerbuchenEvent
} from "../../../../framework-data/events";

import {
  StatusService
} from "../../../services/export";
import {
  Statustyp
} from "../../../enumerations/export"

@autoinject
@transient()
export class LagerkorrekturEditUtils {
  constructor(
    private status: StatusService,
    private webEvent: WebEventService,
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;
    const offenStatus = this.status.getStatusByTyp(Statustyp.Offen);

    form.models.onLoaded.register((args) => {
      if (args.model.id == "$m_Lagerkorrektur") {
        this.doVerbuchen.isEnabled = args.data.IdStatus == offenStatus.Id;
      }

      return Promise.resolve();
    });
  }

  doVerbuchen: ICommandData = {
    id: "doVerbuchen",
    idCategory: "lagerkorrektur",
    title: "lagerkorrektur.verbuchen",
    icon: "envelope-o",
    isEnabled: false,
    execute: async () => {
      const result = await this.form.saveIfDirty();
      if (!result.isValid) {
        return;
      }

      const data: any = this.form.models.data.$m_Lagerkorrektur;

      this.webEvent
        .execute(new OnLagerkorrekturVerbuchenEvent({
          IdLagerkorrektur: data.Id
        }), true)
        .then(() => {
          this.form.models.reloadAll();
        });
    }
  }
}
