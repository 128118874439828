import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    IntrastatSyncListUtils
} from "./intrastat-sync-list-utils";

@fwx.autoinject
export class IntrastatSyncListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_IntrastatSyncListUtils: IntrastatSyncListUtils) {
        super(element, formBaseImport);
        this.id = "intrastat-sync-list";
        this.title = "intrastat-sync-list.intrastat-sync-list_caption";
        this.addModel({
            "id": "$m_IntrastatSync",
            "webApiAction": "ERP/Stammdaten/IntrastatSync",
            "webApiExpand": {
                'Firma': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_IntrastatSyncListUtils.showSynchronsierenPopupCommand",
                "bindToFQ": "functions.$f_IntrastatSyncListUtils.showSynchronsierenPopupCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_IntrastatSyncListUtils", $f_IntrastatSyncListUtils, "functions.$f_IntrastatSyncListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_intrastatSyncTyp",
                "caption": "intrastat-sync-list.r_intrastatsynctyp_caption",
                "bindTo": "Typ",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.IntrastatSyncTyp"
            }, {
                "id": "r_intrastatSyncDatumVon",
                "caption": "intrastat-sync-list.r_intrastatsyncdatumvon_caption",
                "bindTo": "DatumVon",
                "width": "120px",
                "format": "d"
            }, {
                "id": "r_intrastatSyncDatumBis",
                "caption": "intrastat-sync-list.r_intrastatsyncdatumbis_caption",
                "bindTo": "DatumBis",
                "width": "120px",
                "format": "d"
            }, {
                "id": "r_intrastatSyncFirma",
                "caption": "intrastat-sync-list.r_intrastatsyncfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_intrastatSyncStatus",
                "caption": "intrastat-sync-list.r_intrastatsyncstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_intrastatSyncGridToolbarOptions",
                "optionsNameFQ": "r_intrastatSyncGridToolbarOptions"
            },
            "caption": "intrastat-sync-list.r_intrastatsyncgrid_caption",
            "binding": {
                "dataContext": "$m_IntrastatSync"
            },
            "dataModel": "$m_IntrastatSync",
            "editUrl": "ERP/Stammdaten/IntrastatSync",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_intrastatSyncGrid",
            "options": {
                "optionsName": "r_intrastatSyncGridOptions",
                "optionsNameFQ": "r_intrastatSyncGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_intrastatSyncPopup",
            "options": {
                "optionsName": "r_intrastatSyncPopupOptions",
                "optionsNameFQ": "r_intrastatSyncPopupOptions"
            },
            "caption": "intrastat-sync-list.r_intrastatsyncpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_IntrastatSyncListUtils.doSynchronisierenCommand",
                    "bindToFQ": "functions.$f_IntrastatSyncListUtils.doSynchronisierenCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "intrastatSyncTyp",
            "filters": [],
            "caption": "intrastat-sync-list.r_intrastatsynctyp_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_intrastatSyncTyp",
            "options": {
                "optionsName": "r_intrastatSyncTypOptions",
                "optionsNameFQ": "r_intrastatSyncTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "intrastat-sync-list.r_firma_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "intrastat-sync-list.r_datumvon_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datumVon",
            "options": {
                "optionsName": "r_datumVonOptions",
                "optionsNameFQ": "r_datumVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "intrastat-sync-list.r_datumbis_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datumBis",
            "options": {
                "optionsName": "r_datumBisOptions",
                "optionsNameFQ": "r_datumBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}