import * as fwx from "../../../../framework/forms/form-export";
import {
    ArbeitszeitBenutzerZuteilungEditUtils
} from "./arbeitszeit-benutzer-zuteilung-edit-utils";

@fwx.autoinject
export class ArbeitszeitBenutzerZuteilungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ArbeitszeitBenutzerZuteilungEditUtils: ArbeitszeitBenutzerZuteilungEditUtils) {
        super(element, formBaseImport);
        this.id = "arbeitszeit-benutzer-zuteilung-edit";
        this.title = "arbeitszeit-benutzer-zuteilung-edit.arbeitszeit-benutzer-zuteilung-edit_caption";
        this.addModel({
            "id": "$m_ArbeitszeitBenutzerZuteilung",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitBenutzerZuteilung",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArbeitszeitBenutzerZuteilungEditUtils.doNeuerMitarbeiterstatus",
                "bindToFQ": "functions.$f_ArbeitszeitBenutzerZuteilungEditUtils.doNeuerMitarbeiterstatus"
            }
        });
        this.addFunction("$f_ArbeitszeitBenutzerZuteilungEditUtils", $f_ArbeitszeitBenutzerZuteilungEditUtils, "functions.$f_ArbeitszeitBenutzerZuteilungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "arbeitszeit-benutzer-zuteilung-edit.r_arbeitszeitzuteilungbenutzer_caption",
            "isReadOnlyExpression": "models.data.$m_ArbeitszeitBenutzerZuteilung.Id",
            "binding": {
                "dataContext": "$m_ArbeitszeitBenutzerZuteilung",
                "bindTo": "IdBenutzer",
                "bindToFQ": "models.data.$m_ArbeitszeitBenutzerZuteilung.IdBenutzer"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_arbeitszeitZuteilungBenutzer",
            "options": {
                "optionsName": "r_arbeitszeitZuteilungBenutzerOptions",
                "optionsNameFQ": "r_arbeitszeitZuteilungBenutzerOptions"
            }
        });
        super.onConstructionFinished();
    }
}