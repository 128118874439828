import {
  autoinject,
  bindable,
  computedFrom,
  BindingEngine,
  TaskQueue
} from "aurelia-framework";

import { IToolbarOptions } from "../../elements/toolbar/toolbar-options";

@autoinject
export class View {
  constructor(
    private element: Element,
    private bindingEngine: BindingEngine,
    private taskQueue: TaskQueue
  ) { }

  @bindable createToolbar: boolean = true;
  @bindable view;

  @computedFrom("createToolbar")
  get className(): string {
    if (this.createToolbar) {
      return "t--view-with-toolbar";
    }
  }

  @computedFrom("view.controller.currentViewModel.toolbarOptions")
  get toolbarOptions(): IToolbarOptions {
    if (!this.view || !this.view.controller || !this.view.controller.currentViewModel) {
      return null;
    }

    return this.view.controller.currentViewModel.toolbarOptions;
  }

  bind() {
    this.element.classList.remove("t--view-attached");
    this.element.querySelector(".t--view-content").classList.remove("t--view-content-attached");

  }
  attached() {
    setTimeout(() => {
      this.element.classList.add("t--view-attached")
      this.element.querySelector(".t--view-content").classList.add("t--view-content-attached");
    }, 100);
  }
}