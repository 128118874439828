import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase, ICommandData
} from "../../../../framework/forms/export";
import { WebEventService, LocalizationService } from '../../../../framework/base/export';
import { OnNewsletterAnmeldungStatusRequestEvent, OnNewsletterAnmeldungStatusRequest, OnPersonWebshopEvent } from '../../../../framework-data/events';
import { DxCustomizeService } from '../../../services/dx-customize-service';
import { BriefService } from '../../../services/export';

@autoinject
@transient()
export class PersonEditUtils {
  constructor(
    private webEventService: WebEventService,
    private dxCustomizeService: DxCustomizeService,
    private briefService: BriefService,
    private localizationService: LocalizationService
  ) { }

  form: FormBase;

  statusNewsletter: string;

  webshopAktivierenCommand: ICommandData = {
    id: "webshopAktivieren",
    title: "person.webshop_aktivieren",
    sortIndex: 100,
    idCategory: "webshop",
    isVisibleExpression: "!models.data.$m_Person.IsWebshopAktiv",
    execute: async () => {
      this.changeWebshopStatus(0);
    }
  };
  webshopDeaktivierenCommand: ICommandData = {
    id: "webshopDeaktivieren",
    title: "person.webshop_deaktivieren",
    sortIndex: 101,
    idCategory: "webshop",
    isVisibleExpression: "models.data.$m_Person.IsWebshopAktiv",
    execute: async () => {
      this.changeWebshopStatus(1);
    }
  };
  webshopNeuesPasswortCommand: ICommandData = {
    id: "webshopNeuesPasswort",
    title: "person.webshop_neues_passwort",
    sortIndex: 102,
    idCategory: "webshop",
    isVisibleExpression: "models.data.$m_Person.IsWebshopAktiv",
    execute: async () => {
      this.changeWebshopStatus(2);
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.callOnBind(() => {
      this.dxCustomizeService.addTelefon(this.form["r_telefonOptions"]);
      this.dxCustomizeService.addTelefon(this.form["r_mobiltelefonOptions"]);
      this.dxCustomizeService.addBrief(this.form["r_emailOptions"], async () => {
        if (!this.form.models.data.$m_Person.Email) {
          DevExpress.ui.notify(
            this.localizationService.translateOnce("person.email-fehlt"),
            "error",
            3000
          );

          return;
        }

        const saveResult = await this.form.saveIfDirty();
        if (!saveResult.isValid) {
          return;
        }

        this.briefService.createAndGoToBrief(this.form.models.data.$m_Person.Id);
      });
    });

    this.form.focusFirstEnabledEditor = () => {
      const anrede = form["r_anrede"];
      if (anrede && anrede.instance) {
        anrede.instance.focus();
      }
    };

    this.form.models.onLoaded.register(r => {
      if (r.model.id == "$m_Person") {
        this.checkNewsletterAnmeldungStatus();
      }

      return Promise.resolve();
    });
  }

  private async checkNewsletterAnmeldungStatus() {
    this.statusNewsletter = null;

    const data = this.form.models.data.$m_Person;
    if (!data || !data.Email) {
      return;
    }

    const args = new OnNewsletterAnmeldungStatusRequestEvent({
      Email: data.Email
    });

    const result: OnNewsletterAnmeldungStatusRequest = await this.webEventService.execute(args);
    if (!result) {
      return;
    }

    this.statusNewsletter = result.Status;
  }
  private async changeWebshopStatus(status) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    await this.webEventService.execute(new OnPersonWebshopEvent({
      IdPerson: this.form.models.data.$m_Person.Id,
      StatusWechselTyp: status
    }), true);

    await this.form.reloadIfServerHasNewVersion();
  }
}
