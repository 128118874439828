import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuKontoFilterEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-konto-filter-edit";
        this.title = "fibu-konto-filter-edit.fibu-konto-filter-edit_caption";
        this.addModel({
            "id": "$m_FibuKontoFilter",
            "webApiAction": "ERP/Fibu/KontoFilter",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "fibu-konto-filter-edit.r_steuertyp_caption",
            "binding": {
                "dataContext": "$m_FibuKontoFilter",
                "bindTo": "SteuerTyp",
                "bindToFQ": "models.data.$m_FibuKontoFilter.SteuerTyp"
            },
            "validationRules": [],
            "id": "r_steuerTyp",
            "options": {
                "optionsName": "r_steuerTypOptions",
                "optionsNameFQ": "r_steuerTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuersatz",
            "filters": [],
            "caption": "fibu-konto-filter-edit.r_steuersatz_caption",
            "binding": {
                "dataContext": "$m_FibuKontoFilter",
                "bindTo": "IdSteuersatz",
                "bindToFQ": "models.data.$m_FibuKontoFilter.IdSteuersatz"
            },
            "validationRules": [],
            "id": "r_steuersatz",
            "options": {
                "optionsName": "r_steuersatzOptions",
                "optionsNameFQ": "r_steuersatzOptions"
            }
        });
        super.onConstructionFinished();
    }
}