import { autoinject, bindable, observable, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../../framework/base/export";
import { RestService } from "./../../../../framework/base/services/rest-service";

@autoinject
export class BestellungStreckengeschaeft {
  constructor(
    private _restService: RestService) { }

  @bindable @observable idAuftrag: number;
  lieferadresse: any;
  lieferadresseAnsprechperson: any;
  isVisible: boolean = false;
  scopeContainer: ScopeContainer;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
    if (this.idAuftrag) {
      this.loadData();
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idAuftragChanged() {
    this.loadData();
  }

  private async loadData() {
    if (this.idAuftrag) {
      const auftrag = await this._restService.get({
        url: `${this._restService.getWebApiUrl("ERP/Verkauf/Auftrag")}/${this.idAuftrag}`,
        getOptions: {
          expand: {
            LieferortAdresse: {
              columns: ["Firmenbez1", "Firmenbez2", "Firmenbez3", "Strasse", "Ort", "PLZ", "IdLand"],
              expand: {
                Land: {
                  columns: ["Bezeichnung"]
                }
              }
            },
            LieferortAnsprechperson: {
              columns: ["IdAnrede", "Vorname", "Nachname"],
              expand: {
                Anrede: {
                  columns: ["Bezeichnung"]
                }
              }
            }
          }
        }
      });
      if (auftrag.IsStreckengeschaeft) {
        this.lieferadresse = auftrag.LieferortAdresse;
        this.lieferadresseAnsprechperson = auftrag.LieferortAnsprechperson;
      }
    } else {
      this.lieferadresse = null;
      this.lieferadresseAnsprechperson = null;
    }
    this.isVisible = this.lieferadresse != null;
  }
}
