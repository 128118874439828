import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    IntrastatSyncEditUtils
} from "./intrastat-sync-edit-utils";

@fwx.autoinject
export class IntrastatSyncEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_IntrastatSyncJobEditUtils: IntrastatSyncEditUtils) {
        super(element, formBaseImport);
        this.id = "intrastat-sync-edit";
        this.title = "intrastat-sync-edit.intrastat-sync-edit_caption";
        this.addModel({
            "id": "$m_IntrastatSync",
            "webApiAction": "ERP/Stammdaten/IntrastatSync",
            "webApiExpand": {
                'Firma': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                },
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_IntrastatSyncJobEditUtils.doSynchronisierungStarten",
                "bindToFQ": "functions.$f_IntrastatSyncJobEditUtils.doSynchronisierungStarten"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_IntrastatSyncJobEditUtils", $f_IntrastatSyncJobEditUtils, "functions.$f_IntrastatSyncJobEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "intrastatSyncTyp",
            "filters": [],
            "caption": "intrastat-sync-edit.r_synctyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_IntrastatSync.Typ"
            },
            "validationRules": [],
            "id": "r_syncTyp",
            "options": {
                "optionsName": "r_syncTypOptions",
                "optionsNameFQ": "r_syncTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "intrastat-sync-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_IntrastatSync.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "intrastat-sync-edit.r_datumvon_caption",
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "DatumVon",
                "bindToFQ": "models.data.$m_IntrastatSync.DatumVon"
            },
            "validationRules": [],
            "id": "r_datumVon",
            "options": {
                "optionsName": "r_datumVonOptions",
                "optionsNameFQ": "r_datumVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "intrastat-sync-edit.r_datumbis_caption",
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "DatumBis",
                "bindToFQ": "models.data.$m_IntrastatSync.DatumBis"
            },
            "validationRules": [],
            "id": "r_datumBis",
            "options": {
                "optionsName": "r_datumBisOptions",
                "optionsNameFQ": "r_datumBisOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "intrastat-sync-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "Firma.Bezeichnung",
                "bindToFQ": "models.data.$m_IntrastatSync.Firma.Bezeichnung",
                "propertyPrefix": "Firma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 4,
            "showClearButton": false,
            "caption": "intrastat-sync-edit.r_dmslink_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_IntrastatSync",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_IntrastatSync.DMSLink"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_dmslink",
            "options": {
                "optionsName": "r_dmslinkOptions",
                "optionsNameFQ": "r_dmslinkOptions"
            }
        });
        super.onConstructionFinished();
    }
}