
import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  RestService
} from "../../framework/base/export";
import {
  IStartupInfo
} from "../interfaces/export";

@autoinject
export class StartupService {
  constructor(
    private rest: RestService,
    private eventAggregator: EventAggregator
  ) { }

  startupInfo: IStartupInfo;

  loadStartupInfo(autoLoadMandantDaten: boolean = true): Promise<IStartupInfo> {
    return this.rest.get({
      url: this.rest.getApiUrl("ERP/Startup/Info")
    }).then(r => {
      this.startupInfo = r;

      this.eventAggregator.publish(
        "startupInfo:loaded", {
          startupInfo: r,
          autoLoadMandantDaten: autoLoadMandantDaten
        });

      return r;
    });
  }
}
