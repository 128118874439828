import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    LadelisteEditUtils
} from "./ladeliste-edit-utils";

@fwx.autoinject
export class LadelisteEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_LadelisteEditUtils: LadelisteEditUtils) {
        super(element, formBaseImport);
        this.id = "ladeliste-edit";
        this.title = "ladeliste-edit.ladeliste-edit_caption";
        this.addModel({
            "id": "$m_Ladeliste",
            "webApiAction": "ERP/Verkauf/Ladeliste",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Ladelistenposition",
            "webApiAction": "ERP/Verkauf/Ladelistenposition",
            "webApiExpand": {
                'Lieferschein': {
                    'expand': {
                        'Kunde': {
                            'expand': {
                                'Adresse': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                }
            },
            "webApiWhere": [
                ["Status.Typ", "1"],
                ["IdLadelistenposition", "null"]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "IdLadeliste",
                "webApiCustomValue": "models.data.$m_Ladeliste.Id"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LadelisteEditUtils.showImportLieferscheinPopupCommand",
                "bindToFQ": "functions.$f_LadelisteEditUtils.showImportLieferscheinPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LadelisteEditUtils.lieferscheinEntfernenCommand",
                "bindToFQ": "functions.$f_LadelisteEditUtils.lieferscheinEntfernenCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_LadelisteEditUtils", $f_LadelisteEditUtils, "functions.$f_LadelisteEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "ladeliste-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Ladeliste.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "ladeliste-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Ladeliste.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "ladeliste-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Ladeliste.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "ladeliste-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "ladeliste-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_ladepNummer",
                "caption": "ladeliste-edit.r_ladepnummer_caption",
                "bindTo": "Lieferschein.Nummer",
                "width": "80px"
            }, {
                "id": "r_ladepDatum",
                "caption": "ladeliste-edit.r_ladepdatum_caption",
                "bindTo": "Lieferschein.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_ladepKundenNr",
                "caption": "ladeliste-edit.r_ladepkundennr_caption",
                "bindTo": "Lieferschein.Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_ladepFirmenbez1",
                "caption": "ladeliste-edit.r_ladepfirmenbez1_caption",
                "bindTo": "Lieferschein.Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_ladepStrasse",
                "caption": "ladeliste-edit.r_ladepstrasse_caption",
                "bindTo": "Lieferschein.Kunde.Adresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_ladepPLZ",
                "caption": "ladeliste-edit.r_ladepplz_caption",
                "bindTo": "Lieferschein.Kunde.Adresse.PLZ",
                "width": "80px"
            }, {
                "id": "r_ladepOrt",
                "caption": "ladeliste-edit.r_ladeport_caption",
                "bindTo": "Lieferschein.Kunde.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_ladepGewichtBrutto",
                "caption": "ladeliste-edit.r_ladepgewichtbrutto_caption",
                "bindTo": "Lieferschein.GewichtBrutto",
                "width": "80px",
                "format": "n0"
            }],
            "optionsToolbar": {
                "optionsName": "r_ladelistenpositionGridToolbarOptions",
                "optionsNameFQ": "r_ladelistenpositionGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Ladeliste"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_Ladelistenposition",
                "bindTo": "IdLadeliste",
                "bindToFQ": "models.data.$m_Ladelistenposition.IdLadeliste"
            },
            "dataModel": "$m_Ladeliste",
            "addShortscuts": true,
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_ladelistenpositionGrid",
            "options": {
                "optionsName": "r_ladelistenpositionGridOptions",
                "optionsNameFQ": "r_ladelistenpositionGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "ladeliste-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Ladeliste.Id > 0",
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Ladeliste.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Ladeliste.IdFirma"
            }],
            "filters": [],
            "caption": "ladeliste-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Ladeliste.Id > 0",
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Ladeliste.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "spediteur",
            "filters": [],
            "caption": "ladeliste-edit.r_spediteur_caption",
            "binding": {
                "dataContext": "$m_Ladeliste",
                "bindTo": "IdSpediteur",
                "bindToFQ": "models.data.$m_Ladeliste.IdSpediteur"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_spediteur",
            "options": {
                "optionsName": "r_spediteurOptions",
                "optionsNameFQ": "r_spediteurOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_lieferscheinEditPopup",
            "options": {
                "optionsName": "r_lieferscheinEditPopupOptions",
                "optionsNameFQ": "r_lieferscheinEditPopupOptions"
            },
            "caption": "ladeliste-edit.r_lieferscheineditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_LadelisteEditUtils.importLieferscheinpositionCommand",
                    "bindToFQ": "functions.$f_LadelisteEditUtils.importLieferscheinpositionCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_liefLieferscheinNummer",
                "caption": "ladeliste-edit.r_lieflieferscheinnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_liefDatum",
                "caption": "ladeliste-edit.r_liefdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_liefKundenNummer",
                "caption": "ladeliste-edit.r_liefkundennummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefFirmenbez1",
                "caption": "ladeliste-edit.r_lieffirmenbez1_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_liefStrasse",
                "caption": "ladeliste-edit.r_liefstrasse_caption",
                "bindTo": "Kunde.Adresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_liefPLZ",
                "caption": "ladeliste-edit.r_liefplz_caption",
                "bindTo": "Kunde.Adresse.PLZ",
                "width": "80px"
            }, {
                "id": "r_liefOrt",
                "caption": "ladeliste-edit.r_liefort_caption",
                "bindTo": "Kunde.Adresse.Ort",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_lieferscheinGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Lieferschein"
            },
            "dataModel": "$m_Lieferschein",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheinGrid",
            "options": {
                "optionsName": "r_lieferscheinGridOptions",
                "optionsNameFQ": "r_lieferscheinGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}