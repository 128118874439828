import { autoinject } from "../../framework/forms/form-export";
import { WebEventService } from '../../framework/base/export';
import { OnCreateZugriffHistorieEvent } from '../../framework-data/events';

@autoinject
export class ZugriffHistorieService {
  constructor(
    private webEventService: WebEventService
  ) {}

  async createZugriffHistorie(idEntitaet: number) {
    await this.webEventService.execute(new OnCreateZugriffHistorieEvent({
      IdEntitaet: idEntitaet
    }));
  }
}
