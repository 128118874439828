import { autoinject, bindable, observable, Scope, TaskQueue, computedFrom } from "aurelia-framework";
import { RestService, GlobalizationService, ScopeContainer, LocalizationService } from '../../../framework/base/export';
import { IdxPopoverComponent } from '../../interfaces/export';
import { ModelUtilsService, FormBase } from '../../../framework/forms/export';

//TODO Check ReadOnly

@autoinject
export class MhdChargeMengeAusw {
  constructor(
    private restService: RestService,
    private globalizationService: GlobalizationService,
    private localizationService: LocalizationService,
    private modelUtilsService: ModelUtilsService,
    private taskQueue: TaskQueue
  ) {}

  mhdChargeList: any[] = [];
  mhdChargeBestandList: any[] = [];
  bestandList: any[] = [];

  @bindable @observable mainModel: any;
  @bindable @observable idArtikel: number;
  @bindable @observable idLager: number;
  @bindable @observable idEinheit: number;
  @bindable isReadOnly: boolean;
  @bindable isRequired: boolean;
  @bindable mengeZiel: number;
  @bindable mengeProperty: string;

  @observable artikel: any;
  
  mhdChargeContainer: any;
  hasMhdCharge: boolean;
  umrechner: number;
  itemRowClass: string;
  form: FormBase;

  scope: Scope;
  scopeContainer: ScopeContainer;

  mengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this.globalizationService.getNumberFormat("n2"),
    min: 0,
    onValueChangedByUser: (e) => {
      this.fireMengeEditorValueChanged(e.value);
    },
    bindingOptions: {
      readOnly: "isReadOnlyMenge"
    }
  };
  openPopupButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-pencil",
    onClick: (e) => {
      const compareMHDCharge = (item1, item2) => {
        let isEquals = true;

        if (isEquals && (item1.Charge || null) != (item2.Charge || null)) {
          isEquals = false;
        }

        if (isEquals) {
          const mhd1 = item1.MHD ? item1.MHD.getTime() : null;
          const mhd2 = item2.MHD ? item2.MHD.getTime() : null;

          isEquals = mhd1 == mhd2;
        }

        return isEquals;
      }

      this.mhdChargeList = this.mhdChargeContainer.MHDChargeEintraege.map(c => {
        const bestand = this.bestandList.find(d => compareMHDCharge(c, d));

        return {
          Ref: c,
          MHD: c.MHD,
          Charge: c.Charge,
          MengeVerpEH: c.MengeVerpEH,
          MaxMengeVerpEH: bestand 
            ? Math.round(bestand.Bestand / (this.umrechner || 1) * 100) / 100
            : 0
        };
      });

      this.mhdChargeBestandList = this.bestandList.filter(c => {
        const exists = this.mhdChargeList.some(d => compareMHDCharge(c, d));
        return !exists;
      }).map(c => {
        return {
          MHD: c.MHD,
          Charge: c.Charge,
          MengeVerpEH: Math.round(c.Bestand / (this.umrechner || 1) * 100) / 100
        };
      });

      this.popover.instance.show(e.event.currentTarget);
    }
  }
  mhdChargeMengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this.globalizationService.getNumberFormat("n2"),
    min: 0,
    width: "100%",
    onValueChangedByUser: (e) => {
      const item = e.model.bindingContext.item;

      if (item.MaxMengeVerpEH < e.value) {
        this.taskQueue.queueMicroTask(() => {
          item.MengeVerpEH = item.MaxMengeVerpEH;
        });
      }
    },
    bindingOptions: {
      value: "item.MengeVerpEH",
      readOnly: "isReadOnlyChecked"
    }
  }
  useButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-plus",
    width: "100%",
    onClick: (e) => {
      const item = e.model.bindingContext.item;
      const index = this.mhdChargeBestandList.indexOf(item);

      const maxMengeVerp = Math.round(item.MengeVerpEH / (this.umrechner || 1) * 100) / 100;

      const mengeVerp = this.mhdChargeList.reduce((p, c) => {
        return p + c.MengeVerpEH;
      }, 0);
      
      let mengeRest = this.mengeZiel - mengeVerp;
      if (mengeRest < 0) {
        mengeRest = 0;
      }
      else if (mengeRest > maxMengeVerp) {
        mengeRest = maxMengeVerp;
      }

      this.mhdChargeList.push({
        MHD: item.MHD,
        Charge: item.Charge,
        MaxMengeVerpEH: maxMengeVerp,
        MengeVerpEH: (mengeRest || null)
      });

      if (index >= 0) {
        this.mhdChargeBestandList.splice(index, 1);
      }
    },
    bindingOptions: {
      disabled: "isReadOnlyChecked"
    }
  }
  submitButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("mhd_charge_menge.uebernehmen"),
    onClick: () => {
      let mengeVerpEH = 0;

      const deleteList = [];
      this.mhdChargeList.forEach(c => {
        let remove = false;

        if (!c.MengeVerpEH) {
          remove = true;
        }

        if (remove) {
          if (c.Ref) {
            deleteList.push(c.Ref);
          }
        } else {
          let ref = c.Ref;
          if (!ref) {
            ref = {};
            this.mhdChargeContainer.MHDChargeEintraege.push(ref)
          };

          ref.MHD = c.MHD;
          ref.Charge = c.Charge;
          ref.MengeVerpEH = c.MengeVerpEH;

          mengeVerpEH += c.MengeVerpEH;
        }
      });

      deleteList.forEach(c => {
        const index = this.mhdChargeContainer.MHDChargeEintraege.indexOf(c);
        if (index < 0) {
          return;
        }

        this.mhdChargeContainer.MHDChargeEintraege.splice(index, 1);
      });

      this.mainModel.MHDChargeContainer = this.mhdChargeContainer;

      this.mainModel[this.mengeProperty] = mengeVerpEH;
      this.modelUtilsService.setDirty(this.mainModel);
      this.fireMengeEditorValueChanged(mengeVerpEH);

      this.popover.instance.hide();
    },
    bindingOptions: {
      disabled: "isReadOnlyChecked"
    }
  }
  cancelButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("mhd_charge_menge.abbrechen"),
    onClick: () => {
      this.popover.instance.hide();
    }
  }
  popover: IdxPopoverComponent;
  popoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: "500px"
  }

  @computedFrom("isReadOnly", "isReadOnlyInternal")
  get isReadOnlyChecked() {
    if (this.isReadOnly) {
      return true;
    }
    if (this.isReadOnlyInternal) {
      return true;
    }

    return false;
  }
  @computedFrom("isReadOnlyInternal", "hasMhdCharge")
  get isReadOnlyMenge() {
    if (this.isReadOnlyInternal) {
      return true;
    }
    if (this.hasMhdCharge) {
      return true;
    }

    return false;
  }
  @computedFrom("idArtikel", "mainModel", "mainModel.CanSave")
  get isReadOnlyInternal() {
    if (!this.idArtikel) {
      return true;
    }
    if (!this.mainModel) {
      return true;
    }
    if (this.mainModel.CanSave != void(0) && !this.mainModel.CanSave) {
      return true;
    }

    return false;
  }

  bind(bindingContext) {
    this.form = bindingContext;

    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    if (!this.mengeProperty) {
      throw new Error("propertyMenge nicht definiert");
    }
    if (this.isRequired) {
      this.mengeNumberBoxOptions["validators"] = [{
        type: "required",
        message: "Pflichtfeld",
      }];
    }

    this.mengeNumberBoxOptions.bindingOptions.value = `mainModel.${this.mengeProperty}`;

    this.loadArtikelData();
    this.loadLagerData();
    this.loadEinheit();
    this.loadMHDChargeContainer();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  mainModelChanged() {
    this.loadMHDChargeContainer();
  }
  idArtikelChanged() {
    this.loadArtikelData();
  }
  artikelChanged() {
    this.loadLagerData();
  }
  idLagerChanged() {
    this.loadLagerData();
  }
  idEinheitChanged() {
    this.loadEinheit();
  }

  private async loadMHDChargeContainer() {
    this.mhdChargeContainer = null;    

    if (!this.mainModel) {
      return;
    }

    if (this.mainModel.MHDChargeContainer) {
      this.mhdChargeContainer = this.mainModel.MHDChargeContainer;
    } else if (this.mainModel.IdMHDChargeContainer) {
      this.mhdChargeContainer = await this.restService.get({
        url: this.restService.getWebApiUrl("ERP/Lager/MHDChargeContainer/".concat(this.mainModel.IdMHDChargeContainer)),
        getOptions: {
          expand: {
            "MHDChargeEintraege": null
          }
        }
      });
    } else {
      this.mhdChargeContainer = {
        MHDChargeEintraege: []
      };
    }
  }
  private async loadArtikelData() {
    if (!this.idArtikel) {
      return;
    }

    this.artikel = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/Artikel/".concat(this.idArtikel.toString())),
      getOptions: {
        columns: ["Id", "SupportsMHD", "SupportsCharge"]
      }
    });

    this.hasMhdCharge = this.artikel.SupportsMHD || this.artikel.SupportsCharge;

    this.itemRowClass = this.artikel.SupportsMHD && this.artikel.SupportsCharge
      ? "mhd-charge-menge-ausw-item-row"
      : "mhd-menge-ausw-item-row";
  }
  private async loadLagerData() {
    this.bestandList = [];

    if (!this.artikel || !(this.artikel.SupportsMHD || this.artikel.SupportsCharge) || !this.idLager) {
      return;
    }

    this.bestandList = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/Lager/LagerbestandDetail"),
      getOptions: {
        columns: ["Id", "MHD", "Charge", "Bestand"],
        where: [["IdArtikel", this.artikel.Id], ["IdLager", this.idLager], ["Bestand", ">", 0]],
        orderBy: [{columnName: "MHD", sortOrder: 0}, {columnName: "Charge", sortOrder: 0}]
      }
    });
  }
  private async loadEinheit() {
    this.umrechner = 1;

    if (!this.idEinheit) {
      return;
    }

    const r = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/ArtikelEinheit/".concat(this.idEinheit.toString())),
      getOptions: {
        columns: ["Id", "Umrechner"]
      }
    });

    if (!r) {
      return;
    }

    this.umrechner = r.Umrechner;
  }
  private fireMengeEditorValueChanged(mengeVerpEH: number) {
    const model = this.form.models
      .getModels()
      .find(c => this.form.models.data[c.id] == this.mainModel);

    if (!model) {
      return;
    }

    this.form.onEditorValueChanged.fire({
      binding: {
        bindTo: this.mengeProperty,
        bindToFQ: this.mengeProperty,
        dataContext: model.id
      },
      value: mengeVerpEH
    });
  }
}
