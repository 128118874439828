import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    FibuSyncJobEditUtils
} from "./fibu-sync-job-edit-utils";

@fwx.autoinject
export class FibuSyncJobEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_FibuSyncJobEditUtils: FibuSyncJobEditUtils) {
        super(element, formBaseImport);
        this.id = "fibu-sync-job-edit";
        this.title = "fibu-sync-job-edit.fibu-sync-job-edit_caption";
        this.addModel({
            "id": "$m_FibuSyncJob",
            "webApiAction": "ERP/Fibu/SyncJob",
            "webApiExpand": {
                'Firma': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                },
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FibuSyncJobEditUtils.doSynchronisierungStarten",
                "bindToFQ": "functions.$f_FibuSyncJobEditUtils.doSynchronisierungStarten"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_FibuSyncJobEditUtils", $f_FibuSyncJobEditUtils, "functions.$f_FibuSyncJobEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuSyncTyp",
            "filters": [],
            "caption": "fibu-sync-job-edit.r_synctyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_FibuSyncJob",
                "bindTo": "SyncTyp",
                "bindToFQ": "models.data.$m_FibuSyncJob.SyncTyp"
            },
            "validationRules": [],
            "id": "r_syncTyp",
            "options": {
                "optionsName": "r_syncTypOptions",
                "optionsNameFQ": "r_syncTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "fibu-sync-job-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_FibuSyncJob",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_FibuSyncJob.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "fibu-sync-job-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_FibuSyncJob",
                "bindTo": "Firma.Bezeichnung",
                "bindToFQ": "models.data.$m_FibuSyncJob.Firma.Bezeichnung",
                "propertyPrefix": "Firma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 4,
            "showClearButton": false,
            "caption": "fibu-sync-job-edit.r_dmslink_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_FibuSyncJob",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_FibuSyncJob.DMSLink"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_dmslink",
            "options": {
                "optionsName": "r_dmslinkOptions",
                "optionsNameFQ": "r_dmslinkOptions"
            }
        });
        super.onConstructionFinished();
    }
}