import { autoinject, transient } from "aurelia-framework";
import { OnBestellungListBestaetigt, OnBestellungListBestaetigtEvent, OnBestellungListLieferdatumAendern, OnBestellungListLieferdatumAendernEvent } from "../../../../framework-data/events";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase } from "../../../../framework/forms/classes/form-base";
import { ICommandData } from "../../../../framework/forms/interfaces/command-data";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent, IdxDateBoxComponent, IdxPopupComponent } from "../../../interfaces/export";
import { StatusService } from "../../../services/status-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { WebEventService } from "./../../../../framework/base/services/web-event-service";
import { BelegExportService } from "./../../../services/beleg-export-service";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class BestellungListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _localizationService: LocalizationService,
    private _statusService: StatusService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_BestellungListUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_BestellungListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_bestellungGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Bestellung, keys);
    }
  };

  bestaetigenSetzenCommand: ICommandData = {
    id: "bestaetigenSetzen",
    idCategory: "bestellung",
    title: "bestellung.bestaetigen",
    icon: "star-o",
    sortIndex: 10,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_bestellungGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const bestellungGridData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (bestellungGridData.length == 0) {
        return;
      }

      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("bestellung.sure_bestaetigen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasBestaetigteEintraege = bestellungGridData
        .some((c) => c.IsBestaetigt);

      if (hasBestaetigteEintraege) {
        const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
          this._localizationService.translateOnce("bestellung.hinweis_bestaetigt"),
          this._localizationService.translateOnce("base.question")));

        if (!doExecute) {
          return;
        }
      }

      const idBestellungList = bestellungGridData.map((c) => c.Id);

      const args: OnBestellungListBestaetigt = {
        IdBestellungList: idBestellungList,
        IsBestaetigt: true
      };

      const webEventResult: OnBestellungListBestaetigt = await this._webEventService.execute(new OnBestellungListBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };

  bestaetigenEntfernenCommand: ICommandData = {
    id: "bestaetigenEntfernen",
    idCategory: "bestellung",
    title: "bestellung.nicht_bestaetigen",
    icon: "star",
    sortIndex: 10,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_bestellungGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const bestellungGridData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (bestellungGridData.length == 0) {
        return;
      }

      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("bestellung.sure_nicht_bestaetigen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasBestaetigteEintraege = bestellungGridData
        .some((c) => !c.IsBestaetigt);

      if (hasBestaetigteEintraege) {
        const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
          this._localizationService.translateOnce("bestellung.hinweis_nicht_bestaetigt"),
          this._localizationService.translateOnce("base.question")));

        if (!doExecute) {
          return;
        }
      }

      const idBestellungList = bestellungGridData.map((c) => c.Id);

      const args: OnBestellungListBestaetigt = {
        IdBestellungList: idBestellungList,
        IsBestaetigt: false
      };

      const webEventResult: OnBestellungListBestaetigt = await this._webEventService.execute(new OnBestellungListBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };

  lieferdatumAendernCommand: ICommandData = {
    id: "lieferdatumAendern",
    idCategory: "bestellung",
    title: "bestellung.lieferdatum_aendern",
    icon: "calculator",
    sortIndex: 10,
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_bestellungLieferdatumAendernPopup"];

      popup.instance.show();

      const dateBox: IdxDateBoxComponent = this.form["r_datum"];
      dateBox.setOption({
        value: null
      });

      return this.form.models.reloadAll();
    }
  };

  doLieferdatumAendernCommand: ICommandData = {
    id: "doLieferdatumAendern",
    title: "bestellung.lieferdatum_aendern",
    icon: "save",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_bestellungLieferdatumAendernPopup"];
      const dateBox: IdxDateBoxComponent = this.form["r_datum"];

      const datum = dateBox.instance.option("value");
      if (!datum) {
        DevExpress.ui.notify(this._localizationService.translateOnce("bestellung.datum_auswaehlen"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const idBestellungList = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      const args: OnBestellungListLieferdatumAendern = {
        IdBestellungList: idBestellungList,
        Lieferdatum: datum
      };

      const webEventResult: OnBestellungListLieferdatumAendern = await this._webEventService.execute(new OnBestellungListLieferdatumAendernEvent(args), true);
      this.form.models.reloadAll();
      popup.instance.hide();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Bestellung);
      return Promise.resolve();
    });
  }

  onLieferdatumCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.Lieferdatum || !e.data._LieferdatumTyp) {
      return;
    }

    if (e.data.IdStatus) {
      if (!this._statusService.isOffen(e.data.IdStatus)) {
        return;
      }
    }

    const cell: HTMLTableDataCellElement = e.cellElement;

    cell.style.backgroundColor = this.getLieferdatumTypFarbe(e.data._LieferdatumTyp);
    cell.style.color = "white";
  }

  private getLieferdatumTypFarbe(typ): string {
    switch (typ) {
      case 1: {
        return "#C0392B";
      }
      case 2: {
        return "#F39C12";
      }
      default: {
        return "";
      }
    }
  }
}
