import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitAnspruchsgruppeZuteilungEdit {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IArbeitszeitAnspruchsgruppeZuteilungEditOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  anspruchsgruppeList: any[];
  arbeitszeitAnspruchsgruppe: IArbeitszeitAnspruchsgruppe;

  arbeitszeitAnspruchsgruppeZuteilungEditPopup: Interfaces.IdxPopupComponent;
  arbeitszeitAnspruchsgruppeZuteilungEditPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitAnspruchsgruppeZuteilungEditPopupCommands: ICommandData[] = [{
    id: "doarbeitszeitAnspruchsgruppeZuteilungEdit",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitAnspruchsgruppeZuteilungEditPopup.instance.hide();
      this._options.callback(this.arbeitszeitAnspruchsgruppe);
    }
  }];

  arbeitszeitAnspruchsgruppeZuteilungEditValidation: any;
  arbeitszeitAnspruchsgruppeZuteilungEditValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  gueltigVonDateBox: Interfaces.IdxDateBoxComponent;
  gueltigVonDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitAnspruchsgruppe.GueltigVon",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  anspruchsgruppeSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "anspruchsgruppeList",
      value: "arbeitszeitAnspruchsgruppe.IdAnspruchsgruppe",
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitAnspruchsgruppeZuteilungEditToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.anspruchsgruppe",
      options: this.arbeitszeitAnspruchsgruppeZuteilungEditPopupOptions,
      commands: this.arbeitszeitAnspruchsgruppeZuteilungEditPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.loadAnspruchsgruppeDatasource();
  }

  async show(options: IArbeitszeitAnspruchsgruppeZuteilungEditOptions) {
    this._options = options;
    this.arbeitszeitAnspruchsgruppe = null;
    this.arbeitszeitAnspruchsgruppeZuteilungEditPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitAnspruchsgruppeZuteilungEditValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }

    if (this.arbeitszeitAnspruchsgruppe.GueltigVon < this._options.mitarbeiterstatus.Eintrittsdatum) {
      DevExpress.ui.notify(
        "Gültig von Datum darf nicht kleiner als Eintrittsdatum sein",
        "error",
        this.NOTIFY_TIMEOUT);
      return false;
    }

    return true;
  }

  private async loadAnspruchsgruppeDatasource() {
    this.anspruchsgruppeList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/anspruchsgruppe")
    });
  }
}

export interface IArbeitszeitAnspruchsgruppeZuteilungEditOptions {
  mitarbeiterstatus: IArbeitszeitMitarbeiterstatus,
  callback: { (arbeitszeitAnspruchsgruppe: IArbeitszeitAnspruchsgruppe): void };
}

export interface IArbeitszeitAnspruchsgruppe {
  IdAnspruchsgruppe: number;
  GueltigVon: Date;
}
