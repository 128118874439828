import {
  autoinject,
  OverrideContext,
  Scope,
  createOverrideContext
} from "aurelia-framework";

import {
  DataSourceService,
  GlobalizationService,
  ScopeContainer,
  LocalizationService
} from "../../../framework/base/export";
import {
  SimpleWidgetCreatorService
} from "../../../framework/forms/widget-services/simple-widget-creator-service";

import {
  ProdAuftragSearchService
} from "../../services/export";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ProdAuftragSearchResult {
  private _onProdAuftragSelected: { (idProdAuftrag: number): void };
  private _onProdAuftragSelectedCancel: { (): void };

  constructor(
    private dataSource: DataSourceService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private prodAuftragSearch: ProdAuftragSearchService,
    private simpleWidgetCreator: SimpleWidgetCreatorService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  searchValue: string = null;
  searchListDataSource: DevExpress.data.DataSource;

  searchResultDataGrid: Interfaces.IdxDataGridComponent;
  searchResultDataGridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    columns: [{
      caption: this.localization.translateOnce("prod-auftrag-search-result.nummer"),
      dataField: "Nummer",
      width: "80px"
    }, {
      caption: this.localization.translateOnce("prod-auftrag-search-result.datum"),
      dataField: "Datum",
      format: this.globalization.getFormatter("d"),
      width: "90px"
    }, {
      caption: this.localization.translateOnce("prod-auftrag-search-result.artikel-nummer"),
      dataField: "Artikel.Nummer",
      width: "80px"
    }, {
      caption: this.localization.translateOnce("prod-auftrag-search-result.artikel-bezeichnung"),
      dataField: "Artikel.Bezeichnung"
    }, {
      caption: this.localization.translateOnce("prod-auftrag-search-result.menge-verp-eh"),
      dataField: "MengeVerpEH",
      width: "80px"
    }, {
      caption: this.localization.translateOnce("prod-auftrag-search-result.einheit"),
      dataField: "Einheit.Einheit.Bezeichnung",
      width: "120px"
    }],
    onRowClick: (e) => {
      this._onProdAuftragSelected(e.key.Id);
      this.searchResultPopup.instance.hide();
    },
    filterRow: {
      visible: true
    },
    remoteOperations: {
      filtering: true,
      paging: true,
      sorting: true
    }
  }

  searchResultPopup: Interfaces.IdxPopupComponent;
  searchResultPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    onHidden: () => {
      this.searchValue = null;
      this._onProdAuftragSelectedCancel();
    }
  };

  searchTextBox: Interfaces.IdxTextBoxComponent;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this.localization.translateOnce("base.search"),
    onValueChanged: (e) => {
      this.reloadData();
      this.focusSearchTextBox();
    },
    bindingOptions: {
      value: "searchValue"
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.prodAuftragSearch.prodAuftragSearchResult = this;

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: "base.search",
      scopeContainer: this.scopeContainer,
      options: this.searchResultPopupOptions
    });

    this.searchListDataSource = this.dataSource.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Produktion/ProdAuftrag",
        webApiExpand: {
          Artikel: null,
          Einheit: { expand: { Einheit: null } }
        }
      },
      {
        getSearchText: () => {
          this;
          return this.searchValue;
        }
      }
    );

    this.searchResultDataGridOptions.dataSource = this.searchListDataSource;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup(onProjektSelected: { (idProjekt: number): void },
    onProjektSelectedCancel: { (): void },
    options: Interfaces.ISearchOptions, ) {

    this._onProdAuftragSelected = onProjektSelected;
    this._onProdAuftragSelectedCancel = onProjektSelectedCancel;
    this.searchValue = options.searchValue;

    if (this.searchResultDataGrid && this.searchResultDataGrid.instance) {
      const filter = this.searchResultDataGrid.instance.getCombinedFilter();
      if (filter) {
        this.searchResultDataGrid.instance.clearFilter();
      }
    }

    this.searchResultPopup.instance.show()
      .then(() => {
        this.focusSearchTextBox();
      });
  }

  private focusSearchTextBox() {
    const element: Element = this.searchTextBox.instance.element();
    const input = element.querySelector("input");
    
    input.focus();
    input.select();
  }
  private reloadData() {
    if (this.searchListDataSource.pageIndex() > 0) {
      this.searchListDataSource.pageIndex(0);
    }

    this.searchListDataSource.reload();
  }
}
