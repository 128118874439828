import { autoinject, bindable, observable } from "aurelia-framework";
import { WebEventService } from '../../../../framework/base/export';
import { OnFehlerRequestEvent, OnFehlerRequest } from '../../../../framework-data/events';

@autoinject
export class Fehler {
  private _timeout: any;

  constructor(
    private webEventService: WebEventService
  ) {}

  @bindable @observable idGeschaeftspartner: number;
  @bindable @observable idRechnungsort: number;
  @bindable @observable idArtikel: number;

  fehlerList: any[] = [];
  isVisible: boolean = false;

  idGeschaeftspartnerChanged() {
    this.loadFehler();
  }
  idRechnungsortChanged() {
    this.loadFehler();
  }
  idArtikelChanged() {
    this.loadFehler();
  }

  private loadFehler() {
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }

    this._timeout = setTimeout(async () => {
      const result: OnFehlerRequest = await this.webEventService.execute(new OnFehlerRequestEvent({
        IdArtikel: this.idArtikel,
        IdGeschaeftspartner: this.idGeschaeftspartner,
        IdRechnungsort: this.idRechnungsort
      }));

      this.fehlerList = result && result.FehlerList
        ? result.FehlerList
        : [];

      this.isVisible = this.fehlerList.length > 0;
    }, 100);
  }
}
