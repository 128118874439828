import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, Scope } from "aurelia-framework";
import { OnProjektTaetigkeitBuchungAnzahlAktiveBuchungen, OnProjektTaetigkeitBuchungAnzahlAktiveBuchungenEvent, OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequest, OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequestEvent } from "../../../framework-data/events";
import { DataSourceService, GlobalizationService, RestService, ScopeContainer, WebEventService } from "../../../framework/base/export";
import { AsyncService } from "../../../framework/base/services/async-service";
import { CustomEditPopup } from "../../../framework/forms/elements/custom-edit-popup/custom-edit-popup";
import { ListView } from "../../../framework/forms/elements/list-view/list-view";
import { IListViewOptions } from "../../../framework/forms/elements/list-view/list-view-options";
import { IdxPopoverComponent, IdxTextBoxComponent } from "../../interfaces/export";
import { HeaderCommandService, StartupService } from "../../services/export";

@autoinject
export class ProjektTaetigkeitBuchungCommand {
  private _buchungChangedSubscription: Subscription;

  constructor(
    private _restService: RestService,
    private _startupService: StartupService,
    private _headerCommandService: HeaderCommandService,
    private _dataSourceService: DataSourceService,
    private _webEventService: WebEventService,
    private _globalizationService: GlobalizationService,
    private _eventAggregator: EventAggregator,
    private _asyncService: AsyncService) {
    _headerCommandService.projektTaetigkeitBuchung = this;
  }

  scope: Scope;
  scopeContainer: ScopeContainer;

  buchungEditPopup: CustomEditPopup;

  aktuellList: IBuchung[] = [];
  historieList: IBuchung[] = [];
  vergangeneTageList = [];

  popover: IdxPopoverComponent;
  popoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: "auto",
    height: "auto",
    maxHeight: "75vh"
  };
  taetigkeitListView: ListView;
  taetigkeitListViewOptions: IListViewOptions = {
    showReloadButton: false,
    pageSize: 15,
    height: "460px",
    useDefaultListItemStyle: false,
    onItemClick: (e) => {
    }
  };
  vergangeneTageChartOptions: DevExpress.viz.dxChartOptions = {
    rotated: true,
    series: {
      argumentField: "Datum",
      valueField: "DauerStunden",
      type: "bar",
      label: {
        alignment: "left",
        backgroundColor: "transparent",
        position: "inside",
        visible: true,
        customizeText: (e) => {
          if (!e.value) {
            return null;
          }

          return this._globalizationService
            .format(e.value, "n2")
            .concat("h");
        }
      }
    },
    legend: {
      visible: false
    },
    size: {
      height: 250
    },
    argumentAxis: {
      type: "discrete",
      label: {
        format: this._globalizationService.getFormatter("d"),
        overlappingBehavior: "none"
      }
    },
    valueAxis: {
      allowDecimals: false,
      tickInterval: 1,
      visualRange: [0, 12],
      visualRangeUpdateMode: "keep"
    },
    bindingOptions: {
      dataSource: "vergangeneTageList"
    }
  };

  startButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-play",
    hint: "Start",
    width: "100%",
    onClick: (e) => {
      if (e.model && e.model.bindingContext && e.model.bindingContext.item) {
        this.start(e.model.bindingContext.item);
      }
    }
  };
  endeButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-stop",
    hint: "Ende",
    width: "100%",
    onClick: (e) => {
      if (e.model && e.model.bindingContext && e.model.bindingContext.aktuell) {
        this.ende(e.model.bindingContext.aktuell);
      }
    }
  };
  korrButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-pencil",
    hint: "Korrektur",
    width: "100%",
    onClick: (e) => {
      let id = null;

      if (e.model && e.model.bindingContext && e.model.bindingContext.aktuell) {
        id = e.model.bindingContext.aktuell.Id;
      } else if (e.model && e.model.bindingContext && e.model.bindingContext.historie) {
        id = e.model.bindingContext.historie.Id;
      } else if (e.model && e.model.bindingContext && e.model.bindingContext.item) {
        id = 0;
      }

      if (id == void (0)) {
        return;
      }

      this.buchungEditPopup.show({
        mappings: { "$id": id }
      });
    }
  };
  neuButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-plus",
    hint: "Neu",
    width: "100%",
    onClick: (e) => {
      this.buchungEditPopup.show({
        mappings: {
          "$id": 0,
          "$v_IdBenutzer": this._startupService.startupInfo.Benutzer.IsSupport
            ? null
            : this._startupService.startupInfo.Benutzer.Id,
          "$v_IdProjektTaetigkeit": e.model.bindingContext.item.Id
        }
      });
    }
  };

  searchTextBox: IdxTextBoxComponent;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    showClearButton: true,
    onValueChangedByUser: () => {
      this.taetigkeitListView.refresh(false);
    }
  };

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };

    this.scopeContainer = new ScopeContainer(this.scope);

    this._buchungChangedSubscription = this._eventAggregator.subscribe("projekt-taetigkeit-buchung:changed", async () => {
      this.loadAnzahl();

      if (!this.popover || !this.popover.instance) {
        return;
      }

      const visible = this.popover.instance.option("visible");
      if (!visible) {
        return;
      }

      await this.loadHistorie();
      await this.loadAktuell();
      await this.loadVergangeneTage();

      this.popover.instance.repaint();
    });

    this.taetigkeitListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ERP/Stammdaten/ProjektTaetigkeit",
      webApiColumns: ["Id", "IdProjekt", "IdTaetigkeit"],
      webApiExpand: {
        ProjektEx: {
          columns: ["Id", "Nummer", "Bezeichnung"]
        },
        Taetigkeit: {
          columns: ["Id", "Bezeichnung"]
        }
      },
      webApiWhere: [
        [["ProjektEx.GueltigVon", "<=", "@Today()"], "or", ["ProjektEx.GueltigVon", "null"]],
        [["ProjektEx.GueltigBis", ">=", "@Today()"], "or", ["ProjektEx.GueltigBis", "null"]],
        ["Status.Typ", 0]
      ],
      webApiOrderBy: [{ columnName: "ProjektEx.GueltigVon", sortOrder: 0 }],
      webApiSearchtextEnabled: true
    }, {
      getSearchText: () => {
        if (!this.searchTextBox || !this.searchTextBox.instance) {
          return null;
        }

        return this.searchTextBox.instance.option("value") || null;
      }
    });

    this.loadAnzahl();
  }
  unbind() {
    this._buchungChangedSubscription.dispose();
    this._buchungChangedSubscription = null;

    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async showPopover(targetElement: Element) {
    await this.loadAktuell();
    await this.loadHistorie();
    this.loadVergangeneTage();

    if (this.taetigkeitListView) {
      this.taetigkeitListView.refresh(false);
    }

    await this._asyncService.convertToPromise(this.popover.instance.show(targetElement));
    this.searchTextBox.instance.focus();
  }

  private async loadAktuell() {
    this.aktuellList = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Zeiterfassung/ProjektTaetigkeitBuchung"),
      getOptions: {
        where: [
          ["IdBenutzer", this._startupService.startupInfo.Benutzer.Id],
          ["IsUnvollstaendig", true]
        ],
        expand: {
          ProjektTaetigkeit: {
            columns: ["Id", "IdProjekt", "IdTaetigkeit"],
            expand: {
              ProjektEx: {
                columns: ["Id", "Nummer", "Bezeichnung"]
              },
              Taetigkeit: {
                columns: ["Id", "Bezeichnung"]
              }
            }
          }
        },
        orderBy: [{ columnName: "Start", sortOrder: 0 }, { columnName: "Ende", sortOrder: 0 }]
      }
    });
  }
  private async loadHistorie() {
    this.historieList = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Zeiterfassung/ProjektTaetigkeitBuchung"),
      getOptions: {
        where: [
          ["IdBenutzer", this._startupService.startupInfo.Benutzer.Id],
          ["IsUnvollstaendig", false]
        ],
        expand: {
          ProjektTaetigkeit: {
            columns: ["Id", "IdProjekt", "IdTaetigkeit"],
            expand: {
              ProjektEx: {
                columns: ["Id", "Nummer", "Bezeichnung"]
              },
              Taetigkeit: {
                columns: ["Id", "Bezeichnung"]
              }
            }
          }
        },
        orderBy: [{ columnName: "Ende", sortOrder: 1 }],
        take: 3
      }
    });
  }
  private async loadVergangeneTage() {
    const r: OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequest = await this._webEventService.execute(
      new OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequestEvent({}));

    this.vergangeneTageList = r.DataList;
  }

  private async start(taetigkeit: IProjektTaetigkeit) {
    await this._restService.post({
      url: this._restService.getWebApiUrl("ERP/Zeiterfassung/ProjektTaetigkeitBuchung"),
      data: <IBuchung>{
        IdBenutzer: this._startupService.startupInfo.Benutzer.Id,
        IdProjektTaetigkeit: taetigkeit.Id,
        Start: new Date(),
        _AndereBeenden: true
      },
      increaseLoadingCount: true
    });

    await this.loadHistorie();
    await this.loadAktuell();
    await this.loadVergangeneTage();

    this.popover.instance.repaint();
  }
  private async ende(buchung: IBuchung) {
    await this._restService.post({
      url: this._restService.getWebApiUrl("ERP/Zeiterfassung/ProjektTaetigkeitBuchung"),
      data: <IBuchung>{
        Id: buchung.Id,
        Ende: new Date()
      },
      increaseLoadingCount: true
    });

    await this.loadHistorie();
    await this.loadAktuell();
    await this.loadVergangeneTage();

    this.popover.instance.repaint();
  }

  private async loadAnzahl() {
    const r: OnProjektTaetigkeitBuchungAnzahlAktiveBuchungen = await this._webEventService.execute(
      new OnProjektTaetigkeitBuchungAnzahlAktiveBuchungenEvent({}));

    if (!r || !r.Anzahl) {
      this._headerCommandService.projektTaetigkeitBuchungCommand.badgeText = null;
    } else {
      this._headerCommandService.projektTaetigkeitBuchungCommand.badgeText = <any>{
        key: "base.param_1",
        parameters: [r.Anzahl]
      };
    }
  }
}

interface IBuchung {
  Id?: number;
  IdBenutzer: number;
  IdProjektTaetigkeit: number;
  Start?: Date;
  Ende?: Date;
}
interface IProjektTaetigkeit {
  Id: number;
  IdProjekt?: number;
  IdTaetigkeit?: number;
}
