import { autoinject, transient } from "aurelia-framework";
import { OnBestellungspositionVariablerSetartikelManuellerPreisEntfernenEvent, OnBestellungspositionVariablerSetartikelPreisAendernEvent, OnBestellungspositionVariablerSetartikelRabattEntfernenEvent } from "../../../../framework-data/events";
import { RestService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase, ICommandData, ModelUtilsService } from "../../../../framework/forms/export";
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from "../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern";
import { IKalkulationRefreshOptions } from "../../../interfaces/kalkulation-refresh-options";
import { AuftragService } from "../../../services/auftrag-service";
import { BestellungService } from "../../../services/bestellung-service";
import { DxCustomizeService } from "../../../services/dx-customize-service";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { StartupService } from "../../../services/startup-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { ArtikelService } from "./../../../services/artikel-service";

@autoinject
@transient()
export class BestellungspositionEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    public dynFelderDataService: DynFelderDataService,
    private _auftragService: AuftragService,
    private _asyncService: AsyncService,
    private _artikelService: ArtikelService,
    private _bestellungService: BestellungService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _dxCustomizeService: DxCustomizeService,
    private _localizationService: LocalizationService,
    private _modelUtilsService: ModelUtilsService,
    private _restService: RestService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  mengeVerpEH: number;
  showVariablerSetartikelCheckbox: boolean;

  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "bestellungsposition",
    icon: "money",
    title: "bestellung.preis_aendern",
    isVisibleExpression: "functions.$f_BestellungspositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Bestellungsposition && models.data.$m_Bestellungsposition.Id > 0 && models.data.$m_Bestellungsposition.IsVariablerSetartikel && models.data.$m_Bestellungsposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Bestellungsposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    this.refreshOptions = {
      form: form,
      modelName: "$m_Bestellungsposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeVerpEH"
    };

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "bestellungspositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });


    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0 || e.model.id != "$m_Bestellungsposition") {
        return;
      }

      this.refreshBestellungsgruppeButton();

      this.showVariablerSetartikelCheckbox = this.form.models.data.$m_Bestellungsposition
        && this.form.models.data.$m_Bestellungsposition.Artikel != null
        && this.form.models.data.$m_Bestellungsposition.Artikel.BezugTyp == 3
        ? true
        : false;

      if (this.form.models.data.$m_Bestellungsposition) {
        this.mengeVerpEH = this.form.models.data.$m_Bestellungsposition.MengeVerpEH;
      }
    });

    this.form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        let result = await this.validateVariablerSetartikel();

        if (!result) {
          throw new Error();
        }

        result = await this.validateMindestbestellmenge();

        if (!result) {
          throw new Error();
        }

        result = await this.validateIntercompany();

        if (!result) {
          throw new Error();
        }
      }
    });
  }

  async idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);

    if (!this.form.models.data.$m_Bestellungsposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Bestellungsposition);
    }

    this._artikelService.doEvalEinkaufArtikeldaten(e, this.form.models.data.$m_Bestellung, this.form.models.data.$m_Bestellungsposition);
  }

  onBestellungsgruppeChanged() {
    this.refreshBestellungsgruppeButton();
  }

  copyBestellungsposition($delegate: { (customData: any): Promise<any> }) {
    $delegate({ "PosNrUeberschreiben": true })
  }

  private refreshBestellungsgruppeButton() {
    const dataPos = this.form.models.data.$m_Bestellungsposition;
    const dataBestellung = this.form.models.data.$m_Bestellung;

    if (!dataPos || !dataBestellung) {
      return;
    }

    const editor = this.form["r_bestellungsgruppe"].instance;
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (!dataPos.IdBestellungsgruppe && dataPos.CanSave && !dataBestellung.HasStreckengeschaeftAuftrag) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: async () => {
            this.form.models.data.$m_BestellungsgruppeEdit = {
              Id: 0
            };

            this.form.editPopups.showEx({
              idEditPopup: "bestellungsgruppeEditPopup",
              modelLoaded: (form, ev) => {
                if (!form.models.data.$m_Bestellungsgruppe) {
                  return;
                }
                form.models.data.$m_Bestellungsgruppe.IdBestellung = this.form.models.data.$m_Bestellungsposition.IdBestellung;
              },
              closeCallback: async (form) => {
                this.form["r_bestellungsgruppe"].instance.getDataSource().reload();

                const model = form.models.modelWithKeyId;
                const data = form.models.data[model.id];
                this.form.models.data.$m_Bestellungsposition.IdBestellungsgruppe = data.Id;

                this.form.onEditorValueChanged.fire({
                  binding: {
                    dataContext: "$m_Bestellungsposition",
                    bindTo: "IdBestellungsgruppe"
                  },
                  value: data.Id
                });
              }
            });
          }
        }
      });
    }

    buttons.push({
      location: "after",
      name: "clear"
    }, {
      location: "after",
      name: "dropDown"
    });

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnBestellungspositionVariablerSetartikelRabattEntfernenEvent({
            IdBestellungspositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnBestellungspositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdBestellungspositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnBestellungspositionVariablerSetartikelPreisAendernEvent({
            IdBestellungspositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }

  private async validateMindestbestellmenge() {
    const artikelLieferant = await this._artikelService.getLieferantById(this.form.models.data.$m_Bestellungsposition.IdArtikel, this.form.models.data.$m_Bestellung.IdLieferant);

    if (artikelLieferant == null) {
      return true;
    }

    let menge = this.form.models.data.$m_Bestellungsposition.MengeVerpEH;
    const bestellungPosList = await this._bestellungService.getBestellungPosByIdBestellung(this.form.models.data.$m_Bestellung.Id);

    if (bestellungPosList != null) {
      const mengeBestellungPos = bestellungPosList
        .filter((c) => {
          return c.Id != this.form.models.data.$m_Bestellungsposition.Id
            && c.IdArtikel == this.form.models.data.$m_Bestellungsposition.IdArtikel;
        })
        .reduce((p, c) => {
          return p + c.MengeVerpEH;
        }, 0);

      menge += mengeBestellungPos;
    }

    if (artikelLieferant.MindestbestellmengeVerpEH != null && artikelLieferant.MindestbestellmengeVerpEH > menge) {
      const einheit = await this._artikelService.getEinheitbezeichnungByArtikelEinheit(this.form.models.data.$m_Bestellungsposition.IdEinheit);

      const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
        "Die Menge aller Positionen dieses Artikels ("
        + menge
        + " "
        + einheit
        + ") unterschreitet die Mindestbestellmenge von "
        + artikelLieferant.MindestbestellmengeVerpEH
        + " "
        + einheit
        + ". Wollen Sie wirklich weniger bestellen?",
        this._localizationService.translateOnce("base.question")));

      if (!doExecute) {
        return false;
      }
      return true;
    }
    return true;
  }

  private async validateVariablerSetartikel() {
    if (!this.form.models.data.$m_Bestellungsposition.IsVariablerSetartikel)
      return true;

    if (this.form.models.data.$m_Bestellung.IsIntercompany) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("bestellung.fehler_variabler_setartikel_intercompany"),
        "error",
        this.NOTIFY_TIMEOUT)
      return false;
    } else if (this.form.models.data.$m_Bestellung.HasStreckengeschaeftAuftrag) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("bestellung.fehler_variabler_setartikel_streckengeschaeft"),
        "error",
        this.NOTIFY_TIMEOUT);

      return false;
    }
    return true;
  }

  private async validateIntercompany() {
    const checkIntercompany = this.startupService.startupInfo.Lizenz.HasIntercompany
      && this.form.models.data.$m_Bestellung.IsIntercompany
      && this.form.models.data.$m_Bestellung.IdIcAuftrag != null

    if (!checkIntercompany) {
      return true;
    }

    if (this.mengeVerpEH < this.form.models.data.$m_Bestellungsposition.MengeVerpEH) {
      const icAuftragsposition = await this._auftragService.getAuftragspositionByIdIcBestellungsposition(this.form.models.data.$m_Bestellungsposition.Id);

      if (icAuftragsposition != null) {
        let doExecute = await this.validateIntercompanyLieferschein(icAuftragsposition);

        if (doExecute) {
          doExecute = await this.validateIntercompanyBestellung(icAuftragsposition);

          if (!doExecute) {
            return false;
          }
          return true;
        }
      }
    }

    return true;
  }

  private async validateIntercompanyLieferschein(icAuftragsposition: any) {
    if (icAuftragsposition.MengeLieferscheinVerpEH > 0) {
      const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
        this._localizationService.translateOnce("bestellung.sure_intercompany_menge_lieferschein"),
        this._localizationService.translateOnce("base.question")));

      if (!doExecute) {
        return false;
      }
      return true;
    }
    return true;
  }

  private async validateIntercompanyBestellung(icAuftragsposition: any) {
    const mengeBestellungIcAuftragsposition = await this._bestellungService.getMengeVerpEHByIdAuftragsposition(icAuftragsposition.Id);

    if (mengeBestellungIcAuftragsposition > 0) {
      const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
        this._localizationService.translateOnce("bestellung.sure_intercompany_menge_bestellung"),
        this._localizationService.translateOnce("base.question")));

      if (!doExecute) {
        return false;
      }
      return true;
    }
    return true;
  }
}
