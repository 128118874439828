import {
  autoinject, transient
} from "aurelia-framework";
import {
  FormBase, 
  PopupInfoService
} from "../../../../framework/forms/export";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  ICommandData
} from "./../../../../framework/forms/interfaces/export";
import {
  OnMandantWechselnEvent
} from "../../../../framework-data/events";
import {
  StartupService
} from "./../../../services/export";

@autoinject
@transient()
export class MandantWechselnUtils {
  constructor(
    public startupService: StartupService,
    private _popupInfoService: PopupInfoService,
    private _webEventService: WebEventService) { }

  form: FormBase;

  mandantWechselnCommand: ICommandData = {
    id: "mandantWechselnCommand",
    icon: "exchange",
    title: "mandant.mandant_wechseln",
    execute: async () => {
      const currentMandantId = this.form.models.data.$m_Mandant.Id;
      
      if (this.startupService.startupInfo.Mandant.Id == currentMandantId) {
        this._popupInfoService.closeCurrentPopup();
        return;
      }

      const mandantWechseln = new OnMandantWechselnEvent({
        IdMandant: currentMandantId
      });

      await this._webEventService.execute(mandantWechseln, true);
      
      this._popupInfoService.closeCurrentPopup();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
