import { GlobalizationService } from './../../../base/services/globalization-service';
import { autoinject, OverrideContext, Scope, createOverrideContext, TaskQueue } from 'aurelia-framework';
import { DataSourceService, LocalizationService, RestService, ScopeContainer } from '../../../base/export';
import { GridLayoutService } from '../../services/grid-layout-service';
import { LayoutService } from '../../services/layout-service';
import { SimpleWidgetCreatorService, ICommandData, IModel } from '../../export';
@autoinject
export class GridLayoutColumns {
  private _deleteColumnList: string[] = [];

  constructor(
    private dataSourceService: DataSourceService,
    private gridLayoutService: GridLayoutService,
    private globalizationService: GlobalizationService,
    private layoutService: LayoutService,
    private localizationService: LocalizationService,
    private restService: RestService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private taskQueue: TaskQueue
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  options: IGridLayoutColumnsShowOptions;
  gridColumns: IGridLayoutColumn[] = [];

  addColumnCommand: ICommandData = {
    id: "addDynSpalteCommand",
    title: "grid_layout_columns.neu_spalte",
    icon: "plus",
    sortIndex: 1010,
    execute: () => {
      if (this.gridColumns.length > 0) {
        const result = this.gridColumnsValidation.instance.validate();

        if (!result.isValid) {
          return;
        }
      }

      this.gridColumns.push({
        caption: null,
        captionFQ: null,
        dataField: null,
        dataFieldFQ: null,
        dataType: null,
        format: null
      });
    }
  };
  saveColumnsCommand: ICommandData = {
    id: "addDynSpalteCommand",
    title: "base.save",
    icon: "floppy-o",
    sortIndex: 1015,
    execute: async () => {
      const isValid = this.validateGridLayout();
      if (!isValid) {
        return;
      }
      const layout = this.getColumnsLayoutToSave();

      await this.layoutService.saveLayout(
        this.gridLayoutService.GRID_COLUMNS,
        this.options.formViewUrl,
        this.options.idGrid, 
        layout);

      this.updateVisibleGridColumns();
      this.gridColumnsPopup.instance.hide();

      DevExpress.ui.notify(
        this.localizationService.translateOnce("base.save_success"),
        "SUCCESS",
        3000);
    }
  };

  deleteColumnButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-times fa-fw",
    onClick: (e) => {
      const gridColumn: IGridLayoutColumn = e.model.bindingContext.gridColumn;
      const index = this.gridColumns.findIndex(d => d === gridColumn);
      this.gridColumns.splice(index, 1);

      this._deleteColumnList.push(gridColumn.dataFieldFQ);
    }
  }

  captionTexBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "gridColumn.caption"
    }
  }
  gridColumnsPopup: any;
  gridColumnsPopupOptions: DevExpress.ui.dxPopupOptions = {
    width: "700px",
    minHeight: "250px",
    maxHeight: "350px"
  };
  gridColumnDropBoxOptions: DevExpress.ui.dxDropDownBoxOptions = {
    displayExpr: "captionFQ",
    valueExpr: "dataFieldFQ",
    showClearButton: true,
    contentTemplate: "contentTreeViewTemplate",
    onInitialized: (e: any) => {
      const value = e.component.option("value")
      if (!value) {
        return;
      }

      e.component.option("items", [e.model.bindingContext.gridColumn]);
    },
    bindingOptions: {
      value: "gridColumn.dataFieldFQ"
    }
  };
  gridColumnTreeViewOptions: DevExpress.ui.dxTreeViewOptions = {
    dataStructure: "tree",
    itemsExpr: "NestedProperties",
    keyExpr: "dataFieldFQ",
    displayExpr: "Caption",
    selectionMode: "single",
    selectByClick: true,
    height: "200px",
    focusStateEnabled: false,
    onInitialized: (e: any) => {
      const dataSource = e.component.option("dataSource");
      if (!dataSource) {
        return;
      }

      const dataFieldFQ: string = e.model.bindingContext.data.value;
      const hasLoaded = e.component.option("items").length;
      const component: DevExpress.ui.dxTreeView = e.component;

      if (!dataFieldFQ || !hasLoaded) {
        component.unselectAll();
        return;
      }

      const parentFQ = dataFieldFQ.substring(0, dataFieldFQ.lastIndexOf("__"));

      if (parentFQ && parentFQ.startsWith("_cgc_")) {
        this.taskQueue.queueMicroTask(() => {
          component.expandItem(parentFQ);
        });
      }

      component.selectItem(dataFieldFQ);
    },
    onItemSelectionChanged: (e: any) => {
      const hasChildren = e.itemData.hasItems
      const component: DevExpress.ui.dxDropDownBox = e.model.bindingContext.data.component;
      e.model.overrideContext.bindingContext.gridColumn.dataFieldFQ = !hasChildren
        ? e.itemData.dataFieldFQ
        : null;
      e.model.overrideContext.bindingContext.gridColumn.dataType = !hasChildren
        ? e.itemData.DataType
        : null;
      e.model.overrideContext.bindingContext.gridColumn.format = !hasChildren
        ? e.itemData.Format
        : null;

      e.model.bindingContext.data.component.option("items", [e.itemData]);
    },
    bindingOptions: {
      dataSource: "gridColumnsDataSource"
    }
  };

  gridColumnsData: any[];
  gridColumnsDataSource: DevExpress.data.DataSource;

  gridColumnsValidation: any;
  gridColumnsValidationGroupOptions: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{ type: "required" }]
  };

  attached() {
    this.gridLayoutService.gridLayoutColumnsElement = this;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "dynSpaltenPopupToolbar",
      caption: "grid_layout_columns.popup_titel",
      options: this.gridColumnsPopupOptions,
      commands: [this.addColumnCommand, this.saveColumnsCommand],
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup(options: IGridLayoutColumnsShowOptions) {
    this._deleteColumnList = [];
    this.options = options;

    if (!this.options.webApiAction) {
      return;
    }

    await this.loadProperties();
    this.loadCurrentLayout();

    this.gridColumnsPopup.instance.show();
  }

  private async loadProperties() {
    const data: any[] = await this.restService.get({
      url: `${this.restService.getApiUrl("base/WebApiInfo/Properties")}/?webApiAction=${this.options.webApiAction}&levels=2`
    });

    this.prepareProperties("", "_cgc_", data);

    this.gridColumnsData = data;
    this.gridColumnsDataSource = new DevExpress.data.DataSource(data);
  }
  private loadCurrentLayout() {
    const columns: IGridLayoutColumn[] = this.layoutService.getLayout(
      this.gridLayoutService.GRID_COLUMNS,
      this.options.formViewUrl,
      this.options.idGrid) || [];

    this.gridColumns = columns.length > 0
      ? columns.map(c => {
        return {
          caption: c.caption,
          captionFQ: this.getCaptionFQ(this.gridColumnsData, c.dataField),
          dataField: c.dataField,
          dataFieldFQ: c.dataField,
          dataType: this.getDataType(this.gridColumnsData, c.dataField) || GridColumnDataType.Unknown,
          format: c.format
        }
      })
      : [];
  }
  private getGridColumnWith(dataType: GridColumnDataType, format: string): string {
    switch (dataType) {
      case GridColumnDataType.Number:
      case GridColumnDataType.Boolean:
        return "80px";
      case GridColumnDataType.Date:
        return format == "g" ? "120px" : "90px";
      default:
        return undefined;
    }
  }
  private getGridColumnMinWith(dataType: GridColumnDataType): number {
    switch (dataType) {
      case GridColumnDataType.Text:
      case GridColumnDataType.Unknown:
        return 100;
      default:
        return undefined;
    }
  }
  private getCaptionFQ(data: any[], dataFieldFQ: string): string {
    for (let item of data) {
      if (item.dataFieldFQ === dataFieldFQ) {
        return item.captionFQ;
      } else if (item.hasItems) {
        let captionFQ = this.getCaptionFQ(item.NestedProperties, dataFieldFQ);
        if (captionFQ) {
          return captionFQ;
        }
      }
    }

    return "";
  }
  private getDataType(data: any[], dataFieldFQ: string): GridColumnDataType {
    for (let item of data) {
      if (item.dataFieldFQ === dataFieldFQ) {
        return item.DataType;
      } else if (item.hasItems) {
        let dataType = this.getDataType(item.NestedProperties, dataFieldFQ);
        if (dataType != void(0)) {
          return dataType;
        }
      }
    }

    return undefined;
  }
  private getColumnsLayoutToSave(): any[] {
    return this.gridColumns.map(c => {
      return {
        caption: c.caption,
        dataField: c.dataFieldFQ,
        format: c.format,
        minWidth: this.getGridColumnMinWith(c.dataType),
        width: this.getGridColumnWith(c.dataType, c.format)
      }
    });
  }
  private prepareProperties(prefixCaptionFQ: string, prefixDataFieldFQ: string, list: any[]) {
    for (let item of list) {
      item.captionFQ = prefixCaptionFQ.concat(item.Caption);
      item.dataFieldFQ = prefixDataFieldFQ.concat(item.Name);
      item.hasItems = item.NestedProperties.length > 0;

      if (item.hasItems) {
        this.prepareProperties(item.captionFQ.concat("."), item.dataFieldFQ.concat("__"), item.NestedProperties);
      }
    }

    list.sort((a, b) => {
      if (a.hasItems && !b.hasItems) {
        return 1;
      } else if (b.hasItems && !a.hasItems) {
        return -1;
      }

      return a.Caption.localeCompare(b.Caption);
    })
  }
  private updateVisibleGridColumns() {
    try {
      let hasColumnsAdded = false;
      this.options.gridInstance.beginUpdate();

      for (let dataFieldFQ of this._deleteColumnList) {
        this.options.gridInstance.deleteColumn(dataFieldFQ);
      }
      this._deleteColumnList = [];

      for (let gridColumn of this.gridColumns) {
        const column: DevExpress.ui.dxDataGridColumn = this.options.gridInstance.columnOption(gridColumn.dataFieldFQ);

        if (column) {
          this.options.gridInstance.columnOption(gridColumn.dataFieldFQ, "caption", gridColumn.caption);
        } else {
          this.options.gridInstance.addColumn({
            caption: gridColumn.caption,
            dataField: gridColumn.dataFieldFQ,
            format: gridColumn.format ? this.globalizationService.getFormatterParser(gridColumn.format) : null,
            width: this.getGridColumnWith(gridColumn.dataType, gridColumn.format),
            minWidth: this.getGridColumnMinWith(gridColumn.dataType),
            visible: true
          });
  
          hasColumnsAdded = true;
        }
      }
  
      if (hasColumnsAdded && this.options.onColumnsAdded) {
        this.options.onColumnsAdded();
      }
    }
    finally {
      this.options.gridInstance.endUpdate();
    }
  }
  private validateGridLayout(): boolean {
    if (this.gridColumns.length <= 0) {
      return true;
    }

    this.gridColumns.forEach(c => c["_invalid"] = false);

    let isValid = this.gridColumnsValidation.instance.validate().isValid;

    if (isValid === true) {
      isValid = !this.gridColumns.some((c, i, a) => {
        const sameGridColum = a.filter(b => c.dataFieldFQ == b.dataFieldFQ);
        if (sameGridColum.length > 1) {
          sameGridColum.forEach(s => s["_invalid"] = true);
          return true;
        }

        return false;
      });

      if (!isValid) {
        DevExpress.ui.notify(this.localizationService.translateOnce("grid_layout_columns.spalten_doppelt"), "ERROR", 3000);
      }
    }

    return isValid;
  }
}

export interface IGridLayoutColumnsShowOptions {
  webApiAction: string;
  formViewUrl: string;
  idGrid: string;
  gridInstance: DevExpress.ui.dxDataGrid;
  onColumnsAdded?: {(): void};
}
interface IGridLayoutColumn {
  caption: string;
  captionFQ: string;
  dataField: string;
  dataFieldFQ: string;
  dataType: GridColumnDataType;
  format?: string;
}
enum GridColumnDataType {
  Text = 0,
  Number = 1,
  Date = 2,
  Boolean = 3,
  Unknown = 4
}
