import { FileService } from './../../../framework/base/services/file-service';
import { bindable } from 'aurelia-templating';
import {
  autoinject,
  computedFrom
} from "aurelia-framework";

@autoinject
export class DokumentViewer {
  constructor(
    private file: FileService
  ) { }

  @bindable dmsLink: string;
  @bindable mimeType: string;

  @computedFrom("dmsLink")
  get dokumentInlineLink() {
    if (this.dmsLink == void 0) {
      return "";
    };

    return this.file.getInlineUrl(this.dmsLink);
  }
  @computedFrom("dmsLink", "mimeType")
  get showObjectViewer() {
    return this.dmsLink
      && (!this.mimeType || this.mimeType === "application/pdf");
  }
  @computedFrom("dmsLink", "mimeType")
  get showImageViewer() {
    return this.dmsLink
      && this.mimeType
      && this.mimeType.substr(0, 5) === "image";
  }
  @computedFrom("mimeType")
  get dokumentMimeType() {
    if (this.mimeType == void 0) {
      return "application/pdf";
    }

    return this.mimeType;
  }

}
