import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class MedienListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "medien-list";
        this.title = "medien-list.medien-list_caption";
        this.addModel({
            "id": "$m_Medien",
            "webApiAction": "ERP/Stammdaten/Medien",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_medienBezeichnung",
                "caption": "medien-list.r_medienbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_medienGridToolbarOptions",
                "optionsNameFQ": "r_medienGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Medien"
            },
            "dataModel": "$m_Medien",
            "editUrl": "ERP/Stammdaten/Medien",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_medienGrid",
            "options": {
                "optionsName": "r_medienGridOptions",
                "optionsNameFQ": "r_medienGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}