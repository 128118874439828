import { EntitaetInfoService } from './../../../services/entitaet-info-service';
import { ScopeContainer } from './../../../../framework/base/classes/scope-container';
import { DataSourceService } from './../../../../framework/base/services/data-source-service';
import { autoinject } from "aurelia-framework";
import { IWidgetModel } from '../../interfaces/widget-model';
import { IEntitaetTagsSettings } from './entitaet-tags-settings';
import { ListView } from '../../../../framework/forms/elements/export';
import { IListViewOptions } from '../../../../framework/forms/elements/list-view/export';
import { PopupInfoService } from '../../../../framework/forms/export';
import { LocationService, IDataSourceOptions } from '../../../../framework/base/export';
import { Statustyp } from '../../../enumerations/export';

@autoinject
export class EntitaetTags {
  constructor(
    private _dataSourceService: DataSourceService,
    private _popupInfoService: PopupInfoService,
    private _locationService: LocationService,
    private _entitaetInfoService: EntitaetInfoService
  ) { }

  scopeContainer: ScopeContainer;

  widgetModel: IWidgetModel;
  settings: IEntitaetTagsSettings;
  entitateTitle: string;

  entitaetListView: ListView;
  entitaetListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 20,
    hoverStateEnabled: true,
    height: "100%",
    onItemClick: async (e) => {
      this._entitaetInfoService
      .getUrl(e.item.ObjektTypeName, e.item.IdObjekt)
      .then(r => {
        this._popupInfoService.closeAllPopups().then(async pr => {
          if (!pr) {
            return;
          }

          const viewScrollInfo = await this.getViewScrollInfo(e.item);

          this._locationService.goTo({
            url: r,
            viewScrollInfo: viewScrollInfo
          });
        })
      });
    }
  }

  dataSourceOptions: IDataSourceOptions;

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.loadData();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async loadData() {
    let dataSource: DevExpress.data.DataSource;

    if (this.settings.idTag) {
      const where = [["IdTag", this.settings.idTag]];

      if (this.settings.typeNameEntitaet) {
        where.push(["ObjektTypeName", this.settings.typeNameEntitaet]);
      }

      if (this.settings.nurAktiveDatensaetze) {
        where.push(["ObjektStatusTyp", "!=", <any>Statustyp.Erledigt]);
      }

      this.dataSourceOptions = {
        keyProperty: "Id",
        webApiAction: "ERP/Tag/TagEintrag",
        webApiColumns: ["Id", "IdTagContainer", "ObjektTypeName", "IdObjekt", "ObjektBezeichnung", "ObjektStatusFarbe"],
        webApiWhere: where,
        webApiOrderBy: [{columnName: "Id", sortOrder: 1}]
      };

      dataSource = this._dataSourceService.createDataSource(this.scopeContainer, this.dataSourceOptions);
    } else {
      dataSource = new DevExpress.data.DataSource(new DevExpress.data.ArrayStore({ data: [] }));
    }

    if (this.entitaetListView && this.entitaetListViewOptions.dataSource) {
      this.entitaetListView.replaceDataSource(dataSource);
    } else {
      this.entitaetListViewOptions.dataSource = dataSource;
    }
  }

  private async getViewScrollInfo(item): Promise<any>{
    const viewScrollInfo = await this._entitaetInfoService.createViewScrollInfoEx(
      "ERP/Tag/TagEintrag",
      ["IdObjekt"],
      [this.dataSourceOptions.webApiWhere, ["ObjektTypeName", item.ObjektTypeName]],
      null,
      this.dataSourceOptions.webApiOrderBy,
      item.IdObjekt,
      (item) => {
        return item.IdObjekt;
      }
    );

    return viewScrollInfo;
  }
}
