import { autoinject } from "aurelia-framework";
import { StatistikEinkaufDetail } from '../elements/statistik-einkauf-detail/statistik-einkauf-detail';
import { IUmsatzWhereItem, StatistikVerkaufDetail, StatistikVerkaufDetailViewTyp } from '../elements/statistik-verkauf-detail/statistik-verkauf-detail';

@autoinject
export class StatistikService {
  constructor() { }

  verkaufElement: StatistikVerkaufDetail;
  einkaufElement: StatistikEinkaufDetail;

  async showVerkaufDetail(typ: StatistikVerkaufDetailViewTyp, where: IUmsatzWhereItem) {
    return this.verkaufElement.show(typ, where);
  }
  async showEinkaufDetail(where: IUmsatzWhereItem) {
    return this.einkaufElement.show(where);
  }
}
