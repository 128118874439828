import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { IWidgetModel } from '../interfaces/widget-model';
import { IWidgetSettings } from '../interfaces/widget-settings';

@autoinject
export class WidgetToolbar {
  private _bindingContext: any;

  constructor(
    private _element: Element
  ) {}

  @bindable widgetModel: IWidgetModel;

  @computedFrom("widgetModel.widget.settingViewModel")
  get hasSettings() {
    return this.widgetModel
      && this.widgetModel.widget
      && this.widgetModel.widget.settingViewModel;
  }

  bind(bindingContext) {
    this._bindingContext = bindingContext;
  }

  onSettingClick() {
    this._element.dispatchEvent(new CustomEvent(
      "widget-setting", {
        bubbles: true,
        detail: <IWidgetSettings>{
          widgetModel: this.widgetModel,
          widgetViewModel: this._bindingContext
        }
      }
    ));
  }
  onDeleteClick() {
    this._element.dispatchEvent(new CustomEvent(
      "au-grid-cell-delete", {
        bubbles: true
      }
    ));
  }
}