import {
  autoinject
} from "aurelia-framework";
import {
  ProjektSearchResult
} from "../elements/projekt-search-result/projekt-search-result";
import {
  ISearchOptions
} from "../interfaces/export";

@autoinject
export class ProjektSearchService {
  constructor() { }

  projektSearchResult: ProjektSearchResult;
  
  show(callbackSuccess: { (idProjekt): void }, callbackCancel: { (): void }, options?: ISearchOptions) {
    this.projektSearchResult.showPopup(callbackSuccess, callbackCancel, options)
  }
}