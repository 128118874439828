import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ArtikelLieferantEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "artikel-lieferant-edit";
        this.title = "artikel-lieferant-edit.artikel-lieferant-edit_caption";
        this.addModel({
            "id": "$m_ArtikelLieferant",
            "webApiAction": "ERP/Stammdaten/ArtikelLieferant",
            "webApiExpand": {
                'Artikel': null,
                'Geschaeftspartner': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-lieferant-edit.r_hauptlieferant_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "IsHauptlieferant",
                "bindToFQ": "models.data.$m_ArtikelLieferant.IsHauptlieferant"
            },
            "validationRules": [],
            "id": "r_hauptlieferant",
            "options": {
                "optionsName": "r_hauptlieferantOptions",
                "optionsNameFQ": "r_hauptlieferantOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 18,
            "caption": "artikel-lieferant-edit.r_ean_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "EAN",
                "bindToFQ": "models.data.$m_ArtikelLieferant.EAN"
            },
            "validationRules": [{
                "item": {
                    "type": "ean",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 18
                    }]
                }
            }],
            "id": "r_ean",
            "options": {
                "optionsName": "r_eanOptions",
                "optionsNameFQ": "r_eanOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "artikel-lieferant-edit.r_lieferantartikelnummer_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "LieferantArtikelNr",
                "bindToFQ": "models.data.$m_ArtikelLieferant.LieferantArtikelNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_lieferantArtikelNummer",
            "options": {
                "optionsName": "r_lieferantArtikelNummerOptions",
                "optionsNameFQ": "r_lieferantArtikelNummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "artikel-lieferant-edit.r_lieferantartikelbezeichnung_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "LieferantArtikelbezeichnung",
                "bindToFQ": "models.data.$m_ArtikelLieferant.LieferantArtikelbezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_lieferantArtikelbezeichnung",
            "options": {
                "optionsName": "r_lieferantArtikelbezeichnungOptions",
                "optionsNameFQ": "r_lieferantArtikelbezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "caption": "artikel-lieferant-edit.r_lieferantmindestbestellmengeverpeh_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "MindestbestellmengeVerpEH",
                "bindToFQ": "models.data.$m_ArtikelLieferant.MindestbestellmengeVerpEH"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_ArtikelLieferant.IdEinheit"
                    }]
                }
            }],
            "id": "r_lieferantMindestbestellmengeVerpEH",
            "options": {
                "optionsName": "r_lieferantMindestbestellmengeVerpEHOptions",
                "optionsNameFQ": "r_lieferantMindestbestellmengeVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": ["IdArtikel", {
                "isBound": true,
                "expression": "models.data.$m_ArtikelLieferant.IdArtikel"
            }],
            "filters": [],
            "caption": "artikel-lieferant-edit.r_lieferantideinheit_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_ArtikelLieferant.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_ArtikelLieferant.MindestbestellmengeVerpEH"
                    }]
                }
            }],
            "id": "r_lieferantIdEinheit",
            "options": {
                "optionsName": "r_lieferantIdEinheitOptions",
                "optionsNameFQ": "r_lieferantIdEinheitOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "artikel-lieferant-edit.r_beschaffungsperiode_caption",
            "binding": {
                "dataContext": "$m_ArtikelLieferant",
                "bindTo": "Beschaffungsperiode",
                "bindToFQ": "models.data.$m_ArtikelLieferant.Beschaffungsperiode"
            },
            "validationRules": [{
                "item": {
                    "type": "duration",
                    "parameters": [{
                        "name": "durationPeriodType",
                        "value": "2"
                    }]
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 10
                    }]
                }
            }],
            "id": "r_beschaffungsperiode",
            "options": {
                "optionsName": "r_beschaffungsperiodeOptions",
                "optionsNameFQ": "r_beschaffungsperiodeOptions"
            }
        });
        super.onConstructionFinished();
    }
}