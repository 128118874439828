import { TicketStatus } from './../enumerations/ticket-status';
import {
  autoinject,
  valueConverter
} from "aurelia-framework";

@autoinject
@valueConverter("ticketStatusName")
export class TicketStatusNameValueConverter {
  constructor(
  ) { }

  toView(value: any) {
    if (value == null) {
      return "";
    }

    let ticketStatus = value;

    switch (ticketStatus) {
      case TicketStatus.Offen:
        value = "Offen";
        break;
      case TicketStatus.InBearbeitungTIP:
        value = "In Bearbeitung";
        break;
      case TicketStatus.ErledigtTIP:
        value = "Erledigt";
        break;
      case TicketStatus.ErledigtKunde:
        value = "Erledigt durch Kunde";
        break;
      case TicketStatus.WarteAufKunde:
        value = "Antwort Kunde ausständig";
        break;
      case TicketStatus.Doppelt:
        value = "Doppelt";
        break;
      case TicketStatus.MitNaechstemUpdate:
        value = "mit nächstem Update";
        break;
    }

    return value;
  }
}
