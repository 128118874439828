import { autoinject, transient } from "aurelia-framework";
import { OnInventurFehlArtZuZaehllisteEvent, OnInventurVerbuchenEvent, OnInventurZaehllisteErstellenEvent } from "../../../../framework-data/events";
import { WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { EximService } from "../../../services/exim-service";
import { ValidationExService } from "./../../../services/validation-ex-service";

@autoinject
@transient()
export class InventurEditUtils {
  constructor(
    private _webEventService: WebEventService,
    private _eximService: EximService,
    private _validationExService: ValidationExService
  ) { }

  form: FormBase;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "models.data.$m_Inventur.Id > 0",
    title: "erp.export",
    execute: async () => {
      const id = this.form.models.data.$m_Inventur.Id;
      this._eximService.export("InventurExim", [id]);
    }
  };
  doZaehllisteErstellen: ICommandData = {
    id: "doZaehllisteErstellen",
    idCategory: "inventur",
    title: "inventur.zaehlliste_erstellen",
    icon: "list",
    sortIndex: 10,
    isEnabledExpression: "!models.data.$m_Inventur.IsZaehllisteErstellt && models.data.$m_Inventur.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnInventurZaehllisteErstellenEvent({
        IdInventur: this.form.models.data["$m_Inventur"].Id
      });

      await this._webEventService.execute(webEvent, true);
      this.form.models.reloadAll();
    }
  };
  doInventurVerbuchen: ICommandData = {
    id: "doInventurVerbuchen",
    idCategory: "inventur",
    title: "inventur.inventur_verbuchen",
    icon: "exchange",
    sortIndex: 20,
    isEnabledExpression: "models.data.$m_Inventur.CanSave && models.data.$m_Inventur.Id > 0",
    execute: async () => {
      const data: any = this.form.models.data.$m_Inventur;

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Inventur.TypeName,
        this.form.models.data.$m_Inventur.Id,
        "INVENTUR_VERBUCHEN"
      );

      if (!validationResult) {
        return;
      }

      const webEvent = new OnInventurVerbuchenEvent({
        IdInventur: data.Id
      });

      await this._webEventService.execute(webEvent, true);
      this.form.models.reloadAll();
    }
  };
  doFehlendeArtikelHinz: ICommandData = {
    id: "doFehlendeArtikelHinz",
    idCategory: "fehlende_artikel_laden",
    icon: "plus-square",
    title: "inventur.fehlende_artikel_laden",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Inventur.IsZaehllisteErstellt && models.data.$m_Inventur.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnInventurFehlArtZuZaehllisteEvent({
        IdInventur: this.form.models.data["$m_Inventur"].Id
      });

      await this._webEventService.execute(webEvent, true);
      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
