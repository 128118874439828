import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class LagerkorrekturListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "lagerkorrektur-list";
        this.title = "lagerkorrektur-list.lagerkorrektur-list_caption";
        this.addModel({
            "id": "$m_Lagerkorrektur",
            "webApiAction": "ERP/Lager/Lagerkorrektur",
            "webApiExpand": {
                'Status': null,
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Lagerbuchungsart': null,
                'LagerVon': null,
                'LagerNach': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_lagkDatum",
                "caption": "lagerkorrektur-list.r_lagkdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_lagkLagerbuchungsart",
                "caption": "lagerkorrektur-list.r_lagklagerbuchungsart_caption",
                "bindTo": "Lagerbuchungsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_lagkLagerVon",
                "caption": "lagerkorrektur-list.r_lagklagervon_caption",
                "bindTo": "LagerVon.BezeichnungFQ",
                "minWidth": 100
            }, {
                "id": "r_lagkLagerNach",
                "caption": "lagerkorrektur-list.r_lagklagernach_caption",
                "bindTo": "LagerNach.BezeichnungFQ",
                "minWidth": 100
            }, {
                "id": "r_lagkArtikelnr",
                "caption": "lagerkorrektur-list.r_lagkartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_lagkArtikel",
                "caption": "lagerkorrektur-list.r_lagkartikel_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagkMenge",
                "caption": "lagerkorrektur-list.r_lagkmenge_caption",
                "bindTo": "Menge",
                "width": "80px"
            }, {
                "id": "r_lagkLagereinheit",
                "caption": "lagerkorrektur-list.r_lagklagereinheit_caption",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_lagkKommentar",
                "caption": "lagerkorrektur-list.r_lagkkommentar_caption",
                "bindTo": "Kommentar",
                "minWidth": 100
            }, {
                "id": "r_lagkStatus",
                "caption": "lagerkorrektur-list.r_lagkstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_lagerkorrekturGridToolbarOptions",
                "optionsNameFQ": "r_lagerkorrekturGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Lagerkorrektur"
            },
            "dataModel": "$m_Lagerkorrektur",
            "editUrl": "ERP/Lager/Lagerkorrektur",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lagerkorrekturGrid",
            "options": {
                "optionsName": "r_lagerkorrekturGridOptions",
                "optionsNameFQ": "r_lagerkorrekturGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}