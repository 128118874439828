import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ZahlungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "zahlung-list";
        this.title = "zahlung-list.zahlung-list_caption";
        this.addModel({
            "id": "$m_Zahlung",
            "webApiAction": "ERP/Verkauf/Zahlung",
            "webApiExpand": {
                'Zahlungsart': null,
                'Waehrung': null,
                'Geschaeftspartner': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Faktura': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                },
                'Eingangsrechnung': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                },
                'KassaBeleg': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_zahlungTyp",
                "caption": "zahlung-list.r_zahlungtyp_caption",
                "bindTo": "Typ",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.ZahlungTyp"
            }, {
                "id": "r_zahlungsart",
                "caption": "zahlung-list.r_zahlungsart_caption",
                "bindTo": "Zahlungsart.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_geschaeftspartner",
                "caption": "zahlung-list.r_geschaeftspartner_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_fakturaNr",
                "caption": "zahlung-list.r_fakturanr_caption",
                "bindTo": "Faktura.Nummer",
                "width": "80px"
            }, {
                "id": "r_fakturaDatum",
                "caption": "zahlung-list.r_fakturadatum_caption",
                "bindTo": "Faktura.Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_eingangsrechnungNr",
                "caption": "zahlung-list.r_eingangsrechnungnr_caption",
                "bindTo": "Eingangsrechnung.Nummer",
                "width": "80px"
            }, {
                "id": "r_eingangsrechnungDatum",
                "caption": "zahlung-list.r_eingangsrechnungdatum_caption",
                "bindTo": "Eingangsrechnung.Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_kassabelegNr",
                "caption": "zahlung-list.r_kassabelegnr_caption",
                "bindTo": "KassaBeleg.Nummer",
                "width": "80px"
            }, {
                "id": "r_kassabelegDatum",
                "caption": "zahlung-list.r_kassabelegdatum_caption",
                "bindTo": "KassaBeleg.Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_belegNr",
                "caption": "zahlung-list.r_belegnr_caption",
                "bindTo": "BelegNr",
                "width": "80px"
            }, {
                "id": "r_belegdatum",
                "caption": "zahlung-list.r_belegdatum_caption",
                "bindTo": "Belegdatum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_betrag",
                "caption": "zahlung-list.r_betrag_caption",
                "bindTo": "Betrag",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_skonto",
                "caption": "zahlung-list.r_skonto_caption",
                "bindTo": "Skonto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_waehrung",
                "caption": "zahlung-list.r_waehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }],
            "optionsToolbar": {
                "optionsName": "r_zahlungGridToolbarOptions",
                "optionsNameFQ": "r_zahlungGridToolbarOptions"
            },
            "caption": "zahlung-list.r_zahlunggrid_caption",
            "binding": {
                "dataContext": "$m_Zahlung"
            },
            "dataModel": "$m_Zahlung",
            "editUrl": "ERP/Verkauf/Zahlung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_zahlungGrid",
            "options": {
                "optionsName": "r_zahlungGridOptions",
                "optionsNameFQ": "r_zahlungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}