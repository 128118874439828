import { DynFeldLogikTyp } from './../../../enumerations/dyn-feld-logik-typ';
import { autoinject, transient } from "aurelia-framework";
import { OnAuftragIntercompanyErstellen, OnAuftragIntercompanyErstellenEvent, OnBestellungBestaetigt, OnBestellungBestaetigtEvent, OnBestellungKondAktualisieren, OnBestellungKondAktualisierenEvent, OnBestellungPositionenSortierenEvent, OnBestellungspositionenInNeueBestellungUebernehmen, OnBestellungspositionenInNeueBestellungUebernehmenEvent, OnDeleteIdBestellungspositionListEvent, OnErstelleWarenuebernahmeAusBestellung, OnErstelleWarenuebernahmeAusBestellungEvent, OnKopierenRequest, OnKopierenRequestEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { LocationService } from "../../../../framework/base/services/location-service";
import { ContextMenu, FormBase, ICommandData } from "../../../../framework/forms/export";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { BelegExportService } from "../../../services/beleg-export-service";
import { DynFelderDataService, DynFelderService, FilialeService, FirmaService, GeschaeftspartnerService, LagerService, StartupService, ValidationExService } from "../../../services/export";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { AsyncService } from "./../../../../framework/base/services/async-service";

@autoinject
@transient()
export class BestellungEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderData: DynFelderDataService,
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _dynFelderService: DynFelderService,
    private _filialeService: FilialeService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _lagerService: LagerService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 1,
    isVisibleExpression: "functions.$f_BestellungEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_BestellungEditUtils.isBelegExportMandantAktiv && models.data.$m_Bestellung.Id > 0",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Bestellung.Id;
      this._belegExportService.export(BelegImportTyp.Bestellung, [id]);
    }
  };
  bestaetigenCommand: ICommandData = {
    id: "bestaetigen",
    idCategory: "bestellung",
    title: "",
    icon: "",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const args: OnBestellungBestaetigt = {
        IdBestellung: this.form.models.data.$m_Bestellung.Id,
        IsBestaetigt: false
      };

      if (!this.form.models.data.$m_Bestellung.IsBestaetigt) {
        args.IsBestaetigt = true;
      }

      const webEventResult: OnBestellungBestaetigt = await this._webEventService.execute(new OnBestellungBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };
  icAuftragErstellenCommand: ICommandData = {
    id: "doICAuftragErstellen",
    idCategory: "bestellung",
    icon: "plus-circle",
    title: "bestellung.ic_auftrag_erstellen",
    sortIndex: 999,
    isEnabledExpression: "!models.data.$m_Bestellung.IdIcAuftrag",
    isVisibleExpression: "functions.$f_BestellungEditUtils.startupService.startupInfo.Lizenz.HasIntercompany && models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0 && models.data.$m_Bestellung.IsIntercompany",
    execute: async (e: any) => {
      const webEvent = new OnAuftragIntercompanyErstellenEvent({
        IdBestellung: this.form.models.data.$m_Bestellung.Id
      });

      const webEventResult: OnAuftragIntercompanyErstellen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdBestellung) {
        return;
      }

      const navigationUrl = `ERP/Verkauf/Auftrag/${webEventResult.IdAuftrag}`;

      this._locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  };
  doWarenuebernahmeErstellenCommand: ICommandData = {
    id: "doWarenuebernahmeErstellen",
    idCategory: "bestellung",
    icon: "plus-circle",
    title: "bestellung.warenuebernahme_erstellen",
    sortIndex: 999,
    isEnabledExpression: "models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0 && !models.data.$m_Bestellung.HasStreckengeschaeftAuftrag && !models.data.$m_Bestellung.IsIntercompany ",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Bestellung.TypeName,
        this.form.models.data.$m_Bestellung.Id,
        "WARENUEBERNAHME_ERSTELLEN"
      );

      if (!validationResult) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnErstelleWarenuebernahmeAusBestellungEvent({
        IdBestellung: this.form.models.data.$m_Bestellung.Id,
        UebernehmeAllePositionen: selectedKeys.length === 0,
        IdBestellungPosList: selectedKeys
      });

      const webEventResult: OnErstelleWarenuebernahmeAusBestellung = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdWarenuebernahme) {
        return;
      }

      const navigationUrl = `ERP/Einkauf/Warenuebernahme/${webEventResult.IdWarenuebernahme}`;

      if (ctrlKeyPressed) {
        this._locationService.openWindow(navigationUrl);
        this.form.models.reloadAll();
      } else {
        this._locationService.goTo({
          url: navigationUrl,
          clearStack: false
        });
      }
    }
  };
  bestellungKopieErstellenCommand: ICommandData = {
    id: "bestellungKopieErstellen",
    idCategory: "split",
    icon: "plus-circle",
    title: "bestellung.bestellung_erstellen",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0 && !models.data.$m_Bestellung.IsIntercompany",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnBestellungspositionenInNeueBestellungUebernehmenEvent({
        IdBestellung: this.form.models.data.$m_Bestellung.Id,
        IdBestellungPosList: selectedKeys
      });

      const webEventResult: OnBestellungspositionenInNeueBestellungUebernehmen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdBestellung) {
        return;
      }

      this._locationService.goTo({
        url: `ERP/Einkauf/Bestellung/${webEventResult.IdBestellungKopie}`,
        clearStack: false
      });

      this.form.models.reloadAll();
    }
  };
  neueKonditionenLaden: ICommandData = {
    id: "neueKonditionenLaden",
    idCategory: "konditionen",
    icon: "refresh",
    title: "bestellung.konditionen_laden",
    sortIndex: 1,
    isEnabledExpression: "models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const args: OnBestellungKondAktualisieren = {
        IdBestellung: this.form.models.data.$m_Bestellung.Id,
        AllePositionen: selectedKeys.length === 0,
        IdBestellungPosList: selectedKeys
      };

      await this._webEventService.execute(new OnBestellungKondAktualisierenEvent(args), true);
      return this.form.models.reloadAll();
    }
  };
  positionenSortierenCommand: ICommandData = {
    id: "positionenSortieren",
    idCategory: "positionen",
    icon: "sort-alpha-asc",
    title: "erp.positionen-sortieren",
    sortIndex: 10,
    isEnabledExpression: "models.data.$m_Bestellung.CanSave && models.data.$m_Bestellung.Id > 0",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const sort = async (typ) => {
        const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
        await this._webEventService.execute(new OnBestellungPositionenSortierenEvent({
          IdBestellung: this.form.models.data.$m_Bestellung.Id,
          Typ: typ
        }), true);

        grid.instance.refresh();
      };

      const contextMenu = new ContextMenu();
      contextMenu.items.push({
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelnr"),
        execute: () => {
          sort(0);
        }
      }, {
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelbezeichnung"),
        execute: () => {
          sort(1);
        }
      });

      contextMenu.show(e.event.target);
    }
  };
  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Bestellung.CanSave && !models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      const bestellungPosGridRowData = grid.instance.getSelectedRowsData();

      if (bestellungPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("bestellung.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const bestellungsposition of bestellungPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          bestellungsposition.TypeName,
          bestellungsposition.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const bestellungsposition of bestellungPosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: bestellungsposition.Id,
          CustomData: { "PosNrUeberschreiben": true }
        }),
          true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Bestellung.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      const bestellungPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (bestellungPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("bestellung.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (bestellungPosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("bestellung.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const bestellungsposition of bestellungPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          bestellungsposition.TypeName,
          bestellungsposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = bestellungPosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdBestellungspositionListEvent({
        IdBestellungspositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const lieferant = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_lieferant'] .dx-searchbox input");
      if (!lieferant) {
        return;
      }

      lieferant.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Bestellung",
      idArtPropertyName: "IdBestellungsart",
      idVorgEntitaetPropertyName: "IdLieferant"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Bestellung",
      kalkulationRef: "r_kalkulation",
      editPopups: ["bestellungspositionEditPopup", "bestellungsgruppeEditPopup"]
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "bestellungspositionEditPopup") {
        form["r_bestellungsgruppeGrid"].instance.refresh();
      } else if (r.editPopup.id == "bestellungsgruppeEditPopup") {
        form["r_bestellungspositionGrid"].instance.refresh();
      }

      return Promise.resolve();
    });

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Bestellung") {
        return;
      }

      this.bestaetigenCommand.isVisible = this.form.models.data.$m_Bestellung.CanSave
        && this.form.models.data.$m_Bestellung.Id > 0;

      if (this.form.models.data.$m_Bestellung.IsBestaetigt) {
        this.bestaetigenCommand.title = "bestellung.nicht_bestaetigen";
        this.bestaetigenCommand.icon = "star";
      } else {
        this.bestaetigenCommand.title = "bestellung.bestaetigen";
        this.bestaetigenCommand.icon = "star-o";
      }

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Bestellung);
      return Promise.resolve();
    });
    form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        if (this.startupService.startupInfo.Lizenz.HasIntercompany && this.form.models.data.$m_Bestellung.IdIcAuftrag) {
          const dynFeldIntercompany = this.dynFelderData.dynFeldList
            .filter(c => c.LogikTyp == DynFeldLogikTyp.Intercompany)[0] || null;

          if (dynFeldIntercompany == null) {
            return Promise.resolve();
          }

          const dynFeldEintragIntercompany = this.form.models.data.$m_Bestellung.DynamischesFeldContainer.DynamischesFeldEintraege
            .filter(c => c.IdDynamischesFeld == dynFeldIntercompany.Id)[0] || null;

          if (!dynFeldEintragIntercompany || !dynFeldEintragIntercompany.IsGeaendert) {
            return Promise.resolve();
          }

          if (dynFeldEintragIntercompany.WertNummer == 0) {
            const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
              this._localizationService.translateOnce("bestellung.sure_intercompany"),
              this._localizationService.translateOnce("base.question")));

            if (!doExecute) {
              return Promise.reject();
            }
            return Promise.resolve();
          }
        }
      }
      return Promise.resolve();
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_BestellungspositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Bestellung") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Bestellung"];
          if (model) {
            model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);

            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Bestellung",
                bindTo: "IdLieferfiliale"
              },
              value: model.IdLieferfiliale
            });
          }
        }

        if (args.binding.bindTo === "IdLieferfiliale") {
          const model = form.models.data["$m_Bestellung"];
          if (model) {
            model.IdLieferlager = await this._lagerService.getIdLagerVorbelegung(args.value);

            const idWaehrung = await this._filialeService.getWaehrungIdOfFiliale(args.value);
            if (idWaehrung != null) {
              model.IdWaehrung = idWaehrung;
            } else {
              model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Bestellung.IdFirma);
            }
          }
        }
      }
    });
  }

  async onLieferantChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }

  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Bestellung.CanSave && this.form.models.data.$m_Bestellung.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Bestellung.IdLieferant);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Bestellung.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Bestellung",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
