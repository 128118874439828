import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    BestellungspositionEditUtils
} from "./bestellungsposition-edit-utils";

@fwx.autoinject
export class BestellungspositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_BestellungspositionEditUtils: BestellungspositionEditUtils) {
        super(element, formBaseImport);
        this.id = "bestellungsposition-edit";
        this.title = "bestellungsposition-edit.bestellungsposition-edit_caption";
        this.addModel({
            "id": "$m_Bestellungsposition",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "webApiExpand": {
                'Artikel': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Bestellung.CanSave && !models.data.$m_Bestellung.HasStreckengeschaeftAuftrag && !models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "custom": {
                'copyFunc': '$f_BestellungspositionEditUtils.copyBestellungsposition'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Bestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Bestellungsposition.IdBestellung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungsgruppeEdit",
            "webApiAction": "ERP/Einkauf/Bestellungsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungspositionStuecklisteRel",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "webApiExpand": {
                'Artikel': null,
                'KalkulationContainer': null,
                'Angebotsgruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
                'BestellungspositionStuecklisteInfo': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungspositionStuecklisteEdit",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungspositionEditUtils.showVariablerSetartikelPreisAendernCommand",
                "bindToFQ": "functions.$f_BestellungspositionEditUtils.showVariablerSetartikelPreisAendernCommand"
            }
        });
        this.addEditPopup({
            "idContent": "bestellungspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BestellungspositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BestellungspositionStuecklisteEdit.Id"
                }
            }],
            "id": "bestellungspositionEditPopup",
            "options": {
                "optionsName": "bestellungspositionEditPopupOptions",
                "optionsNameFQ": "bestellungspositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "bestellungsgruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BestellungsgruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BestellungsgruppeEdit.Id"
                }
            }],
            "id": "bestellungsgruppeEditPopup",
            "options": {
                "optionsName": "bestellungsgruppeEditPopupOptions",
                "optionsNameFQ": "bestellungsgruppeEditPopupOptions"
            },
            "caption": "bestellungsposition-edit.bestellungsgruppeeditpopup_caption",
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_BestellungspositionEditUtils", $f_BestellungspositionEditUtils, "functions.$f_BestellungspositionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "bestellungsposition-edit.r_menge_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "MengeVerpEH",
                "bindToFQ": "models.data.$m_Bestellungsposition.MengeVerpEH"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_menge",
            "options": {
                "optionsName": "r_mengeOptions",
                "optionsNameFQ": "r_mengeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": ["IdArtikel", {
                "isBound": true,
                "expression": "models.data.$m_Bestellungsposition.IdArtikel"
            }],
            "filters": [],
            "caption": "bestellungsposition-edit.r_einheit_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Bestellungsposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_bespPosNr",
                "caption": "bestellungsposition-edit.r_bespposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_bespArtikelNr",
                "caption": "bestellungsposition-edit.r_bespartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_bespBezeichnung",
                "caption": "bestellungsposition-edit.r_bespbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bespMengeVerpEH",
                "caption": "bestellungsposition-edit.r_bespmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespMengeWarenuebernahmeVerpEH",
                "caption": "bestellungsposition-edit.r_bespmengewarenuebernahmeverpeh_caption",
                "bindTo": "MengeWarenuebernahmeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespMengeGeliefertVerpEH",
                "caption": "bestellungsposition-edit.r_bespmengegeliefertverpeh_caption",
                "bindTo": "MengeGeliefertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespEinheit",
                "caption": "bestellungsposition-edit.r_bespeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespPositionsart",
                "caption": "bestellungsposition-edit.r_besppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespWertNetto",
                "caption": "bestellungsposition-edit.r_bespwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespWertSteuer",
                "caption": "bestellungsposition-edit.r_bespwertsteuer_caption",
                "bindTo": "KalkulationContainer.WertSteuer",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespWertBrutto",
                "caption": "bestellungsposition-edit.r_bespwertbrutto_caption",
                "bindTo": "KalkulationContainer.WertBrutto",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellungspositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_bestellungspositionStuecklistenGridToolbarOptions"
            },
            "caption": "bestellungsposition-edit.r_bestellungspositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Bestellungsposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_BestellungspositionStuecklisteRel",
                "bindTo": "IdSetartikelBestellungsposition",
                "bindToFQ": "models.data.$m_BestellungspositionStuecklisteRel.IdSetartikelBestellungsposition"
            },
            "dataModel": "$m_Bestellungsposition",
            "editDataContext": "$m_BestellungspositionStuecklisteEdit",
            "idEditPopup": "bestellungspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellungspositionStuecklistenGrid",
            "options": {
                "optionsName": "r_bestellungspositionStuecklistenGridOptions",
                "optionsNameFQ": "r_bestellungspositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "bestellungsposition-edit.r_isvariablersetartikel_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellungsposition.Id > 0",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "IsVariablerSetartikel",
                "bindToFQ": "models.data.$m_Bestellungsposition.IsVariablerSetartikel"
            },
            "validationRules": [],
            "id": "r_isVariablerSetartikel",
            "options": {
                "optionsName": "r_isVariablerSetartikelOptions",
                "optionsNameFQ": "r_isVariablerSetartikelOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bestellungsgruppe",
            "filter": ["IdBestellung", {
                "isBound": true,
                "expression": "models.data.$m_Bestellungsposition.IdBestellung"
            }],
            "filters": [],
            "caption": "bestellungsposition-edit.r_bestellungsgruppe_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "IdBestellungsgruppe",
                "bindToFQ": "models.data.$m_Bestellungsposition.IdBestellungsgruppe"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_BestellungspositionEditUtils.onBestellungsgruppeChanged",
            "id": "r_bestellungsgruppe",
            "options": {
                "optionsName": "r_bestellungsgruppeOptions",
                "optionsNameFQ": "r_bestellungsgruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "bestellungsposition-edit.r_posnr_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Bestellungsposition.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "bestellungsposition-edit.r_lieferantartikelnummer_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "LieferantArtikelNr",
                "bindToFQ": "models.data.$m_Bestellungsposition.LieferantArtikelNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_lieferantArtikelNummer",
            "options": {
                "optionsName": "r_lieferantArtikelNummerOptions",
                "optionsNameFQ": "r_lieferantArtikelNummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "bestellungsposition-edit.r_lieferantartikelbezeichnung_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "LieferantArtikelbezeichnung",
                "bindToFQ": "models.data.$m_Bestellungsposition.LieferantArtikelbezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_lieferantArtikelbezeichnung",
            "options": {
                "optionsName": "r_lieferantArtikelbezeichnungOptions",
                "optionsNameFQ": "r_lieferantArtikelbezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "positionsart",
            "filters": [],
            "caption": "bestellungsposition-edit.r_positionsart_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsposition",
                "bindTo": "IdPositionsart",
                "bindToFQ": "models.data.$m_Bestellungsposition.IdPositionsart"
            },
            "validationRules": [],
            "id": "r_positionsart",
            "options": {
                "optionsName": "r_positionsartOptions",
                "optionsNameFQ": "r_positionsartOptions"
            }
        });
        super.onConstructionFinished();
    }
}