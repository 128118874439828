import * as fwx from "../../../../framework/forms/form-export";
import {
    BestellvorschlagListUtils
} from "./bestellvorschlag-list-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BestellvorschlagListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BestellvorschlagListUtils: BestellvorschlagListUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "bestellvorschlag-list";
        this.title = "bestellvorschlag-list.bestellvorschlag-list_caption";
        this.addModel({
            "id": "$m_Bestellvorschlag",
            "webApiAction": "ERP/Einkauf/Bestellvorschlag",
            "webApiExpand": {
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Lieferfiliale': null,
                'Status': null,
                'Projekt': null,
                'Lieferant': {
                    'expand': {
                        'Adresse': null
                    }
                },
                'Auftrag': {
                    'columns': [
                        'Id',
                        'Nummer'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagListUtils.doArtikelBestellenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagListUtils.doArtikelBestellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagListUtils.doArtikelBestellenUndVersendenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagListUtils.doArtikelBestellenUndVersendenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagListUtils.doArtikelIgnorierenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagListUtils.doArtikelIgnorierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellvorschlagListUtils.doBestellvorschlagRechnenCommand",
                "bindToFQ": "functions.$f_BestellvorschlagListUtils.doBestellvorschlagRechnenCommand"
            }
        });
        this.addFunction("$f_BestellvorschlagListUtils", $f_BestellvorschlagListUtils, "functions.$f_BestellvorschlagListUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_besvoLieferfiliale",
                "caption": "bestellvorschlag-list.r_besvolieferfiliale_caption",
                "bindTo": "Lieferfiliale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_besvoBVTyp",
                "caption": "bestellvorschlag-list.r_besvobvtyp_caption",
                "bindTo": "Artikel.BestellvorschlagTyp",
                "minWidth": 100,
                "enumTypeName": "TIP.ERP.Business.Enumerations.BestellvorschlagTyp"
            }, {
                "id": "r_besvoProjekt",
                "caption": "bestellvorschlag-list.r_besvoprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_besvoAuftragNr",
                "caption": "bestellvorschlag-list.r_besvoauftragnr_caption",
                "bindTo": "Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_besvoLieferantNr",
                "caption": "bestellvorschlag-list.r_besvolieferantnr_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "80px"
            }, {
                "id": "r_besvoFirmenbez1",
                "caption": "bestellvorschlag-list.r_besvofirmenbez1_caption",
                "bindTo": "Lieferant.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_besvoOrt",
                "caption": "bestellvorschlag-list.r_besvoort_caption",
                "bindTo": "Lieferant.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_besvoArtikelNr",
                "caption": "bestellvorschlag-list.r_besvoartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_besvoBezeichnung",
                "caption": "bestellvorschlag-list.r_besvobezeichnung_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_besvoBeschaffungBis",
                "caption": "bestellvorschlag-list.r_besvobeschaffungbis_caption",
                "bindTo": "BeschaffungBis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besvoLagerbestand",
                "caption": "bestellvorschlag-list.r_besvolagerbestand_caption",
                "bindTo": "LagerbestandLagEH",
                "width": "80px"
            }, {
                "id": "r_besvoBestellmengeLagEH",
                "caption": "bestellvorschlag-list.r_besvobestellmengelageh_caption",
                "bindTo": "BestellmengeLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_besvoLagereinheit",
                "caption": "bestellvorschlag-list.r_besvolagereinheit_caption",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_besvoStatus",
                "caption": "bestellvorschlag-list.r_besvostatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellvorschlagGridToolbarOptions",
                "optionsNameFQ": "r_bestellvorschlagGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bestellvorschlag"
            },
            "dataModel": "$m_Bestellvorschlag",
            "editUrl": "ERP/Einkauf/Bestellvorschlag",
            "addShortscuts": true,
            "isMainList": true,
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellvorschlagGrid",
            "options": {
                "optionsName": "r_bestellvorschlagGridOptions",
                "optionsNameFQ": "r_bestellvorschlagGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}