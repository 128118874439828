
import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase,
  ICommandData
} from "../../../../framework/forms/export";
import {
  OnImportLieferscheinInLadelisteEvent,
  OnImportLieferscheinInLadeliste,
  OnLadelisteEntferneLieferscheineEvent,
  OnLadelisteEntferneLieferscheine
} from "./../../../../framework-data/events";
import {
  IdxDataGridComponent
} from "../../../interfaces/export";
import {
  WebEventService,
  LocalizationService
} from "../../../../framework/base/export";
import { DynFelderDataService } from "../../../services/export";
import { AsyncService } from '../../../../framework/base/services/async-service';


@autoinject
@transient()
export class LadelisteEditUtils {
  constructor(
    public dynFelderDataService: DynFelderDataService,
    private _webEventService: WebEventService,
    private _localizationService: LocalizationService,
    private _asyncService: AsyncService
  ) { }

  form: FormBase;

  showImportLieferscheinPopupCommand: ICommandData = {
    id: "showImportLieferscheinPopupCommand",
    icon: "plus",
    idCategory: "ladeliste",
    sortIndex: 0,
    title: "ladeliste.lieferscheinpos_importieren",
    isEnabledExpression: "models.data.$m_Ladeliste.CanSave && models.data.$m_Ladeliste.Status && models.data.$m_Ladeliste.Status.Typ === 0",
    execute: async () => {
      const validationResult = await this.form.saveIfDirty();
      if (!validationResult.isValid) {
        return;
      }

      const lieferscheinpositionGrid: IdxDataGridComponent = this.form["r_lieferscheinGrid"];
      if (lieferscheinpositionGrid && lieferscheinpositionGrid.instance) {
        await this._asyncService.convertToPromise(lieferscheinpositionGrid.instance.deselectAll());
        lieferscheinpositionGrid.instance.refresh();
      }

      this.form["r_lieferscheinEditPopup"].instance.show();
    }
  }
  importLieferscheinpositionCommand: ICommandData = {
    id: "importLieferscheinpositionCommand",
    title: "ladeliste.mark_pos_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_lieferscheinGrid"];

      const webEvent = new OnImportLieferscheinInLadelisteEvent({
        IdLadeliste: this.form.models.data.$m_Ladeliste.Id,
        IdLieferscheinList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportLieferscheinInLadeliste = await this._webEventService.execute(webEvent, true);

      this.form["r_lieferscheinEditPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  }
  lieferscheinEntfernenCommand: ICommandData = {
    id: "lieferscheinEntfernenCommand",
    idCategory: "ladeliste-lieferschein-weiteres",
    icon: "minus-square",
    title: "ladeliste.lieferschein_entfernen",
    isEnabledExpression: "models.data.$m_Ladeliste.CanSave",
    sortIndex: 0,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_ladelistenpositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const args: OnLadelisteEntferneLieferscheine = {
        IdLadeliste: this.form.models.data.$m_Ladeliste.Id,
        IdLadelistePosList: selectedKeys
      };

      const webEventResult: OnLadelisteEntferneLieferscheine = await this._webEventService.execute(new OnLadelisteEntferneLieferscheineEvent(args), true);
      if (selectedKeys.length === 0) {
        DevExpress.ui.notify(this._localizationService.translateOnce("ladeliste.kein_lieferschein_ausgewaehlt"), "error", 3000);
        return;
      }

      return this.form.models.reloadAll();
    }
  }

  bind(form: FormBase) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Ladeliste"
    });
  }
}


