import {
  autoinject,
  bindable,
  bindingMode,
  observable,
  TaskQueue
} from "aurelia-framework";
import {
  DataSourceService,
  LocalizationService,
  WebEventService
} from "../../../framework/base/export";
import {
  AdresseService
} from "../../services/export";

import * as Interfaces from "../../interfaces/export";
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { ModelUtilsService } from '../../../framework/forms/export';
import { OnLandPlzQueryOrtEvent, OnLandPlzQueryOrt } from '../../../framework-data/events';

@autoinject
export class Adresse {
  constructor(
    private adresseService: AdresseService,
    private dataSource: DataSourceService,
    private localization: LocalizationService,
    private taskQueue: TaskQueue,
    private modelUtilsService: ModelUtilsService,
    private webEventService: WebEventService
  ) { }

  @bindable @observable mainModel: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) idAdresse: number;
  @bindable isAdresseReadOnly: boolean;
  @bindable isFirmenbezHidden: boolean;
  @bindable isDelayed: boolean;

  /** Muss leider so sein - durch mehrfaches Binding und öftere Änderung führt dies sonst zu Problemen */
  @bindable adressePropertyName: string;

  adresse: Interfaces.IAdresse;

  scopeContainer: ScopeContainer;

  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "custom",
        message: "Pflichtfeld",
        validationCallback: (e) => {
          return this.isAdresseReadOnly == true
            ? true
            : e.value ? true : false;
        }
      }]
  };

  firmenbez1: Interfaces.IdxTextBoxComponent;
  firmenbez1Options: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    placeholder: this.localization.translateOnce("adresse.firmenbez1"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.Firmenbez1"
    }
  };
  firmenbez2: Interfaces.IdxTextBoxComponent;
  firmenbez2Options: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    placeholder: this.localization.translateOnce("adresse.firmenbez2"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.Firmenbez2"
    }
  };
  firmenbez3: Interfaces.IdxTextBoxComponent;
  firmenbez3Options: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    placeholder: this.localization.translateOnce("adresse.firmenbez3"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.Firmenbez3"
    }
  };
  plz: Interfaces.IdxTextBoxComponent;
  plzOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 10,
    placeholder: this.localization.translateOnce("adresse.postleitzahl"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();

      this.taskQueue.queueMicroTask(() => {
        this.checkLandPlzOrt();
      });
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.PLZ"
    }
  };
  ort: Interfaces.IdxTextBoxComponent;
  ortOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    placeholder: this.localization.translateOnce("adresse.ort"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.Ort"
    }
  };
  strasse: Interfaces.IdxTextBoxComponent;
  strasseOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    placeholder: this.localization.translateOnce("adresse.strasse"),
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.Strasse"
    }
  };
  land: Interfaces.IdxSelectBoxComponent;
  landOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    searchEnabled: true,
    placeholder: this.localization.translateOnce("adresse.land"),
    inputAttr: {
      autocomplete: "Land"
    },
    onValueChangedByUser: () => {
      this.setMainModelDirty();
    },
    bindingOptions: {
      readOnly: "isAdresseReadOnly",
      value: "adresse.IdLand",
    }
  };

  bind(bindingContext: any) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.landOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
      webApiAction: "ERP/Stammdaten/Land",
      webApiColumns: ["Id", "Bezeichnung"],
      keyProperty: "Id"
    });

    if (this.isDelayed && this.mainModel) {
      const adresse = this.mainModel[this.adressePropertyName];
      if (adresse) {
        this.setAdresse(adresse);
      }
      if (this.idAdresse) {
        this.idAdresseChanged(this.idAdresse);
      }
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  mainModelChanged(newVal, oldVal) {
    if (!newVal) {
      return;
    }

    if (this.adressePropertyName) {
      if (newVal && oldVal && newVal.Id == oldVal.Id) {
        this.taskQueue.queueMicroTask(() => {
          this.adresseService
            .getAdresseById(this.idAdresse)
            .then(r => {
              this.setAdresse(r);
            });
        })
      } else if (!newVal.Id) {
        this.setAdresse({});
      }
    }
  }
  idAdresseChanged(newVal) {
    if (!newVal) {
      return;
    }

    this.adresseService
      .getAdresseById(newVal)
      .then(r => {
        this.setAdresse(r);
      });
  }

  resetAdresse() {
    if (this.adresse == null) {
      this.setAdresse({});
    }

    this.adresse.Firmenbez1 = null;
    this.adresse.Firmenbez2 = null;
    this.adresse.Firmenbez3 = null;
    this.adresse.Strasse = null;
    this.adresse.IdLand = null;
    this.adresse.PLZ = null;
    this.adresse.Ort = null;
  }
  setAdresse(adresse: any) {
    //Prüfen, dass nicht schon ein anderer Datensatz geladen wurde ...
    if (adresse.Id && adresse.Id != this.idAdresse) {
      return;
    }

    this.adresse = adresse;

    if (this.mainModel && this.adressePropertyName) {
      this.mainModel[this.adressePropertyName] = adresse;
    }
  }
  setAdresseByGeschaeftspartner(geschaeftspartner: any) {
    if (geschaeftspartner) {
      if (this.adresse == null) {
        this.setAdresse({});
      }

      const adr = geschaeftspartner.Adresse;

      this.adresse.Firmenbez1 = adr.Firmenbez1;
      this.adresse.Firmenbez2 = adr.Firmenbez2;
      this.adresse.Firmenbez3 = adr.Firmenbez3;
      this.adresse.Strasse = adr.Strasse;
      this.adresse.IdLand = adr.IdLand;
      this.adresse.PLZ = adr.PLZ;
      this.adresse.Ort = adr.Ort;
    } else {
      this.resetAdresse();
    }
  }
  setMainModelDirty() {
    if (!this.mainModel) {
      return;
    }

    this.modelUtilsService.setDirty(this.mainModel);
  }
  private async checkLandPlzOrt() {
    if (!this.adresse) {
      return;
    }

    if (!this.adresse.PLZ || !this.adresse.IdLand) {
      return;
    }

    const r: OnLandPlzQueryOrt = await this.webEventService.execute(new OnLandPlzQueryOrtEvent({
      IdLand: this.adresse.IdLand,
      Plz: this.adresse.PLZ
    }));

    if (!r.Ort) {
      return;
    }

    this.adresse.Ort = r.Ort;
  }
}
