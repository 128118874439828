import { autoinject, bindable, OverrideContext, observable } from "aurelia-framework";
import { IListViewOptions, ListView } from "../../../../framework/forms/elements/list-view/export";
import { DataSourceService, ScopeContainer, LocationService, RestService, IViewScrollInfo } from "../../../../framework/base/export";
import { EntitaetInfoService } from "../../../services/entitaet-info-service";
import { PopupInfoService } from "../../../../framework/forms/export";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { NotificationTyp } from '../../../enumerations/notification-typ';

@autoinject
export class VerknuepfteEntitaet {
  private _refreshSubscription: Subscription;

  constructor(
    private dataSource: DataSourceService,
    private entitaetInfo: EntitaetInfoService,
    private location: LocationService,
    private popupInfo: PopupInfoService,
    private eventAggregator: EventAggregator,
    private restService: RestService
  ) {}

  @bindable @observable data;
  
  scopeContainer: ScopeContainer;

  verknEntitaetListView: ListView;
  verknEntitaetListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 15,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.entitaetInfo
        .getUrl(e.item.VerknuepftTypeName, e.item.IdVerknuepft)
        .then(r => {
          this.popupInfo.closeAllPopups().then(async pr => {
            if (!pr) {
              return;
            }

            const viewScrollInfo = await this.getViewScrollInfo(e);

            this.location.goTo({
              url: r,
              viewScrollInfo: viewScrollInfo
            });
          })
        });
    }
  }

  showNotizenButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-comments-o",
    onClick: (e) => {
      this.showNotizListPopover(e.event, e.model.bindingContext.item);
    }
  };

  isVisible: boolean = false;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });

    this.verknEntitaetListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/VerknuepfteEntitaet",
        webApiColumns: ["Id", "IdVerknuepft", "VerknuepftTypeName", "VerknuepftBezeichnung", "HasVerknuepftNotiz", "TypeName"],
        filters: [{ webApiCustomKey: "InklIdStatusFarbe", webApiCustomValue: "true" }],
        webApiOrderBy: [{columnName: "AnlageDatum", sortOrder: 0}]
      }, {
        canLoad: () => {
          return this.data && this.data.Id;
        },
        getCustomWhere: () => {
          return ["IdEntitaet", this.data.Id];
        },
        resultInterceptor: (data) => {
          this.isVisible = data.count > 0;
          return data;
        }
      });

    this._refreshSubscription = this.eventAggregator.subscribe("verknuepfte-entitaet:refresh", e => {
      if (this.data && this.data.Id == e.id) {
        this.dataChanged();
      }
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this._refreshSubscription.dispose();
  }

  dataChanged() {
    if (this.verknEntitaetListView) {
      this.verknEntitaetListView.refresh(false);
    }
  }

  showNotizListPopover(e: DevExpress.event, data) {
    e.stopPropagation();

    this.eventAggregator.publish(
      NotificationTyp.notizShowListPopover, {
        id: data.IdVerknuepft,
        target: e.target
      }
    );
  }
  
  private async getViewScrollInfo(e): Promise<any> {
    const id = e.item.Id;
    const typeName = e.item.VerknuepftTypeName;
    const url = this.restService.getWebApiUrl("ERP/Stammdaten/VerknuepfteEntitaet");
    const orderBy = [{ columnName: "AnlageDatum", sortOrder: 0 }];
    const where = [["IdEntitaet", this.data.Id], ["VerknuepftTypeName", typeName]];

    const viewScrollInfo = await this.entitaetInfo.createViewScrollInfo(
      "TIP.ERP.Business.Entitaeten.Stammdaten.VerknuepfteEntitaet",
      where,
      null,
      orderBy,
      id
    );

    if (!viewScrollInfo) {
      return null;
    }

    viewScrollInfo.columns = ["IdVerknuepft"];

    viewScrollInfo.resultKeyResolver = (key, data) => {
      if (this.entitaetInfo.needsParentTypeNameForUrl(typeName)) {
        return this.entitaetInfo.getParentId(typeName, data.IdVerknuepft);
      } else {
        return data.IdVerknuepft;
      }
    };

    return viewScrollInfo;
  }
}
