import { autoinject, bindable } from "aurelia-framework";
import { GlobalizationService } from "../../../../../framework/base/services/globalization-service";

@autoinject
export class CronJobs {
  constructor(
    private _globalizationService: GlobalizationService
  ) {}
  
  @bindable status;

  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      caption: "Host",
      dataField: "HostName",
      width: 120
    }, {
      caption: "Prozess",
      dataField: "ProcessName",
      width: 120
    }, {
      caption: "Name",
      dataField: "Name"
    }, {
      caption: "Aktiv?",
      dataField: "IsExecuting",
      width: 120
    }, {
      caption: "Letzte Ausführung",
      dataField: "LastExecution",
      format: this._globalizationService.getFormatter("g"),
      width: 120
    }, {
      caption: "Letzter Status",
      dataField: "LastState"
    }, {
      caption: "Nächste Ausführung",
      dataField: "NextExecution",
      format: this._globalizationService.getFormatter("g"),
      width: 120
    }],
    allowColumnReordering: true,
    allowColumnResizing: true,
    filterRow: {
      visible: true
    },
    headerFilter: {
      visible: true
    },
    bindingOptions: {
      "dataSource": "status"
    }
  }
}
