import { EventAggregator } from 'aurelia-event-aggregator';
import { OverrideContext, Scope, autoinject, bindable, observable } from "aurelia-framework";
import { RestService } from '../../../framework/base/export';
import { NotificationTyp } from '../../enumerations/notification-typ';

@autoinject
export class EntitaetMenu {
  constructor(
    private eventAggregator: EventAggregator,
    private restService: RestService) { }

  @bindable @observable data: any;
  @bindable hideAenddat: boolean;

  scope: Scope;

  id: any;
  hasNotiz: boolean;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.dataChanged();
  }

  dataChanged() {
    if (!this.data) {
      return;
    }

    switch (this.data.TypeName) {
      case "TIP.ERP.Business.Entitaeten.Stammdaten.VerknuepfteEntitaet": {
        this.id = this.data.IdVerknuepft;
        this.hasNotiz = this.data.HasVerknuepftNotiz;
        break;
      }
      default: {
        this.id = this.data.Id;
        this.hasNotiz = this.data.HasNotiz;
        break;
      }
    }
  }

  async showNotizListPopover(e: Event) {
    e.stopPropagation();

    const id = await this.getId();
    if (!id) {
      return;
    }

    this.eventAggregator.publish(
      NotificationTyp.notizShowListPopover, {
      id: id,
      target: e.target
    }
    );
  }

  private async getId() {
    if (this.data.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.BriefZuEmpfaenger") {
      const r = await this.restService.get({
        url: this.restService.getWebApiUrl("ERP/Stammdaten/BriefZuEmpfaenger/").concat(this.id),
        getOptions: {
          columns: ["IdBrief"]
        }
      });

      if (!r) {
        return null;
      }

      return r.IdBrief;
    } else {
      return this.id;
    }
  }
}
