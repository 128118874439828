import {
  autoinject
} from "aurelia-framework";

import {
  CustomEditPopup
} from "../../framework/forms/elements/custom-edit-popup/custom-edit-popup";

@autoinject
export class CustomEditPopupService {
  constructor() { }

  artikelEditPopup: CustomEditPopup;
  aufgabeEditPopup: CustomEditPopup;
  benachrichtigungEditPopup: CustomEditPopup;
  briefEditPopup: CustomEditPopup;
  benutzerEinstellungenEditPopup: CustomEditPopup;
  geschaeftspartnerEditPopup: CustomEditPopup;
  mandantWechselnPopup: CustomEditPopup;
  notizEditPopup: CustomEditPopup;
  personEditPopup: CustomEditPopup;
  projektEditPopup: CustomEditPopup;
  prodAuftragEditPopup: CustomEditPopup;
  impressumEditPopup: CustomEditPopup;
}
