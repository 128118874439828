import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitFreieInformationEdit {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IArbeitszeitFreieInformationEditOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  freieInformationDetailList: any[];
  freieInformationDetail: any;
  arbeitszeitFreieInformation: IArbeitszeitFreieInformation;

  valueString: string;
  valueBoolean: boolean;
  valueNumber: number;

  arbeitszeitFreieInformationEditPopup: Interfaces.IdxPopupComponent;
  arbeitszeitFreieInformationEditPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitFreieInformationEditPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitFreieInformationEdit",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (this.freieInformationDetail != null) {
        this.arbeitszeitFreieInformation.IdFreieInformationDetail = this.freieInformationDetail.IdFreieInformationDetail;
        switch (this.freieInformationDetail.ObjectType) {
          case "String": {
            this.arbeitszeitFreieInformation.Wert = this.valueString;
            break;
          }
          case "Boolean": {
            this.arbeitszeitFreieInformation.Wert = this.valueBoolean == true
              ? "True"
              : "False";
            break;
          }
          case "Decimal": {
            this.arbeitszeitFreieInformation.Wert = this.valueNumber.toString();
            break;
          }
        }
      }
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitFreieInformationEditPopup.instance.hide();
      this._options.callback(this.arbeitszeitFreieInformation);
    }
  }];

  arbeitszeitFreieInformationEditValidation: any;
  arbeitszeitFreieInformationEditValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  gueltigVonDateBox: Interfaces.IdxDateBoxComponent;
  gueltigVonDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitFreieInformation.GueltigVon",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };
  gueltigBisDateBox: Interfaces.IdxDateBoxComponent;
  gueltigBisDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitFreieInformation.GueltigBis",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  freieInformationSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "IdFreieInformationDetail",
    bindingOptions: {
      dataSource: "freieInformationDetailList",
      value: "arbeitszeitFreieInformation.IdFreieInformationDetail",
    },
    onValueChangedByUser: (e) => {
      if (this.arbeitszeitFreieInformation.IdFreieInformationDetail > 0) {
        this.freieInformationDetail = this.freieInformationDetailList
          .filter(c => c.IdFreieInformationDetail == this.arbeitszeitFreieInformation.IdFreieInformationDetail)[0] || null;
      }
    }
  };

  valueNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n2"),
    bindingOptions: {
      value: "valueNumber"
    }
  }
  valueTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    bindingOptions: {
      value: "valueString"
    }
  }
  valueCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    bindingOptions: {
      value: "valueBoolean"
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitFreieInformationEditToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.freie-information",
      options: this.arbeitszeitFreieInformationEditPopupOptions,
      commands: this.arbeitszeitFreieInformationEditPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.loadFreieInformationDetailDatasource();
  }

  async show(options: IArbeitszeitFreieInformationEditOptions) {
    this._options = options;
    this.freieInformationDetail = null;
    this.arbeitszeitFreieInformation = null;
    this.valueBoolean = false;
    this.valueNumber = null;
    this.valueString = null;
    this.arbeitszeitFreieInformation = options.arbeitszeitFreieInformation;

    if (this.arbeitszeitFreieInformation.IdFreieInformationDetail > 0) {
      this.freieInformationDetail = this.freieInformationDetailList
        .filter(c => c.IdFreieInformationDetail == this.arbeitszeitFreieInformation.IdFreieInformationDetail)[0] || null;

      if (this.arbeitszeitFreieInformation.Id != null) {
        switch (this.freieInformationDetail.ObjectType) {
          case "String": {
            this.valueString = this.arbeitszeitFreieInformation.Wert
            break;
          }
          case "Boolean": {
            this.valueBoolean = this.arbeitszeitFreieInformation.Wert == "True" ? true : false
            break;
          }
          case "Decimal": {
            this.valueNumber = parseFloat(this.arbeitszeitFreieInformation.Wert);
            break;
          }
        }
      }
    }

    this.arbeitszeitFreieInformationEditPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitFreieInformationEditValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }

    if (this.arbeitszeitFreieInformation.GueltigVon != null
      && this.arbeitszeitFreieInformation.GueltigBis != null
      && this.arbeitszeitFreieInformation.GueltigVon > this.arbeitszeitFreieInformation.GueltigBis) {
      DevExpress.ui.notify(
        "Gültig von Datum darf nicht kleiner als Gültig bis sein",
        "error",
        this.NOTIFY_TIMEOUT);
      return false;
    }

    return true;
  }

  private async loadFreieInformationDetailDatasource() {
    this.freieInformationDetailList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/ArbeitszeitPartei/freieinformationdetail")
    });
  }
}

export interface IArbeitszeitFreieInformationEditOptions {
  arbeitszeitFreieInformation: IArbeitszeitFreieInformation,
  callback: { (arbeitszeitFreieInformation: IArbeitszeitFreieInformation): void };
}

export interface IArbeitszeitFreieInformation {
  Id?: number;
  IdFreieInformationDetail?: number;
  Wert?: string;
  GueltigVon?: Date;
  GueltigBis?: Date;
}
