import {
  autoinject,
  bindable,
  bindingMode
} from "aurelia-framework";
import {
  LocalizationService
} from "../../../framework/base/export";
import {
  FilterService
} from "../../services/export";
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { IdxPopupComponent, IdxFilterBuilderComponent } from '../../interfaces/dx-components';
import { WebEventService } from '../../../framework/base/services/web-event-service';
import { OnFilterFeldRequestEvent, OnFilterFeldRequest } from '../../../framework-data/events';
import { EntitaetInfoService } from '../../services/entitaet-info-service';

@autoinject
export class Filter {
  constructor(
    private element: Element,
    private filter: FilterService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private webEvent: WebEventService,
    private entitaetInfo: EntitaetInfoService,
    private filterService: FilterService
  ) { }

  @bindable webApiAction: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) tokens: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) searchtext: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) filterManuell: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) filterInject: string;
  @bindable hasVolltext: boolean;
  @bindable hasFilterManuell: boolean;

  scopeContainer: ScopeContainer;
  filterGruppen: any[];
  filterManuellFeldList: any[];

  filterInjectData: any;

  searchtextOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this.localization.translateOnce("base.search"),
    showClearButton: true,
    bindingOptions: {
      value: "searchtext"
    }
  }
  scrollViewOptions: DevExpress.ui.dxScrollViewOptions = {}
  filterManuellButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localization.translateOnce("erp-divers.erweitert"),
    onClick: async () => {
      if (!this.filterManuellFeldList) {
        await this.loadFilterFeldList();
      }

      this.showFilterBuilder();
    }
  }
  filterManuellPopup: IdxPopupComponent;
  filterManuellPopupOptions: DevExpress.ui.dxPopupOptions = {
    width: "500px",
    height: "450px"
  }
  filterManuellPopupCommands: ICommandData[] = [{
    id: "useFilter",
    title: "erp-filter.uebernehmen",
    icon: "check",
    execute: () => {
      document.body.click();

      this.setFilter(this.filterBuilder.instance.option("value"));
      this.filterManuellPopup.instance.hide();
    }
  }, {
    id: "clearFilter",
    title: "erp-filter.entfernen",
    icon: "times",
    isEnabledExpression: "!!currentFilter",
    execute: () => {
      this.setFilter(null);
      this.filterManuellPopup.instance.hide();
    }
  }];
  filterBuilder: IdxFilterBuilderComponent;
  filterBuilderOptions: DevExpress.ui.dxFilterBuilderOptions = {
  }
  currentFilter: any;

  bind(bindingContext, overrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    if (bindingContext && bindingContext.viewItemInfo && bindingContext.viewItemInfo.routeInfo && bindingContext.viewItemInfo.routeInfo.customOptions && bindingContext.viewItemInfo.routeInfo.customOptions.filterInject) {
      const filterInjectData = bindingContext.viewItemInfo.routeInfo.customOptions.filterInject;
      filterInjectData.isAktiv = true;
      this.filterInjectData = filterInjectData;
      this.filterInject = filterInjectData.Where;
    }

    this.filterGruppen = this
      .filter
      .getFilterGruppen(this.webApiAction)
      .map(c => {
        return {
          id: c.Id,
          bezeichnung: c.Bezeichnung,
          idElementAktiv: this.filterInjectData
            ? null
            : this.getIdElementAktiv(c.Id),
          elemente: c.FilterElemente.map(d => {
            return {
              id: d.Id,
              bezeichnung: d.Bezeichnung
            }
          })
        }
      });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "filterManuellPopupToolbar",
      caption: "erp-filter.erweitert",
      options: this.filterManuellPopupOptions,
      commands: this.filterManuellPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  onElementClicked(gruppe, element) {
    if (gruppe.idElementAktiv === element.id) {
      gruppe.idElementAktiv = null;
    } else {
      gruppe.idElementAktiv = element.id;
    }

    this.tokens = this.filterGruppen
      .filter(c => c.idElementAktiv)
      .map(c => `|${c.id}=${c.idElementAktiv}|`)
      .join("");
  }
  onFilterInjectClicked() {
    this.filterInjectData.isAktiv = !this.filterInjectData.isAktiv;

    if (this.filterInjectData.isAktiv) {
      this.filterInject = this.filterInjectData.Where;
    } else {
      this.filterInject = null;
    }
  }

  private getIdElementAktiv(idGruppe: number): number {
    if (!this.tokens) {
      return null;
    }

    const prefix = `|${idGruppe}=`;
    const indexOf = this.tokens.indexOf(prefix);
    if (indexOf < 0) {
      return null;
    }

    const text = this.tokens.substr(indexOf + prefix.length);
    return parseInt(text.substr(0, text.indexOf("|")));
  }
  private async loadFilterFeldList() {
    const info = this.entitaetInfo.getEntitaetInfoByWebApiAction(this.webApiAction);

    const result: OnFilterFeldRequest = await this.webEvent.execute(new OnFilterFeldRequestEvent({
      TypeName: info.FullName
    }), true);

    this.filterManuellFeldList = this.filterService.getFilterBuilderFeldList(
      this.scopeContainer,
      result.FeldList);

    this.filterBuilderOptions.fields = this.filterManuellFeldList;
  }
  private showFilterBuilder() {
    if (this.filterBuilder) {
      this.filterBuilder.setOption({
        "value": this.currentFilter
      });
    } else {
      this.filterBuilderOptions.value = this.currentFilter;
    }

    this.filterManuellPopup.instance.show();
  }
  private setFilter(value) {
    this.currentFilter = value;

    this.filterManuell = value
      ? JSON.stringify(this.currentFilter)
      : null;
  }
}
