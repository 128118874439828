import { autoinject, view } from "aurelia-framework";
import { ElementService } from "./element-service";
import { IViewModel } from '../interfaces/view-model';

@autoinject
export class ChartService {
  constructor(
    private elementService: ElementService
  ) { }

  createOptions(viewModel: IViewModel, idDataSource: string, options: DevExpress.viz.dxChartOptions) {
    const result: any = Object.assign({
    }, options, <DevExpress.viz.dxChartOptions>{
        dataSource: []
      });

    this.elementService.prepareContentReady(result);
    this.elementService.addOnInitialized(result, (e) => {
      viewModel.registerDataSourceChanged(idDataSource, (idDataSource, data) => {
        e.component.option("dataSource", data);
      }, false);

      const data = viewModel.dataSource[idDataSource] || [];
      e.component.option("dataSource", data);
    });

    return result;
  }
}