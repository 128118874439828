import { autoinject } from "aurelia-framework";

@autoinject
export class BrowserService {
  constructor() {
    this.isIE = navigator.userAgent.indexOf("Trident/") >= 0;
    this.isEdge = navigator.userAgent.indexOf("Edge/") >= 0;
    this.isFirefox = navigator.userAgent.indexOf("Firefox/") >= 0;

    this.isMobile = window.screen.height < 700
      || window.screen.width < 700;

    this.isXL = window.screen.width >= 1500;
    this.isLG = !this.isXL && window.screen.width >= 1200;
    this.isMD = !this.isXL && !this.isLG && window.screen.width >= 992;
    this.isSM = !this.isXL && !this.isLG && !this.isMD && window.screen.width >= 768;
    this.isXS = window.screen.width < 768;

    this.isTouch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      .test(navigator.userAgent);

    const iOS = !!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i);
    const webkit = !!navigator.userAgent.match(/WebKit/i);
    this.isIOSSafari = iOS && webkit && !navigator.userAgent.match(/CriOS/i);
    this.isIOS = iOS && webkit;

    const body = document.getElementsByTagName("body")[0];

    if (this.isMobile) {
      body.classList.add("t--is-mobile");
    }
    if (this.isTouch) {
      body.classList.add("t--is-touch");
    }
    if (this.isIE) {
      body.classList.add("t--is-ie");
    }
    if (this.isEdge) {
      body.classList.add("t--is-edge");
    }
    if (this.isFirefox) {
      body.classList.add("t--is-firefox");
    }
    if (this.isXL) {
      body.classList.add("t--is-xl");
    }
    if (this.isLG) {
      body.classList.add("t--is-lg");
    }
    if (this.isMD) {
      body.classList.add("t--is-md");
    }
    if (this.isSM) {
      body.classList.add("t--is-sm");
    }
    if (this.isXS) {
      body.classList.add("t--is-xs");
    }
  }

  isFirefox: boolean;
  isIE: boolean;
  isEdge: boolean;
  isIOSSafari: boolean;
  isIOS: boolean;

  isMobile: boolean;
  isTouch: boolean;

  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
}
