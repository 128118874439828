import { autoinject } from "aurelia-framework";
import { CustomEvent } from "../classes/custom-event";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class WindowService {
  constructor(
    private eventAggregator: EventAggregator
  ) {
    this.registerEvents();
  }

  blurCurrentElement() {
    if (!document.activeElement) {
      return;
    }

    const activeElement = (<any>document.activeElement);
    if (!activeElement.blur) {
      return;
    }

    activeElement.blur();
  }

  private registerEvents() {
    window.addEventListener("resize", () => {
      this.eventAggregator.publish("window:resize", {});
    });
    window.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        this.eventAggregator.publish("window:activated", {});
      }
    })
  }
}
