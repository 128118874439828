import {
  autoinject,
  bindable,
  bindingMode,
  computedFrom,
  observable
} from "aurelia-framework";
import {
  DataSourceService,
  LocalizationService,
  ScopeContainer,
} from "../../../framework/base/export";
import {
  ModelUtilsService
} from "../../../framework/forms/export";

import {
  CustomEditPopupService,
  ProjektService,
  ProdAuftragService,
  ProdAuftragSearchService
} from "../../services/export";
import {
  IdxTextBoxComponent,
} from "../../interfaces/export";

@autoinject
export class ProdAuftrag {
  constructor(
    private customEditPopup: CustomEditPopupService,
    private prodAuftrag: ProdAuftragService,
    private prodAuftragSearch: ProdAuftragSearchService,
    private localization: LocalizationService,
    private modelUtils: ModelUtilsService
  ) { }

  @bindable mainModel: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable idProdAuftrag: number;
  @bindable isReadOnly: boolean;

  @computedFrom("isReadOnly", "mainModel.CanSave")
  get isReadOnlyChecked() {
    return this.isReadOnly || !this.mainModel || this.mainModel.CanSave === false;
  }

  prodAuftragNummer: IdxTextBoxComponent;
  prodAuftragNummerOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    onValueChangedByUser: (e) => {
      if (!e.value) {
        this.setProdAuftragtById(null);
        this.resetData();
        return;
      }

      this.onProdAuftragChanged(e.value);
    },
    bindingOptions: {
      readOnly: {
        expression: "isReadOnlyChecked",
        readOnly: true
      }
    }
  }
  showProdAuftragButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-pencil",
    tabIndex: -1,
    onClick: async () => {
      this.customEditPopup.prodAuftragEditPopup.show({
        mappings: { "$id": this.idProdAuftrag || 0 },
        closeCallback: (form) => {
          if (this.idProdAuftrag) {
            this.idProdAuftragChanged(this.idProdAuftrag);
            return;
          }

          const model = form.models.modelWithKeyId;
          const data = form.models.data[model.id];

          if (!data || !data.Id) {
            return;
          }

          this.setProdAuftragtById(data.Id);
        }
      });
    }
  }

  prodAuftragArtikelBezeichnung: IdxTextBoxComponent;
  prodAuftragArtikelBezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    readOnly: true,
    tabIndex: -1
  }

  idProdAuftragChanged(newValue) {
    if (newValue == void 0) {
      this.resetData();
      return;
    }
    this.prodAuftrag.getProdAuftragById(newValue)
      .then(projekt => {
        this.setNummer(projekt.Nummer);
        this.setBezeichnung(projekt.Artikel.Bezeichnung);
      })
  }
  onProdAuftragChanged(searchValue: string) {
    const isValidNumber: boolean = /^[0-9]+$/.test(searchValue);

    if (isValidNumber) {
      this.prodAuftrag.getProdAuftragByNummer(searchValue)
        .then(r => {
          if (r == void 0 || !r.length) {
            this.showProjektSearchPopup(searchValue);
          } else {
            this.setProdAuftragtById(r[0].Id);
          }
        });
    } else {
      this.prodAuftrag.getProdAuftragtBySearchtextEindeutig(searchValue)
        .then(r => {
          if (r && r.length === 1) {
            this.setProdAuftragtById(r[0].Id);
          } else {
            this.showProjektSearchPopup(searchValue);
          }
        });
    }
  }

  showProjektSearchPopup(searchValue: string) {
    this.prodAuftragSearch.show(
      (idProdAuftrag) => {
        this.prodAuftrag.getProdAuftragById(idProdAuftrag)
          .then(prodAuftrag => {
            this.setProdAuftragtById(prodAuftrag.Id);
          })
      },
      () => {
        this.resetData();
        this.prodAuftragNummer.instance.focus();
      },
      { searchValue: searchValue }
    );
  }

  resetData() {
    this.setNummer(null);
    this.setBezeichnung(null);
  }
  setBezeichnung(bezeichnung: string) {
    this.prodAuftragArtikelBezeichnung.setOption({ value: bezeichnung });
  }
  setNummer(nummer: number | string) {
    if (nummer != void 0) {
      nummer = nummer.toString();
    }

    this.prodAuftragNummer.setOption({ value: nummer });
  }
  setProdAuftragtById(idProdAuftrag: number) {
    this.idProdAuftrag = idProdAuftrag;
    this.modelUtils.setDirty(this.mainModel);
  }
}
