import {
  autoinject
} from "aurelia-framework";
import {
  BindingService,
  LocalizationService,
  ScopeContainer
} from "../../base/export";

@autoinject
export class ValidationService {
  private validators: any = {};

  constructor(
    private _bindingService: BindingService,
    private _localizationService: LocalizationService
  ) {
    this.registerRequired();
    this.registerConditionalRequired();
    this.registerEmail();
    this.registerEmailMultiple();
    this.registerStringLength();
    this.registerIBAN();
    this.registerBIC();
    this.registerDuration();
    this.registerAHVNr();
    this.registerSVNr();
    this.registerTelefonLandvorwahl();
  }

  registerValidator(type: string, callback: { (scopeContainer: ScopeContainer, caption: string, parameters: any): any }) {
    this.validators[type] = callback;
  }

  getValidator(scopeContainer: ScopeContainer, type: string, caption: string, parameters: any): any {
    const validator = this.validators[type];

    if (!validator) {
      throw new Error(`Validator ${type} not found`);
    }

    return validator(scopeContainer, caption, parameters);
  }

  private registerRequired() {
    this.registerValidator("required", (scopeContainer, caption, parameters) => {
      return {
        type: "required",
        message: this._localizationService.translateOnce(
          "forms.validator_required",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
  private registerConditionalRequired() {
    this.registerValidator("conditionalRequired", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        reevaluate: true,
        message: this._localizationService.translateOnce(
          "forms.validator_required",
          [this._localizationService.translateOnce(caption)]),
        validationCallback: (e) => {
          if (e.value != void (0) && e.value.toString() != "") {
            return true;
          }

          const condition = parameters.find((p) => p.name === "condition");
          if (!condition || !condition.value) {
            return false;
          }

          return !this._bindingService.evaluate(scopeContainer.scope, condition.value);
        }
      };
    });
  }
  private registerBIC() {
    this.registerValidator("bic", (scopeContainer, caption, parameters) => {
      return {
        type: "pattern",
        pattern: "^([A-Z]){4}([A-Z]){2}([0-9A-Z]){2}([0-9A-Z]{3})?$",
        message: this._localizationService.translateOnce(
          "forms.validator_bic",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
  private registerIBAN() {
    this.registerValidator("iban", (scopeContainer, caption, parameters) => {
      return {
        type: "pattern",
        pattern: "[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}",
        message: this._localizationService.translateOnce(
          "forms.validator_iban",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
  private registerDuration() {
    this.registerValidator("duration", (scopeContainer, caption, parameters) => {
      const durationPeriodTypeParameter = parameters.find((p) => p.name === "durationPeriodType");
      const durationPeriodType = durationPeriodTypeParameter
        ? parseInt(durationPeriodTypeParameter.value)
        : 0;

      let message = null;
      switch (durationPeriodType) {
        case 0: {
          message = this._localizationService.translateOnce("forms.validator_duration_seconds_and_more");
          break;
        }
        case 1: {
          message = this._localizationService.translateOnce("forms.validator_duration_minutes_and_more");
          break;
        }
        case 2: {
          message = this._localizationService.translateOnce("forms.validator_duration_days_and_more");
          break;
        }
        default: {
          break;
        }
      }

      return {
        type: "custom",
        reevaluate: true,
        message: message,
        validationCallback: (e) => {
          if (e.value == void (0) || e.value == "") {
            return true;
          }

          let index = 0;
          for (const i of e.value) {
            const code = i.charCodeAt(0);
            if (code < 48 || code > 57) {
              break;
            }

            index++;
          }

          if (index == 0) {
            return false;
          }

          const period = e.value.substr(index);
          switch (period) {
            case "J":
            case "H":
            case "Q":
            case "M":
            case "W":
            case "T":
            case "AT": {
              return durationPeriodType <= 2;
            }
            case "S":
            case "MI": {
              return durationPeriodType <= 1;
            }
            case "SE": {
              return durationPeriodType <= 0;
            }
            default: {
              return false;
            }
          }
        }
      };
    });
  }
  private registerEmail() {
    this.registerValidator("email", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        reevaluate: true,
        message: this._localizationService.translateOnce(
          "forms.validator_email",
          [this._localizationService.translateOnce(caption)]),
        validationCallback: (e) => {
          if (e.value == void (0) || e.value == "") {
            return true;
          }

          const value: string = e.value;

          const isValid = /^[\d\w\._\-]+@([\d\w\._\-üäö]+\.)+[\w]+$/i.test(value);
          if (!isValid) {
            return false;
          }

          return true;
        }
      };
    });
  }
  private registerEmailMultiple() {
    this.registerValidator("emailMultiple", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        reevaluate: true,
        message: this._localizationService.translateOnce(
          "forms.validator_email",
          [this._localizationService.translateOnce(caption)]),
        validationCallback: (e) => {
          if (e.value == void (0) || e.value == "") {
            return true;
          }

          const value: string = e.value;
          const tokens = value.split(";");

          for (let token of tokens) {
            token = token.trim();

            const isValid = /^[\d\w\._\-]+@([\d\w\._\-üäö]+\.)+[\w]+$/i.test(token);
            if (!isValid) {
              return false;
            }
          }

          return true;
        }
      };
    });
  }
  private registerStringLength() {
    this.registerValidator("stringLength", (scopeContainer, caption, parameters) => {
      const min = parameters.find((p) => p.name === "min");
      const max = parameters.find((p) => p.name === "max");

      if (min && max) {
        return {
          type: "stringLength",
          max: max.value,
          min: min.value,
          message: this._localizationService.translateOnce(
            "forms.validator_stringLengthMinMax",
            [this._localizationService.translateOnce(caption), min.value, max.value])
        };
      } else if (min) {
        return {
          type: "stringLength",
          min: min.value,
          message: this._localizationService.translateOnce(
            "forms.validator_stringLengthMin",
            [this._localizationService.translateOnce(caption), min.value])
        };
      } else if (max) {
        return {
          type: "stringLength",
          max: max.value,
          message: this._localizationService.translateOnce(
            "forms.validator_stringLengthMax",
            [this._localizationService.translateOnce(caption), max.value])
        };
      } else {
        throw new Error("No min/max specified");
      }
    });
  }
  private registerSVNr() {
    this.registerValidator("svnr", (scopeContainer, caption, parameters) => {
      return {
        type: "pattern",
        pattern: "^[0-9]{4}$",
        message: this._localizationService.translateOnce(
          "forms.validator_svnr",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
  private registerAHVNr() {
    this.registerValidator("ahvnr", (scopeContainer, caption, parameters) => {
      return {
        type: "pattern",
        pattern: "^[0-9\\.]{5,20}$",
        message: this._localizationService.translateOnce(
          "forms.validator_ahvnr",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
  private registerTelefonLandvorwahl() {
    this.registerValidator("telefonlandvorwahl", (scopeContainer, caption, parameters) => {
      return {
        type: "pattern",
        pattern: "^\\+[0-9]{1,3}$",
        message: this._localizationService.translateOnce(
          "forms.validator_telefonlandvorwahl",
          [this._localizationService.translateOnce(caption)])
      };
    });
  }
}
