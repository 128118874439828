import {
  autoinject
} from "aurelia-framework";
import {
  ISelectItem
} from "../widget-options/select-item";
import {
  DxTemplateService
} from "../../dx/services/export";

import * as selectItems from "../../../framework-data/select-items.json";
import { ScopeContainer } from '../../base/classes/scope-container';
import { IDataSourceOptionFilter } from '../../base/interfaces/data-source-option-filter';
import { IDataSourceOptionCustom } from '../../base/interfaces/data-source-option-custom';
import { RestService, DataSourceService } from '../../base/export';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class SelectItemService {

  constructor(
    private dxTemplate: DxTemplateService,
    private restService: RestService,
    private eventAggregator: EventAggregator,
    private dataSourceService: DataSourceService
  ) {}

  addSelectItem(id: string, data: any) {
    selectItems[id] = data;
  }
  getSelectItem(id: string): ISelectItem {
    if (!selectItems) {
      throw new Error("No select-items defined");
    }
    if (!selectItems[id]) {
      throw new Error(`Select-item ${id} is not defined`);
    }

    return selectItems[id];
  }  

  createSelectDataSource(
    scopeContainer: ScopeContainer, 
    selectItem: ISelectItem, 
    filter: any, 
    filters: IDataSourceOptionFilter[], 
    customs: IDataSourceOptionCustom[],
    observerCallback?: {(dataSourceOptions: any): void}): DevExpress.data.DataSource {
    if (selectItem.loadItemListDeferred) {
      return new DevExpress.data.DataSource(<any>new DevExpress.data.CustomStore({
        cacheRawData: true,
        loadMode: "raw",
        load: async () => {
          const result = await this.restService.get({
            url: this.restService.getApiUrl(`base/FormSelectItem/Items/${selectItem.id}`)
          });

          const args = {
            selectItemId: selectItem.id,
            data: result
          };

          this.eventAggregator.publish("form-select-item:data-loaded", args);
          return args.data;
        }
      }));
    } else if (selectItem.items && selectItem.items.length > 0) {
      return new DevExpress.data.DataSource(<any>new DevExpress.data.ArrayStore({
        data: selectItem.items
      }));
    } else if (selectItem.action) {
      const where = [];
      if (filter) {
        where.push(filter);
      }
      if (selectItem.where) {
        where.push(selectItem.where);
      }

      const dataSourcefilters: IDataSourceOptionFilter[] = [];
      if (selectItem.customs) {
        dataSourcefilters.push(...selectItem.customs);
      }
      if (customs) {
        customs.forEach(custom => {
          dataSourcefilters.push({
            webApiCustomKey: custom.key,
            webApiCustomValue: custom.value
          })
        });
      }
      if (filters) {
        dataSourcefilters.push(...filters);
      }

      const dataSourceOptions = {
        keyProperty: selectItem.valueMember,
        webApiAction: selectItem.action,
        webApiColumns: selectItem.columns,
        webApiExpand: selectItem.expand,
        webApiOrderBy: selectItem.orderBy,
        webApiWhere: where,
        webApiSearchtextEnabled: selectItem.searchtextEnabled,
        filters: dataSourcefilters
      };

      const dataSource = this.dataSourceService.createDataSource(scopeContainer, dataSourceOptions);

      if (observerCallback) {
        observerCallback(dataSourceOptions);
      }

      return dataSource;
    }
  }
}