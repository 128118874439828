export class OnCreateLoggingEvent {
  constructor(public data: OnCreateLogging) {}

  EventName = "TIP.ERP.Business.Events.Cloud.OnCreateLogging";
}
export class OnInstanzBenutzerRequestEvent {
  constructor(public data: OnInstanzBenutzerRequest) {}

  EventName = "TIP.ERP.Business.Events.Cloud.OnInstanzBenutzerRequest";
}
export class OnDatabaseKillSessionEvent {
  constructor(public data: OnDatabaseKillSession) {}

  EventName = "TIP.ERP.Business.Events.Datenbank.OnDatabaseKillSession";
}
export class OnDatabaseRunningQueryRequestEvent {
  constructor(public data: OnDatabaseRunningQueryRequest) {}

  EventName = "TIP.ERP.Business.Events.Datenbank.OnDatabaseRunningQueryRequest";
}
export class OnDatabaseUpdateStatisticsEvent {
  constructor(public data: OnDatabaseUpdateStatistics) {}

  EventName = "TIP.ERP.Business.Events.Datenbank.OnDatabaseUpdateStatistics";
}
export class OnReportDruckEvent {
  constructor(public data: OnReportDruck) {}

  EventName = "TIP.ERP.Business.Events.DMS.OnReportDruck";
}
export class OnReportRequestEvent {
  constructor(public data: OnReportRequest) {}

  EventName = "TIP.ERP.Business.Events.DMS.OnReportRequest";
}
export class OnDynamischesFeldEvalVorbelegungEvent {
  constructor(public data: OnDynamischesFeldEvalVorbelegung) {}

  EventName = "TIP.ERP.Business.Events.DynamischeFelder.OnDynamischesFeldEvalVorbelegung";
}
export class OnDynamischesFeldHasManuellerTextEvent {
  constructor(public data: OnDynamischesFeldHasManuellerText) {}

  EventName = "TIP.ERP.Business.Events.DynamischeFelder.OnDynamischesFeldHasManuellerText";
}
export class OnDynamischesFeldUpdateManuellerTextEvent {
  constructor(public data: OnDynamischesFeldUpdateManuellerText) {}

  EventName = "TIP.ERP.Business.Events.DynamischeFelder.OnDynamischesFeldUpdateManuellerText";
}
export class OnDynamischesFeldUpdateWertEvent {
  constructor(public data: OnDynamischesFeldUpdateWert) {}

  EventName = "TIP.ERP.Business.Events.DynamischeFelder.OnDynamischesFeldUpdateWert";
}
export class OnBestellungBestaetigtEvent {
  constructor(public data: OnBestellungBestaetigt) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungBestaetigt";
}
export class OnBestellungIntercompanyErstellenEvent {
  constructor(public data: OnBestellungIntercompanyErstellen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungIntercompanyErstellen";
}
export class OnBestellungKondAktualisierenEvent {
  constructor(public data: OnBestellungKondAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungKondAktualisieren";
}
export class OnBestellungListBestaetigtEvent {
  constructor(public data: OnBestellungListBestaetigt) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungListBestaetigt";
}
export class OnBestellungListLieferdatumAendernEvent {
  constructor(public data: OnBestellungListLieferdatumAendern) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungListLieferdatumAendern";
}
export class OnBestellungPositionenSortierenEvent {
  constructor(public data: OnBestellungPositionenSortieren) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungPositionenSortieren";
}
export class OnBestellungspositionenInNeueBestellungUebernehmenEvent {
  constructor(public data: OnBestellungspositionenInNeueBestellungUebernehmen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungspositionenInNeueBestellungUebernehmen";
}
export class OnBestellungspositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnBestellungspositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungspositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnBestellungspositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnBestellungspositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungspositionVariablerSetartikelPreisAendern";
}
export class OnBestellungspositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnBestellungspositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungspositionVariablerSetartikelRabattEntfernen";
}
export class OnBestellungStreckengeschaeftErstellenEvent {
  constructor(public data: OnBestellungStreckengeschaeftErstellen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBestellungStreckengeschaeftErstellen";
}
export class OnBVArtikelBestellenEvent {
  constructor(public data: OnBVArtikelBestellen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBVArtikelBestellen";
}
export class OnBVArtikelIgnorierenEvent {
  constructor(public data: OnBVArtikelIgnorieren) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBVArtikelIgnorieren";
}
export class OnBVRechnenEvent {
  constructor(public data: OnBVRechnen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnBVRechnen";
}
export class OnCalcLieferterminEvent {
  constructor(public data: OnCalcLiefertermin) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnCalcLiefertermin";
}
export class OnDeleteIdBestellungspositionListEvent {
  constructor(public data: OnDeleteIdBestellungspositionList) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnDeleteIdBestellungspositionList";
}
export class OnDeleteIdEingangsrechnungspositionListEvent {
  constructor(public data: OnDeleteIdEingangsrechnungspositionList) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnDeleteIdEingangsrechnungspositionList";
}
export class OnDeleteIdWarenuebernahmepositionListEvent {
  constructor(public data: OnDeleteIdWarenuebernahmepositionList) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnDeleteIdWarenuebernahmepositionList";
}
export class OnEingangsrechnungAbschliessenEvent {
  constructor(public data: OnEingangsrechnungAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnEingangsrechnungAbschliessen";
}
export class OnEingangsrechnungBezahltAendernEvent {
  constructor(public data: OnEingangsrechnungBezahltAendern) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnEingangsrechnungBezahltAendern";
}
export class OnErstelleBestellungEvent {
  constructor(public data: OnErstelleBestellung) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnErstelleBestellung";
}
export class OnErstelleWarenuebernahmeAusBestellungEvent {
  constructor(public data: OnErstelleWarenuebernahmeAusBestellung) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnErstelleWarenuebernahmeAusBestellung";
}
export class OnEvalEinkaufArtikeldatenEvent {
  constructor(public data: OnEvalEinkaufArtikeldaten) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnEvalEinkaufArtikeldaten";
}
export class OnImportBestellungspositionenInWarenuebernahmeEvent {
  constructor(public data: OnImportBestellungspositionenInWarenuebernahme) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnImportBestellungspositionenInWarenuebernahme";
}
export class OnImportProdFertigwareInEingangsrechnungEvent {
  constructor(public data: OnImportProdFertigwareInEingangsrechnung) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnImportProdFertigwareInEingangsrechnung";
}
export class OnImportWarenuebernahmepositionenInEingangsrechnungEvent {
  constructor(public data: OnImportWarenuebernahmepositionenInEingangsrechnung) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnImportWarenuebernahmepositionenInEingangsrechnung";
}
export class OnWarenuebernahmeAbschliessenEvent {
  constructor(public data: OnWarenuebernahmeAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnWarenuebernahmeAbschliessen";
}
export class OnWarenuebernahmeNichtVerrechnenEvent {
  constructor(public data: OnWarenuebernahmeNichtVerrechnen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnWarenuebernahmeNichtVerrechnen";
}
export class OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnWarenuebernahmepositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnWarenuebernahmepositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnWarenuebernahmepositionVariablerSetartikelPreisAendern";
}
export class OnWarenuebernahmepositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnWarenuebernahmepositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Einkauf.OnWarenuebernahmepositionVariablerSetartikelRabattEntfernen";
}
export class OnCreateFibuPersonenkontenEvent {
  constructor(public data: OnCreateFibuPersonenkonten) {}

  EventName = "TIP.ERP.Business.Events.Fibu.OnCreateFibuPersonenkonten";
}
export class OnCreateFibuSyncJobsEvent {
  constructor(public data: OnCreateFibuSyncJobs) {}

  EventName = "TIP.ERP.Business.Events.Fibu.OnCreateFibuSyncJobs";
}
export class OnImportFibuOPListeEvent {
  constructor(public data: OnImportFibuOPListe) {}

  EventName = "TIP.ERP.Business.Events.Fibu.OnImportFibuOPListe";
}
export class OnPrepareFibuSyncJobEvent {
  constructor(public data: OnPrepareFibuSyncJob) {}

  EventName = "TIP.ERP.Business.Events.Fibu.OnPrepareFibuSyncJob";
}
export class OnBelegExportEvent {
  constructor(public data: OnBelegExport) {}

  EventName = "TIP.ERP.Business.Events.Import.OnBelegExport";
}
export class OnBelegImportFreigebenEvent {
  constructor(public data: OnBelegImportFreigeben) {}

  EventName = "TIP.ERP.Business.Events.Import.OnBelegImportFreigeben";
}
export class OnEximExportEvent {
  constructor(public data: OnEximExport) {}

  EventName = "TIP.ERP.Business.Events.Import.OnEximExport";
}
export class OnImportCreateVorlageEvent {
  constructor(public data: OnImportCreateVorlage) {}

  EventName = "TIP.ERP.Business.Events.Import.OnImportCreateVorlage";
}
export class OnImportFreigebenEvent {
  constructor(public data: OnImportFreigeben) {}

  EventName = "TIP.ERP.Business.Events.Import.OnImportFreigeben";
}
export class OnErstellenIntrastatSyncEvent {
  constructor(public data: OnErstellenIntrastatSync) {}

  EventName = "TIP.ERP.Business.Events.Intrastat.OnErstellenIntrastatSync";
}
export class OnPrepareIntrastatSyncEvent {
  constructor(public data: OnPrepareIntrastatSync) {}

  EventName = "TIP.ERP.Business.Events.Intrastat.OnPrepareIntrastatSync";
}
export class OnDoKalkulierenEvent {
  constructor(public data: OnDoKalkulieren) {}

  EventName = "TIP.ERP.Business.Events.Kalkulation.OnDoKalkulieren";
}
export class OnKalkulationFeldRequestEvent {
  constructor(public data: OnKalkulationFeldRequest) {}

  EventName = "TIP.ERP.Business.Events.Kalkulation.OnKalkulationFeldRequest";
}
export class OnKalkulationItemRequestEvent {
  constructor(public data: OnKalkulationItemRequest) {}

  EventName = "TIP.ERP.Business.Events.Kalkulation.OnKalkulationItemRequest";
}
export class OnKassaArtikelRequestEvent {
  constructor(public data: OnKassaArtikelRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaArtikelRequest";
}
export class OnKassaAuftragRequestEvent {
  constructor(public data: OnKassaAuftragRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaAuftragRequest";
}
export class OnKassaBelegBerechnenRequestEvent {
  constructor(public data: OnKassaBelegBerechnenRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaBelegBerechnenRequest";
}
export class OnKassaBelegPositionenRequestEvent {
  constructor(public data: OnKassaBelegPositionenRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaBelegPositionenRequest";
}
export class OnKassaCreateBelegRequestEvent {
  constructor(public data: OnKassaCreateBelegRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaCreateBelegRequest";
}
export class OnKassaCreateNullBelegEvent {
  constructor(public data: OnKassaCreateNullBeleg) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaCreateNullBeleg";
}
export class OnKassaCreateSchlussBelegEvent {
  constructor(public data: OnKassaCreateSchlussBeleg) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaCreateSchlussBeleg";
}
export class OnKassaCreateStartBelegEvent {
  constructor(public data: OnKassaCreateStartBeleg) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaCreateStartBeleg";
}
export class OnKassaCreateStornoBelegRequestEvent {
  constructor(public data: OnKassaCreateStornoBelegRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaCreateStornoBelegRequest";
}
export class OnKassaDEPRequestEvent {
  constructor(public data: OnKassaDEPRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaDEPRequest";
}
export class OnKassaEinAuszahlungEvent {
  constructor(public data: OnKassaEinAuszahlung) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaEinAuszahlung";
}
export class OnKassaFakturaRequestEvent {
  constructor(public data: OnKassaFakturaRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaFakturaRequest";
}
export class OnKassaFakturaZahlenEvent {
  constructor(public data: OnKassaFakturaZahlen) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaFakturaZahlen";
}
export class OnKassaGutscheinRequestEvent {
  constructor(public data: OnKassaGutscheinRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaGutscheinRequest";
}
export class OnKassaInfoRequestEvent {
  constructor(public data: OnKassaInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaInfoRequest";
}
export class OnKassaKundeRequestEvent {
  constructor(public data: OnKassaKundeRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaKundeRequest";
}
export class OnKassaKundeUpdateEvent {
  constructor(public data: OnKassaKundeUpdate) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaKundeUpdate";
}
export class OnKassaMandantWechselnEvent {
  constructor(public data: OnKassaMandantWechseln) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaMandantWechseln";
}
export class OnKassaRequestLagerbestandDetailEvent {
  constructor(public data: OnKassaRequestLagerbestandDetail) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaRequestLagerbestandDetail";
}
export class OnKassaStatusRequestEvent {
  constructor(public data: OnKassaStatusRequest) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaStatusRequest";
}
export class OnKassaWarenanforderungEvent {
  constructor(public data: OnKassaWarenanforderung) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaWarenanforderung";
}
export class OnKassaZertifikatAnfordernEvent {
  constructor(public data: OnKassaZertifikatAnfordern) {}

  EventName = "TIP.ERP.Business.Events.Kassen.OnKassaZertifikatAnfordern";
}
export class OnArtikelverleihAbholungEvent {
  constructor(public data: OnArtikelverleihAbholung) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnArtikelverleihAbholung";
}
export class OnArtikelverleihRueckgabeEvent {
  constructor(public data: OnArtikelverleihRueckgabe) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnArtikelverleihRueckgabe";
}
export class OnArtikelverleihVerfuegbarEvent {
  constructor(public data: OnArtikelverleihVerfuegbar) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnArtikelverleihVerfuegbar";
}
export class OnInventurFehlArtZuZaehllisteEvent {
  constructor(public data: OnInventurFehlArtZuZaehlliste) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnInventurFehlArtZuZaehlliste";
}
export class OnInventurVerbuchenEvent {
  constructor(public data: OnInventurVerbuchen) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnInventurVerbuchen";
}
export class OnInventurZaehllisteErstellenEvent {
  constructor(public data: OnInventurZaehllisteErstellen) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnInventurZaehllisteErstellen";
}
export class OnLagerbestandRequestEvent {
  constructor(public data: OnLagerbestandRequest) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnLagerbestandRequest";
}
export class OnLagerkorrekturVerbuchenEvent {
  constructor(public data: OnLagerkorrekturVerbuchen) {}

  EventName = "TIP.ERP.Business.Events.Lager.OnLagerkorrekturVerbuchen";
}
export class OnAppMandantWechselnEvent {
  constructor(public data: OnAppMandantWechseln) {}

  EventName = "TIP.ERP.Business.Events.MobileSync.OnAppMandantWechseln";
}
export class OnErstelleProdAuftragEvent {
  constructor(public data: OnErstelleProdAuftrag) {}

  EventName = "TIP.ERP.Business.Events.Produktion.OnErstelleProdAuftrag";
}
export class OnProdAuftragBestaetigtEvent {
  constructor(public data: OnProdAuftragBestaetigt) {}

  EventName = "TIP.ERP.Business.Events.Produktion.OnProdAuftragBestaetigt";
}
export class OnProdAuftragRohwareLieferscheinErstellenEvent {
  constructor(public data: OnProdAuftragRohwareLieferscheinErstellen) {}

  EventName = "TIP.ERP.Business.Events.Produktion.OnProdAuftragRohwareLieferscheinErstellen";
}
export class OnProdFertigwareQuickEvent {
  constructor(public data: OnProdFertigwareQuick) {}

  EventName = "TIP.ERP.Business.Events.Produktion.OnProdFertigwareQuick";
}
export class OnProjektTaetigkeitBuchungAnzahlAktiveBuchungenEvent {
  constructor(public data: OnProjektTaetigkeitBuchungAnzahlAktiveBuchungen) {}

  EventName = "TIP.ERP.Business.Events.Projektzeit.OnProjektTaetigkeitBuchungAnzahlAktiveBuchungen";
}
export class OnProjektTaetigkeitBuchungAusFakturaEntfernenEvent {
  constructor(public data: OnProjektTaetigkeitBuchungAusFakturaEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Projektzeit.OnProjektTaetigkeitBuchungAusFakturaEntfernen";
}
export class OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequestEvent {
  constructor(public data: OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequest) {}

  EventName = "TIP.ERP.Business.Events.Projektzeit.OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequest";
}
export class OnProjektTaetigkeitBuchungNichtVerrechenbarEvent {
  constructor(public data: OnProjektTaetigkeitBuchungNichtVerrechenbar) {}

  EventName = "TIP.ERP.Business.Events.Projektzeit.OnProjektTaetigkeitBuchungNichtVerrechenbar";
}
export class OnProjektTaetigkeitBuchungVerrechnenEvent {
  constructor(public data: OnProjektTaetigkeitBuchungVerrechnen) {}

  EventName = "TIP.ERP.Business.Events.Projektzeit.OnProjektTaetigkeitBuchungVerrechnen";
}
export class OnProvisionBerechnenEvent {
  constructor(public data: OnProvisionBerechnen) {}

  EventName = "TIP.ERP.Business.Events.Provision.OnProvisionBerechnen";
}
export class OnProjektzeitReportDruckenEvent {
  constructor(public data: OnProjektzeitReportDrucken) {}

  EventName = "TIP.ERP.Business.Events.Report.OnProjektzeitReportDrucken";
}
export class OnProjektzeitReportRequestEvent {
  constructor(public data: OnProjektzeitReportRequest) {}

  EventName = "TIP.ERP.Business.Events.Report.OnProjektzeitReportRequest";
}
export class OnPasswortAendernEvent {
  constructor(public data: OnPasswortAendern) {}

  EventName = "TIP.ERP.Business.Events.Security.OnPasswortAendern";
}
export class OnSpracheUebersetzenEvent {
  constructor(public data: OnSpracheUebersetzen) {}

  EventName = "TIP.ERP.Business.Events.Sprache.OnSpracheUebersetzen";
}
export class OnArtikelartAendernEvent {
  constructor(public data: OnArtikelartAendern) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnArtikelartAendern";
}
export class OnAufgabeAnzahlRequestEvent {
  constructor(public data: OnAufgabeAnzahlRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnAufgabeAnzahlRequest";
}
export class OnAufgabeErledigenRequestEvent {
  constructor(public data: OnAufgabeErledigenRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnAufgabeErledigenRequest";
}
export class OnBenachrichtigungAnzahlRequestEvent {
  constructor(public data: OnBenachrichtigungAnzahlRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnBenachrichtigungAnzahlRequest";
}
export class OnBenachrichtigungGelesenEvent {
  constructor(public data: OnBenachrichtigungGelesen) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnBenachrichtigungGelesen";
}
export class OnBriefAnzahlRequestEvent {
  constructor(public data: OnBriefAnzahlRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnBriefAnzahlRequest";
}
export class OnBriefErstellenEvent {
  constructor(public data: OnBriefErstellen) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnBriefErstellen";
}
export class OnBriefVersendenEvent {
  constructor(public data: OnBriefVersenden) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnBriefVersenden";
}
export class OnCreateNewsletterEmpfaengerEvent {
  constructor(public data: OnCreateNewsletterEmpfaenger) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnCreateNewsletterEmpfaenger";
}
export class OnCreateNewsletterEmpfaengerAusAnmeldungEvent {
  constructor(public data: OnCreateNewsletterEmpfaengerAusAnmeldung) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnCreateNewsletterEmpfaengerAusAnmeldung";
}
export class OnCreateZugriffHistorieEvent {
  constructor(public data: OnCreateZugriffHistorie) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnCreateZugriffHistorie";
}
export class OnErstelleAngebotAusMailImportItemEvent {
  constructor(public data: OnErstelleAngebotAusMailImportItem) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnErstelleAngebotAusMailImportItem";
}
export class OnErstelleAuftragAusMailImportItemEvent {
  constructor(public data: OnErstelleAuftragAusMailImportItem) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnErstelleAuftragAusMailImportItem";
}
export class OnErstelleBestellungAusMailImportItemEvent {
  constructor(public data: OnErstelleBestellungAusMailImportItem) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnErstelleBestellungAusMailImportItem";
}
export class OnErstelleDruckJobEvent {
  constructor(public data: OnErstelleDruckJob) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnErstelleDruckJob";
}
export class OnErstelleKundenreklamationAusMailImportItemEvent {
  constructor(public data: OnErstelleKundenreklamationAusMailImportItem) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnErstelleKundenreklamationAusMailImportItem";
}
export class OnEvalSteuertypEvent {
  constructor(public data: OnEvalSteuertyp) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnEvalSteuertyp";
}
export class OnFehlerRequestEvent {
  constructor(public data: OnFehlerRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnFehlerRequest";
}
export class OnFilterFeldRequestEvent {
  constructor(public data: OnFilterFeldRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnFilterFeldRequest";
}
export class OnFirmaSteuerinformationGueltigkeitAbgrenzenEvent {
  constructor(public data: OnFirmaSteuerinformationGueltigkeitAbgrenzen) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnFirmaSteuerinformationGueltigkeitAbgrenzen";
}
export class OnGeschaeftspartnerartAendernEvent {
  constructor(public data: OnGeschaeftspartnerartAendern) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnGeschaeftspartnerartAendern";
}
export class OnInfobarDynFeldRequestEvent {
  constructor(public data: OnInfobarDynFeldRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnInfobarDynFeldRequest";
}
export class OnInfoBVRohwareRequestEvent {
  constructor(public data: OnInfoBVRohwareRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnInfoBVRohwareRequest";
}
export class OnIsDiverserGeschaeftspartnerEvent {
  constructor(public data: OnIsDiverserGeschaeftspartner) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnIsDiverserGeschaeftspartner";
}
export class OnKopierenRequestEvent {
  constructor(public data: OnKopierenRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnKopierenRequest";
}
export class OnLandPlzQueryOrtEvent {
  constructor(public data: OnLandPlzQueryOrt) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnLandPlzQueryOrt";
}
export class OnMailImportItemAnzahlRequestEvent {
  constructor(public data: OnMailImportItemAnzahlRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnMailImportItemAnzahlRequest";
}
export class OnMandantWechselnEvent {
  constructor(public data: OnMandantWechseln) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnMandantWechseln";
}
export class OnNewsletterAnmeldungStatusAendernEvent {
  constructor(public data: OnNewsletterAnmeldungStatusAendern) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNewsletterAnmeldungStatusAendern";
}
export class OnNewsletterAnmeldungStatusRequestEvent {
  constructor(public data: OnNewsletterAnmeldungStatusRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNewsletterAnmeldungStatusRequest";
}
export class OnNewsletterEmpfaengerImportEvent {
  constructor(public data: OnNewsletterEmpfaengerImport) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNewsletterEmpfaengerImport";
}
export class OnNewsletterTestVersendenEvent {
  constructor(public data: OnNewsletterTestVersenden) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNewsletterTestVersenden";
}
export class OnNewsletterVersendenEvent {
  constructor(public data: OnNewsletterVersenden) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNewsletterVersenden";
}
export class OnNotizAnzahlRequestEvent {
  constructor(public data: OnNotizAnzahlRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnNotizAnzahlRequest";
}
export class OnOffice365AuthTokenAbfrageEvent {
  constructor(public data: OnOffice365AuthTokenAbfrage) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnOffice365AuthTokenAbfrage";
}
export class OnOffice365TokenAnfordernEvent {
  constructor(public data: OnOffice365TokenAnfordern) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnOffice365TokenAnfordern";
}
export class OnPapierkorbWiederherstellenEvent {
  constructor(public data: OnPapierkorbWiederherstellen) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnPapierkorbWiederherstellen";
}
export class OnPeriodeRequestEvent {
  constructor(public data: OnPeriodeRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnPeriodeRequest";
}
export class OnPersonRequestEvent {
  constructor(public data: OnPersonRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnPersonRequest";
}
export class OnPersonWebshopEvent {
  constructor(public data: OnPersonWebshop) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnPersonWebshop";
}
export class OnProjektDokumentRequestEvent {
  constructor(public data: OnProjektDokumentRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnProjektDokumentRequest";
}
export class OnProjektRequestStatistikEvent {
  constructor(public data: OnProjektRequestStatistik) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnProjektRequestStatistik";
}
export class OnRequestAnzAusdruckeEvent {
  constructor(public data: OnRequestAnzAusdrucke) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnRequestAnzAusdrucke";
}
export class OnRequestMandantEvent {
  constructor(public data: OnRequestMandant) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnRequestMandant";
}
export class OnRequestQRLabelEvent {
  constructor(public data: OnRequestQRLabel) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnRequestQRLabel";
}
export class OnStatusAendernEvent {
  constructor(public data: OnStatusAendern) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnStatusAendern";
}
export class OnStatusMoeglichRequestEvent {
  constructor(public data: OnStatusMoeglichRequest) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnStatusMoeglichRequest";
}
export class OnTapiAnrufEvent {
  constructor(public data: OnTapiAnruf) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnTapiAnruf";
}
export class OnUIDListValidierenEvent {
  constructor(public data: OnUIDListValidieren) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnUIDListValidieren";
}
export class OnUIDValidierenEvent {
  constructor(public data: OnUIDValidieren) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnUIDValidieren";
}
export class OnVerknuepfenEntitaetenEvent {
  constructor(public data: OnVerknuepfenEntitaeten) {}

  EventName = "TIP.ERP.Business.Events.Stammdaten.OnVerknuepfenEntitaeten";
}
export class OnStatistikArtikelDataRequestEvent {
  constructor(public data: OnStatistikArtikelDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikArtikelDataRequest";
}
export class OnStatistikArtikelInfoRequestEvent {
  constructor(public data: OnStatistikArtikelInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikArtikelInfoRequest";
}
export class OnStatistikAuftragDataRequestEvent {
  constructor(public data: OnStatistikAuftragDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikAuftragDataRequest";
}
export class OnStatistikAuftragInfoRequestEvent {
  constructor(public data: OnStatistikAuftragInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikAuftragInfoRequest";
}
export class OnStatistikBestellungenDataRequestEvent {
  constructor(public data: OnStatistikBestellungenDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikBestellungenDataRequest";
}
export class OnStatistikBestellungenInfoRequestEvent {
  constructor(public data: OnStatistikBestellungenInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikBestellungenInfoRequest";
}
export class OnStatistikDataRequestEvent {
  constructor(public data: OnStatistikDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikDataRequest";
}
export class OnStatistikEinAuszahlungsRechnungDataRequestEvent {
  constructor(public data: OnStatistikEinAuszahlungsRechnungDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikEinAuszahlungsRechnungDataRequest";
}
export class OnStatistikEinAuszahlungsRechnungInfoRequestEvent {
  constructor(public data: OnStatistikEinAuszahlungsRechnungInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikEinAuszahlungsRechnungInfoRequest";
}
export class OnStatistikEinkaufDetailRequestEvent {
  constructor(public data: OnStatistikEinkaufDetailRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikEinkaufDetailRequest";
}
export class OnStatistikFakturaDataRequestEvent {
  constructor(public data: OnStatistikFakturaDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikFakturaDataRequest";
}
export class OnStatistikFakturaInfoRequestEvent {
  constructor(public data: OnStatistikFakturaInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikFakturaInfoRequest";
}
export class OnStatistikFeldRequestEvent {
  constructor(public data: OnStatistikFeldRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikFeldRequest";
}
export class OnStatistikGeschaeftspartnerPersonDataRequestEvent {
  constructor(public data: OnStatistikGeschaeftspartnerPersonDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikGeschaeftspartnerPersonDataRequest";
}
export class OnStatistikGeschaeftspartnerPersonInfoRequestEvent {
  constructor(public data: OnStatistikGeschaeftspartnerPersonInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikGeschaeftspartnerPersonInfoRequest";
}
export class OnStatistikGruppeDataRequestEvent {
  constructor(public data: OnStatistikGruppeDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikGruppeDataRequest";
}
export class OnStatistikGruppeInfoRequestEvent {
  constructor(public data: OnStatistikGruppeInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikGruppeInfoRequest";
}
export class OnStatistikGruppiertDashboardEvent {
  constructor(public data: OnStatistikGruppiertDashboard) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikGruppiertDashboard";
}
export class OnStatistikKassaDataRequestEvent {
  constructor(public data: OnStatistikKassaDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKassaDataRequest";
}
export class OnStatistikKassaDetailDataRequestEvent {
  constructor(public data: OnStatistikKassaDetailDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKassaDetailDataRequest";
}
export class OnStatistikKassaInfoRequestEvent {
  constructor(public data: OnStatistikKassaInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKassaInfoRequest";
}
export class OnStatistikKennzahlenEvent {
  constructor(public data: OnStatistikKennzahlen) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKennzahlen";
}
export class OnStatistikKundeDataRequestEvent {
  constructor(public data: OnStatistikKundeDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKundeDataRequest";
}
export class OnStatistikKundeInfoRequestEvent {
  constructor(public data: OnStatistikKundeInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikKundeInfoRequest";
}
export class OnStatistikLagerbestandDataRequestEvent {
  constructor(public data: OnStatistikLagerbestandDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikLagerbestandDataRequest";
}
export class OnStatistikLagerbestandInfoRequestEvent {
  constructor(public data: OnStatistikLagerbestandInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikLagerbestandInfoRequest";
}
export class OnStatistikLagerbewegungenDataRequestEvent {
  constructor(public data: OnStatistikLagerbewegungenDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikLagerbewegungenDataRequest";
}
export class OnStatistikLagerbewegungenInfoRequestEvent {
  constructor(public data: OnStatistikLagerbewegungenInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikLagerbewegungenInfoRequest";
}
export class OnStatistikProjektzeitDataRequestEvent {
  constructor(public data: OnStatistikProjektzeitDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikProjektzeitDataRequest";
}
export class OnStatistikProjektzeitInfoRequestEvent {
  constructor(public data: OnStatistikProjektzeitInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikProjektzeitInfoRequest";
}
export class OnStatistikReklamationenDataRequestEvent {
  constructor(public data: OnStatistikReklamationenDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikReklamationenDataRequest";
}
export class OnStatistikReklamationenInfoRequestEvent {
  constructor(public data: OnStatistikReklamationenInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikReklamationenInfoRequest";
}
export class OnStatistikRequestEvent {
  constructor(public data: OnStatistikRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikRequest";
}
export class OnStatistikVerkaufDetailRequestEvent {
  constructor(public data: OnStatistikVerkaufDetailRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikVerkaufDetailRequest";
}
export class OnStatistikWebshopLoginLogDataRequestEvent {
  constructor(public data: OnStatistikWebshopLoginLogDataRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikWebshopLoginLogDataRequest";
}
export class OnStatistikWebshopLoginLogInfoRequestEvent {
  constructor(public data: OnStatistikWebshopLoginLogInfoRequest) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnStatistikWebshopLoginLogInfoRequest";
}
export class OnTopArtikelEvent {
  constructor(public data: OnTopArtikel) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnTopArtikel";
}
export class OnTopKundeEvent {
  constructor(public data: OnTopKunde) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnTopKunde";
}
export class OnUmsatzAktuellEvent {
  constructor(public data: OnUmsatzAktuell) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnUmsatzAktuell";
}
export class OnUmsatzLandEvent {
  constructor(public data: OnUmsatzLand) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnUmsatzLand";
}
export class OnUmsatzMonatEvent {
  constructor(public data: OnUmsatzMonat) {}

  EventName = "TIP.ERP.Business.Events.Statistik.OnUmsatzMonat";
}
export class OnVolltextSucheSingleEvent {
  constructor(public data: OnVolltextSucheSingle) {}

  EventName = "TIP.ERP.Business.Events.Suche.OnVolltextSucheSingle";
}
export class OnTagCreateEvent {
  constructor(public data: OnTagCreate) {}

  EventName = "TIP.ERP.Business.Events.Tags.OnTagCreate";
}
export class OnTagUpdateEvent {
  constructor(public data: OnTagUpdate) {}

  EventName = "TIP.ERP.Business.Events.Tags.OnTagUpdate";
}
export class OnTicketAufwandBestaetigenEvent {
  constructor(public data: OnTicketAufwandBestaetigen) {}

  EventName = "TIP.ERP.Business.Events.Tickets.OnTicketAufwandBestaetigen";
}
export class OnTicketBearbeiterRequestEvent {
  constructor(public data: OnTicketBearbeiterRequest) {}

  EventName = "TIP.ERP.Business.Events.Tickets.OnTicketBearbeiterRequest";
}
export class OnTicketFollowEvent {
  constructor(public data: OnTicketFollow) {}

  EventName = "TIP.ERP.Business.Events.Tickets.OnTicketFollow";
}
export class OnVersionHasUpdateEvent {
  constructor(public data: OnVersionHasUpdate) {}

  EventName = "TIP.ERP.Business.Events.Tickets.OnVersionHasUpdate";
}
export class OnVersionUpdateGelesenEvent {
  constructor(public data: OnVersionUpdateGelesen) {}

  EventName = "TIP.ERP.Business.Events.Tickets.OnVersionUpdateGelesen";
}
export class OnAngebotAddLieferscheinpositionEvent {
  constructor(public data: OnAngebotAddLieferscheinposition) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotAddLieferscheinposition";
}
export class OnAngebotGewichtAktualisierenEvent {
  constructor(public data: OnAngebotGewichtAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotGewichtAktualisieren";
}
export class OnAngebotKondAktualisierenEvent {
  constructor(public data: OnAngebotKondAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotKondAktualisieren";
}
export class OnAngebotPositionenSortierenEvent {
  constructor(public data: OnAngebotPositionenSortieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotPositionenSortieren";
}
export class OnAngebotspositionArtikelErsetzenEvent {
  constructor(public data: OnAngebotspositionArtikelErsetzen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionArtikelErsetzen";
}
export class OnAngebotspositionenInNeuesAngebotUebernehmenEvent {
  constructor(public data: OnAngebotspositionenInNeuesAngebotUebernehmen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionenInNeuesAngebotUebernehmen";
}
export class OnAngebotspositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnAngebotspositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnAngebotspositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnAngebotspositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionVariablerSetartikelPreisAendern";
}
export class OnAngebotspositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnAngebotspositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionVariablerSetartikelRabattEntfernen";
}
export class OnAngebotspositionVariableStuecklisteAuswahlEvent {
  constructor(public data: OnAngebotspositionVariableStuecklisteAuswahl) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionVariableStuecklisteAuswahl";
}
export class OnAngebotspositionVariableStuecklistenkomponentenErstellenEvent {
  constructor(public data: OnAngebotspositionVariableStuecklistenkomponentenErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAngebotspositionVariableStuecklistenkomponentenErstellen";
}
export class OnAuftragAddLieferscheinpositionEvent {
  constructor(public data: OnAuftragAddLieferscheinposition) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragAddLieferscheinposition";
}
export class OnAuftragBestaetigtEvent {
  constructor(public data: OnAuftragBestaetigt) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragBestaetigt";
}
export class OnAuftragGewichtAktualisierenEvent {
  constructor(public data: OnAuftragGewichtAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragGewichtAktualisieren";
}
export class OnAuftragIntercompanyErstellenEvent {
  constructor(public data: OnAuftragIntercompanyErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragIntercompanyErstellen";
}
export class OnAuftragKondAktualisierenEvent {
  constructor(public data: OnAuftragKondAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragKondAktualisieren";
}
export class OnAuftragListBestaetigtEvent {
  constructor(public data: OnAuftragListBestaetigt) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragListBestaetigt";
}
export class OnAuftragListLieferdatumAendernEvent {
  constructor(public data: OnAuftragListLieferdatumAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragListLieferdatumAendern";
}
export class OnAuftragPositionenSortierenEvent {
  constructor(public data: OnAuftragPositionenSortieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragPositionenSortieren";
}
export class OnAuftragRecalcLieferdatumEvent {
  constructor(public data: OnAuftragRecalcLieferdatum) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragRecalcLieferdatum";
}
export class OnAuftragRequestStatistikEvent {
  constructor(public data: OnAuftragRequestStatistik) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragRequestStatistik";
}
export class OnAuftragReservierenEvent {
  constructor(public data: OnAuftragReservieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragReservieren";
}
export class OnAuftragspositionArtikelErsetzenEvent {
  constructor(public data: OnAuftragspositionArtikelErsetzen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionArtikelErsetzen";
}
export class OnAuftragspositionenInNeuenAuftragUebernehmenEvent {
  constructor(public data: OnAuftragspositionenInNeuenAuftragUebernehmen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionenInNeuenAuftragUebernehmen";
}
export class OnAuftragspositionRecalcLieferdatumEvent {
  constructor(public data: OnAuftragspositionRecalcLieferdatum) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionRecalcLieferdatum";
}
export class OnAuftragspositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnAuftragspositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnAuftragspositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnAuftragspositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionVariablerSetartikelPreisAendern";
}
export class OnAuftragspositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnAuftragspositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionVariablerSetartikelRabattEntfernen";
}
export class OnAuftragspositionVariableStuecklisteAuswahlEvent {
  constructor(public data: OnAuftragspositionVariableStuecklisteAuswahl) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionVariableStuecklisteAuswahl";
}
export class OnAuftragspositionVariableStuecklistenkomponentenErstellenEvent {
  constructor(public data: OnAuftragspositionVariableStuecklistenkomponentenErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragspositionVariableStuecklistenkomponentenErstellen";
}
export class OnAuftragVorauskasseEvent {
  constructor(public data: OnAuftragVorauskasse) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnAuftragVorauskasse";
}
export class OnDeleteIdAngebotspositionListEvent {
  constructor(public data: OnDeleteIdAngebotspositionList) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnDeleteIdAngebotspositionList";
}
export class OnDeleteIdAuftragspositionListEvent {
  constructor(public data: OnDeleteIdAuftragspositionList) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnDeleteIdAuftragspositionList";
}
export class OnDeleteIdFakturapositionListEvent {
  constructor(public data: OnDeleteIdFakturapositionList) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnDeleteIdFakturapositionList";
}
export class OnDeleteIdLieferscheinpositionListEvent {
  constructor(public data: OnDeleteIdLieferscheinpositionList) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnDeleteIdLieferscheinpositionList";
}
export class OnErstelleAngebotEvent {
  constructor(public data: OnErstelleAngebot) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnErstelleAngebot";
}
export class OnErstelleAuftragEvent {
  constructor(public data: OnErstelleAuftrag) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnErstelleAuftrag";
}
export class OnErstelleAuftragAusAngebotEvent {
  constructor(public data: OnErstelleAuftragAusAngebot) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnErstelleAuftragAusAngebot";
}
export class OnErstelleFakturaStornoEvent {
  constructor(public data: OnErstelleFakturaStorno) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnErstelleFakturaStorno";
}
export class OnErstelleLieferscheinAusAuftragEvent {
  constructor(public data: OnErstelleLieferscheinAusAuftrag) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnErstelleLieferscheinAusAuftrag";
}
export class OnEvalLieferRechnungsortEvent {
  constructor(public data: OnEvalLieferRechnungsort) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnEvalLieferRechnungsort";
}
export class OnEvalVerkaufArtikeldatenEvent {
  constructor(public data: OnEvalVerkaufArtikeldaten) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnEvalVerkaufArtikeldaten";
}
export class OnFakturaAbschliessenEvent {
  constructor(public data: OnFakturaAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturaAbschliessen";
}
export class OnFakturaBezahltAendernEvent {
  constructor(public data: OnFakturaBezahltAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturaBezahltAendern";
}
export class OnFakturaListAbschliessenEvent {
  constructor(public data: OnFakturaListAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturaListAbschliessen";
}
export class OnFakturaMahnenEvent {
  constructor(public data: OnFakturaMahnen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturaMahnen";
}
export class OnFakturapositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnFakturapositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturapositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnFakturapositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnFakturapositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturapositionVariablerSetartikelPreisAendern";
}
export class OnFakturapositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnFakturapositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturapositionVariablerSetartikelRabattEntfernen";
}
export class OnFakturapositionVariableStuecklisteAuswahlEvent {
  constructor(public data: OnFakturapositionVariableStuecklisteAuswahl) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturapositionVariableStuecklisteAuswahl";
}
export class OnFakturapositionVariableStuecklistenkomponentenErstellenEvent {
  constructor(public data: OnFakturapositionVariableStuecklistenkomponentenErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnFakturapositionVariableStuecklistenkomponentenErstellen";
}
export class OnImportAngebotspositionenInAuftragEvent {
  constructor(public data: OnImportAngebotspositionenInAuftrag) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnImportAngebotspositionenInAuftrag";
}
export class OnImportAuftragInLieferscheinEvent {
  constructor(public data: OnImportAuftragInLieferschein) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnImportAuftragInLieferschein";
}
export class OnImportLieferscheinInLadelisteEvent {
  constructor(public data: OnImportLieferscheinInLadeliste) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnImportLieferscheinInLadeliste";
}
export class OnImportLieferscheinpositionenInKundenreklamationEvent {
  constructor(public data: OnImportLieferscheinpositionenInKundenreklamation) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnImportLieferscheinpositionenInKundenreklamation";
}
export class OnKundenreklamationAbschliessenEvent {
  constructor(public data: OnKundenreklamationAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnKundenreklamationAbschliessen";
}
export class OnKundenreklamationErsatzauftragErstellenEvent {
  constructor(public data: OnKundenreklamationErsatzauftragErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnKundenreklamationErsatzauftragErstellen";
}
export class OnKundenreklamationWarenuebernahmeErstellenEvent {
  constructor(public data: OnKundenreklamationWarenuebernahmeErstellen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnKundenreklamationWarenuebernahmeErstellen";
}
export class OnLadelisteEntferneLieferscheineEvent {
  constructor(public data: OnLadelisteEntferneLieferscheine) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLadelisteEntferneLieferscheine";
}
export class OnLieferscheinAbschliessenEvent {
  constructor(public data: OnLieferscheinAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinAbschliessen";
}
export class OnLieferscheinFakturaStatusAendernEvent {
  constructor(public data: OnLieferscheinFakturaStatusAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinFakturaStatusAendern";
}
export class OnLieferscheinGewichtAktualisierenEvent {
  constructor(public data: OnLieferscheinGewichtAktualisieren) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinGewichtAktualisieren";
}
export class OnLieferscheinListAbschliessenEvent {
  constructor(public data: OnLieferscheinListAbschliessen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinListAbschliessen";
}
export class OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernenEvent {
  constructor(public data: OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernen";
}
export class OnLieferscheinpositionVariablerSetartikelPreisAendernEvent {
  constructor(public data: OnLieferscheinpositionVariablerSetartikelPreisAendern) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinpositionVariablerSetartikelPreisAendern";
}
export class OnLieferscheinpositionVariablerSetartikelRabattEntfernenEvent {
  constructor(public data: OnLieferscheinpositionVariablerSetartikelRabattEntfernen) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnLieferscheinpositionVariablerSetartikelRabattEntfernen";
}
export class OnMahnlaufEvent {
  constructor(public data: OnMahnlauf) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnMahnlauf";
}
export class OnMahnungenVersendenEvent {
  constructor(public data: OnMahnungenVersenden) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnMahnungenVersenden";
}
export class OnMahnungVersendenEvent {
  constructor(public data: OnMahnungVersenden) {}

  EventName = "TIP.ERP.Business.Events.Verkauf.OnMahnungVersenden";
}
export class OnWebValidateEvent {
  constructor(public data: OnWebValidate) {}

  EventName = "TIP.ERP.Business.Events.WebValidation.OnWebValidate";
}
export interface OnCreateLogging {
  Beschreibung?: string;
  Ausgangspunkt?: string;
}
export interface OnInstanzBenutzerRequest {
  Datenbank?: string;
  OnlyCloudERP: boolean;
  Data?: Object;
}
export interface OnDatabaseKillSession {
  SessionIdList: number[];
}
export interface OnDatabaseRunningQueryRequest {
  DatabaseQueryList?: DatabaseQuery[];
}
export interface OnDatabaseUpdateStatistics {
}
export interface OnReportDruck {
  IdObjekt: number;
  IdReport?: string;
  DoArchivieren?: boolean;
  AddBriefpapier?: boolean;
  DMSLink?: string;
}
export interface OnReportRequest {
  IdObjekt: number;
  ReportItemList?: ReportItem[];
}
export interface OnDynamischesFeldEvalVorbelegung {
  IdVorgEntitaet?: number;
  TypeName?: string;
  IdArt?: number;
  ItemList?: DynamischesFeldVorbelegungItem[];
}
export interface OnDynamischesFeldHasManuellerText {
  TypeName?: string;
  HasKopftext?: boolean;
  HasFusstext?: boolean;
}
export interface OnDynamischesFeldUpdateManuellerText {
  Id: number;
  Kopftext: boolean;
  Fusstext: boolean;
}
export interface OnDynamischesFeldUpdateWert {
  IdDynamischesFeldContainer: number;
  IdDynamischesFeld: number;
  WertText?: string;
  WertNummer?: number;
  WertDatum?: Date;
  IdWertAuswahlliste?: number;
}
export interface OnBestellungBestaetigt {
  IdBestellung: number;
  IsBestaetigt: boolean;
}
export interface OnBestellungIntercompanyErstellen {
  IdAuftrag: number;
  IdBestellung?: number;
}
export interface OnBestellungKondAktualisieren {
  IdBestellung: number;
  AllePositionen: boolean;
  IdBestellungPosList: number[];
}
export interface OnBestellungListBestaetigt {
  IdBestellungList: number[];
  IsBestaetigt: boolean;
}
export interface OnBestellungListLieferdatumAendern {
  IdBestellungList: number[];
  Lieferdatum: Date;
}
export interface OnBestellungPositionenSortieren {
  IdBestellung: number;
  Typ?: PositionSortierenTyp;
}
export interface OnBestellungspositionenInNeueBestellungUebernehmen {
  IdBestellung: number;
  IdBestellungPosList: number[];
  IdBestellungKopie?: number;
}
export interface OnBestellungspositionVariablerSetartikelManuellerPreisEntfernen {
  IdBestellungspositionSetartikel: number;
}
export interface OnBestellungspositionVariablerSetartikelPreisAendern {
  IdBestellungspositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnBestellungspositionVariablerSetartikelRabattEntfernen {
  IdBestellungspositionSetartikel: number;
}
export interface OnBestellungStreckengeschaeftErstellen {
  IdAuftrag: number;
  IdBestellung?: number;
}
export interface OnBVArtikelBestellen {
  IdBestellvorschlagList: number[];
  DoAbschliessen: boolean;
  IdBestellung?: number;
  IdProdAuftrag?: number;
}
export interface OnBVArtikelIgnorieren {
  IdBestellvorschlagList: number[];
}
export interface OnBVRechnen {
  IdArtikelList: number[];
}
export interface OnCalcLiefertermin {
  IdArtikel: number;
  IdLager: number;
  MengeLagEH: number;
  IdAuftragsposition?: number;
  Bereitstelldatum?: Date;
  Lieferdatum?: Date;
  Liefertage?: number;
  HasFehlendeBeschaffungsperiode: boolean;
  DetailList?: Object;
  BestandList?: Object;
}
export interface OnDeleteIdBestellungspositionList {
  IdBestellungspositionList: number[];
}
export interface OnDeleteIdEingangsrechnungspositionList {
  IdEingangsrechnungspositionList: number[];
}
export interface OnDeleteIdWarenuebernahmepositionList {
  IdWarenuebernahmepositionList: number[];
}
export interface OnEingangsrechnungAbschliessen {
  IdEingangsrechnung: number;
}
export interface OnEingangsrechnungBezahltAendern {
  IdEingangsrechnung: number;
  Typ?: EingangsrechnungBezahltTyp;
}
export interface OnErstelleBestellung {
  IdArtikel: number;
  IdBestellung?: number;
}
export interface OnErstelleWarenuebernahmeAusBestellung {
  IdBestellung: number;
  IdWarenuebernahme?: number;
  UebernehmeAllePositionen: boolean;
  IdBestellungPosList: number[];
}
export interface OnEvalEinkaufArtikeldaten {
  IdArtikel: number;
  IdLieferant: number;
  IdEingangsrechnung?: number;
  IdArtikelEinheit?: number;
  LieferantArtikelNr?: string;
  LieferantArtikelbezeichnung?: string;
  Steuersatz?: number;
}
export interface OnImportBestellungspositionenInWarenuebernahme {
  IdWarenuebernahme: number;
  IdBestellungspositionList: number[];
}
export interface OnImportProdFertigwareInEingangsrechnung {
  IdEingangsrechnung: number;
  IdProdFertigwareList: number[];
}
export interface OnImportWarenuebernahmepositionenInEingangsrechnung {
  IdEingangsrechnung: number;
  IdWarenuebernahmepositionList: number[];
}
export interface OnWarenuebernahmeAbschliessen {
  IdWarenuebernahme: number;
}
export interface OnWarenuebernahmeNichtVerrechnen {
  IdWarenuebernahmepositionList: number[];
}
export interface OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernen {
  IdWarenuebernahmepositionSetartikel: number;
}
export interface OnWarenuebernahmepositionVariablerSetartikelPreisAendern {
  IdWarenuebernahmepositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnWarenuebernahmepositionVariablerSetartikelRabattEntfernen {
  IdWarenuebernahmepositionSetartikel: number;
}
export interface OnCreateFibuPersonenkonten {
  IdFirma?: number;
  IdGeschaeftspartnerart?: number;
}
export interface OnCreateFibuSyncJobs {
  IdFirma: number;
  DatumBis?: Date;
}
export interface OnImportFibuOPListe {
  IdFirma: number;
  DMSLink?: string;
}
export interface OnPrepareFibuSyncJob {
  IdFibuSyncJob: number;
}
export interface OnBelegExport {
  BelegImportTyp?: BelegImportTyp;
  IdList: number[];
  DMSLink?: string;
}
export interface OnBelegImportFreigeben {
  IdBelegImport: number;
}
export interface OnEximExport {
  EximName?: string;
  IdList: number[];
  DMSLink?: string;
}
export interface OnImportCreateVorlage {
  IdImport: number;
  DMSLink?: string;
}
export interface OnImportFreigeben {
  IdImport: number;
}
export interface OnErstellenIntrastatSync {
  Typ?: IntrastatSyncTyp;
  DatumVon: Date;
  DatumBis: Date;
  IdFirma: number;
}
export interface OnPrepareIntrastatSync {
  IdIntrastatSync: number;
}
export interface OnDoKalkulieren {
  LoadKonditionen: boolean;
  IsKalkulationKopf: boolean;
  Datum: Date;
  TypeName?: string;
  IdKopf?: number;
  IdPos?: number;
  IdBerechnungsversion: number;
  IdFirma: number;
  IdFiliale?: number;
  IdLager?: number;
  IdWaehrung?: number;
  IdArtikel?: number;
  IdArtikelEinheit?: number;
  IdKunde?: number;
  IdLieferant?: number;
  MengeVerrVerpEH?: number;
  Steuersatz?: number;
  IsGratis?: boolean;
  IsFromWebshop?: boolean;
  GewichtBrutto?: number;
  GewichtNetto?: number;
  Laenge?: number;
  Breite?: number;
  Hoehe?: number;
  WertNetto?: number;
  WertSteuer?: number;
  WertBrutto?: number;
  InputList?: KalkulationInputItem[];
  FixList?: KalkulationFixItem[];
  OutputList?: KalkulationOutputItem[];
  PosList?: KalkulationPosItem[];
  SteuerPosList?: KalkulationSteuerPosItem[];
}
export interface OnKalkulationFeldRequest {
  IdBerechnungsversion: number;
  IsKalkulationKopf: boolean;
  FeldList?: KalkulationFeldItem[];
}
export interface OnKalkulationItemRequest {
  IdKalkulationContainer: number;
  InputItemList?: KalkulationInputItem[];
  FixItemList?: KalkulationFixItem[];
  OutputItemList?: KalkulationOutputItem[];
  PosItemList?: KalkulationPosItem[];
  SteuerPosList?: KalkulationSteuerPosItem[];
  WertNetto?: number;
  WertSteuer?: number;
  WertBrutto?: number;
}
export interface OnKassaArtikelRequest {
  IdKassa: number;
  IdKunde: number;
  Suchtext?: string;
  IdArtikelkategorieList: number[];
  IdArtikelList: number[];
  Skip: number;
  ArtikelList?: ArtikelInfo[];
  AutoAdd: boolean;
  Count: number;
}
export interface OnKassaAuftragRequest {
  IdKassa: number;
  IdKunde: number;
  AuftragList?: AuftragInfo[];
}
export interface OnKassaBelegBerechnenRequest {
  IdKassa: number;
  Beleg?: BelegInfo;
}
export interface OnKassaBelegPositionenRequest {
  IdKassaBeleg: number;
  KassaBelegPositionList?: KassaBelegPositionInfo[];
}
export interface OnKassaCreateBelegRequest {
  IdKassa: number;
  Beleg?: BelegInfo;
  Email?: string;
  DMSLinkList?: string[];
}
export interface OnKassaCreateNullBeleg {
  IdKassa: number;
}
export interface OnKassaCreateSchlussBeleg {
  IdKassa: number;
}
export interface OnKassaCreateStartBeleg {
  IdKassa: number;
}
export interface OnKassaCreateStornoBelegRequest {
  IdKassa: number;
  IdBeleg: number;
  IdZahlungsart: number;
  BelegPositionMengeList?: KassaBelegPositionStornoInfo[];
  Email?: string;
  IdStornoBeleg: number;
  DMSLink?: string;
}
export interface OnKassaDEPRequest {
  IdKassa: number;
  Von: Date;
  Bis: Date;
  DMSLink?: string;
}
export interface OnKassaEinAuszahlung {
  IdKassa: number;
  Betrag: number;
  Text?: string;
  IdWaehrung: number;
}
export interface OnKassaFakturaRequest {
  IdKassa: number;
  IdKunde: number;
  FakturaList?: FakturaInfo[];
}
export interface OnKassaFakturaZahlen {
  IdKassa: number;
  IdFaktura: number;
  IdZahlungsart?: number;
  Zahlbetrag: number;
  Email?: string;
  DMSLink?: string;
}
export interface OnKassaGutscheinRequest {
  IdKassa: number;
  Code?: string;
  IdGutschein?: number;
  WertOffen?: number;
}
export interface OnKassaInfoRequest {
  Benutzer?: BenutzerCompactModel;
  KundeStandard?: KundeInfo;
  KassaList?: KassaInfo[];
  MandantList?: MandantInfo[];
  EinheitList?: EinheitInfo[];
  LandList?: LandInfo[];
  ArtikelkategorieList?: ArtikelkategorieInfo[];
  ZahlungsartList?: ZahlungsartInfo[];
  IsWertBrutto: boolean;
  WaehrungMandant?: WaehrungInfo;
  LogoDMSLink?: string;
  BezeichnungMandant?: string;
  IsDruckjobAktiv: boolean;
}
export interface OnKassaKundeRequest {
  IdKassa: number;
  Suchtext?: string;
  IdKunde?: number;
  Kunde?: KundeInfo;
}
export interface OnKassaKundeUpdate {
  IdKassa: number;
  Kunde?: KundeInfo;
}
export interface OnKassaMandantWechseln {
  IdMandant: number;
}
export interface OnKassaRequestLagerbestandDetail {
  IdKassa: number;
  IdArtikel: number;
  BestandDetailList?: LagerbestandDetailInfo[];
}
export interface OnKassaStatusRequest {
  IdKassa: number;
  WertAktuell?: number;
  TaeglicherUmsatz?: number;
  MonatlicherUmsatz?: number;
}
export interface OnKassaWarenanforderung {
  IdKassa: number;
  IdAuftrag: number;
  Zahlbetrag: number;
  IdZahlungsart?: number;
  Email?: string;
  DMSLink?: string;
  PosList?: AuftragspositionInfo[];
  DMSLinkList?: string[];
}
export interface OnKassaZertifikatAnfordern {
  IdKassa: number;
}
export interface OnArtikelverleihAbholung {
  IdArtikelverleih: number;
}
export interface OnArtikelverleihRueckgabe {
  IdArtikelverleih: number;
}
export interface OnArtikelverleihVerfuegbar {
  IdLager: number;
  IdArtikel: number;
  IdArtikelverleih?: number;
  DatumVon: Date;
  DatumBis: Date;
  ReserviertList?: ReserviertInfo[];
  VerfuegbarList?: VerfuegbarInfo[];
}
export interface OnInventurFehlArtZuZaehlliste {
  IdInventur: number;
}
export interface OnInventurVerbuchen {
  IdInventur: number;
}
export interface OnInventurZaehllisteErstellen {
  IdInventur: number;
}
export interface OnLagerbestandRequest {
  IdFiliale: number;
  IdArtikel: number;
  LagerbestandList?: LagerbestandInfo[];
}
export interface OnLagerkorrekturVerbuchen {
  IdLagerkorrektur: number;
}
export interface OnAppMandantWechseln {
  IdMandant: number;
}
export interface OnErstelleProdAuftrag {
  IdArtikel: number;
  IdProdAuftrag?: number;
}
export interface OnProdAuftragBestaetigt {
  IdProdAuftrag: number;
  IsBestaetigt: boolean;
}
export interface OnProdAuftragRohwareLieferscheinErstellen {
  IdProdAuftrag: number;
  IdRohwareList: number[];
  IdLieferschein?: number;
}
export interface OnProdFertigwareQuick {
  IdProdAuftrag: number;
  IdAbgangslagerRW: number;
  MengeVerpEH: number;
  IdEinheit: number;
  DoErledigen: boolean;
  MHDChargeEintragList?: ProdFertigwareMHDChargeEintrag[];
}
export interface OnProjektTaetigkeitBuchungAnzahlAktiveBuchungen {
  Anzahl?: number;
}
export interface OnProjektTaetigkeitBuchungAusFakturaEntfernen {
  IdProjektTaetigkeitBuchung: number;
}
export interface OnProjektTaetigkeitBuchungHistorieGruppiertDatumRequest {
  DataList?: any[];
}
export interface OnProjektTaetigkeitBuchungNichtVerrechenbar {
  IdProjektTaetigkeitBuchungList: number[];
}
export interface OnProjektTaetigkeitBuchungVerrechnen {
  IdFakturaMerge?: number;
  IdGeschaeftspartner?: number;
  IdProjektTaetigkeitBuchungList: number[];
  IdFaktura?: number;
}
export interface OnProvisionBerechnen {
  IdFirma: number;
  Bis?: Date;
}
export interface OnProjektzeitReportDrucken {
  IdReport?: string;
  DatumVon: Date;
  DatumBis: Date;
  IdProjektList: number[];
  IdBenutzerList: number[];
  DMSLink?: string;
}
export interface OnProjektzeitReportRequest {
  ReportItemList?: ReportItem[];
}
export interface OnPasswortAendern {
  PasswortAlt?: string;
  PasswortNeu?: string;
}
export interface OnSpracheUebersetzen {
  IdSpracheContainer: number;
  DoSave: boolean;
  ItemList?: SpracheItemInfo[];
}
export interface OnArtikelartAendern {
  IdArtikel: number;
  IdArtikelart: number;
}
export interface OnAufgabeAnzahlRequest {
  AufgabeBenutzer: boolean;
  IdVerknuepfteEntitaet?: number;
  Anzahl?: number;
  AnzahlErinnernFaellig?: number;
}
export interface OnAufgabeErledigenRequest {
  IdAufgabe: number;
}
export interface OnBenachrichtigungAnzahlRequest {
  Anzahl?: number;
}
export interface OnBenachrichtigungGelesen {
  IdBenachrichtigung: number;
}
export interface OnBriefAnzahlRequest {
  Anzahl?: number;
  IdVerknuepfteEntitaet: number;
}
export interface OnBriefErstellen {
  IdVerknuepfteEntitaet?: number;
  IsHauptdokument: boolean;
  DMSLinkList?: string[];
  IdBrief?: number;
}
export interface OnBriefVersenden {
  IdBrief: number;
  IdVerknuepfteEntitaet?: number;
  VersandTyp?: BriefVersendenTyp;
}
export interface OnCreateNewsletterEmpfaenger {
  Bezeichnung?: string;
  IdPersonList: number[];
  IdNewsletterEmpfaenger?: number;
}
export interface OnCreateNewsletterEmpfaengerAusAnmeldung {
  Bezeichnung?: string;
  Briefanrede?: string;
  IdNewsletterEmpfaenger?: number;
}
export interface OnCreateZugriffHistorie {
  IdEntitaet: number;
}
export interface OnErstelleAngebotAusMailImportItem {
  IdMailImportItem: number;
  IdAngebot?: number;
}
export interface OnErstelleAuftragAusMailImportItem {
  IdMailImportItem: number;
  IdAuftrag?: number;
}
export interface OnErstelleBestellungAusMailImportItem {
  IdMailImportItem: number;
  IdBestellung?: number;
}
export interface OnErstelleDruckJob {
  DMSLink?: string;
  AnzAusdrucke?: number;
}
export interface OnErstelleKundenreklamationAusMailImportItem {
  IdMailImportItem: number;
  IdKundenreklamation?: number;
}
export interface OnEvalSteuertyp {
  IdFirma: number;
  IdGeschaeftspartner: number;
  SteuerTyp?: SteuerTyp;
}
export interface OnFehlerRequest {
  IdGeschaeftspartner?: number;
  IdRechnungsort?: number;
  IdArtikel?: number;
  FehlerList?: string[];
}
export interface OnFilterFeldRequest {
  TypeName?: string;
  FeldList?: FilterFeldItem[];
}
export interface OnFirmaSteuerinformationGueltigkeitAbgrenzen {
  IdFirmaSteuerinformation: number;
  GueltigVonFirmaSteuerinformationNeu: Date;
  IdKassaList: number[];
  SchlussBelegErstellen: boolean;
}
export interface OnGeschaeftspartnerartAendern {
  IdGeschaeftspartner: number;
  IdGeschaeftspartnerart: number;
}
export interface OnInfobarDynFeldRequest {
  Id: number;
  ItemList?: InfobarDynFeldItem[];
}
export interface OnInfoBVRohwareRequest {
  IdArtikel: number;
  IdFiliale: number;
  Bestand?: number;
  BestandFW?: number;
  Einheit?: string;
  DetailFWList?: InfoBVRohwareDetail[];
  DetailRWList?: InfoBVRohwareDetail[];
  DetailVerwandtList?: InfoBVRohwareDetail[];
}
export interface OnIsDiverserGeschaeftspartner {
  IdGeschaeftspartner: number;
  IsDivers?: boolean;
}
export interface OnKopierenRequest {
  IdSourceOjekt: number;
  CustomData?: any;
  IdTargetObjekt?: number;
}
export interface OnLandPlzQueryOrt {
  IdLand: number;
  Plz?: string;
  Ort?: string;
}
export interface OnMailImportItemAnzahlRequest {
  Anzahl?: number;
}
export interface OnMandantWechseln {
  IdMandant: number;
}
export interface OnNewsletterAnmeldungStatusAendern {
  IdAnmeldung: number;
  Status?: NewsletterAnmeldungStatus;
}
export interface OnNewsletterAnmeldungStatusRequest {
  Email?: string;
  Status?: string;
}
export interface OnNewsletterEmpfaengerImport {
  Bezeichnung?: string;
  DMSLink?: string;
}
export interface OnNewsletterTestVersenden {
  IdNewsletter: number;
  Email?: string;
}
export interface OnNewsletterVersenden {
  IdNewsletter: number;
}
export interface OnNotizAnzahlRequest {
  Anzahl?: number;
  IdVerknuepfteEntitaet?: number;
}
export interface OnOffice365AuthTokenAbfrage {
  HasAuthToken: boolean;
}
export interface OnOffice365TokenAnfordern {
  AuthUrl?: string;
}
export interface OnPapierkorbWiederherstellen {
  IdPapierkorb: number;
}
export interface OnPeriodeRequest {
  Datum: Date;
  Periode?: string;
  Ergebnis?: Date;
}
export interface OnPersonRequest {
  IdGeschaeftspartner: number;
  Typ?: PersonRequestTyp;
  IdPerson?: number;
}
export interface OnPersonWebshop {
  IdPerson: number;
  StatusWechselTyp?: PersonWebshopStatusWechselTyp;
}
export interface OnProjektDokumentRequest {
  IdProjekt: number;
  DokumentList?: ProjektDokumentInfo[];
}
export interface OnProjektRequestStatistik {
  IdProjekt: number;
  HasData?: boolean;
  Nettoumsatz?: number;
  Einstandswert?: number;
  DB?: number;
  DBProzent?: number;
  AnzLieferscheinpositionen?: number;
}
export interface OnRequestAnzAusdrucke {
  IdEntitaet: number;
  AnzAusdrucke?: number;
}
export interface OnRequestMandant {
  Id?: number;
  Bezeichnung?: string;
}
export interface OnRequestQRLabel {
  IdFirma: number;
  Anzahl: number;
  DMSLink?: string;
}
export interface OnStatusAendern {
  IdObjekt: number;
  IdStatus: number;
}
export interface OnStatusMoeglichRequest {
  IdObjekt: number;
  StatusList?: StatusItem[];
}
export interface OnTapiAnruf {
  Anrufer?: string;
  Geschaeftspartner?: string;
  UrlGeschaeftspartner?: string;
  Person?: string;
  UrlPerson?: string;
}
export interface OnUIDListValidieren {
  IdUIDNummerList: number[];
}
export interface OnUIDValidieren {
  UIDNummer?: string;
  NeuValidieren: boolean;
  IsGueltig: boolean;
  Text?: string;
  Exists: boolean;
}
export interface OnVerknuepfenEntitaeten {
  IdEntitaet1: number;
  IdEntitaet2: number;
}
export interface OnStatistikArtikelDataRequest {
  Von: Date;
  Bis: Date;
  IdFirma: number;
  UmsatzTyp?: StatistikUmsatzTyp;
  Filter?: any;
  ArtikelList?: any[];
  DynamischeSpalteList?: string[];
}
export interface OnStatistikArtikelInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikAuftragDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  IdFirma: number;
  NurOffenePositionen: boolean;
  DynamischeSpalteList?: string[];
  Filter?: any;
  AuftraegeList?: any[];
}
export interface OnStatistikAuftragInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikBestellungenDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  IdFirma: number;
  NurOffenePositionen: boolean;
  DynamischeSpalteList?: string[];
  Filter?: any;
  BestellungenList?: any[];
}
export interface OnStatistikBestellungenInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikDataRequest {
  KeyStatistik?: string;
  Von: Date;
  Bis: Date;
  FeldList?: StatistikFeldDataItem[];
  DataList?: any[];
}
export interface OnStatistikEinAuszahlungsRechnungDataRequest {
  DatumVon?: Date;
  DatumBis?: Date;
  IdFirma: number;
  DynamischeSpalteList?: string[];
  Filter?: any;
  EinAuszahlungsRechnungList?: any[];
  SteuerSpalteList?: SteuerListZusatzspalte[];
}
export interface OnStatistikEinAuszahlungsRechnungInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikEinkaufDetailRequest {
  DoGruppieren: boolean;
  Where?: UmsatzWhereItem;
  Data?: Object;
}
export interface OnStatistikFakturaDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  IdFirma: number;
  NurOffenePositionen: boolean;
  DynamischeSpalteList?: string[];
  Filter?: any;
  FakturaList?: any[];
}
export interface OnStatistikFakturaInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikFeldRequest {
  KeyStatistik?: string;
  InklDynFelder: boolean;
  FeldList?: StatistikFeldItem[];
}
export interface OnStatistikGeschaeftspartnerPersonDataRequest {
  Filter?: any;
  LoadPerson: boolean;
  LoadPersonDynFelder: boolean;
  LoadAktion: boolean;
  LoadFunktion: boolean;
  LoadUmsatz: boolean;
  LoadDynFelder: boolean;
  LoadBriefanrede: boolean;
  ItemList?: any[];
  ZusatzspalteList?: Zusatzspalte[];
}
export interface OnStatistikGeschaeftspartnerPersonInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikGruppeDataRequest {
  Von: Date;
  Bis: Date;
  IdFirma: number;
  Filter?: any;
  IdGruppe?: string;
  Vergleich?: StatistikGruppeVergleich;
  Caption?: string;
  DataList?: StatistikGruppeData[];
}
export interface OnStatistikGruppeInfoRequest {
  GruppeInfoList?: StatistikGruppeInfo[];
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikGruppiertDashboard {
  EntitaetFullName?: string;
  IdDynFeld?: number;
  ZeitraumTyp?: StatistikVerkaufZeitraumTyp;
  Anzahl: number;
  ShowRest: boolean;
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdFirma?: number;
  DataList?: StatistikGruppiertDashboardData[];
}
export interface OnStatistikKassaDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  IdKassa: number;
  Filter?: any;
  KassaInfoList?: any[];
}
export interface OnStatistikKassaDetailDataRequest {
  Datum: Date;
  IdKassa: number;
  Filter?: any;
  DynamischeSpalteList?: string[];
  KassaInfoList?: any[];
}
export interface OnStatistikKassaInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikKennzahlen {
  TypList?: StatistikKennzahlenTyp[];
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdFirma?: number;
  ItemList?: StatistikKennzahl[];
}
export interface OnStatistikKundeDataRequest {
  Von: Date;
  Bis: Date;
  IdFirma: number;
  UmsatzTyp?: StatistikUmsatzTyp;
  Filter?: any;
  KundeList?: any[];
  DynamischeSpalteList?: string[];
}
export interface OnStatistikKundeInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikLagerbestandDataRequest {
  InklDetail: boolean;
  Filter?: any;
  LagerbestandList?: any[];
  ZusatzspalteList?: Zusatzspalte[];
  DynamischeSpalteList?: string[];
}
export interface OnStatistikLagerbestandInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikLagerbewegungenDataRequest {
  DatumVon?: Date;
  DatumBis?: Date;
  LagerbuchungsartIdList: number[];
  IdArtikel?: number;
  LagerIdList: number[];
  DynamischeSpalteList?: string[];
  Filter?: any;
  LagerbewegungenList?: any[];
}
export interface OnStatistikLagerbewegungenInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikProjektzeitDataRequest {
  Von: Date;
  Bis: Date;
  Filter?: any;
  DataList?: any[];
}
export interface OnStatistikProjektzeitInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikReklamationenDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  IdFirma: number;
  NurOffeneReklamationen: boolean;
  DynamischeSpalteList?: string[];
  Filter?: any;
  ReklamationspositionList?: any[];
}
export interface OnStatistikReklamationenInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnStatistikRequest {
  ItemList?: StatistikItem[];
}
export interface OnStatistikVerkaufDetailRequest {
  ViewTyp?: StatistikVerkaufDetailViewTyp;
  DoGruppieren: boolean;
  Where?: UmsatzWhereItem;
  Data?: Object;
}
export interface OnStatistikWebshopLoginLogDataRequest {
  DatumVon: Date;
  DatumBis: Date;
  DynamischeSpalteList?: string[];
  Filter?: any;
  WebshoploginLogList?: any[];
}
export interface OnStatistikWebshopLoginLogInfoRequest {
  FilterInfoList?: StatistikFilterInfo[];
}
export interface OnTopArtikel {
  Anzahl: number;
  IdGeschaeftspartner?: number;
  IdFirma?: number;
  ItemList?: TopArtikelItem[];
  Where?: string;
}
export interface OnTopKunde {
  Anzahl: number;
  Art?: TopKundeArt;
  IdArtikel?: number;
  IdFirma?: number;
  ItemList?: TopKundeItem[];
  Where?: string;
}
export interface OnUmsatzAktuell {
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdFirma?: number;
  WertTyp?: StatistikVerkaufWertTyp;
  ItemList?: UmsatzItem[];
}
export interface OnUmsatzLand {
  IdArtikel?: number;
  IdFirma?: number;
  ZeitraumTyp?: StatistikVerkaufZeitraumTyp;
  WertTyp?: StatistikVerkaufWertTyp;
  Data?: Object;
}
export interface OnUmsatzMonat {
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdFirma?: number;
  WertTyp?: StatistikVerkaufWertTyp;
  Data?: Object;
}
export interface OnVolltextSucheSingle {
  Suchtext?: string;
  VerknuepfteEntitaetTypeName?: string;
  IdVerknuepfteEntitaet?: number;
  SuchtextPlain?: string;
}
export interface OnTagCreate {
  Bezeichnung?: string;
  EntitaetFullName?: string;
  Tag?: Object;
}
export interface OnTagUpdate {
  IdTag: number;
  Bezeichnung?: string;
  Farbe?: string;
}
export interface OnTicketAufwandBestaetigen {
  IdTicket: number;
}
export interface OnTicketBearbeiterRequest {
  IdTicket: number;
  BearbeiterList?: Object;
}
export interface OnTicketFollow {
  IdTicket: number;
  Follow: boolean;
}
export interface OnVersionHasUpdate {
  HasNewVersion: boolean;
}
export interface OnVersionUpdateGelesen {
}
export interface OnAngebotAddLieferscheinposition {
  IdAngebot: number;
  IdLieferscheinposition: number;
}
export interface OnAngebotGewichtAktualisieren {
  IdAngebot: number;
}
export interface OnAngebotKondAktualisieren {
  IdAngebot: number;
  AllePositionen: boolean;
  IdAngebotPosList: number[];
}
export interface OnAngebotPositionenSortieren {
  IdAngebot: number;
  Typ?: PositionSortierenTyp;
}
export interface OnAngebotspositionArtikelErsetzen {
  IdAngebotsposition: number;
  IdArtikel: number;
  IdAngebotspositionNeu?: number;
}
export interface OnAngebotspositionenInNeuesAngebotUebernehmen {
  IdAngebot: number;
  IdAngebotPosList: number[];
  IdAngebotKopie?: number;
}
export interface OnAngebotspositionVariablerSetartikelManuellerPreisEntfernen {
  IdAngebotspositionSetartikel: number;
}
export interface OnAngebotspositionVariablerSetartikelPreisAendern {
  IdAngebotspositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnAngebotspositionVariablerSetartikelRabattEntfernen {
  IdAngebotspositionSetartikel: number;
}
export interface OnAngebotspositionVariableStuecklisteAuswahl {
  IdArtikel?: number;
  IdAngebotsposition?: number;
  StuecklistenkomponentenAuswahlList?: StuecklistenkomponentenAuswahl[];
}
export interface OnAngebotspositionVariableStuecklistenkomponentenErstellen {
  IdAngebotsposition: number;
  StuecklistenkomponentenMengeList?: StuecklistenkomponentenMenge[];
}
export interface OnAuftragAddLieferscheinposition {
  IdAuftrag: number;
  IdLieferscheinposition: number;
}
export interface OnAuftragBestaetigt {
  IdAuftrag: number;
  IsBestaetigt: boolean;
}
export interface OnAuftragGewichtAktualisieren {
  IdAuftrag: number;
}
export interface OnAuftragIntercompanyErstellen {
  IdBestellung: number;
  IdAuftrag?: number;
}
export interface OnAuftragKondAktualisieren {
  IdAuftrag: number;
  AllePositionen: boolean;
  IdAuftragPosList: number[];
}
export interface OnAuftragListBestaetigt {
  IdAuftragList: number[];
  IsBestaetigt: boolean;
}
export interface OnAuftragListLieferdatumAendern {
  IdAuftragList: number[];
  Lieferdatum: Date;
}
export interface OnAuftragPositionenSortieren {
  IdAuftrag: number;
  Typ?: PositionSortierenTyp;
}
export interface OnAuftragRecalcLieferdatum {
  IdAuftrag: number;
}
export interface OnAuftragRequestStatistik {
  IdAuftrag: number;
  HasData?: boolean;
  Nettoumsatz?: number;
  Einstandswert?: number;
  DB?: number;
  DBProzent?: number;
  AnzLieferscheinpositionen?: number;
}
export interface OnAuftragReservieren {
  IdAuftrag: number;
  DoReservieren: boolean;
  AllePositionen: boolean;
  IdAuftragPosList: number[];
}
export interface OnAuftragspositionArtikelErsetzen {
  IdAuftragsposition: number;
  IdArtikel: number;
  IdAuftragspositionNeu?: number;
}
export interface OnAuftragspositionenInNeuenAuftragUebernehmen {
  IdAuftrag: number;
  IdAuftragPosList: number[];
  IdAuftragKopie?: number;
}
export interface OnAuftragspositionRecalcLieferdatum {
  IdAuftragsposition: number;
}
export interface OnAuftragspositionVariablerSetartikelManuellerPreisEntfernen {
  IdAuftragspositionSetartikel: number;
}
export interface OnAuftragspositionVariablerSetartikelPreisAendern {
  IdAuftragspositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnAuftragspositionVariablerSetartikelRabattEntfernen {
  IdAuftragspositionSetartikel: number;
}
export interface OnAuftragspositionVariableStuecklisteAuswahl {
  IdArtikel?: number;
  IdAuftragsposition?: number;
  StuecklistenkomponentenAuswahlList?: StuecklistenkomponentenAuswahl[];
}
export interface OnAuftragspositionVariableStuecklistenkomponentenErstellen {
  IdAuftragsposition: number;
  StuecklistenkomponentenMengeList?: StuecklistenkomponentenMenge[];
}
export interface OnAuftragVorauskasse {
  IdAuftrag: number;
}
export interface OnDeleteIdAngebotspositionList {
  IdAngebotspositionList: number[];
}
export interface OnDeleteIdAuftragspositionList {
  IdAuftragspositionList: number[];
}
export interface OnDeleteIdFakturapositionList {
  IdFakturapositionList: number[];
}
export interface OnDeleteIdLieferscheinpositionList {
  IdLieferscheinpositionList: number[];
}
export interface OnErstelleAngebot {
  IdGeschaeftspartner: number;
  IdAngebot?: number;
}
export interface OnErstelleAuftrag {
  IdGeschaeftspartner: number;
  IdAuftrag?: number;
}
export interface OnErstelleAuftragAusAngebot {
  IdAngebot: number;
  IdAuftrag?: number;
  UebernehmeAllePositionen: boolean;
  IdAngebotPosList: number[];
}
export interface OnErstelleFakturaStorno {
  IdFaktura: number;
  AllePositionen: boolean;
  IdFakturaPosList: number[];
  IdStorno?: number;
}
export interface OnErstelleLieferscheinAusAuftrag {
  IdAuftrag: number;
  IdMergeMitLieferschein?: number;
  IdLieferschein?: number;
  UebernehmeAllePositionen: boolean;
  IdAuftragPosList: number[];
}
export interface OnEvalLieferRechnungsort {
  IdKunde: number;
  IdLieferort?: number;
  HasMoreThanOneLieferort?: boolean;
  IdRechnungsort?: number;
}
export interface OnEvalVerkaufArtikeldaten {
  IdArtikel: number;
  IdKunde: number;
  IdArtikelEinheit?: number;
  KundeArtikelNr?: string;
  KundeArtikelbezeichnung?: string;
}
export interface OnFakturaAbschliessen {
  IdFaktura: number;
}
export interface OnFakturaBezahltAendern {
  IdFaktura: number;
  Typ?: FakturaBezahltTyp;
}
export interface OnFakturaListAbschliessen {
  IdFakturaList: number[];
}
export interface OnFakturaMahnen {
  IdFaktura: number;
  IdMahnung?: number;
}
export interface OnFakturapositionVariablerSetartikelManuellerPreisEntfernen {
  IdFakturapositionSetartikel: number;
}
export interface OnFakturapositionVariablerSetartikelPreisAendern {
  IdFakturapositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnFakturapositionVariablerSetartikelRabattEntfernen {
  IdFakturapositionSetartikel: number;
}
export interface OnFakturapositionVariableStuecklisteAuswahl {
  IdArtikel?: number;
  IdFakturaposition?: number;
  StuecklistenkomponentenAuswahlList?: StuecklistenkomponentenAuswahl[];
}
export interface OnFakturapositionVariableStuecklistenkomponentenErstellen {
  IdFakturaposition: number;
  StuecklistenkomponentenMengeList?: StuecklistenkomponentenMenge[];
}
export interface OnImportAngebotspositionenInAuftrag {
  IdAuftrag: number;
  IdAngebotspositionList: number[];
}
export interface OnImportAuftragInLieferschein {
  IdLieferschein: number;
  IdAuftragList: number[];
}
export interface OnImportLieferscheinInLadeliste {
  IdLadeliste: number;
  IdLieferscheinList: number[];
}
export interface OnImportLieferscheinpositionenInKundenreklamation {
  IdKundenreklamation: number;
  IdLieferscheinpositionList: number[];
}
export interface OnKundenreklamationAbschliessen {
  IdKundenreklamation: number;
}
export interface OnKundenreklamationErsatzauftragErstellen {
  IdKundenreklamation: number;
  IdAuftrag?: number;
}
export interface OnKundenreklamationWarenuebernahmeErstellen {
  IdKundenreklamation: number;
  IdWarenuebernahme?: number;
}
export interface OnLadelisteEntferneLieferscheine {
  IdLadeliste: number;
  IdLadelistePosList: number[];
}
export interface OnLieferscheinAbschliessen {
  IdLieferschein: number;
}
export interface OnLieferscheinFakturaStatusAendern {
  IdLieferschein: number;
  Status?: LieferscheinFakturaStatus;
}
export interface OnLieferscheinGewichtAktualisieren {
  IdLieferschein: number;
}
export interface OnLieferscheinListAbschliessen {
  IdLieferscheinList: number[];
}
export interface OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernen {
  IdLieferscheinpositionSetartikel: number;
}
export interface OnLieferscheinpositionVariablerSetartikelPreisAendern {
  IdLieferscheinpositionSetartikel: number;
  Typ?: VariablerSetartikelKalkulationFeldTyp;
  Wert: number;
}
export interface OnLieferscheinpositionVariablerSetartikelRabattEntfernen {
  IdLieferscheinpositionSetartikel: number;
}
export interface OnMahnlauf {
}
export interface OnMahnungenVersenden {
}
export interface OnMahnungVersenden {
  IdMahnung: number;
}
export interface OnWebValidate {
  TypeName?: string;
  Id: number;
  Code?: string;
  ResultList?: WebValidationResult[];
}
export interface Object {
}
export interface DatabaseQuery {
  SessionId: number;
  Text?: string;
  Status?: string;
  Command?: string;
  CpuTime: number;
  TotalElapsedTime: number;
}
export interface ReportItem {
  Id?: string;
  Bezeichnung?: string;
}
export interface DynamischesFeldVorbelegungItem {
  IdDynamischesFeld: number;
  VorbIdWertAuswahlliste?: number;
  VorbWertText?: string;
  VorbWertDatum?: Date;
  VorbWertNummer?: number;
}
export enum PositionSortierenTyp {
  ArtikelNr = 0,
  Artikelbezeichnung = 1
}
export enum VariablerSetartikelKalkulationFeldTyp {
  Rabatt = 0,
  ManuellerPreis = 1
}
export enum EingangsrechnungBezahltTyp {
  Offen = 0,
  Bezahlt = 1
}
export enum BelegImportTyp {
  Eingangsrechnung = 0,
  EingangsrechnungDokument = 1,
  QRDokument = 2,
  Dokument = 3,
  Angebot = 4,
  Auftrag = 5,
  Bestellung = 6,
  Artikel = 7,
  FertigstellungStreckengeschaeft = 8,
  Faktura = 9
}
export enum IntrastatSyncTyp {
  Export = 0,
  Import = 1
}
export interface KalkulationInputItem {
  Code?: string;
  IsManuell: boolean;
  Id: number;
  Wert?: number;
  WertPer?: number;
  Staffel?: number;
  WaehrungISO4217?: string;
}
export interface KalkulationFixItem {
  Typ?: KalkulationItemFixTyp;
  Id: number;
  Wert?: number;
  WertPer?: number;
  Staffel?: number;
  WaehrungISO4217?: string;
}
export interface KalkulationOutputItem {
  Code?: string;
  Id: number;
  Wert?: number;
  WertPer?: number;
  Staffel?: number;
  WaehrungISO4217?: string;
}
export interface KalkulationPosItem {
  Code?: string;
  PosType?: KalkulationItemPosInputType;
  Id: number;
  Wert?: number;
  WertPer?: number;
  Staffel?: number;
  WaehrungISO4217?: string;
}
export interface KalkulationSteuerPosItem {
  Steuersatz: number;
  WertNettoPos: number;
  WertNettoGes: number;
  WertSteuerPos: number;
  WertSteuerGes: number;
  WertBruttoPos: number;
  WertBruttoGes: number;
}
export interface KalkulationFeldItem {
  Id: number;
  Typ?: KalkulationFeldTyp;
  Code?: string;
  Bezeichnung?: string;
  SortNr: number;
  AnzKommastellen?: number;
  Waehrung?: KonditionsfeldWaehrung;
  HasWertPer: boolean;
  HasStaffel: boolean;
  VorbWaehrungISO4217?: string;
  IsAenderbar: boolean;
  IsSichtbar: boolean;
}
export interface ArtikelInfo {
  Id: number;
  Bezeichnung?: string;
  Bestand: number;
  IdEinheit: number;
  Listenpreis: number;
  Rabatt: number;
  Preis: number;
  Steuersatz?: number;
  Bild?: string;
  IsPfand: boolean;
  IdDynamischesFeldContainer: number;
  DynamischesFeldInfoList?: ArtikelDynamischesFeldInfo[];
  SupportsMHD: boolean;
  SupportsCharge: boolean;
  IsBezeichnungEditierbar: boolean;
  IsLagerfuehrend: boolean;
}
export interface AuftragInfo {
  Id: number;
  Nummer?: number;
  Datum?: Date;
  Firmenbez1?: string;
  Ort?: string;
  WertGesamt?: number;
  Bezahlt?: number;
  Offen?: number;
  IsBestaetigt: boolean;
  Reservierungsgrad: number;
  PosList?: AuftragspositionInfo[];
}
export interface BelegInfo {
  Id?: number;
  Nummer?: number;
  Datum: Date;
  IdKunde: number;
  Zahlbetrag: number;
  IsAnzahlung: boolean;
  IdZahlungsart?: number;
  IsAufRechnung: boolean;
  Rabatt?: number;
  WertBrutto: number;
  DMSLink?: string;
  BelegPosList?: BelegPosInfo[];
  GutscheinList?: GutscheinInfo[];
}
export interface KassaBelegPositionInfo {
  Id: number;
  Bezeichnung?: string;
  IdArtikel: number;
  IdEinheit: number;
  MengeLagEH?: number;
  GewichtTara?: number;
  GewichtBrutto?: number;
  MengeStorniertLagEH?: number;
  Rabatt?: number;
  WertBrutto: number;
}
export interface KassaBelegPositionStornoInfo {
  Id: number;
  MengeLagEH: number;
}
export interface FakturaInfo {
  Id: number;
  Nummer?: number;
  Datum?: Date;
  Firmenbez1?: string;
  Ort?: string;
  WertGesamt?: number;
  Bezahlt?: number;
  Offen?: number;
}
export interface BenutzerCompactModel {
  Id: number;
  Initiale?: string;
  Vorname?: string;
  Nachname?: string;
  Farbe?: string;
  AvatarDownloadKey?: string;
  CanMandantWechseln: boolean;
}
export interface KundeInfo {
  Id: number;
  Nummer: number;
  Firmenbez1?: string;
  Firmenbez2?: string;
  Firmenbez3?: string;
  Strasse?: string;
  IdLand: number;
  PLZ?: string;
  Ort?: string;
  Telefon?: string;
  Email?: string;
  IsDiverserGeschaeftspartner: boolean;
}
export interface KassaInfo {
  Id: number;
  Bezeichnung?: string;
  IsEmailversandKassaErlaubt: boolean;
}
export interface MandantInfo {
  Id: number;
  Bezeichnung?: string;
}
export interface EinheitInfo {
  Id: number;
  Bezeichnung?: string;
  IsTaraKassa: boolean;
}
export interface LandInfo {
  Id: number;
  Bezeichnung?: string;
}
export interface ArtikelkategorieInfo {
  Id: number;
  Bezeichnung?: string;
  SortNr?: number;
  IdUebergeordneteArtikelkategorie?: number;
}
export interface ZahlungsartInfo {
  Id: number;
  Bezeichnung?: string;
  IsBargeld: boolean;
}
export interface WaehrungInfo {
  Id: number;
  Iso4217?: string;
}
export interface LagerbestandDetailInfo {
  MHD?: Date;
  Charge?: string;
  Bestand: number;
}
export interface AuftragspositionInfo {
  Id: number;
  ArtikelNr?: string;
  Bezeichnung?: string;
  MengeOffenLagEH: number;
  MengeReserviertLagEH: number;
  IdEinheit?: number;
  CanLiefern: boolean;
  Liefern: boolean;
}
export interface ReserviertInfo {
  IdArtikelverleih: number;
  Firmenbez1?: string;
  Ort?: string;
  MengeLagEH: number;
  Einheit?: string;
  Abholung?: Date;
  Rueckgabe?: Date;
  IsGleichzeitig: boolean;
}
export interface VerfuegbarInfo {
  Datum: Date;
  VerfuegbarLagEH: number;
}
export interface LagerbestandInfo {
  IdLager: number;
  Bezeichnung?: string;
  Bestand: number;
  Frei: number;
  Schwimmend: number;
  Bestellt: number;
  Produktion: number;
  Auftrag: number;
  Reserviert: number;
}
export interface ProdFertigwareMHDChargeEintrag {
  MHD: Date;
  Charge?: string;
  MengeVerpEH: number;
}
export interface SpracheItemInfo {
  IdSprache: number;
  Eigenschaft?: string;
  Caption?: string;
  EditorTyp?: SpracheEditorTyp;
  MaxLaenge: number;
  Original?: string;
  Uebersetzung?: string;
}
export enum BriefVersendenTyp {
  Drucken = 0,
  Email = 1
}
export enum SteuerTyp {
  ReverseCharge = 0,
  Binnenlieferung = 1,
  InnergemeinschaftlichDreieck = 2,
  Innergemeinschaftlich = 3,
  GrenzueberschrOhneUSt = 4,
  GrenzueberschrMitUSt = 5,
  Steuerfrei = 6,
  InnergemeinschaftlichMitUst = 7,
  EUOSS = 8
}
export interface FilterFeldItem {
  Name?: string;
  Bezeichnung?: string;
  Datentyp?: string;
  Format?: string;
  Operatoren?: string[];
  IdDynFeld: number;
  DynFeldAuswahlliste?: string;
  IdSelect?: string;
  SelectFilter?: string;
  SelectCustoms?: string;
}
export interface InfobarDynFeldItem {
  Bezeichnung?: string;
  Typ?: DynamischesFeldtyp;
  Wert?: string;
}
export interface InfoBVRohwareDetail {
  IdArtikel: number;
  Nummer?: string;
  Bezeichnung?: string;
  Bestand: number;
  Einheit?: string;
}
export enum NewsletterAnmeldungStatus {
  ManuelleAnlage = 0,
  Angemeldet = 1,
  Aktiviert = 2,
  Abgemeldet = 3
}
export enum PersonRequestTyp {
  Angebot = 0,
  Auftrag = 1,
  Lieferschein = 2,
  Faktura = 3,
  Bestellung = 4
}
export enum PersonWebshopStatusWechselTyp {
  Aktivieren = 0,
  Deaktiviern = 1,
  NeuesPasswort = 2
}
export interface ProjektDokumentInfo {
  Id: number;
  TypeName?: string;
  Gruppe?: string;
  Dateiname?: string;
  IdDokumentenart?: number;
  Dokumentenart?: string;
  DMSLink?: string;
  MimeType?: string;
  AnlageDatum?: Date;
}
export interface StatusItem {
  Id: number;
  Bezeichnung?: string;
}
export enum StatistikUmsatzTyp {
  Alle = 0,
  MitUmsatz = 1,
  OhneUmsatz = 2
}
export interface StatistikFilterInfo {
  Name?: string;
  Bezeichnung?: string;
  Datentyp?: string;
  Format?: string;
  Operatoren?: string[];
  IdDynFeld: number;
  DynFeldAuswahlliste?: string;
  IdSelect?: string;
  SelectFilter?: string;
  SelectCustoms?: string;
}
export interface StatistikFeldDataItem {
  Key?: string;
  Aggregate: boolean;
}
export interface SteuerListZusatzspalte {
  DataField?: string;
  Bezeichnung?: string;
  DataType?: string;
  Breite?: string;
  MinBreite?: number;
  Format?: string;
  Steuersatz?: string;
}
export interface UmsatzWhereItem {
  Typ?: UmsatzWhereTyp;
  Data?: any;
  IdGeschaeftspartner?: number;
  IdArtikel?: number;
  IdProjekt?: number;
  IdFirma?: number;
}
export interface StatistikFeldItem {
  Key?: string;
  Typ?: StatistikFeldTyp;
  Expression?: string;
  Bezeichnung?: string;
  Datentyp?: string;
  Format?: string;
  DynFeldAuswahlliste?: string;
  Width?: string;
  IsVisible: boolean;
  CanAggregate: boolean;
  StandardArea?: string;
}
export interface Zusatzspalte {
  DataField?: string;
  Bezeichnung?: string;
  DataType?: string;
  Breite?: string;
  MinBreite?: number;
  Format?: string;
}
export enum StatistikGruppeVergleich {
  Kein = 0,
  Monat = 1,
  Jahr = 2
}
export interface StatistikGruppeData {
  Bezeichnung?: string;
  Wert?: number;
  WertVergleich?: number;
}
export interface StatistikGruppeInfo {
  Id?: string;
  Bezeichnung?: string;
}
export enum StatistikVerkaufZeitraumTyp {
  Jahr = 0,
  YTD = 1,
  Vorjahr = 2
}
export interface StatistikGruppiertDashboardData {
  Bezeichnung?: string;
  Wert: number;
  Id: number;
}
export enum StatistikKennzahlenTyp {
  OffeneBestellungen = 0,
  OffeneWarenuebernahmen = 1,
  OffeneEingangsrechnungen = 2,
  OffeneAngebote = 3,
  OffeneAuftraege = 4,
  OffeneLieferscheine = 5,
  OffeneFakturen = 6,
  OffenePosten = 7,
  OffeneKundenreklamationen = 8,
  OffeneProduktionen = 9,
  AnzArtikel = 10,
  AnzKunden = 11,
  AnzLieferanten = 12,
  AnzNewsletterAnmeldung = 13,
  AnzNewsletterAbmeldung = 14
}
export interface StatistikKennzahl {
  Bezeichnung?: string;
  Anzahl: number;
  Wert?: number;
  TypeName?: string;
  FilterInject?: string;
}
export interface StatistikItem {
  Key?: string;
  Bezeichnung?: string;
}
export enum StatistikVerkaufDetailViewTyp {
  Statistik = 0,
  Auftrag = 1,
  Forecast = 2
}
export interface TopArtikelItem {
  Id: number;
  Nummer?: string;
  Bezeichnung?: string;
  Wert: number;
  Vergleich: number;
  Menge: number;
  MengeVergleich: number;
  Einheit?: string;
}
export enum TopKundeArt {
  Kunde = 0,
  Lieferant = 1
}
export interface TopKundeItem {
  Id: number;
  Nummer?: number;
  Firmenbez1?: string;
  Ort?: string;
  Wert: number;
  Vergleich: number;
}
export enum StatistikVerkaufWertTyp {
  Netto = 0,
  Einsatz = 1,
  DB = 2,
  Auftragseingang = 3,
  NettoMitForecast = 4
}
export interface UmsatzItem {
  Name?: string;
  NameVergleich?: string;
  NameVergleich2?: string;
  Wert: number;
  Vergleich: number;
  Vergleich2: number;
  Plan: number;
  WhereWert?: UmsatzWhereItem;
  WhereVergleich?: UmsatzWhereItem;
  WhereVergleich2?: UmsatzWhereItem;
}
export interface StuecklistenkomponentenAuswahl {
  Id: number;
  Artikelnummer?: string;
  Artikelbezeichnung?: string;
  Menge: number;
  Einheit?: string;
  Faktor?: string;
  IsInaktiv: boolean;
  HasVariablerSetartikelVorbelegung: boolean;
}
export interface StuecklistenkomponentenMenge {
  IdStuecklistenkomponente: number;
  Menge: number;
}
export enum FakturaBezahltTyp {
  Offen = 0,
  Bezahlt = 1,
  Inkasso = 2,
  Uneinbringbar = 3
}
export enum LieferscheinFakturaStatus {
  Offen = 0,
  FakturaErstellt = 1,
  FakturaNichtNotwendig = 2,
  FakturaGeloescht = 3
}
export interface WebValidationResult {
  IsError: boolean;
  Text?: string;
}
export enum KalkulationItemFixTyp {
  Einstandspreis = 0,
  ProdExterneKosten = 1,
  Transportkosten = 2,
  VariablerSetartikel = 3
}
export enum KalkulationItemPosInputType {
  Sum = 0
}
export enum KalkulationFeldTyp {
  Konditionsfeld = 0,
  Berechnungsfeld = 1
}
export enum KonditionsfeldWaehrung {
  Waehrung = 0,
  Prozent = 1,
  Alles = 2
}
export interface ArtikelDynamischesFeldInfo {
  Bezeichnung?: string;
  WertText?: string;
  WertAuswahlliste?: string;
  WertDatum?: Date;
  WertNummer?: number;
  Typ?: DynamischesFeldtyp;
  SortNr?: number;
}
export interface BelegPosInfo {
  IdArtikel: number;
  Bezeichnung?: string;
  IdEinheit: number;
  MengeLagEH: number;
  GewichtTara?: number;
  GewichtBrutto?: number;
  Rabatt?: number;
  PreisManuell?: number;
  Steuersatz?: number;
  WertBrutto: number;
  MHD?: Date;
  Charge?: string;
  IsBezeichnungEditierbar: boolean;
  DoBerechnen: boolean;
}
export interface GutscheinInfo {
  IdGutschein: number;
  Code?: string;
  WertOffen: number;
}
export enum SpracheEditorTyp {
  TextBox = 0,
  TextArea = 1,
  HtmlEditor = 2
}
export enum DynamischesFeldtyp {
  Text = 0,
  Datum = 1,
  Nummer = 2,
  Auswahlliste = 3,
  FormatiertenText = 4,
  Checkbox = 5,
  MehrzeiligerText = 6
}
export enum UmsatzWhereTyp {
  Tag = 0,
  Monat = 1,
  Jahr = 2,
  YTD = 3,
  YTD2 = 4
}
export enum StatistikFeldTyp {
  Property = 0,
  DynFeld = 1
}
