import {
  PLATFORM
} from "aurelia-framework";

export default {
  baseUrl: "http://localhost:5073",
  apiUrl: "api",
  webApiUrl: "api/data",
  webSocketUrl: "api",

  loginApp: PLATFORM.moduleName("framework/login/login"),
  mainApp: PLATFORM.moduleName("app"),

  appId: "Cloud.ERP"
}
