import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class KassaBelegEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "kassa-beleg-edit";
        this.title = "kassa-beleg-edit.kassa-beleg-edit_caption";
        this.addModel({
            "id": "$m_KassaBeleg",
            "webApiAction": "ERP/Kassa/KassaBeleg",
            "webApiExpand": {
                'GutscheinContainer': {
                    'expand': {
                        'GutscheinZuObjekte': {
                            'expand': {
                                'Gutschein': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_KassaBelegpositionen",
            "webApiAction": "ERP/Kassa/KassaBelegposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GutscheinZuObjekte",
            "webApiAction": "ERP/Verkauf/GutscheinZuObjekt",
            "webApiExpand": {
                'Gutschein': null
            },
            "webApiWhere": ["IdGutscheinContainer", {
                "isBound": true,
                "expression": "models.data.$m_KassaBeleg.IdGutscheinContainer"
            }],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kassa",
            "filters": [],
            "caption": "kassa-beleg-edit.r_kassa_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdKassa",
                "bindToFQ": "models.data.$m_KassaBeleg.IdKassa"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_kassa",
            "options": {
                "optionsName": "r_kassaOptions",
                "optionsNameFQ": "r_kassaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "kassa-beleg-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_KassaBeleg.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "kassa-beleg-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_KassaBeleg.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lager",
            "filters": [],
            "caption": "kassa-beleg-edit.r_lager_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdLager",
                "bindToFQ": "models.data.$m_KassaBeleg.IdLager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lager",
            "options": {
                "optionsName": "r_lagerOptions",
                "optionsNameFQ": "r_lagerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "kassa-beleg-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_KassaBeleg.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zahlungsart",
            "filters": [],
            "caption": "kassa-beleg-edit.r_zahlungsart_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdZahlungsart",
                "bindToFQ": "models.data.$m_KassaBeleg.IdZahlungsart"
            },
            "validationRules": [],
            "id": "r_zahlungsart",
            "options": {
                "optionsName": "r_zahlungsartOptions",
                "optionsNameFQ": "r_zahlungsartOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "kassa-beleg-edit.r_aufrechnung_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IsAufRechnung",
                "bindToFQ": "models.data.$m_KassaBeleg.IsAufRechnung"
            },
            "validationRules": [],
            "id": "r_aufRechnung",
            "options": {
                "optionsName": "r_aufRechnungOptions",
                "optionsNameFQ": "r_aufRechnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "kassa-beleg-edit.r_zahlbetrag_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Zahlbetrag",
                "bindToFQ": "models.data.$m_KassaBeleg.Zahlbetrag"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_zahlbetrag",
            "options": {
                "optionsName": "r_zahlbetragOptions",
                "optionsNameFQ": "r_zahlbetragOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "kassa-beleg-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_KassaBeleg.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kassaBelegTyp",
            "filters": [],
            "caption": "kassa-beleg-edit.r_typ_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_KassaBeleg.Typ"
            },
            "validationRules": [],
            "id": "r_typ",
            "options": {
                "optionsName": "r_typOptions",
                "optionsNameFQ": "r_typOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kassaBelegSignaturStatus",
            "filters": [],
            "caption": "kassa-beleg-edit.r_signaturstatus_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "SignaturStatus",
                "bindToFQ": "models.data.$m_KassaBeleg.SignaturStatus"
            },
            "validationRules": [],
            "id": "r_signaturStatus",
            "options": {
                "optionsName": "r_signaturStatusOptions",
                "optionsNameFQ": "r_signaturStatusOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "kassa-beleg-edit.r_text_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_KassaBeleg.Text"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 200
                    }]
                }
            }],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "kassa-beleg-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_KassaBeleg.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "caption": "kassa-beleg-edit.r_fehlermeldung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_KassaBeleg",
                "bindTo": "Fehlermeldung",
                "bindToFQ": "models.data.$m_KassaBeleg.Fehlermeldung"
            },
            "validationRules": [],
            "id": "r_fehlermeldung",
            "options": {
                "optionsName": "r_fehlermeldungOptions",
                "optionsNameFQ": "r_fehlermeldungOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kassaPosArtikelNr",
                "caption": "kassa-beleg-edit.r_kassaposartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_kassaPosArtikelBezeichnung",
                "caption": "kassa-beleg-edit.r_kassaposartikelbezeichnung_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kassaPosMengeLagEH",
                "caption": "kassa-beleg-edit.r_kassaposmengelageh_caption",
                "bindTo": "MengeLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_kassaPosEinheit",
                "caption": "kassa-beleg-edit.r_kassaposeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_kassaPosMHD",
                "caption": "kassa-beleg-edit.r_kassaposmhd_caption",
                "bindTo": "MHD",
                "width": "90px"
            }, {
                "id": "r_kassaPosCharge",
                "caption": "kassa-beleg-edit.r_kassaposcharge_caption",
                "bindTo": "Charge",
                "width": "80px"
            }, {
                "id": "r_kassaPosWertBrutto",
                "caption": "kassa-beleg-edit.r_kassaposwertbrutto_caption",
                "bindTo": "WertBrutto",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_kassaBelegpositionenGridToolbarOptions",
                "optionsNameFQ": "r_kassaBelegpositionenGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_KassaBeleg"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_KassaBelegpositionen",
                "bindTo": "IdKassaBeleg",
                "bindToFQ": "models.data.$m_KassaBelegpositionen.IdKassaBeleg"
            },
            "dataModel": "$m_KassaBeleg",
            "pageSize": 20,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kassaBelegpositionenGrid",
            "options": {
                "optionsName": "r_kassaBelegpositionenGridOptions",
                "optionsNameFQ": "r_kassaBelegpositionenGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kassaGutCode",
                "caption": "kassa-beleg-edit.r_kassagutcode_caption",
                "bindTo": "Gutschein.Code",
                "minWidth": 100
            }, {
                "id": "r_kassaGutWertEingeloest",
                "caption": "kassa-beleg-edit.r_kassagutwerteingeloest_caption",
                "bindTo": "WertEingeloest",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_kassaGutscheineGridToolbarOptions",
                "optionsNameFQ": "r_kassaGutscheineGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_GutscheinZuObjekte"
            },
            "dataModel": "$m_GutscheinZuObjekte",
            "pageSize": 20,
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kassaGutscheineGrid",
            "options": {
                "optionsName": "r_kassaGutscheineGridOptions",
                "optionsNameFQ": "r_kassaGutscheineGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}