import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  OnEvalEinkaufArtikeldaten,
  OnEvalEinkaufArtikeldatenEvent,
  OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernenEvent,
  OnWarenuebernahmepositionVariablerSetartikelPreisAendernEvent,
  OnWarenuebernahmepositionVariablerSetartikelRabattEntfernenEvent
} from "../../../../framework-data/events";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase, ICommandData, ModelUtilsService
} from "../../../../framework/forms/export";
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from "../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern";
import {
  IKalkulationRefreshOptions
} from "../../../interfaces/kalkulation-refresh-options";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";
import {
  KalkulationRefreshService
} from "../../../services/kalkulation-refresh-service";
import { StartupService } from "../../../services/startup-service";

@autoinject
@transient()
export class WarenuebernahmepositionEditUtils {
  constructor(
    public dynFelderDataService: DynFelderDataService,
    public startupService: StartupService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _modelUtilsService: ModelUtilsService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  showVariablerSetartikelCheckbox: boolean;
  onReloadStuecklistenkomponentenlist: boolean;

  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "warenuebernahme",
    icon: "money",
    title: "warenuebernahme.preis_aendern",
    isVisibleExpression: "functions.$f_WarenuebernahmepositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Warenuebernahmeposition && models.data.$m_Warenuebernahmeposition.Id > 0 && models.data.$m_Warenuebernahmeposition.IsVariablerSetartikel && models.data.$m_Warenuebernahmeposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Warenuebernahmeposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    this.refreshOptions = {
      form: form,
      modelName: "$m_Warenuebernahmeposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeIstVerpEH"
    }

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "warenuebernahmepositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Warenuebernahmeposition") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "MengeIstVerpEH" && this.form.models.data.$m_Warenuebernahmeposition.IsSetartikel) {
          this.onReloadStuecklistenkomponentenlist = true;
        }
      }
    });

    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0 || e.model.id != "$m_Warenuebernahmeposition") {
        return;
      }

      this.showVariablerSetartikelCheckbox = this.form.models.data.$m_Warenuebernahmeposition
        && this.form.models.data.$m_Warenuebernahmeposition.Artikel != null
        && this.form.models.data.$m_Warenuebernahmeposition.Artikel.BezugTyp == 3
        ? true
        : false;
    });

    this.form.models.onSaved.register(async (e) => {
      if (this.form.models.data.$m_Warenuebernahmeposition.IsSetartikel) {
        if (this.onReloadStuecklistenkomponentenlist) {
          this.onReloadStuecklistenkomponentenlist = false;
          this.form.models.reloadAll();
        }
      }
    });

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);
  }

  async idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);

    if (!this.form.models.data.$m_Warenuebernahmeposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Warenuebernahmeposition);
    }

    this.doEvalEinkaufArtikeldaten(e.detail.value);
  }

  copyWarenuebernahmeposition($delegate: { (customData: any): Promise<any> }) {
    $delegate({ "PosNrUeberschreiben": true })
  }

  private async doEvalEinkaufArtikeldaten(idArtikel) {
    if (!idArtikel) {
      return;
    }

    const webEvent = new OnEvalEinkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdLieferant: this.form.models.data.$m_Warenuebernahme.IdLieferant
    });

    const result: OnEvalEinkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    this.form.models.data.$m_Warenuebernahmeposition.IdEinheit = result.IdArtikelEinheit;
    this.form.models.data.$m_Warenuebernahmeposition.LieferantArtikelNr = result.LieferantArtikelNr;
    this.form.models.data.$m_Warenuebernahmeposition.LieferantArtikelbezeichnung = result.LieferantArtikelbezeichnung;
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Warenuebernahmeposition) {
      return;
    }

    if (this.form.models.data.$m_Warenuebernahmeposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistenkomponentenlist = true;
    }
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnWarenuebernahmepositionVariablerSetartikelRabattEntfernenEvent({
            IdWarenuebernahmepositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnWarenuebernahmepositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdWarenuebernahmepositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnWarenuebernahmepositionVariablerSetartikelPreisAendernEvent({
            IdWarenuebernahmepositionSetartikel: this.form.models.data.$m_Bestellungsposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }
}

