import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  NotificationTyp
} from "../enumerations/export";
import { KonditionsfeldWaehrung } from "../../framework-data/events";

@autoinject
export class WaehrungService {
  private _waehrungList: any[];
  
  constructor(
    private eventAggregator: EventAggregator,
    private rest: RestService
  ) {
    this.initializeSubscribers();
  }

  getWaehrungList(waehrung: KonditionsfeldWaehrung): any[] {
    return this._waehrungList.filter((c) => {
      switch (waehrung) {
        case KonditionsfeldWaehrung.Waehrung: {
          return c.IsProzent == false;
        }
        case KonditionsfeldWaehrung.Prozent: {
          return c.IsProzent == true;
        }
        default: {
          return true;
        }
      }
    });
  }
  reload() {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Stammdaten/Waehrung"),
      getOptions: { customs: [{ "key": "Waehrung", "value": 2 }] }
    }).then(r => {
      this._waehrungList = r;
    });
  }

  private initializeSubscribers() {
    this.eventAggregator.subscribe(
      NotificationTyp.waehrungChanged,
      this.reload.bind(this));
  }
}
