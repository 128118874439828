import {
  autoinject,
  OverrideContext,
  Scope,
  PLATFORM
} from "aurelia-framework";
import {
  FileService,
  ScopeContainer,
  GlobalizationService
} from "../../../framework/base/export";
import {
  HistoryService,
  ICommandData,
  SimpleWidgetCreatorService
} from "../../../framework/forms/export";
import {
  OnBriefErstellen,
  OnBriefErstellenEvent
} from "../../../framework-data/events";

import {
  DruckenService,
  FormCommandService,
  BriefService,
  StartupService
} from "../../services/export";
import * as Interfaces from "../../interfaces/export";
import { IFormCommandExecuteOptions } from '../../interfaces/export';
import { bindable, TemplatingEngine } from 'aurelia-templating';
import { AnzAusdrucke } from '../anz-ausdrucke/anz-ausdrucke';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NotificationTyp } from '../../enumerations/notification-typ';

@autoinject
export class DruckenCommand {
  private _detachedCommand: { (): void };
  constructor(
    private briefService: BriefService,
    private drucken: DruckenService,
    private element: Element,
    private file: FileService,
    private formCommand: FormCommandService,
    private history: HistoryService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private templating: TemplatingEngine,
    private globalizationService: GlobalizationService,
    private startupService: StartupService,
    private eventAggregator: EventAggregator
  ) { }

  dmsLink: string;
  idVerknuepfteEntitaet: number;
  @bindable showInline: boolean;
  anzAusdrucke: AnzAusdrucke;
  anzAusdruckeVorb: number;

  druckenPopupCommands: ICommandData[] = [
    {
      id: "downloadDocument",
      title: "dms-viewer.download-dokument",
      icon: "download",
      isEnabledExpression: "dmsLink",
      execute: this.downloadCurrentDokument.bind(this)
    }, {
      id: "sendDocument",
      title: "dms-viewer.send-dokument",
      icon: "share-alt",
      isEnabledExpression: "dmsLink",
      execute: this.onSendDokumentClick.bind(this)
    }, {
      id: "anzAusdrucke",
      title: "dms-viewer.anzahl-ausdrucke",
      isVisible: this.isDruckJobAktiv(),
      template: {
        moduleId: PLATFORM.moduleName("erp/elements/anz-ausdrucke/anz-ausdrucke"),
        options: {
          options: {
            onInitialized: (e) => {
              this.anzAusdrucke = e.component;
              e.options.value = this.anzAusdruckeVorb;
            }
          }
        }
      }
    }, {
      id: "sendDruckJob",
      title: "dms-viewer.send-druck-job",
      icon: "print",
      isEnabledExpression: "dmsLink",
      isVisible: this.isDruckJobAktiv(),
      execute: this.onSendDruckJob.bind(this)
    }
  ];
  druckenPopup: Interfaces.IdxPopupComponent;
  druckenPopupOptions: DevExpress.ui.dxPopupOptions = {};

  druckenContextMenu: Interfaces.IdxContextMenuComponent;
  druckenContextMenuOptions: DevExpress.ui.dxContextMenuOptions = {
    target: "#NONSENSE",
    visible: false,
    displayExpr: "Bezeichnung",
    position: { my: "top", at: "bottom" },
    onItemClick: (e) => {
      this.drucken.getReport(this.idVerknuepfteEntitaet, e.itemData.Id)
        .then(r => {
          this.showPopup(r.DMSLink, r.IdObjekt);
        });
    }
  }

  scope: Scope;
  scopeContainer: ScopeContainer;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.drucken.druckenCommand = this;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "druckenPopupToolbar",
      caption: "dms-viewer.popup-titel",
      options: this.druckenPopupOptions,
      commands: this.druckenPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.eventAggregator.subscribe(NotificationTyp.startupInfoLoaded, async () => {
      this.druckenPopupCommands.forEach(cmd => {
        if (cmd.id == "anzAusdrucke" || cmd.id == "sendDruckJob") {
          cmd.isVisible = this.isDruckJobAktiv();
        }
      });
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }
  attached() {
    const druckCommand: ICommandData =
      {
        id: "doDokumentDrucken",
        title: "drucken-command.command-text",
        icon: "print",
        isEnabled: false,
        sortIndex: 500
      };
    this._detachedCommand = this.formCommand.attachFormCommand(
      druckCommand,
      {
        isCommandVisibleModelProperty: "HasAusdruck",
        saveModelOnExecute: true,
        execute: this.drucken.executeDruckCommand.bind(this.drucken)
      });
  }
  detached() {
    this._detachedCommand();
  }

  async showPopup(dmsLink: string, idVerknuepfteEntitaet: number = null, empfaengerList?: string[]) {
    this.dmsLink = dmsLink;
    this.idVerknuepfteEntitaet = idVerknuepfteEntitaet;

    this.anzAusdruckeVorb = await this.drucken.getAnzAusdrucke(idVerknuepfteEntitaet);
    if (this.anzAusdrucke) {
      this.anzAusdrucke.setValue(this.anzAusdruckeVorb);
    }

    this.druckenPopup.instance.show();
  }
  showContextMenu(idVerknuepfteEntitaet: number, dokumentList: any[], e: IFormCommandExecuteOptions) {
    this.idVerknuepfteEntitaet = idVerknuepfteEntitaet;

    this.druckenContextMenu.instance.option("dataSource", dokumentList);
    this.druckenContextMenu.instance.option("target", this.formCommand.getCommandTarget(e.options.event))
    this.druckenContextMenu.instance.show();
  }

  private isDruckJobAktiv() {
    return this.startupService.startupInfo.Mandant.IsDruckJobAktiv;
  }

  private downloadCurrentDokument() {
    const url = this.file.getDownloadUrl(this.dmsLink);
    window.open(url, "_blank");
  }
  private async onSendDokumentClick() {
    await this.briefService.createAndGoToBrief(this.idVerknuepfteEntitaet, [this.dmsLink], true);
    this.druckenPopup.instance.hide();
  }
  private async onSendDruckJob() {
    await this.drucken.executeDruckJob(this.dmsLink, this.anzAusdrucke.getValue());

    this.druckenPopup.instance.hide();
  }
}
