import { WebEventService } from "./../../../../framework/base/services/web-event-service";
import { OnErstellenIntrastatSync, OnErstellenIntrastatSyncEvent } from "./../../../../framework-data/events";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";
import { FormBase } from "./../../../../framework/forms/classes/form-base";
import { autoinject, transient } from "aurelia-framework";
import { ICommandData } from "../../../../framework/forms/export";
import { IdxDateBoxComponent, IdxPopupComponent, IdxSelectBoxComponent } from "../../../interfaces/dx-components";
import * as moment from "moment";

@autoinject
@transient()
export class IntrastatSyncListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _localizationService: LocalizationService,
    private _webEventService: WebEventService) { }

  form: FormBase;

  showSynchronsierenPopupCommand: ICommandData = {
    id: "showSynchronsierenPopup",
    idCategory: "fibu-sync-job",
    title: "intrastat-sync.do_synchronisieren",
    sortIndex: 10,
    icon: "refresh",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_intrastatSyncPopup"];

      popup.instance.show();
      const selectBox: IdxSelectBoxComponent = this.form["r_firma"];
      if (selectBox) {
        const dataSource = selectBox.instance.getDataSource();
        await dataSource.reload();

        if (dataSource.items().length > 0) {
          selectBox.setOption({
            value: dataSource.items()[0].Id
          });
        }
      }

      const selectBoxTyp: IdxSelectBoxComponent = this.form["r_intrastatSyncTyp"];
      if (selectBoxTyp) {
        const dataSource = selectBoxTyp.instance.getDataSource();
        dataSource.reload();

        selectBoxTyp.setOption({
          value: 0
        });
      }
      const dateVonBox: IdxDateBoxComponent = this.form["r_datumVon"];
      dateVonBox.setOption({
        value: moment()
          .subtract(1, "month")
          .startOf("month")
          .toDate()
      });

      const dateBisBox: IdxDateBoxComponent = this.form["r_datumBis"];
      dateBisBox.setOption({
        value: moment()
          .subtract(1, "month")
          .endOf("month")
          .startOf("day")
          .toDate()
      });
    }
  };

  doSynchronisierenCommand: ICommandData = {
    id: "doSynchronisieren",
    title: "intrastat-sync.do_synchronisieren",
    icon: "refresh",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_intrastatSyncPopup"];
      const dateBoxVon: IdxDateBoxComponent = this.form["r_datumVon"];
      const dateBoxBis: IdxDateBoxComponent = this.form["r_datumBis"];
      const firmaSelectBox: IdxSelectBoxComponent = this.form["r_firma"];

      const idFirma = firmaSelectBox.instance.option("value");
      if (!idFirma) {
        DevExpress.ui.notify(this._localizationService.translateOnce("fibu-sync-job.firma_auswaehlen"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      const selectBoxTyp: IdxSelectBoxComponent = this.form["r_intrastatSyncTyp"];

      const webEvent = new OnErstellenIntrastatSyncEvent({
        Typ: selectBoxTyp.instance.option("value"),
        DatumVon: dateBoxVon.instance.option("value"),
        DatumBis: dateBoxBis.instance.option("value"),
        IdFirma: idFirma
      });

      const webEventResult: OnErstellenIntrastatSync = await this._webEventService.execute(webEvent, true);
      this.form.models.reloadAll();
      popup.instance.hide();
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
