import * as fwx from "../../../../framework/forms/form-export";
import {
    ZahlungsverkehrListUtils
} from "./zahlungsverkehr-list-utils";

@fwx.autoinject
export class ZahlungsverkehrListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ZahlungsverkehrListUtils: ZahlungsverkehrListUtils) {
        super(element, formBaseImport);
        this.id = "zahlungsverkehr-list";
        this.title = "zahlungsverkehr-list.zahlungsverkehr-list_caption";
        this.addModel({
            "id": "$m_Zahlungsverkehr",
            "webApiAction": "ERP/Zahlungsverkehr/Zahlungsverkehr",
            "webApiExpand": {
                'FirmaZahlungsverkehr': {
                    'expand': {
                        'FirmaBankverbindung': null
                    }
                },
                'Waehrung': null,
                'Geschaeftspartner': {
                    'expand': {
                        'Adresse': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ZahlungsverkehrListUtils.showDokumentErstellenPopupCommand",
                "bindToFQ": "functions.$f_ZahlungsverkehrListUtils.showDokumentErstellenPopupCommand"
            }
        });
        this.addFunction("$f_ZahlungsverkehrListUtils", $f_ZahlungsverkehrListUtils, "functions.$f_ZahlungsverkehrListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_zahlvFirmaZahlungsverkehr",
                "caption": "zahlungsverkehr-list.r_zahlvfirmazahlungsverkehr_caption",
                "bindTo": "FirmaZahlungsverkehr.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_zahlvTyp",
                "caption": "zahlungsverkehr-list.r_zahlvtyp_caption",
                "bindTo": "Typ",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.ZahlungsverkehrTyp"
            }, {
                "id": "r_zahlvGpNummer",
                "caption": "zahlungsverkehr-list.r_zahlvgpnummer_caption",
                "bindTo": "Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_zahlvGpBez",
                "caption": "zahlungsverkehr-list.r_zahlvgpbez_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_zahlvRechnungNr",
                "caption": "zahlungsverkehr-list.r_zahlvrechnungnr_caption",
                "bindTo": "RechnungNr",
                "width": "120px"
            }, {
                "id": "r_zahlvRechnungDatum",
                "caption": "zahlungsverkehr-list.r_zahlvrechnungdatum_caption",
                "bindTo": "RechnungDatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_zahlvValutaDatum",
                "caption": "zahlungsverkehr-list.r_zahlvvalutadatum_caption",
                "bindTo": "ValutaDatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_zahlvZahlbetrag",
                "caption": "zahlungsverkehr-list.r_zahlvzahlbetrag_caption",
                "bindTo": "Zahlbetrag",
                "width": "90px",
                "format": "n2"
            }, {
                "id": "r_zahlvWaehrung",
                "caption": "zahlungsverkehr-list.r_zahlvwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }],
            "optionsToolbar": {
                "optionsName": "r_zahlungsverkehrGridToolbarOptions",
                "optionsNameFQ": "r_zahlungsverkehrGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Zahlungsverkehr"
            },
            "dataModel": "$m_Zahlungsverkehr",
            "editUrl": "ERP/Zahlungsverkehr/Zahlungsverkehr",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_zahlungsverkehrGrid",
            "options": {
                "optionsName": "r_zahlungsverkehrGridOptions",
                "optionsNameFQ": "r_zahlungsverkehrGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_zahlungsverkehrDokumentErstellenPopup",
            "options": {
                "optionsName": "r_zahlungsverkehrDokumentErstellenPopupOptions",
                "optionsNameFQ": "r_zahlungsverkehrDokumentErstellenPopupOptions"
            },
            "caption": "zahlungsverkehr-list.r_zahlungsverkehrdokumenterstellenpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_ZahlungsverkehrListUtils.doDokumentErstellenCommand",
                    "bindToFQ": "functions.$f_ZahlungsverkehrListUtils.doDokumentErstellenCommand"
                }
            }]
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "zahlungsverkehr-list.r_wertbehalten_caption",
            "binding": {},
            "validationRules": [],
            "onValueChanged": "functions.$f_ZahlungsverkehrListUtils.onValutaDatumBeibehaltenChanged",
            "id": "r_wertBehalten",
            "options": {
                "optionsName": "r_wertBehaltenOptions",
                "optionsNameFQ": "r_wertBehaltenOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "zahlungsverkehr-list.r_wertaendern_caption",
            "binding": {},
            "validationRules": [],
            "onValueChanged": "functions.$f_ZahlungsverkehrListUtils.onValutaDatumAendernChanged",
            "id": "r_wertAendern",
            "options": {
                "optionsName": "r_wertAendernOptions",
                "optionsNameFQ": "r_wertAendernOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlungsverkehr-list.r_datum_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        super.onConstructionFinished();
    }
}