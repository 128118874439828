import {
  autoinject,
  bindable,
  customAttribute
} from "aurelia-framework";

@autoinject
@customAttribute("animation")
export class AnimationCustomAttribute {
  constructor(
    private element: Element
  ) { }

  valueChanged(newVal, oldVal) {
    if (newVal == oldVal){
      return;
    }
    this.doAnimation();
  }

  private doAnimation() {
    const isAnimating = this.element.classList.contains("animating");
    if (isAnimating === false) {
      this.element.classList.add("animating");
    }
    const animatingElementsCount = document.getElementsByClassName("animating").length;
    const delay = 100 * animatingElementsCount;

    setTimeout(() => {
      this.element.classList.remove("animating");
    }, 150 + delay)
  }
}
