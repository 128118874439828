import { autoinject } from "aurelia-framework";
import { ElementService } from "./element-service";
import { IViewModel } from '../interfaces/view-model';
import { ExcelExportService } from "../../forms/services/excel-export-service";

@autoinject
export class DataGridService {
  constructor(
    private _elementService: ElementService,
    private _excelExportService: ExcelExportService
  ) { }

  createOptions(viewModel: IViewModel, idDataSource: string, options: DevExpress.ui.dxDataGridOptions) {
    const result = Object.assign({
    }, options, <DevExpress.ui.dxDataGridOptions>{
        dataSource: [],
        focusedRowEnabled: true,
        keyExpr: "_id",
        readOnly: true,
        onContextMenuPreparing: (e) => {
          e.items = e.items || [];

          e.items.push({
            beginGroup: e.items.length > 0,
            text: "Daten neu laden",
            onClick: () => {
              viewModel.report.loadDataSource(idDataSource);
            }
          });

          e.items.push({
            beginGroup: true,
            text: "Export",
            onClick: () => {
              this._excelExportService.exportDataGrid({
                component: e.component,
                selectedRowsOnly: false,
                exporting: options["excelExporting"],
                customizeCell: options["excelCustomizeCell"],
                saving: options["excelSaving"]
              });
            }
          });
        },
        onOptionChanged: (e) => {
          if (e.name == "focusedRowKey") {
            if (e.value) {
              const item = viewModel.dataSource[idDataSource].find(c => c._id == e.value);
              viewModel.fireDataSourceSelectionChanged(idDataSource, item ? [item]: []);
            } else {
              viewModel.fireDataSourceSelectionChanged(idDataSource, []);
            }
          }
        }
      });

    this._elementService.prepareContentReady(result);
    this._elementService.addOnInitialized(result, (e) => {
      viewModel.registerDataSourceChanged(idDataSource, (idDataSource, data) => {
        e.component.option({
          focusedRowKey: undefined,
          focusedRowIndex: -1,
          dataSource: data
        });

        this._elementService.addToContentReady(e.component, () => {
          if (data.length > 0) {
            const key = e.component.getKeyByRowIndex(0);
            e.component.option("focusedRowKey", key);
          };
        });
      }, true);

      const data = viewModel.dataSource[idDataSource] || [];
      e.component.option({
        focusedRowKey: undefined,
        dataSource: data
      });

      this._elementService.addToContentReady(e.component, () => {
        if (data.length > 0) {
          const key = e.component.getKeyByRowIndex(0);
          e.component.option("focusedRowKey", key);
        }
      });
    });

    return result;
  }
}