import { autoinject } from "aurelia-framework";
import { RestService, LocalizationService } from '../../../framework/base/export';

@autoinject
export class VersionCheck {
  private _version: string;
  private _timeoutId: any;

  constructor(
    private restService: RestService,
    private localizationService: LocalizationService
  ) { }

  showVersionReload: boolean = false;

  reloadButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("version_check.reload"),
    onClick: () => {
      location.reload(true);
    }
  }

  bind() {
    this.checkVersion();
  }
  unbind() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = null;
    }
  }

  async checkVersion() {
    if (location.port) {
      return;
    }

    const r = await this.restService.get({
      url: this.restService.getUrl("version.json?random=" + new Date().getTime().toString()),
      errorCallback: (response) => {
        return true;
      }
    });

    if (r && r.v) {
      if (!this._version) {
        this._version = r.v;
      } else if (this._version != r.v) {
        this.showVersionReload = true;
      }
    }
    
    if (!this.showVersionReload) {
      this._timeoutId = setTimeout(() => {
        this.checkVersion();
      }, 60000);
    }
  }
}
