import { TicketKategorie } from './../enumerations/ticket-kategorie';
import {
  autoinject,
  valueConverter
} from "aurelia-framework";

@autoinject
@valueConverter("ticketKategorieName")
export class TicketKategorieNameValueConverter {
  constructor(
  ) { }

  toView(value: any) {
    if (value == null) {
      return "";
    }

    let ticketKategorie = value;

    switch (ticketKategorie) {
      case TicketKategorie.Dashboard:
        value = "Dashboard";
        break;
      case TicketKategorie.Stammdaten:
        value = "Stammdaten";
        break;
      case TicketKategorie.Einkauf:
        value = "Einkauf";
        break;
      case TicketKategorie.Verkauf:
        value = "Verkauf";
        break;
      case TicketKategorie.Produktion:
        value = "Produktion";
        break;
      case TicketKategorie.Lager:
        value = "Lager";
        break;
      case TicketKategorie.Statistik:
        value = "Statistik";
        break;
      case TicketKategorie.Konfiguration:
        value = "Konfiguration";
        break;
      case TicketKategorie.Andere:
        value = "Andere";
        break;
    }

    return value;
  }
}
