import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    BestellungListUtils
} from "./bestellung-list-utils";

@fwx.autoinject
export class BestellungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_BestellungListUtils: BestellungListUtils) {
        super(element, formBaseImport);
        this.id = "bestellung-list";
        this.title = "bestellung-list.bestellung-list_caption";
        this.addModel({
            "id": "$m_Bestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null,
                'Lieferant': null,
                'LieferantAdresse': {
                    'expand': {
                        'Land': null
                    }
                },
                'Auftrag': {
                    'columns': [
                        'Id',
                        'Nummer'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_bestellungGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungListUtils.doExport",
                "bindToFQ": "functions.$f_BestellungListUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungListUtils.bestaetigenSetzenCommand",
                "bindToFQ": "functions.$f_BestellungListUtils.bestaetigenSetzenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungListUtils.bestaetigenEntfernenCommand",
                "bindToFQ": "functions.$f_BestellungListUtils.bestaetigenEntfernenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungListUtils.lieferdatumAendernCommand",
                "bindToFQ": "functions.$f_BestellungListUtils.lieferdatumAendernCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_BestellungListUtils", $f_BestellungListUtils, "functions.$f_BestellungListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_besNummer",
                "caption": "bestellung-list.r_besnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_besDatum",
                "caption": "bestellung-list.r_besdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besProjekt",
                "caption": "bestellung-list.r_besprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_besAuftragNr",
                "caption": "bestellung-list.r_besauftragnr_caption",
                "bindTo": "Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_besLieferdatum",
                "caption": "bestellung-list.r_beslieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_BestellungListUtils.onLieferdatumCellPrepared"
            }, {
                "id": "r_besLieferantNummer",
                "caption": "bestellung-list.r_beslieferantnummer_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "80px"
            }, {
                "id": "r_besFirmenbez1",
                "caption": "bestellung-list.r_besfirmenbez1_caption",
                "bindTo": "LieferantAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_besStrasse",
                "caption": "bestellung-list.r_besstrasse_caption",
                "bindTo": "LieferantAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_besLand",
                "caption": "bestellung-list.r_besland_caption",
                "bindTo": "LieferantAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_besOrt",
                "caption": "bestellung-list.r_besort_caption",
                "bindTo": "LieferantAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_besWertNetto",
                "caption": "bestellung-list.r_beswertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_besWaehrung",
                "caption": "bestellung-list.r_beswaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_besStatus",
                "caption": "bestellung-list.r_besstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellungGridToolbarOptions",
                "optionsNameFQ": "r_bestellungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bestellung"
            },
            "dataModel": "$m_Bestellung",
            "editUrl": "ERP/Einkauf/Bestellung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellungGrid",
            "options": {
                "optionsName": "r_bestellungGridOptions",
                "optionsNameFQ": "r_bestellungGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bestellungLieferdatumAendernPopup",
            "options": {
                "optionsName": "r_bestellungLieferdatumAendernPopupOptions",
                "optionsNameFQ": "r_bestellungLieferdatumAendernPopupOptions"
            },
            "caption": "bestellung-list.r_bestellunglieferdatumaendernpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_BestellungListUtils.doLieferdatumAendernCommand",
                    "bindToFQ": "functions.$f_BestellungListUtils.doLieferdatumAendernCommand"
                }
            }]
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellung-list.r_datum_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        super.onConstructionFinished();
    }
}