import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  FileService,
  WebEventService
} from "../../../../framework/base/export";
import {
  OnImportFreigebenEvent,
  OnImportCreateVorlageEvent,
  OnImportCreateVorlage
} from "../../../../framework-data/events";
import {
  StatusService
} from "../../../services/export";

@autoinject
@transient()
export class ImportEditUtils {
  constructor(
    private webEvent: WebEventService,
    private file: FileService,
    private status: StatusService
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "models.data.$m_Import.CanSave",
      callback: () => {
        const data: any = this.form.models.data.$m_Import;
        this.doFreigebenCommand.isEnabled = data && data.CanSave;
      }
    });

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "models.data.$m_Import.Id",
      callback: () => {
        const data: any = this.form.models.data.$m_Import;
        this.doCreateVorlageCommand.isEnabled = data
          && data.Id;
      }
    });
  }

  doFreigebenCommand: ICommandData = {
    id: "doFreigeben",
    idCategory: "import",
    title: "import-edit-utils.do-freigeben",
    icon: "check",
    isEnabled: false,
    execute: () => {
      const data: any = this.form.models.data.$m_Import;
      if (data && data.CanSave) {
        this.form.save()
          .then((r) => {
            if (!r.isValid) {
              return;
            }

            return this.webEvent
              .execute(new OnImportFreigebenEvent({
                IdImport: this.form.models.data.$m_Import.Id
              }), true)
              .then(() => {
                return this.form.models.reloadAll();
              });
          });
      }
    }
  }
  doCreateVorlageCommand: ICommandData = {
    id: "doCreateVorlage",
    idCategory: "import",
    title: "import-edit-utils.do-create-vorlage",
    icon: "download",
    isEnabled: false,
    execute: () => {
      return this.webEvent
        .execute(new OnImportCreateVorlageEvent({
          IdImport: this.form.models.data.$m_Import.Id,
          DMSLink: null
        }), true)
        .then((r: OnImportCreateVorlage) => {
          if (!r.DMSLink) {
            return;
          }

          const url = this.file.download(r.DMSLink);
        });
    }
  }
}
