import * as fwx from "../../../../framework/forms/form-export";
import {
    KonditionListNestedUtils
} from "./kondition-list-nested-utils";

@fwx.autoinject
export class KonditionListNestedForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_konditionList: KonditionListNestedUtils) {
        super(element, formBaseImport);
        this.id = "kondition-list-nested";
        this.title = "kondition-list-nested.kondition-list-nested_caption";
        this.addModel({
            "id": "$m_Kondition",
            "webApiAction": "ERP/Kalkulation/Kondition",
            "webApiExpand": {
                'Konditionsfeld': null,
                'GeschaeftspartnerInfo': null,
                'ArtikelInfo': null,
                'Filiale': null,
                'Lager': null,
                'Waehrung': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": [{
                "webApiCustomKey": "InklIntern",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "KonditionIdArtikel",
                "webApiCustomValue": "variables.data.$v_idArtikel"
            }, {
                "webApiCustomKey": "KonditionIdGeschaeftspartner",
                "webApiCustomValue": "variables.data.$v_idGeschaeftspartner"
            }, {
                "webApiCustomKey": "Waehrung",
                "webApiCustomValue": "2"
            }, {
                "webApiCustomKey": "AddGeschaeftspartnerInfoCboBezeichnung",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "AddArtikelInfoCboBezeichnung",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_KonditionE",
            "webApiAction": "ERP/Kalkulation/Kondition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addEditPopup({
            "idContent": "konditionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_KonditionE",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_KonditionE.Id"
                }
            }, {
                "to": "$v_idArtikel",
                "binding": {
                    "bindTo": "$v_idArtikel",
                    "bindToFQ": "variables.data.$v_idArtikel"
                }
            }, {
                "to": "$v_idGeschaeftspartner",
                "binding": {
                    "bindTo": "$v_idGeschaeftspartner",
                    "bindToFQ": "variables.data.$v_idGeschaeftspartner"
                }
            }],
            "id": "konditionEditPopup",
            "options": {
                "optionsName": "konditionEditPopupOptions",
                "optionsNameFQ": "konditionEditPopupOptions"
            },
            "caption": "kondition-list-nested.konditioneditpopup_caption",
            "height": "600px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_konditionList", $f_konditionList, "functions.$f_konditionList");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_konKonditionsfeld",
                "caption": "kondition-list-nested.r_konkonditionsfeld_caption",
                "bindTo": "Konditionsfeld.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konFiliale",
                "caption": "kondition-list-nested.r_konfiliale_caption",
                "bindTo": "Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konLager",
                "caption": "kondition-list-nested.r_konlager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konGeschaeftspartnerInfo",
                "caption": "kondition-list-nested.r_kongeschaeftspartnerinfo_caption",
                "bindTo": "_GeschaeftspartnerInfoCboBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konArtikelInfo",
                "caption": "kondition-list-nested.r_konartikelinfo_caption",
                "bindTo": "_ArtikelInfoCboBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_konWert",
                "caption": "kondition-list-nested.r_konwert_caption",
                "bindTo": "Wert",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_konWaehrung",
                "caption": "kondition-list-nested.r_konwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_konWertPer",
                "caption": "kondition-list-nested.r_konwertper_caption",
                "bindTo": "WertPer",
                "width": "60px",
                "format": "n0"
            }, {
                "id": "r_konStaffel",
                "caption": "kondition-list-nested.r_konstaffel_caption",
                "bindTo": "Staffel",
                "width": "70px",
                "format": "n0"
            }, {
                "id": "r_konGueltigVon",
                "caption": "kondition-list-nested.r_kongueltigvon_caption",
                "bindTo": "GueltigVon",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_konGueltigBis",
                "caption": "kondition-list-nested.r_kongueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_konPrioritaet",
                "caption": "kondition-list-nested.r_konprioritaet_caption",
                "bindTo": "Prioritaet",
                "width": "50px"
            }, {
                "id": "r_konBemerkung",
                "caption": "kondition-list-nested.r_konbemerkung_caption",
                "bindTo": "Bemerkung",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_konditionGridToolbarOptions",
                "optionsNameFQ": "r_konditionGridToolbarOptions"
            },
            "caption": "kondition-list-nested.r_konditiongrid_caption",
            "binding": {
                "dataContext": "$m_Kondition"
            },
            "dataModel": "$m_Kondition",
            "editDataContext": "$m_KonditionE",
            "idEditPopup": "konditionEditPopup",
            "height": "auto",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_konditionGrid",
            "options": {
                "optionsName": "r_konditionGridOptions",
                "optionsNameFQ": "r_konditionGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}