import { autoinject, observable, bindable, OverrideContext } from "aurelia-framework";
import { ScopeContainer, DataSourceService, RestService } from '../../../../framework/base/export';
import { LocationService } from '../../../../framework/base/services/location-service';
import { EntitaetInfoService } from '../../../services/export';
import { ListView } from '../../../../framework/forms/elements/export';
import { IListViewOptions } from '../../../../framework/forms/elements/list-view/export';
import { PopupInfoService } from '../../../../framework/forms/export';

@autoinject
export class Projekte {
  constructor(
    private entitaetInfoService: EntitaetInfoService,
    private locationService: LocationService,
    private popupInfoService: PopupInfoService,
    private dataSourceService: DataSourceService,
    private restService: RestService
  ) {}

  @bindable @observable idGeschaeftspartner: number;

  isVisible: boolean = false;
  scopeContainer: ScopeContainer;

  projekteListView: ListView;
  projekteListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.popupInfoService.closeAllPopups().then(async pr => {
        if (!pr) {
          return;
        }

        const viewScrollInfo = await this.getViewScrollInfo(e, "TIP.ERP.Business.Entitaeten.Stammdaten.Projekt");

        this.locationService.goTo({
          url: "ERP/Stammdaten/Projekt/".concat(e.item.Id),
          viewScrollInfo: viewScrollInfo
        });
      });
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });

    this.projekteListViewOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Projekt",
        webApiColumns: ["Id", "Nummer", "Bezeichnung", "IdStatus"],
        filters: [{ webApiCustomKey: "InklIdStatusFarbe", webApiCustomValue: "true" }],
        webApiOrderBy: [{ columnName: "Status.Typ", sortOrder: 0 }, { columnName: "AnlageDatum", sortOrder: 1 }]
      }, {
        canLoad: () => {
          return !!this.idGeschaeftspartner;
        },
        getCustomFilters: () => {
          return [{webApiCustomKey: "IdGeschaeftspartner", webApiCustomValue: <any>this.idGeschaeftspartner}];
        },
        resultInterceptor: (data) => {
          this.isVisible = data.count > 0;
          return data;
        }
      });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idGeschaeftspartnerChanged() {
    if (this.projekteListView) {
      this.projekteListView.refresh(false);
    }
  }
  
  private async getViewScrollInfo(e, typeName): Promise<any> {
    const id = e.item.Id;
    const orderBy = [{ columnName: "Status.Typ", sortOrder: 0 }, { columnName: "AnlageDatum", sortOrder: 1 }];
    const customs = [{ key: "IdGeschaeftspartner", value: <any>this.idGeschaeftspartner}];

    const viewScrollInfo = await this.entitaetInfoService.createViewScrollInfo(
      typeName,
      null,
      customs,
      orderBy,
      id
    );

    return viewScrollInfo;
  }
}
