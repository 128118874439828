import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class MailImportItemAnhangEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "mail-import-item-anhang-edit";
        this.title = "mail-import-item-anhang-edit.mail-import-item-anhang-edit_caption";
        this.addModel({
            "id": "$m_MailImportItemAnhang",
            "webApiAction": "ERP/Stammdaten/MailImportItemAnhang",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_MailImportItem",
            "webApiAction": "ERP/Stammdaten/MailImportItem",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_MailImportItemAnhang.IdMailImportItem",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "mail-import-item-anhang-edit.r_bezeichnung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_MailImportItemAnhang",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_MailImportItemAnhang.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "dokumentenart",
            "filters": [{
                "webApiCustomKey": "IsMailImport",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "IsMailImport",
                "webApiCustomValue": "true"
            }],
            "caption": "mail-import-item-anhang-edit.r_dokumentenart_caption",
            "binding": {
                "dataContext": "$m_MailImportItemAnhang",
                "bindTo": "IdDokumentenart",
                "bindToFQ": "models.data.$m_MailImportItemAnhang.IdDokumentenart"
            },
            "validationRules": [],
            "id": "r_dokumentenart",
            "options": {
                "optionsName": "r_dokumentenartOptions",
                "optionsNameFQ": "r_dokumentenartOptions"
            }
        });
        super.onConstructionFinished();
    }
}