import { autoinject } from 'aurelia-framework';
import { RestService } from '../../../../framework/base/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';

@autoinject
export class OffeneAuftraegeSettings {
  constructor(
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IOffeneAuftraegeSettings;
  firmaDataSource: any[];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  };

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.loadFirmaDataSource();
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      },
      increaseLoadingCount: true
    });
  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }

    this.widgetViewModel.loadData();
  }
}
export interface IOffeneAuftraegeSettings {
  idFirma?: number;
}
