import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase, ICommandData
} from "../../../../framework/forms/export";
import { IdxDataGridComponent } from '../../../interfaces/export';
import { LocalizationService } from '../../../../framework/base/services/localization-service';
import { RestService } from '../../../../framework/base/services/rest-service';
import { LoadingService } from '../../../../framework/default-ui/services/loading-service';
import { LocationService } from '../../../../framework/base/services/location-service';

@autoinject
@transient()
export class NewsletterEmpfaengerEditUtils {

  constructor(
    private localization: LocalizationService,
    private rest: RestService,
    private loadingService: LoadingService,
    private locationService: LocationService
  ) { }

  form: FormBase;

  deleteSelectedCommand: ICommandData = {
    id: "deleteSelectedCommand",
    title: "newsletter-empfaenger.markierte_loeschen",
    icon: "times",
    execute: async () => {
      const keys = this.getEmpfaengerGrid().instance.getSelectedRowKeys();
      if (keys.length == 0) {
        DevExpress.ui.notify(
          this.localization.translateOnce("newsletter-empfaenger.no_empfaenger_markiert_zum_loeschen"),
          "error",
          3000
        );
        return;
      }

      try {
        this.loadingService.beginLoading();

        for (let key of keys) {
          await this.rest.delete({
            url: this.rest.getWebApiUrl("ERP/Stammdaten/NewsletterEmpfaengerElement"),
            id: key
          });
        }

        this.refreshPersonGrid();        
      } finally {
        this.loadingService.endLoading();
      }
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.onReactivated.register(e => {
      this.refreshPersonGrid();
      return Promise.resolve();
    })
  }

  onPersonClick(event) {
    const idPerson = event.data.IdPerson;
    if (!idPerson) {
      return;
    }

    this.locationService.goTo({
      url: `ERP/Stammdaten/Person/${idPerson}`,
      currentViewModel: this.form
    });
  }

  private getEmpfaengerGrid(): IdxDataGridComponent {
    return this.form["r_ElementGrid"];
  }
  private refreshPersonGrid() {
    this.getEmpfaengerGrid().instance.refresh();
  }
}
