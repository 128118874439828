import { autoinject, transient } from "aurelia-framework";
import * as moment from "moment";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent } from "../../../interfaces/dx-components";
import { AsyncService } from "./../../../../framework/base/services/async-service";
import { BelegExportService } from "./../../../services/beleg-export-service";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class EingangsrechnungListUtils {
  constructor(
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService
  ) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_EingangsrechnungListUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_EingangsrechnungListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_eingangsrechnungGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Eingangsrechnung, keys);
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Eingangsrechnung);
      return Promise.resolve();
    });
  }

  onZahlungFaelligCellPrepared(e): void {
    if (e.rowType != "data" || e.data.BezahltTyp == void (0)) {
      return;
    }
    if (e.data.BezahltTyp != 0) {
      return;
    }
    const val: Date = e.value;
    if (val == void (0)) {
      return;
    }

    const diff = moment(val)
      .diff(new Date(), "days");

    const cell: HTMLTableCellElement = e.cellElement;
    cell.style.color = "white";
    if (val.getTime() <= new Date().getTime()) {
      cell.style.backgroundColor = "#C1392D";
    } else if (diff <= 5) {
      cell.style.backgroundColor = "#E67E22";
    } else {
      cell.style.backgroundColor = "#2DCC70";
    }
  }
}
