import { ValidationExService } from "./../../services/validation-ex-service";
import { autoinject } from "aurelia-framework";
import { HistoryService } from "./../../../framework/forms/services/history-service";
import { WebEventService } from "./../../../framework/base/services/web-event-service";
import { OnKopierenRequestEvent, OnKopierenRequest } from "../../../framework-data/events";
import { ICommandData, FormBase, IModel } from "../../../framework/forms/export";
import { FormCommandService } from "../../services/export";
import { LocalizationService } from "../../../framework/base/export";

@autoinject
export class KopierenFormCommand {
  private NOTIFY_TIMEOUT: number = 3000;
  private _availableForms: FormBase[] = [];
  private _detachedCommand: { (): void };

  constructor(
    private _formCommandService: FormCommandService,
    private _historyService: HistoryService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  kopierenFormCommand: ICommandData = {
    id: "kopierenFormCommand",
    idCategory: "$datensatz",
    title: "form-command.kopieren-command-icon-text",
    icon: "clone",
    isEnabled: false,
    badgeText: null,
    sortIndex: 9100
  };

  attached() {
    this._detachedCommand = this._formCommandService.attachFormCommand(
      this.kopierenFormCommand,
      {
        isCommandVisibleModelProperty: "CanKopieren",
        execute: (e) => {
          this.copyObjekt(e.form, e.modelData.Id);
        },
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.deleteAvailableForm.bind(this)
      });
  }
  detached() {
    this._detachedCommand();
  }

  private async copyObjekt(form: FormBase, idSourceObjekt: number) {
    if (idSourceObjekt == void 0) {
      return;
    }

    const mainModel = form.models.getModelWithKeyId();

    if (mainModel.custom == void 0
      || mainModel.custom.copyFunc == void 0) {
      this.doCopy(form, idSourceObjekt);

      return;
    }

    const customCopyFunc = this.getCustomCopyFunc(mainModel, form);
    customCopyFunc((customData): Promise<any> => {
      return this.doCopy(form, idSourceObjekt, customData);
    });
  }

  private getCustomCopyFunc(mainModel: IModel, currentForm: FormBase): Function {
    const customOptions = mainModel.custom.copyFunc.split(".");
    if (!customOptions || !customOptions[0] || !customOptions[1]) {
      return;
    }
    const funcNamespace: string = customOptions[0];
    let copyFuncName: string = customOptions[1];

    const funcNameIndex = copyFuncName.indexOf("(");
    if (funcNameIndex != -1) {
      copyFuncName = copyFuncName.substring(0, funcNameIndex);
    }

    return currentForm[funcNamespace][copyFuncName].bind(currentForm[funcNamespace]);
  }

  private async doCopy(form: FormBase, idSourceObjekt: number, customData?: any) {
    const mainModel = form.models.modelWithKeyId;
    if (!mainModel) {
      return;
    }

    const data = form.models.data[mainModel.id];
    const validationResult = await this._validationExService.executeServerValidation(
      data.TypeName,
      data.Id,
      "KOPIEREN"
    );

    if (!validationResult) {
      return;
    }

    const webEvent = new OnKopierenRequestEvent({ IdSourceOjekt: idSourceObjekt, CustomData: customData });
    const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(webEvent, true);

    if (!onKopierenRequest || !onKopierenRequest.IdTargetObjekt) {
      return;
    }

    this.notifySuccess();
    form.loadById(onKopierenRequest.IdTargetObjekt.toString());
  }

  private notifySuccess() {
    DevExpress.ui.notify(
      this._localizationService.translateOnce("base.save_success"),
      "SUCCESS",
      this.NOTIFY_TIMEOUT);
  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private deleteAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1);
  }
}
