import { BackgroundImageValueConverter } from './../../../../framework/base/value-converters/background-image/background-image-value-converter';
import { LocalizationService } from './../../../../framework/base/services/localization-service';
import { WebEventService } from './../../../../framework/base/services/web-event-service';
import { IdxPopupComponent, IdxDateBoxComponent, IdxSelectBoxComponent } from './../../../interfaces/dx-components';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { transient } from 'aurelia-dependency-injection';
import { autoinject } from 'aurelia-framework';
import { OnProvisionBerechnenEvent, OnProvisionBerechnen } from '../../../../framework-data/events';
import * as moment from "moment";

@transient()
@autoinject
export class ProvisionListUtils {
  constructor(
    private webEventService: WebEventService,
    private localizationServive: LocalizationService
  ) { }


  form: FormBase;

  provisionBerechnenCommand: ICommandData = {
    id: "provisionBerechnen",
    idCategory: "provision",
    icon: "calculator",
    title: "provision.provision_berechnen",
    execute: async () => {
      const selectBox: IdxSelectBoxComponent = this.form["r_firmaSelectBox"];
      if (selectBox) {
        const dataSource = selectBox.instance.getDataSource();
        dataSource.reload();

        selectBox.setOption({
          value: null
        });
      }

      const popup: IdxPopupComponent = this.form["r_provisionBerechnenBisPopup"];
      popup.instance.show();

      const dateBox: IdxDateBoxComponent = this.form["r_fakturenBis"];
      dateBox.setOption({
        value: moment().subtract(1, "month").endOf("month").toDate()
      });
    }
  }

  doProvisionBerechnenCommand: ICommandData = {
    id: "doProvisionBerechnen",
    icon: "floppy-o",
    title: "base.save",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_provisionBerechnenBisPopup"];
      const dateBox: IdxDateBoxComponent = this.form["r_fakturenBis"];
      const selectBox: IdxSelectBoxComponent = this.form["r_firmaSelectBox"];

      const webEventData: OnProvisionBerechnen = {
        IdFirma: selectBox.instance.option("value"),
        Bis: dateBox.instance.option("value")
      };

      if (!webEventData.IdFirma) {
        DevExpress.ui.notify(this.localizationServive.translateOnce("provision.firma_auswaehlen"), "ERROR", 3000);
        return;
      }
      if (!webEventData.Bis) {
        DevExpress.ui.notify(this.localizationServive.translateOnce("provision.bis_auswaehlen"), "ERROR", 3000);
        return;
      }

      const webEvent = new OnProvisionBerechnenEvent(webEventData);

      const webEventResult: OnProvisionBerechnen = await this.webEventService.execute(webEvent, true);

      this.form.models.reloadAll();

      DevExpress.ui.notify(this.localizationServive.translateOnce("provision.provisionen_werden_berechnet"), "SUCCESS", 3000);
      popup.instance.hide();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
