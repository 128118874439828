import { autoinject } from "aurelia-framework";
import { Commands, FormBase, FormEventService, ICommandData, ToolbarService } from "../../framework/forms/export";
import { IFormCommandOptions } from "../interfaces/export";
import { IFormCommandExecuteOptions } from "../interfaces/form-command-options";
import { EntitaetInfoService } from "./entitaet-info-service";

@autoinject
export class FormCommandService {
  constructor(
    private entitaetInfo: EntitaetInfoService,
    private formEvent: FormEventService,
    private toolbar: ToolbarService) {
    this.setToolbarPages();
    this.setToolbarCategories();
  }

  attachFormCommand(commandInstance: ICommandData, commandOptions: IFormCommandOptions): { (): void } {
    return this.formEvent.onBind.register((args) => {
      const model = args.form.models.modelWithKeyId;
      if (!model) {
        return;
      }

      const entitaetInfo = this.entitaetInfo.getEntitaetInfoByWebApiAction(model.webApiAction);
      if (!entitaetInfo) {
        return;
      }
      if (commandOptions.isCommandVisibleModelProperty != void 0 && entitaetInfo[commandOptions.isCommandVisibleModelProperty] === false) {
        return;
      }

      const command: ICommandData = Object.assign({}, commandInstance);

      command.idCategory = command.idCategory || this.getCommandCategory(entitaetInfo.FullName);

      command.execute = async (options) => {
        const data = args.form.models.data[model.id];
        const canExecute = commandOptions.isCommandVisibleModelProperty == void 0 || this.entitaetInfo.getEntitaetInfoByWebApiAction(model.webApiAction);
        if (data == void 0 || !canExecute) {
          return;
        }

        const r = await args.form.saveIfDirty();
        if (!r.isValid) {
          return;
        }

        const formCommandExecOptions: IFormCommandExecuteOptions = {
          form: args.form,
          modelData: data,
          options: {
            callBack: commandOptions.onFormModelLoaded,
            event: options.event
          }
        };

        commandOptions.execute(formCommandExecOptions);
      };

      this.addCommand(command, args.form);

      if (commandOptions.onCommandAttached) {
        commandOptions.onCommandAttached(args.form);
      }

      args.form.models.onLoaded.register((m) => {
        if (m.model != model || m.data == void 0) {
          return;
        }

        return this.onModelLoaded(m.data, command, commandOptions);
      });

      args.form.onDetached.register((r) => {
        if (commandOptions.onCommandDetached) {
          commandOptions.onCommandDetached(args.form);
        }

        return Promise.resolve();
      });

      return Promise.resolve();
    });
  }
  //TODO Entfernen getReloadCommand Logik
  getReloadCommand(command: ICommandData, forms: FormBase[], options: { modelPropertyName: string; modelPropertyValue: number }): ICommandData {
    const reloadComand = this.getReloadFormCommand(command, forms, options);

    return reloadComand || null;
  }
  getCommandCategory(entitaetFullName: string): string {
    const entitaetName = entitaetFullName.substring(entitaetFullName.lastIndexOf(".") + 1);

    return entitaetName != void 0 ?
      entitaetName.toLowerCase()
      : undefined;
  }
  getCommandTarget(event: Event): Element {
    const element = <Element>event.target;

    return this.getCommandElement(element);
  }
  getFormCommandById(id: string, form: FormBase) {
    return form.commands
      .getCommands()
      .find((c) => c.id == id);
  }
  private addCommand(command: ICommandData, form: FormBase) {
    const exists = this.commandExists(command, form.commands);
    if (exists) {
      return;
    }

    form.commands.addCommand(command);
  }
  private commandExists(command: ICommandData, commands: Commands): boolean {
    return commands
      .getCommands()
      .some((cmd) => {
        return cmd.id == command.id;
      });
  }
  private getFormByProperty(forms: FormBase[], options: { modelPropertyName: string; modelPropertyValue: number }) {
    return forms.find((f) => {
      const model = f.models.getModelWithKeyId();

      if (!model) {
        return;
      }
      if (!f.models.data[model.id]) {
        return null;
      }

      return f.models.data[model.id][options.modelPropertyName] == options.modelPropertyValue;
    });
  }
  private getCommandElement(e: Element) {
    if (e.classList.contains("t--ribbon-toolbar-item")) {
      return e;
    }

    let parent = null;
    while (e) {
      parent = e.parentElement;
      if (parent && parent.classList.contains("t--ribbon-toolbar-item")) {
        return parent;
      }

      e = parent;
    }

    return null;
  }
  private getReloadFormCommand(command: ICommandData, forms: FormBase[], options: { modelPropertyName: string; modelPropertyValue: number }): ICommandData {
    const needsToReload = this.needsToReload(command, forms, options);
    if (!needsToReload) {
      return null;
    }

    const commandForm = this.getFormByProperty(forms, options);
    if (!commandForm) {
      return null;
    }

    return this.getFormCommandById(command.id, commandForm) || null;
  }
  private setToolbarPages() {
    this.toolbar.addPage("weiteres", "erp.weiteres", 5);
    this.toolbar.addPage("kommunikation", "erp.kommunikation", 10);
    this.toolbar.addPage("aufgaben", "erp.aufgaben", 20);
    this.toolbar.addPage("notizen", "erp.notizen", 30);
  }
  private setToolbarCategories() {
    //General
    this.toolbar.addCategory("sprache", "$start", "erp.sprache", 40);
    this.toolbar.addCategory("benutzer", "$start", "erp.benutzer", 50);
    this.toolbar.addCategory("brief-entitaet", "$start", "erp.brief", 50);
    this.toolbar.addCategory("beleg-export", "$start", "erp.export", 90);
    this.toolbar.addCategory("aufgabe", "$start", "erp.aufgabe", 60);
    this.toolbar.addCategory("mail", "$start", "erp.mail", 70);
    this.toolbar.addCategory("status", "$start", "erp.status", 90);
    this.toolbar.addCategory("versionierung", "$start", "erp.versionierung", 95);
    this.toolbar.addCategory("dms", "kommunikation", "erp.dms", 60);
    this.toolbar.addCategory("aufgaben", "kommunikation", "erp.aufgaben", 70);
    this.toolbar.addCategory("notizen", "kommunikation", "erp.notizen", 75);
    this.toolbar.addCategory("brief", "kommunikation", "erp.brief", 80);
    this.toolbar.addCategory("benachrichtigung", "$start", "erp.benachrichtigung", 83);
    this.toolbar.addCategory("artikel_bestellvorschlag", "weiteres", "erp.bestellvorschlag", 83);
    this.toolbar.addCategory("artikel_art", "weiteres", "erp.artikelart", 85);
    this.toolbar.addCategory("artikel", "$start", "erp.artikel", 80);
    this.toolbar.addCategory("geschaeftspartner", "$start", "erp.geschaeftspartner", 80);
    this.toolbar.addCategory("geschaeftspartner_art", "weiteres", "erp.geschaeftspartnerart", 85);
    this.toolbar.addCategory("statistik", "$start", "erp.statistik", 90);
    this.toolbar.addCategory("statistik-filter", "$start", "erp.filterauswahl", 80);
    this.toolbar.addCategory("darstellungen", "$start", "erp.darstellungen", 70);
    this.toolbar.addCategory("newsletter", "$start", "erp.newsletter", 80);
    this.toolbar.addCategory("newsletter-empfaenger", "$start", "erp.newsletter-empfaenger", 80);
    this.toolbar.addCategory("newsletter-anmeldung", "$start", "erp.newsletter-anmeldung", 80);
    this.toolbar.addCategory("projekttaetigkeit", "$start", "erp.projekttaetigkeit", 40);
    this.toolbar.addCategory("projekt", "$start", "erp.projekt", 40);
    this.toolbar.addCategory("webshop", "weiteres", "erp.webshop", 90);
    this.toolbar.addCategory("kassa", "$start", "erp.kassa", 40);
    this.toolbar.addCategory("papierkorb", "$start", "erp.papierkorb", 40);
    this.toolbar.addCategory("zahlungsverkehr", "$start", "erp.zahlungsverkehr", 40);
    //Einkauf
    this.toolbar.addCategory("bestellung", "$start", "erp.bestellung", 40);
    this.toolbar.addCategory("bestellungsposition", "$start", "erp.bestellungsposition", 40);
    this.toolbar.addCategory("bestellvorschlag", "$start", "erp.bestellvorschlag", 40);
    this.toolbar.addCategory("eingangsrechnung", "$start", "erp.eingangsrechnung", 40);
    this.toolbar.addCategory("warenuebernahme", "$start", "erp.warenuebernahme", 40);
    this.toolbar.addCategory("warenuebernahmeposition", "$start", "erp.warenuebernahmesposition", 40);
    //Verkauf
    this.toolbar.addCategory("angebot", "$start", "erp.angebot", 40);
    this.toolbar.addCategory("angebotsposition", "$start", "erp.angebotsposition", 40);
    this.toolbar.addCategory("auftrag", "$start", "erp.auftrag", 40);
    this.toolbar.addCategory("auftragsposition", "$start", "erp.auftragsposition", 40);
    this.toolbar.addCategory("faktura", "$start", "erp.faktura", 40);
    this.toolbar.addCategory("fakturaposition", "$start", "erp.fakturaposition", 40);
    this.toolbar.addCategory("ladeliste", "$start", "erp.ladeliste", 40);
    this.toolbar.addCategory("ladeliste-lieferschein-weiteres", "weiteres", "erp.lieferschein", 40);
    this.toolbar.addCategory("lieferschein", "$start", "erp.lieferschein", 40);
    this.toolbar.addCategory("lieferscheinposition", "$start", "erp.lieferscheinposition", 40);
    this.toolbar.addCategory("reservierung", "weiteres", "erp.reservierung", 40);
    this.toolbar.addCategory("konditionen", "weiteres", "erp.konditionen", 30);
    this.toolbar.addCategory("gewicht", "weiteres", "erp.gewichte", 30);
    this.toolbar.addCategory("kundenreklamation", "$start", "erp.kundenreklamation", 40);
    this.toolbar.addCategory("split", "weiteres", "erp.split", 50);
    this.toolbar.addCategory("text", "weiteres", "erp.texte", 60);
    this.toolbar.addCategory("positionen", "weiteres", "erp.positionen", 10);
    this.toolbar.addCategory("provision", "$start", "erp.provision", 40);
    this.toolbar.addCategory("kassabeleg", "$start", "erp.kassa-beleg", 40);
    this.toolbar.addCategory("mahnung", "$start", "erp.mahnung", 40);
    this.toolbar.addCategory("gutschein", "$start", "erp.gutschein", 40);
    //projektzeit
    this.toolbar.addCategory("projekt-taetigkeit-buchung", "$start", "erp.projekttaetigkeitbuchung", 40);
    //arbeitszeit
    this.toolbar.addCategory("arbeitszeit", "$start", "erp.arbeitszeit", 40);
    //Produktion
    this.toolbar.addCategory("prodauftrag", "$start", "erp.prodauftrag", 40);
    this.toolbar.addCategory("prodauftrag_quick", "weiteres", "erp.quick", 83);
    //Lager
    this.toolbar.addCategory("inventur", "$start", "erp.inventur", 40);
    this.toolbar.addCategory("fehlende_artikel_laden", "weiteres", "erp.inventur", 40);
    this.toolbar.addCategory("lagerkorrektur", "$start", "erp.lagerkorrektur", 40);
    this.toolbar.addCategory("artikelverleih", "$start", "erp.artikelverleih", 40);
    //Konfiguration
    this.toolbar.addCategory("import", "$start", "erp.import", 40);
    this.toolbar.addCategory("fibu-sync-job", "$start", "erp.synchronisierung", 40);
    //Ticket
    this.toolbar.addCategory("ticket", "$start", "erp.ticket", 40);
    //Datenbank Info
    this.toolbar.addCategory("datenbank-info", "$start", "erp.datenbank-info", 40);
    //Instanz-Status
    this.toolbar.addCategory("instanz-status", "$start", "erp.instanz-status", 40);
  }
  private needsToReload(command: ICommandData, forms: FormBase[], options: { modelPropertyName: string; modelPropertyValue: number }): boolean {
    return forms.some((form) => {
      const model = form.models.getModelWithKeyId();

      if (model == void 0) {
        return null;
      }
      return form.models.data[model.id] != void 0
        && form.models.data[model.id][options.modelPropertyName] == options.modelPropertyValue;
    });
  }
  private onModelLoaded(modelData: any, command: ICommandData, options?: IFormCommandOptions): Promise<any> {
    if (options.onFormModelLoaded) {

      options.onFormModelLoaded(modelData, command);
    }

    this.setCommandBindings(modelData, command, options);

    return Promise.resolve();
  }
  private setCommandBindings(modelData: any, command: ICommandData, options?: IFormCommandOptions) {
    if (modelData == void 0) {
      return;
    }
    const isEnabled = modelData.Id > 0;

    if (modelData.Id == void 0) {
      command.badgeText = null;
    }

    command.isEnabled = isEnabled;
  }
}
