import { autoinject } from "aurelia-framework";

@autoinject
export class ObjectService {
  constructor() {}

  mergeDeep(target, ...sources) {
    if (!sources.length) {
      return target
    }

    const source = sources.shift();
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }

          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return this.mergeDeep(target, ...sources);
  }
  isObject(item) {
    return (item 
      && typeof item === "object"
      && !Array.isArray(item));
  }

  getValue(obj: any, propertyName: string): any {
    const tokens = propertyName.split(".");

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (i + 1 === tokens.length) {
        return obj[token];
      } 

      obj = obj[token];
      if (!obj) {
        return;
      }
    }

    return null;
  }
  setValue(obj: any, propertyName: string, value: any, createObjectIfMissing: boolean = false) {
    const tokens = propertyName.split(".");

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (i + 1 === tokens.length) {
        obj[token] = value;
      } else {
        let newObj = obj[token];

        if (newObj == void(0)) {
          if (createObjectIfMissing) {
            newObj = {};
            obj[token] = newObj;
          } else {
            return;
          }
        }

        obj = newObj;
      }
    }
  }
}
