import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class KundenreklamationListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "kundenreklamation-list";
        this.title = "kundenreklamation-list.kundenreklamation-list_caption";
        this.addModel({
            "id": "$m_Kundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Kunde': null,
                'Projekt': null,
                'KundeAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_kundenreklamationGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_reklNummer",
                "caption": "kundenreklamation-list.r_reklnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_reklDatum",
                "caption": "kundenreklamation-list.r_rekldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_reklProjekt",
                "caption": "kundenreklamation-list.r_reklprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_reklKundeNummer",
                "caption": "kundenreklamation-list.r_reklkundenummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_reklFirmenbez1",
                "caption": "kundenreklamation-list.r_reklfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_reklStrasse",
                "caption": "kundenreklamation-list.r_reklstrasse_caption",
                "bindTo": "KundeAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_reklLand",
                "caption": "kundenreklamation-list.r_reklland_caption",
                "bindTo": "KundeAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_reklOrt",
                "caption": "kundenreklamation-list.r_reklort_caption",
                "bindTo": "KundeAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_reklWertNetto",
                "caption": "kundenreklamation-list.r_reklwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklWaehrung",
                "caption": "kundenreklamation-list.r_reklwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_reklStatus",
                "caption": "kundenreklamation-list.r_reklstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kundenreklamationGridToolbarOptions",
                "optionsNameFQ": "r_kundenreklamationGridToolbarOptions"
            },
            "caption": "kundenreklamation-list.r_kundenreklamationgrid_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamation"
            },
            "dataModel": "$m_Kundenreklamation",
            "editUrl": "ERP/Verkauf/Kundenreklamation",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kundenreklamationGrid",
            "options": {
                "optionsName": "r_kundenreklamationGridOptions",
                "optionsNameFQ": "r_kundenreklamationGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}