import { autoinject, bindable } from "aurelia-framework";
import { LocalizationService } from '../../../../framework/base/services/localization-service';
import { GlobalizationService } from '../../../../framework/base/services/globalization-service';

@autoinject
export class NotizItem {
  constructor(
    private localizationService: LocalizationService,
    private globalizationService: GlobalizationService
  ) {}

  @bindable item: any;
  anlageinfo: string = "";

  bind() {
    if (this.item && this.item.AnlageBenutzer && this.item.AnlageDatum) {
      this.anlageinfo = this.localizationService
        .translateOnce(
          "erp_aendinfo.erstellt_von", [
            this.item.AnlageBenutzer, 
            this.globalizationService.format(this.item.AnlageDatum, "d"),
            this.globalizationService.format(this.item.AnlageDatum, "t")]);
    }
  }
}
