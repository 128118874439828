import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  OnEvalEinkaufArtikeldaten,
  OnEvalEinkaufArtikeldatenEvent
} from "../../../../framework-data/events";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase, ModelUtilsService
} from "../../../../framework/forms/export";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";

@autoinject
@transient()
export class EingangsrechnungspositionEditUtils {
  constructor(
    public dynFelderDataService: DynFelderDataService,
    private _modelUtilsService: ModelUtilsService,
    private _webEventService: WebEventService) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Eingangsrechnungsposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    form.editPopups.onEditPopupHidden.register((r) => {
        if (r.hasDataReloaded) {
            return Promise.resolve();
        }

        if (r.editPopup.id == "eingangsrechungspositionEditPopup") {
            this.form.models.reloadAll();
        }

        return Promise.resolve();
    });
  }

  async idArtikelChanged(e) {
    if (!this.form.models.data.$m_Eingangsrechnungsposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Eingangsrechnungsposition);
    }

    this.doEvalEinkaufArtikeldaten(e.detail.value);
  }

  private async doEvalEinkaufArtikeldaten(idArtikel) {
    if (!idArtikel) {
      return;
    }

    const webEvent = new OnEvalEinkaufArtikeldatenEvent({
      IdEingangsrechnung: this.form.models.data.$m_Eingangsrechnung.Id,
      IdArtikel: idArtikel,
      IdLieferant: this.form.models.data.$m_Eingangsrechnung.IdLieferant
    });

    const result: OnEvalEinkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    this.form.models.data.$m_Eingangsrechnungsposition.IdEinheit = result.IdArtikelEinheit;
    this.form.models.data.$m_Eingangsrechnungsposition.LieferantArtikelNr = result.LieferantArtikelNr;
    this.form.models.data.$m_Eingangsrechnungsposition.LieferantArtikelbezeichnung = result.LieferantArtikelbezeichnung;

    if (result.Steuersatz != void (0)) {
      this.form.models.data.$m_Eingangsrechnungsposition.Steuersatz = result.Steuersatz;
    }
  }
}
