import {
  autoinject,
  TemplatingEngine
} from "aurelia-framework";
import {
  RestService, TemplatingExService, GlobalizationService, ScopeContainer, JsonService
} from "../../framework/base/export";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  NotificationTyp
} from "../enumerations/export";
import {
  FormBase,
  IModel,
  SelectItemService,
  FormEventService
} from "../../framework/forms/export";
import {
  EntitaetInfoService
} from "./entitaet-info-service";
import { DynFelderDataService } from './dyn-felder-data-service';
import { DxCustomizeService } from './dx-customize-service';

@autoinject
export class FilterService {
  private _filterGruppeList: any[];

  constructor(
    private eventAggregator: EventAggregator,
    private rest: RestService,
    private entitaetInfo: EntitaetInfoService,
    private templatingEngine: TemplatingEngine,
    private templatingExService: TemplatingExService,
    private globalizationService: GlobalizationService,
    private dynFeldDataService: DynFelderDataService,
    private dxCustomizeService: DxCustomizeService,
    private selectItemService: SelectItemService,
    private formEvent: FormEventService,
    private jsonService: JsonService
  ) {
    this.initializeSubscribers();

    eventAggregator.subscribe(
      "model:added",
      this.checkFilterInjectionModel.bind(this));

    formEvent.onAttached.register(e => {
      e.form.callOnBind(() => {
        this.checkFilterInjection(e.form);
      });

      return Promise.resolve();
    });
  }

  getFilterGruppen(webApiAction: string): any[] {
    if (!this._filterGruppeList) {
      return [];
    }

    const entitaet = this.entitaetInfo.getEntitaetInfoByWebApiAction(webApiAction).FullName;

    return this._filterGruppeList.filter(c => {
      return c.Entitaet === entitaet;
    });
  }
  reload() {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Stammdaten/FilterGruppe"),
      getOptions: { expand: { FilterElemente: null } }
    }).then(r => {
      this._filterGruppeList = r;
    });
  }

  getFilterBuilderFeldList(scopeContainer: ScopeContainer, filterFeldItemList: IFilterFeldItem[]) {
    const result = filterFeldItemList.map(feld => {
      const r: DevExpress.ui.dxFilterBuilderField = {
        caption: feld.Bezeichnung,
        dataField: feld.Name,
        format: feld.Format
          ? this.globalizationService.getFormatterParser(feld.Format)
          : null,
        dataType: <any>feld.Datentyp,
        filterOperations: feld.Operatoren && feld.Operatoren.length > 0
          ? feld.Operatoren
          : null
      };

      if (feld.DynFeldAuswahlliste) {
        r.lookup = {
          dataSource: {
            store: this.dynFeldDataService.getDataSource(feld.DynFeldAuswahlliste, feld.IdDynFeld, scopeContainer, true)
          },
          valueExpr: "Id",
          displayExpr: "DisplayText"
        }
      } else if (feld.IdSelect) {
        const selectItem = this.selectItemService.getSelectItem(feld.IdSelect);
        if (selectItem) {
          const dataSource = this.selectItemService.createSelectDataSource(
            scopeContainer,
            selectItem,
            feld.SelectFilter ? this.jsonService.parse(feld.SelectFilter) : null,
            null,
            feld.SelectCustoms ? this.jsonService.parse(feld.SelectCustoms) : null
          );

          r.lookup = {
            dataSource: {
              store: dataSource.store()
            },
            valueExpr: selectItem.valueMember,
            displayExpr: selectItem.displayMember
          }
        }
      }

      return r;
    });

    this.dxCustomizeService.checkFilterBuilderFieldOperators(result);
    return result;
  }

  private checkFilterInjectionModel(args: any) {
    const form: FormBase = args.form;
    const model: IModel = args.model;

    if (!model.custom || !model.custom.addFilter) {
      return;
    }

    const entitaetInfo = this.entitaetInfo.getEntitaetInfoByWebApiAction(model.webApiAction);
    if (!entitaetInfo) {
      return;
    }

    form["_filterModel"] = model;
    form.variables.addInfo({
      id: "$v_filter"
    });

    if (entitaetInfo.HasVolltext) {
      form.variables.addInfo({
        id: "$v_filterSearchtext"
      });

      model.searchtext = "variables.data.$v_filterSearchtext";
      model.webApiSearchtextEnabled = true;
    }
    if (entitaetInfo.HasFilterManuell) {
      form.variables.addInfo({
        id: "$v_filterManuell"
      });

      model.filters.push({
        if: "variables.data.$v_filterManuell",
        webApiCustomKey: "filterManuell",
        webApiCustomValue: "variables.data.$v_filterManuell"
      });
    }

    model.filters.push({
      if: "variables.data.$v_filter",
      webApiCustomKey: "filter",
      webApiCustomValue: "variables.data.$v_filter"
    });
    
    model.filters.push({
      if: "variables.data.$v_filterInject",
      webApiCustomKey: "filterInject",
      webApiCustomValue: "variables.data.$v_filterInject"
    });

    if (!form.viewItemInfo || !form.viewItemInfo.routeInfo || !form.viewItemInfo.routeInfo.customOptions || !form.viewItemInfo.routeInfo.customOptions.filterInject) {
      let filterDefault = "";
      for (let gruppe of this.getFilterGruppen(model.webApiAction)) {
        for (let element of gruppe.FilterElemente) {
          if (!element.IsVorbelegung) {
            continue;
          }
  
          filterDefault += `|${gruppe.Id}=${element.Id}|`
          break;
        }
      }
  
      form.variables.data.$v_filter = filterDefault;
    }    
  }

  private checkFilterInjection(form: FormBase) {
    if (!form["_filterModel"]) {
      return;
    }

    const model: IModel = form["_filterModel"];

    const grid = form.element.querySelector(".t--dx-widget[name='dxDataGrid']");
    if(!grid){
      return;
    }

    const filterContainer = document.createElement("div");
    filterContainer.className = "erp--filter-container";

    if (!form.isNestedForm) {
      filterContainer.className += " erp--filter-container-100";
    }

    const filter = document.createElement("filter");
    filter.setAttribute("web-api-action", model.webApiAction);
    filter.setAttribute("tokens.bind", "variables.data.$v_filter");

    const relativeContainer = document.createElement("div");

    if (model.webApiSearchtextEnabled) {
      filter.setAttribute("searchtext.bind", "variables.data.$v_filterSearchtext");
      filter.setAttribute("has-volltext.bind", "true");
    }
    if (model.filters && model.filters.some(filter => filter.webApiCustomKey == "filterManuell")) {
      filter.setAttribute("filter-manuell.bind", "variables.data.$v_filterManuell");
      filter.setAttribute("has-filter-manuell.bind", "true");
    }

    filter.setAttribute("filter-inject.bind", "variables.data.$v_filterInject");

    grid.parentElement.appendChild(filterContainer);
    filterContainer.appendChild(filter);
    filterContainer.appendChild(relativeContainer);
    relativeContainer.appendChild(grid);

    const result = this.templatingEngine.enhance({
      element: filter,
      bindingContext: form.scope.bindingContext,
      overrideContext: form.scope.overrideContext
    });

    const hasSearchtext = form.viewItemInfo 
      && form.viewItemInfo.routeInfo 
      && form.viewItemInfo.routeInfo.customOptions 
      && form.viewItemInfo.routeInfo.customOptions.searchtext;

    if (hasSearchtext) {
      form.variables.data.$v_filterSearchtext = form.viewItemInfo.routeInfo.customOptions.searchtext;
    }

    form.onUnbind.register(() => {
      this.templatingExService.destroyView(result);
      return Promise.resolve();
    });
  }

  private initializeSubscribers() {
    this.eventAggregator.subscribe(
      NotificationTyp.filtergruppeChanged,
      this.reload.bind(this));

    this.eventAggregator.subscribe(
      NotificationTyp.filterelementChanged,
      this.reload.bind(this));
  }
}

interface IFilterFeldItem {
  Name?: string;
  Bezeichnung?: string;
  Datentyp?: string;
  Format?: string;
  Operatoren?: string[];
  
  IdDynFeld?: number;
  DynFeldAuswahlliste?: string;

  IdSelect?: string;
  SelectFilter?: string;
  SelectCustoms?: string;
}