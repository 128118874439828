import { autoinject, bindable, observable } from "aurelia-framework";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { IdxDataGridComponent } from "../../interfaces/dx-components";
import { ArbeitszeitKalenderZuteilungEdit, IArbeitszeitKalender } from './../arbeitszeit-kalender-zuteilung-edit/arbeitszeit-kalender-zuteilung-edit';
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";

@autoinject
export class ArbeitszeitKalenderZuteilungList {

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  @bindable @observable mitarbeiterstatus: IArbeitszeitMitarbeiterstatus;
  arbeitszeitKalenderZuteilungEdit: ArbeitszeitKalenderZuteilungEdit;

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnResizing: true,
    columnResizingMode: "widget",
    paging: {
      pageSize: 20
    },
    hoverStateEnabled: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columns: [{
      dataField: "GueltigVon",
      caption: "Gültig von",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "GueltigBis",
      caption: "Gültig bis",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    }]
  };

  onNeuButtonClick() {
    this.arbeitszeitKalenderZuteilungEdit.show({
      mitarbeiterstatus: this.mitarbeiterstatus,
      callback: async (arbeitszeitKalender: IArbeitszeitKalender) => {
        const r = await this._restService.post({
          url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/kalender`),
          data: {
            IdMitarbeiterstatus: this.mitarbeiterstatus.IdMitarbeiterstatus,
            IdKalender: arbeitszeitKalender.IdKalender,
            GueltigVon: arbeitszeitKalender.GueltigVon
          }
        });

        this.loadMitarbeiterstatusKalenderZuteilung();
      }
    });
  }

  mitarbeiterstatusChanged(e) {
    if (this.mitarbeiterstatus != null && this.mitarbeiterstatus.IdMitarbeiterstatus > 0) {
      this.loadMitarbeiterstatusKalenderZuteilung();
    }
  }


  private async loadMitarbeiterstatusKalenderZuteilung() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/kalender/${this.mitarbeiterstatus.IdMitarbeiterstatus}`)
    });

    this.grid.instance.option("dataSource", r);
  }
}
