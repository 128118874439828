import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    NewsletterEditUtils
} from "./newsletter-edit-utils";

@fwx.autoinject
export class NewsletterEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_NewsletterEditUtils: NewsletterEditUtils) {
        super(element, formBaseImport);
        this.id = "newsletter-edit";
        this.title = "newsletter-edit.newsletter-edit_caption";
        this.addModel({
            "id": "$m_Newsletter",
            "webApiAction": "ERP/Stammdaten/Newsletter",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_NewsletterVersandStatusRel",
            "webApiAction": "ERP/Stammdaten/NewsletterVersandStatus",
            "webApiExpand": {
                'Person': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NewsletterEditUtils.versendenCommand",
                "bindToFQ": "functions.$f_NewsletterEditUtils.versendenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NewsletterEditUtils.testVersendenCommand",
                "bindToFQ": "functions.$f_NewsletterEditUtils.testVersendenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NewsletterEditUtils.doExportGridCommand",
                "bindToFQ": "functions.$f_NewsletterEditUtils.doExportGridCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_NewsletterEditUtils", $f_NewsletterEditUtils, "functions.$f_NewsletterEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "newsletter-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Newsletter",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Newsletter.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "newsletter-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Newsletter",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Newsletter.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "newsletter-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Newsletter",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_Newsletter.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mailVorlage",
            "filter": ["Entitaet", "TIP.ERP.Business.Entitaeten.Stammdaten.Newsletter"],
            "filters": [],
            "caption": "newsletter-edit.r_mailvorlage_caption",
            "binding": {
                "dataContext": "$m_Newsletter",
                "bindTo": "IdMailVorlage",
                "bindToFQ": "models.data.$m_Newsletter.IdMailVorlage"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_mailVorlage",
            "options": {
                "optionsName": "r_mailVorlageOptions",
                "optionsNameFQ": "r_mailVorlageOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "newsletterEmpfaenger",
            "filters": [],
            "caption": "newsletter-edit.r_empfaenger_caption",
            "binding": {
                "dataContext": "$m_Newsletter",
                "bindTo": "IdNewsletterEmpfaenger",
                "bindToFQ": "models.data.$m_Newsletter.IdNewsletterEmpfaenger"
            },
            "validationRules": [],
            "id": "r_empfaenger",
            "options": {
                "optionsName": "r_empfaengerOptions",
                "optionsNameFQ": "r_empfaengerOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_versVorname",
                "caption": "newsletter-edit.r_versvorname_caption",
                "bindTo": "Vorname"
            }, {
                "id": "r_versNachname",
                "caption": "newsletter-edit.r_versnachname_caption",
                "bindTo": "Nachname"
            }, {
                "id": "r_versEmail",
                "caption": "newsletter-edit.r_versemail_caption",
                "bindTo": "Email"
            }, {
                "id": "r_versVersandDatum",
                "caption": "newsletter-edit.r_versversanddatum_caption",
                "bindTo": "VersandDatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_versLeseDatum",
                "caption": "newsletter-edit.r_verslesedatum_caption",
                "bindTo": "LeseDatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_versStatus",
                "caption": "newsletter-edit.r_versstatus_caption",
                "bindTo": "VersandStatusTyp",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.NewsletterVersandStatusTyp"
            }, {
                "id": "r_versFehlerInfo",
                "caption": "newsletter-edit.r_versfehlerinfo_caption",
                "bindTo": "Fehlerinfo",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_versGridToolbarOptions",
                "optionsNameFQ": "r_versGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Newsletter"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_NewsletterVersandStatusRel",
                "bindTo": "IdNewsletter",
                "bindToFQ": "models.data.$m_NewsletterVersandStatusRel.IdNewsletter"
            },
            "dataModel": "$m_Newsletter",
            "editUrl": "ERP/Stammdaten/Person",
            "editUrlIdProperty": "IdPerson",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_versGrid",
            "options": {
                "optionsName": "r_versGridOptions",
                "optionsNameFQ": "r_versGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_testMailPopup",
            "options": {
                "optionsName": "r_testMailPopupOptions",
                "optionsNameFQ": "r_testMailPopupOptions"
            },
            "caption": "newsletter-edit.r_testmailpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_NewsletterEditUtils.testVersendenNowCommand",
                    "bindToFQ": "functions.$f_NewsletterEditUtils.testVersendenNowCommand"
                }
            }]
        });
        this.widgetCreator.addTextBox(this, {
            "mode": "email",
            "caption": "newsletter-edit.r_testmailempfaenger_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_testMailEmpfaenger",
            "options": {
                "optionsName": "r_testMailEmpfaengerOptions",
                "optionsNameFQ": "r_testMailEmpfaengerOptions"
            }
        });
        super.onConstructionFinished();
    }
}