import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuKontoListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-konto-list";
        this.title = "fibu-konto-list.fibu-konto-list_caption";
        this.addModel({
            "id": "$m_FibuKonto",
            "webApiAction": "ERP/Fibu/Konto",
            "webApiExpand": {
                'Firma': null,
                'Kontogruppe': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fibuKFirma",
                "caption": "fibu-konto-list.r_fibukfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fibuKKontogruppe",
                "caption": "fibu-konto-list.r_fibukkontogruppe_caption",
                "bindTo": "Kontogruppe.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fibuKNummer",
                "caption": "fibu-konto-list.r_fibuknummer_caption",
                "bindTo": "Nummer",
                "width": "80px",
                "format": "f0"
            }, {
                "id": "r_fibuKBezeichnung",
                "caption": "fibu-konto-list.r_fibukbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_fiboKontoGridToolbarOptions",
                "optionsNameFQ": "r_fiboKontoGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_FibuKonto"
            },
            "dataModel": "$m_FibuKonto",
            "editUrl": "ERP/Fibu/Konto",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fiboKontoGrid",
            "options": {
                "optionsName": "r_fiboKontoGridOptions",
                "optionsNameFQ": "r_fiboKontoGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}