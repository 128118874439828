import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, computedFrom } from "aurelia-framework";
import { OnUmsatzLandEvent, StatistikVerkaufDetailViewTyp, StatistikVerkaufZeitraumTyp } from '../../../../framework-data/events';
import { GlobalizationService, JsonService, WebEventService } from '../../../../framework/base/export';
import { IdxChartComponent } from '../../../interfaces/export';
import { FirmaService, StartupService } from '../../../services/export';
import { StatistikService } from '../../../services/statistik-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IUmsatzLandSettings } from './umsatz-land-settings';

@autoinject
export class UmsatzLand {
  private _windowResizeSubscription: Subscription;

  constructor(
    private _firmaService: FirmaService,
    private _globalizationService: GlobalizationService,
    private _webEventService: WebEventService,
    private _startupService: StartupService,
    private _statistikVerkaufService: StatistikService,
    private _jsonService: JsonService,
    private _eventAggregator: EventAggregator) { }

  widgetModel: IWidgetModel;
  settings: IUmsatzLandSettings;
  firmaBezeichnung: string;

  instance: IdxChartComponent;
  options: DevExpress.viz.charts.dxChartOptions = {
    commonSeriesSettings: {
      type: "stackedbar",
      argumentField: "Jahr",
    },
    argumentAxis: {
      argumentType: "string"
    },
    valueAxis: [{
      label: { format: this._globalizationService.getFormatterParser("n0") }
    }],
    tooltip: {
      enabled: true,
      zIndex: 1505,
      customizeTooltip: (e) => {
        return {
          text: `${e.argumentText}<br>${e.seriesName}: ${this._startupService.startupInfo.Mandant.WaehrungCode} ${this._globalizationService.format(e.value, "n0")} (= ${this._globalizationService.format(e.percent, "p0")})`
        };
      }
    },
    onPointClick: (e) => {
      if (!e.target || !e.target.data) {
        return;
      }
      if (!e.target.series.getValueFields) {
        return;
      }
      if (!this.data || !this.data.Where) {
        return;
      }
      if (!e.target.data.Jahr) {
        return;
      }

      const valueFields = e.target.series.getValueFields();
      if (valueFields.length != 1) {
        return;
      }

      const valueField = valueFields[0];

      const info = this.data.Info.find(c => c.DataField == valueField);
      if (!info || !info.Where) {
        return;
      }

      const where = this._jsonService.parse(this._jsonService.stringify(this.data.Where));
      where.Data.Jahr = e.target.data.Jahr;
      where.Data.Land = info.Where;

      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
    }
  }

  statistikZeitraumTyp: StatistikVerkaufZeitraumTyp = StatistikVerkaufZeitraumTyp.YTD;

  ytdButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Umsatz YTD",
    hint: "Umsatz YTD aus Statistik gruppiert nach Land",
    onClick: () => {
      this.statistikZeitraumTyp = StatistikVerkaufZeitraumTyp.YTD;
      this.loadData(true);
    }
  }
  jahrButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Umsatz Jahr",
    hint: "Umsatz Jahr aus Statistik gruppiert nach Land",
    onClick: () => {
      this.statistikZeitraumTyp = StatistikVerkaufZeitraumTyp.Jahr;
      this.loadData(true);
    }
  }

  data: any;

  @computedFrom("statistikZeitraumTyp")
  get statistikZeitraumTypBez() {
    switch (this.statistikZeitraumTyp) {
      case StatistikVerkaufZeitraumTyp.Jahr: {
        return "Umsatz je Land Jahr";
      }
      case StatistikVerkaufZeitraumTyp.YTD: {
        return "Umsatz je Land YTD";
      }
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;

    this.statistikZeitraumTyp = this.settings.standard;
  }

  bind() {
    this.loadData(false);
  }
  attached() {
    this._windowResizeSubscription = this._eventAggregator.subscribe("window:resize", () => {
      if (this.instance && this.instance.instance) {
        this.instance.instance.render();
      }
    });
  }
  detached() {
    this._windowResizeSubscription.dispose();
    this._windowResizeSubscription = null;
  }

  private async loadData(showLoading: boolean) {
    const idArtikel = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
      ? this.widgetModel.mainModel.Id
      : null;

    const result: any = await this._webEventService.execute(
      new OnUmsatzLandEvent({
        ZeitraumTyp: this.statistikZeitraumTyp,
        IdArtikel: idArtikel,
        IdFirma: this.settings.idFirma
      }),
      showLoading);
    if (!result) {
      return;
    }

    this.data = result.Data;

    const series = this.data.Info.map(c => {
      return {
        name: c.Bez,
        valueField: c.DataField
      };
    });

    if (series.length > 0) {
      Object.assign(series[series.length - 1], {
        label: {
          backgroundColor: "#ddd",
          font: {
            color: "black"
          },
          position: "outside",
          visible: true,
          customizeText: (e) => {
            return this._globalizationService.format(e.total, "n0");
          }
        }
      });
    }

    const options = {
      series: series,
      dataSource: this.data.Data
    }

    if (this.instance && this.instance.instance) {
      this.instance.instance.option(options);
    } else {
      Object.assign(this.options, options);
    }

    this.getFirmaBezeichnung();
  }
  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
