import { autoinject } from "aurelia-framework";
import * as moment from "moment";
import { OnTopArtikel, OnTopArtikelEvent, StatistikVerkaufDetailViewTyp, UmsatzWhereTyp } from '../../../../framework-data/events';
import { LocationService, WebEventService } from '../../../../framework/base/export';
import { PopupInfoService } from '../../../../framework/forms/export';
import { IUmsatzWhereItem } from '../../../elements/statistik-verkauf-detail/statistik-verkauf-detail';
import { EntitaetInfoService, FirmaService } from '../../../services/export';
import { StatistikService } from '../../../services/statistik-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { ITopArtikelSettings } from './top-artikel-settings';

@autoinject
export class TopArtikel {
  constructor(
    private _firmaService: FirmaService,
    private _webEventService: WebEventService,
    private _locationService: LocationService,
    private _entitaetInfoService: EntitaetInfoService,
    private _popupInfoService: PopupInfoService,
    private _statistikVerkaufService: StatistikService
  ) {
    this.bezWert = moment().year().toString();
    this.bezVergl = moment().subtract(1, "year").year().toString();
  }

  widgetModel: IWidgetModel;
  settings: ITopArtikelSettings;

  bezWert: string;
  bezVergl: string;
  itemList: any[];
  where: string;
  firmaBezeichnung: string;

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;
  }

  bind() {
    this.loadData();
  }

  async loadData() {
    const idGeschaeftspartner = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
      ? this.widgetModel.mainModel.Id
      : null;

    const result: OnTopArtikel = await this._webEventService.execute(new OnTopArtikelEvent({
      IdGeschaeftspartner: idGeschaeftspartner,
      Anzahl: this.settings.anzahl,
      IdFirma: this.settings.idFirma
    }));
    if (!result) {
      return;
    }

    this.getFirmaBezeichnung();

    this.where = result.Where;
    this.itemList = result.ItemList;
  }

  async onClick(item) {
    const url = await this._entitaetInfoService.getUrl("TIP.ERP.Business.Entitaeten.Stammdaten.Artikel", item.Id);
    if (!url) {
      return;
    }

    const popupClosed = await this._popupInfoService.closeAllPopups();
    if (!popupClosed) {
      return;
    }

    this._locationService.goTo({
      url: url,
      viewScrollInfo: {
        index: this.itemList.indexOf(item),
        maxCount: this.itemList.length,
        getNextKey: (index) => {
          return this.itemList[index].Id
        }
      }
    });
  }
  onWertClick(e: Event, item) {
    e.preventDefault();
    e.stopPropagation();

    const where: IUmsatzWhereItem = {
      Typ: UmsatzWhereTyp.Jahr,
      IdArtikel: item.Id,
      IdFirma: this.settings.idFirma,
      Data: {
        Jahr: moment().year()
      }
    }

    //TODO
    // if (this.idGeschaeftspartner) {
    //   where.IdGeschaeftspartner = this.idGeschaeftspartner;
    // }

    this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
  }
  onVergleichClick(e: Event, item) {
    e.preventDefault();
    e.stopPropagation();

    const where: IUmsatzWhereItem = {
      Typ: UmsatzWhereTyp.Jahr,
      IdArtikel: item.Id,
      IdFirma: this.settings.idFirma,
      Data: {
        Jahr: moment().subtract(1, "year").year()
      }
    }

    //TODO
    // if (this.idGeschaeftspartner) {
    //   where.IdGeschaeftspartner = this.idGeschaeftspartner;
    // }

    this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
  }

  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
