import * as fwx from "../../../../framework/forms/form-export";
import {
    LagerbestandListNestedUtils
} from "./lagerbestand-list-nested-utils";

@fwx.autoinject
export class LagerbestandListNestedForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_lagerbestandListNestedUtils: LagerbestandListNestedUtils) {
        super(element, formBaseImport);
        this.id = "lagerbestand-list-nested";
        this.title = "lagerbestand-list-nested.lagerbestand-list-nested_caption";
        this.addModel({
            "id": "$m_Lagerbestand",
            "webApiAction": "ERP/Lager/Lagerbestand",
            "webApiExpand": {
                'Artikel': null,
                'Lager': {
                    'expand': {
                        'Filiale': null
                    }
                }
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowLoad": "variables.data.$v_idArtikel || variables.data.$v_idLager",
            "custom": {
                'addFilter': true
            },
            "filters": [{
                "if": "variables.data.$v_idArtikel",
                "webApiWhere": ["Artikel.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idArtikel"
                }]
            }, {
                "if": "variables.data.$v_idLager",
                "webApiWhere": ["Lager.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idLager"
                }]
            }]
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addVariable({
            "id": "$v_idLager"
        });
        this.addVariable({
            "id": "$v_showFilterRow"
        });
        this.addVariable({
            "id": "$v_showExcelExport"
        });
        this.addFunction("$f_lagerbestandListNestedUtils", $f_lagerbestandListNestedUtils, "functions.$f_lagerbestandListNestedUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_lagbArtikelNummer",
                "caption": "lagerbestand-list-nested.r_lagbartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_lagbBezeichnung",
                "caption": "lagerbestand-list-nested.r_lagbbezeichnung_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbFiliale",
                "caption": "lagerbestand-list-nested.r_lagbfiliale_caption",
                "bindTo": "Lager.Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbLager",
                "caption": "lagerbestand-list-nested.r_lagblager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbBestand",
                "caption": "lagerbestand-list-nested.r_lagbbestand_caption",
                "bindTo": "Bestand",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbAuftrag",
                "caption": "lagerbestand-list-nested.r_lagbauftrag_caption",
                "bindTo": "Auftrag",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbReserviert",
                "caption": "lagerbestand-list-nested.r_lagbreserviert_caption",
                "bindTo": "Reserviert",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbBestellt",
                "caption": "lagerbestand-list-nested.r_lagbbestellt_caption",
                "bindTo": "Bestellt",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbProduktion",
                "caption": "lagerbestand-list-nested.r_lagbproduktion_caption",
                "bindTo": "Produktion",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lagerbestandGridToolbarOptions",
                "optionsNameFQ": "r_lagerbestandGridToolbarOptions"
            },
            "caption": "lagerbestand-list-nested.r_lagerbestandgrid_caption",
            "binding": {
                "dataContext": "$m_Lagerbestand"
            },
            "dataModel": "$m_Lagerbestand",
            "height": "auto",
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "bindTo": "$f_lagerbestandListNestedUtils.doExportExcelCommand",
                    "bindToFQ": "functions.$f_lagerbestandListNestedUtils.doExportExcelCommand"
                }
            }],
            "id": "r_lagerbestandGrid",
            "options": {
                "optionsName": "r_lagerbestandGridOptions",
                "optionsNameFQ": "r_lagerbestandGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}