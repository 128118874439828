import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class KonditionsfeldListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "konditionsfeld-list";
        this.title = "konditionsfeld-list.konditionsfeld-list_caption";
        this.addModel({
            "id": "$m_Konditionsfeld",
            "webApiAction": "ERP/Kalkulation/Konditionsfeld",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_konfCode",
                "caption": "konditionsfeld-list.r_konfcode_caption",
                "bindTo": "Code",
                "width": "100px"
            }, {
                "id": "r_konfBezeichnung",
                "caption": "konditionsfeld-list.r_konfbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_konditionsfeldGridToolbarOptions",
                "optionsNameFQ": "r_konditionsfeldGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Konditionsfeld"
            },
            "dataModel": "$m_Konditionsfeld",
            "editUrl": "ERP/Kalkulation/Konditionsfeld",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_konditionsfeldGrid",
            "options": {
                "optionsName": "r_konditionsfeldGridOptions",
                "optionsNameFQ": "r_konditionsfeldGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}