import { autoinject } from "aurelia-framework";
import { OnStatistikKennzahlen, OnStatistikKennzahlenEvent, StatistikKennzahlenTyp } from '../../../../framework-data/events';
import { LocationService, WebEventService } from '../../../../framework/base/export';
import { PopupInfoService } from '../../../../framework/forms/export';
import { EntitaetInfoService } from '../../../services/entitaet-info-service';
import { FirmaService } from "../../../services/firma-service";
import { IWidgetModel } from '../../interfaces/widget-model';
import { IKennzahlenSettings } from './kennzahlen-settings';

@autoinject
export class Kennzahlen {
  constructor(
    private _firmaService: FirmaService,
    private _webEventService: WebEventService,
    private _locationService: LocationService,
    private _entitaetInfoService: EntitaetInfoService,
    private _popupInfoService: PopupInfoService) { }

  widgetModel: IWidgetModel;
  settings: IKennzahlenSettings;
  firmaBezeichnung: string;
  itemList: any[];

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;
  }

  bind() {
    this.loadData();
  }

  async loadData() {
    const typList: StatistikKennzahlenTyp[] = [];

    if (this.settings.showOffeneBestellung) {
      typList.push(StatistikKennzahlenTyp.OffeneBestellungen);
    }
    if (this.settings.showOffeneWarenuebernahmen) {
      typList.push(StatistikKennzahlenTyp.OffeneWarenuebernahmen);
    }
    if (this.settings.showOffeneEingangsrechnungen) {
      typList.push(StatistikKennzahlenTyp.OffeneEingangsrechnungen);
    }
    if (this.settings.showOffeneAngebote) {
      typList.push(StatistikKennzahlenTyp.OffeneAngebote);
    }
    if (this.settings.showOffeneAuftraege) {
      typList.push(StatistikKennzahlenTyp.OffeneAuftraege);
    }
    if (this.settings.showOffeneLieferscheine) {
      typList.push(StatistikKennzahlenTyp.OffeneLieferscheine);
    }
    if (this.settings.showOffeneFakturen) {
      typList.push(StatistikKennzahlenTyp.OffeneFakturen);
    }
    if (this.settings.showOffenePosten) {
      typList.push(StatistikKennzahlenTyp.OffenePosten);
    }
    if (this.settings.showOffeneKundenreklamationen) {
      typList.push(StatistikKennzahlenTyp.OffeneKundenreklamationen);
    }
    if (this.settings.showOffeneProduktionen) {
      typList.push(StatistikKennzahlenTyp.OffeneProduktionen);
    }
    // if(this.settings.showAnzArtikel){
    //   list.push(StatistikKennzahlenTyp.AnzArtikel); //Noch nicht umgesetzt
    // }
    // if(this.settings.showNewsletterAktivierungen){
    //   list.push(StatistikKennzahlenTyp.AnzKunden);
    // }
    // if(this.settings.showNewsletterAktivierungen){
    //   list.push(StatistikKennzahlenTyp.AnzLieferanten);
    // }
    if (this.settings.showNewsletterAktivierungen) {
      typList.push(StatistikKennzahlenTyp.AnzNewsletterAnmeldung);
    }
    if (this.settings.showNewsletterAbmeldungen) {
      typList.push(StatistikKennzahlenTyp.AnzNewsletterAbmeldung);
    }

    const idGeschaeftspartner = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
      ? this.widgetModel.mainModel.Id
      : null;

    const idArtikel = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
      ? this.widgetModel.mainModel.Id
      : null;

    const result: OnStatistikKennzahlen = await this._webEventService.execute(new OnStatistikKennzahlenEvent({
      IdGeschaeftspartner: idGeschaeftspartner,
      IdArtikel: idArtikel,
      IdFirma: this.settings.idFirma,
      TypList: typList
    }));

    this.getFirmaBezeichnung();

    if (!result) {
      return;
    }

    this.itemList = result.ItemList;
    if ((this.itemList.length % 2) != 0) {
      this.itemList.push({});
    }
  }

  async onClick(item) {
    const url = await this._entitaetInfoService.getUrl(item.TypeName, null);
    if (!url) {
      return;
    }

    const popupClosed = await this._popupInfoService.closeAllPopups();
    if (!popupClosed) {
      return;
    }

    const customOptions: any = {};

    if (item.FilterInject) {
      customOptions.filterInject = {
        Bezeichnung: item.Bezeichnung,
        Where: item.FilterInject
      };
    }

    this._locationService.goTo({
      url: url,
      customOptions: customOptions
    });
  }

  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
