import { autoinject, transient } from "aurelia-framework";
import { WebEventService } from "../../../../framework/base/export";
import { LocalizationService } from "../../../../framework/base/services/localization-service";
import { LocationService } from "../../../../framework/base/services/location-service";
import { ContextMenu } from "../../../../framework/forms/classes/context-menu";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { DynFelderDataService, DynFelderService, FirmaService, GeschaeftspartnerService, ValidationExService } from "../../../services/export";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { OnDeleteIdFakturapositionListEvent, OnErstelleFakturaStorno, OnErstelleFakturaStornoEvent, OnFakturaAbschliessenEvent, OnFakturaBezahltAendernEvent, OnFakturaMahnen, OnFakturaMahnenEvent, OnKopierenRequest, OnKopierenRequestEvent } from "./../../../../framework-data/events";
import { AsyncService } from "./../../../../framework/base/services/async-service";
import { StartupService } from "./../../../services/startup-service";
import { BelegExportService } from "../../../services/beleg-export-service";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";

@autoinject
@transient()
export class FakturaEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderData: DynFelderDataService,
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _dynFelderService: DynFelderService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _localizationService: LocalizationService,
    private _locationService: LocationService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  //für Binding ZahlungEditForm benötigt
  id0: number = 0;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 999,
    isVisibleExpression: "functions.$f_FakturaEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_FakturaEditUtils.isBelegExportMandantAktiv && models.data.$m_Faktura.Id > 0 && models.data.$m_Faktura.Typ != 1 && models.data.$m_Faktura.StornoTyp == 0",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Faktura.Id;
      this._belegExportService.export(BelegImportTyp.Faktura, [id]);
    }
  };

  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "faktura",
    title: "faktura.abschliessen",
    icon: "check",
    isEnabledExpression: "models.data.$m_Faktura.Status && models.data.$m_Faktura.Status.Typ === 0",
    sortIndex: 998,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Faktura.TypeName,
        this.form.models.data.$m_Faktura.Id,
        "ABSCHLIESSEN"
      );

      if (!validationResult) {
        return;
      }

      await this._webEventService.execute(new OnFakturaAbschliessenEvent({
        IdFaktura: this.form.models.data.$m_Faktura.Id
      }), true);

      return this.form.models.reloadAll();
    }
  };
  doStornierenCommand: ICommandData = {
    id: "doStornieren",
    idCategory: "faktura",
    icon: "times-circle",
    title: "faktura.stornieren",
    sortIndex: 999,
    isVisibleExpression: "models.data.$m_Faktura.Status.Typ === 1",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_fakturapositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnErstelleFakturaStornoEvent({
        IdFaktura: this.form.models.data.$m_Faktura.Id,
        AllePositionen: selectedKeys.length === 0,
        IdFakturaPosList: selectedKeys
      });

      const webEventResult: OnErstelleFakturaStorno = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdStorno) {
        return;
      }

      this._locationService.goTo({
        url: `ERP/Verkauf/Faktura/${webEventResult.IdStorno}`,
        clearStack: false
      });
    }
  };
  doBezahltAendernCommand: ICommandData = {
    id: "doBezahltAendern",
    idCategory: "faktura",
    icon: "flag",
    title: "faktura.bezahlt_aendern",
    sortIndex: 1000,
    isVisibleExpression: "models.data.$m_Faktura.Status.Typ === 1",
    execute: async (e) => {
      const changeStatus = async (typ) => {
        const webEvent = new OnFakturaBezahltAendernEvent({
          IdFaktura: this.form.models.data.$m_Faktura.Id,
          Typ: typ
        });

        await this._webEventService.execute(webEvent, true);
        this.form.models.reloadAll();
      };

      const contextMenu = new ContextMenu();
      contextMenu.items.push({
        text: this._localizationService.translateOnce("faktura.bezahlt_offen"),
        execute: () => {
          changeStatus(0);
        }
      });
      contextMenu.items.push({
        text: this._localizationService.translateOnce("faktura.bezahlt_bezahlt"),
        execute: () => {
          changeStatus(1);
        }
      });
      contextMenu.items.push({
        text: this._localizationService.translateOnce("faktura.bezahlt_inkasso"),
        execute: () => {
          changeStatus(2);
        }
      });
      contextMenu.items.push({
        text: this._localizationService.translateOnce("faktura.bezahlt_uneinbringbar"),
        execute: () => {
          changeStatus(3);
        }
      });

      if (this.form.models.data.$m_Faktura.BezahltTyp == 0 && this.form.models.data.$m_Faktura.Status && this.form.models.data.$m_Faktura.Status.Typ == 1) {
        contextMenu.items.push({
          beginGroup: true,
          text: this._localizationService.translateOnce("faktura.zahlungseingang_erfassen"),
          execute: async () => {
            const saveResult = await this.form.saveIfDirty();
            if (!saveResult.isValid) {
              return;
            }

            this.form.editPopups.show("zahlungseingangEditPopup", null);
          }
        });
      }

      contextMenu.show(e.event && e.event.target ? e.event.target : null);
    }
  };
  doMahnenCommand: ICommandData = {
    id: "doMahnen",
    idCategory: "faktura",
    icon: "tags",
    title: "faktura.mahnen",
    sortIndex: 999,
    isVisibleExpression: "functions.$f_FakturaEditUtils.startupService.startupInfo.Lizenz.HasMahnung && models.data.$m_Faktura.Status.Typ === 1 && models.data.$m_Faktura.BezahltTyp == 0 && models.data.$m_Faktura.WertBrutto > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const onFakturaMahnenEvent = new OnFakturaMahnenEvent({
        IdFaktura: this.form.models.data.$m_Faktura.Id
      });

      const result: OnFakturaMahnen = await this._webEventService.execute(onFakturaMahnenEvent, true);
      if (result.IdMahnung) {
        this._locationService.goTo({
          url: "ERP/Verkauf/Mahnung/".concat(result.IdMahnung.toString()),
          clearStack: false
        });
      }
    }
  };
  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Faktura.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_fakturapositionGrid"];
      const fakturaPosGridRowData = grid.instance.getSelectedRowsData();

      if (fakturaPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("faktura.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const faktura of fakturaPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          faktura.TypeName,
          faktura.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const faktura of fakturaPosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: faktura.Id,
          CustomData: { "PosNrUeberschreiben": true }
        }),
          true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Faktura.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_fakturapositionGrid"];
      const fakturaPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (fakturaPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("faktura.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (fakturaPosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("faktura.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const fakturaposition of fakturaPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          fakturaposition.TypeName,
          fakturaposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = fakturaPosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdFakturapositionListEvent({
        IdFakturapositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const rechnungsort = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_rechnungsort'] .dx-searchbox input");
      if (!rechnungsort) {
        return;
      }

      rechnungsort.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Faktura",
      idVorgEntitaetPropertyName: "IdRechnungsort"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Faktura",
      kalkulationRef: "r_kalkulation",
      editPopups: ["fakturapositionEditPopup", "fakturagruppeEditPopup"]
    });

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Faktura") {
        return;
      }

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Faktura);

      return Promise.resolve();
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_FakturapositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "fakturapositionEditPopup") {
        form["r_fakturagruppeGrid"].instance.refresh();
      } else if (r.editPopup.id == "fakturagruppeEditPopup") {
        form["r_fakturapositionGrid"].instance.refresh();
      } else if (r.editPopup.id == "zahlungseingangEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Faktura") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Faktura"];
          if (model) {
            model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(args.value);
          }
        }
      }
    });
  }

  async onRechnungsortChanged() {
    await this.dynFelderData.loadVorbelegungen();

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }

  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Faktura.CanSave && this.form.models.data.$m_Faktura.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Faktura.IdRechnungsort);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Faktura.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Faktura",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
