import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuKontogruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-kontogruppe-edit";
        this.title = "fibu-kontogruppe-edit.fibu-kontogruppe-edit_caption";
        this.addModel({
            "id": "$m_FibuKontogruppe",
            "webApiAction": "ERP/Fibu/Kontogruppe",
            "webApiExpand": {
                'Firma': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "fibu-kontogruppe-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_FibuKontogruppe",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_FibuKontogruppe.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuKontoTyp",
            "filters": [],
            "caption": "fibu-kontogruppe-edit.r_kontotyp_caption",
            "binding": {
                "dataContext": "$m_FibuKontogruppe",
                "bindTo": "KontoTyp",
                "bindToFQ": "models.data.$m_FibuKontogruppe.KontoTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_kontoTyp",
            "options": {
                "optionsName": "r_kontoTypOptions",
                "optionsNameFQ": "r_kontoTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuSollHaben",
            "filters": [],
            "caption": "fibu-kontogruppe-edit.r_sollhaben_caption",
            "binding": {
                "dataContext": "$m_FibuKontogruppe",
                "bindTo": "SollHaben",
                "bindToFQ": "models.data.$m_FibuKontogruppe.SollHaben"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sollHaben",
            "options": {
                "optionsName": "r_sollHabenOptions",
                "optionsNameFQ": "r_sollHabenOptions"
            }
        });
        super.onConstructionFinished();
    }
}