import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";

import {
  IAdresse
} from "../interfaces/export";

@autoinject
export class AdresseService {
  constructor(
    private rest: RestService
  ) { }

  getAdresseById(id: number): Promise<IAdresse> {
    return this.rest.get({
      url: `${this.rest.getWebApiUrl("ERP/Stammdaten/Adresse")}/${id != void 0 ? id : 0}`
    });
  }
}