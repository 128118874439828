import { ListView } from './../../../framework/forms/elements/list-view/list-view';
import {
  autoinject,
  OverrideContext,
  Scope
} from "aurelia-framework";
import {
  EventAggregator, Subscription
} from 'aurelia-event-aggregator';

import {
  DataSourceService,
  ScopeContainer,
  LocalizationService,
  WebEventService,
  IViewScrollInfo
} from "../../../framework/base/export";
import {
  OnMailImportItemAnzahlRequestEvent
} from "../../../framework-data/events";
import {
  ICommandData
} from "../../../framework/forms/export";
import {
  IListViewOptions
} from "../../../framework/forms/elements/list-view/list-view-options";

import {
  HeaderCommandService
} from "../../services/export";

import {
  IdxPopoverComponent
} from "../../interfaces/export";
import {
  HeaderCommandEvent,
  NotificationTyp
} from "../../enumerations/export";
import { Statustyp } from "../../enumerations/status-typ";
import { LocationService } from '../../../framework/base/services/location-service';

@autoinject
export class MailCommand {
  private _commandShowingSubscription: Subscription;
  private _anzahlChangedSubscription: Subscription;

  constructor(
    private _dataSourceService: DataSourceService,
    private _eventAggregator: EventAggregator,
    private _headerCommandService: HeaderCommandService,
    private _localizationService: LocalizationService,
    private _webEventService: WebEventService,
    private _locationService: LocationService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  mail: IdxPopoverComponent;
  mailOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400
  };

  mailListView: ListView;
  mailListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const mail = e.item;
      this.goToMail(mail);
    }
  }

  showAllMailButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("form-command.mail-command-alle-anzeigen"),
    width: "100%",
    onClick: this.onAlleAnzeigenClicked.bind(this)
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.mailListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/MailImportItem",
        webApiWhere: ["Status.Typ", Statustyp.Offen],
        webApiOrderBy: [{
          columnName: "Status.SortNr", 
          sortOrder: 0
        }, {
          columnName: "Datum", 
          sortOrder: 0
        }],
        filters: [{ 
          webApiCustomKey: "InklIdStatusFarbe", 
          webApiCustomValue: "true" 
        }, {
          webApiCustomKey: "NurEigene", 
          webApiCustomValue: "true" 
        }, {
          webApiCustomKey: "AddTags", 
          webApiCustomValue: "true" 
        }]
      },
    );

    this.loadAnzahl();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    this._headerCommandService.mail = this;

    this._anzahlChangedSubscription = this._eventAggregator
      .subscribe(
      NotificationTyp.mailAnzahlChanged,
      this.loadAnzahl.bind(this)
      );

    this._commandShowingSubscription = this._eventAggregator
      .subscribe(
      HeaderCommandEvent.onShowingPopover,
      (command: ICommandData) => {
        if (this._headerCommandService.mailCommand.id != command.id) {
          this.mail.instance.hide();
        }
      }
      );
  }
  detached() {
    this._anzahlChangedSubscription.dispose();
    this._commandShowingSubscription.dispose();
  }

  showPopover(targetElement: Element) {
    if (this.mailListView) {
      this.mailListView.refresh(false);
    }

    this.mail.instance.show(targetElement);
    this.mailListView.scrollToTop();
  }

  private onAlleAnzeigenClicked() {
    this.goToMailList();
    this.mail.instance.hide();
  }
  private goToMailList() {
    this._locationService.goTo({
      url: "ERP/Stammdaten/MailImportItem",
      clearStack: false
    });
  }

  private goToMail(mail: any) {
    this.mail.instance.hide();
    this._locationService.goTo({
      url: `ERP/Stammdaten/MailImportItem/${mail.Id}`,
      clearStack: false,
      viewScrollInfo: this.getViewScrollInfo(mail)
    });
  }  
  private getViewScrollInfo(mail): IViewScrollInfo {
    const dataSource = this.mailListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(mail);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
  
  private loadAnzahl() {
    const mailEvent = new OnMailImportItemAnzahlRequestEvent({ Anzahl: 0 });
    this._webEventService
      .execute(mailEvent)
      .then(r => {
        if (r == void 0 || r.Anzahl == 0) {
          this._headerCommandService.mailCommand.badgeText = null;
          return;
        } else {
          this._headerCommandService.mailCommand.badgeText = {
            key: "base.param_1",
            parameters: [r.Anzahl]
          };
        }
      });
  }
}
