import * as fwx from "../../../../framework/forms/form-export";
import {
    EinstellungEditUtils
} from "./einstellung-edit-utils";

@fwx.autoinject
export class EinstellungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_Einstellung: EinstellungEditUtils) {
        super(element, formBaseImport);
        this.id = "einstellung-edit";
        this.title = "einstellung-edit.einstellung-edit_caption";
        this.addModel({
            "id": "$m_Einstellung",
            "webApiAction": "ERP/Cloud/Einstellung",
            "webApiExpand": {
                'EinstellungTicketStatusZuBenutzer': null,
                'EinstellungFehlerStatusZuBenutzer': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$idEinstellung",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": []
        });
        this.addVariable({
            "id": "$idEinstellung"
        });
        this.addFunction("$f_Einstellung", $f_Einstellung, "functions.$f_Einstellung");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "einstellung-edit.r_mailabsender_caption",
            "binding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "MailAbsender",
                "bindToFQ": "models.data.$m_Einstellung.MailAbsender"
            },
            "validationRules": [{
                "item": {
                    "type": "email",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_mailAbsender",
            "options": {
                "optionsName": "r_mailAbsenderOptions",
                "optionsNameFQ": "r_mailAbsenderOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "mode": "password",
            "caption": "einstellung-edit.r_mailgatewayapikey_caption",
            "binding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "MailGatewayApiKey",
                "bindToFQ": "models.data.$m_Einstellung.MailGatewayApiKey"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_mailGatewayApiKey",
            "options": {
                "optionsName": "r_mailGatewayApiKeyOptions",
                "optionsNameFQ": "r_mailGatewayApiKeyOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "einstellung-edit.r_checkticketstatus_caption",
            "binding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "CheckTicketStatus",
                "bindToFQ": "models.data.$m_Einstellung.CheckTicketStatus"
            },
            "validationRules": [],
            "id": "r_checkTicketstatus",
            "options": {
                "optionsName": "r_checkTicketstatusOptions",
                "optionsNameFQ": "r_checkTicketstatusOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "einstellung-edit.r_ticketstatus_caption",
            "dataContext": "$m_Einstellung",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "EinstellungTicketStatusZuBenutzer",
                "bindToFQ": "models.data.$m_Einstellung.EinstellungTicketStatusZuBenutzer"
            },
            "relationProperty": "IdBenutzer",
            "editorOptions": {
                "editorTypeName": "select",
                "idSelect": "benutzer",
                "filter": null,
                "customs": [],
                "caption": "Benutzer",
                "isReadOnly": null,
                "isReadOnlyExpression": null,
                "isDisabled": null,
                "isDisabledExpression": null,
                "placeholder": null,
                "informationButtonHint": null
            },
            "binding": {
                "dataContext": "$m_Einstellung"
            },
            "validationRules": [],
            "id": "r_ticketStatus",
            "options": {
                "optionsName": "r_ticketStatusOptions",
                "optionsNameFQ": "r_ticketStatusOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "einstellung-edit.r_checkfehlerstatus_caption",
            "binding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "CheckFehlerStatus",
                "bindToFQ": "models.data.$m_Einstellung.CheckFehlerStatus"
            },
            "validationRules": [],
            "id": "r_checkFehlerstatus",
            "options": {
                "optionsName": "r_checkFehlerstatusOptions",
                "optionsNameFQ": "r_checkFehlerstatusOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "einstellung-edit.r_fehlerstatus_caption",
            "dataContext": "$m_Einstellung",
            "batchSelectionEnabled": true,
            "relationBinding": {
                "dataContext": "$m_Einstellung",
                "bindTo": "EinstellungFehlerStatusZuBenutzer",
                "bindToFQ": "models.data.$m_Einstellung.EinstellungFehlerStatusZuBenutzer"
            },
            "relationProperty": "IdBenutzer",
            "editorOptions": {
                "editorTypeName": "select",
                "idSelect": "benutzer",
                "filter": null,
                "customs": [],
                "caption": "Benutzer",
                "isReadOnly": null,
                "isReadOnlyExpression": null,
                "isDisabled": null,
                "isDisabledExpression": null,
                "placeholder": null,
                "informationButtonHint": null
            },
            "binding": {
                "dataContext": "$m_Einstellung"
            },
            "validationRules": [],
            "id": "r_fehlerStatus",
            "options": {
                "optionsName": "r_fehlerStatusOptions",
                "optionsNameFQ": "r_fehlerStatusOptions"
            }
        });
        super.onConstructionFinished();
    }
}