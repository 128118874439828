import {
  autoinject,
  transient,
  TaskQueue
} from "aurelia-framework";
import {
  AuthorizationService, LocalizationService
} from "../../../base/export";
import { 
  FormBase,
  ICommandData
} from "../../../forms/export";
import { LocationService } from '../../../base/services/location-service';
import { HistoryService } from '../../../forms/services/history-service';

@autoinject
@transient()
export class LoginFuncs {
  private form: FormBase;
  
  constructor(
    private _authorizationService: AuthorizationService,
    private _historyService: HistoryService,
    private _locationService: LocationService,
    private _localization: LocalizationService,
    private _taskQueue: TaskQueue
  ) { }

  goToUrlAfterLogin: string;
  showTwoFactorAuth = false;

  loginCommand: ICommandData = {
    id: "$login",
    title: "login-form-funcs.anmelden_caption",
    execute: async (e: any) => {
      const event = e && e.event
        ? e.event
        : (e.preventDefault ? e : null);

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (!this.form.models.data.$m_login.Username || !this.form.models.data.$m_login.Password) {
        DevExpress.ui.notify(this._localization.translateOnce("login-form-funcs.benutzer_passwort_angeben"), "error", 3000);
        return;
      }

      const r = await this._authorizationService.login(this.form.models.data.$m_login);

      if (r.isLoggedIn && this.goToUrlAfterLogin) {
        this._historyService.pipelineUrl = this.goToUrlAfterLogin;
      } else if (r.needsTwoFactorAuthKey) {
        this.showTwoFactorAuth = true;

        this._taskQueue.queueTask(() => {
          const keyInput = this.form["twoFactorKey"];
          if (keyInput && keyInput.instance) {
            keyInput.instance.focus();
          }
        });
      }
    }
  };
  
  forgotPasswordCommand: ICommandData = {
      id: "$reset",
      title: "login-form-funcs.vergessen_caption",
      execute: () => {
        this._locationService.goTo({
          url: "reset-password",
          clearStack: true
        });
      }
  };

  goBackToLoginCommand: ICommandData = {
    id: "$reset",
    title: "login-form-funcs.zurueck_zu_login",
    execute: () => {
      this.showTwoFactorAuth = false;
    }
};

  bind(form: FormBase) {
    this.form = form;

    this.goToUrlAfterLogin = this._historyService.lastRequestUrl;

    form.models.data.$m_login = {
      StayLoggedOn: false
    };
  }
}
