import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    GeschaeftspartnerListUtils
} from "./geschaeftspartner-list-utils";

@fwx.autoinject
export class GeschaeftspartnerListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_GeschaeftspartnerListUtils: GeschaeftspartnerListUtils) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-list";
        this.title = "geschaeftspartner-list.geschaeftspartner-list_caption";
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ERP/Stammdaten/Geschaeftspartner",
            "webApiExpand": {
                'Adresse': {
                    'expand': {
                        'Land': null
                    }
                },
                'Geschaeftspartnerart': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_geschaeftspartnerGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerListUtils.doExport",
                "bindToFQ": "functions.$f_GeschaeftspartnerListUtils.doExport"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_GeschaeftspartnerListUtils", $f_GeschaeftspartnerListUtils, "functions.$f_GeschaeftspartnerListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_gespNummer",
                "caption": "geschaeftspartner-list.r_gespnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_gespArt",
                "caption": "geschaeftspartner-list.r_gespart_caption",
                "bindTo": "Geschaeftspartnerart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_gespFirmenbez1",
                "caption": "geschaeftspartner-list.r_gespfirmenbez1_caption",
                "bindTo": "Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_gespFirmenbez2",
                "caption": "geschaeftspartner-list.r_gespfirmenbez2_caption",
                "bindTo": "Adresse.Firmenbez2",
                "minWidth": 100
            }, {
                "id": "r_gespStrasse",
                "caption": "geschaeftspartner-list.r_gespstrasse_caption",
                "bindTo": "Adresse.Strasse",
                "minWidth": 100
            }, {
                "id": "r_gespLand",
                "caption": "geschaeftspartner-list.r_gespland_caption",
                "bindTo": "Adresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_gespPlz",
                "caption": "geschaeftspartner-list.r_gespplz_caption",
                "bindTo": "Adresse.PLZ",
                "width": "80px"
            }, {
                "id": "r_gespOrt",
                "caption": "geschaeftspartner-list.r_gesport_caption",
                "bindTo": "Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_gespStatus",
                "caption": "geschaeftspartner-list.r_gespstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_geschaeftspartnerGridToolbarOptions",
                "optionsNameFQ": "r_geschaeftspartnerGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "dataModel": "$m_Geschaeftspartner",
            "editUrl": "ERP/Stammdaten/Geschaeftspartner",
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_geschaeftspartnerGrid",
            "options": {
                "optionsName": "r_geschaeftspartnerGridOptions",
                "optionsNameFQ": "r_geschaeftspartnerGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}