import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    KundenreklamationspositionEditUtils
} from "./kundenreklamationsposition-edit-utils";

@fwx.autoinject
export class KundenreklamationspositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_KundenreklamationspositionEditUtils: KundenreklamationspositionEditUtils) {
        super(element, formBaseImport);
        this.id = "kundenreklamationsposition-edit";
        this.title = "kundenreklamationsposition-edit.kundenreklamationsposition-edit_caption";
        this.addModel({
            "id": "$m_Kundenreklamationsposition",
            "webApiAction": "ERP/Verkauf/Kundenreklamationsposition",
            "webApiExpand": {
                'Lieferscheinposition': {
                    'expand': {
                        'Lieferschein': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_Kundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Kundenreklamationsposition.IdKundenreklamation",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_KundenreklamationspositionStuecklisteRel",
            "webApiAction": "ERP/Verkauf/Kundenreklamationsposition",
            "webApiExpand": {
                'Lieferscheinposition': {
                    'expand': {
                        'Lieferschein': null
                    }
                },
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_KundenreklamationspositionStuecklisteEdit",
            "webApiAction": "ERP/Verkauf/Kundenreklamationsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addEditPopup({
            "idContent": "kundenreklamationspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_KundenreklamationspositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_KundenreklamationspositionStuecklisteEdit.Id"
                }
            }],
            "id": "kundenreklamationspositionEditPopup",
            "options": {
                "optionsName": "kundenreklamationspositionEditPopupOptions",
                "optionsNameFQ": "kundenreklamationspositionEditPopupOptions"
            },
            "caption": "kundenreklamationsposition-edit.kundenreklamationspositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_KundenreklamationspositionEditUtils", $f_KundenreklamationspositionEditUtils, "functions.$f_KundenreklamationspositionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kundenreklamationsposition-edit.r_mengeverpeh_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "MengeVerpEH",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.MengeVerpEH"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_KundenreklamationspositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_mengeVerpEH",
            "options": {
                "optionsName": "r_mengeVerpEHOptions",
                "optionsNameFQ": "r_mengeVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kundenreklamationsposition-edit.r_mengegutschriftverpeh_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "MengeGutschriftVerpEH",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.MengeGutschriftVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeGutschriftVerpEH",
            "options": {
                "optionsName": "r_mengeGutschriftVerpEHOptions",
                "optionsNameFQ": "r_mengeGutschriftVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kundenreklamationsposition-edit.r_mengeersatzverpeh_caption",
            "isReadOnlyExpression": "models.data.$m_Kundenreklamationsposition.IdErsatzAuftragsposition || models.data.$m_Kundenreklamationsposition.IdErsatzLieferscheinposition",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "MengeErsatzVerpEH",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.MengeErsatzVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeErsatzVerpEH",
            "options": {
                "optionsName": "r_mengeErsatzVerpEHOptions",
                "optionsNameFQ": "r_mengeErsatzVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kundenreklamationsposition-edit.r_mengeersatzverrverpeh_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "MengeErsatzVerrVerpEH",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.MengeErsatzVerrVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeErsatzVerrVerpEH",
            "options": {
                "optionsName": "r_mengeErsatzVerrVerpEHOptions",
                "optionsNameFQ": "r_mengeErsatzVerrVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": [
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Kundenreklamationsposition.IdArtikel"
                }],
                ["IsVerkaufseinheit", true]
            ],
            "filters": [],
            "caption": "kundenreklamationsposition-edit.r_einheit_caption",
            "isReadOnlyExpression": "models.data.$m_Kundenreklamationsposition.IdErsatzAuftragsposition || models.data.$m_Kundenreklamationsposition.IdErsatzLieferscheinposition",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_KundenreklamationspositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "kundenreklamationsposition-edit.r_iswarenruecksendungerforderlich_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "IsWarenruecksendungErforderlich",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.IsWarenruecksendungErforderlich"
            },
            "validationRules": [],
            "id": "r_isWarenruecksendungErforderlich",
            "options": {
                "optionsName": "r_isWarenruecksendungErforderlichOptions",
                "optionsNameFQ": "r_isWarenruecksendungErforderlichOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_reklpArtikelNummer",
                "caption": "kundenreklamationsposition-edit.r_reklpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_reklpBezeichnug",
                "caption": "kundenreklamationsposition-edit.r_reklpbezeichnug_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_reklpLieferscheinNummer",
                "caption": "kundenreklamationsposition-edit.r_reklplieferscheinnummer_caption",
                "bindTo": "Lieferscheinposition.Lieferschein.Nummer",
                "width": "80px"
            }, {
                "id": "r_reklpMengelVerpEH",
                "caption": "kundenreklamationsposition-edit.r_reklpmengelverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpMengeRetourniertVerpEH",
                "caption": "kundenreklamationsposition-edit.r_reklpmengeretourniertverpeh_caption",
                "bindTo": "MengeRetourniertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpMengeGutschriftVerpEH",
                "caption": "kundenreklamationsposition-edit.r_reklpmengegutschriftverpeh_caption",
                "bindTo": "MengeGutschriftVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_reklpEinheit",
                "caption": "kundenreklamationsposition-edit.r_reklpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_kundenreklamationspositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_kundenreklamationspositionStuecklistenGridToolbarOptions"
            },
            "caption": "kundenreklamationsposition-edit.r_kundenreklamationspositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_KundenreklamationspositionStuecklisteRel",
                "bindTo": "IdSetartikelKundenreklamationsposition",
                "bindToFQ": "models.data.$m_KundenreklamationspositionStuecklisteRel.IdSetartikelKundenreklamationsposition"
            },
            "dataModel": "$m_Kundenreklamationsposition",
            "editDataContext": "$m_KundenreklamationspositionStuecklisteEdit",
            "idEditPopup": "kundenreklamationspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kundenreklamationspositionStuecklistenGrid",
            "options": {
                "optionsName": "r_kundenreklamationspositionStuecklistenGridOptions",
                "optionsNameFQ": "r_kundenreklamationspositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "kundenreklamationsposition-edit.r_mengeretourverpeh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "MengeRetourniertVerpEH",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.MengeRetourniertVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeRetourVerpEH",
            "options": {
                "optionsName": "r_mengeRetourVerpEHOptions",
                "optionsNameFQ": "r_mengeRetourVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "kundenreklamationsposition-edit.r_lieferscheinpositionnummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "Lieferscheinposition.Lieferschein.Nummer",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.Lieferscheinposition.Lieferschein.Nummer",
                "propertyPrefix": "Lieferscheinposition.Lieferschein"
            },
            "validationRules": [],
            "id": "r_lieferscheinpositionNummer",
            "options": {
                "optionsName": "r_lieferscheinpositionNummerOptions",
                "optionsNameFQ": "r_lieferscheinpositionNummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "reklamationsgrund",
            "filters": [],
            "caption": "kundenreklamationsposition-edit.r_reklamationsgrund_caption",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "IdReklamationsgrund",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.IdReklamationsgrund"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_reklamationsgrund",
            "options": {
                "optionsName": "r_reklamationsgrundOptions",
                "optionsNameFQ": "r_reklamationsgrundOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "kundenreklamationErsatzartikelPreisbasis",
            "filters": [],
            "caption": "kundenreklamationsposition-edit.r_ersatzartikelpreisbasis_caption",
            "isReadOnlyExpression": "models.data.$m_Kundenreklamationsposition.IdErsatzAuftragsposition || models.data.$m_Kundenreklamationsposition.IdErsatzLieferscheinposition || !models.data.$m_Kundenreklamationsposition.IdErsatzartikel",
            "binding": {
                "dataContext": "$m_Kundenreklamationsposition",
                "bindTo": "ErsatzartikelPreisbasis",
                "bindToFQ": "models.data.$m_Kundenreklamationsposition.ErsatzartikelPreisbasis"
            },
            "validationRules": [{
                "item": {
                    "type": "conditionalRequired",
                    "parameters": [{
                        "name": "condition",
                        "value": "models.data.$m_Kundenreklamationsposition.IdErsatzartikel"
                    }]
                }
            }],
            "id": "r_ersatzartikelPreisbasis",
            "options": {
                "optionsName": "r_ersatzartikelPreisbasisOptions",
                "optionsNameFQ": "r_ersatzartikelPreisbasisOptions"
            }
        });
        super.onConstructionFinished();
    }
}