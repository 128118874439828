import {
  autoinject,
  observable
} from "aurelia-framework";
import {
  CustomEvent,
} from "../../base/export";
import {
  ICommandData
} from "../../forms/export";
import { Header } from "../views/header/header";

declare var require: any;

const logo = require("../../images/logo_tip.png");
const user = require("../../images/user.jpg");

@autoinject
export class HeaderService {
  constructor() {
    this.avatarUrlChanged();
  }

  logoUrl: string = logo.default || logo;
  @observable avatarUrl: string;
  text: string = "TIP Technik und Informatik Partner GmbH & Co KG";
  logoStyle: any;
  avatarStyle: any;
  header: Header;
  isSearchRegistered: boolean;

  commands: ICommandData[] = [];
  avatarCommands: ICommandData[] = [];

  avatarUrlChanged() {
    const image = this.avatarUrl || user.default || user;

    this.avatarStyle = {
      "background-image": `url(${image})`
    }
  }
  hideAvatarPopoper(){
    const popover = this.header.avatarPopover;
    if(!popover || !popover.instance){
      return;
    }
    
    this.header.avatarPopover.instance.hide();
  }
  
  setLogoUrl(logoUrl: string) {
    this.logoUrl = logoUrl || logo.default || logo;
  }
}
