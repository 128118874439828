import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    FakturapositionEditUtils
} from "./fakturaposition-edit-utils";

@fwx.autoinject
export class FakturapositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_FakturapositionEditUtils: FakturapositionEditUtils) {
        super(element, formBaseImport);
        this.id = "fakturaposition-edit";
        this.title = "fakturaposition-edit.fakturaposition-edit_caption";
        this.addModel({
            "id": "$m_Fakturaposition",
            "webApiAction": "ERP/Verkauf/Fakturaposition",
            "webApiExpand": {
                'Artikel': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Faktura.CanSave && !models.data.$m_Faktura.Typ && models.data.$m_Fakturaposition.IsPositionStuecklistenkomponente == false",
            "custom": {
                'copyFunc': '$f_FakturapositionEditUtils.copyFakturaposition'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Faktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Fakturaposition.IdFaktura",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturagruppeEdit",
            "webApiAction": "ERP/Verkauf/Fakturagruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturapositionStuecklisteRel",
            "webApiAction": "ERP/Verkauf/Fakturaposition",
            "webApiExpand": {
                'Artikel': null,
                'KalkulationContainer': null,
                'Fakturagruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
                'FakturapositionStuecklisteInfo': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturapositionStuecklisteEdit",
            "webApiAction": "ERP/Verkauf/Fakturaposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturapositionEditUtils.showVariablerSetartikelKomponentenAuswahlCommand",
                "bindToFQ": "functions.$f_FakturapositionEditUtils.showVariablerSetartikelKomponentenAuswahlCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturapositionEditUtils.showVariablerSetartikelPreisAendernCommand",
                "bindToFQ": "functions.$f_FakturapositionEditUtils.showVariablerSetartikelPreisAendernCommand"
            }
        });
        this.addEditPopup({
            "idContent": "fakturapositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_FakturapositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_FakturapositionStuecklisteEdit.Id"
                }
            }],
            "id": "fakturapositionEditPopup",
            "options": {
                "optionsName": "fakturapositionEditPopupOptions",
                "optionsNameFQ": "fakturapositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "fakturagruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_FakturagruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_FakturagruppeEdit.Id"
                }
            }],
            "id": "fakturagruppeEditPopup",
            "options": {
                "optionsName": "fakturagruppeEditPopupOptions",
                "optionsNameFQ": "fakturagruppeEditPopupOptions"
            },
            "caption": "fakturaposition-edit.fakturagruppeeditpopup_caption",
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_FakturapositionEditUtils", $f_FakturapositionEditUtils, "functions.$f_FakturapositionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "fakturaposition-edit.r_mengesollverpeh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "MengeSollVerpEH",
                "bindToFQ": "models.data.$m_Fakturaposition.MengeSollVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeSollVerpEH",
            "options": {
                "optionsName": "r_mengeSollVerpEHOptions",
                "optionsNameFQ": "r_mengeSollVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "fakturaposition-edit.r_mengeistverpeh_caption",
            "isReadOnlyExpression": "$m_IsVariablerSetartikel && $m_Lieferscheinposition",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "MengeIstVerpEH",
                "bindToFQ": "models.data.$m_Fakturaposition.MengeIstVerpEH"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_FakturapositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_mengeIstVerpEH",
            "options": {
                "optionsName": "r_mengeIstVerpEHOptions",
                "optionsNameFQ": "r_mengeIstVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": [
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Fakturaposition.IdArtikel"
                }],
                ["IsVerkaufseinheit", true]
            ],
            "filters": [],
            "caption": "fakturaposition-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Fakturaposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_FakturapositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_faktpPosNr",
                "caption": "fakturaposition-edit.r_faktpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_faktpArtikelNr",
                "caption": "fakturaposition-edit.r_faktpartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_faktpBezeichnung",
                "caption": "fakturaposition-edit.r_faktpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_faktpMengeIstVerpEH",
                "caption": "fakturaposition-edit.r_faktpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_faktpEinheit",
                "caption": "fakturaposition-edit.r_faktpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_faktpFaktor",
                "caption": "fakturaposition-edit.r_faktpfaktor_caption",
                "bindTo": "FakturapositionStuecklisteInfo.Faktor",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.StuecklisteFaktorTyp"
            }, {
                "id": "r_faktpWertNetto",
                "caption": "fakturaposition-edit.r_faktpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_faktpWertSteuer",
                "caption": "fakturaposition-edit.r_faktpwertsteuer_caption",
                "bindTo": "KalkulationContainer.WertSteuer",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_faktpWertBrutto",
                "caption": "fakturaposition-edit.r_faktpwertbrutto_caption",
                "bindTo": "KalkulationContainer.WertBrutto",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_fakturapositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_fakturapositionStuecklistenGridToolbarOptions"
            },
            "caption": "fakturaposition-edit.r_fakturapositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_FakturapositionStuecklisteRel",
                "bindTo": "IdSetartikelFakturaposition",
                "bindToFQ": "models.data.$m_FakturapositionStuecklisteRel.IdSetartikelFakturaposition"
            },
            "dataModel": "$m_Fakturaposition",
            "editDataContext": "$m_FakturapositionStuecklisteEdit",
            "idEditPopup": "fakturapositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fakturapositionStuecklistenGrid",
            "options": {
                "optionsName": "r_fakturapositionStuecklistenGridOptions",
                "optionsNameFQ": "r_fakturapositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "fakturaposition-edit.r_isvariablersetartikel_caption",
            "isReadOnlyExpression": "models.data.$m_Fakturaposition.Id > 0",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "IsVariablerSetartikel",
                "bindToFQ": "models.data.$m_Fakturaposition.IsVariablerSetartikel"
            },
            "validationRules": [],
            "id": "r_isVariablerSetartikel",
            "options": {
                "optionsName": "r_isVariablerSetartikelOptions",
                "optionsNameFQ": "r_isVariablerSetartikelOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fakturagruppe",
            "filter": ["IdFaktura", {
                "isBound": true,
                "expression": "models.data.$m_Fakturaposition.IdFaktura"
            }],
            "filters": [],
            "caption": "fakturaposition-edit.r_fakturagruppe_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "IdFakturagruppe",
                "bindToFQ": "models.data.$m_Fakturaposition.IdFakturagruppe"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_FakturapositionEditUtils.onFakturagruppeChanged",
            "id": "r_fakturagruppe",
            "options": {
                "optionsName": "r_fakturagruppeOptions",
                "optionsNameFQ": "r_fakturagruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "fakturaposition-edit.r_posnr_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Fakturaposition.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "fakturaposition-edit.r_kundeartikelnr_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "KundeArtikelNr",
                "bindToFQ": "models.data.$m_Fakturaposition.KundeArtikelNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_kundeArtikelNr",
            "options": {
                "optionsName": "r_kundeArtikelNrOptions",
                "optionsNameFQ": "r_kundeArtikelNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "fakturaposition-edit.r_kundeartikelbezeichnung_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "KundeArtikelbezeichnung",
                "bindToFQ": "models.data.$m_Fakturaposition.KundeArtikelbezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_kundeArtikelbezeichnung",
            "options": {
                "optionsName": "r_kundeArtikelbezeichnungOptions",
                "optionsNameFQ": "r_kundeArtikelbezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "positionsart",
            "filters": [],
            "caption": "fakturaposition-edit.r_positionsart_caption",
            "binding": {
                "dataContext": "$m_Fakturaposition",
                "bindTo": "IdPositionsart",
                "bindToFQ": "models.data.$m_Fakturaposition.IdPositionsart"
            },
            "validationRules": [],
            "id": "r_positionsart",
            "options": {
                "optionsName": "r_positionsartOptions",
                "optionsNameFQ": "r_positionsartOptions"
            }
        });
        super.onConstructionFinished();
    }
}