import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase
} from "../../../../framework/forms/export";
import { ExcelExportService } from "../../../../framework/forms/services/excel-export-service";

@autoinject
@transient()
export class LagerbestandDetailListNestedUtils {
  constructor(
    private _excelExportService: ExcelExportService) { }

  form: FormBase;

  doExportExcelCommand: ICommandData = {
    id: "doExportExcel",
    icon: "file-excel-o",
    title: "base.excel-export",
    isVisibleExpression: "variables.data.$v_showExcelExport",
    execute: () => {
      const grid: DevExpress.ui.dxDataGrid = this.form["r_lagerbestandGrid"].instance;
      this._excelExportService.exportDataGrid({
        component: grid,
        selectedRowsOnly: false
      });
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.callOnBind(() => {
      const gridOptions: DevExpress.ui.dxDataGridOptions = this.form["r_lagerbestandGridOptions"];

      gridOptions.bindingOptions = gridOptions.bindingOptions || {};
      gridOptions.bindingOptions["filterRow.visible"] = "variables.data.$v_showFilterRow";
    });
  }
}
