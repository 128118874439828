import * as fwx from "../../../../framework/forms/form-export";
import {
    WarenuebernahmepositionEditUtils
} from "./warenuebernahmeposition-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class WarenuebernahmepositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_WarenuebernahmepositionEditUtils: WarenuebernahmepositionEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "warenuebernahmeposition-edit";
        this.title = "warenuebernahmeposition-edit.warenuebernahmeposition-edit_caption";
        this.addModel({
            "id": "$m_Warenuebernahmeposition",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "webApiExpand": {
                'Artikel': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Warenuebernahme.CanSave",
            "custom": {
                'copyFunc': '$f_WarenuebernahmepositionEditUtils.copyWarenuebernahmeposition'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Warenuebernahme",
            "webApiAction": "ERP/Einkauf/Warenuebernahme",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Warenuebernahmeposition.IdWarenuebernahme",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_WarenuebernahmepositionStuecklisteRel",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "webApiExpand": {
                'Artikel': null,
                'KalkulationContainer': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Positionsart': null,
                'WarenuebernahmepositionStuecklisteInfo': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_WarenuebernahmepositionStuecklisteEdit",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_WarenuebernahmepositionEditUtils.showVariablerSetartikelPreisAendernCommand",
                "bindToFQ": "functions.$f_WarenuebernahmepositionEditUtils.showVariablerSetartikelPreisAendernCommand"
            }
        });
        this.addEditPopup({
            "idContent": "warenuebernahmepositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_WarenuebernahmepositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_WarenuebernahmepositionStuecklisteEdit.Id"
                }
            }],
            "id": "warenuebernahmepositionEditPopup",
            "options": {
                "optionsName": "warenuebernahmepositionEditPopupOptions",
                "optionsNameFQ": "warenuebernahmepositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addFunction("$f_WarenuebernahmepositionEditUtils", $f_WarenuebernahmepositionEditUtils, "functions.$f_WarenuebernahmepositionEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "warenuebernahmeposition-edit.r_mengesollverpeh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "MengeSollVerpEH",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.MengeSollVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeSollVerpEH",
            "options": {
                "optionsName": "r_mengeSollVerpEHOptions",
                "optionsNameFQ": "r_mengeSollVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": ["IdArtikel", {
                "isBound": true,
                "expression": "models.data.$m_Warenuebernahmeposition.IdArtikel"
            }],
            "filters": [],
            "caption": "warenuebernahmeposition-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_WarenuebernahmepositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_warpArtikelNummer",
                "caption": "warenuebernahmeposition-edit.r_warpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_warpBezeichnung",
                "caption": "warenuebernahmeposition-edit.r_warpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_warpMengeSollVerpEH",
                "caption": "warenuebernahmeposition-edit.r_warpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_warpMengeIstVerpEH",
                "caption": "warenuebernahmeposition-edit.r_warpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_warpEinheit",
                "caption": "warenuebernahmeposition-edit.r_warpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_warpPositionsart",
                "caption": "warenuebernahmeposition-edit.r_warppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_warenuebernahmepositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_warenuebernahmepositionStuecklistenGridToolbarOptions"
            },
            "caption": "warenuebernahmeposition-edit.r_warenuebernahmepositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_WarenuebernahmepositionStuecklisteRel",
                "bindTo": "IdSetartikelWarenuebernahmeposition",
                "bindToFQ": "models.data.$m_WarenuebernahmepositionStuecklisteRel.IdSetartikelWarenuebernahmeposition"
            },
            "dataModel": "$m_Warenuebernahmeposition",
            "editDataContext": "$m_WarenuebernahmepositionStuecklisteEdit",
            "idEditPopup": "warenuebernahmepositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_warenuebernahmepositionStuecklistenGrid",
            "options": {
                "optionsName": "r_warenuebernahmepositionStuecklistenGridOptions",
                "optionsNameFQ": "r_warenuebernahmepositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "warenuebernahmeposition-edit.r_isvariablersetartikel_caption",
            "isReadOnlyExpression": "models.data.$m_Warenuebernahmeposition.Id > 0",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "IsVariablerSetartikel",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.IsVariablerSetartikel"
            },
            "validationRules": [],
            "id": "r_isVariablerSetartikel",
            "options": {
                "optionsName": "r_isVariablerSetartikelOptions",
                "optionsNameFQ": "r_isVariablerSetartikelOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "warenuebernahmeposition-edit.r_lieferantartikelnummer_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "LieferantArtikelNr",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.LieferantArtikelNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_lieferantArtikelNummer",
            "options": {
                "optionsName": "r_lieferantArtikelNummerOptions",
                "optionsNameFQ": "r_lieferantArtikelNummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "warenuebernahmeposition-edit.r_lieferantartikelbezeichnung_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "LieferantArtikelbezeichnung",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.LieferantArtikelbezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_lieferantArtikelbezeichnung",
            "options": {
                "optionsName": "r_lieferantArtikelbezeichnungOptions",
                "optionsNameFQ": "r_lieferantArtikelbezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "positionsart",
            "filters": [],
            "caption": "warenuebernahmeposition-edit.r_positionsart_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahmeposition",
                "bindTo": "IdPositionsart",
                "bindToFQ": "models.data.$m_Warenuebernahmeposition.IdPositionsart"
            },
            "validationRules": [],
            "id": "r_positionsart",
            "options": {
                "optionsName": "r_positionsartOptions",
                "optionsNameFQ": "r_positionsartOptions"
            }
        });
        super.onConstructionFinished();
    }
}