import {
  autoinject,
  OverrideContext,
  Scope,
  createOverrideContext
} from "aurelia-framework";
import {
  DataSourceService,
  ScopeContainer,
  LocalizationService,
  IDataSourceOptionFilter
} from "../../../framework/base/export";
import {
  SimpleWidgetCreatorService
} from "../../../framework/forms/widget-services/simple-widget-creator-service";

import {
  GeschaeftspartnerSearchService
} from "../../services/export";

import * as Interfaces from "../../interfaces/export";
import { GridLayoutService, IGridColumnExtenderResult } from '../../../framework/forms/services/grid-layout-service';

@autoinject
export class GeschaeftspartnerSearchResult {
  private _onGeschaeftspartnerSelected: { (idGeschaeftspartner: number): void };
  private _onGeschaeftspartnerSelectedCancel: { (): void };

  constructor (
    private _element: Element,
    private _dataSourceService: DataSourceService,
    private _localizationService: LocalizationService,
    private _geschaeftspartnerSearchService: GeschaeftspartnerSearchService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _gridLayoutService: GridLayoutService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  searchValue: string = null;
  searchListDataSource: DevExpress.data.DataSource;
  optionsCaption: string;
  optionsWhere: any;
  optionsFilters: any;
  gridColumnExtenderResult: IGridColumnExtenderResult;

  searchResultDataGrid: Interfaces.IdxDataGridComponent;
  searchResultDataGridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    columns: [{
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.nummer"),
      dataField: "Nummer",
      width: "80px"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.geschaeftspartner_art"),
      dataField: "Geschaeftspartnerart.Bezeichnung",
      width: "120px"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.firmenbez1"),
      dataField: "Adresse.Firmenbez1"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.firmenbez2"),
      dataField: "Adresse.Firmenbez2"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.strasse"),
      dataField: "Adresse.Strasse"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.land"),
      dataField: "Adresse.Land.Bezeichnung",
      width: "120px"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.plz"),
      dataField: "Adresse.PLZ"
    }, {
      caption: this._localizationService.translateOnce("geschaeftspartner_search_result.ort"),
      dataField: "Adresse.Ort",
      width: "120px"
    }],
    onRowClick: (e) => {
      this._onGeschaeftspartnerSelected(e.key.Id);
      this.searchResultPopup.instance.hide();
    },
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    remoteOperations: {
      filtering: true,
      paging: true,
      sorting: true
    }
  }

  searchResultPopup: Interfaces.IdxPopupComponent;
  searchResultPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    onHidden: () => {
      this.searchValue = null;
      this._onGeschaeftspartnerSelectedCancel();
    }
  };

  searchTextBox: Interfaces.IdxTextBoxComponent;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this._localizationService.translateOnce("base.search"),
    onValueChanged: async (e) => {
      this.reloadData();
      this.focusSearchTextBox();
    },
    bindingOptions: {
      value: "searchValue"
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._geschaeftspartnerSearchService.geschaeftspartnerSearchResult = this;

    this.gridColumnExtenderResult = this._gridLayoutService.addGridColumnExtender({
      webApiAction: "ERP/Stammdaten/Geschaeftspartner",
      gridOptions: this.searchResultDataGridOptions,
      viewUrl: this._element["au"].controller.view.resources.viewUrl,
      id: "searchResultDataGrid"
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: this.optionsCaption || "base.search",
      scopeContainer: this.scopeContainer,
      options: this.searchResultPopupOptions
    });

    this.searchListDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Geschaeftspartner",
        webApiExpand: {
          Adresse: {
            expand: { Land: null }
          },
          Geschaeftspartnerart: null
        }
      },
      {
        getSearchText: () => {
          return this.searchValue;
        },
        getCustomWhere: () => {
          return this.optionsWhere;
        },
        getCustomFilters: () => {
          const filter: IDataSourceOptionFilter[] = this.optionsFilters || [];

          const dynamicColumns = this.gridColumnExtenderResult.getDynamicColumns();
          if (dynamicColumns.length > 0) {
            filter.push({
              webApiCustomKey: "ExtendedLoadProperties",
              webApiCustomValue: JSON.stringify(dynamicColumns)
            });
          }

          return filter;
        }
      }
    );

    this.searchResultDataGridOptions.dataSource = this.searchListDataSource;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showPopup(onGeschaeftspartnerSelected: { (idGeschaeftspartner: number): void },
    onGeschaeftspartnerSelectedCancel: { (): void },
    options: Interfaces.ISearchOptions) {

    this._onGeschaeftspartnerSelected = onGeschaeftspartnerSelected;
    this._onGeschaeftspartnerSelectedCancel = onGeschaeftspartnerSelectedCancel;
    this.searchValue = options.searchValue;
    this.optionsCaption = options.caption;
    this.optionsWhere = options.where;
    this.optionsFilters = options.filters;

    if (this["searchResultPopupToolbar"]) {
      this["searchResultPopupToolbar"].title = this.optionsCaption || "base.search";
    }

    if (this.searchResultDataGrid && this.searchResultDataGrid.instance) {
      const filter = this.searchResultDataGrid.instance.getCombinedFilter();
      if (filter) {
        this.searchResultDataGrid.instance.clearFilter();
      }
    }

    this.searchResultPopup.instance.show()
      .then(() => {
        this.focusSearchTextBox();
      });
  }

  private focusSearchTextBox() {
    const element: Element = this.searchTextBox.instance.element();
    const input = element.querySelector("input");
    
    input.focus();
    input.select();
  }
  private reloadData() {
    if (this.searchResultDataGrid.instance.pageIndex() > 0) {
      this.searchResultDataGrid.instance.pageIndex(0);
    }

    this.searchResultDataGrid.instance.refresh();
  }
}
