import {
  autoinject,
  bindable
} from "aurelia-framework";
import { INavigationRoute } from "../../../forms/export";

@autoinject
export class SidebarSub {
  constructor(
    private element: Element
  ) { }

  @bindable route: INavigationRoute;

  attached() {
    const html = <HTMLElement>this.element;
    html.style.top = null;

    if (html.offsetTop + html.offsetHeight > window.innerHeight) {
      html.style.top = (window.innerHeight - html.offsetHeight) + "px";
    }
  }
}
