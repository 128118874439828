import { autoinject, computedFrom } from "aurelia-framework";
import { StatistikVerkaufZeitraumTyp } from '../../../../framework-data/events';
import { GlobalizationService, RestService } from '../../../../framework/base/export';
import { DynFeldTyp } from '../../../enumerations/dyn-feld-typ';
import { StatistikDarstellungTyp } from '../../../enumerations/statistik-darstellung-typ';
import { DynFelderService } from '../../../services/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';

@autoinject
export class StatistikGruppiert {
  constructor(
    private _globalizationService: GlobalizationService,
    private _dynFelderService: DynFelderService,
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IStatistikGruppiertSettings;
  firmaDataSource: any[];

  entitaetDataSource = [{
    Bezeichnung: "Artikel",
    Value: "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
  }, {
    Bezeichnung: "Kunde",
    Value: "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
  }, {
    Bezeichnung: "Projekt",
    Value: "TIP.ERP.Business.Entitaeten.Stammdaten.Projekt"
  }];

  dynFeldDataSource: any[];

  zeitraumDataSource = [{
    Bezeichnung: "YTD",
    Value: StatistikVerkaufZeitraumTyp.YTD
  }, {
    Bezeichnung: "aktuelles Jahr",
    Value: StatistikVerkaufZeitraumTyp.Jahr
  }, {
    Bezeichnung: "Vorjahr",
    Value: StatistikVerkaufZeitraumTyp.Vorjahr
  }];

  darstellungDataSource = [{
    Bezeichnung: "Tabelle",
    Value: StatistikDarstellungTyp.Tabelle
  }, {
    Bezeichnung: "Kreisdiagramm",
    Value: StatistikDarstellungTyp.Kreisdiagramm
  }];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  };

  entitaetBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    searchEnabled: true,
    bindingOptions: {
      dataSource: "entitaetDataSource",
      value: "settings.entitaetFullname",
    },
    onValueChangedByUser: () => {
      this.settings.idDynFeld = null;
      this.loadDynFeldDataSource();
      this.reloadData();
    }
  }

  dynFeldBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      dataSource: "dynFeldDataSource",
      value: "settings.idDynFeld",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  zeitraumBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    searchEnabled: true,
    bindingOptions: {
      dataSource: "zeitraumDataSource",
      value: "settings.zeitraumTyp",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  numberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    min: 3,
    bindingOptions: {
      value: "settings.anzahl"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  doShowRestBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Rest anzeigen",
    bindingOptions: {
      value: "settings.showRest"
    },
    onValueChangedByUser: (e) => {
      this.reloadData();
    }
  }

  darstellungBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    searchEnabled: true,
    bindingOptions: {
      dataSource: "darstellungDataSource",
      value: "settings.darstellungTyp",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  @computedFrom("settings.entitaetFullname")
  get isArtikelOrGeschaeftspartner() {
    switch (this.settings.entitaetFullname) {
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel":
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.entitaetBoxOptions.dataSource = this.entitaetDataSource;
    this.loadDynFeldDataSource();
    this.loadFirmaDataSource();
  }

  private async loadDynFeldDataSource() {
    if (this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel" || this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner") {
      const dynFelder = await this._dynFelderService.loadDynFelder(this.settings.entitaetFullname);

      this.dynFeldDataSource = dynFelder
        .filter(d => d.Typ == DynFeldTyp.auswahlliste);
    }
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung", "IsVorbelegung"]
      },
      increaseLoadingCount: true
    });
  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }
    if (!this.isArtikelOrGeschaeftspartner) {
      this.settings.showRest = false;
    }
    this.widgetViewModel.loadData();
  }
}

export interface IStatistikGruppiertSettings {
  idFirma?: number;
  entitaetFullname: string;
  idDynFeld: number;
  zeitraumTyp: number;
  anzahl: number;
  showRest: boolean;
  darstellungTyp: number;
}
