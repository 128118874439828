import {
  autoinject, transient
} from "aurelia-framework";
import {
  FileService,
  LocalizationService,
  RestService,
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase,
  ICommandData
} from "../../../../framework/forms/export";
import { IdxCheckBoxComponent, IdxDataGridComponent, IdxDateBoxComponent, IdxPopupComponent, IdxRadioGroupComponent } from "../../../interfaces/dx-components";
import { OnKopierenRequest } from "../../../../framework-data/events";
import { AsyncService } from "../../../../framework/base/services/async-service";
import moment = require("moment");
import { forEach } from "lodash";


@autoinject
@transient()
export class ZahlungsverkehrListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _asyncService: AsyncService,
    private _fileService: FileService,
    private _restService: RestService,
    private _webEventService: WebEventService,
    private _localizationService: LocalizationService) { }

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
  }

  showDokumentErstellenPopupCommand: ICommandData = {
    id: "showDokumentErstellenPopup",
    idCategory: "zahlungsverkehr",
    icon: "file",
    title: "zahlungsverkehr.dokument_erstellen",
    sortIndex: 10,
    execute: async (e) => {
      const grid: IdxDataGridComponent = this.form["r_zahlungsverkehrGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const zahlungsverkehrGridRowData = grid.instance.getSelectedRowsData();

      grid.instance.clearSelection();

      if (zahlungsverkehrGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("zahlungsverkehr.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const popup: IdxPopupComponent = this.form["r_zahlungsverkehrDokumentErstellenPopup"];
      popup.instance.show();

      const dateBox: IdxDateBoxComponent = this.form["r_datum"];
      dateBox.setOption({ value: moment() });
      dateBox.setOption({ disabled: true });

      const valutadatumBeibehaltenCheckbox: IdxCheckBoxComponent = this.form["r_wertBehalten"];
      valutadatumBeibehaltenCheckbox.setOption({ value: true });
    }
  };
  doDokumentErstellenCommand: ICommandData = {
    id: "doDokumentErstellen",
    title: "zahlungsverkehr.dokument_erstellen",
    icon: "file",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_zahlungsverkehrDokumentErstellenPopup"];

      const grid: IdxDataGridComponent = this.form["r_zahlungsverkehrGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());
      grid.instance.clearSelection();

      const valutadatumBehaltenCheckbox: IdxCheckBoxComponent = this.form["r_wertBehalten"];
      const valutaDatumBehalten = valutadatumBehaltenCheckbox.instance.option("value");

      const dateBox: IdxDateBoxComponent = this.form["r_datum"];
      const valutaDatum = dateBox.instance.option("value");

      const r = await this._restService.post({
        url: this._restService.getApiUrl(`ERP/Zahlungsverkehr/ZahlungsverkehrJob`),
        data: {
          IdZahlungsverkehrList: keys,
          ValutaDatum: valutaDatumBehalten
            ? null
            : valutaDatum,
        },
      });

      if (r == null) {
        return;
      }

      r.DMSLinkList.forEach(dMSLink => {
          this._fileService.download(dMSLink);
      });

      this.form.models.reloadAll();
      popup.instance.hide();
    }
  }

  onValutaDatumBeibehaltenChanged(e) {
    const valutadatumAendernCheckbox: IdxCheckBoxComponent = this.form["r_wertAendern"];
    valutadatumAendernCheckbox.setOption({ value: !e.value });
  }

  onValutaDatumAendernChanged(e) {
    const valutadatumBehaltenCheckbox: IdxCheckBoxComponent = this.form["r_wertBehalten"];
    valutadatumBehaltenCheckbox.setOption({ value: !e.value });

    const dateBox: IdxDateBoxComponent = this.form["r_datum"];

    if (e.value) {
      dateBox.setOption({ disabled: false });
    } else {
      dateBox.setOption({ disabled: true });
    }
  }
}
