import { autoinject, bindable, observable } from "aurelia-framework";
import { TagService } from '../../services/tag-service';

@autoinject
export class TagsList {
  constructor(
    private _element: Element,
    private _tagService: TagService
  ) {}

  @bindable @observable tags: string;

  bind() {
    if (!this.tags) {
      return;
    }

    this.renderTags();
  }

  tagsChanged() {
    this.renderTags();
  }

  private renderTags() {
    while (this._element.children.length > 0) {
      this._element.removeChild(this._element.children.item(0));
    }

    const container = this._tagService.renderTagList(this.tags, true);
    this._element.appendChild(container);
  }
}
