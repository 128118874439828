import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { IViewScrollInfo, RestService } from "../../framework/base/export";
import { IEntitaetInfo } from "../interfaces/export";
import { StartupService } from "./startup-service";

@autoinject
export class EntitaetInfoService {
  private _typeNameDic: any = {};
  private _webApiActionDic: any = {};

  constructor(
    private restService: RestService,
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator) {
    this._eventAggregator.subscribe(
      "startupInfo:loaded",
      (r) => this.setEntitaetDic(r.startupInfo.EntitaetInfos));

    if (this._startupService.startupInfo) {
      this.setEntitaetDic(this._startupService.startupInfo.EntitaetInfos);
    }
  }

  getUrl(typeName: string, id?: number): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const parentTypeName = this.getTypeNameParent(typeName);

      if (parentTypeName != typeName) {
        id = await this.getParentId(typeName, id);
      }

      resolve(this.getUrlString(parentTypeName, id));
    });
  }
  getBezeichnung(typeName: string | { value: string }): string {
    if (typeof typeName !== "string") {
      typeName = typeName.value;
    }

    if (this._typeNameDic[typeName] && this._typeNameDic[typeName]["Bezeichnung"]) {
      return this._typeNameDic[typeName]["Bezeichnung"];
    }

    return "";
  }
  getEntitaetInfoByTypeName(typeName: string): IEntitaetInfo {
    return this._typeNameDic[typeName];
  }
  getEntitaetInfoByWebApiAction(webApiAction: string): IEntitaetInfo {
    return this._webApiActionDic[webApiAction];
  }
  getEntitaetInfoList(predicate: { (item: IEntitaetInfo): boolean }): IEntitaetInfo[] {
    const result: IEntitaetInfo[] = [];

    for (const key in this._typeNameDic) {
      const item = this._typeNameDic[key];

      if (predicate) {
        const add = predicate(item);
        if (!add) {
          continue;
        }
      }

      result.push(item);
    }

    result.sort((a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung));
    return result;
  }

  async createViewScrollInfo(
    typeName: string,
    where: any,
    customs: { key: string; value: any; }[],
    orderBy: any,
    id: any): Promise<IViewScrollInfo> {
    const info = this.getEntitaetInfoByTypeName(typeName);
    const url = this.restService.getWebApiUrl(info.WebApiUrl);

    const result = await this.restService.get({
      url: this.restService.getWebApiUrl(info.WebApiUrl),
      getOptions: {
        columns: ["Id"],
        where: where,
        customs: customs,
        orderBy: orderBy,
        skip: 0,
        take: 150,
        requireTotalCount: true
      }
    });

    if (!result || !result.rows) {
      return null;
    }

    const indexOf = result.rows.findIndex((i) => {
      return i.Id == id;
    });

    if (indexOf < 0) {
      return null;
    }

    const viewScrollInfo = <IViewScrollInfo>{
      lastLoadInfo: {
        url: url,
        getOptions: {
          where: where,
          orderBy: orderBy
        }
      },
      index: indexOf,
      maxCount: result.count
    };

    if (this.needsParentTypeNameForUrl(typeName)) {
      viewScrollInfo.resultKeyResolver = (key) => {
        return this.getParentId(typeName, key);
      };
    }

    return viewScrollInfo;
  }
  async createViewScrollInfoEx(
    loadWebApiAction: string,
    columns: string[],
    where: any,
    customs: { key: string; value: any; }[],
    orderBy: any,
    id: any,
    queryId: { (item): number }): Promise<IViewScrollInfo> {
    const url = this.restService.getWebApiUrl(loadWebApiAction);

    const result = await this.restService.get({
      url: url,
      getOptions: {
        columns: columns,
        where: where,
        customs: customs,
        orderBy: orderBy,
        skip: 0,
        take: 150,
        requireTotalCount: true
      }
    });

    if (!result || !result.rows) {
      return null;
    }

    const indexOf = result.rows.findIndex((i) => {
      return queryId(i) == id;
    });

    if (indexOf < 0) {
      return null;
    }

    const viewScrollInfo = <IViewScrollInfo>{
      lastLoadInfo: {
        url: url,
        getOptions: {
          where: where,
          orderBy: orderBy
        }
      },
      columns: columns,
      index: indexOf,
      maxCount: result.count,
      resultKeyResolver: (key, data) => {
        return Promise.resolve(queryId(data));
      }
    };

    return viewScrollInfo;
  }

  async getParentId(childTypeName: string, childId: any): Promise<any> {
    const parentTypeName = this.getTypeNameParent(childTypeName);

    if (parentTypeName == childTypeName) {
      return childId;
    }

    const childInfo = this.getEntitaetInfoByTypeName(childTypeName);
    const parentInfo = this.getEntitaetInfoByTypeName(parentTypeName);

    const parentId = await this.getParentIdFromDetail(childInfo, childId, parentInfo);
    return parentId;
  }
  async getEntitaetUrlById(id: number): Promise<string> {
    if (id == void 0) {
      return undefined;
    }

    const entitaet = await this.restService.get({
      url: `${this.restService.getWebApiUrl("ERP/Stammdaten/Entitaet")}/${id}`,
      getOptions: {
        columns: ["TypeName"]      
      }
    });

    if (entitaet == void 0) {
      return undefined;
    }

    return this.getUrl(entitaet.TypeName, id);
  }
  needsParentTypeNameForUrl(typeName: string): boolean {
    return this.getTypeNameParent(typeName) != typeName;
  }
  private getBaseEntitaetUrl(typeName: string) {
    switch (typeName) {
      case "TIP.ERP.Business.Entitaeten.Import.ImportItem": {
        return "ERP/Import/Import";
      }
      case "TIP.ERP.Business.Entitaeten.Fibu.FibuSyncJob": {
        return "ERP/Fibu/SyncJob";
      }
      case "TIP.ERP.Business.Entitaeten.Kassen.KassaBeleg": {
        return "ERP/Kassa/KassaBeleg";
      }
      default: {
        const propertyNameList = typeName.split(".");
        const url = propertyNameList.slice(propertyNameList.indexOf("Entitaeten") + 1).join("/");

        return `ERP/${url}`;
      }
    }
  }
  private getEntitaetName(typeName: string): string {
    const propertyNameList = typeName.split(".");
    return propertyNameList[propertyNameList.length - 1];
  }
  private async getParentIdFromDetail(positionInfo: IEntitaetInfo, positionId: number, parentInfo: IEntitaetInfo): Promise<number> {
    if (!positionInfo || !positionId || !parentInfo) {
      return undefined;
    }

    const idParentPropertyName = `Id${this.getEntitaetName(parentInfo.FullName)}`;
    const result = await this.restService.get({
      url: `${this.restService.getWebApiUrl(positionInfo.WebApiUrl)}/${positionId}`,
      getOptions: { columns: ["Id", idParentPropertyName] }
    });

    return result && result[idParentPropertyName]
      ? result[idParentPropertyName]
      : undefined;
  }
  private getTypeNameParent(detailTypeName: string): string {
    let parentEntitaetInfo = detailTypeName;

    switch (detailTypeName) {
      case "TIP.ERP.Business.Entitaeten.Verkauf.Angebotsposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Verkauf.Angebot";
        break;
      case "TIP.ERP.Business.Entitaeten.Verkauf.Auftragsposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Verkauf.Auftrag";
        break;
      case "TIP.ERP.Business.Entitaeten.Verkauf.Lieferscheinposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Verkauf.Lieferschein";
        break;
      case "TIP.ERP.Business.Entitaeten.Verkauf.Fakturaposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Verkauf.Faktura";
        break;
      case "TIP.ERP.Business.Entitaeten.Einkauf.Bestellungsposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Einkauf.Bestellung";
        break;
      case "TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahmeposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahme";
        break;
      case "TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnungsposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnung";
        break;
      case "TIP.ERP.Business.Entitaeten.Stammdaten.BriefZuEmpfaenger":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Stammdaten.Brief";
        break;
      case "TIP.ERP.Business.Entitaeten.Produktion.ProdFertigware":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Produktion.ProdAuftrag";
        break;
      case "TIP.ERP.Business.Entitaeten.Lager.Inventurposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Lager.Inventur";
        break;
      case "TIP.ERP.Business.Entitaeten.Produktion.ProdRohware":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Produktion.ProdAuftrag";
        break;
      case "TIP.ERP.Business.Entitaeten.Kassen.KassaBelegposition":
        parentEntitaetInfo = "TIP.ERP.Business.Entitaeten.Kassen.KassaBeleg";
        break;
    }

    return parentEntitaetInfo;
  }
  private getUrlString(typeName: string, id: number) {
    const entitaetInfo = this.getEntitaetInfoByTypeName(typeName);

    if (entitaetInfo.Url.startsWith("ERP/Zeiterfassung/")) {
      entitaetInfo.Url = entitaetInfo.Url.replace("Zeiterfassung", "Projektzeit");
    }

    return entitaetInfo ?
      `${entitaetInfo.Url}${id != void 0 ? ("/" + id) : ""}`
      : null;
  }
  private setEntitaetDic(entitaetList: IEntitaetInfo[]) {
    entitaetList.forEach((e) => {
      e.Url = this.getBaseEntitaetUrl(e.FullName);

      this._typeNameDic[e.FullName] = e;
      this._webApiActionDic[e.WebApiUrl] = e;
    });
  }
}
