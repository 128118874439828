import { autoinject, transient } from "aurelia-framework";
import { OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernenEvent, OnLieferscheinpositionVariablerSetartikelPreisAendernEvent, OnLieferscheinpositionVariablerSetartikelRabattEntfernenEvent } from "../../../../framework-data/events";
import { WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from "../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern";
import { IKalkulationRefreshOptions } from "../../../interfaces/kalkulation-refresh-options";
import { DxCustomizeService } from '../../../services/dx-customize-service';
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { StartupService } from "../../../services/startup-service";

@autoinject
@transient()
export class LieferscheinpositionEditUtils {

  constructor(
    public startupService: StartupService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _dxCustomizeService: DxCustomizeService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  onReloadStuecklistenkomponentenlist: boolean;

  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "lieferscheinposition",
    icon: "money",
    title: "lieferschein.preis_aendern",
    isVisibleExpression: "functions.$f_LieferscheinpositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Lieferscheinposition && models.data.$m_Lieferscheinposition.Id > 0 && models.data.$m_Lieferscheinposition.IsVariablerSetartikel && models.data.$m_Lieferscheinposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.refreshOptions = {
      form: form,
      modelName: "$m_Lieferscheinposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeVerpEH"
    }

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "lieferscheinpositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Lieferscheinposition") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "MengeIstVerpEH" && this.form.models.data.$m_Lieferscheinposition.IsSetartikel) {
          this.onReloadStuecklistenkomponentenlist = true;
        }
      }
    });

    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0 || e.model.id != "$m_Lieferscheinposition") {
        return;
      }
      this.refreshLieferscheingruppeButton();
    });

    this.form.onSaved.register(async (r) => {
      if (this.form.models.data.$m_Lieferscheinposition.IsSetartikel) {
        if (this.onReloadStuecklistenkomponentenlist) {
          this.onReloadStuecklistenkomponentenlist = false;
          this.form.models.reloadAll();
        }
        return;
      }
    });
  }

  idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Lieferscheinposition) {
      return;
    }

    if (this.form.models.data.$m_Lieferscheinposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistenkomponentenlist = true;
    }
  }

  onLieferscheingruppeChanged() {
    this.refreshLieferscheingruppeButton();
  }

  private refreshLieferscheingruppeButton() {
    const data = this.form.models.data.$m_Lieferscheinposition;
    if (!data) {
      return;
    }
    const editor = this.form["r_lieferscheingruppe"].instance;
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (!data.IdLieferscheingruppe && data.CanSave) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: async () => {
            this.form.models.data.$m_LieferscheingruppeEdit = {
              Id: 0
            };

            this.form.editPopups.showEx({
              idEditPopup: "lieferscheingruppeEditPopup",
              modelLoaded: (form, ev) => {
                if (!form.models.data.$m_Lieferscheingruppe) {
                  return;
                }
                form.models.data.$m_Lieferscheingruppe.IdLieferschein = this.form.models.data.$m_Lieferscheinposition.IdLieferschein;
              },
              closeCallback: async (form) => {
                this.form["r_lieferscheingruppe"].instance.getDataSource().reload();

                const model = form.models.modelWithKeyId;
                const data = form.models.data[model.id];
                this.form.models.data.$m_Lieferscheinposition.IdLieferscheingruppe = data.Id;

                this.form.onEditorValueChanged.fire({
                  binding: {
                    dataContext: "$m_Lieferscheinposition",
                    bindTo: "IdLieferscheingruppe"
                  },
                  value: data.Id
                });
              }
            });
          }
        }
      });
    }

    buttons.push({
      location: "after",
      name: "clear"
    }, {
      location: "after",
      name: "dropDown"
    });

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnLieferscheinpositionVariablerSetartikelRabattEntfernenEvent({
            IdLieferscheinpositionSetartikel: this.form.models.data.$m_Lieferscheinposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnLieferscheinpositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdLieferscheinpositionSetartikel: this.form.models.data.$m_Lieferscheinposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnLieferscheinpositionVariablerSetartikelPreisAendernEvent({
            IdLieferscheinpositionSetartikel: this.form.models.data.$m_Lieferscheinposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }
}
