import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    MahnungListUtils
} from "./mahnung-list-utils";

@fwx.autoinject
export class MahnungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_MahnungListUtils: MahnungListUtils) {
        super(element, formBaseImport);
        this.id = "mahnung-list";
        this.title = "mahnung-list.mahnung-list_caption";
        this.addModel({
            "id": "$m_Mahnung",
            "webApiAction": "ERP/Verkauf/Mahnung",
            "webApiExpand": {
                'Firma': null,
                'Status': null,
                'Rechnungsort': null,
                'Waehrung': null,
                'RechnungsortAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_mahnungGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_MahnungListUtils.doMahnungErstellen",
                "bindToFQ": "functions.$f_MahnungListUtils.doMahnungErstellen"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_MahnungListUtils.doMahnungenVersenden",
                "bindToFQ": "functions.$f_MahnungListUtils.doMahnungenVersenden"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_MahnungListUtils", $f_MahnungListUtils, "functions.$f_MahnungListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_mahnFirma",
                "caption": "mahnung-list.r_mahnfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_mahnDatum",
                "caption": "mahnung-list.r_mahndatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_mahnKundennr",
                "caption": "mahnung-list.r_mahnkundennr_caption",
                "bindTo": "Rechnungsort.Nummer",
                "width": "80px"
            }, {
                "id": "r_mahnFirmenbez1",
                "caption": "mahnung-list.r_mahnfirmenbez1_caption",
                "bindTo": "RechnungsortAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_mahnStrasse",
                "caption": "mahnung-list.r_mahnstrasse_caption",
                "bindTo": "RechnungsortAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_mahnLand",
                "caption": "mahnung-list.r_mahnland_caption",
                "bindTo": "RechnungsortAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_mahnOrt",
                "caption": "mahnung-list.r_mahnort_caption",
                "bindTo": "RechnungsortAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_mahnBetragOffen",
                "caption": "mahnung-list.r_mahnbetragoffen_caption",
                "bindTo": "BetragOffen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnZinsen",
                "caption": "mahnung-list.r_mahnzinsen_caption",
                "bindTo": "Zinsen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMahnspesen",
                "caption": "mahnung-list.r_mahnmahnspesen_caption",
                "bindTo": "Mahnspesen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMaxMahnstufe",
                "caption": "mahnung-list.r_mahnmaxmahnstufe_caption",
                "bindTo": "MaxMahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_mahnWaehrung",
                "caption": "mahnung-list.r_mahnwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_mahnStatus",
                "caption": "mahnung-list.r_mahnstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_mahnungGridToolbarOptions",
                "optionsNameFQ": "r_mahnungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Mahnung"
            },
            "dataModel": "$m_Mahnung",
            "editUrl": "ERP/Verkauf/Mahnung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_mahnungGrid",
            "options": {
                "optionsName": "r_mahnungGridOptions",
                "optionsNameFQ": "r_mahnungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}