import * as fwx from "../../../../framework/forms/form-export";
import {
    LieferscheinEditUtils
} from "./lieferschein-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class LieferscheinEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_LieferscheinEditUtils: LieferscheinEditUtils,
        private $f_StatusService: StatusService,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "lieferschein-edit";
        this.title = "lieferschein-edit.lieferschein-edit_caption";
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Status': null,
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_LieferscheinpositionRel",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "webApiExpand": {
                'Artikel': null,
                'Lieferscheingruppe': null,
                'Auftragsposition': {
                    'columns': [
                        'Bezeichnung',
                        'IdAuftrag'
                    ],
                    'expand': {
                        'Auftrag': {
                            'columns': [
                                'Nummer'
                            ]
                        }
                    }
                },
                'Einheit': {
                    'columns': [
                        'Id',
                        'IdEinheit'
                    ],
                    'expand': {
                        'Einheit': {
                            'columns': [
                                'Bezeichnung'
                            ]
                        }
                    }
                },
                'Positionsart': {
                    'columns': [
                        'Bezeichnung'
                    ]
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": [{
                "webApiCustomKey": "AddMehrfachbearbeitungTypeName",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_LieferscheinpositionEdit",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Kunde': {
                    'expand': {
                        'Adresse': {
                            'expand': {
                                'Land': null
                            }
                        }
                    }
                },
                'Waehrung': null,
                'Status': null
            },
            "webApiWhere": [
                ["IdKunde", {
                    "isBound": true,
                    "expression": "models.data.$m_Lieferschein.IdKunde"
                }],
                ["MergeKey", {
                    "isBound": true,
                    "expression": "models.data.$m_Lieferschein.MergeKey"
                }],
                ["IsBestaetigt", "true"],
                ["Status.Typ", "0"]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "HasLieferposition",
                "webApiCustomValue": "true"
            }, {
                "webApiCustomKey": "IdLieferschein",
                "webApiCustomValue": "models.data.$m_Lieferschein.Id"
            }]
        });
        this.addModel({
            "id": "$m_LieferscheingruppeRel",
            "webApiAction": "ERP/Verkauf/Lieferscheingruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Lieferschein.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_LieferscheingruppeEdit",
            "webApiAction": "ERP/Verkauf/Lieferscheingruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LieferscheinEditUtils.fakturaStatusAendernCommand",
                "bindToFQ": "functions.$f_LieferscheinEditUtils.fakturaStatusAendernCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LieferscheinEditUtils.doAbschliessenCommand",
                "bindToFQ": "functions.$f_LieferscheinEditUtils.doAbschliessenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LieferscheinEditUtils.showImportAuftragPopupCommand",
                "bindToFQ": "functions.$f_LieferscheinEditUtils.showImportAuftragPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LieferscheinEditUtils.refreshGewichtCommand",
                "bindToFQ": "functions.$f_LieferscheinEditUtils.refreshGewichtCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "lieferscheinpositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_LieferscheinpositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_LieferscheinpositionEdit.Id"
                }
            }],
            "id": "lieferscheinpositionEditPopup",
            "options": {
                "optionsName": "lieferscheinpositionEditPopupOptions",
                "optionsNameFQ": "lieferscheinpositionEditPopupOptions"
            },
            "caption": "lieferschein-edit.lieferscheinpositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "lieferscheingruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_LieferscheingruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_LieferscheingruppeEdit.Id"
                }
            }],
            "id": "lieferscheingruppeEditPopup",
            "options": {
                "optionsName": "lieferscheingruppeEditPopupOptions",
                "optionsNameFQ": "lieferscheingruppeEditPopupOptions"
            },
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_LieferscheinEditUtils", $f_LieferscheinEditUtils, "functions.$f_LieferscheinEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "lieferschein-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Lieferschein.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "lieferschein-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Lieferschein.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lieferschein-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Lieferschein.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lieferschein-edit.r_versanddatum_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Versanddatum",
                "bindToFQ": "models.data.$m_Lieferschein.Versanddatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_versanddatum",
            "options": {
                "optionsName": "r_versanddatumOptions",
                "optionsNameFQ": "r_versanddatumOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "lieferschein-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabGruppen",
                "caption": "lieferschein-edit.r_tabgruppen_caption"
            }, {
                "id": "r_tabAdressen",
                "caption": "lieferschein-edit.r_tabadressen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "lieferschein-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_liefpPosNr",
                "caption": "lieferschein-edit.r_liefpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_liefpArtikelNummer",
                "caption": "lieferschein-edit.r_liefpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefpBezeichnug",
                "caption": "lieferschein-edit.r_liefpbezeichnug_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_liefpAuftragsNummer",
                "caption": "lieferschein-edit.r_liefpauftragsnummer_caption",
                "bindTo": "Auftragsposition.Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefpMengeSollVerpEH",
                "caption": "lieferschein-edit.r_liefpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpMengeIstVerpEH",
                "caption": "lieferschein-edit.r_liefpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpEinheit",
                "caption": "lieferschein-edit.r_liefpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_liefpPositionsart",
                "caption": "lieferschein-edit.r_liefppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_liefpLieferscheingruppe",
                "caption": "lieferschein-edit.r_liefplieferscheingruppe_caption",
                "bindTo": "Lieferscheingruppe.Bezeichnung",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lieferscheinpositionGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinpositionGridToolbarOptions"
            },
            "caption": "lieferschein-edit.r_lieferscheinpositiongrid_caption",
            "binding": {
                "dataContext": "$m_Lieferschein"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_LieferscheinpositionRel",
                "bindTo": "IdLieferschein",
                "bindToFQ": "models.data.$m_LieferscheinpositionRel.IdLieferschein"
            },
            "dataModel": "$m_Lieferschein",
            "editDataContext": "$m_LieferscheinpositionEdit",
            "idEditPopup": "lieferscheinpositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Lieferschein",
                    "bindTo": "$f_LieferscheinEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_LieferscheinEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_lieferscheinpositionGrid",
            "options": {
                "optionsName": "r_lieferscheinpositionGridOptions",
                "optionsNameFQ": "r_lieferscheinpositionGridOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferschein-edit.r_gewichtbrutto_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "GewichtBrutto",
                "bindToFQ": "models.data.$m_Lieferschein.GewichtBrutto"
            },
            "validationRules": [],
            "id": "r_gewichtBrutto",
            "options": {
                "optionsName": "r_gewichtBruttoOptions",
                "optionsNameFQ": "r_gewichtBruttoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferschein-edit.r_gewichtverpackung_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "GewichtVerpackung",
                "bindToFQ": "models.data.$m_Lieferschein.GewichtVerpackung"
            },
            "validationRules": [],
            "id": "r_gewichtVerpackung",
            "options": {
                "optionsName": "r_gewichtVerpackungOptions",
                "optionsNameFQ": "r_gewichtVerpackungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "lieferschein-edit.r_laenge_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Laenge",
                "bindToFQ": "models.data.$m_Lieferschein.Laenge"
            },
            "validationRules": [],
            "id": "r_laenge",
            "options": {
                "optionsName": "r_laengeOptions",
                "optionsNameFQ": "r_laengeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "lieferschein-edit.r_breite_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Breite",
                "bindToFQ": "models.data.$m_Lieferschein.Breite"
            },
            "validationRules": [],
            "id": "r_breite",
            "options": {
                "optionsName": "r_breiteOptions",
                "optionsNameFQ": "r_breiteOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "lieferschein-edit.r_hoehe_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Hoehe",
                "bindToFQ": "models.data.$m_Lieferschein.Hoehe"
            },
            "validationRules": [],
            "id": "r_hoehe",
            "options": {
                "optionsName": "r_hoeheOptions",
                "optionsNameFQ": "r_hoeheOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anggPosNr",
                "caption": "lieferschein-edit.r_anggposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_anggBezeichnung",
                "caption": "lieferschein-edit.r_anggbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_anggWertnetto",
                "caption": "lieferschein-edit.r_anggwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lieferscheingruppeGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheingruppeGridToolbarOptions"
            },
            "caption": "lieferschein-edit.r_lieferscheingruppegrid_caption",
            "binding": {
                "dataContext": "$m_Lieferschein"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_LieferscheingruppeRel",
                "bindTo": "IdLieferschein",
                "bindToFQ": "models.data.$m_LieferscheingruppeRel.IdLieferschein"
            },
            "dataModel": "$m_Lieferschein",
            "editDataContext": "$m_LieferscheingruppeEdit",
            "idEditPopup": "lieferscheingruppeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheingruppeGrid",
            "options": {
                "optionsName": "r_lieferscheingruppeGridOptions",
                "optionsNameFQ": "r_lieferscheingruppeGridOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "lieferschein-edit.r_lieferorttelefon_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "LieferortTelefon",
                "bindToFQ": "models.data.$m_Lieferschein.LieferortTelefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lieferortTelefon",
            "options": {
                "optionsName": "r_lieferortTelefonOptions",
                "optionsNameFQ": "r_lieferortTelefonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "lieferschein-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Lieferschein.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "lieferschein-edit.r_preisdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Lieferschein.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "lieferschein-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Lieferschein.Id > 0",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Lieferschein.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Lieferschein.IdFirma"
            }],
            "filters": [],
            "caption": "lieferschein-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Lieferschein.Id > 0",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Lieferschein.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "lieferschein-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Lieferschein.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "lieferschein-edit.r_trackingnr_caption",
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "TrackingNr",
                "bindToFQ": "models.data.$m_Lieferschein.TrackingNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 150
                    }]
                }
            }],
            "id": "r_trackingNr",
            "options": {
                "optionsName": "r_trackingNrOptions",
                "optionsNameFQ": "r_trackingNrOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lieferscheinFakturaStatus",
            "filters": [],
            "caption": "lieferschein-edit.r_fakturastatus_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "FakturaStatus",
                "bindToFQ": "models.data.$m_Lieferschein.FakturaStatus"
            },
            "validationRules": [],
            "id": "r_fakturaStatus",
            "options": {
                "optionsName": "r_fakturaStatusOptions",
                "optionsNameFQ": "r_fakturaStatusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "lieferschein-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferschein",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Lieferschein.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_auftragEditPopup",
            "options": {
                "optionsName": "r_auftragEditPopupOptions",
                "optionsNameFQ": "r_auftragEditPopupOptions"
            },
            "caption": "lieferschein-edit.r_auftrageditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_LieferscheinEditUtils.importAuftraegeCommand",
                    "bindToFQ": "functions.$f_LieferscheinEditUtils.importAuftraegeCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufAuftragNummer",
                "caption": "lieferschein-edit.r_aufauftragnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_aufDatum",
                "caption": "lieferschein-edit.r_aufdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufLieferdatum",
                "caption": "lieferschein-edit.r_auflieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufKundenNummer",
                "caption": "lieferschein-edit.r_aufkundennummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufFirmenbez1",
                "caption": "lieferschein-edit.r_auffirmenbez1_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_aufLand",
                "caption": "lieferschein-edit.r_aufland_caption",
                "bindTo": "Kunde.Adresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_aufOrt",
                "caption": "lieferschein-edit.r_aufort_caption",
                "bindTo": "Kunde.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_aufStrasse",
                "caption": "lieferschein-edit.r_aufstrasse_caption",
                "bindTo": "Kunde.Adresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_aufWertNetto",
                "caption": "lieferschein-edit.r_aufwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufWaehrung",
                "caption": "lieferschein-edit.r_aufwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_aufStatus",
                "caption": "lieferschein-edit.r_aufstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_auftragGridToolbarOptions",
                "optionsNameFQ": "r_auftragGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "dataModel": "$m_Auftrag",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_auftragGrid",
            "options": {
                "optionsName": "r_auftragGridOptions",
                "optionsNameFQ": "r_auftragGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}