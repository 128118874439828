import { autoinject, observable, bindable, OverrideContext } from 'aurelia-framework';
import { RestService } from '../../../../framework/base/services/rest-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { NotificationTyp } from '../../../enumerations/notification-typ';
import { IListViewOptions, ListView } from '../../../../framework/forms/elements/list-view/export';
import { DataSourceService } from '../../../../framework/base/services/data-source-service';
import { ScopeContainer } from '../../../../framework/base/classes/scope-container';
import { IViewScrollInfo } from '../../../../framework/base/interfaces/view-scroll-info';

@autoinject
export class Notiz {
  private _notizAnzahlChangedSubscription: Subscription;

  constructor(
    private rest: RestService,
    private dataSource: DataSourceService,
    private eventAggregator: EventAggregator
  ) {}

  @bindable @observable id: number;
  @bindable @observable idGeschaeftspartner: number;
  @bindable @observable idArtikel: number;
  @bindable @observable idProjekt: number;

  scopeContainer: ScopeContainer;

  notizListView: ListView;
  notizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.onNotizClicked(e.item, this.notizListViewOptions);
    }
  }
  geschaeftspartnerNotizListView: ListView;
  geschaeftspartnerNotizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.onNotizClicked(e.item, this.geschaeftspartnerNotizListViewOptions);
    }
  }
  artikelNotizListView: ListView;
  artikelNotizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.onNotizClicked(e.item, this.artikelNotizListViewOptions);
    }
  }
  projektNotizListView: ListView;
  projektNotizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.onNotizClicked(e.item, this.projektNotizListViewOptions);
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.notizListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Notiz",
        webApiColumns: ["Id", "DisplayText", "AnlageDatum"],
        webApiWhere: [["IdVerknuepfteEntitaet", {
          "isBound": true,
          "expression": "id"
        }], [["GueltigBis", "null"], "or", ["GueltigBis", ">=", "@Today()"]]],
        webApiOrderBy: [{columnName: "IsWichtig", sortOrder: 1}, {columnName: "GueltigBis", sortOrder: 0}, {columnName: "Id", sortOrder: 1}]
      }
    );
    this.geschaeftspartnerNotizListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Notiz",
        webApiColumns: ["Id", "DisplayText", "AnlageDatum"],
        webApiWhere: [["IdVerknuepfteEntitaet", {
          "isBound": true,
          "expression": "idGeschaeftspartner"
        }], [["GueltigBis", "null"], "or", ["GueltigBis", ">=", "@Today()"]]],
        webApiOrderBy: [{columnName: "IsWichtig", sortOrder: 1}, {columnName: "GueltigBis", sortOrder: 0}, {columnName: "Id", sortOrder: 1}]
      }
    );
    this.artikelNotizListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Notiz",
        webApiColumns: ["Id", "DisplayText", "AnlageDatum"],
        webApiWhere: [["IdVerknuepfteEntitaet", {
          "isBound": true,
          "expression": "idArtikel"
        }], [["GueltigBis", "null"], "or", ["GueltigBis", ">=", "@Today()"]]],
        webApiOrderBy: [{columnName: "IsWichtig", sortOrder: 1}, {columnName: "GueltigBis", sortOrder: 0}, {columnName: "Id", sortOrder: 1}]
      }
    );
    this.projektNotizListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Notiz",
        webApiColumns: ["Id", "DisplayText", "AnlageDatum"],
        webApiWhere: [["IdVerknuepfteEntitaet", {
          "isBound": true,
          "expression": "idProjekt"
        }], [["GueltigBis", "null"], "or", ["GueltigBis", ">=", "@Today()"]]],
        webApiOrderBy: [{columnName: "IsWichtig", sortOrder: 1}, {columnName: "GueltigBis", sortOrder: 0}, {columnName: "Id", sortOrder: 1}]
      }
    );
    
    this._notizAnzahlChangedSubscription = this.eventAggregator.subscribe(
      NotificationTyp.notizAnzahlChanged,
      this.reloadNotizen.bind(this)
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this._notizAnzahlChangedSubscription.dispose();
  }

  reloadNotizen() {
    if (this.notizListView) {
      this.notizListView.refresh(false);
    }
    if (this.geschaeftspartnerNotizListView) {
      this.geschaeftspartnerNotizListView.refresh(false);
    }
    if (this.artikelNotizListView) {
      this.artikelNotizListView.refresh(false);
    }
    if (this.projektNotizListView) {
      this.projektNotizListView.refresh(false);
    }
  }
  onNotizClicked(notiz, listViewOptions: IListViewOptions) {
    this.eventAggregator.publish(
      NotificationTyp.notizShowEditPopup, {
        id: notiz.Id,
        viewScrollInfo: this.getViewScrollInfo(notiz, listViewOptions)
      }
    )
  }
  
  private getViewScrollInfo(notiz, listViewOptions: IListViewOptions): IViewScrollInfo {
    const dataSource = listViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(notiz);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
}
