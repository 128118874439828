import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    EingangsrechnungListUtils
} from "./eingangsrechnung-list-utils";

@fwx.autoinject
export class EingangsrechnungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_EingangsrechnungListUtils: EingangsrechnungListUtils) {
        super(element, formBaseImport);
        this.id = "eingangsrechnung-list";
        this.title = "eingangsrechnung-list.eingangsrechnung-list_caption";
        this.addModel({
            "id": "$m_Eingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null,
                'Lieferant': null,
                'LieferantAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_eingangsrechnungGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_EingangsrechnungListUtils.doExport",
                "bindToFQ": "functions.$f_EingangsrechnungListUtils.doExport"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_EingangsrechnungListUtils", $f_EingangsrechnungListUtils, "functions.$f_EingangsrechnungListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einNummer",
                "caption": "eingangsrechnung-list.r_einnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_einDatum",
                "caption": "eingangsrechnung-list.r_eindatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_einProjekt",
                "caption": "eingangsrechnung-list.r_einprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_einLieferantNummer",
                "caption": "eingangsrechnung-list.r_einlieferantnummer_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "80px"
            }, {
                "id": "r_einFirmenbez1",
                "caption": "eingangsrechnung-list.r_einfirmenbez1_caption",
                "bindTo": "LieferantAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_einStrasse",
                "caption": "eingangsrechnung-list.r_einstrasse_caption",
                "bindTo": "LieferantAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_einLand",
                "caption": "eingangsrechnung-list.r_einland_caption",
                "bindTo": "LieferantAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_einOrt",
                "caption": "eingangsrechnung-list.r_einort_caption",
                "bindTo": "LieferantAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_einWertBrutto",
                "caption": "eingangsrechnung-list.r_einwertbrutto_caption",
                "bindTo": "WertBrutto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einWaehrung",
                "caption": "eingangsrechnung-list.r_einwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_fakZahlungFaellig",
                "caption": "eingangsrechnung-list.r_fakzahlungfaellig_caption",
                "bindTo": "ZahlungFaelligkeit",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_EingangsrechnungListUtils.onZahlungFaelligCellPrepared"
            }, {
                "id": "r_fakZahlungFaelligSkonto",
                "caption": "eingangsrechnung-list.r_fakzahlungfaelligskonto_caption",
                "bindTo": "ZahlungFaelligkeitSkonto",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_EingangsrechnungListUtils.onZahlungFaelligCellPrepared"
            }, {
                "id": "r_einStatus",
                "caption": "eingangsrechnung-list.r_einstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_eingangsrechnungGridToolbarOptions",
                "optionsNameFQ": "r_eingangsrechnungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Eingangsrechnung"
            },
            "dataModel": "$m_Eingangsrechnung",
            "editUrl": "ERP/Einkauf/Eingangsrechnung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eingangsrechnungGrid",
            "options": {
                "optionsName": "r_eingangsrechnungGridOptions",
                "optionsNameFQ": "r_eingangsrechnungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}