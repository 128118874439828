import { IFormCommandExecuteOptions } from './../../interfaces/form-command-options';
import { IDataSourceCustomizationOptions } from './../../../framework/base/interfaces/data-source-customization-options';
import { IdxContextMenuComponent, IdxColorBoxComponent, IdxPopoverComponent } from './../../interfaces/dx-components';
import { autoinject } from 'aurelia-framework';
import { WebEventService } from './../../../framework/base/services/web-event-service';
import {
  OnStatusMoeglichRequestEvent,
  OnStatusMoeglichRequest,
  OnStatusAendernEvent,
} from "../../../framework-data/events";

import { ICommandData, FormBase } from '../../../framework/forms/export';
import { FormCommandService, StatusService } from '../../services/export';
import { LocalizationService } from '../../../framework/base/export';

@autoinject
export class StatusFormCommand {
  private _availableForms: FormBase[] = [];
  private _detachedCommand: {(): void};

  constructor(
    private formCommand: FormCommandService,
    private localization: LocalizationService,
    private status: StatusService,
    private webEvent: WebEventService
  ) { }

  form: FormBase;
  modelData: any;

  statusFormCommand: ICommandData = {
    id: "statusFormCommand",
    idCategory: "status",
    title: "form-command.status-command-icon-text",
    icon: "bullseye",
    isEnabled: false,
    badgeText: null,
    sortIndex: 9000
  }

  statusContextMenu: IdxContextMenuComponent;
  statusContextMenuOptions: DevExpress.ui.dxContextMenuOptions = {
    target: "#NONSENSE",
    visible: false,
    displayExpr: "Bezeichnung",
    position: { my: "top", at: "bottom" },
    onItemClick: (e) => {
      this.setStatus(e.itemData.Id);
    }
  }

  attached() {
    this._detachedCommand = this.formCommand.attachFormCommand(
      this.statusFormCommand,
      {
        isCommandVisibleModelProperty: "HasStatus",
        execute: (e) => {
          this.showPopover(e);
        },
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.removeAvailableForm.bind(this)
      });
  }
  detached() {
    this._detachedCommand();
  }

  private showPopover(e: IFormCommandExecuteOptions) {
    this.form = e.form;
    this.modelData = e.modelData;
    this.statusContextMenu.instance.option("target", this.formCommand.getCommandTarget(e.options.event));

    this.getStatusList()
      .then((r: OnStatusMoeglichRequest) => {
        if (!r || !r.StatusList) {
          return;
        }

        if (r.StatusList.length == 0 ) {
          DevExpress.ui.notify(
            this.localization.translateOnce("status-form-command.keine-status-vorhanden"),
            "error",
            3000
          );
        }

        this.statusContextMenu.instance.option("dataSource", r.StatusList);
        this.statusContextMenu.instance.show();
      });
  }

  private async getStatusList(): Promise<OnStatusMoeglichRequest> {
    const webEvent = new OnStatusMoeglichRequestEvent({ IdObjekt: this.modelData.Id });
    const result: OnStatusMoeglichRequest = await this.webEvent.execute(webEvent);

    return result;
  }
  private setStatus(idStatus): void {
    if (this.modelData.IdStatus == idStatus) {
      return;
    }

    const webEvent = new OnStatusAendernEvent({ IdObjekt: this.modelData.Id, IdStatus: idStatus });
    this.webEvent
      .execute(webEvent, true)
      .then(r => {
        this.modelData.IdStatus = idStatus;
        DevExpress.ui.notify(
          this.localization.translateOnce("base.save_success"),
          "SUCCESS",
          3000);

        this.reloadForm();
      });
  }  
  
  private reloadForm() {
    if (!this.form) {
      return;
    }

    this.form.models.reloadAll();
  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private removeAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1)
  }
}
