import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ProvisionsfeldEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "provisionsfeld-edit";
        this.title = "provisionsfeld-edit.provisionsfeld-edit_caption";
        this.addModel({
            "id": "$m_Provisionsfeld",
            "webApiAction": "ERP/Provision/Provisionsfeld",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "entitaet",
            "customs": [{
                "key": "KonditionArtikel",
                "value": "true"
            }],
            "filters": [],
            "caption": "provisionsfeld-edit.r_artikel_caption",
            "binding": {
                "dataContext": "$m_Provisionsfeld",
                "bindTo": "IdArtikelInfo",
                "bindToFQ": "models.data.$m_Provisionsfeld.IdArtikelInfo"
            },
            "validationRules": [],
            "id": "r_artikel",
            "options": {
                "optionsName": "r_artikelOptions",
                "optionsNameFQ": "r_artikelOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n4",
            "caption": "provisionsfeld-edit.r_mulitplikator_caption",
            "binding": {
                "dataContext": "$m_Provisionsfeld",
                "bindTo": "Multiplikator",
                "bindToFQ": "models.data.$m_Provisionsfeld.Multiplikator"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_mulitplikator",
            "options": {
                "optionsName": "r_mulitplikatorOptions",
                "optionsNameFQ": "r_mulitplikatorOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "provisionsfeld-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_Provisionsfeld",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_Provisionsfeld.SortNr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}