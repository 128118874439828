import { autoinject } from "aurelia-framework";
import { ScopeContainer } from '../../../../framework/base/export';
import { EntitaetInfoService } from '../../../services/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';
import { TagService } from './../../../services/tag-service';

@autoinject
export class EntitaetTagsSettings {
  constructor(
    private _tagService: TagService,
    private _entitaetInfoService: EntitaetInfoService
  ) { }

  scopeContainer: ScopeContainer;

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IEntitaetTagsSettings;

  dataSourceEntitaeten: any[];
  dataSourceTags: any[];

  tagBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      dataSource: "dataSourceTags",
      value: "settings.idTag",
    },
    onValueChangedByUser: (e) => {
      e.model.bindingContext.settings.typeNameEntitaet = null;
      this.reloadData();
      this.loadEntitaetenDataSource();

    }
  };

  entitaetBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "TypeName",
    searchEnabled: true,
    showClearButton: true,
    bindingOptions: {
      dataSource: "dataSourceEntitaeten",
      value: "settings.typeNameEntitaet",
    },
    onValueChangedByUser: (e) => {
      this.reloadData();
    }
  };

  doShowAktivBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Nur aktive anzeigen",
    bindingOptions: {
      value: "settings.nurAktiveDatensaetze"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.loadTagDataSource();
  }
  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  private async loadEntitaetenDataSource() {
    const result = this._tagService.getEntitaetList(this.settings.idTag);

    const dataSource = [];
    result.forEach(res => {
      dataSource.push({
        Bezeichnung: this._entitaetInfoService.getBezeichnung(res) || res,
        TypeName: res
      })
    });

    dataSource.sort((a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung));
    this.dataSourceEntitaeten = dataSource;
  }

  private loadTagDataSource() {
    const dataSource = this._tagService.getAllTagList();
    this.dataSourceTags = dataSource;

    if (this.settings.idTag) {
      this.loadEntitaetenDataSource();
    }

  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }
    this.widgetViewModel.loadData();
  }
}

export interface IEntitaetTagsSettings {
  typeNameEntitaet?: string;
  idTag?: number;
  nurAktiveDatensaetze?: boolean;
}
