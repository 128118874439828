import { autoinject } from "aurelia-framework";
import { OnWebValidate, OnWebValidateEvent } from "../../framework-data/events";
import { WebEventService } from "../../framework/base/export";
import { FormEventService } from "../../framework/forms/export";
import { ValidationInfo } from "../elements/validation-info/validation-info";
import { LocalizationService } from "./../../framework/base/services/localization-service";
import { ValidationService } from "./../../framework/forms/services/validation-service";

declare const Barcoder: any;

@autoinject
export class ValidationExService {
  constructor(
    private _localizationService: LocalizationService,
    private _validationService: ValidationService,
    private _webEventService: WebEventService,
    private _formEventService: FormEventService) {
    this.registerDeleteValidation();
    this.registerEAN();
    this.registerKassaCode();
  }

  validationInfo: ValidationInfo;

  async executeServerValidation(typeName: string, id: number, code: string): Promise<boolean> {
    const r: OnWebValidate = await this._webEventService.execute(new OnWebValidateEvent({
      TypeName: typeName,
      Id: id,
      Code: code
    }), true);

    if (!r.ResultList || r.ResultList.length == 0) {
      return true;
    }

    const promise = new Promise<boolean>((resolve, reject) => {
      this.validationInfo.showPopup(r.ResultList, (r) => {
        resolve(r);
      });
    });

    return await promise;
  }

  private registerDeleteValidation() {
    this._formEventService.onDeleting.register(async (ev) => {
      const mainModel = ev.form.models.modelWithKeyId;
      if (!mainModel) {
        return;
      }

      const data = ev.form.models.data[mainModel.id];
      if (!data.TypeName || !data.Id) {
        return;
      }

      const r = await this.executeServerValidation(data.TypeName, data.Id, "LOESCHEN");
      if (!r) {
        ev.cancel = true;
      }
    });
  }
  private registerEAN() {
    this._validationService.registerValidator("ean", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        reevaluate: true,
        message: this._localizationService.translateOnce("erp-validation.ean"),
        validationCallback: (e) => {
          if (e.value == void (0) || e.value == "") {
            return true;
          }

          if (!Barcoder.validate(e.value)) {
            return false;
          }

          return true;
        }
      };
    });
  }

  private registerKassaCode() {
    this._validationService.registerValidator("kassacode", (scopeContainer, caption, parameters) => {
      return {
        type: "custom",
        reevaluate: true,
        message: this._localizationService.translateOnce("erp-validation.kassacode"),
        validationCallback: (e) => {
          if (e.value == void (0) || e.value == "") {
            return false;
          }

          const hasInvalidChar = /_/.test(e.value);
          if (hasInvalidChar) {
            return false;
          }

          return true;
        }
      };
    });
  }
}
