import { autoinject } from "aurelia-framework";

@autoinject
export class MarkdownService {
  private _regexBold1 = /\*\*(.*?\S)\*\*/gm;
  private _regexBold2 = /\_\_(.*?\S)\_\_/gm;
  private _regexLink = /\[(.*?\S)\]\((.+?\S)\)/gm;

  constructor() {}

  replace(text: string): string {
    if (!text) {
      return text;
    }

    return text
      .replace(this._regexBold1, "<b>$1</b>")
      .replace(this._regexBold2, "<b>$1</b>")
      .replace(this._regexLink, "<a href='$2' target='_blank'>$1</a>");
  }
}
