import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase,
  TipFileUploaderWithViewer
} from "../../../../framework/forms/export";
import {
  WebEventService, LocalizationService
} from "../../../../framework/base/export";
import {
  OnCreateFibuSyncJobsEvent,
  OnCreateFibuSyncJobs,
  OnImportFibuOPListeEvent,
  OnCreateFibuPersonenkontenEvent
} from "../../../../framework-data/events";
import { IdxPopupComponent, IdxDateBoxComponent, IdxSelectBoxComponent } from '../../../interfaces/export';


@autoinject
@transient()
export class FibuSyncJobListUtils {
  constructor(
    private webEvent: WebEventService,
    private localizationService: LocalizationService
  ) { }

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
  }

  showSynchronsierenPopupCommand: ICommandData = {
    id: "showSynchronsierenPopup",
    idCategory: "fibu-sync-job",
    title: "fibu-sync-job.do_synchronisieren",
    sortIndex: 10,
    icon: "refresh",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_fibuSyncFilterPopup"];
      popup.instance.show();

      const selectBox: IdxSelectBoxComponent = this.form["r_fibuSyncFirma"];
      if (selectBox) {
        const dataSource = selectBox.instance.getDataSource();
        await dataSource.reload();

        if (dataSource.items().length == 1) {
          selectBox.setOption({
            value: dataSource.items()[0].Id
          });
        }
      }
    }
  }
  showExportPersonenkontenPopupCommand: ICommandData = {
    id: "doExportPersonenkonten",
    title: "fibu-sync-job.do_export_personenkonten",
    idCategory: "fibu-sync-job",
    sortIndex: 11,
    icon: "users",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_exportPersonenkontenPopup"];
      popup.instance.show();

      const selectBox: IdxSelectBoxComponent = this.form["r_exportPFirma"];
      if (selectBox) {
        const dataSource = selectBox.instance.getDataSource();
        await dataSource.reload();

        if (dataSource.items().length == 1) {
          selectBox.setOption({
            value: dataSource.items()[0].Id
          });
        }
      }
    }
  }
  showOffenePostenImportPopupCommand: ICommandData = {
    id: "showOffenePostenImportPopup",
    title: "fibu-sync-job.do_op_import",
    idCategory: "fibu-sync-job",
    sortIndex: 12,
    icon: "upload",
    execute: async () => {
      const fileUploader: TipFileUploaderWithViewer = this.form["r_impOPListe"];
      if (fileUploader) {
        fileUploader.setValue(null);
        fileUploader.downloadUrl = null;
      }

      const popup: IdxPopupComponent = this.form["r_importOPPopup"];
      popup.instance.show();

      const selectBox: IdxSelectBoxComponent = this.form["r_impOPFirma"];
      if (selectBox) {
        const dataSource = selectBox.instance.getDataSource();
        await dataSource.reload();

        if (dataSource.items().length == 1) {
          selectBox.setOption({
            value: dataSource.items()[0].Id
          });
        }
      }
    }
  }
  
  doSynchronisierenCommand: ICommandData = {
    id: "doSynchronisieren",
    title: "fibu-sync-job.do_synchronisieren",
    icon: "refresh",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_fibuSyncFilterPopup"];
      const dateBox: IdxDateBoxComponent = this.form["r_datumBis"];
      const firmaSelectBox: IdxSelectBoxComponent = this.form["r_fibuSyncFirma"];

      const idFirma = firmaSelectBox.instance.option("value");
      if (!idFirma) {
        DevExpress.ui.notify(this.localizationService.translateOnce("fibu-sync-job.firma_auswaehlen"), "error", 3000);
        return;
      }
 
      const webEvent = new OnCreateFibuSyncJobsEvent({
        DatumBis: dateBox.instance.option("value"),
        IdFirma: idFirma
      });

      const webEventResult: OnCreateFibuSyncJobs = await this.webEvent.execute(webEvent, true);
      this.form.models.reloadAll();
      popup.instance.hide();
    }
  }
  doOffenePostenImportCommand: ICommandData = {
    id: "doOffenePostenImport",
    title: "fibu-sync-job.do_op_import",
    icon: "upload",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_importOPPopup"];
      const firmaSelectBox: IdxSelectBoxComponent = this.form["r_impOPFirma"];
      const fileUploader: TipFileUploaderWithViewer = this.form["r_impOPListe"];
      
      const idFirma = firmaSelectBox.instance.option("value");
      if (!idFirma) {
        DevExpress.ui.notify(this.localizationService.translateOnce("fibu-sync-job.firma_auswaehlen"), "error", 3000);
        return;
      }

      const dmsLink = fileUploader.currentValue;
      if (!dmsLink) {
        DevExpress.ui.notify(this.localizationService.translateOnce("fibu-sync-job.op_liste_hochladen"), "error", 3000);
        return;
      }

      const webEvent = new OnImportFibuOPListeEvent({
        IdFirma: idFirma,
        DMSLink: dmsLink
      });

      await this.webEvent.execute(webEvent, true);

      this.form.models.reloadAll();
      popup.instance.hide();
    }
  }
  doExportPersonenkontenCommand: ICommandData = {
    id: "doExportPersonenkontenCommand",
    title: "fibu-sync-job.do_export_personenkonten",
    icon: "users",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_exportPersonenkontenPopup"];
      const firmaSelectBox: IdxSelectBoxComponent = this.form["r_exportPFirma"];
      const geschaeftspartnerartSelectBox: IdxSelectBoxComponent = this.form["r_exportPGeschaeftspartnerart"];
      
      const idFirma = firmaSelectBox.instance.option("value");
      if (!idFirma) {
        DevExpress.ui.notify(this.localizationService.translateOnce("fibu-sync-job.firma_auswaehlen"), "error", 3000);
        return;
      }

      const webEvent = new OnCreateFibuPersonenkontenEvent({
        IdFirma: idFirma,
        IdGeschaeftspartnerart: geschaeftspartnerartSelectBox.instance.option("value")
      });

      await this.webEvent.execute(webEvent, true);
      this.form.models.reloadAll();
      popup.instance.hide();
    }
  }
}
