import {
  autoinject,
  Scope,
  OverrideContext
} from "aurelia-framework";
import {
  OnPasswortAendern,
  OnPasswortAendernEvent
} from "../../../framework-data/events";
import {
  ICommandData
} from "../../../framework/forms/export";
import {
  WebEventService,
  ScopeContainer,
  LocalizationService
} from "../../../framework/base/export";
import {
  SimpleWidgetCreatorService
} from "../../../framework/forms/widget-services/simple-widget-creator-service";
import {
  IdxPopupComponent
} from "../../interfaces/export";
import {
  HeaderCommandService
} from "../../services/export";

import * as Interfaces from "../../interfaces/export";

@autoinject
export class PasswortAendern {

  constructor(
    private headerCommand: HeaderCommandService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private webEvent: WebEventService
  ) { }

  scope: Scope
  scopeContainer: ScopeContainer

  passwortAendernValidation: any;
  passwortAendernValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  passwortAendernPopup: IdxPopupComponent;
  passwortAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 500,
    height: "auto"
  };

  passwortAlt: Interfaces.IdxTextBoxComponent;
  passwortAltOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "password"
  };

  passwortNeu: Interfaces.IdxTextBoxComponent;
  passwortNeuOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "password"
  };

  passwortNeuRepeat: Interfaces.IdxTextBoxComponent;
  passwortNeuRepeatOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "password"
  };

  passwortAendernPopupCommands: ICommandData[] = [
    {
      id: "doPassAenderungUebernehmen",
      icon: "save",
      title: "passwort-aendern.passwort_aendern",
      execute: () => {
        this.doPasswortAendern();
      }
    }
  ];

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "passwortAendernPopupToolbar",
      caption: "passwort-aendern.popup_ueberschrift",
      options: this.passwortAendernPopupOptions,
      commands: this.passwortAendernPopupCommands,
      scopeContainer: this.scopeContainer
    });

  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    this.headerCommand.passwortAendern = this;
  }

  doPasswortAendern() {
    const result = this.passwortAendernValidation.instance.validate()
    if (!result.isValid) {
      return;
    }
    if (this.passwortNeu.instance.option("value") !== this.passwortNeuRepeat.instance.option("value")) {
      DevExpress.ui.notify(this.localization.translateOnce("passwort-aendern.passwort_eingabe_error"), "error", 3000);
      return;
    }

    const webEvent = new OnPasswortAendernEvent({
      PasswortAlt: this.passwortAlt.instance.option("value"),
      PasswortNeu: this.passwortNeu.instance.option("value")

    });

    this.webEvent
      .execute(webEvent, true)
      .then(r => {
        DevExpress.ui.notify(this.localization.translateOnce("passwort-aendern.aenderung_uebernehmen"), "success", 3000);
        this.passwortAendernPopup.instance.hide();
        this.passwortNeuRepeat.instance.reset();
        this.passwortAendernValidation.instance.reset();
      });
  }
}
