import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/export";

@autoinject
export class GeschaeftspartnerService {
  constructor(
    private _restService: RestService
  ) { }

  getGeschaeftspartnerBasicDataById(idGeschaeftspartner: number): Promise<any> {
    const getOptions = {
      columns: ["Id", "Nummer", "Telefon"]
    };

    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/Stammdaten/Geschaeftspartner")}/${idGeschaeftspartner}`,
      getOptions: getOptions
    });
  }
  getGeschaeftspartnerById(idGeschaeftspartner: number, needsAdresse: boolean = true): Promise<any> {
    const getOptions: any = {};

    if (needsAdresse) {
      getOptions.expand = {
        Adresse: null
      };
    }

    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/Stammdaten/Geschaeftspartner")}/${idGeschaeftspartner}`,
      getOptions: getOptions,
      increaseLoadingCount: true
    });
  }
  getGeschaeftspartnerByNummer(nummer: string, needsAdresse: boolean = true, where: any = null): Promise<any> {
    const getOptions: any = {
      where: ["Nummer", nummer]
    };

    if (needsAdresse) {
      getOptions.expand = { Adresse: null };
    }

    if (where) {
      getOptions.where = [getOptions.where, where];
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Geschaeftspartner"),
      getOptions: getOptions,
      increaseLoadingCount: true
    });
  }
  getGeschaeftspartnerBySearchtextEindeutig(searchtext: string, needsAdresse: boolean = true, where: any = null): Promise<any[]> {
    const getOptions: any = {
      searchtext: searchtext,
      take: 2
    };

    if (needsAdresse) {
      getOptions.expand = {
        Adresse: null
      };
    }

    if (where) {
      getOptions.where = where;
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Geschaeftspartner"),
      getOptions: getOptions,
      increaseLoadingCount: true
    });
  }
  getGeschaeftspartnerBankverbindungList(idGeschaeftspartner: number, where: any = null): Promise<any> {
    const getOptions: any = {
      where: ["IdGeschaeftspartner", idGeschaeftspartner],
    };

    if (where) {
      getOptions.where = [getOptions.where, where];
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Bankverbindung"),
      getOptions: getOptions
    });
  }
}
