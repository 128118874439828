import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase
} from "../../../../framework/forms/export";
import {
  IKalkulationRefreshOptions
} from "../../../interfaces/kalkulation-refresh-options";
import {
  KalkulationRefreshService
} from "../../../services/export";

@autoinject
@transient()
export class KundenreklamationspositionEditUtils {
  constructor(
    private _kalkulationRefreshService: KalkulationRefreshService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  onReloadStuecklistenkomponentenlist: boolean = false;

  bind(form: FormBase, namespace: string) {
    this.form = form;
    this.refreshOptions = {
      form: form,
      modelName: "$m_Kundenreklamationsposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeVerpEH"
    };

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "kundenreklamationspositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    this.form.models.onSaved.register(async (e) => {
      if (this.form.models.data.$m_Kundenreklamationsposition.IsSetartikel) {
        if (this.onReloadStuecklistenkomponentenlist) {
          this.onReloadStuecklistenkomponentenlist = false;
          this.form.models.reloadAll();
        }
      }
    });
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Kundenreklamationsposition) {
      return;
    }

    if (this.form.models.data.$m_Kundenreklamationsposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistenkomponentenlist = true;
    }
  }


  onErsatzartikelChanged(ev: CustomEvent) {
    const data = this.form.models.data.$m_Kundenreklamationsposition;

    if (ev.detail.value && data.ErsatzartikelPreisbasis == void (0)) {
      data.ErsatzartikelPreisbasis = 1;
    } else if (!ev.detail.value && data.ErsatzartikelPreisbasis != void (0)) {
      data.ErsatzartikelPreisbasis = null;
    }
  }
}
