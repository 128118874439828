import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEdit {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IAbreitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;

  arbeitszeitmusterList: any[];
  abrechnungsmodellList: any[];

  arbeitszeitAbrechnungsmodellArbeitszeitmuster: IArbeitszeitAbrechnungsmodellArbeitszeitmuster

  arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopup: Interfaces.IdxPopupComponent;
  arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEdit",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopup.instance.hide();
      this._options.callback(this.arbeitszeitAbrechnungsmodellArbeitszeitmuster);
    }
  }];

  arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditValidation: any;
  arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  gueltigVonDateBox: Interfaces.IdxDateBoxComponent;
  gueltigVonDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitAbrechnungsmodellArbeitszeitmuster.GueltigVon",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  arbeitszeitmusterSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "arbeitszeitmusterList",
      value: "arbeitszeitAbrechnungsmodellArbeitszeitmuster.IdArbeitszeitmuster",
    }
  };

  abrechnungsmodellSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "abrechnungsmodellList",
      value: "arbeitszeitAbrechnungsmodellArbeitszeitmuster.IdAbrechnungsmodell",
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitMitarbeiterstatusToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.abrechnungsmodell-arbeitszeitmuster",
      options: this.arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopupOptions,
      commands: this.arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.loadArbeitszeitmusterDatasource();
    this.loadAbrechnungsmodellDatasource();
  }


  async show(options: IAbreitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditOptions) {
    this._options = options;
    this.arbeitszeitAbrechnungsmodellArbeitszeitmuster = null;
    this.arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }

    if (this.arbeitszeitAbrechnungsmodellArbeitszeitmuster.GueltigVon < this._options.mitarbeiterstatus.Eintrittsdatum) {
      DevExpress.ui.notify(
        "Gültig von Datum darf nicht kleiner als Eintrittsdatum sein",
        "error",
        this.NOTIFY_TIMEOUT);
      return false;
    }

    return true;
  }

  private async loadArbeitszeitmusterDatasource() {
    this.arbeitszeitmusterList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/arbeitszeitmuster")
    });
  }
  private async loadAbrechnungsmodellDatasource() {
    this.abrechnungsmodellList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/abrechnungsmodell")
    });
  }
}

export interface IAbreitszeitAbrechnungsmodellArbeitszeitmusterZuteilungEditOptions {
  mitarbeiterstatus: IArbeitszeitMitarbeiterstatus,
  callback: { (arbeitszeitAbrechnungsmodellArbeitszeitmuster: IArbeitszeitAbrechnungsmodellArbeitszeitmuster): void };
}

export interface IArbeitszeitAbrechnungsmodellArbeitszeitmuster {
  IdAbrechnungsmodell: number;
  IdArbeitszeitmuster: number;
  GueltigVon: Date;
}
