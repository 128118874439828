import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BriefListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "brief-list";
        this.title = "brief-list.brief-list_caption";
        this.addModel({
            "id": "$m_Brief",
            "webApiAction": "ERP/Stammdaten/Brief",
            "webApiExpand": {
                'Status': null,
                'Projekt': null,
                'Sachbearbeiter': null,
                'Firma': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_briefGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_brFirma",
                "caption": "brief-list.r_brfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_brSachbearbeiter",
                "caption": "brief-list.r_brsachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_brProjekt",
                "caption": "brief-list.r_brprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_brDatum",
                "caption": "brief-list.r_brdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_empfaenger",
                "caption": "brief-list.r_empfaenger_caption",
                "bindTo": "EmpfaengerDisplayText",
                "minWidth": 100
            }, {
                "id": "r_brBetreff",
                "caption": "brief-list.r_brbetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_brText",
                "caption": "brief-list.r_brtext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_brAnzAnhaenge",
                "caption": "brief-list.r_branzanhaenge_caption",
                "bindTo": "AnzAnhaenge",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_brStatus",
                "caption": "brief-list.r_brstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_briefGridToolbarOptions",
                "optionsNameFQ": "r_briefGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Brief"
            },
            "dataModel": "$m_Brief",
            "editUrl": "ERP/Stammdaten/Brief",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_briefGrid",
            "options": {
                "optionsName": "r_briefGridOptions",
                "optionsNameFQ": "r_briefGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}