import { autoinject } from "aurelia-framework";
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';
import { DataSourceService, ScopeContainer, RestService } from '../../../../framework/base/export';

@autoinject
export class AuswertungSettings {
  constructor(
    private _dataSourceService: DataSourceService,
    private _restService: RestService
  ) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IAuswertungSettings;
  scopeContainer: ScopeContainer;

  dataSource: any[];

  auswertungOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Name",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "dataSource",
      value: "settings.idAuswertung",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  };

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.loadDataSource();
    if (this.settings.idAuswertung) {
      this.reloadData();
    }
  }
  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  private async loadDataSource() {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("Framework/Report/Definition"),
      getOptions: {
        where:["IsVisible",true],
        columns: ["Id","Name"]
      }
    });
    this.dataSource = r;
  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }
    this.widgetViewModel.loadData();
  }
}

export interface IAuswertungSettings {
  idAuswertung: number;
}
