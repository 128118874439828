import {
  autoinject
} from "aurelia-framework";
import {
  DxTemplateService
} from "../../framework/dx/export";

import * as cboTypeBezeichnung from "html-loader!../templates/cbo-type-bezeichnung.xhtml";
import * as cboTypeBezeichnungField from "html-loader!../templates/cbo-type-bezeichnung-field.xhtml";
import * as cboTypeBezeichnungTag from "html-loader!../templates/cbo-type-bezeichnung-tag.xhtml";

@autoinject
export class TemplateRegisterService {
  constructor(
    private dxTemplate: DxTemplateService
  ) { }

  register() {
    this.dxTemplate.registerTemplate(
      "erp--cbo-type-bezeichnung",
      <string>cboTypeBezeichnung);

    this.dxTemplate.registerTemplate(
      "erp--cbo-type-bezeichnung-field-template",
      <string>cboTypeBezeichnungField);

    this.dxTemplate.registerTemplate(
      "erp--cbo-type-bezeichnung-tag-template",
      <string>cboTypeBezeichnungTag);
  }
}