import { autoinject } from 'aurelia-framework';
import { RestService } from '../../../../framework/base/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';
import { GlobalizationService } from './../../../../framework/base/services/globalization-service';

@autoinject
export class TopKundenSettings {
  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: ITopKundenSettings;
  firmaDataSource: any[];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  };

  geschaeftspartnerartOptions: DevExpress.ui.dxSelectBoxOptions = {
    items: [
      { id: TopKundenGPArt.Kunde, text: "Kunde" },
      { id: TopKundenGPArt.Lieferant, text: "Lieferant" }],
    valueExpr: "id",
    displayExpr: "text",
    bindingOptions: {
      value: "settings.art"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  anzahlOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    min: 3,
    max: 50,
    bindingOptions: {
      value: "settings.anzahl"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  };

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    if (this.settings.art == void (0)) {
      this.settings.art = TopKundenGPArt.Kunde;
    }

    this.loadFirmaDataSource();
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      },
      increaseLoadingCount: true
    });
  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }

    this.widgetViewModel.loadData();
  }
}
export interface ITopKundenSettings {
  anzahl?: number;
  art?: TopKundenGPArt;
  idFirma?: number;
}
export enum TopKundenGPArt {
  Kunde = 0,
  Lieferant = 1
}
