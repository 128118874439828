import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    FakturaEditUtils
} from "./faktura-edit-utils";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class FakturaEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_FakturaEditUtils: FakturaEditUtils,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "faktura-edit";
        this.title = "faktura-edit.faktura-edit_caption";
        this.addModel({
            "id": "$m_Faktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "webApiExpand": {
                'Status': null,
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturapositionRel",
            "webApiAction": "ERP/Verkauf/Fakturaposition",
            "webApiExpand": {
                'Artikel': null,
                'Fakturagruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'StornoVon': {
                    columns: ["IdFaktura"],
                    'expand': {
                        'Faktura': {
                            columns: ["Nummer"]
                        }
                    }
                },
                'Positionsart': null
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Faktura.CanSave && models.data.$m_Faktura.Typ != 1",
            "filters": [{
                "webApiCustomKey": "AddMehrfachbearbeitungTypeName",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_FakturapositionEdit",
            "webApiAction": "ERP/Verkauf/Fakturaposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturagruppeRel",
            "webApiAction": "ERP/Verkauf/Fakturagruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Faktura.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_FakturagruppeEdit",
            "webApiAction": "ERP/Verkauf/Fakturagruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaEditUtils.doAbschliessenCommand",
                "bindToFQ": "functions.$f_FakturaEditUtils.doAbschliessenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaEditUtils.doStornierenCommand",
                "bindToFQ": "functions.$f_FakturaEditUtils.doStornierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaEditUtils.doBezahltAendernCommand",
                "bindToFQ": "functions.$f_FakturaEditUtils.doBezahltAendernCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaEditUtils.doMahnenCommand",
                "bindToFQ": "functions.$f_FakturaEditUtils.doMahnenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaEditUtils.doExport",
                "bindToFQ": "functions.$f_FakturaEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "fakturapositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_FakturapositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_FakturapositionEdit.Id"
                }
            }],
            "id": "fakturapositionEditPopup",
            "options": {
                "optionsName": "fakturapositionEditPopupOptions",
                "optionsNameFQ": "fakturapositionEditPopupOptions"
            },
            "caption": "faktura-edit.fakturapositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "fakturagruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_FakturagruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_FakturagruppeEdit.Id"
                }
            }],
            "id": "fakturagruppeEditPopup",
            "options": {
                "optionsName": "fakturagruppeEditPopupOptions",
                "optionsNameFQ": "fakturagruppeEditPopupOptions"
            },
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "zahlungseingangEditPopupContent",
            "mappings": [{
                "to": "$v_IdFaktura",
                "binding": {
                    "dataContext": "$m_Faktura",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_Faktura.Id"
                }
            }, {
                "to": "$id",
                "binding": {
                    "dataContext": "$f_FakturaEditUtils",
                    "bindTo": "id0",
                    "bindToFQ": "functions.$f_FakturaEditUtils.id0"
                }
            }],
            "id": "zahlungseingangEditPopup",
            "options": {
                "optionsName": "zahlungseingangEditPopupOptions",
                "optionsNameFQ": "zahlungseingangEditPopupOptions"
            },
            "height": "auto",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_FakturaEditUtils", $f_FakturaEditUtils, "functions.$f_FakturaEditUtils");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "faktura-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Faktura.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "faktura-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Faktura.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "faktura-edit.r_datum_caption",
            "isReadOnlyExpression": "models.data.$m_Faktura.Nummer",
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Faktura.Datum"
            },
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fakturaBezahltTyp",
            "filters": [],
            "caption": "faktura-edit.r_bezahlttyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BezahltTyp",
                "bindToFQ": "models.data.$m_Faktura.BezahltTyp"
            },
            "validationRules": [],
            "id": "r_bezahltTyp",
            "options": {
                "optionsName": "r_bezahltTypOptions",
                "optionsNameFQ": "r_bezahltTypOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "faktura-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabGruppen",
                "caption": "faktura-edit.r_tabgruppen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "faktura-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fakpPosNr",
                "caption": "faktura-edit.r_fakpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_fakpArtikelNummer",
                "caption": "faktura-edit.r_fakpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_fakpBezeichnung",
                "caption": "faktura-edit.r_fakpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fakpStornoVon",
                "caption": "faktura-edit.r_fakpstornovon_caption",
                "bindTo": "StornoVon.Faktura.Nummer",
                "width": "80px"
            }, {
                "id": "r_fakpMengeIstVerpEH",
                "caption": "faktura-edit.r_fakpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_fakpMengeStorniertVerpEH",
                "caption": "faktura-edit.r_fakpmengestorniertverpeh_caption",
                "bindTo": "MengeStorniertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_fakpEinheit",
                "caption": "faktura-edit.r_fakpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_fakpPositionsart",
                "caption": "faktura-edit.r_fakppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_fakpFakturagruppe",
                "caption": "faktura-edit.r_fakpfakturagruppe_caption",
                "bindTo": "Fakturagruppe.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fakpWertNetto",
                "caption": "faktura-edit.r_fakpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_fakturapositionGridToolbarOptions",
                "optionsNameFQ": "r_fakturapositionGridToolbarOptions"
            },
            "caption": "faktura-edit.r_fakturapositiongrid_caption",
            "binding": {
                "dataContext": "$m_Faktura"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_FakturapositionRel",
                "bindTo": "IdFaktura",
                "bindToFQ": "models.data.$m_FakturapositionRel.IdFaktura"
            },
            "dataModel": "$m_Faktura",
            "editDataContext": "$m_FakturapositionEdit",
            "idEditPopup": "fakturapositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Faktura",
                    "bindTo": "$f_FakturaEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_FakturaEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Faktura",
                    "bindTo": "$f_FakturaEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_FakturaEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_fakturapositionGrid",
            "options": {
                "optionsName": "r_fakturapositionGridOptions",
                "optionsNameFQ": "r_fakturapositionGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anggPosNr",
                "caption": "faktura-edit.r_anggposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_anggBezeichnung",
                "caption": "faktura-edit.r_anggbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_anggWertnetto",
                "caption": "faktura-edit.r_anggwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_fakturagruppeGridToolbarOptions",
                "optionsNameFQ": "r_fakturagruppeGridToolbarOptions"
            },
            "caption": "faktura-edit.r_fakturagruppegrid_caption",
            "binding": {
                "dataContext": "$m_Faktura"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_FakturagruppeRel",
                "bindTo": "IdFaktura",
                "bindToFQ": "models.data.$m_FakturagruppeRel.IdFaktura"
            },
            "dataModel": "$m_Faktura",
            "editDataContext": "$m_FakturagruppeEdit",
            "idEditPopup": "fakturagruppeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fakturagruppeGrid",
            "options": {
                "optionsName": "r_fakturagruppeGridOptions",
                "optionsNameFQ": "r_fakturagruppeGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "faktura-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Faktura.Id > 0",
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Faktura.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "faktura-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Faktura.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "faktura-edit.r_preisdatum_caption",
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Faktura.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "faktura-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Faktura.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "faktura-edit.r_uidnummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "UidNummer",
                "bindToFQ": "models.data.$m_Faktura.UidNummer"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_uidNummer",
            "options": {
                "optionsName": "r_uidNummerOptions",
                "optionsNameFQ": "r_uidNummerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "faktura-edit.r_zahlungfaelligkeit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "ZahlungFaelligkeit",
                "bindToFQ": "models.data.$m_Faktura.ZahlungFaelligkeit"
            },
            "validationRules": [],
            "id": "r_zahlungFaelligkeit",
            "options": {
                "optionsName": "r_zahlungFaelligkeitOptions",
                "optionsNameFQ": "r_zahlungFaelligkeitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragbezahltvorauskassa_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragBezahltVorauskassa",
                "bindToFQ": "models.data.$m_Faktura.BetragBezahltVorauskassa"
            },
            "validationRules": [],
            "id": "r_betragBezahltVorauskassa",
            "options": {
                "optionsName": "r_betragBezahltVorauskassaOptions",
                "optionsNameFQ": "r_betragBezahltVorauskassaOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragbezahltgutschein_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragBezahltGutschein",
                "bindToFQ": "models.data.$m_Faktura.BetragBezahltGutschein"
            },
            "validationRules": [],
            "id": "r_betragBezahltGutschein",
            "options": {
                "optionsName": "r_betragBezahltGutscheinOptions",
                "optionsNameFQ": "r_betragBezahltGutscheinOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragbezahltabschlagsfakturen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragBezahltAbschlagsfakturen",
                "bindToFQ": "models.data.$m_Faktura.BetragBezahltAbschlagsfakturen"
            },
            "validationRules": [],
            "id": "r_betragBezahltAbschlagsfakturen",
            "options": {
                "optionsName": "r_betragBezahltAbschlagsfakturenOptions",
                "optionsNameFQ": "r_betragBezahltAbschlagsfakturenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragbezahlt_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragBezahlt",
                "bindToFQ": "models.data.$m_Faktura.BetragBezahlt"
            },
            "validationRules": [],
            "id": "r_betragBezahlt",
            "options": {
                "optionsName": "r_betragBezahltOptions",
                "optionsNameFQ": "r_betragBezahltOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragskontoabzug_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragSkontoAbzug",
                "bindToFQ": "models.data.$m_Faktura.BetragSkontoAbzug"
            },
            "validationRules": [],
            "id": "r_betragSkontoAbzug",
            "options": {
                "optionsName": "r_betragSkontoAbzugOptions",
                "optionsNameFQ": "r_betragSkontoAbzugOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "faktura-edit.r_betragoffen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "BetragOffen",
                "bindToFQ": "models.data.$m_Faktura.BetragOffen"
            },
            "validationRules": [],
            "id": "r_betragOffen",
            "options": {
                "optionsName": "r_betragOffenOptions",
                "optionsNameFQ": "r_betragOffenOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "faktura-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Faktura",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Faktura.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}