export interface IWidget {
  title: string;
  viewModel: string;
  settingViewModel?: string;
  settings?: any;

  supportedDashboardTyp: SupportedDashboardTyp[];

  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

export enum SupportedDashboardTyp {
  Keine = 0,
  Allgemein = 1,
  Geschaeftspartner = 2,
  Artikel = 3
}
