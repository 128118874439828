import { autoinject } from "aurelia-framework";
import { IState } from '../interfaces/state';
import { RestService, JsonService } from '../../base/export';

@autoinject
export class DataService {
  constructor(
    private restService: RestService,
    private jsonService: JsonService
  ) {}

  async getReport(id: string) {
    return this.restService.get({
      url: this.restService.getApiUrl("base/Report/Definition/".concat(id))
    });
  }
  async getData(id: string, idDataSource: string, state: IState) {
    return await this.restService.post({
      url: this.restService.getApiUrl("base/Report/DataSource"),
      data: {
        idReport: id,
        idDataSource: idDataSource,
        state: state
      }
    });
  }
}