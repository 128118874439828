import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase,
  PopupInfoService
} from "../../../../framework/forms/export";
import {
  OnInventurVerbuchenEvent
} from "../../../../framework-data/events";
import {
  WebEventService
} from "../../../../framework/base/export";

import {
  EntitaetInfoService, VerknuepfteEntitaetService
} from "../../../services/export";

@autoinject
@transient()
export class BenachrichtigungEditUtils {
  constructor(
    private entitaetInfo: EntitaetInfoService,
    private popupInfo: PopupInfoService,
    private verknuepfteEntitaet: VerknuepfteEntitaetService,
    private webEvent: WebEventService
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register(r => {
      if (r == void 0 || r.model.id != "$m_Benachrichtigung" || r.data == void 0) {
        return;
      }

      this.verknuepfteEntitaetCommand.isVisible = this.form.models.data.$m_Benachrichtigung.IdVerknuepfteEntitaet != void 0;

      this.doBenachrichtigungGelesen.isVisible = this.form.models.data.$m_Benachrichtigung.Id != void 0
        && !this.form.models.data.$m_Benachrichtigung.IsGelesen;

      return Promise.resolve();
    });
  }

  verknuepfteEntitaetCommand: ICommandData = {
    id: "verknuepfteEntitaet",
    idCategory: "benachrichtigung",
    title: "benachrichtigung.verknuepfte-entitaet-command-text",
    icon: "link",
    sortIndex: 99,
    isVisible: false,
    execute: this.onVerknuepfteEntitaetCommandClicked.bind(this)
  }

  doBenachrichtigungGelesen: ICommandData = {
    id: "doBenachrichtigungGelesen",
    idCategory: "benachrichtigung",
    title: "benachrichtigung.gelesen-command-text",
    icon: "check",
    isVisible: false,
    execute: this.doGelesen.bind(this)
  }

  async onVerknuepfteEntitaetCommandClicked(e) {
    const idVerknuepfteEntitaet = this.form.models.data.$m_Benachrichtigung.IdVerknuepfteEntitaet;
    if (!idVerknuepfteEntitaet) {
      return;
    }

    await this.popupInfo.closeAllPopups();

    this.verknuepfteEntitaet
      .goTo(idVerknuepfteEntitaet, true);
  }

  private async doGelesen() {
    this.form.models.data.$m_Benachrichtigung.IsGelesen = true;
    await this.form.save();

    if (this.form.isEditPopup) {
      this.popupInfo.closeCurrentPopup();
    }
  }
}
