import { autoinject, transient } from "aurelia-framework";
import { LocalizationService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase } from "../../../../framework/forms/export";
import { AngebotService } from "../../../services/angebot-service";

@autoinject
@transient()
export class AngebotsgruppeEditUtils {
  constructor(
    private _angebotService: AngebotService,
    private _asyncService: AsyncService,
    private _localizationService: LocalizationService) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.onDeleting.register(async (e) => {
      if (await this._angebotService.hasAngebotsgruppePositionenZugeteilt(this.form.models.data.$m_Angebotsgruppe.Id)) {
        const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
          this._localizationService.translateOnce("base.sure_delete_question_gruppe"),
          this._localizationService.translateOnce("base.question")));

        if (!doExecute) {
          return Promise.reject();
        }
      }
      return Promise.resolve();
    });
  }
}
