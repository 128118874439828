import { autoinject, bindable, OverrideContext, observable } from "aurelia-framework";
import { ScopeContainer } from '../../../../framework/base/export';
import { OnInfobarDynFeldRequestEvent, OnInfobarDynFeldRequest } from '../../../../framework-data/events';
import { WebEventService } from '../../../../framework/base/services/web-event-service';

@autoinject
export class DynamischeFelder {
  private _loadedId: number;

  constructor(
    private webEvent: WebEventService
  ) {}

  @bindable @observable id: number;
  @bindable titel: string;

  isVisible: boolean = false;
  itemList: any[] = [];
  scopeContainer: ScopeContainer;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }
  attached() {
    this.loadData();
  }
  idChanged() {
    this.loadData();
  }

  private async loadData() {
    if (this._loadedId == this.id) {
      return;
    }

    this._loadedId = this.id;
    if (this.id) {
      const result: OnInfobarDynFeldRequest = await this.webEvent.execute(new OnInfobarDynFeldRequestEvent({
        Id: this.id
      }));

      const isVisible = result && result.ItemList.length > 0;
      if (isVisible) {
        this.itemList = result.ItemList;
        this.isVisible = isVisible;
      } else {
        this.isVisible = isVisible;
      }
    } else {
      this.isVisible = false;
    }
  }
}
