import {
  autoinject,
  OverrideContext,
  Scope
} from "aurelia-framework";
import {
  EventAggregator,
  Subscription
} from 'aurelia-event-aggregator';
import {
  DataSourceService,
  ScopeContainer,
  WebEventService,
  IViewScrollInfo
} from "../../../framework/base/export";
import {
  FormBase,
  ListView
} from "../../../framework/forms/export";
import {
  IListViewOptions
} from '../../../framework/forms/elements/list-view/export';
import {
  ICommandData
} from "../../../framework/forms/interfaces/export";
import {
  OnNotizAnzahlRequestEvent
} from './../../../framework-data/events';
import {
  FormCommandService
} from "../../services/export";
import {
  CustomEditPopupService,
  HeaderCommandService
} from "../../services/export";
import {
  HeaderCommandEvent,
  NotificationTyp,
  Statustyp
} from "../../enumerations/export";
import {
  IdxPopoverComponent,
  IFormCommandExecuteOptions
} from '../../interfaces/export';
import { isMoment } from 'moment';

@autoinject
export class NotizFormCommand {
  private _availableForms: FormBase[] = [];
  private _detachedAddCommand: { (): void };
  private _detachedShowCommand: { (): void };
  private _idVerknuepfteEntitaet: number;
  private _notizFormCommandSubscription: Subscription;
  private _notizShowEditPopupSubscription: Subscription;
  private _notizShowListSubscription: Subscription;
  constructor(
    private customEditPopup: CustomEditPopupService,
    private dataSource: DataSourceService,
    private eventAggregator: EventAggregator,
    private formCommand: FormCommandService,
    private webEvent: WebEventService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  needsReload: boolean = false;

  notiz: IdxPopoverComponent;
  notizOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400,
    onShowing: (e) => {
      (<DevExpress.ui.dxPopover>e.component).repaint();
      this.notizListView.refresh();
    }
  };

  notizListView: ListView;
  notizListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    pageSize: 15,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const notiz = e.item;
      this.showNotizPopup(notiz.Id, this.getViewScrollInfo(e.item));
    }
  }

  showNotizFormCommand: ICommandData = {
    id: "showNotizFormCommand",
    idCategory: "notizen",
    title: "form-command.notiz-command-icon-text",
    icon: "tasks",
    isEnabled: false,
    badgeText: null,
    badgeColor: "#297FB8",
    sortIndex: 8100
  }
  addNotizFormCommand: ICommandData = {
    id: "$addNotiz",
    idCategory: "notizen",
    icon: "plus",
    title: "form-command.notiz-command-neu",
    isEnabled: false,
    sortIndex: 81001
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.notizListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Notiz",
        webApiOrderBy: [{ columnName: "GueltigBis", sortOrder: 0 }, { columnName: "Id", sortOrder: 1 }]
      },
      {
        getCustomWhere: () => {
          if (this._idVerknuepfteEntitaet == void 0) {
            return null;
          }

          return ["IdVerknuepfteEntitaet", this._idVerknuepfteEntitaet];
        }
      }
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    this._notizFormCommandSubscription = this.eventAggregator
      .subscribe(
        NotificationTyp.notizAnzahlChanged,
        this.onNotizAnzahlChanged.bind(this)
      );

    this._notizShowEditPopupSubscription = this.eventAggregator
      .subscribe(
        NotificationTyp.notizShowEditPopup, (e) => {
          this.showNotizPopup(e.id, e.viewScrollInfo);
        });

    this._notizShowListSubscription = this.eventAggregator
      .subscribe(
        NotificationTyp.notizShowListPopover, (e) => {
          this.showNotizPopover(e.id, e.target);
        });

    this._detachedShowCommand = this.formCommand.attachFormCommand(
      this.showNotizFormCommand,
      {
        isCommandVisibleModelProperty: "SupportsNotiz",
        execute: this.onShowNotizenClicked.bind(this),
        onFormModelLoaded: this.loadNotizBadge.bind(this),
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.deleteAvailableForm.bind(this)
      });

    this._detachedAddCommand = this.formCommand.attachFormCommand(
      this.addNotizFormCommand,
      {
        isCommandVisibleModelProperty: "SupportsNotiz",
        execute: this.onAddNotizClicked.bind(this)
      }
    );
  }
  detached() {
    this._notizFormCommandSubscription.dispose();
    this._notizShowEditPopupSubscription.dispose();
    this._notizShowListSubscription.dispose();
    this._detachedShowCommand();
    this._detachedAddCommand();
  }

  isNotizAlt(item) {
    if (!item.GueltigBis) {
      return false;
    }

    return item.GueltigBis.getTime() < new Date().getTime();
  }
  showNotizPopup(id: number, viewScrollInfo?: IViewScrollInfo) {
    this.customEditPopup
      .notizEditPopup
      .show({
        mappings: { "$id": id },
        viewScrollInfo: viewScrollInfo
      });
    this.notiz.instance.hide();
  }
  showNotizPopover(id: number, target: Element){
    this._idVerknuepfteEntitaet = id;
    this.notiz.instance.show(target);
  }
  private onAddNotizClicked(e: IFormCommandExecuteOptions) {
    this._idVerknuepfteEntitaet = e.modelData.Id;
    this.customEditPopup.notizEditPopup.show({
      mappings: { "$id": 0 },
      setValuesOnModelWithKeyIdLoaded: { IdVerknuepfteEntitaet: e.modelData.Id }
    });
  }
  private onNotizAnzahlChanged(data) {
    if (!data || !data.IdVerknuepfteEntitaet) {
      return;
    }

    this.loadNotizBadge({ Id: data.IdVerknuepfteEntitaet });
  }
  private onShowNotizenClicked(e: IFormCommandExecuteOptions) {
    const element = this.formCommand.getCommandTarget(e.options.event);

    this.showNotizPopover(e.modelData.Id, element);
  }

  private getViewScrollInfo(notiz): IViewScrollInfo {
    const dataSource = this.notizListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(notiz);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
  private loadNotizBadge(data, command?: ICommandData) {
    if (data == void 0 || data.Id == void 0) {
      return;
    }

    const commandToReload = command || this.formCommand.getReloadCommand(this.showNotizFormCommand, this._availableForms, {
      modelPropertyName: "Id",
      modelPropertyValue: data.Id
    })

    if (!commandToReload) {
      return;
    }

    const notizAnzahlEvent = new OnNotizAnzahlRequestEvent({
      IdVerknuepfteEntitaet: data.Id
    });
    this.webEvent
      .execute(notizAnzahlEvent)
      .then(r => {
        if (r == void 0 || r.IdVerknuepfteEntitaet == void 0) {
          return;
        }
        if (r.Anzahl == 0 || r.Anzahl == void 0) {
          commandToReload.isEnabled = false;
          commandToReload.badgeText = null;
          return;
        }

        commandToReload.isEnabled = true;
        commandToReload.badgeText = {
          key: "base.param_1",
          parameters: [r.Anzahl]
        };
      });

  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private deleteAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1)
  }
}
