import {
  autoinject,
  OverrideContext,
  Scope
} from "aurelia-framework";
import {
  EventAggregator,
  Subscription
} from 'aurelia-event-aggregator';

import {
  DataSourceService,
  ScopeContainer,
  LocalizationService,
  WebEventService,
  IViewScrollInfo
} from "../../../framework/base/export";
import {
  OnAufgabeAnzahlRequestEvent,
  OnAufgabeErledigenRequestEvent,
  OnAufgabeAnzahlRequest
} from "../../../framework-data/events";
import {
  PopupInfoService
} from "../../../framework/forms/services/export";
import {
  ListView,
  IListViewOptions
} from "../../../framework/forms/elements/list-view/export";
import {
  ICommandData
} from "../../../framework/forms/interfaces/export";
import {
  EntitaetInfoService,
  HeaderCommandService,
  CustomEditPopupService,
  StartupService
} from "../../services/export";
import {
  IdxPopoverComponent,
} from "../../interfaces/export";
import {
  Statustyp,
  HeaderCommandEvent,
  NotificationTyp
} from "../../enumerations/export";

import * as moment from "moment";
import { LocationService } from '../../../framework/base/services/location-service';

@autoinject
export class AufgabeCommand {
  private _commandShowingSubscription: Subscription;
  private _anzahlChangedSubscription: Subscription;
  private _idProjekt: number;
  private _aufgabeBenutzer: boolean;

  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _dataSourceService: DataSourceService,
    private _entitaetInfoService: EntitaetInfoService,
    private _eventAggregator: EventAggregator,
    private _headerCommandService: HeaderCommandService,
    private _localizationService: LocalizationService,
    private _popupInfoService: PopupInfoService,
    private _startupService: StartupService,
    private _webEventService: WebEventService,
    private _locationService: LocationService
  ) { }

  idVerknuepfteEntitaet: number;
  aufgabeUrl: string;
  isHeaderCommand: boolean;

  scope: Scope;
  scopeContainer: ScopeContainer;
  needsReload: boolean = false;
  heute: Date;

  aufgabe: IdxPopoverComponent;
  aufgabeOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400,
    onShowing: (e) => {
      (<DevExpress.ui.dxPopover>e.component).repaint();
      if (this.needsReload) {
        this.aufgabeListView.refresh();
      }
    }
  };

  aufgabeListView: ListView;
  aufgabeListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    pageSize: 15,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const aufgabe = e.item;
      this.showAufgabePopup(aufgabe);
    }
  };

  aufgabeStatusAendernButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-check fa-fw",
    onClick: (e) => {
      //TODO
      const event: Event = <any>e.event;
      event.stopPropagation();

      this.erledigenAufgabe(e.model.bindingContext.item);
    }
  };
  newAufgabeButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("form-command.aufgabe-command-neu"),
    width: "100%",
    onClick: this.onNewAufgabeClicked.bind(this)
  };
  showAllAufgabeButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this._localizationService.translateOnce("form-command.aufgabe-command-alle-anzeigen"),
    width: "100%",
    onClick: this.onAlleAnzeigenClicked.bind(this)
  };

  attached() {
    this._headerCommandService.aufgabe = this;
    this._anzahlChangedSubscription = this._eventAggregator
      .subscribe(
        NotificationTyp.aufgabeAnzahlChanged,
        this.loadAnzahl.bind(this)
      );

    this._commandShowingSubscription = this._eventAggregator
      .subscribe(
        HeaderCommandEvent.onShowingPopover,
        (command: ICommandData) => {
          if (this._headerCommandService.aufgabeCommand.id != command.id) {
            this.aufgabe.instance.hide();
          }
        }
      );
  }
  detached() {
    this._commandShowingSubscription.dispose();
    this._anzahlChangedSubscription.dispose();
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.aufgabeListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiColumns: ["Id", "Betreff", "FaelligAm", "ErinnernAm", "IdGeschaeftspartner", "IdSachbearbeiter", "IsPrioritaet"],
        webApiAction: "ERP/Stammdaten/Aufgabe",
        webApiWhere: ["Status.Typ", Statustyp.Offen],
        webApiExpand: {
          Geschaeftspartner: {
            columns: ["Id", "EntitaetBezeichnung"]
          },
          Sachbearbeiter: {
            columns: ["Id", "Name"]
          }
        },
        filters: [{
          webApiCustomKey: "InklIdStatusFarbe",
          webApiCustomValue: "true"
        }, {
          webApiCustomKey: "AddTags",
          webApiCustomValue: "true"
        }]
      },
      {
        getCustomWhere: () => {
          if (this.idVerknuepfteEntitaet == void 0) {
            return null;
          }

          return ["IdVerknuepfteEntitaet", this.idVerknuepfteEntitaet];
        },
        getCustomFilters: () => {
          if (!this._aufgabeBenutzer) {
            return;
          }

          return [{ webApiCustomKey: "AufgabeBenutzer", webApiCustomValue: "true" }];
        }
      }
    );

    this.loadAnzahl();

    this._entitaetInfoService.getUrl("TIP.ERP.Business.Entitaeten.Stammdaten.Aufgabe")
      .then(r => {
        this.aufgabeUrl = r;
      });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  showPopover(targetElement: Element, modelData: any) {
    if (this._headerCommandService.aufgabeCommand.isEnabled === false) {
      return;
    }

    this.setModelData(modelData);
    this.heute = moment().startOf("day").toDate();

    this.isHeaderCommand = targetElement.firstElementChild.className === "t--header-command";

    this.aufgabe.instance
      .show(targetElement)
      .done(() => {
        if (!this.needsReload) {
          this.needsReload = true;
        }
      });
  }
  goToAufgabeList() {
    this._locationService.goTo({
      url: "ERP/Stammdaten/Aufgabe",
      clearStack: false
    });
  }
  showAufgabePopup(aufgabe) {
    this._customEditPopupService
      .aufgabeEditPopup
      .show({
        mappings: { "$id": aufgabe.Id },
        viewScrollInfo: this.getViewScrollInfo(aufgabe)
      });
    this.aufgabe.instance.hide();
  }

  private erledigenAufgabe(aufgabe: any) {
    if (aufgabe == void 0 || aufgabe.Id == void 0) {
      return;
    }

    const aufgabeErledigenEvent = new OnAufgabeErledigenRequestEvent({ IdAufgabe: aufgabe.Id });

    this._webEventService
      .execute(aufgabeErledigenEvent, true)
      .then(r => {
        this.aufgabeListViewOptions.dataSource.reload();
        this.loadAnzahl();
      });
  }
  private getViewScrollInfo(aufgabe): IViewScrollInfo {
    const dataSource = this.aufgabeListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(aufgabe);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
  private loadAnzahl() {
    if (this._startupService.startupInfo.Benutzer.IsCloudAdministrator) {
      return;
    }
    const aufgabeAnzahlEvent = new OnAufgabeAnzahlRequestEvent({
      AufgabeBenutzer: true
    });

    this._webEventService
      .execute(aufgabeAnzahlEvent)
      .then((r: OnAufgabeAnzahlRequest) => {
        if (r == void 0 || r.Anzahl == 0) {
          this._headerCommandService.aufgabeCommand.badgeText = null;
          return;
        }

        let color = "white";
        let textColor = "black";
        let borderColor = "#C1392D";

        let anzahl = "";
        if (r.AnzahlErinnernFaellig) {
          anzahl += r.AnzahlErinnernFaellig + "/";

          color = "#C1392D";
          textColor = "white";
          borderColor = "transparent";
        }
        anzahl += r.Anzahl;

        this._headerCommandService.aufgabeCommand.isEnabled = true;
        this._headerCommandService.aufgabeCommand.badgeColor = color;
        this._headerCommandService.aufgabeCommand.badgeTextColor = textColor;
        this._headerCommandService.aufgabeCommand.badgeBorderColor = borderColor;

        this._headerCommandService.aufgabeCommand.badgeText = {
          key: "base.param_1",
          parameters: [anzahl]
        };
      });

  }
  private onAlleAnzeigenClicked() {
    this.goToAufgabeList();
    this.aufgabe.instance.hide();
    this._popupInfoService.closeAllPopups();
  }
  private onNewAufgabeClicked() {
    this._customEditPopupService
      .aufgabeEditPopup
      .show({
        mappings: {
          "$id": 0
        }
      });

    this.aufgabe.instance.hide();
  }
  private setModelData(modelData: any) {
    if (modelData == void 0) {
      this._idProjekt = null;
      this.idVerknuepfteEntitaet = null;
      this._aufgabeBenutzer = true;
    } else {
      this.idVerknuepfteEntitaet = modelData.Id;
      this._idProjekt = modelData.IdProjekt;
      this._aufgabeBenutzer = false;
    }
  }
}
