import { autoinject, transient } from "aurelia-framework";
import { OnEvalVerkaufArtikeldaten, OnEvalVerkaufArtikeldatenEvent, OnFakturapositionVariableStuecklisteAuswahl, OnFakturapositionVariableStuecklisteAuswahlEvent, OnFakturapositionVariableStuecklistenkomponentenErstellen, OnFakturapositionVariableStuecklistenkomponentenErstellenEvent, OnFakturapositionVariablerSetartikelManuellerPreisEntfernenEvent, OnFakturapositionVariablerSetartikelPreisAendernEvent, OnFakturapositionVariablerSetartikelRabattEntfernenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData, ModelUtilsService } from "../../../../framework/forms/export";
import { VariablerSetartikelKomponentenAuswahl } from "../../../elements/variabler-setartikel-komponenten-auswahl/variabler-setartikel-komponenten-auswahl";
import { IVariablerSetartikelPreisAendernResult, VariablerSetartikelPreisAendern } from "../../../elements/variabler-setartikel-preis-aendern/variabler-setartikel-preis-aendern";
import { DynFeldLogikTyp } from "../../../enumerations/dyn-feld-logik-typ";
import { IKalkulationRefreshOptions } from "../../../interfaces/kalkulation-refresh-options";
import { ArtikelService } from "../../../services/artikel-service";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";
import { DynFelderService } from "../../../services/dyn-felder-service";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { StartupService } from "../../../services/startup-service";
import { DxCustomizeService } from './../../../services/dx-customize-service';

@autoinject
@transient()
export class FakturapositionEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderDataService: DynFelderDataService,
    public startupService: StartupService,
    private _artikelService: ArtikelService,
    private _dynFelderService: DynFelderService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _localizationService: LocalizationService,
    private _modelUtilsService: ModelUtilsService,
    private _webEventService: WebEventService,
    private _dxCustomizeService: DxCustomizeService) { }

  form: FormBase;
  refreshOptions: IKalkulationRefreshOptions;
  showVariablerSetartikelCheckbox: boolean;
  variablerSetartikelKomponentenAuswahlList: any[];
  onReloadStuecklistemkomponentenlist: boolean;

  showVariablerSetartikelKomponentenAuswahlCommand: ICommandData = {
    id: "variablerSetartikelKomponentenAuswahlCommand",
    idCategory: "fakturaposition",
    icon: "list",
    title: "faktura.stueckliste_auswaehlen",
    isVisibleExpression: "functions.$f_FakturapositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Fakturaposition && models.data.$m_Fakturaposition.Id > 0 && models.data.$m_Fakturaposition.IsVariablerSetartikel && models.data.$m_Fakturaposition.Lieferscheinposition == null && models.data.$m_Fakturaposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();
    }
  }
  showVariablerSetartikelPreisAendernCommand: ICommandData = {
    id: "variablerSetartikelPreisAendernCommand",
    idCategory: "fakturaposition",
    icon: "money",
    title: "faktura.preis_aendern",
    isVisibleExpression: "functions.$f_FakturapositionEditUtils.startupService.startupInfo.Lizenz.HasVariablerSetartikel && !!models.data.$m_Fakturaposition && models.data.$m_Fakturaposition.Id > 0 && models.data.$m_Fakturaposition.IsVariablerSetartikel && models.data.$m_Fakturaposition.CanSave",
    sortIndex: 991,
    execute: async () => {
      this.showVariablerSetartikelPreisAendernAuswahl();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Fakturaposition",
      idVorgEntitaetPropertyName: "IdArtikel"
    });

    this.refreshOptions = {
      form: form,
      modelName: "$m_Fakturaposition",
      kalkulationRef: "r_kalkulation",
      einheitPropertyName: "IdEinheit",
      mengeVerpEHPropertyName: "MengeIstVerpEH"
    }

    this._kalkulationRefreshService.registerRefresh(this.refreshOptions);

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "fakturapositionEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    this.form.models.onLoaded.register(async (e) => {
      if (e == void 0 || e.model.id != "$m_Fakturaposition" || !e.data) {
        return;
      }

      this.showVariablerSetartikelCheckbox = this.form.models.data.$m_Fakturaposition
        && this.form.models.data.$m_Fakturaposition.Artikel != null
        && this.form.models.data.$m_Fakturaposition.Artikel.BezugTyp == 3
        ? true
        : false;

      this.refreshFakturagruppeButton();
    });

    this.form.onSaving.register(async (r) => {
      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }
      if (!this.form.models.data.$m_Fakturaposition.IsVariablerSetartikel) {
        return;
      }

      if (r.form.models.data.$m_Fakturaposition.Id > 0) {
        return;
      }

      if (!this.form.models.data.$m_Fakturaposition.MengeIstVerpEH) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("faktura.fehler_variabler_setartikel_menge"),
          "error",
          this.NOTIFY_TIMEOUT);
        return Promise.reject();
      }

      if (this.variablerSetartikelKomponentenAuswahlList != null) {
        return;
      }

      this.showVariablerSetartikelStuecklistenKomponentenAuswahl();

      return Promise.reject();
    });

    this.form.onSaved.register(async (r) => {
      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }
      if (this.variablerSetartikelKomponentenAuswahlList == null && this.form.models.data.$m_Fakturaposition.IsSetartikel) {
        if (this.onReloadStuecklistemkomponentenlist) {
          this.onReloadStuecklistemkomponentenlist = false;
          this.form.models.reloadAll();
        }
        return;
      }

      if (r.form.models.data.$m_Fakturaposition.Id > 0 && !this.form.models.data.$m_Fakturaposition.IsVariablerSetartikel) {
        return;
      }

      const webEvent = new OnFakturapositionVariableStuecklistenkomponentenErstellenEvent({
        IdFakturaposition: r.form.models.data.$m_Fakturaposition.Id,
        StuecklistenkomponentenMengeList: this.variablerSetartikelKomponentenAuswahlList
      });

      const result: OnFakturapositionVariableStuecklistenkomponentenErstellen = await this._webEventService.execute(webEvent);

      this.form.models.reloadAll();
      this.variablerSetartikelKomponentenAuswahlList = null;
    });
  }

  async idArtikelChanged(e) {
    this._kalkulationRefreshService.onIdArtikelChanged(this.refreshOptions);

    if (!this.form.models.data.$m_Fakturaposition.Id) {
      await this.dynFelderDataService.loadVorbelegungen();
      this._modelUtilsService.setDirty(this.form.models.data.$m_Fakturaposition);
    }

    this.doEvalVerkaufArtikeldaten(e.detail.value);

    if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
      return;
    }
    if (e.detail.value != null) {
      const artikel = await this._artikelService.getArtikelById(e.detail.value);

      if (artikel.BezugTyp != 3) {
        return;
      }

      const dynFeldEintrag = await this._dynFelderService.loadDynFeldEintragByLogikttyp(artikel.IdDynamischesFeldContainer, DynFeldLogikTyp.VariablerSetartikel);
      this.showVariablerSetartikelCheckbox = true;

      if (dynFeldEintrag != null && dynFeldEintrag[0].WertNummer == 1) {
        this.form.models.data.$m_Fakturaposition.IsVariablerSetartikel = true;
      }
    }
  }

  private async showVariablerSetartikelStuecklistenKomponentenAuswahl() {
    const webEvent = new OnFakturapositionVariableStuecklisteAuswahlEvent({
      IdArtikel: this.form.models.data.$m_Fakturaposition.IdArtikel,
      IdFakturaposition: this.form.models.data.$m_Fakturaposition.Id
    });

    const result: OnFakturapositionVariableStuecklisteAuswahl = await this._webEventService.execute(webEvent);

    if (result == void 0) {
      return;
    }

    if (result.StuecklistenkomponentenAuswahlList.length == 0) {
      return;
    }

    const variablerSetartikelAuswahlPopup: VariablerSetartikelKomponentenAuswahl = this.form["r_variablerSetartikelKomponentenAuswahlElement"];

    variablerSetartikelAuswahlPopup.show({
      StuecklisteList: result.StuecklistenkomponentenAuswahlList,
      callback: async (auswahl) => {
        console.log(auswahl);
        this.variablerSetartikelKomponentenAuswahlList = auswahl;
        this.form.save();
      }
    });
  }

  private async showVariablerSetartikelPreisAendernAuswahl() {
    const variablerSetartikelPreisAendernPopup: VariablerSetartikelPreisAendern = this.form["r_variablerSetartikelPreisAendernElement"];

    variablerSetartikelPreisAendernPopup.show({
      callback: async (result: IVariablerSetartikelPreisAendernResult) => {
        if (result.RabattEntfernen) {
          const webEvent = new OnFakturapositionVariablerSetartikelRabattEntfernenEvent({
            IdFakturapositionSetartikel: this.form.models.data.$m_Fakturaposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else if (result.ManuellerPreisEntfernen) {
          const webEvent = new OnFakturapositionVariablerSetartikelManuellerPreisEntfernenEvent({
            IdFakturapositionSetartikel: this.form.models.data.$m_Fakturaposition.Id
          });

          await this._webEventService.execute(webEvent);
        } else {
          const webEvent = new OnFakturapositionVariablerSetartikelPreisAendernEvent({
            IdFakturapositionSetartikel: this.form.models.data.$m_Fakturaposition.Id,
            Typ: result.KalkulationsFeldTyp,
            Wert: result.Wert
          });

          await this._webEventService.execute(webEvent);
        }

        this.form.models.reloadAll();
      }
    });
  }


  private async doEvalVerkaufArtikeldaten(idArtikel) {
    if (!idArtikel) {
      return;
    }

    const webEvent = new OnEvalVerkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdKunde: this.form.models.data.$m_Faktura.IdRechnungsort
    });

    const result: OnEvalVerkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    this.form.models.data.$m_Fakturaposition.IdEinheit = result.IdArtikelEinheit;
    this.form.models.data.$m_Fakturaposition.KundeArtikelNr = result.KundeArtikelNr;
    this.form.models.data.$m_Fakturaposition.KundeArtikelbezeichnung = result.KundeArtikelbezeichnung;
  }

  onReloadStuecklistenkomponentenChanged(e) {
    if (!this.form.models.data.$m_Fakturaposition) {
      return;
    }

    if (this.form.models.data.$m_Fakturaposition.IsSetartikel && e.previousValue != null) {
      this.onReloadStuecklistemkomponentenlist = true;
    }
  }

  onFakturagruppeChanged() {
    this.refreshFakturagruppeButton();
  }

  copyFakturaposition($delegate: { (customData: any): Promise<any> }) {
    $delegate({ "PosNrUeberschreiben": true })
  }

  private refreshFakturagruppeButton() {
    const data = this.form.models.data.$m_Fakturaposition;
    if (!data) {
      return;
    }
    const editor = this.form["r_fakturagruppe"].instance;
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (!data.IdFakturagruppe && data.CanSave) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: async () => {
            this.form.models.data.$m_FakturagruppeEdit = {
              Id: 0
            };

            this.form.editPopups.showEx({
              idEditPopup: "fakturagruppeEditPopup",
              modelLoaded: (form, ev) => {
                if (!form.models.data.$m_Fakturagruppe) {
                  return;
                }
                form.models.data.$m_Fakturagruppe.IdFaktura = this.form.models.data.$m_Fakturaposition.IdFaktura;
              },
              closeCallback: async (form) => {
                this.form["r_fakturagruppe"].instance.getDataSource().reload();

                const model = form.models.modelWithKeyId;
                const data = form.models.data[model.id];
                this.form.models.data.$m_Fakturaposition.IdFakturagruppe = data.Id;

                this.form.onEditorValueChanged.fire({
                  binding: {
                    dataContext: "$m_Fakturaposition",
                    bindTo: "IdFakturagruppe"
                  },
                  value: data.Id
                });
              }
            });
          }
        }
      });
    }

    buttons.push({
      location: "after",
      name: "clear"
    }, {
      location: "after",
      name: "dropDown"
    });

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
}
