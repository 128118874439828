import { autoinject, bindable } from "aurelia-framework";

@autoinject
export class TagColor {
  constructor(
    private _element: Element
  ) {}

  @bindable farbe: string;
  @bindable selected: boolean;

  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this._element.dispatchEvent(new CustomEvent(
      "on-color-click", {
        bubbles: true,
        detail: {
          farbe: this.farbe
        }
      }
    ));
  }
}