import {
  autoinject,
  TemplatingEngine
} from "aurelia-framework";

import {
  LocalizationService, TemplatingExService
} from "../../framework/base/export";
import {
  FormEventService, RouterService, FormBase
} from "../../framework/forms/export";
import { EventAggregator } from 'aurelia-event-aggregator';
import { ZugriffHistorieService } from './zugriff-historie-service';
import { EntitaetInfoService } from './entitaet-info-service';

@autoinject
export class FormExtenderService {
  constructor(
    private _formEventService: FormEventService,
    private _localizationService: LocalizationService,
    private _templatingEngine: TemplatingEngine,
    private _templatingExService: TemplatingExService,
    private _eventAggregator: EventAggregator,
    private _routerService: RouterService,
    private _zugriffHistorieService: ZugriffHistorieService,
    private _entitaetInfoService: EntitaetInfoService
  ) {
    this.registerAendInfo();
    this.registerFormReloadOnActivate();
    this.registerEditFormZugriffHistorie();
  }

  private registerAendInfo() {
    this._formEventService.onAttached.register(e => {
      if (!e.form.models.modelWithKeyId) {
        return;
      }

      const model = e.form.models.modelWithKeyId;
      if (model.custom && model.custom.showAendinfo === false) {
        return;
      }

      let parentElement = e.form.element.querySelector(".t--form-content .dx-scrollview-content > .parent-container > .container");
      if (!parentElement) {
        parentElement = e.form.element.querySelector(".t--form-validation-group > div > .parent-container > .container");

        if (!parentElement) {
          return;
        }
      }

      const modelPraefix = "models.data.".concat(model.id).concat(".");

      const aendDiv = document.createElement("div");
      aendDiv.className = "col-xs-12 aenderung-info";
      
      const erstelltDiv = document.createElement("div");
      erstelltDiv.setAttribute("if.bind", modelPraefix.concat("AnlageBenutzer"));

      const inaktivDiv = document.createElement("div");
      inaktivDiv.className = "inaktiv-info";
      
      const inaktivContainerDiv = document.createElement("div");
      inaktivContainerDiv.setAttribute("if.bind", modelPraefix.concat("IsInaktiv"));
      inaktivContainerDiv.innerText = this._localizationService.translateOnce("erp_aendinfo.inaktiv");

      inaktivDiv.appendChild(inaktivContainerDiv);

      let erstelltText = this._localizationService.translateOnce("erp_aendinfo.erstellt_von");
      erstelltText = this.replaceIndex(erstelltText, 0, modelPraefix.concat("AnlageBenutzer"));
      erstelltText = this.replaceIndex(erstelltText, 1, modelPraefix.concat("AnlageDatum | format:'d'"));
      erstelltText = this.replaceIndex(erstelltText, 2, modelPraefix.concat("AnlageDatum | format:'t'"));
      erstelltDiv.innerText = erstelltText;
      aendDiv.appendChild(erstelltDiv);

      const geandertDiv = document.createElement("div");
      geandertDiv.setAttribute(
        "if.bind", 
        modelPraefix
          .concat("Aenderungsbenutzer")
          .concat(" && ")
          .concat(modelPraefix)
          .concat("IsGeaendert"));

      let geaendertText = this._localizationService.translateOnce("erp_aendinfo.geaendert_von");
      geaendertText = this.replaceIndex(geaendertText, 0, modelPraefix.concat("Aenderungsbenutzer"));
      geaendertText = this.replaceIndex(geaendertText, 1, modelPraefix.concat("Aenderungsdatum | format:'d'"));
      geaendertText = this.replaceIndex(geaendertText, 2, modelPraefix.concat("Aenderungsdatum | format:'t'"));
      geandertDiv.innerText = geaendertText;
      aendDiv.appendChild(geandertDiv);

      parentElement.appendChild(aendDiv);
      parentElement.insertBefore(inaktivDiv, parentElement.firstChild);

      const resultAendInfo = this._templatingEngine.enhance({
        element: aendDiv,
        bindingContext: e.form.scope.bindingContext,
        overrideContext: e.form.scope.overrideContext
      });
      const resultInaktiv = this._templatingEngine.enhance({
        element: inaktivDiv,
        bindingContext: e.form.scope.bindingContext,
        overrideContext: e.form.scope.overrideContext
      });

      e.form.onUnbind.register(() => {
        this._templatingExService.destroyView(resultAendInfo);
        this._templatingExService.destroyView(resultInaktiv);
        return Promise.resolve();
      })

      return Promise.resolve();
    });
  }
  private registerFormReloadOnActivate() {
    this._eventAggregator.subscribe("window:activated", () => {
      if (!this._routerService.currentViewItem) {
        return;
      }

      const viewItem = this._routerService.currentViewItem;
      
      const controller: any = viewItem.controller;
      if (!controller) {
        return;
      }

      const form: FormBase = controller.currentViewModel;
      if (!form) {
        return;
      }
      
      form.reloadIfServerHasNewVersion();
    });
  }
  private registerEditFormZugriffHistorie() {
    this._formEventService.onBind.register(e => {
      if (!e.form.models.modelWithKeyId) {
        return;
      }

      const model = e.form.models.modelWithKeyId;
      const entitaetInfo = this._entitaetInfoService.getEntitaetInfoByWebApiAction(model.webApiAction);
      if (!entitaetInfo) {
        return;
      }

      if (!entitaetInfo.Url) {
        return;
      }

      if (!this._routerService.existsNavigationRoute(entitaetInfo.Url)) {
        return;
      }
      
      e.form.models.onLoaded.register(async loadedArgs => {
        if (loadedArgs.model.id != model.id) {
          return;
        }

        if (!loadedArgs.data) {
          return;
        }
        if (!loadedArgs.data.Id) {
          return;
        }

        this._zugriffHistorieService.createZugriffHistorie(loadedArgs.data.Id);
        return;
      });

      return Promise.resolve();
    });
  }
  private replaceIndex(input: string, index: number, value: string): string {
    const find = "{" + index + "}";
    const replace = "${" + value + "}";

    return input.replace(find, replace)
  }
}
