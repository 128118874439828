import { autoinject, bindable, observable } from "aurelia-framework";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import { IdxDataGridComponent } from "../../interfaces/dx-components";
import { ArbeitszeitBeschaeftigungsausmassZuteilungEdit, IArbeitszeitBeschaeftigungsausmass } from './../arbeitszeit-beschaeftigungsausmass-zuteilung-edit/arbeitszeit-beschaeftigungsausmass-zuteilung-edit';

@autoinject
export class ArbeitszeitBeschaeftigungsausmassZuteilungList {

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  @bindable @observable mitarbeiterstatus: IArbeitszeitMitarbeiterstatus;
  arbeitszeitBeschaeftigungsausmassZuteilungEdit: ArbeitszeitBeschaeftigungsausmassZuteilungEdit;

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnResizing: true,
    columnResizingMode: "widget",
    paging: {
      pageSize: 20
    },
    hoverStateEnabled: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columns: [{
      dataField: "GueltigVon",
      caption: "Gültig von",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "GueltigBis",
      caption: "Gültig bis",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "BeschaeftigungsausmassProzent",
      caption: "Beschaeftigungsausmaß %",
      minWidth: 100,
      format: this._globalizationService.getFormatterParser("n4")
    }]
  };

  onNeuButtonClick() {
    this.arbeitszeitBeschaeftigungsausmassZuteilungEdit.show({
      mitarbeiterstatus: this.mitarbeiterstatus,
      callback: async (arbeitszeitBeschaeftigungsausmass: IArbeitszeitBeschaeftigungsausmass) => {
        const r = await this._restService.post({
          url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/beschaeftigungsausmass`),
          data: {
            IdMitarbeiterstatus: this.mitarbeiterstatus.IdMitarbeiterstatus,
            BeschaeftigungsausmassProzent: arbeitszeitBeschaeftigungsausmass.Beschaeftigungsausmass,
            GueltigVon: arbeitszeitBeschaeftigungsausmass.GueltigVon
          }
        });

        this.loadMitarbeiterstatusBeschaeftigungsausmassZuteilung();
      }
    });
  }

  mitarbeiterstatusChanged(e) {
    if (this.mitarbeiterstatus != null && this.mitarbeiterstatus.IdMitarbeiterstatus > 0) {
      this.loadMitarbeiterstatusBeschaeftigungsausmassZuteilung();
    }
  }

  private async loadMitarbeiterstatusBeschaeftigungsausmassZuteilung() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/beschaeftigungsausmass/${this.mitarbeiterstatus.IdMitarbeiterstatus}`)
    });

    this.grid.instance.option("dataSource", r);
  }
}
