import {
  autoinject
} from "aurelia-framework";
import {
  SearchResult
} from "../elements/search-result/search-result";
import {
  ISearchOptions
} from "../interfaces/export";

@autoinject
export class SearchResultSearchService{
  constructor(){}

  searchResult: SearchResult

   show(callbackSuccess: { (urlToNavigate: string, customOptions?: any): void }, callbackCancel: { (): void }, options?: ISearchOptions) {
    this.searchResult.showSearchPopup(callbackSuccess, callbackCancel, options)
  }
}
