import { autoinject } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';

import * as DxLoader from "../../framework/dx/dx-loader";

@autoinject
export class WidgetExtenderService {
  constructor(
    private eventAggregator: EventAggregator
  ) {
    eventAggregator.subscribe("dx-widget:attaching", e => {
      if (e.name == "dxRadioGroup") {
        if (e.options.layout == void(0)) {
          e.options.layout = "horizontal";
        }
      } else if (e.name == "dxSelectBox") {
        if (e.options.searchTimeout == void(0)) {
          e.options.searchTimeout = 150;
        }
      }
    });

    DxLoader.getDefinition("dxDateBox").defaultOptions({
      options: <DevExpress.ui.dxDateBoxOptions>{
        max: new Date(2099, 11, 31)
      }
    });
  }
}
