import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    FibuSyncJobListUtils
} from "./fibu-sync-job-list-utils";

@fwx.autoinject
export class FibuSyncJobListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_FibuSyncJobListUtils: FibuSyncJobListUtils) {
        super(element, formBaseImport);
        this.id = "fibu-sync-job-list";
        this.title = "fibu-sync-job-list.fibu-sync-job-list_caption";
        this.addModel({
            "id": "$m_FibuSyncJob",
            "webApiAction": "ERP/Fibu/SyncJob",
            "webApiExpand": {
                'Firma': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FibuSyncJobListUtils.showSynchronsierenPopupCommand",
                "bindToFQ": "functions.$f_FibuSyncJobListUtils.showSynchronsierenPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FibuSyncJobListUtils.showOffenePostenImportPopupCommand",
                "bindToFQ": "functions.$f_FibuSyncJobListUtils.showOffenePostenImportPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FibuSyncJobListUtils.showExportPersonenkontenPopupCommand",
                "bindToFQ": "functions.$f_FibuSyncJobListUtils.showExportPersonenkontenPopupCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_FibuSyncJobListUtils", $f_FibuSyncJobListUtils, "functions.$f_FibuSyncJobListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fibuSyncAnlagedatum",
                "caption": "fibu-sync-job-list.r_fibusyncanlagedatum_caption",
                "bindTo": "AnlageDatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_fibuSyncTyp",
                "caption": "fibu-sync-job-list.r_fibusynctyp_caption",
                "bindTo": "SyncTyp",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.FibuSyncTyp"
            }, {
                "id": "r_firma",
                "caption": "fibu-sync-job-list.r_firma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_anzDatensaetze",
                "caption": "fibu-sync-job-list.r_anzdatensaetze_caption",
                "bindTo": "AnzDatensaetze",
                "width": "120px",
                "format": "n0"
            }, {
                "id": "r_status",
                "caption": "fibu-sync-job-list.r_status_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_fibuSyncJobGridToolbarOptions",
                "optionsNameFQ": "r_fibuSyncJobGridToolbarOptions"
            },
            "caption": "fibu-sync-job-list.r_fibusyncjobgrid_caption",
            "binding": {
                "dataContext": "$m_FibuSyncJob"
            },
            "dataModel": "$m_FibuSyncJob",
            "editUrl": "ERP/Fibu/SyncJob",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fibuSyncJobGrid",
            "options": {
                "optionsName": "r_fibuSyncJobGridOptions",
                "optionsNameFQ": "r_fibuSyncJobGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_fibuSyncFilterPopup",
            "options": {
                "optionsName": "r_fibuSyncFilterPopupOptions",
                "optionsNameFQ": "r_fibuSyncFilterPopupOptions"
            },
            "caption": "fibu-sync-job-list.r_fibusyncfilterpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_FibuSyncJobListUtils.doSynchronisierenCommand",
                    "bindToFQ": "functions.$f_FibuSyncJobListUtils.doSynchronisierenCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "fibu-sync-job-list.r_fibusyncfirma_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_fibuSyncFirma",
            "options": {
                "optionsName": "r_fibuSyncFirmaOptions",
                "optionsNameFQ": "r_fibuSyncFirmaOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "fibu-sync-job-list.r_datumbis_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datumBis",
            "options": {
                "optionsName": "r_datumBisOptions",
                "optionsNameFQ": "r_datumBisOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_importOPPopup",
            "options": {
                "optionsName": "r_importOPPopupOptions",
                "optionsNameFQ": "r_importOPPopupOptions"
            },
            "caption": "fibu-sync-job-list.r_importoppopup_caption",
            "height": "auto",
            "width": "600px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_FibuSyncJobListUtils.doOffenePostenImportCommand",
                    "bindToFQ": "functions.$f_FibuSyncJobListUtils.doOffenePostenImportCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "fibu-sync-job-list.r_impopfirma_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_impOPFirma",
            "options": {
                "optionsName": "r_impOPFirmaOptions",
                "optionsNameFQ": "r_impOPFirmaOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "caption": "fibu-sync-job-list.r_impopliste_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_impOPListe",
            "options": {
                "optionsName": "r_impOPListeOptions",
                "optionsNameFQ": "r_impOPListeOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_exportPersonenkontenPopup",
            "options": {
                "optionsName": "r_exportPersonenkontenPopupOptions",
                "optionsNameFQ": "r_exportPersonenkontenPopupOptions"
            },
            "caption": "fibu-sync-job-list.r_exportpersonenkontenpopup_caption",
            "height": "auto",
            "width": "600px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_FibuSyncJobListUtils.doExportPersonenkontenCommand",
                    "bindToFQ": "functions.$f_FibuSyncJobListUtils.doExportPersonenkontenCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "fibu-sync-job-list.r_exportpfirma_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_exportPFirma",
            "options": {
                "optionsName": "r_exportPFirmaOptions",
                "optionsNameFQ": "r_exportPFirmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerart",
            "filter": [
                ["IsLieferant", true], "or", ["IsKunde", true], "or", ["IsRechnungsadresse", true]
            ],
            "filters": [],
            "caption": "fibu-sync-job-list.r_exportpgeschaeftspartnerart_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_exportPGeschaeftspartnerart",
            "options": {
                "optionsName": "r_exportPGeschaeftspartnerartOptions",
                "optionsNameFQ": "r_exportPGeschaeftspartnerartOptions"
            }
        });
        super.onConstructionFinished();
    }
}