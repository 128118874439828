import { WebEventService } from './../../../../framework/base/services/web-event-service';
import { OnMahnungVersendenEvent, OnMahnungVersenden } from './../../../../framework-data/events';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { transient, autoinject } from 'aurelia-framework';
import { ICommandData } from '../../../../framework/forms/export';
import { DynFelderDataService } from '../../../services/dyn-felder-data-service';

@autoinject
@transient()
export class MahnungEditUtils {
  constructor(
    private webEventService: WebEventService,
    public dynFelderData: DynFelderDataService
  ) { }

  form: FormBase;

  doMahnungVersenden: ICommandData = {
    id: "doMahnungVersenden",
    idCategory: "mahnung",
    title: "mahnung.mahnung_versenden",
    icon: "paper-plane",
    isEnabledExpression: "models.data.$m_Mahnung.CanSave && models.data.$m_Mahnung.Status && models.data.$m_Mahnung.Status.Typ == 0",
    execute: async () => {
      const webEvent = new OnMahnungVersendenEvent({
        IdMahnung: this.form.models.data.$m_Mahnung.Id
      });

      const result: OnMahnungVersenden = await this.webEventService.execute(webEvent);
      if (result == void 0) {
        return;
      }
      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.models.onLoaded.register(r => {
      if (r.model.id == "$m_MahnungspositionRel") {
        if (r.data && r.data.data && Array.isArray(r.data.data)) {
          for (let item of r.data.data) {
            if (!item.Faktura || !item.Faktura.IdStatus) {
              continue;
            }

            item["IdStatus"] = item.Faktura.IdStatus;
          }
        }
      }
      return Promise.resolve();
    });

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Mahnung"
    });

  }
}
