import {
  autoinject,
  transient
} from 'aurelia-framework';
import {
  FormBase
} from '../../../../framework/forms/export';
import {
  StartupService
} from './../../../services/export';

@autoinject
@transient()
export class FibuKontoEditUtils {
  constructor(
    private startup: StartupService
  ) {}

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
  }
}
