export class ElementService {
  constructor() {}

  addToContentReady(component: DevExpress.ui.Widget, callback: {(): void}) {
    const c: any = component;

    c._onContentReadyQueue = c._onContentReadyQueue || [];
    c._onContentReadyQueue.push(callback);
  }
  prepareContentReady(options: DevExpress.ui.WidgetOptions) {
    const onContentReadyOld = options.onContentReady;

    options.onContentReady = (e) => {
      if (onContentReadyOld) {
        onContentReadyOld(e);
      }

      const c: any = e.component;
      if (!c._onContentReadyQueue) {
        return;
      }

      while (c._onContentReadyQueue.length > 0) {
        c._onContentReadyQueue[0]();
        c._onContentReadyQueue.splice(0, 1);
      }
    }
  }

  addOnInitialized(options: DevExpress.ui.WidgetOptions, callback: {(e: any): void}) {
    const onInitializedOld = options.onInitialized;

    options.onInitialized = (e) => {
      if (onInitializedOld) {
        onInitializedOld(e);
      }

      callback(e);
    }
  }
}