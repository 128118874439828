import { autoinject } from "aurelia-framework";
import { IViewScrollInfo, LocationService } from '../../../../framework/base/export';
import { ListView } from '../../../../framework/forms/elements/export';
import { IListViewOptions } from '../../../../framework/forms/elements/list-view/export';
import { PopupInfoService } from '../../../../framework/forms/export';
import { Statustyp } from '../../../enumerations/export';
import { FirmaService } from '../../../services/firma-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { ScopeContainer } from './../../../../framework/base/classes/scope-container';
import { DataSourceService } from './../../../../framework/base/services/data-source-service';
import { EntitaetInfoService } from './../../../services/entitaet-info-service';
import { IOffeneAuftraegeSettings } from './offene-auftraege-settings';

@autoinject
export class OffeneAuftraege {
  constructor(
    private _dataSourceService: DataSourceService,
    private _firmaService: FirmaService,
    private _popupInfoService: PopupInfoService,
    private _locationService: LocationService,
    private _entitaetInfoService: EntitaetInfoService) { }

  scopeContainer: ScopeContainer;

  widgetModel: IWidgetModel;
  settings: IOffeneAuftraegeSettings;
  entitateTitle: string;
  firmaBezeichnung: string;

  offeneAuftraegeListView: ListView;
  offeneAuftraegeListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 20,
    hoverStateEnabled: true,
    height: "100%",
    onItemClick: async (e) => {
      const popupClosed = await this._popupInfoService.closeAllPopups();
      if (!popupClosed) {
        return;
      }

      let url = await this._entitaetInfoService.getUrl("TIP.ERP.Business.Entitaeten.Verkauf.Auftrag", e.item.Id);
      this._locationService.goTo({
        url: url,
        viewScrollInfo: this.getViewScrollInfo(e.item)
      });
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.loadData();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async loadData() {
    let dataSource: DevExpress.data.DataSource;

    const where = [["Status.Typ", Statustyp.Offen], ["Lieferdatum", "<=", "@Today()+5"]];

    if (this.settings.idFirma != null) {
      where.push(["IdFirma", this.settings.idFirma]);
    }

    dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      keyProperty: "Id",
      webApiAction: "ERP/Verkauf/Auftrag",
      webApiColumns: ["Id", "Lieferdatum", "Nummer", "Reservierungsgrad", "IsBestaetigt", "IdKundeAdresse", "IdStatus"],
      webApiWhere: where,
      webApiExpand: {
        KundeAdresse: {
          columns: ["Id", "Firmenbez1"]
        },
        Status: {
          columns: ["Id", "Farbe", "Typ"]
        }
      }, filters: [
        {
          webApiCustomKey: "AddTags",
          webApiCustomValue: "true"
        }]
    }
    );

    this.offeneAuftraegeListViewOptions.dataSource = dataSource;

    this.getFirmaBezeichnung();
  }

  private getViewScrollInfo(item): IViewScrollInfo {
    const dataSource = this.offeneAuftraegeListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(item);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }

  getCellFormat(item) {
    if (!item || item.IsBestaetigt == false || item.Status.Typ !== 0) {
      return;
    }

    let returnCss = "color:white;";

    let backgroundColor;
    if (item.Reservierungsgrad < 1) {
      backgroundColor = "#C1392D;"
    } else {
      if (item.HasArtikelNichtLagerfuehrendOffen) {
        backgroundColor = "#566473;"
      } else {
        backgroundColor = "#2DCC70;"
      }
    }

    if (backgroundColor) {
      returnCss = returnCss.concat("background-color:", backgroundColor);
    }
    return returnCss;
  }

  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
