import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    ArtikelListUtils
} from "./artikel-list-utils";

@fwx.autoinject
export class ArtikelListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_ArtikelListUtils: ArtikelListUtils) {
        super(element, formBaseImport);
        this.id = "artikel-list";
        this.title = "artikel-list.artikel-list_caption";
        this.addModel({
            "id": "$m_Artikel",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "webApiExpand": {
                'Artikelart': null,
                'Status': null,
                'Einheit': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'artikelGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelListUtils.doExport",
                "bindToFQ": "functions.$f_ArtikelListUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelListUtils.doBelegExport",
                "bindToFQ": "functions.$f_ArtikelListUtils.doBelegExport"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_ArtikelListUtils", $f_ArtikelListUtils, "functions.$f_ArtikelListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_artNummer",
                "caption": "artikel-list.r_artnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_artArtikelart",
                "caption": "artikel-list.r_artartikelart_caption",
                "bindTo": "Artikelart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_artBezeichnung",
                "caption": "artikel-list.r_artbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_artLagereinheit",
                "caption": "artikel-list.r_artlagereinheit_caption",
                "bindTo": "Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_artStatus",
                "caption": "artikel-list.r_artstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "artikelGridToolbarOptions",
                "optionsNameFQ": "artikelGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "dataModel": "$m_Artikel",
            "editUrl": "ERP/Stammdaten/Artikel",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "artikelGrid",
            "options": {
                "optionsName": "artikelGridOptions",
                "optionsNameFQ": "artikelGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}