import * as fwx from "../../../../framework/forms/form-export";
import {
    LieferscheingruppeEditUtils
} from "./lieferscheingruppe-edit-utils";

@fwx.autoinject
export class LieferscheingruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_LieferscheingruppeEditUtils: LieferscheingruppeEditUtils) {
        super(element, formBaseImport);
        this.id = "lieferscheingruppe-edit";
        this.title = "lieferscheingruppe-edit.lieferscheingruppe-edit_caption";
        this.addModel({
            "id": "$m_Lieferscheingruppe",
            "webApiAction": "ERP/Verkauf/Lieferscheingruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Lieferschein.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Lieferscheingruppe.IdLieferschein",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_LieferscheingruppeEditUtils", $f_LieferscheingruppeEditUtils, "functions.$f_LieferscheingruppeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "lieferscheingruppe-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Lieferscheingruppe",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Lieferscheingruppe.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferscheingruppe-edit.r_posnr_caption",
            "binding": {
                "dataContext": "$m_Lieferscheingruppe",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Lieferscheingruppe.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}