import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class WarenuebernahmeListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "warenuebernahme-list";
        this.title = "warenuebernahme-list.warenuebernahme-list_caption";
        this.addModel({
            "id": "$m_Warenuebernahme",
            "webApiAction": "ERP/Einkauf/Warenuebernahme",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null,
                'Lieferant': null,
                'LieferantAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_warenuebernahmeGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_waNummer",
                "caption": "warenuebernahme-list.r_wanummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_waDatum",
                "caption": "warenuebernahme-list.r_wadatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_waProjekt",
                "caption": "warenuebernahme-list.r_waprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_waLieferantNummer",
                "caption": "warenuebernahme-list.r_walieferantnummer_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "80px"
            }, {
                "id": "r_waFirmenbez1",
                "caption": "warenuebernahme-list.r_wafirmenbez1_caption",
                "bindTo": "LieferantAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_waStrasse",
                "caption": "warenuebernahme-list.r_wastrasse_caption",
                "bindTo": "LieferantAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_waLand",
                "caption": "warenuebernahme-list.r_waland_caption",
                "bindTo": "LieferantAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_waOrt",
                "caption": "warenuebernahme-list.r_waort_caption",
                "bindTo": "LieferantAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_waWertNetto",
                "caption": "warenuebernahme-list.r_wawertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_waWaehrung",
                "caption": "warenuebernahme-list.r_wawaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_waStatus",
                "caption": "warenuebernahme-list.r_wastatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_warenuebernahmeGridToolbarOptions",
                "optionsNameFQ": "r_warenuebernahmeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Warenuebernahme"
            },
            "dataModel": "$m_Warenuebernahme",
            "editUrl": "ERP/Einkauf/Warenuebernahme",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_warenuebernahmeGrid",
            "options": {
                "optionsName": "r_warenuebernahmeGridOptions",
                "optionsNameFQ": "r_warenuebernahmeGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}