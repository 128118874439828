import * as fwx from "../../../../framework/forms/form-export";
import {
    PersonEditUtils
} from "./person-edit-utils";

@fwx.autoinject
export class PersonEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_PersonEditUtils: PersonEditUtils) {
        super(element, formBaseImport);
        this.id = "person-edit";
        this.title = "person-edit.person-edit_caption";
        this.addModel({
            "id": "$m_Person",
            "webApiAction": "ERP/Stammdaten/Person",
            "webApiExpand": {
                'Personaktionen': null,
                'Personfunktionen': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Personaktionen",
            "webApiAction": "ERP/Stammdaten/Personenaktion",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Personfunktionen",
            "webApiAction": "ERP/Stammdaten/Personenfunktion",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonEditUtils.webshopAktivierenCommand",
                "bindToFQ": "functions.$f_PersonEditUtils.webshopAktivierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonEditUtils.webshopDeaktivierenCommand",
                "bindToFQ": "functions.$f_PersonEditUtils.webshopDeaktivierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_PersonEditUtils.webshopNeuesPasswortCommand",
                "bindToFQ": "functions.$f_PersonEditUtils.webshopNeuesPasswortCommand"
            }
        });
        this.addFunction("$f_PersonEditUtils", $f_PersonEditUtils, "functions.$f_PersonEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "anrede",
            "filters": [],
            "caption": "person-edit.r_anrede_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "IdAnrede",
                "bindToFQ": "models.data.$m_Person.IdAnrede"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_anrede",
            "options": {
                "optionsName": "r_anredeOptions",
                "optionsNameFQ": "r_anredeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_vorname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Vorname",
                "bindToFQ": "models.data.$m_Person.Vorname"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_vorname",
            "options": {
                "optionsName": "r_vornameOptions",
                "optionsNameFQ": "r_vornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_nachname_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Nachname",
                "bindToFQ": "models.data.$m_Person.Nachname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_nachname",
            "options": {
                "optionsName": "r_nachnameOptions",
                "optionsNameFQ": "r_nachnameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-edit.r_titelvorne_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "TitelVorne",
                "bindToFQ": "models.data.$m_Person.TitelVorne"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_titelVorne",
            "options": {
                "optionsName": "r_titelVorneOptions",
                "optionsNameFQ": "r_titelVorneOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-edit.r_titelhinten_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "TitelHinten",
                "bindToFQ": "models.data.$m_Person.TitelHinten"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_titelHinten",
            "options": {
                "optionsName": "r_titelHintenOptions",
                "optionsNameFQ": "r_titelHintenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Email",
                "bindToFQ": "models.data.$m_Person.Email"
            },
            "validationRules": [{
                "item": {
                    "type": "email",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "person-edit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Telefon",
                "bindToFQ": "models.data.$m_Person.Telefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "person-edit.r_mobiltelefon_caption",
            "binding": {
                "dataContext": "$m_Person",
                "bindTo": "Mobiltelefon",
                "bindToFQ": "models.data.$m_Person.Mobiltelefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_mobiltelefon",
            "options": {
                "optionsName": "r_mobiltelefonOptions",
                "optionsNameFQ": "r_mobiltelefonOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "person-edit.r_aktionen_caption",
            "dataContext": "$m_Person",
            "relationBinding": {
                "dataContext": "$m_Person",
                "bindTo": "Personaktionen",
                "bindToFQ": "models.data.$m_Person.Personaktionen"
            },
            "relationProperty": "IdPersonenaktion",
            "itemDataContext": "$m_Personaktionen",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Person"
            },
            "validationRules": [],
            "id": "r_aktionen",
            "options": {
                "optionsName": "r_aktionenOptions",
                "optionsNameFQ": "r_aktionenOptions"
            }
        });
        this.widgetCreator.addTagBox(this, {
            "caption": "person-edit.r_funktionen_caption",
            "dataContext": "$m_Person",
            "relationBinding": {
                "dataContext": "$m_Person",
                "bindTo": "Personfunktionen",
                "bindToFQ": "models.data.$m_Person.Personfunktionen"
            },
            "relationProperty": "IdPersonenfunktion",
            "itemDataContext": "$m_Personfunktionen",
            "itemValueExpr": "Id",
            "itemDisplayExpr": "Bezeichnung",
            "binding": {
                "dataContext": "$m_Person"
            },
            "validationRules": [],
            "id": "r_funktionen",
            "options": {
                "optionsName": "r_funktionenOptions",
                "optionsNameFQ": "r_funktionenOptions"
            }
        });
        super.onConstructionFinished();
    }
}