import { autoinject, bindable, observable, computedFrom } from "aurelia-framework";
import { StartupService } from '../../services/export';
import { FileService } from '../../../framework/base/export';

@autoinject
export class Avatar {
  private _currentAvatarDownloadKey: string;

  constructor(
    private startupService: StartupService,
    private fileService: FileService
  ) {
  }

  @bindable @observable data: any;
  @bindable width: number;
  @bindable marginRight: number;
  @bindable marginLeft: number;
  @bindable highlight: boolean;

  avatarStyle: any = {};
  content: string;

  @computedFrom("data", "data.AnlageBenutzer")
  get isVisible() {
    if (!this.data) {
      return false;
    }
    if (!this.data.AnlageBenutzer && !this.data.IdAnlageBenutzer && !this.data.AnlageBenutzerInitiale) {
      return false;
    }

    return true;
  }

  bind() {
    this.avatarStyle = {
      "width": (this.width || 40) + "px",
      "height": (this.width || 40) + "px",
      "border-radius": (this.width || 40) + "px",
      "margin-right": (this.marginRight || 0) + "px",
      "margin-left": (this.marginLeft || 0) + "px",
      "font-size": ((this.width || 40) / 2 - 6) + "px"
    };

    this.setContent();
  }
  unbind() {
    this._currentAvatarDownloadKey = null;
    this.content = null;
    this.avatarStyle = {};
  }

  dataChanged() {
    this.setContent();
  }

  private setContent() {
    let content = "";
    let avatarDownloadKey: string;
    let farbe = "gray";

    if (this.data && this.data.AvatarUrl)  {
      avatarDownloadKey = this.data.AvatarUrl;
    } else if (this.data && this.data.IdAnlageBenutzer) {
      const benutzer = this.startupService
        .startupInfo
        .BenutzerList
        .find(benutzer => benutzer.Id == this.data.IdAnlageBenutzer);

      if (benutzer) {
        if (benutzer.AvatarDownloadKey) {
          avatarDownloadKey = benutzer.AvatarDownloadKey;
        } else {
          content = benutzer.Initiale;
        }

        farbe = benutzer.Farbe;
      }
    } else if (this.data && this.data.AnlageBenutzerInitiale) {
      content = this.data.AnlageBenutzerInitiale;
    } else if (this.data && this.data.AnlageBenutzer) {
      const tokens = this.data.AnlageBenutzer.split(' ');
      for (let token of tokens) {
        if (!token) {
          continue;
        }

        content += token[0];
      }

      content = content.toUpperCase().substr(0, 2);
    }

    if (this._currentAvatarDownloadKey != avatarDownloadKey) {
      this._currentAvatarDownloadKey = avatarDownloadKey;

      const url = avatarDownloadKey.toLowerCase().startsWith("http")
        ? avatarDownloadKey
        : this.fileService.getInlineUrl(avatarDownloadKey);

      this.avatarStyle["background-image"] = "url(".concat(url).concat(")");
      delete this.avatarStyle["background-color"];
    }
    if (this.content != content) {
      this.content = content;
    }
    if (!this._currentAvatarDownloadKey && farbe != this.avatarStyle["background-color"]) {
      this.avatarStyle["background-color"] = farbe;
    }

    if (this.highlight) {
      this.avatarStyle["box-shadow"] = "0px 0px 10px 5px rgba(39,174,96,1)";
    }
  }
}
