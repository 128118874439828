import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusListUtils
} from "./status-list-utils";

@fwx.autoinject
export class StatusListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusList: StatusListUtils) {
        super(element, formBaseImport);
        this.id = "status-list";
        this.title = "status-list.status-list_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_StatusList.doAktualisierenCommand",
                "bindToFQ": "functions.$f_StatusList.doAktualisierenCommand"
            }
        });
        this.addFunction("$f_StatusList", $f_StatusList, "functions.$f_StatusList");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}