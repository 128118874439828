import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    AuftragService
} from "../../../services/auftrag-service";
import {
    AuftragListUtils
} from "./auftrag-list-utils";

@fwx.autoinject
export class AuftragListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_AuftragService: AuftragService,
        private $f_AuftragListUtils: AuftragListUtils) {
        super(element, formBaseImport);
        this.id = "auftrag-list";
        this.title = "auftrag-list.auftrag-list_caption";
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Auftagsart': null,
                'Status': null,
                'Projekt': null,
                'Waehrung': null,
                'Kunde': null,
                'KundeAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_auftragGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragListUtils.doExport",
                "bindToFQ": "functions.$f_AuftragListUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragListUtils.bestaetigenSetzenCommand",
                "bindToFQ": "functions.$f_AuftragListUtils.bestaetigenSetzenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragListUtils.bestaetigenEntfernenCommand",
                "bindToFQ": "functions.$f_AuftragListUtils.bestaetigenEntfernenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragListUtils.lieferdatumAendernCommand",
                "bindToFQ": "functions.$f_AuftragListUtils.lieferdatumAendernCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AuftragService", $f_AuftragService, "functions.$f_AuftragService");
        this.addFunction("$f_AuftragListUtils", $f_AuftragListUtils, "functions.$f_AuftragListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufNummer",
                "caption": "auftrag-list.r_aufnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_aufDatum",
                "caption": "auftrag-list.r_aufdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufProjekt",
                "caption": "auftrag-list.r_aufprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_aufLieferdatum",
                "caption": "auftrag-list.r_auflieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_AuftragService.onLieferdatumCellPrepared"
            }, {
                "id": "r_aufKundeNummer",
                "caption": "auftrag-list.r_aufkundenummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufFirmenbez1",
                "caption": "auftrag-list.r_auffirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_aufStrasse",
                "caption": "auftrag-list.r_aufstrasse_caption",
                "bindTo": "KundeAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_aufLand",
                "caption": "auftrag-list.r_aufland_caption",
                "bindTo": "KundeAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_aufOrt",
                "caption": "auftrag-list.r_aufort_caption",
                "bindTo": "KundeAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_aufReservierungsgrad",
                "caption": "auftrag-list.r_aufreservierungsgrad_caption",
                "bindTo": "Reservierungsgrad",
                "width": "80px",
                "format": "p0",
                "onCellPrepared": "functions.$f_AuftragListUtils.onReservierungsgradCellPrepared"
            }, {
                "id": "r_aufWertNetto",
                "caption": "auftrag-list.r_aufwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufWaehrung",
                "caption": "auftrag-list.r_aufwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_aufStatus",
                "caption": "auftrag-list.r_aufstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_auftragGridToolbarOptions",
                "optionsNameFQ": "r_auftragGridToolbarOptions"
            },
            "caption": "auftrag-list.r_auftraggrid_caption",
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "dataModel": "$m_Auftrag",
            "editUrl": "ERP/Verkauf/Auftrag",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_auftragGrid",
            "options": {
                "optionsName": "r_auftragGridOptions",
                "optionsNameFQ": "r_auftragGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_auftragLieferdatumAendernPopup",
            "options": {
                "optionsName": "r_auftragLieferdatumAendernPopupOptions",
                "optionsNameFQ": "r_auftragLieferdatumAendernPopupOptions"
            },
            "caption": "auftrag-list.r_auftraglieferdatumaendernpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_AuftragListUtils.doLieferdatumAendernCommand",
                    "bindToFQ": "functions.$f_AuftragListUtils.doLieferdatumAendernCommand"
                }
            }]
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "auftrag-list.r_datum_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        super.onConstructionFinished();
    }
}