import * as fwx from "../../../../framework/forms/form-export";
import {
    ArbeitszeitLohnexportListUtils
} from "./lohnexport-list-utils";

@fwx.autoinject
export class LohnexportListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ArbeitszeitLohnexportListUtils: ArbeitszeitLohnexportListUtils) {
        super(element, formBaseImport);
        this.id = "lohnexport-list";
        this.title = "lohnexport-list.lohnexport-list_caption";
        this.addModel({
            "id": "$m_ArbeitszeitLohnexport",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitLohnexport",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArbeitszeitLohnexportListUtils.showLohnexportErstellenPopupCommand",
                "bindToFQ": "functions.$f_ArbeitszeitLohnexportListUtils.showLohnexportErstellenPopupCommand"
            }
        });
        this.addFunction("$f_ArbeitszeitLohnexportListUtils", $f_ArbeitszeitLohnexportListUtils, "functions.$f_ArbeitszeitLohnexportListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_arbLohnexportDatumVon",
                "caption": "lohnexport-list.r_arblohnexportdatumvon_caption",
                "bindTo": "DatumVon",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_arbLohnexportDatumBis",
                "caption": "lohnexport-list.r_arblohnexportdatumbis_caption",
                "bindTo": "DatumBis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_arbLohnexportBezeichnung",
                "caption": "lohnexport-list.r_arblohnexportbezeichnung_caption",
                "bindTo": "Bezeichnung"
            }],
            "optionsToolbar": {
                "optionsName": "r_arbeitszeitLohnexportGridToolbarOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportGridToolbarOptions"
            },
            "caption": "lohnexport-list.r_arbeitszeitlohnexportgrid_caption",
            "binding": {
                "dataContext": "$m_ArbeitszeitLohnexport"
            },
            "dataModel": "$m_ArbeitszeitLohnexport",
            "editUrl": "ERP/Arbeitszeit/ArbeitszeitLohnexport",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_arbeitszeitLohnexportGrid",
            "options": {
                "optionsName": "r_arbeitszeitLohnexportGridOptions",
                "optionsNameFQ": "r_arbeitszeitLohnexportGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}