import { autoinject } from "aurelia-framework";
import { RestService, AuthorizationService } from '../../../../framework/base/export';

@autoinject
export class QrImage {
  constructor(
    private _restService: RestService,
    private _authorizationService: AuthorizationService
  ) {
    
    const authKey = this._authorizationService.getAuthorizationKey();

    this.urlQrCode = this._restService.getApiUrl("ERP/Authenticator/QR")
      .concat(`?auth-token=${encodeURIComponent(authKey)}`)
      .concat("&random=")
      .concat(new Date().getTime().toString());
  }

  showCodeButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "QR-Code anzeigen",
    onClick: () => {
      this.showCode = true;
    }
  }

  showCode = false;
  urlQrCode: string;
}