import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  WebEventService,
  LocalizationService
} from "../../../../framework/base/export";
import {
  OnBVArtikelBestellenEvent,
  OnBVArtikelBestellen,
  OnBVArtikelIgnorierenEvent,
  OnBVArtikelIgnorieren,
  OnBVRechnenEvent,
  OnBVRechnen
} from "../../../../framework-data/events";
import {
  IdxDataGridComponent
} from "../../../interfaces/export";
import { LocationService } from '../../../../framework/base/services/location-service';
import { AsyncService } from '../../../../framework/base/services/async-service';

@autoinject
@transient()
export class BestellvorschlagListUtils {

  constructor(
    private localization: LocalizationService,
    private webEvent: WebEventService,
    private locationService: LocationService,
    private asyncService: AsyncService
  ) { }

  form: FormBase;

  doBestellvorschlagRechnenCommand: ICommandData = {
    id: "doBestellvorschlagRechnenCommand",
    icon: "calculator",
    idCategory: "bestellvorschlag",
    sortIndex: 900,
    title: "bestellvorschlag.bestellvorschlag_rechnen",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellvorschlagGrid"];
      const selectedData: number[] = grid.instance.getSelectedRowsData().map(c => c.IdArtikel);

      if (selectedData.length === 0) {
        DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.kein_artikel_ausgewaehlt"), "error", 3000);
        return;
      }

      const webEvent = new OnBVRechnenEvent({
        IdArtikelList: selectedData
      });

      const webEventResult: OnBVRechnen = await this.webEvent.execute(webEvent, true);
      DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.berechn_ausführ_weitergeleitet"), "SUCCESS", 3000);

      await this.asyncService.convertToPromise(grid.instance.deselectAll());
      grid.instance.refresh();
    }
  }
  doArtikelBestellenCommand: ICommandData = {
    id: "doArtikelBestellen",
    icon: "plus-circle",
    idCategory: "bestellvorschlag",
    sortIndex: 997,
    title: "bestellvorschlag.artikel_bestellen",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellvorschlagGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnBVArtikelBestellenEvent({
        IdBestellvorschlagList: selectedKeys,
        DoAbschliessen: false
      });

      if (selectedKeys.length === 0) {
        DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.kein_artikel_ausgewaehlt"), "error", 3000);
        return;
      }

      const webEventResult: OnBVArtikelBestellen = await this.webEvent.execute(webEvent, true);
      await this.asyncService.convertToPromise(grid.instance.deselectAll());
      grid.instance.refresh();

      let navigationUrl: string = null;

      if (webEventResult.IdBestellung) {
        navigationUrl = `ERP/Einkauf/Bestellung/${webEventResult.IdBestellung}`;
      } else if (webEventResult.IdProdAuftrag) {
        navigationUrl = `ERP/Produktion/ProdAuftrag/${webEventResult.IdProdAuftrag}`;
      }

      if (navigationUrl) {
        if (ctrlKeyPressed) {
          this.locationService.openWindow(navigationUrl);
        } else {
          this.locationService.goTo({
            url: navigationUrl,
            clearStack: false
          });
        }
      }
    }
  }
  doArtikelBestellenUndVersendenCommand: ICommandData = {
    id: "doArtikelBestellenAndVersenden",
    icon: "plus-circle",
    idCategory: "bestellvorschlag",
    sortIndex: 998,
    title: "bestellvorschlag.artikel_bestellen_und_versenden",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellvorschlagGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnBVArtikelBestellenEvent({
        IdBestellvorschlagList: selectedKeys,
        DoAbschliessen: true
      });

      if (selectedKeys.length === 0) {
        DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.kein_artikel_ausgewaehlt"), "error", 3000);
        return;
      }

      const webEventResult: OnBVArtikelBestellen = await this.webEvent.execute(webEvent, true);
      
      await this.asyncService.convertToPromise(grid.instance.deselectAll());
      grid.instance.refresh();
    }
  }
  doArtikelIgnorierenCommand: ICommandData = {
    id: "doArtikelIgnorieren",
    icon: "ban",
    idCategory: "bestellvorschlag",
    sortIndex: 999,
    title: "bestellvorschlag.artikel_ignorieren",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_bestellvorschlagGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnBVArtikelIgnorierenEvent({
        IdBestellvorschlagList: selectedKeys
      });

      if (selectedKeys.length === 0) {
        DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.kein_artikel_ausgewaehlt"), "error", 3000);
        return;
      }

      const webEventResult: OnBVArtikelIgnorieren = await this.webEvent.execute(webEvent, true);
      
      await this.asyncService.convertToPromise(grid.instance.deselectAll());
      grid.instance.refresh();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
