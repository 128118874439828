import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class InstanzListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "instanz-list";
        this.title = "instanz-list.instanz-list_caption";
        this.addModel({
            "id": "$m_Instanz",
            "webApiAction": "ERP/Cloud/Instanz",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_insFirmenbez1",
                "caption": "instanz-list.r_insfirmenbez1_caption",
                "bindTo": "Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_insDatenbank",
                "caption": "instanz-list.r_insdatenbank_caption",
                "bindTo": "Datenbank",
                "minWidth": 100
            }, {
                "id": "r_insBenutzerSuffix",
                "caption": "instanz-list.r_insbenutzersuffix_caption",
                "bindTo": "BenutzerSuffix",
                "minWidth": 50
            }],
            "optionsToolbar": {
                "optionsName": "r_instanzGridToolbarOptions",
                "optionsNameFQ": "r_instanzGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Instanz"
            },
            "dataModel": "$m_Instanz",
            "editUrl": "ERP/Cloud/Instanz",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_instanzGrid",
            "options": {
                "optionsName": "r_instanzGridOptions",
                "optionsNameFQ": "r_instanzGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}