import { autoinject, bindable, Scope, createOverrideContext, TaskQueue, computedFrom } from "aurelia-framework";
import { Report } from "../report/report";
import { IVariable } from '../../interfaces/variable';
import { GlobalizationService, DataSourceService, ScopeContainer, JsonService } from '../../../base/export';
import { VariableService } from '../../services/variable-service';

@autoinject
export class Variable {
  constructor(
    private globalizationService: GlobalizationService,
    private variableService: VariableService,
    private dataSourceService: DataSourceService,
    private jsonService: JsonService,
    private taskQueue: TaskQueue
  ) { }

  @bindable id: string;

  report: Report;
  variable: IVariable;
  state: any;

  scope: Scope;
  scopeContainer: ScopeContainer;

  dropDownPopupInstance: DevExpress.ui.dxPopup;

  operatorOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "text",
    valueExpr: "key",
    onInitialized: (e) => {
      e.component.option("value", this.state.operator);

      e.component["_variableChanged"] = this.report.registerVariableChanged(this.id, () => {
        e.component.option("value", this.state.operator);
      });
    },
    onValueChanged: (e) => {
      if (!e.event) {
        return;
      }

      this.state.operator = e.value;
      this.variableService.checkVariableValue(this.state);

      this.report.fireVariableChanged(this.id);
    },
    onDisposing: (e) => {
      if (!e.component["_variableChanged"]) {
        return;
      }

      e.component["_variableChanged"]();
      delete e.component["_variableChanged"];
    }
  };

  dropDownOptions: DevExpress.ui.dxDropDownBoxOptions = {
    width: "100%",
    contentTemplate: "contentTemplate",
    dropDownOptions: {
      height: "auto",
      minHeight: "250px",
      onShowing: (e) => {
        this.dropDownPopupInstance = e.component;
      },
      onHidden: (e) => {
        this.dropDownPopupInstance = null;
      }
    },
    onInitialized: (e) => {
      this.setDropDownText(e.component);

      e.component["_variableChanged"] = this.report.registerVariableChanged(this.id, () => {
        this.setDropDownText(e.component);
      });
    },
    onClosed: (e) => {
      this.report.fireVariableChanged(this.id);
    },
    onDisposing: (e) => {
      if (!e.component["_variableChanged"]) {
        return;
      }

      e.component["_variableChanged"]();
      delete e.component["_variableChanged"];
    }
  };
  deleteValueOptions: DevExpress.ui.dxButtonOptions = {
    text: "Löschen",
    width: "100%",
    onClick: (e) => {
      const value = e.model.bindingContext.value;
      const index = this.state.value.indexOf(value);
      if (index < 0) {
        return;
      }

      this.state.value.splice(index, 1);
      this.repaintDropDownPopup();
    }
  };

  editorType: string;
  editorOptions: DevExpress.ui.EditorOptions = {
    width: "100%",
    onInitialized: (e) => {
      this.setCurrentValue(e.component, e["model"]);

      const valueField = this.getValueField(e.component);

      if (valueField == "valueView") {
        e.component.option("readOnly", true);
      } else {
        e.component["_variableChanged"] = this.report.registerVariableChanged(this.id, () => {
          this.setCurrentValue(e.component);
        });
      }
    },
    onValueChanged: (e) => {
      if (!e.event) {
        return;
      }

      const valueKey = this.getValueField(e.component);
      if (valueKey == "valueList") {
        if (e.value == void (0)) {
          return;
        }

        e.component.option("value", null);
        this.repaintDropDownPopup();
      }

      if (!this.showOperator) {
        this.state.operator = "=";
      }

      this.variableService.setVariableValue(this.state, valueKey, e.value);

      if (valueKey != "valueList") {
        this.report.fireVariableChanged(this.id);
      }
    },
    onDisposing: (e) => {
      if (!e.component["_variableChanged"]) {
        return;
      }

      e.component["_variableChanged"]();
      delete e.component["_variableChanged"];
    }
  };

  @computedFrom("variable")
  get showOperator(): boolean {
    const validEditorTypes = ["dxTextBox", "dxDateBox", "dxNumberBox", "dxSelectBox"];

    return this.variable 
      && validEditorTypes.indexOf(this.editorType) >= 0;
  }

  bind(bindingContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.report = bindingContext.report;

    this.variable = this.report.reportDef.variables.find(v => v.id == this.id);
    if (!this.variable) {
      throw new Error("Ungültige Variable ".concat(this.id));
    }

    this.state = this.report.viewModel.variable[this.id];
    if (!this.state) {
      this.state = {};
      this.report.viewModel.variable[this.id] = this.state;
    }

    const operators = this.variable.operators;
    if (operators.length == 0) {
      operators.push("=");
    }

    this.operatorOptions.dataSource = this.variableService
      .getAllOperators()
      .filter(o => operators.indexOf(o.key) >= 0);

    if (!this.state.operator) {
      this.state.operator = operators[0];
    }

    switch (this.variable.type) {
      case "text": {
        this.editorType = this.variable.editor || "dxTextBox";

        if (this.editorType == "dxTextBox") {
          const textBoxOptions: DevExpress.ui.dxTextBoxOptions = this.editorOptions;
          textBoxOptions.showClearButton = true;
        }

        break;
      }
      case "date": {
        this.editorType = this.variable.editor || "dxDateBox";

        if (this.editorType == "dxDateBox") {
          const dateBoxOptions: DevExpress.ui.dxDateBoxOptions = this.editorOptions;
          dateBoxOptions.showClearButton = true;
          dateBoxOptions.displayFormat = this.globalizationService.getFormatterParser(this.variable.format || "d");
        }

        break;
      }
      case "number": {
        this.editorType = this.variable.editor || "dxNumberBox";

        if (this.editorType == "dxNumberBox") {
          const numberBoxOptions: DevExpress.ui.dxNumberBoxOptions = this.editorOptions;
          numberBoxOptions.showClearButton = true;
          numberBoxOptions.format = this.globalizationService.getNumberFormat(this.variable.format || "n2");
        }

        break;
      }
      case "bool": {
        this.editorType = this.variable.editor || "dxSwitch";

        if (this.editorType == "dxSwitch") {
          const switchOptions: DevExpress.ui.dxSwitchOptions = this.editorOptions;
          switchOptions.switchedOnText = "Ja";
          switchOptions.switchedOffText = "Nein";
        }

        if (this.state.value == void (0)) {
          this.state.value = false;
        }
        break;
      }
      case "lookup": {
        this.editorType = "dxSelectBox";

        const selectBoxOptions: DevExpress.ui.dxSelectBoxOptions = this.editorOptions;
        selectBoxOptions.searchEnabled = true;
        selectBoxOptions.showClearButton = true;
        selectBoxOptions.valueExpr = this.variable.lookupValueExpr;
        selectBoxOptions.displayExpr = this.variable.lookupDisplayExpr;

        const getOptions = this.jsonService.parse(this.variable.lookupGetOptions || "{}");
        selectBoxOptions.dataSource = this.dataSourceService.createDataSource(
          this.scopeContainer, {
            webApiAction: this.variable.lookupAction,
            keyProperty: this.variable.lookupValueExpr,
            webApiColumns: getOptions.columns ? getOptions.columns : null,
            webApiExpand: getOptions.expand ? getOptions.expand : null,
            webApiOrderBy: getOptions.orderBy ? getOptions.orderBy : null,
            webApiWhere: getOptions.where ? getOptions.where : null,
          }
        );

        break;
      }
      default: {
        throw new Error("Unbekannter Variablen-Typ ".concat(this.variable.type));
      }
    }

    if (this.variable.editorOptions) {
      const func = new Function(this.variable.editorOptions);
      const options = func();

      this.editorOptions = Object.assign(options, this.editorOptions);
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  getValueField(component: DevExpress.ui.Widget): string {
    return component.element().parentElement.getAttribute("value-field");
  }
  setCurrentValue(component: DevExpress.ui.Widget, model: any = null) {
    const valueKey = this.getValueField(component);
    if (valueKey == "valueList") {
      return;
    } else if (valueKey == "valueView") {
      const value = model && model.bindingContext
        ? model.bindingContext.value
        : null;
      component.option("value", value);
    } else {
      const value = this.variableService.getVariableValue(this.state, valueKey);
      component.option("value", value);
    }
  }
  async setDropDownText(component: DevExpress.ui.dxDropDownBox) {
    const valueList = [];

    if (Array.isArray(this.state.value)) {
      for (let value of this.state.value) {
        const displayText = await this.variableService.getVariableDisplayText(this.variable, value);
        valueList.push(displayText);
      }
    }

    component.option("value", valueList.join(" | "));
  }
  repaintDropDownPopup() {
    if (!this.dropDownPopupInstance) {
      return;
    }

    this.taskQueue.queueTask(() => {
      this.dropDownPopupInstance.repaint();
    });
  }
}