import { autoinject, transient } from "aurelia-framework";
import { FormBase } from '../../../../framework/forms/export';

@autoinject
@transient()
export class ImpressumUtils {
  constructor() {}
    
  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
