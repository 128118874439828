import { TicketStatus } from './../enumerations/ticket-status';
import {
  autoinject,
  valueConverter
} from "aurelia-framework";

@autoinject
@valueConverter("ticketStatusFarbe")
export class TicketStatusFarbeValueConverter {
  constructor(
  ) { }

  toView(value: any, farbe: string) {
    if (value == null) {
      return "";
    }

    let ticketStatus = value;

    switch (ticketStatus) {
      case TicketStatus.Offen:
        farbe = ""
        break;
      case TicketStatus.InBearbeitungTIP:
        farbe = "#df7401"
        break;
      case TicketStatus.ErledigtTIP:
      case TicketStatus.MitNaechstemUpdate:
        farbe = "#2DCC70"
        break;
      case TicketStatus.ErledigtKunde:
        farbe = "#2DCC70"
        break;
      case TicketStatus.WarteAufKunde:
        farbe = "#df7401"
        break;
      case TicketStatus.Doppelt:
        farbe = "#C1392D"
        break;
    }

    return farbe;
  }
}
