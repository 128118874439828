import {
  autoinject,
  singleton,
  TaskQueue
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  FormBase
} from "../classes/form-base";
import {
  ToolbarService
} from "../services/toolbar-service";
import {
  PopupInfoService
} from "../services/popup-info-service";
import {
  SimpleWidgetCreatorService
} from "../widget-services/simple-widget-creator-service";
import {
  IEditPopupHiddenEventArgs,
  IEditPopupShownEventArgs,
  IEditPopupModelLoadedEventArgs
} from "../event-args/export";
import {
  CustomEvent
} from "../../base/classes/custom-event";
import {
  IViewScrollInfo
} from "../../base/interfaces/export";
import * as Interfaces from "../interfaces/export";

@autoinject
@singleton(true)
export class EditPopups {
  private _form: FormBase;
  private _editPopups: Interfaces.IEditPopup[] = [];
  private _options: IEditPopupShowOptions;

  constructor(
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private toolbar: ToolbarService,
    private popupInfo: PopupInfoService,
    private taskQueue: TaskQueue,
    private eventAggregator: EventAggregator,
    public onEditPopupShown: CustomEvent<IEditPopupShownEventArgs>,
    public onEditPopupHidden: CustomEvent<IEditPopupHiddenEventArgs>,
    public onEditPopupModelLoaded: CustomEvent<IEditPopupModelLoadedEventArgs>
  ) {}

  addInfo(editPopup: Interfaces.IEditPopup) {
    this._editPopups.push(editPopup);
    this.createOptions(editPopup);
  }
  getInfo(id: string): Interfaces.IEditPopup {
    return this._editPopups.find(c => c.id === id);
  
  }
  async show(id: string, viewScrollInfo: IViewScrollInfo) {
    return this.showEx({
      idEditPopup: id,
      viewScrollInfo: viewScrollInfo
    });
  }
  async showEx(options: IEditPopupShowOptions) {
    this._options = options;

    const saveResult = await this._form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const editPopup = this._editPopups.find(c => c.id === options.idEditPopup);

    if (!editPopup) {
      throw new Error(`No edit popup with id ${options.idEditPopup} found`);
    }

    const instance: DevExpress.ui.dxPopup = this._form[editPopup.id].instance;

    let showInstance = true;
    if (!editPopup.isInitialized) {
      this.initializeContent(instance, editPopup);

      //wird durch onContentTemplateRendered ausgelöst
      showInstance = false;
    }

    const editPopupFormBase: FormBase = this._form[editPopup.idContent]
    editPopupFormBase.viewScrollInfo = options.viewScrollInfo;

    if (showInstance) {
      instance.show();
    }
  }

  registerForm(form: FormBase) {
    if (this._form) {
      throw new Error("Form was already registered");
    }

    this._form = form;
  }

  dispose() {
    this.onEditPopupHidden.dispose();
    this.onEditPopupModelLoaded.dispose();
    this.onEditPopupShown.dispose();
  }

  private createOptions(editPopup: Interfaces.IEditPopup) {
    this.simpleWidgetCreator.addPopup(this._form, editPopup, false);
  }
  private initializeContent(instance: DevExpress.ui.dxPopup, editPopup: Interfaces.IEditPopup) {
    editPopup.isInitialized = true;

    this._form[editPopup.options.optionsName].onContentTemplateRendered = (e) => {
      this.taskQueue.queueTask(() => {
        e.instance.show();
      });
    };

    instance.option("deferRendering", false);

    const popup: DevExpress.ui.dxPopup = this._form[editPopup.id].instance;
    const content: FormBase = this._form[editPopup.idContent];

    content.models.onLoaded.register(r => {
      const modelLoadedArgs = {
        editPopup: editPopup,
        model: r.model,
        data: r.data
      };

      if (this._options.modelLoaded) {
        this._options.modelLoaded(content, modelLoadedArgs);
      }

      return this.onEditPopupModelLoaded.fire(modelLoadedArgs);
    });

    content.models.onSaved.register(async e => {
      if (this._options.savedCallback) {
        this._options.savedCallback(content);
      }
    });

    content.callOnCommandsLoaded(() => {
      const toolbarOptions = this.toolbar.createToolbarOptions(
        content.scopeContainer,
        content.title, 
        content.commands.getCommands());

      content.binding.observe({
        scopeContainer: content.scopeContainer,
        expression: "title",
        callback: (newVal) => {
          toolbarOptions.title = newVal;
        }
      });

      this._form[`${editPopup.id}Toolbar`] = toolbarOptions;
    });

    popup.on({
      showing: (e) => {
        this.popupInfo.onShowPopup({
          owner: this._form,
          popup: e.component,
          form: content,
          executeCommand: (idCommand) => {
            content.executeCommand(idCommand);
          },
          handleChangedData: () => {
            return content.handleChangedData();
          },
          hasChangedData: () => {
            return content.models.hasChangedData();
          }
        });
      },
      shown: () => {
        editPopup.mappings.forEach(m => {
          content.variables.data[m.to] = this._form.binding.evaluate(
            this._form.scope, 
            m.binding.bindToFQ
          );
        });

        this.onEditPopupShown.fire({
          editPopup: editPopup
        });
        this.eventAggregator.publish("editpopup:shown", {
          form: content
        });

        content.dispatchOnReady();
      },
      hiding: (e) => {
        this.popupInfo.onHidePopup(e.component);

        if (this._options.closeCallback) {
          this._options.closeCallback(content);
        }

        this.eventAggregator.publish("editpopup:hiding", {
          form: content
        });
      },
      hidden: async () => {
        editPopup.mappings.forEach(m => {
          content.variables.data[m.to] = null;
        });

        const hasDataReloaded = await this._form.reloadIfServerHasNewVersion();

        this.onEditPopupHidden.fire({
          editPopup: editPopup,
          hasDataReloaded: hasDataReloaded
        });
        this.eventAggregator.publish("editpopup:hidden", {
          form: content
        });
      }
    });
  }
}

export interface IEditPopupShowOptions {
  idEditPopup: string;
  viewScrollInfo?: IViewScrollInfo;
  modelLoaded?: {(form: FormBase, ev: IEditPopupModelLoadedEventArgs): void};
  savedCallback?: {(form: FormBase): void};
  closeCallback?: {(form: FormBase): void};
}