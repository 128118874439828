import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ArbeitszeitKostenstelleListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "arbeitszeit-kostenstelle-list";
        this.title = "arbeitszeit-kostenstelle-list.arbeitszeit-kostenstelle-list_caption";
        this.addModel({
            "id": "$m_ArbeitszeitKostenstelle",
            "webApiAction": "ERP/Arbeitszeit/ArbeitszeitKostenstelle",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kostenstelleBezeichnung",
                "caption": "arbeitszeit-kostenstelle-list.r_kostenstellebezeichnung_caption",
                "bindTo": "Bezeichnung"
            }, {
                "id": "r_kostenstelleSortNr",
                "caption": "arbeitszeit-kostenstelle-list.r_kostenstellesortnr_caption",
                "bindTo": "SortNr"
            }],
            "optionsToolbar": {
                "optionsName": "r_arbeitszeitKostenstelleGridToolbarOptions",
                "optionsNameFQ": "r_arbeitszeitKostenstelleGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_ArbeitszeitKostenstelle"
            },
            "dataModel": "$m_ArbeitszeitKostenstelle",
            "editUrl": "ERP/Arbeitszeit/ArbeitszeitKostenstelle",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_arbeitszeitKostenstelleGrid",
            "options": {
                "optionsName": "r_arbeitszeitKostenstelleGridOptions",
                "optionsNameFQ": "r_arbeitszeitKostenstelleGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}