import {
  autoinject
} from "aurelia-framework";
import {
  CustomEvent
} from "../../base/export";
import {
  IFormAttachedEventArgs,
  IFormCreatedEventArgs,
  IFormBindEventArgs,
  IFormUnbindEventArgs,
  IFormReadyEventArgs,
  IFormReactivatedEventArgs,
  IFormDetachedEventArgs,
  IFormValidatingEventArgs,
  IFormExecuteCommandEventArgs,
  IFormSavedEventArgs,
  IFormSavingEventArgs,
  IFormDeletedEventArgs
} from "../event-args/export";
import { IFormDeletingEventArgs } from '../event-args/form-deleting';

@autoinject
export class FormEventService {
  constructor(
    public onCreated: CustomEvent<IFormCreatedEventArgs>,
    public onBind: CustomEvent<IFormBindEventArgs>,
    public onUnbind: CustomEvent<IFormUnbindEventArgs>,
    public onAttached: CustomEvent<IFormAttachedEventArgs>,
    public onReady: CustomEvent<IFormReadyEventArgs>,
    public onReactivating: CustomEvent<IFormReactivatedEventArgs>,
    public onDetached: CustomEvent<IFormDetachedEventArgs>,
    public onValidating: CustomEvent<IFormValidatingEventArgs>,
    public onSaving: CustomEvent<IFormSavingEventArgs>,
    public onSaved: CustomEvent<IFormSavedEventArgs>,
    public onDeleting: CustomEvent<IFormDeletingEventArgs>,
    public onDeleted: CustomEvent<IFormDeletedEventArgs>,
    public onExecuteCommand: CustomEvent<IFormExecuteCommandEventArgs>
  ) {}
}
