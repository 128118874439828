import {
  autoinject,
  TaskQueue
} from "aurelia-framework";
import {
  IKalkulationRefreshOptions
} from "../interfaces/kalkulation-refresh-options";

@autoinject
export class KalkulationRefreshService {
  constructor(
    private taskQueue: TaskQueue
  ) { }

  registerRefresh(options: IKalkulationRefreshOptions) {
    options.form.callOnBind(() => {
      this.registerEditorValueChanged(options);
      this.registerEditPopups(options);
    });
  }
  onIdArtikelChanged(options: IKalkulationRefreshOptions) {
    this.doKalkulieren(options, true);
  }

  private registerEditorValueChanged(options: IKalkulationRefreshOptions) {
    options.form.onEditorValueChanged.register(e => {
      if (!e.binding || e.binding.dataContext != options.modelName) {
        return;
      }

      const hasPropertyChanged = (options.mengeVerpEHPropertyName && e.binding.bindTo === options.mengeVerpEHPropertyName)
        || (options.einheitPropertyName && e.binding.bindTo === options.einheitPropertyName);

      if (hasPropertyChanged) {
        this.doKalkulieren(options, false);
      }

      return Promise.resolve();
    });
  }
  private registerEditPopups(options: IKalkulationRefreshOptions) {
    if (!options.editPopups) {
      return;
    }

    options.form.editPopups.onEditPopupHidden.register(e => {
      if (!options.editPopups.some(x => x == e.editPopup.id)) {
        return;
      }

      this.doLoadItemsFromContainer(options);
      return Promise.resolve();
    });
  }

  private doKalkulieren(options: IKalkulationRefreshOptions, refreshKonditionen: boolean) {
    this.taskQueue.queueMicroTask(() => {
      options.form[options.kalkulationRef].doKalkulieren(refreshKonditionen, false);
    });
  }
  private doLoadItemsFromContainer(options: IKalkulationRefreshOptions) {
    this.taskQueue.queueMicroTask(() => {
      options.form[options.kalkulationRef].doLoadItemsFromContainer();
    });
  }
}
