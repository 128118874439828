import * as fwx from "../../../../framework/forms/form-export";
import {
    NewsletterEmpfaengerListUtils
} from "./newsletter-empfaenger-list-utils";

@fwx.autoinject
export class NewsletterEmpfaengerListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_NewsletterEmpfaengerListUtils: NewsletterEmpfaengerListUtils) {
        super(element, formBaseImport);
        this.id = "newsletter-empfaenger-list";
        this.title = "newsletter-empfaenger-list.newsletter-empfaenger-list_caption";
        this.addModel({
            "id": "$m_Empfaenger",
            "webApiAction": "ERP/Stammdaten/NewsletterEmpfaenger",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_Import",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_NewsletterEmpfaengerListUtils.showImportCommand",
                "bindToFQ": "functions.$f_NewsletterEmpfaengerListUtils.showImportCommand"
            }
        });
        this.addFunction("$f_NewsletterEmpfaengerListUtils", $f_NewsletterEmpfaengerListUtils, "functions.$f_NewsletterEmpfaengerListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_empfBezeichnung",
                "caption": "newsletter-empfaenger-list.r_empfbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }],
            "optionsToolbar": {
                "optionsName": "r_empfaengerToolbarOptions",
                "optionsNameFQ": "r_empfaengerToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Empfaenger"
            },
            "dataModel": "$m_Empfaenger",
            "editUrl": "ERP/Stammdaten/NewsletterEmpfaenger",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_empfaenger",
            "options": {
                "optionsName": "r_empfaengerOptions",
                "optionsNameFQ": "r_empfaengerOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_import",
            "options": {
                "optionsName": "r_importOptions",
                "optionsNameFQ": "r_importOptions"
            },
            "caption": "newsletter-empfaenger-list.r_import_caption",
            "height": "385px",
            "width": "600px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_NewsletterEmpfaengerListUtils.doImportCommand",
                    "bindToFQ": "functions.$f_NewsletterEmpfaengerListUtils.doImportCommand"
                }
            }]
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "newsletter-empfaenger-list.r_impbezeichnung_caption",
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Import.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_impBezeichnung",
            "options": {
                "optionsName": "r_impBezeichnungOptions",
                "optionsNameFQ": "r_impBezeichnungOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "caption": "newsletter-empfaenger-list.r_impfile_caption",
            "binding": {
                "dataContext": "$m_Import",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_Import.DMSLink"
            },
            "validationRules": [],
            "id": "r_impFile",
            "options": {
                "optionsName": "r_impFileOptions",
                "optionsNameFQ": "r_impFileOptions"
            }
        });
        super.onConstructionFinished();
    }
}