import {
  autoinject,
  bindable,
  observable
} from "aurelia-framework";

import {
  FormBase
} from "../../../framework/forms/export";

import {
  DataSourceService,
  GlobalizationService,
  LocalizationService
} from "../../../framework/base/export";

import {
  KalkulationFeldTyp
} from "../../enumerations/export";

import {
  Kalkulation
} from "../kalkulation/kalkulation";

import {
  IdxDateBoxComponent,
  IdxNumberBoxComponent
} from "../../interfaces/export";
import { WaehrungService, StartupService, FirmaService } from "../../services/export";
import { KonditionsfeldWaehrung } from "../../../framework-data/events";


@autoinject
export class KalkulationContainer {
  constructor(
    private dataSource: DataSourceService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private waehrungService: WaehrungService,
    private startupService: StartupService,
    private firmaService: FirmaService
  ) { }

  @bindable @observable idArtikel: number;
  @bindable @observable idKunde: number;

  kalkulation: Kalkulation;
  kalkulationModel: any = {
    datum: new Date(),
    menge: 1
  };

  form: FormBase;

  firmaOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "kalkulationModel.idFirma"
    }
  }
  artikelOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "kalkulationModel.idArtikel"
    }
  }
  berechnungVersionOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "BezeichnungMitDefinition",
    valueExpr: "Id",
    bindingOptions: {
      value: "kalkulationModel.idBerechnungsversion"
    }
  }
  kundeOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "DisplayText",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "kalkulationModel.idKunde"
    }
  }
  mengeOptions: DevExpress.ui.dxNumberBoxOptions = {
    bindingOptions: {
      value: "kalkulationModel.menge"
    }
  };
  datumOptions: DevExpress.ui.dxDateBoxOptions = {
    displayFormat: this.globalization.getFormatterParser("d"),
    bindingOptions: {
      value: "kalkulationModel.datum"
    }
  };
  waehrungOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "ISO4217",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "kalkulationModel.idWaehrung"
    }
  }
  kalkulationButtonOption: DevExpress.ui.dxButtonOptions = {
    text: this.localization.translateOnce("kalkulation_container.kalkulieren_aktion"),
    icon: "fa fa-refresh fa-fw",
    width: "100%",
    onClick: () => {
      if (!this.kalkulationModel.idBerechnungsversion) {
        DevExpress.ui.notify(this.localization.translateOnce("kalkulation_container.kein_schema_gewaehlt"), "ERROR", 3000);
        return;
      }

      this.kalkulation.doKalkulieren(true);
    }
  }

  idArtikelChanged(newValue) {
    this.kalkulationModel.idArtikel = newValue;
    this.kalkulation.reset();
  }
  idKundeChanged(newValue) {
    this.kalkulationModel.idKunde = newValue;
  }

  bind(bindingContext) {
    this.form = bindingContext.form || bindingContext;

    this.artikelOptions.dataSource = this.dataSource.createDataSource(this.form.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Artikel",
        webApiColumns: ["Id", "Bezeichnung"],
        webApiSearchtextEnabled: true,
        keyProperty: "Id"
      });
    this.berechnungVersionOptions.dataSource = this.dataSource.createDataSource(this.form.scopeContainer,
      {
        webApiAction: "ERP/Kalkulation/Berechnungsversion",
        webApiColumns: ["Id", "BezeichnungMitDefinition"],
        keyProperty: "Id"
      });
    this.kundeOptions.dataSource = this.dataSource.createDataSource(this.form.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Geschaeftspartner",
        webApiColumns: ["Id", "DisplayText", "IdGeschaeftspartnerart"],
        webApiWhere: ["Geschaeftspartnerart.IsKunde", true],
        webApiSearchtextEnabled: true,
        keyProperty: "Id"
      });
    this.firmaOptions.dataSource = this.dataSource.createDataSource(this.form.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Firma",
        webApiColumns: ["Id", "Bezeichnung"],
        webApiSearchtextEnabled: true,
        keyProperty: "Id"
      });

    const waehrungList = this
      .waehrungService
      .getWaehrungList(KonditionsfeldWaehrung.Alles);

    this.waehrungOptions.dataSource = waehrungList;

    if (this.startupService
      && this.startupService.startupInfo
      && this.startupService.startupInfo.Mandant
      && this.startupService.startupInfo.Mandant.IdWaehrung) {
      this.kalkulationModel.idWaehrung = this.startupService.startupInfo.Mandant.IdWaehrung;
    }

    this.firmaService.getIdFirmaVorbelegung().then(r => this.kalkulationModel.idFirma = r);
  }
}
