import { autoinject, bindable, bindingMode, computedFrom, observable, TaskQueue } from "aurelia-framework";
import { RestService } from "../../../framework/base/export";
import { IdxTextBoxComponent } from "../../interfaces/export";
import { DxCustomizeService } from "../../services/dx-customize-service";
import { ArtikelSearchService, ArtikelService, CustomEditPopupService } from "../../services/export";

@autoinject
export class Artikel {

  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _element: Element,
    private _taskQueue: TaskQueue,
    private _artikelService: ArtikelService,
    private _artikelSearchService: ArtikelSearchService,
    private _restService: RestService,
    private _dxCustomizeService: DxCustomizeService
  ) {
    this.label = "artikel.artikel";
  }

  @bindable @observable mainModel: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable idArtikel: number;
  @bindable @observable isReadOnly: boolean;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) bezeichnung: string;
  @bindable hasOwnBezeichnung: boolean;
  @bindable label: string;
  @bindable isRequired: boolean = true;

  @bindable where: any;

  isBezeichnungEditierbar: boolean = false;

  @computedFrom("isReadOnly", "mainModel.CanSave")
  get isReadOnlyChecked() {
    return this.isReadOnly || (this.mainModel && this.mainModel.CanSave === false);
  }
  @computedFrom("isReadOnlyChecked", "isBezeichnungEditierbar", "hasOwnBezeichnung")
  get isReadOnlyBezeichnung() {
    return (this.mainModel && this.mainModel.CanSave === false)
      || !this.isBezeichnungEditierbar
      || !this.hasOwnBezeichnung;
  }

  validatorOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [{
      type: "custom",
      reevaluate: true,
      message: "Artikel ist ein Pfichtfeld",
      validationCallback: (ev) => {
        if (!this.isRequired) {
          return true;
        }

        return !!ev.value;
      }
    }]
  };

  artikelNummer: IdxTextBoxComponent;
  artikelNummerOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    onInitialized: (e) => {
      this.updateArtikelButtons(e.component);
    },
    onValueChangedByUser: (e) => {
      if (!e.value) {
        this.resetData(false);
      } else {
        this.onArtikelChanged(e.value);
      }
    },
    bindingOptions: {
      readOnly: {
        expression: "isReadOnlyChecked",
        readOnly: true
      }
    }
  };
  artikelBezeichnung: IdxTextBoxComponent;
  artikelBezeichnungOptions: DevExpress.ui.dxTextAreaOptions = {
    tabIndex: -1,
    height: "100px",
    maxLength: 500,
    onValueChangedByUser: (e) => {
      this.bezeichnung = e.value;
    },
    bindingOptions: {
      readOnly: {
        expression: "isReadOnlyBezeichnung",
        readOnly: true
      }
    }
  };

  bind() {
    if (this.idArtikel) {
      this.setArtikelData(this.idArtikel);
    }
  }

  idArtikelChanged(newValue, oldValue) {
    if (newValue == void 0 && oldValue == void 0) {
      return;
    }

    if (newValue == void 0) {
      this.resetData(true);
      return;
    }

    this.setArtikelData(newValue);
  }
  mainModelChanged() {
    if (!this.hasOwnBezeichnung) {
      return;
    }

    this._taskQueue.queueMicroTask(() => {
      this.setBezeichnung(this.bezeichnung);
    });
  }
  isReadOnlyChanged(newValue) {
    this.artikelNummerOptions.readOnly = newValue;

    if (this.artikelNummer && this.artikelNummer.instance) {
      this.artikelNummer.instance.option("readOnly", newValue);
    }
  }

  async onArtikelChanged(searchValue) {
    const isValidNumber: boolean = /^[0-9]+$/.test(searchValue);

    const r1 = await this._artikelService.getArtikelByNummer(searchValue, this.where);
    if (r1 && r1.length === 1) {
      this.setIdArtikel(r1[0].Id, false);
      this.setBezeichnung(r1[0].Bezeichnung);
      return;
    }

    const r2 = await this._artikelService.getArtikelBySearchtextEindeutig(searchValue, this.where);
    if (r2 && r2.length === 1) {
      this.setIdArtikel(r2[0].Id, false);
      this.setBezeichnung(r2[0].Bezeichnung);
      return;
    }

    this.showArtikelSearchPopup(searchValue);
  }

  showArtikelSearchPopup(searchValue) {
    this._artikelSearchService.show(
      (idArtikel) => {
        this._artikelService.getArtikelById(idArtikel)
          .then(r => {
            this.setIdArtikel(r.Id, false);
            this.setBezeichnung(r.Bezeichnung);
          });
      }, () => {
        this.resetData(false);
        this.artikelNummer.instance.focus();
      },
      { searchValue: searchValue, where: this.where });
  }

  private resetData(isResetByCode: boolean) {
    this.setIdArtikel(null, isResetByCode);
    this.setNummer(null);
    this.setBezeichnung(null);
    this.resetValidation();
  }
  private resetValidation() {
    this.artikelNummer.instance.option("isValid", true);
  }
  private setIdArtikel(id: number, isChangeByCode: boolean) {
    this.idArtikel = id;

    if (!isChangeByCode) {
      this._taskQueue.queueMicroTask(() => {
        const event = new CustomEvent("id-artikel-changed", {
          detail: {
            sender: this,
            value: id
          },
          bubbles: true
        });

        this._element.dispatchEvent(event);
      });
    }
  }
  private async setArtikelData(id: number, forceUpdateBezeichnung: boolean = false) {
    if (!id) {
      return;
    }
    const artikel = await this._artikelService.getArtikelById(id);
    const bezeichnung = this.hasOwnBezeichnung && !forceUpdateBezeichnung
      ? this.bezeichnung
      : artikel.Bezeichnung;

    this.setBezeichnung(bezeichnung);

    this.isBezeichnungEditierbar = artikel.IsBezeichnungEditierbar;

    this.setNummer(artikel.Nummer);
  }
  private setBezeichnung(bezeichnung: string) {
    this.bezeichnung = bezeichnung;
    if (!this.artikelBezeichnung) {
      return;
    }

    if (this.artikelBezeichnung) {
      this.artikelBezeichnung.setOption({ value: bezeichnung });
    } else {
      this.artikelBezeichnungOptions.value = bezeichnung;
    }
    this.updateArtikelButtons();
  }
  private setNummer(nummer: number | string) {
    if (!this.artikelNummer) {
      this.updateArtikelButtons();
      return;
    }

    if (nummer != void 0) {
      nummer = nummer.toString();
    }

    if (this.artikelNummer) {
      this.artikelNummer.setOption({ value: nummer });
    } else {
      this.artikelNummerOptions.value = <string>nummer;
    }
    this.updateArtikelButtons();
  }

  private updateArtikelButtons(editor: DevExpress.ui.dxTextBox = null) {
    if (!editor) {
      if (this.artikelNummer && this.artikelNummer.instance) {
        editor = this.artikelNummer.instance;
      }
    }

    if (!editor) {
      return;
    }

    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (this.idArtikel) {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-pencil",
          stylingMode: "text",
          tabIndex: -1,
          onClick: (e) => {
            if (e.event && (<any>e.event).shiftKey) {
              this.openArtikelInNewTab();
              return;
            }

            this.openArtikelPopup();
          }
        }
      });
    } else if (!this.isReadOnlyChecked) {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            this.openArtikelPopup();
          }
        }
      });
    }

    if (editor.option("value")) {
      buttons.push({
        location: "after",
        name: "clear"
      });
    }

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
  private openArtikelInNewTab() {
    const url = this._restService.getViewUrl("#ERP/Stammdaten/Artikel/".concat(this.idArtikel.toString()));

    window.open(url, "_blank");
  }
  private openArtikelPopup() {
    if (this._customEditPopupService.artikelEditPopup.isVisible) {
      this.openArtikelInNewTab();
      return;
    }

    this._customEditPopupService.artikelEditPopup.show({
      mappings: { "$id": this.idArtikel || 0 },
      savedCallback: async (form) => {
        if (this.idArtikel && !this.isBezeichnungEditierbar && !this.isReadOnlyChecked) {
          await this.setArtikelData(this.idArtikel, true);
        }
      },
      closeCallback: async (form) => {
        if (this.idArtikel && !this.isBezeichnungEditierbar && !this.isReadOnlyChecked) {
          return;
        }
        const model = form.models.modelWithKeyId;
        const data = form.models.data[model.id];

        if (!data || !data.Id) {
          return;
        }

        if (this.idArtikel != data.Id) {
          this.setIdArtikel(data.Id, false);
        }
        await this.setArtikelData(data.Id, true);
      }
    });
  }
}
