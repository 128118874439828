import * as fwx from "../../../../framework/forms/form-export";
import {
    AbschlagfakturaEditUtils
} from "./abschlagfaktura-edit-utils";

@fwx.autoinject
export class AbschlagsfakturaEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AbschlagfakturaEditUtils: AbschlagfakturaEditUtils) {
        super(element, formBaseImport);
        this.id = "abschlagsfaktura-edit";
        this.title = "abschlagsfaktura-edit.abschlagsfaktura-edit_caption";
        this.addModel({
            "id": "$m_Abschlagsfaktura",
            "webApiAction": "ERP/Verkauf/Abschlagfaktura",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Auftrag.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Abschlagsfaktura.IdAuftrag",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_AbschlagfakturaEditUtils", $f_AbschlagfakturaEditUtils, "functions.$f_AbschlagfakturaEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "showClearButton": true,
            "min": 0.0,
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_wertprozent_caption",
            "isReadOnlyExpression": "models.data.$m_Abschlagsfaktura.IdFaktura > 0 || (models.data.$m_Abschlagsfaktura.WertProzent == null && models.data.$m_Abschlagsfaktura.WertNetto > 0)",
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "WertProzent",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.WertProzent"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_AbschlagfakturaEditUtils.onWertProzentValueChanged",
            "id": "r_wertProzent",
            "options": {
                "optionsName": "r_wertProzentOptions",
                "optionsNameFQ": "r_wertProzentOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_wertnetto_caption",
            "isReadOnlyExpression": "models.data.$m_Abschlagsfaktura.IdFaktura > 0 || models.data.$m_Abschlagsfaktura.WertProzent > 0",
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "WertNetto",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.WertNetto"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wertNetto",
            "options": {
                "optionsName": "r_wertNettoOptions",
                "optionsNameFQ": "r_wertNettoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_steuersatz_caption",
            "isReadOnlyExpression": "models.data.$m_Abschlagsfaktura.IdFaktura > 0",
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "Steuersatz",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.Steuersatz"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_steuersatz",
            "options": {
                "optionsName": "r_steuersatzOptions",
                "optionsNameFQ": "r_steuersatzOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "abschlagsfaktura-edit.r_bezeichnung_caption",
            "isReadOnlyExpression": "models.data.$m_Abschlagsfaktura.IdFaktura > 0",
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "abschlagsfaktura-edit.r_isvorausinkasso_caption",
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "IsVorausinkasso",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.IsVorausinkasso"
            },
            "validationRules": [],
            "id": "r_isVorausinkasso",
            "options": {
                "optionsName": "r_isVorausinkassoOptions",
                "optionsNameFQ": "r_isVorausinkassoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_auftrwertnetto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "WertNetto",
                "bindToFQ": "models.data.$m_Auftrag.WertNetto"
            },
            "validationRules": [],
            "id": "r_auftrWertNetto",
            "options": {
                "optionsName": "r_auftrWertNettoOptions",
                "optionsNameFQ": "r_auftrWertNettoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_auftabschlagoffen_caption",
            "binding": {
                "dataContext": "$m_Auftrag",
                "bindTo": "AbschlagsfakturaOffen",
                "bindToFQ": "models.data.$m_Auftrag.AbschlagsfakturaOffen"
            },
            "validationRules": [],
            "id": "r_auftAbschlagOffen",
            "options": {
                "optionsName": "r_auftAbschlagOffenOptions",
                "optionsNameFQ": "r_auftAbschlagOffenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_betragbezahltvorauskassa_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "BetragBezahltVorauskassa",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.BetragBezahltVorauskassa"
            },
            "validationRules": [],
            "id": "r_betragBezahltVorauskassa",
            "options": {
                "optionsName": "r_betragBezahltVorauskassaOptions",
                "optionsNameFQ": "r_betragBezahltVorauskassaOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "abschlagsfaktura-edit.r_betragbezahltgutschein_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Abschlagsfaktura",
                "bindTo": "BetragBezahltGutschein",
                "bindToFQ": "models.data.$m_Abschlagsfaktura.BetragBezahltGutschein"
            },
            "validationRules": [],
            "id": "r_betragBezahltGutschein",
            "options": {
                "optionsName": "r_betragBezahltGutscheinOptions",
                "optionsNameFQ": "r_betragBezahltGutscheinOptions"
            }
        });
        super.onConstructionFinished();
    }
}