import {
  autoinject
} from "aurelia-framework";
import {
  DruckenCommand
} from "../elements/drucken-command/drucken-command";
import {
  WebEventService, LocalizationService
} from "../../framework/base/export";
import {
  OnReportDruckEvent,
  OnReportDruck,
  OnReportRequestEvent,
  OnReportRequest,
  OnErstelleDruckJobEvent,
  OnRequestAnzAusdruckeEvent,
  OnRequestAnzAusdrucke
} from "../../framework-data/events";
import { IFormCommandExecuteOptions } from '../interfaces/export';
import { ValidationExService } from './validation-ex-service';

@autoinject
export class DruckenService {
  constructor(
    private _webEventService: WebEventService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService
  ) { }

  druckenCommand: DruckenCommand;

  showDruckenPopup(dmsLink: string, idVerknuepfteEntitaet: number = null) {
    this.druckenCommand.showPopup(dmsLink, idVerknuepfteEntitaet);
  }
  showDruckenContextMenu(idVerknuepfteEntitaet: number, reportList: any[], options: IFormCommandExecuteOptions) {
    this.druckenCommand.showContextMenu(idVerknuepfteEntitaet, reportList, options);
  }
  async executeDruckCommand(e: IFormCommandExecuteOptions) {
    const validationResult = await this._validationExService.executeServerValidation(
      e.modelData.TypeName,
      e.modelData.Id,
      "DRUCK"
    );

    if (!validationResult) {
      return;
    }

    const webEvent = new OnReportRequestEvent({ IdObjekt: e.modelData.Id });
    const result: OnReportRequest = await this._webEventService.execute(webEvent, true);

    if (!result || !result.ReportItemList || result.ReportItemList.length === 0) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("erp_druck.keine-ausdrucke-vorhanden"),
        "info", 
        3000);

      return;
    }

    this.handleDruckRequest(result, e);
  }
  async executeDruckJob(dmsLink: string, anzAusdrucke: number = 1) {
    const webEvent = new OnErstelleDruckJobEvent({ 
      DMSLink: dmsLink,
      AnzAusdrucke: anzAusdrucke
    });
    await this._webEventService.execute(webEvent, true);

    DevExpress.ui.notify(
      this._localizationService.translateOnce("erp_druck.sent-to-druck-job"),
      "success", 
      3000);
  }
  async getAnzAusdrucke(idEntitaet: number): Promise<number> {
    if (!idEntitaet) {
      return 1;
    }

    const webEvent = new OnRequestAnzAusdruckeEvent({ 
      IdEntitaet: idEntitaet
    });
    const result: OnRequestAnzAusdrucke = await this._webEventService.execute(webEvent, true);
    return result.AnzAusdrucke || 1;
  }

  async getReport(idObjekt: number, idReport: string) {
    const webEvent = new OnReportDruckEvent({ IdObjekt: idObjekt, IdReport: idReport });
    const report: OnReportDruck = await this._webEventService.execute(webEvent, true);

    return report;
  }

  private async handleDruckRequest(res: OnReportRequest, e: IFormCommandExecuteOptions) {
    const hasMultipleRecords = res.ReportItemList.length > 1;

    if (hasMultipleRecords) {
      this.showDruckenContextMenu(res.IdObjekt, res.ReportItemList, e);
      return
    }

    const report = await this.getReport(res.IdObjekt, res.ReportItemList[0].Id);

    this.showDruckenPopup(report.DMSLink, report.IdObjekt);
  }
}
