import { autoinject } from "aurelia-framework";
import { OnEvalEinkaufArtikeldaten, OnEvalEinkaufArtikeldatenEvent, OnEvalVerkaufArtikeldaten, OnEvalVerkaufArtikeldatenEvent } from "../../framework-data/events";
import { RestService, WebEventService } from "../../framework/base/export";

@autoinject
export class ArtikelService {
  constructor(
    private _restService: RestService,
    private _webEventService: WebEventService
  ) { }

  getArtikelById(id: number): Promise<any> {
    return this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Artikel/${id}`),
      getOptions: {
        expand: {"SetartikelAngebotsposition": null}
      }
    });
  }
  getArtikelByNummer(nummer: string, where: any = null): Promise<any> {
    const getOptions: any = {
      where: ["Nummer", nummer]
    };

    if (where) {
      getOptions.where = [getOptions.where, where];
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Artikel"),
      getOptions: getOptions
    });
  }
  getArtikelBySearchtextEindeutig(searchtext: string, where: any = null): Promise<any[]> {
    const getOptions: any = {
      searchtext: searchtext,
      columns: ["Id", "Bezeichnung"],
      take: 2
    };

    if (where) {
      getOptions.where = where;
    }

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Artikel"),
      getOptions: getOptions,
      increaseLoadingCount: true
    });
  }
  getIdHauptlieferant(idArtikel: number): Promise<number> {
    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/ArtikelLieferant"),
      getOptions: {
        where: [["IsHauptlieferant", true], ["IdArtikel", idArtikel]]
      }
    }).then((r) => {
      if (!r || r.length === 0) {
        return null;
      }

      return r[0]["IdLieferant"];
    });
  }
  getLieferantById(idArtikel: number, idLieferant: number): Promise<any> {
    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/ArtikelLieferant"),
      getOptions: {
        where: [["IdLieferant", idLieferant], ["IdArtikel", idArtikel]]
      }
    }).then((r) => {
      if (!r || r.length === 0) {
        return null;
      }

      return r[0];
    });
  }
  async getEinheitbezeichnungByArtikelEinheit(idArtikelEinheit: number): Promise<any> {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/ArtikelEinheit/".concat(idArtikelEinheit.toString())),
      getOptions: {
        expand: { "Einheit": null }
      }
    });

    if (!r) {
      return null;
    }

    return r.Einheit.Bezeichnung;
  }
  async getUmrechner(idArtikelEinheit: number): Promise<number> {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/ArtikelEinheit/".concat(idArtikelEinheit.toString())),
      getOptions: {
        columns: ["Id", "Umrechner"]
      }
    });

    if (!r || !r.Umrechner) {
      return 1;
    }

    return r.Umrechner;
  }
  getArtikelartById(id: number): Promise<any> {
    return this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Artikelart/${id}`),
    })
  }
  async hasArtikelBeistellartikel(idArtikel): Promise<boolean>{
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/ArtikelStueckliste"),
      getOptions: {
        where: [["IdArtikelFW", idArtikel],["IsBeistellartikel", true]]
      }
    });

    if (r.length == 0) {
      return false;
    }

    return true;
  }

  async onArtikeldatenChanged(e: CustomEvent, modelData: any) {
    // TODO OnEvalVerkaufArtikeldatenEvent Centralized here.
    const idArtikel = e.detail.value;
    if (idArtikel == void 0) {
      return;
    }
    const webEvent = new OnEvalVerkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdKunde: modelData.IdFirma || 0
    });

    const result: OnEvalVerkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    modelData.IdEinheit = result.IdArtikelEinheit;
  }
  async doEvalEinkaufArtikeldaten(e: CustomEvent, modelData: any, positionData: any) {
    //TODO Centralized all the doEvalEinkaufArtikeldaten here.
    const idArtikel = e.detail.value;
    if (idArtikel == void 0) {
      return;
    }

    const webEvent = new OnEvalEinkaufArtikeldatenEvent({
      IdArtikel: idArtikel,
      IdLieferant: modelData.IdLieferant
    });

    const result: OnEvalEinkaufArtikeldaten = await this._webEventService.execute(webEvent);
    if (result == void 0) {
      return;
    }

    positionData.IdEinheit = result.IdArtikelEinheit;
    positionData.LieferantArtikelNr = result.LieferantArtikelNr;
    positionData.LieferantArtikelbezeichnung = result.LieferantArtikelbezeichnung;
  }
}
