import { IFormCommandExecuteOptions } from './../interfaces/form-command-options';
import {
  autoinject,
  Disposable
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  RestService
} from "../../framework/base/export";
import {
  NotificationTyp
} from "../enumerations/export";
import {
  StatusFormCommand
} from './../elements/status-form-command/status-form-command';

@autoinject
export class StatusService {
  private _statusInfo = {};

  constructor(
    private eventAggregator: EventAggregator,
    private rest: RestService
  ) {
    this.initializeSubscribers();
  }

  onCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.IdStatus) {
      return;
    }

    const info = this._statusInfo[e.data.IdStatus];
    if (!info || !info.Farbe) {
      return;
    }

    const cell: HTMLTableDataCellElement = e.cellElement;

    cell.style.backgroundColor = info.Farbe;
    cell.style.color = "white";
  }
  onValueChanged(e): void {
    const value = e.value;
    const element: Element = e.element;

    const info = this._statusInfo[value];
    const input = <HTMLInputElement>element.querySelector(".dx-texteditor-container input");

    if (input) {
      if (info && info.Farbe) {
        input.style.backgroundColor = info.Farbe;
        input.style.color = "white";
      } else {
        input.style.backgroundColor = "";
        input.style.color = "";
      }
    }
  }

  reload(): Promise<any> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Stammdaten/Status")
    }).then((r: any[]) => {
      this._statusInfo = {};

      if (!r) {
        return;
      }

      r.forEach(i => {
        this._statusInfo[i.Id] = i;
      });
    });
  }

  getStatusByTyp(typ: number): any {
    let statusResult = null;
    for (let status in this._statusInfo) {
      if (this._statusInfo[status].Typ == typ) {
        statusResult = this._statusInfo[status];
        break;
      }
    }

    return statusResult;
  }

  isOffen(idStatus: number): boolean {
    const info = this._statusInfo[idStatus];
    if (!info || info.Typ == 1) {
      return false;
    }

    return true;
  }
  isErledigt(idStatus: number): boolean {
    const info = this._statusInfo[idStatus];
    if (!info || info.Typ != 1) {
      return false;
    }

    return true;
  }

  private initializeSubscribers() {
    this.eventAggregator
      .subscribe(
        NotificationTyp.statusChanged,
        this.reload.bind(this)
      );
  }
}
