import {
  autoinject,
  singleton
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  BindingService,
  CustomEvent,
  RestService,
  ScopeContainer,
  PermissionService
} from "../../base/export";
import {
  IModelLoadRequiredEventArgs,
  IModelLoadedEventArgs,
  IModelLoadedInterceptorEventArgs,
  IModelSavedEventArgs,
  IModelDeletedEventArgs
} from "../event-args/export";
import {
  ModelEventService,
  ModelUtilsService
} from "../services/export";
import {
  FormBase
} from "./form-base";
import {
  DataSourceService
} from "../../base/services/data-source-service";
import * as Interfaces from "../interfaces/export";
import { ObjectService } from '../../base/services/object-service';

@autoinject
@singleton(true)
export class Models {
  private form: FormBase;
  private info: any;

  constructor(
    private rest: RestService,
    private dataSource: DataSourceService,
    private binding: BindingService,
    private modelEvent: ModelEventService,
    private eventAggregator: EventAggregator,
    private modelUtils: ModelUtilsService,
    private objectService: ObjectService,
    private permissionService: PermissionService,
    public onLoadRequired: CustomEvent<IModelLoadRequiredEventArgs>,
    public onLoadedInterceptor: CustomEvent<IModelLoadedInterceptorEventArgs>,
    public onLoaded: CustomEvent<IModelLoadedEventArgs>,
    public onSaved: CustomEvent<IModelSavedEventArgs>,
    public onDeleted: CustomEvent<IModelDeletedEventArgs>
  ) {
    this.onLoadRequired.waitTimeout = 10;

    this.data = {};
    this.info = {};

    this.onLoadRequired.register((args) => {
      if (!this.form.isBound) {
        return Promise.resolve();
      }

      if (args.model.key || args.model.autoLoad) {
        const key = this.form.binding.evaluate(this.form.scope, args.model.key);
        return this.loadModel(args.model, key);
      }

      return Promise.resolve();
    });
  }

  data: any;

  modelWithKeyId: Interfaces.IModel;

  addInfo(model: Interfaces.IModel) {
    model.keyProperty = model.keyProperty || "Id";

    this.info[model.id] = model;

    this.eventAggregator.publish("model:added", {
      form: this.form,
      model: model
    });

    this.addObservers(model);

    if (this.isModelWithKeyId(model)) {
      this.modelWithKeyId = model;
    }
  }
  allowNew(scopeContainer: ScopeContainer, model: Interfaces.IModel): boolean {
    if (model.allowNew == void (0)) {
      return true;
    }

    return !!this.binding.evaluate(scopeContainer.scope, model.allowNew);
  }
  allowSave(scopeContainer: ScopeContainer, model: Interfaces.IModel): boolean {
    if (this.isModelWithKeyId(model) && model.modificationInfoEnabled) {
      const canSave = this.data[model.id]
        && this.data[model.id]["CanSave"];

      if (!canSave) {
        return false;
      }
    }

    if (model.allowSave == void (0)) {
      return true;
    }

    return !!this.binding.evaluate(scopeContainer.scope, model.allowSave);
  }
  allowDelete(scopeContainer: ScopeContainer, model: Interfaces.IModel): boolean {
    if (this.isModelWithKeyId(model) && model.modificationInfoEnabled) {
      const canDelete = this.data[model.id]
        && this.data[model.id]["CanDelete"];

      if (!canDelete) {
        return false;
      }
    }

    if (model.allowDelete == void (0)) {
      return true;
    }

    return !!this.binding.evaluate(scopeContainer.scope, model.allowDelete);
  }
  callOnLoaded(model: Interfaces.IModel, data: any) {
    this.onLoaded.fire({
      model: model,
      data: data
    });
    this.modelEvent.onLoaded.fire({
      model: model,
      data: data
    });
  }
  canNew(model: Interfaces.IModel) {
    if (!model.webApiAction) {
      return true;
    }

    return this.permissionService.canWebApiNew(model.webApiAction, this.form.moduleId);
  }
  canSave(model: Interfaces.IModel) {
    if (!model.webApiAction) {
      return true;
    }

    return this.permissionService.canWebApiModify(model.webApiAction, this.form.moduleId);
  }
  canDelete(model: Interfaces.IModel) {
    if (!model.webApiAction) {
      return true;
    }

    return this.permissionService.canWebApiDelete(model.webApiAction, this.form.moduleId);
  }
  createNewModelData(model: Interfaces.IModel): any {
    const data = {};

    data[model.keyProperty] = 0;

    return data;
  }
  getInfo(id: string, throwErrorIfMissing: boolean = true): Interfaces.IModel {
    const model = this.info[id];
    if (!model && throwErrorIfMissing) {
      throw new Error();
    }

    return model;
  }
  getModels(): Interfaces.IModel[] {
    const arr: Interfaces.IModel[] = [];

    for (let i in this.info) {
      arr.push(this.info[i]);
    }

    return arr;
  }
  getModelWithKeyId(): Interfaces.IModel {
    return this
      .getModels()
      .find(m => this.isModelWithKeyId(m));
  };
  hasChangedData(): boolean {
    for (let model of this.getModels()) {
      if (!model.postOnSave) {
        continue;
      }

      if (!this.data[model.id]) {
        continue;
      }

      if (!this.data[model.id][model.keyProperty]) {
        return true;
      }
      if (!this.modelUtils.isDirty(this.data[model.id])) {
        continue;
      }

      return true;
    }

    return false;
  }
  isModelWithKeyId(model: Interfaces.IModel): boolean {
    return model.key === "variables.data.$id";
  }
  loadModel(model: Interfaces.IModel, keyValue: any): Promise<any> {
    const getOptions = this.dataSource.createGetOptions(this.form.scopeContainer, model);

    if (keyValue == void (0)) {
      this.data[model.id] = null;

      this.callOnLoaded(model, null);
    } else {
      return this.rest.get({
        url: this.rest.getWebApiUrl(`${model.webApiAction}/${keyValue}`),
        getOptions,
        moduleId: this.form.moduleId,
        increaseLoadingCount: true
      }).then(r => {
        if (this.modelWithKeyId == model) {
          this.transferValuesFromRouteInfo(r);
        }

        this.onLoadedInterceptor.fire({
          model: model,
          data: r
        });

        this.data[model.id] = r;

        this.callOnLoaded(model, r);
      });
    }
  }
  loadModelWithKeyId(): Promise<any> {
    const model = this.modelWithKeyId;
    if (!model) {
      return Promise.resolve();
    }

    const key = this.form.variables.data.$id;
    return this.loadModel(model, key);
  }
  async reloadAll(): Promise<any> {
    const loadInfo = {};

    for (let m of this.getModels()) {
      loadInfo[m.id] = false;
    }

    const loadDependent = async (model: Interfaces.IModel) => {
      const prefix = `models.data.${model.id}`;
      const next: Interfaces.IModel[] = [];

      for (let m of this.getModels()) {
        if (!m.key || !m.key.startsWith(prefix)) {
          continue;
        }
        if (loadInfo[m.id]) {
          continue;
        }

        await this.onLoadRequired.fire({
          model: m
        }, 0);

        loadInfo[m.id] = true;
        next.push(m);
      }

      for (let n of next) {
        await loadDependent(n);
      }
    }

    if (this.modelWithKeyId) {
      await this.onLoadRequired.fire({
        model: this.modelWithKeyId
      }, 0);

      loadInfo[this.modelWithKeyId.id] = true;
      loadDependent(this.modelWithKeyId);
    }

    for (let m of this.getModels()) {
      if (loadInfo[m.id]) {
        continue;
      }

      await this.onLoadRequired.fire({
        model: m
      }, 0);

      loadInfo[m.id] = true;
    }
  }
  registerForm(form: FormBase) {
    if (this.form) {
      throw new Error("Form was already registered");
    }

    this.form = form;
  }
  setDataChanged(model: Interfaces.IModel) {
    if (!this.data[model.id]) {
      return;
    }

    this.modelUtils.setDirty(this.data[model.id]);
  }

  save(): Promise<any> {
    const promiseArr = this.getModels()
      .filter(m => m.postOnSave && this.data[m.id])
      .map(m => {

        let method = "post";

        if (!this.data[m.id][m.keyProperty]) {
          method = "put";
        }

        const promise = this.rest[method]({
          url: this.rest.getWebApiUrl(m.webApiAction),
          moduleId: this.form.moduleId,
          data: this.data[m.id],
          increaseLoadingCount: true,
          getOptions: this.dataSource.createGetOptions(this.form.scopeContainer, m)
        }).then(r => {
          this.data[m.id] = r;

          if (m.key && m.key === "variables.data.$id") {
            this.form.variables.data.$id = r[m.keyProperty];
          }

          this.onSaved.fire({
            model: m,
            data: r
          });
          this.modelEvent.onSaved.fire({
            model: m,
            data: r
          });

          this.callOnLoaded(m, r);
        });

        return promise;
      });

    return Promise
      .all(promiseArr)
      .then(() => {
        return this.form.nestedForms.getNestedForms().map(f => f.models.save());
      });
  }
  delete(): Promise<any> {
    const promiseArr = this.getModels()
      .filter(m => m.postOnSave && this.data[m.id] && this.data[m.id][m.keyProperty])
      .map(m => {
        const data = this.data[m.id];

        const promise = this.rest.delete({
          url: this.rest.getWebApiUrl(m.webApiAction),
          id: data[m.keyProperty],
          moduleId: this.form.moduleId,
          increaseLoadingCount: true
        }).then(() => {
          this.modelUtils.clearDirty(data);

          this.onDeleted.fire({
            model: m,
            data: data
          });
          this.modelEvent.onDeleted.fire({
            model: m,
            data: data
          });

          return Promise.resolve();
        });

        return promise;
      });

    return Promise.all(promiseArr)
      .then(() => {
        return this.form.nestedForms.getNestedForms().map(f => f.models.delete());
      });
  }

  dispose() {
    this.onDeleted.dispose();
    this.onLoaded.dispose();
    this.onLoadedInterceptor.dispose();
    this.onLoadRequired.dispose();
    this.onSaved.dispose();
  }

  private addObservers(model: Interfaces.IModel) {
    this.addObserversDetail(model, model.key, true);

    this.dataSource.addObservers(this.form.scopeContainer, model, () => {
      this.onLoadRequired.fire({
        model
      });
    });
  }
  private addObserversDetail(model: Interfaces.IModel, expression: string, checkKeyProperty: boolean) {
    if (expression == void (0)) {
      return;
    }

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: expression, 
      callback: (newValue, oldValue) => {
        this.onLoadRequired.fire({
          model
        });
      }
    });
  }
  private transferValuesFromRouteInfo(data: any) {
    if (!this.form.viewItemInfo) {
      return;
    }
    if (!this.form.viewItemInfo.routeInfo) {
      return;
    }
    if (data[this.modelWithKeyId.keyProperty]) {
      return;
    }

    const setValues = this.form.viewItemInfo.routeInfo.setValuesOnModelWithKeyIdLoaded;
    if (!setValues) {
      return;
    }

    for (let key in setValues) {
      this.objectService.setValue(data, key, setValues[key]);
    }
  }
}
