import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ImportListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "import-list";
        this.title = "import-list.import-list_caption";
        this.addModel({
            "id": "$m_Import",
            "webApiAction": "ERP/Import/Import",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_imBezeichnung",
                "caption": "import-list.r_imbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_imFullName",
                "caption": "import-list.r_imfullname_caption",
                "bindTo": "FullName",
                "minWidth": 100
            }, {
                "id": "r_imAnlagedat",
                "caption": "import-list.r_imanlagedat_caption",
                "bindTo": "AnlageDatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_imAenddat",
                "caption": "import-list.r_imaenddat_caption",
                "bindTo": "Aenderungsdatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_imStatus",
                "caption": "import-list.r_imstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_importGridToolbarOptions",
                "optionsNameFQ": "r_importGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Import"
            },
            "dataModel": "$m_Import",
            "editUrl": "ERP/Import/Import",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_importGrid",
            "options": {
                "optionsName": "r_importGridOptions",
                "optionsNameFQ": "r_importGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}