import {
  autoinject
} from "aurelia-framework";
import {
  CustomEditPopup
} from "../../../framework/forms/elements/custom-edit-popup/custom-edit-popup";

import {
  CustomEditPopupService
} from "../../services/export";

@autoinject
export class CustomEditPopupContainer {
  constructor(
    private customEditPopup: CustomEditPopupService
  ) { }

  artikelEditPopup: CustomEditPopup;
  aufgabeEditPopup: CustomEditPopup;
  benachrichtigungEditPopup: CustomEditPopup;
  briefEditPopup: CustomEditPopup;
  benutzerEinstellungenEditPopup: CustomEditPopup;
  geschaeftspartnerEditPopup: CustomEditPopup;
  mandantWechselnPopup: CustomEditPopup;
  notizEditPopup: CustomEditPopup;
  personEditPopup: CustomEditPopup;
  prodAuftragEditPopup: CustomEditPopup;
  projektEditPopup: CustomEditPopup;
  impressumEditPopup: CustomEditPopup;

  attached() {
    this.customEditPopup.artikelEditPopup = this.artikelEditPopup;
    this.customEditPopup.aufgabeEditPopup = this.aufgabeEditPopup;
    this.customEditPopup.benachrichtigungEditPopup = this.benachrichtigungEditPopup;
    this.customEditPopup.briefEditPopup = this.briefEditPopup;
    this.customEditPopup.benutzerEinstellungenEditPopup = this.benutzerEinstellungenEditPopup;
    this.customEditPopup.geschaeftspartnerEditPopup = this.geschaeftspartnerEditPopup;
    this.customEditPopup.personEditPopup = this.personEditPopup;
    this.customEditPopup.projektEditPopup = this.projektEditPopup;
    this.customEditPopup.notizEditPopup = this.notizEditPopup;
    this.customEditPopup.mandantWechselnPopup = this.mandantWechselnPopup;
    this.customEditPopup.prodAuftragEditPopup = this.prodAuftragEditPopup;
    this.customEditPopup.impressumEditPopup = this.impressumEditPopup;
  }
}
