import { autoinject, bindable, observable, OverrideContext } from "aurelia-framework";
import { WebEventService } from '../../../../framework/base/services/web-event-service';
import { OnInfoBVRohwareRequestEvent, OnInfoBVRohwareRequest } from '../../../../framework-data/events';
import { LocationService } from '../../../../framework/base/export';

@autoinject
export class InfoBvRohware {
  private _loadTimeout: any;

  constructor(
    private locationService: LocationService,
    private webEvent: WebEventService
  ) {}

  @bindable @observable typeName: string;
  @bindable @observable idArtikel: number;  
  @bindable @observable idFiliale: number;

  isVisible: boolean = false;
  data: OnInfoBVRohwareRequest;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.loadData();
  }

  typeNameChanged() {
    this.loadData();
  }
  idFilialeChanged() {
    this.loadData();
  }
  idArtikelChanged() {
    this.loadData();
  }

  onItemClick(list: any[], item: any) {
    if (!item.IdArtikel) {
      return;
    }

    this.locationService.goTo({
      url: "ERP/Stammdaten/Artikel/".concat(item.IdArtikel),
      clearStack: false,
      viewScrollInfo: {
        maxCount: list.length,
        index: list.findIndex(c => c == item),
        getNextKey: (index) => {
          if (list.length <= index) {
            return null;
          }

          return list[index].IdArtikel;
        }
      }
    })
  }

  private loadData() {
    this.data = null;

    if (this.idArtikel && (this.idFiliale || this.typeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel")) {
      if (this._loadTimeout) {
        clearTimeout(this._loadTimeout);
      }

      this._loadTimeout = setTimeout(async () => {
        this.data = await this.webEvent.execute(new OnInfoBVRohwareRequestEvent({
          IdArtikel: this.idArtikel,
          IdFiliale: this.idFiliale || 0
        }));
  
        this.isVisible = this.data.DetailFWList.length > 0
          || this.data.DetailRWList.length > 0
          || this.data.DetailVerwandtList.length > 0;
      }, 100);
      
    } else {
      this.isVisible = false;
    }
  }
}
