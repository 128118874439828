import { autoinject } from "aurelia-framework";
import { FileService } from '../../framework/base/services/file-service';
import { WebEventService } from '../../framework/base/services/web-event-service';
import { OnEximExport, OnEximExportEvent } from '../../framework-data/events';

@autoinject
export class EximService {
  constructor(
    private fileService: FileService,
    private webEventService: WebEventService
  ) {}

  async export(eximName: string, keys: number[]): Promise<any> {
    const result: OnEximExport = await this.webEventService.execute(new OnEximExportEvent({
      EximName: eximName,
      IdList: keys
    }), true);

    if (!result.DMSLink) {
      return;
    }

    this.fileService.download(result.DMSLink);
  }
}
