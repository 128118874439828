import {
  autoinject,
  valueConverter
} from "aurelia-framework";
import { EntitaetInfoService } from '../services/export';

@autoinject
@valueConverter("typeDisplayName")
export class TypeDisplayNameValueConverter{
  constructor(
    private entitaetInfoService: EntitaetInfoService
  ) {}

  toView(value: string) {
    if (!value) {
      return "";
    }

    return this.entitaetInfoService.getBezeichnung(value);
  }
}
