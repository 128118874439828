import { autoinject } from "aurelia-framework";

@autoinject
export class BenachrichtigungService {
  constructor() {}

  onIsPrioritaetCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.IsPrioritaet) {
      return;
    }

    const cell: HTMLTableDataCellElement = e.cellElement;

    cell.style.backgroundColor = "#C74031";
    cell.style.color = "white";
  }
}
