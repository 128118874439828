import {
  autoinject, optional
} from "aurelia-framework";
import {
  IViewScrollInfo
} from "../interfaces/export";
import { EventAggregator } from 'aurelia-event-aggregator';
import { ILocationGoToEventArgs } from '../event-args/location-go-to';

@autoinject
export class LocationService {
  constructor(
    private eventAggregator: EventAggregator
  ) {}

  goBack(): Promise<any> {
    const args = {
      promise: null
    };

    this.eventAggregator.publish("location:go-back", args);
    return args.promise || Promise.resolve();
  }
  async goTo(options?: ILocationGoToEventArgs): Promise<any> {
    const url = options.url;
    if (!url.startsWith("#")) {
      options.url = "#".concat(url);
    }

    const args = {
      promise: null,
      args: options,
      isHandled: false
    }

    this.eventAggregator.publish("location:go-to", args);
    await args.promise || Promise.resolve();

    if (!args.isHandled) {
      location.assign(url);
    }
  }
  openWindow(url: string) {
    window.open(`${location.origin}${location.pathname}#${url}`, "_blank");
  }
}
