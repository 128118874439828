import {
  autoinject,
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  IPopupInfo
} from "../interfaces/popup-info";
import { FormBase } from '../classes/form-base';

@autoinject
export class PopupInfoService {
  private _popupStack: IPopupInfo[] = [];

  constructor(
    private _eventAggregator: EventAggregator
  ) {
    this._eventAggregator.subscribe("window:beforeunload", e => {
      e.hasChangedData = e.hasChangedData || this.hasPopupWithUnchangedData();
    });
    this._eventAggregator.subscribe("popup-info:popup-open", e => {
      e.isOpen = this.isPopupOpen();
    });
    this._eventAggregator.subscribe("shortcut:execute", e => {
      if (this.isPopupOpen()) {
        const lastPopupInfo = this.getCurrentPopup();

        if (lastPopupInfo.executeCommand) {
          lastPopupInfo.executeCommand(e.idCommand);
        }
      }
    });
  }

  onShowPopup(info: IPopupInfo) {
    if (info.popup) {
      const content = info.popup.content();

      //Muss 2x ausgeführt werden ...
      //Das erste Mal wegen der Bestimmung der Höhe
      //Das zweite Mal, wenn danach die Breite des Popups von DX noch angepasst wurde
      this._eventAggregator.publish("grid:check-class", {
        element: content
      });

      const handler = (() => {
        this._eventAggregator.publish("grid:check-class", {
          element: content
        });

        info.popup.off("positioned", handler);
      }).bind(this);
      info.popup.on("positioned", handler);
    }

    this._popupStack.push(info);
  }
  onHidePopup(popup: DevExpress.ui.dxPopup) {
    const popupInfoIndex = this._popupStack.findIndex(c => c.popup == popup);

    if (popupInfoIndex < 0) {
      console.log("Popup not registered");
      throw new Error("Popup not registered");
    }

    this._popupStack.splice(popupInfoIndex, 1);
  }

  getCurrentPopup(): IPopupInfo {
    if (this._popupStack.length == 0) {
      return;
    }

    return this._popupStack[this._popupStack.length - 1];
  }
  getPopup(form: FormBase): IPopupInfo {
    return this._popupStack.find(p => p.form == form);
  }

  async closeCurrentPopup(): Promise<boolean> {
    const popupInfo = this.getCurrentPopup();
    if (!popupInfo) {
      return;
    }

    if (popupInfo.handleChangedData) {
      const canDeactivate = await popupInfo.handleChangedData();
      if (!canDeactivate) {
        return false;
      }
    }

    popupInfo.popup.hide();
    return true;
  }
  async closeAllPopups(): Promise<any> {
    while (this._popupStack.length > 0) {
      const result = await this.closeCurrentPopup();

      if (!result) {
        return false;
      }
    }

    return true;
  }

  isPopupOpen() {
    return this._popupStack.length > 0;
  }

  private hasPopupWithUnchangedData(): boolean {
    for (let item of this._popupStack) {
      if (!item.hasChangedData) {
        continue;
      }

      if (item.hasChangedData()) {
        return true;
      }
    }

    return false;
  }
}
