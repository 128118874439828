import * as fwx from "../../../../framework/forms/form-export";
import {
    LagerbestandDetailListNestedUtils
} from "./lagerbestand-detail-list-nested-utils";

@fwx.autoinject
export class LagerbestandDetailListNestedForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_lagerbestandDetailListNestedUtils: LagerbestandDetailListNestedUtils) {
        super(element, formBaseImport);
        this.id = "lagerbestand-detail-list-nested";
        this.title = "lagerbestand-detail-list-nested.lagerbestand-detail-list-nested_caption";
        this.addModel({
            "id": "$m_Lagerbestand",
            "webApiAction": "ERP/Lager/LagerbestandDetail",
            "webApiExpand": {
                'Artikel': null,
                'Lager': {
                    'expand': {
                        'Filiale': null
                    }
                }
            },
            "webApiWhere": ["Bestand", "!=", 0],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowLoad": "variables.data.$v_idArtikel || variables.data.$v_idLager",
            "custom": {
                'addFilter': true
            },
            "filters": [{
                "if": "variables.data.$v_idArtikel",
                "webApiWhere": ["Artikel.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idArtikel"
                }]
            }, {
                "if": "variables.data.$v_idLager",
                "webApiWhere": ["Lager.Id", {
                    "isBound": true,
                    "expression": "variables.data.$v_idLager"
                }]
            }]
        });
        this.addVariable({
            "id": "$v_idArtikel"
        });
        this.addVariable({
            "id": "$v_idLager"
        });
        this.addVariable({
            "id": "$v_showFilterRow"
        });
        this.addVariable({
            "id": "$v_showExcelExport"
        });
        this.addFunction("$f_lagerbestandDetailListNestedUtils", $f_lagerbestandDetailListNestedUtils, "functions.$f_lagerbestandDetailListNestedUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_lagbArtikelNummer",
                "caption": "lagerbestand-detail-list-nested.r_lagbartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_lagbBezeichnung",
                "caption": "lagerbestand-detail-list-nested.r_lagbbezeichnung_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbFiliale",
                "caption": "lagerbestand-detail-list-nested.r_lagbfiliale_caption",
                "bindTo": "Lager.Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbLager",
                "caption": "lagerbestand-detail-list-nested.r_lagblager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_lagbBestand",
                "caption": "lagerbestand-detail-list-nested.r_lagbbestand_caption",
                "bindTo": "Bestand",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_lagbMHD",
                "caption": "lagerbestand-detail-list-nested.r_lagbmhd_caption",
                "bindTo": "MHD",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_lagbCharge",
                "caption": "lagerbestand-detail-list-nested.r_lagbcharge_caption",
                "bindTo": "Charge",
                "width": "90px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lagerbestandGridToolbarOptions",
                "optionsNameFQ": "r_lagerbestandGridToolbarOptions"
            },
            "caption": "lagerbestand-detail-list-nested.r_lagerbestandgrid_caption",
            "binding": {
                "dataContext": "$m_Lagerbestand"
            },
            "dataModel": "$m_Lagerbestand",
            "height": "auto",
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "bindTo": "$f_lagerbestandDetailListNestedUtils.doExportExcelCommand",
                    "bindToFQ": "functions.$f_lagerbestandDetailListNestedUtils.doExportExcelCommand"
                }
            }],
            "id": "r_lagerbestandGrid",
            "options": {
                "optionsName": "r_lagerbestandGridOptions",
                "optionsNameFQ": "r_lagerbestandGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}