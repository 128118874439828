import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    ProvisionListUtils
} from "./provision-list-utils";

@fwx.autoinject
export class ProvisionListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_ProvisionListUtils: ProvisionListUtils) {
        super(element, formBaseImport);
        this.id = "provision-list";
        this.title = "provision-list.provision-list_caption";
        this.addModel({
            "id": "$m_Provision",
            "webApiAction": "ERP/Provision/Provision",
            "webApiExpand": {
                'Vertreter': {
                    'expand': {
                        'Adresse': {
                            'expand': {
                                'Land': null
                            }
                        }
                    }
                },
                'Status': null,
                'Firma': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addTagToDataGrids': [
                    'r_provisionGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ProvisionListUtils.provisionBerechnenCommand",
                "bindToFQ": "functions.$f_ProvisionListUtils.provisionBerechnenCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_ProvisionListUtils", $f_ProvisionListUtils, "functions.$f_ProvisionListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_provFirma",
                "caption": "provision-list.r_provfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_provFirmenbez1",
                "caption": "provision-list.r_provfirmenbez1_caption",
                "bindTo": "Vertreter.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_provBis",
                "caption": "provision-list.r_provbis_caption",
                "bindTo": "Bis",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_provWertNetto",
                "caption": "provision-list.r_provwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_provStatus",
                "caption": "provision-list.r_provstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_provisionGridToolbarOptions",
                "optionsNameFQ": "r_provisionGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Provision"
            },
            "dataModel": "$m_Provision",
            "editUrl": "ERP/Provision/Provision",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_provisionGrid",
            "options": {
                "optionsName": "r_provisionGridOptions",
                "optionsNameFQ": "r_provisionGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_provisionBerechnenBisPopup",
            "options": {
                "optionsName": "r_provisionBerechnenBisPopupOptions",
                "optionsNameFQ": "r_provisionBerechnenBisPopupOptions"
            },
            "caption": "provision-list.r_provisionberechnenbispopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_ProvisionListUtils.doProvisionBerechnenCommand",
                    "bindToFQ": "functions.$f_ProvisionListUtils.doProvisionBerechnenCommand"
                }
            }]
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "provision-list.r_fakturenbis_caption",
            "binding": {},
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_fakturenBis",
            "options": {
                "optionsName": "r_fakturenBisOptions",
                "optionsNameFQ": "r_fakturenBisOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "provision-list.r_firmaselectbox_caption",
            "binding": {},
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firmaSelectBox",
            "options": {
                "optionsName": "r_firmaSelectBoxOptions",
                "optionsNameFQ": "r_firmaSelectBoxOptions"
            }
        });
        super.onConstructionFinished();
    }
}