import { autoinject } from "aurelia-framework";
import { ElementService } from "./element-service";
import { IViewModel } from '../interfaces/view-model';

@autoinject
export class EditorService {
  constructor(
    private elementService: ElementService
  ) { }

  createOptions(viewModel: IViewModel, idDataSource: string, dataField: string, options: DevExpress.ui.EditorOptions) {
    const result = Object.assign({
    }, options, {
        readOnly: true,
        bindingOptions: {
          value: "dataSourceSelected.".concat(idDataSource).concat(".").concat(dataField)
        }
      });

    this.elementService.addOnInitialized(result, (e) => {
      viewModel.registerDataSourceSelectionChanged(idDataSource, (idDataSource, data) => {
        if (data.length != 1) {
          e.component.option("value", null);
        } else {
          const row = data[0];
          const tokens = dataField.split(".");

          let val = data[0];
          for (let item of tokens) {
            val = val[item];
            if (!val) {
              break;
            }
          }

          e.component.option("value", val);
        }
      });
    });

    return result;
  }
}