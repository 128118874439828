import { autoinject, transient } from "aurelia-framework";
import { RestService } from '../../../../framework/base/export';
import { FormBase } from "../../../../framework/forms/export";
import { ZahlungTyp } from '../../../enumerations/zahlung-typ';

@autoinject
@transient()
export class ZahlungEditUtils {
  constructor(
    private _restService: RestService
  ) { }

  form: FormBase;
  doShowWaehrungFirmaFelder: boolean = false;
  betragFirmaFeldBez = "";
  skontoFirmaFeldBez = "";

  bind(form: FormBase) {
    this.form = form;
    this.form.models.onLoaded.register(async (e) => {
      if (e.model.id == "$m_Zahlung" && e.data) {
        this.setInitialData();
      }
    });
    this.form.focusFirstEnabledEditor = () => {
      this.form["r_betrag"].instance.focus();
    }
  }

  private async setInitialData() {
    const data = this.form.models.data.$m_Zahlung;
    const idEingangsrechnung = this.form.variables.data.$v_IdEingangsrechnung;
    const idFaktura = this.form.variables.data.$v_IdFaktura;

    if (idFaktura) {
      const faktura = await this._restService.get({
        url: this._restService.getWebApiUrl("ERP/Verkauf/Faktura/".concat(idFaktura)),
        getOptions: {},
        increaseLoadingCount: true
      });

      data.IdFirma = faktura.IdFirma;
      data.IdFaktura = faktura.Id;
      data.Faktura = faktura;
      data.IdGeschaeftspartner = faktura.IdRechnungsort;
      data.IdWaehrung = faktura.IdWaehrung;
      data.Typ = ZahlungTyp.Eingang;
    } else if (idEingangsrechnung) {
      const eingangsrechnung = await this._restService.get({
        url: this._restService.getWebApiUrl("ERP/Einkauf/Eingangsrechnung/".concat(idEingangsrechnung)),
        getOptions: {},
        increaseLoadingCount: true
      });

      data.IdFirma = eingangsrechnung.IdFirma;
      data.IdEingangsrechnung = eingangsrechnung.Id;
      data.Eingangsrechnung = eingangsrechnung;
      data.IdGeschaeftspartner = eingangsrechnung.IdLieferant;
      data.IdWaehrung = eingangsrechnung.IdWaehrung;
      data.Typ = ZahlungTyp.Ausgang;
    }
    data.Firma = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma/".concat(data.IdFirma.toString())),
      getOptions: {
        expand: {
          Waehrung: {
            columns: ["Id", "ISO4217"]
          }
        }
      },
      increaseLoadingCount: true
    });

    this.betragFirmaFeldBez = "Betrag " + data.Firma.Waehrung.ISO4217;
    this.skontoFirmaFeldBez = "Skonto " + data.Firma.Waehrung.ISO4217;
  }
}
