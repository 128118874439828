import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase, ICommandData, ICommand
} from "../../../../framework/forms/export";
import { IdxPopupComponent, IdxTextBoxComponent, IdxDataGridComponent } from '../../../interfaces/export';
import { LocalizationService, WebEventService } from '../../../../framework/base/export';
import { OnNewsletterEmpfaengerImportEvent } from '../../../../framework-data/events';
import { AsyncService } from '../../../../framework/base/services/async-service';

@autoinject
@transient()
export class NewsletterEmpfaengerListUtils {

  constructor(
    private localizationService: LocalizationService,
    private webEventService: WebEventService,
    private asyncService: AsyncService
  ) {}

  form: FormBase;

  showImportCommand: ICommandData = {
    id: "showImport",
    icon: "upload",
    idCategory: "newsletter-empfaenger",
    title: "newsletter-empfaenger.excel_import",
    execute: async () => {
      this.form.models.data.$m_Import = {};

      const popup: IdxPopupComponent = this.form["r_import"];
      await this.asyncService.convertToPromise(popup.instance.show());

      const bez: IdxTextBoxComponent = this.form["r_impBezeichnung"];
      bez.instance.focus();
    }
  }
  doImportCommand: ICommandData = {
    id: "doImport",
    icon: "upload",
    idCategory: "newsletter-empfaenger",
    title: "newsletter-empfaenger.import_empfaenger",
    execute: async () => {
      const data = this.form.models.data.$m_Import;

      if (!data.Bezeichnung) {
        DevExpress.ui.notify(this.localizationService.translateOnce("newsletter-empfaenger.bezeichnung_erfassen"), "ERROR", 3000);
        return;
      }
      if (!data.DMSLink) {
        DevExpress.ui.notify(this.localizationService.translateOnce("newsletter-empfaenger.datei_erfassen"), "ERROR", 3000);
        return;
      }

      await this.webEventService.execute(new OnNewsletterEmpfaengerImportEvent({
        Bezeichnung: data.Bezeichnung,
        DMSLink: data.DMSLink
      }), true);

      const grid: IdxDataGridComponent = this.form["r_empfaenger"];
      grid.instance.refresh();

      const popup: IdxPopupComponent = this.form["r_import"];
      await this.asyncService.convertToPromise(popup.instance.hide());
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
