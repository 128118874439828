import {
  autoinject,
  transient
} from "aurelia-framework";

import {
  FormBase,
} from "../../../../framework/forms/export";

@autoinject
@transient()
export class KonditionListNestedUtils {
  constructor(
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "variables.data.$v_idArtikel",
      callback: (newValue) => {
        this.changeColumnVisible("ArtikelInfo.CboBezeichnung", !newValue);
      }
    });
    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "variables.data.$v_idGeschaeftspartner", 
      callback: (newValue) => {
        this.changeColumnVisible("GeschaeftspartnerInfo.CboBezeichnung", !newValue);
      }
    });

    if (!this.form.variables.getInfo("$v_idArtikel").isMapped) {
      this.form.variables.data.$v_idArtikel = 0;
    }
    if (!this.form.variables.getInfo("$v_idGeschaeftspartner").isMapped) {
      this.form.variables.data.$v_idGeschaeftspartner = 0;
    }
  }

  private changeColumnVisible(dataField: string, isVisible: boolean) {
    const grid = this.form["r_konditionGrid"];
    if (grid && grid.instance) {
      const grid: DevExpress.ui.dxDataGrid = this.form["r_konditionGrid"].instance;

      const columns: any[] = grid.option("columns");
      const indexOf = columns.findIndex(c => c.dataField === dataField);

      if (indexOf < 0) {
        return;
      }

      grid.option(`columns[${indexOf}].visible`, isVisible);
    } else {
      const options: DevExpress.ui.dxDataGridOptions = this.form["r_konditionGridOptions"];
      const column = (<DevExpress.ui.dxDataGridColumn[]>options.columns).find(c => c.dataField === dataField);

      if (column == null) {
        return;
      }

      column.visible = isVisible;
    }
  }
}
