import { LocationService } from '../../../base/services/location-service';
import { FormBase } from '../../../forms/export';

export default class Nav {
  constructor(
    private _element: Element,
    private _locationService: LocationService
  ) {}

  goTo(route: string) {
    let vm = null;
    let el = this._element;
    
    while (el) {
      const au = el["au"];
      if (au && au.controller && au.controller.viewModel) {
        const currentVM = au.controller.viewModel;
        if (currentVM && currentVM instanceof FormBase) {
          vm = currentVM;
          break;
        }
      }

      el = el.parentElement;
    }

    this._locationService.goTo({
      currentViewModel: vm,
      clearStack: false,
      url: route
    });
  }
}