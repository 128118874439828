
import {
  autoinject,
  transient,
  TaskQueue
} from "aurelia-framework";
import {
  ICommandData,
  FormBase,
  PopupInfoService,
} from "../../../../framework/forms/export";
import {
  StartupService,
  VerknuepfteEntitaetService
} from "../../../services/export";
import * as moment from "moment";
import {
  IdxTagboxComponent
} from "../../../interfaces/export";
@autoinject
@transient()
export class AufgabeEditUtils {
  constructor(
    private popupInfo: PopupInfoService,
    private startup: StartupService,
    private taskQueue: TaskQueue,
    private verknuepfteEntitaet: VerknuepfteEntitaetService
  ) { }

  form: FormBase;

  verknuepfteEntitaetCommand: ICommandData = {
    id: "verknuepfteEntitaet",
    idCategory: "aufgabe",
    title: "aufgabe.verknuepfte-entitaet-command-text",
    icon: "link",
    sortIndex: 99,
    isVisible: false,
    execute: this.onVerknuepfteEntitaetCommandClicked.bind(this)
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register(r => {
      if (r.model.id == "$m_Aufgabe" && r.data && !r.data.Id) {
        this.setFollowerCurrentBenutzer(r.data);
      }

      return Promise.resolve();
    });

    form.focusFirstEnabledEditor = () => {
      if (form["r_betreff"] && form["r_betreff"].instance) {
        form["r_betreff"].instance.focus();
      }
    };

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: "models.data.$m_Aufgabe.FaelligAm",
      callback: (newVal) => {
        const dateBox: DevExpress.ui.dxDateBox = this.form["r_faelligAm"];
        this.setDatumStyle(dateBox.element, newVal);
      }
    });

    this.form.binding.observe({
      scopeContainer: this.form.scopeContainer,
      expression: "models.data.$m_Aufgabe.ErinnernAm",
      callback: (newVal) => {
        const dateBox: DevExpress.ui.dxDateBox = this.form["r_erinnernAm"];
        this.setDatumStyle(dateBox.element, newVal);
      }
    });

    form.models.onLoaded.register(r => {
      this.verknuepfteEntitaetCommand.isVisible = this.form
        && this.form.models.data.$m_Aufgabe
        && this.form.models.data.$m_Aufgabe.IdVerknuepfteEntitaet != void 0;

      return Promise.resolve();
    });
  }

  async onVerknuepfteEntitaetCommandClicked(e) {
    const idVerknuepfteEntitaet = this.form.models.data.$m_Aufgabe.IdVerknuepfteEntitaet;

    if (idVerknuepfteEntitaet == void 0) {
      return;
    }

    if (this.form.isEditPopup == true) {
      this.popupInfo.closeCurrentPopup();
    }

    this.verknuepfteEntitaet
      .goTo(idVerknuepfteEntitaet);
  }

  setDatumStyle(element: any, value: Date): void {
    this.taskQueue.queueMicroTask(() => {
      const isVorbei = value && moment().isSameOrAfter(value);
      const input: HTMLInputElement = element.querySelector("input:not([type ='hidden'])");

      input.style.backgroundColor = isVorbei
        ? "#C1392D"
        : "white";
      input.style.color = isVorbei
        ? "white"
        : "inherit";
    });
  }

  private setFollowerCurrentBenutzer(data: any) {
    if (data.AufgabeInfoBenutzer == void(0)) {
      data.AufgabeInfoBenutzer = [];
    }

    data.AufgabeInfoBenutzer.push({
      IdBenutzer: this.startup.startupInfo.Benutzer.Id
    });
  }

  async onAufgabeKommentarChanged(e: any) {
    await this.form.models.reloadAll();
  }
}
