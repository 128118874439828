import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../../framework/forms/export";
import { DynFeldLogikTyp } from "../../../enumerations/dyn-feld-logik-typ";
import { DynFelderService, StartupService } from '../../../services/export';

@autoinject
@transient()
export class ArtikelStuecklisteEditUtils {
  constructor(
    public startupService: StartupService,
    private _dynFelderService: DynFelderService) { }

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Artikel") {
        return;
      }

      if (!this.startupService.startupInfo.Lizenz.HasVariablerSetartikel) {
        return;
      }

      if (this.form.models.data.$m_Artikel != null) {
        if (this.form.models.data.$m_Artikel.BezugTyp != 3) {
          return;
        }

        const dynFeldEintrag = await this._dynFelderService
          .loadDynFeldEintragByLogikttyp(this.form.models.data.$m_Artikel.IdDynamischesFeldContainer, DynFeldLogikTyp.VariablerSetartikel);

        if (dynFeldEintrag != null && dynFeldEintrag[0].WertNummer == 1) {
          this.form.models.data.$m_ArtikelStueckliste.HasVariablerSetartikelVorbelegung = true;
        }
      }

      return Promise.resolve();
    });
  }
}
