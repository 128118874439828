import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BelegImportListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "beleg-import-list";
        this.title = "beleg-import-list.beleg-import-list_caption";
        this.addModel({
            "id": "$m_BelegImport",
            "webApiAction": "ERP/Import/BelegImport",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_imDateiname",
                "caption": "beleg-import-list.r_imdateiname_caption",
                "bindTo": "Dateiname",
                "minWidth": 100
            }, {
                "id": "r_imTyp",
                "caption": "beleg-import-list.r_imtyp_caption",
                "bindTo": "Typ",
                "minWidth": 100,
                "enumTypeName": "TIP.ERP.Business.Enumerations.BelegImportTyp"
            }, {
                "id": "r_imAnlagedat",
                "caption": "beleg-import-list.r_imanlagedat_caption",
                "bindTo": "AnlageDatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_imAenddat",
                "caption": "beleg-import-list.r_imaenddat_caption",
                "bindTo": "Aenderungsdatum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_imStatus",
                "caption": "beleg-import-list.r_imstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_importGridToolbarOptions",
                "optionsNameFQ": "r_importGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BelegImport"
            },
            "dataModel": "$m_BelegImport",
            "editUrl": "ERP/Import/BelegImport",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_importGrid",
            "options": {
                "optionsName": "r_importGridOptions",
                "optionsNameFQ": "r_importGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}