import {
  computedFrom,
  Controller
} from "aurelia-framework";
import {
  IViewItemModel
} from "../interfaces/view-item-model";

export class ViewItem {
  constructor(viewModelItem: IViewItemModel) {
    this.title = viewModelItem.routeInfo.route.caption;
    this.moduleId = viewModelItem.moduleId;
    this.model = viewModelItem;
    this.isCurrent = true;
  }

  title: string;
  moduleId: any;
  model: IViewItemModel;
  isCurrent: boolean;
  controller?: Controller;
  customClassName?: string;
  isAttached: boolean;

  @computedFrom("isCurrent", "isAttached", "customClassName")
  get className(): string {
    return (this.isCurrent 
      ? "t--view-current"
      : "t--view-history") + (this.isAttached ? " t--view-attached" : "") + (this.customClassName ? ` ${this.customClassName}` : "");
  }
}
