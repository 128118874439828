import {
  autoinject, noView
} from "aurelia-framework";
import { NotificationService } from '../../../base/services/notification-service';

@autoinject
@noView
export class WebSocket {
  constructor(
    private notification: NotificationService
  ) { }

  attached() {
    this.notification.subscribe();
  }
  detached() {
    this.notification.unsuscribe();
  }
}
