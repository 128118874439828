import {
  autoinject
} from "aurelia-framework";
import {
  ArtikelSearchResult
} from "../elements/artikel-search-result/artikel-search-result";
import {
  ISearchOptions
} from "../interfaces/export";

@autoinject
export class ArtikelSearchService {
  constructor() { }

  artikelSearchResult: ArtikelSearchResult
  
  show(callbackSuccess: { (idArtikel): void }, callbackCancel: { (): void }, options?: ISearchOptions) {
    this.artikelSearchResult.showPopup(callbackSuccess, callbackCancel, options)
  }
}
