import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { NotificationTyp } from "./erp/enumerations/export";
import { IStartupInfo } from "./erp/interfaces/export";
import { DxCustomizeService } from "./erp/services/dx-customize-service";
import { FilterService, FormExtenderService, HeaderCommandService, KonditionService, SearchResultSearchService, StartupService, StatusService, TemplateRegisterService, ValidationExService, WaehrungService } from "./erp/services/export";
import { ModuleService } from "./erp/services/module-service";
import { TagService } from "./erp/services/tag-service";
import { WidgetExtenderService } from "./erp/services/widget-extender-service";
import { FileService, LocalizationService, PermissionService, RestService, ShortcutService, StyleService } from "./framework/base/export";
import { LocationService } from "./framework/base/services/location-service";
import { HeaderService, LayoutService } from "./framework/default-ui/services/export";
import { HistoryService, IRoute, LayoutService as FormLayoutService, RouterService, RoutesCreatorService } from "./framework/forms/export";

import * as routesForm from "./framework-data/forms.json";
import * as routesStructure from "./route-info/structure.json";


@autoinject
export class App {
  private _routes: IRoute[] = [];
  private _startupInfo: IStartupInfo;
  private _avatarUrlChangedSubscription: Subscription;
  private _headerSearchSubscription: Subscription;
  private _headerShowSearchPopupSubscription: Subscription;
  private _startupInfoLoadedSubscription: Subscription;
  private _mandantSwitchedSubscription: Subscription;
  private _mandantChangedSubscription: Subscription;
  private _htmlEditorMentionSubscription: Subscription;

  constructor(
    private _router: RouterService,
    private _historyService: HistoryService,
    private _routesCreator: RoutesCreatorService,
    private _header: HeaderService,
    private _headerCommand: HeaderCommandService,
    private _layout: LayoutService,
    private _startup: StartupService,
    private _file: FileService,
    private _searchResultSearch: SearchResultSearchService,
    private _shortcut: ShortcutService,
    private _localization: LocalizationService,
    private _status: StatusService,
    private _kondition: KonditionService,
    private _waehrung: WaehrungService,
    private _templateRegister: TemplateRegisterService,
    private _formExtender: FormExtenderService,
    private _filter: FilterService,
    private _style: StyleService,
    private _validationEx: ValidationExService,
    private _eventAggregator: EventAggregator,
    private _locationService: LocationService,
    private _formLayoutService: FormLayoutService,
    private _widgetExtenderService: WidgetExtenderService,
    private _permissionService: PermissionService,
    private _moduleService: ModuleService,
    private _restService: RestService,
    private _dxCustomizeService: DxCustomizeService,
    private _tagService: TagService) {
    this.configureHeader();
    this.registerShortcuts();

    _templateRegister.register();
  }

  async activate() {
    await this.loadRoutes();
    await this._startup.loadStartupInfo();
    await this.loadMandantDaten();
    await this.startupInfoLoaded();

    this._permissionService.allowAllPermission = true;
  }
  attached() {
    this.registerRoutes();
    this.registerAvatarUrlChanged();
    this.registerStartupInfoLoaded();
    this.registerHeaderSearch();
    this.registerMandantSwitched();
    this.registerMandantChanged();
    this.registerHtmlEditorMention();

    //this.memoryTest();
  }
  detached() {
    if (this._avatarUrlChangedSubscription) {
      this._avatarUrlChangedSubscription.dispose();
      this._avatarUrlChangedSubscription = null;
    }
    if (this._startupInfoLoadedSubscription) {
      this._startupInfoLoadedSubscription.dispose();
      this._startupInfoLoadedSubscription = null;
    }
    if (this._headerSearchSubscription) {
      this._headerSearchSubscription.dispose();
      this._headerSearchSubscription = null;
    }
    if (this._headerShowSearchPopupSubscription) {
      this._headerShowSearchPopupSubscription.dispose();
      this._headerShowSearchPopupSubscription = null;
    }
    if (this._mandantSwitchedSubscription) {
      this._mandantSwitchedSubscription.dispose();
      this._mandantSwitchedSubscription = null;
    }
    if (this._mandantChangedSubscription) {
      this._mandantChangedSubscription.dispose();
      this._mandantChangedSubscription = null;
    }
    if (this._htmlEditorMentionSubscription) {
      this._htmlEditorMentionSubscription.dispose();
      this._htmlEditorMentionSubscription = null;
    }
  }

  private async loadRoutes() {
    const routes = this._routesCreator.createRoutes(
      <any>routesStructure,
      routesForm,
      (r) => {
        return this._moduleService.canActivateModule(r.moduleId);
      });

    const routeAuswertung = routes.find(r => r.caption == "routes.auswertungen");
    if (routeAuswertung) {
      const auswertungList = await this._restService.get({
        url: this._restService.getWebApiUrl("Framework/Report/Definition"),
        getOptions: {
          where: ["IsVisible", true],
          columns: ["Id", "Name"]
        }
      });

      for (let auswertung of auswertungList) {
        this._localization.addLocalizationNeutral("auswertung", auswertung.Id, auswertung.Name);

        const route: IRoute = {
          caption: "auswertung.".concat(auswertung.Id),
          canActivate: () => true,
          navigation: {},
          moduleId: "erp/views/statistik/auswertung/auswertung-form",
          route: "ERP/Statistik/Auswertung/".concat(auswertung.Id)
        };
        routeAuswertung.children.push(route);
      }
    }

    this._routes = this.orderRoutes(routes);
  }
  private registerRoutes() {
    if (this._startup.startupInfo.Benutzer.IsCloudAdministrator) {
      this._router.registerRoutes(this._routes, "ERP/Ticket/Ticket");
    } else {
      this._router.registerRoutes(this._routes, "ERP/Dashboard");
    }
  }
  private registerAvatarUrlChanged() {
    this._avatarUrlChangedSubscription = this._eventAggregator
      .subscribe(
        NotificationTyp.benutzerAvatarUrlChanged,
        (data) => {
          this._header.avatarUrl = this._file.getDownloadUrl(data);
        });
  }
  private registerStartupInfoLoaded() {
    this._startupInfoLoadedSubscription = this._eventAggregator.subscribe(NotificationTyp.startupInfoLoaded, (r) => {
      this.startupInfoLoaded(r.autoLoadMandantDaten);
    });
  }
  private registerHeaderSearch() {
    this._header.isSearchRegistered = true;

    this._headerSearchSubscription = this._eventAggregator.subscribe("header:search", e => {
      this._searchResultSearch.show(
        (urlToNavigate: string, customOptions?: any) => {
          this._locationService.goTo({
            url: urlToNavigate,
            clearStack: true,
            customOptions: customOptions
          });
        },
        () => { },
        { searchValue: e.text }
      );
    });
    this._headerShowSearchPopupSubscription = this._eventAggregator.subscribe("header:show-search-popup", () => {
      this._searchResultSearch.show(
        (urlToNavigate: string, customOptions?: any) => {
          this._locationService.goTo({
            url: urlToNavigate,
            clearStack: true,
            customOptions: customOptions
          });
        },
        () => { });
    });
  }
  private registerMandantSwitched() {
    this._mandantSwitchedSubscription = this._eventAggregator.subscribe(NotificationTyp.mandantSwitched, async () => {
      await this._startup.loadStartupInfo(true);

      this._eventAggregator.publish(NotificationTyp.aufgabeAnzahlChanged);
      this._eventAggregator.publish(NotificationTyp.benachrichtigungAnzahlChanged);
      this._eventAggregator.publish(NotificationTyp.briefAnzahlChanged);
      this._eventAggregator.publish(NotificationTyp.mailAnzahlChanged);

      this._historyService.clearAndGoToFallback();
    });
  }
  private registerMandantChanged() {
    this._mandantChangedSubscription = this._eventAggregator.subscribe(NotificationTyp.mandantChanged, async (e) => {
      if (this._startup.startupInfo.Mandant.Id != e.IdMandant) {
        return;
      }

      await this._startup.loadStartupInfo(true);
    });
  }
  private registerHtmlEditorMention() {
    this._htmlEditorMentionSubscription = this._eventAggregator.subscribe("html-editor:mention", (e) => {
      if (e.mentionKey == "benutzer") {
        e.mention = <DevExpress.ui.dxHtmlEditorMention>{
          dataSource: new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
            cacheRawData: true,
            loadMode: "raw",
            load: async () => {
              return this._restService.get({
                url: this._restService.getWebApiUrl("ERP/Stammdaten/Benutzer"),
                getOptions: {
                  columns: ["Id", "Name"],
                  orderBy: [{ columnName: "Name", sortOrder: 0 }],
                  customs: [{
                    key: "HtmlMention",
                    value: e.mentionKey
                  }]
                }
              });
            }
          })),
          displayExpr: "Name",
          searchExpr: "Name",
          valueExpr: "Id"
        };
      }
    });
  }
  private async loadMandantDaten(): Promise<any> {
    await this._status.reload();
    await this._waehrung.reload();
    await this._kondition.reload();
    await this._filter.reload();
  }
  private configureHeader() {
    this._header.text = "TIP Technik und Informatik Partner GmbH & Co KG";
  }
  private registerShortcuts() {
    this._shortcut.bindShortcut("n g p", () => {
      this._locationService.goTo({
        url: "ERP/Stammdaten/Geschaeftspartner/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n p e", () => {
      this._locationService.goTo({
        url: "ERP/Stammdaten/Person/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n p r", () => {
      this._locationService.goTo({
        url: "ERP/Stammdaten/Projekt/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n a n", () => {
      this._locationService.goTo({
        url: "ERP/Verkauf/Angebot/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n a u", () => {
      this._locationService.goTo({
        url: "ERP/Verkauf/Auftrag/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n r e", () => {
      this._locationService.goTo({
        url: "ERP/Verkauf/Faktura/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n b s", () => {
      this._locationService.goTo({
        url: "ERP/Einkauf/Bestellung/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n w u", () => {
      this._locationService.goTo({
        url: "ERP/Einkauf/Warenuebernahme/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n e r", () => {
      this._locationService.goTo({
        url: "ERP/Einkauf/Eingangsrechnung/0",
        clearStack: true
      });
    }, false);

    this._shortcut.bindShortcut("n b r", "$addBrief", false);

    this._shortcut.bindShortcut("n a g", "$addAufgabe", false);

    this._shortcut.bindShortcut("n n o", "$addNotiz", false);
  }
  private orderRoutes(routes: IRoute[]): IRoute[] {
    const dashboard = routes.find(c => c.route == "ERP/Dashboard");
    if (!dashboard) {
      return routes;
    }

    const dashboardIndex = routes.indexOf(dashboard);
    routes.splice(dashboardIndex, 1);
    routes.splice(0, 0, dashboard);

    return routes;
  }
  private async startupInfoLoaded(autoLoadMandantDaten?: boolean) {
    const startupInfo = this._startup.startupInfo;
    this._startupInfo = startupInfo;

    this._moduleService.resetBerechtModules();

    if (startupInfo.Mandant.Bezeichnung) {
      this._header.text = startupInfo.Mandant.Bezeichnung;
      startupInfo.Benutzer.Benutzername
    }
    if (startupInfo.Benutzer.AvatarDownloadKey) {
      this._header.avatarUrl = this._file.getDownloadUrl(startupInfo.Benutzer.AvatarDownloadKey);
    }
    
    if (startupInfo.Mandant.Logo) {
      this._header.setLogoUrl(this._file.getDownloadUrl(startupInfo.Mandant.Logo));
    } else {
      this._header.setLogoUrl(null);
    }

    this._layout.themeColor = startupInfo.Mandant.Farbe || "#396394";
    this._style.addStyles("erp--filter", [
      {
        name: ".filter-container .filter-element-bez.filter-element-aktiv",
        properties: [{
          propertyName: "background-color",
          value: this._layout.themeColor
        }]
      }
    ]);

    this._headerCommand.initialize();
    this._layout.activateTheme();

    await this._formLayoutService.initializeLayouts();

    if (autoLoadMandantDaten) {
      await this.loadMandantDaten();
    }
  }

  private memoryTest() {
    let isEdit = false;
    let count = 0;
    const switchForm = () => {
      count++;
      if (window["s"]) {
        return;
      }

      console.log(count);

      if (isEdit) {
        this._locationService.goTo({
          url: "ERP/Stammdaten/Artikel",
          clearStack: true
        });
      } else {
        this._locationService.goTo({
          url: "ERP/Stammdaten/Artikel/1789",
          clearStack: true
        });
      }

      isEdit = !isEdit;
      setTimeout(switchForm.bind(this), 500);
    }

    setTimeout(switchForm.bind(this), 1500);
  }
}
