import { autoinject } from "../../../framework/forms/form-export";
import { Scope, OverrideContext } from '../../../../node_modules/aurelia-framework';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { IdxPopoverComponent } from '../../interfaces/dx-components';
import { ListView, IListViewOptions } from '../../../framework/forms/elements/list-view/export';
import { DataSourceService, LocationService } from '../../../framework/base/export';
import { HeaderCommandService, EntitaetInfoService } from '../../services/export';
import { AsyncService } from '../../../framework/base/services/async-service';

@autoinject
export class ZugriffHistorieCommand {
  constructor(
    private headerCommandService: HeaderCommandService,
    private dataSourceService: DataSourceService,
    private entitaetInfoService: EntitaetInfoService,
    private locationService: LocationService,
    private asyncService: AsyncService
  ) {}  

  scope: Scope;
  scopeContainer: ScopeContainer;

  zugriffHistorie: IdxPopoverComponent;
  zugriffHistorieOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400
  };

  zugriffHistorieListView: ListView;
  zugriffHistorieListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    hoverStateEnabled: true,
    onItemClick: (e) => {
      const item = e.item;
      this.openEntitaet(item);
    }
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.zugriffHistorieListViewOptions.dataSource = this.dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/ZugriffHistorie",
        webApiOrderBy: [{columnName: "Id", sortOrder: 1}]
      },
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    this.headerCommandService.zugriffHistorie = this;
  }
  detached() {
  }

  async showPopover(targetElement: Element) {
    if (this.zugriffHistorieListView) {
      this.zugriffHistorieListView.refresh(false);
    }

    await this.asyncService.convertToPromise(this.zugriffHistorie.instance.show(targetElement));
    this.zugriffHistorieListView.scrollToTop();
  }

  private async openEntitaet(item: any) {
    this.zugriffHistorie.instance.hide();
    
    const url = await this.entitaetInfoService.getUrl(item.TypeName, item.IdEntitaet);
    if (!url) {
      return;
    }
    
    this.locationService.goTo({
      url: url,
      clearStack: true
    });
  }
}
