import { autoinject, createOverrideContext, Scope, TaskQueue } from "aurelia-framework";
import { OnStatistikEinkaufDetailRequestEvent, OnStatistikEinkaufDetailRequest } from '../../../framework-data/events';
import { GlobalizationService, LocalizationService, LocationService, ScopeContainer, WebEventService } from '../../../framework/base/export';
import { PopupInfoService, SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { IdxDataGridComponent, IdxPopupComponent } from '../../interfaces/dx-components';
import { StatistikService } from '../../services/statistik-service';
import { ICommandData } from './../../../framework/forms/interfaces/command-data';
import { IUmsatzWhereItem } from '../statistik-verkauf-detail/statistik-verkauf-detail';
import { ExcelExportService } from "../../../framework/forms/services/excel-export-service";

@autoinject
export class StatistikEinkaufDetail {
  constructor(
    private _statistikVerkaufService: StatistikService,
    private _webEventService: WebEventService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _globalizationService: GlobalizationService,
    private _taskQueue: TaskQueue,
    private _locationService: LocationService,
    private _popupInfoService: PopupInfoService,
    private _localizationService: LocalizationService,
    private _excelExportService: ExcelExportService
  ) {
    this._statistikVerkaufService.einkaufElement = this;
  }

  where: IUmsatzWhereItem;
  doGruppieren = true;
  data: any[];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate"
  };

  doGruppierenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Daten auf Warenübernahme gruppieren",
    bindingOptions: {
      value: "doGruppieren"
    },
    onValueChangedByUser: () => {
      this._taskQueue.queueMicroTask(() => {
        this.loadDataAndSetDataSource();
      });
    }
  }

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    height: "100%",
    hoverStateEnabled: true,
    allowColumnResizing: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columnResizingMode: "widget",
    onCellClick: (e) => {
      if (!e.data) {
        return;
      }

      if (!e.column || !e.column.dataField) {
        return;
      }

      const canShow = (field: string) => {
        return e.column.dataField.indexOf(field) >= 0
          && !!e.data["Id".concat(field)];
      };

      if (canShow("Eingangsrechnung")) {
        this.open("Eingangsrechnung", e.data);
      } else if (canShow("Warenuebernahme")) {
        this.open("Warenuebernahme", e.data);
      } else if (canShow("Bestellung")) {
        this.open("Bestellung", e.data);
      }else if (canShow("Auftrag")) {
        this.open("Auftrag", e.data);
      }
    }
  };

  doExportGridCommand: ICommandData[] = [{
    id: "doExportGrid",
    title: "statistik_einkauf_detail.export-grid",
    icon: "file-excel-o",
    sortIndex: 101,
    execute: () => {
      this._excelExportService.exportDataGrid({
        component: this.grid.instance,
        selectedRowsOnly: false
      });
    }
  }];

  scope: Scope;
  scopeContainer: ScopeContainer;

  get titel() {
    return this._localizationService.translateOnce("statistik_verkauf_detail.statistik");
  }

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: "statistik_einkauf_detail.detail",
      options: this.popupOptions,
      commands: this.doExportGridCommand,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  async show(where: IUmsatzWhereItem) {
    this.doGruppieren = true;
    this.where = where;

    await this.loadDataAndSetDataSource();

    this.popup.instance.show();
  }

  private async loadDataAndSetDataSource() {
    const r: OnStatistikEinkaufDetailRequest = await this._webEventService.execute(new OnStatistikEinkaufDetailRequestEvent({
      DoGruppieren: this.doGruppieren,
      Where: this.where
    }), true);

    this.data = <any>r.Data;

    const options = {
      dataSource: this.data,
      columns: this.getColumns(),
      summary: this.getSummaryStatistik(),
      paging: {
        pageIndex: 0
      }
    };

    if (this.grid && this.grid.instance) {
      this.grid.setOption(options);
    } else {
      Object.assign(this.gridOptions, options);
    }
  }

  private getColumns() {
    const result: DevExpress.ui.dxDataGridColumn[] = []

    result.push({
      dataField: "LieferantNr",
      caption: "Lieferant-Nr.",
      width: "80px"
    }, {
      dataField: "LieferantFirmenbez1",
      caption: "Lieferant Firmenbez. 1",
      minWidth: 100
    }, {
      dataField: "ArtikelNr",
      caption: "Artikel-Nr.",
      width: "80px"
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    }, {
      dataField: "MengeWarenuebernahmeLagEH",
      caption: "Mge. WÜ LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "MengeEingangsrechnungLagEH",
      caption: "Mge. ER LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Einheit",
      caption: "Einheit",
      width: "120px"
    }, {
      dataField: "WertNettoBereinigt",
      caption: "Nettoumsatz bereinigt",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    });

    if (this.doGruppieren) {
      result.push({
        dataField: "AnzahlGruppiert",
        caption: "Anz. gruppiert",
        format: this._globalizationService.getFormatterParser("n0"),
        width: "60px"
      });
    }

    result.push({
      dataField: "BestellungNr",
      caption: "Bestellung-Nr.",
      width: "80px"
    }, {
      dataField: "BestellungDatum",
      caption: "Bestellung-Datum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "WarenuebernahmeNr",
      caption: "Warenübernahme-Nr.",
      width: "80px"
    }, {
      dataField: "WarenuebernahmeDatum",
      caption: "Warenuebernahme-Datum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "EingangsrechnungNr",
      caption: "Eingangsrechnung-Nr.",
      width: "80px"
    }, {
      dataField: "EingangsrechnungDatum",
      caption: "Eingangsrechnung-Datum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "AuftragNr",
      caption: "Auftrag-Nr.",
      width: "80px"
    }, {
      dataField: "AuftragDatum",
      caption: "Auftrag-Datum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Filiale",
      caption: "Filiale",
      minWidth: 100
    }, {
      dataField: "Projekt",
      caption: "Projekt",
      minWidth: 100
    });

    return result;
  }
  private getSummaryStatistik() {
    const result = [{
      column: "WertNettoBereinigt",
      summaryType: "sum",
      displayFormat: "{0}",
      valueFormat: this._globalizationService.getFormatterParser("n2")
    }];

    return {
      totalItems: result
    };
  }

  private open(property: string, data: any) {
    const keyProperty = "Id".concat(property);

    const scrollList = this.data
      .map(c => c[keyProperty])
      .filter((c, i, a) => c && a.indexOf(c) == i);

    const current = scrollList.indexOf(data[keyProperty]);
    this._popupInfoService.closeAllPopups();

    const modul = property == "Auftrag"
      ? "Verkauf"
      : "Einkauf";

    this._locationService.goTo({
      url: `ERP/${modul}/${property}/${data[keyProperty]}`,
      viewScrollInfo: {
        index: current,
        maxCount: scrollList.length,
        getNextKey: (index) => scrollList[index]
      }
    });
  }
}