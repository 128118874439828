import { autoinject, transient } from "aurelia-framework";
import { OnDeleteIdEingangsrechnungspositionListEvent, OnEingangsrechnungAbschliessenEvent, OnEingangsrechnungBezahltAendernEvent, OnEvalSteuertyp, OnEvalSteuertypEvent, OnImportProdFertigwareInEingangsrechnung, OnImportProdFertigwareInEingangsrechnungEvent, OnImportWarenuebernahmepositionenInEingangsrechnung, OnImportWarenuebernahmepositionenInEingangsrechnungEvent, OnKopierenRequest, OnKopierenRequestEvent, OnWarenuebernahmeNichtVerrechnenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { ContextMenu, FormBase, ICommandData } from "../../../../framework/forms/export";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { DynFelderDataService, DynFelderService, FirmaService, GeschaeftspartnerService, ValidationExService } from "../../../services/export";
import { BelegExportService } from "./../../../services/beleg-export-service";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class EingangsrechnungEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;
  private _selectedWarenuebernahmepositionen = false;

  constructor(
    public dynFelderData: DynFelderDataService,
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _dynFelderService: DynFelderService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;
  //für Binding ZahlungEditForm benötigt
  id0: number = 0;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_EingangsrechnungEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_EingangsrechnungEditUtils.isBelegExportMandantAktiv && models.data.$m_Eingangsrechnung.Id > 0 && !models.data.$m_Eingangsrechnung.IsIntercompany",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Eingangsrechnung.Id;
      this._belegExportService.export(BelegImportTyp.Eingangsrechnung, [id]);
    }
  };
  showImportWarenuebernahmepositionenPopupCommand: ICommandData = {
    id: "showImportWarenubernahmepositionenPopup",
    icon: "plus",
    idCategory: "eingangsrechnung",
    title: "eingangsrechnung.warenuebernahmepos_uebernehmen",
    sortIndex: 10,
    isEnabledExpression: "models.data.$m_Eingangsrechnung.CanSave && !models.data.$m_Eingangsrechnung.IsIntercompany",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      this._selectedWarenuebernahmepositionen = this.form.models.data.$m_Eingangsrechnung.LieferantLieferscheinNr
        && this.form.models.data.$m_WarenuebernahmepositionFilter.FilterLS
        ? true
        : false;

      const warenuebernahmepositionGrid: IdxDataGridComponent = this.form["r_warenuebernahmepositionGrid"];
      if (warenuebernahmepositionGrid && warenuebernahmepositionGrid.instance) {
        await this._asyncService.convertToPromise(warenuebernahmepositionGrid.instance.deselectAll());
        warenuebernahmepositionGrid.instance.refresh();
      }

      this.form["r_warenuebernahmepositionEditPopup"].instance.show();
    }
  };
  importWarenubernahmepositionenCommand: ICommandData = {
    id: "importWarenubernahmepositionen",
    title: "eingangsrechnung.mark_pos_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_warenuebernahmepositionGrid"];

      const webEvent = new OnImportWarenuebernahmepositionenInEingangsrechnungEvent({
        IdEingangsrechnung: this.form.models.data.$m_Eingangsrechnung.Id,
        IdWarenuebernahmepositionList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportWarenuebernahmepositionenInEingangsrechnung = await this._webEventService.execute(webEvent, true);
      this.form["r_warenuebernahmepositionEditPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  };
  erledigenWarenubernahmepositionenCommand: ICommandData = {
    id: "erledigenWarenubernahmepositionen",
    title: "eingangsrechnung.mark_pos_erledigen",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_warenuebernahmepositionGrid"];

      const webEvent = new OnWarenuebernahmeNichtVerrechnenEvent({
        IdWarenuebernahmepositionList: grid.instance.getSelectedRowKeys()
      });

      await this._webEventService.execute(webEvent, true);
      grid.instance.refresh();
    }
  };
  doBezahltAendernCommand: ICommandData = {
    id: "doBezahltAendern",
    idCategory: "eingangsrechnung",
    icon: "flag",
    title: "eingangsrechnung.bezahlt_aendern",
    sortIndex: 1000,
    isVisibleExpression: "models.data.$m_Eingangsrechnung.Status.Typ === 1",
    execute: async (e) => {
      const changeStatus = async (typ) => {
        const webEvent = new OnEingangsrechnungBezahltAendernEvent({
          IdEingangsrechnung: this.form.models.data.$m_Eingangsrechnung.Id,
          Typ: typ
        });

        await this._webEventService.execute(webEvent, true);
        this.form.models.reloadAll();
      };

      const contextMenu = new ContextMenu();
      contextMenu.items.push({
        text: this._localizationService.translateOnce("eingangsrechnung.bezahlt_offen"),
        execute: () => {
          changeStatus(0);
        }
      });
      contextMenu.items.push({
        text: this._localizationService.translateOnce("eingangsrechnung.bezahlt_bezahlt"),
        execute: () => {
          changeStatus(1);
        }
      });

      if (this.form.models.data.$m_Eingangsrechnung.BezahltTyp == 0 && this.form.models.data.$m_Eingangsrechnung.Status && this.form.models.data.$m_Eingangsrechnung.Status.Typ == 1) {
        contextMenu.items.push({
          beginGroup: true,
          text: this._localizationService.translateOnce("eingangsrechnung.zahlungsausgang_erfassen"),
          execute: async () => {
            const saveResult = await this.form.saveIfDirty();
            if (!saveResult.isValid) {
              return;
            }

            this.form.editPopups.show("zahlungsausgangEditPopup", null);
          }
        });
      }

      contextMenu.show(e.event && e.event.target ? e.event.target : null);
    }
  };

  showImportProduktionPopupCommand: ICommandData = {
    id: "showImportProduktionPopupCommand",
    icon: "plus",
    idCategory: "eingangsrechnung",
    title: "eingangsrechnung.produktion_uebernehmen",
    sortIndex: 20,
    isEnabledExpression: "models.data.$m_Eingangsrechnung.CanSave && !models.data.$m_Eingangsrechnung.IsIntercompany",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const prodFertigwareGrid: IdxDataGridComponent = this.form["r_prodFertigwareGrid"];
      if (prodFertigwareGrid && prodFertigwareGrid.instance) {
        await this._asyncService.convertToPromise(prodFertigwareGrid.instance.deselectAll());
        prodFertigwareGrid.instance.refresh();
      }

      this.form["r_prodFertigwareEditPopup"].instance.show();
    }
  };
  importProduktionCommand: ICommandData = {
    id: "importProduktionCommand",
    title: "eingangsrechnung.mark_pos_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_prodFertigwareGrid"];

      const webEvent = new OnImportProdFertigwareInEingangsrechnungEvent({
        IdEingangsrechnung: this.form.models.data.$m_Eingangsrechnung.Id,
        IdProdFertigwareList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportProdFertigwareInEingangsrechnung = await this._webEventService.execute(webEvent, true);
      this.form["r_prodFertigwareEditPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  };

  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "eingangsrechnung",
    title: "eingangsrechnung.abschliessen",
    icon: "check",
    sortIndex: 999,
    isEnabledExpression: "models.data.$m_Eingangsrechnung.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Eingangsrechnung.TypeName,
        this.form.models.data.$m_Eingangsrechnung.Id,
        "ABSCHLIESSEN"
      );

      if (!validationResult) {
        return;
      }

      await this._webEventService.execute(new OnEingangsrechnungAbschliessenEvent({
        IdEingangsrechnung: this.form.models.data["$m_Eingangsrechnung"].Id
      }), true);

      return this.form.models.reloadAll();
    }
  };

  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Eingangsrechnung.CanSave && !models.data.$m_Eingangsrechnung.IsIntercompany",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_eingangsrechnungspositionGrid"];
      const eingangsrechnungPosGridRowData = grid.instance.getSelectedRowsData();

      if (eingangsrechnungPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("eingangsrechnung.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const eingangsrechnungsposition of eingangsrechnungPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          eingangsrechnungsposition.TypeName,
          eingangsrechnungsposition.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const eingangsrechnungsposition of eingangsrechnungPosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: eingangsrechnungsposition.Id,
          CustomData: null
        }),
          true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Eingangsrechnung.CanDelete && !models.data.$m_Eingangsrechnung.IsIntercompany",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_eingangsrechnungspositionGrid"];
      const eingangsrechnungPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (eingangsrechnungPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("eingangsrechnung.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (eingangsrechnungPosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("eingangsrechnung.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const eingangsrechnungsposition of eingangsrechnungPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          eingangsrechnungsposition.TypeName,
          eingangsrechnungsposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = eingangsrechnungPosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdEingangsrechnungspositionListEvent({
        IdEingangsrechnungspositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.models.data.$m_WarenuebernahmepositionFilter = {
      FilterLS: true
    };

    form.focusFirstEnabledEditor = () => {
      const lieferant = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_lieferant'] .dx-searchbox input");
      if (!lieferant) {
        return;
      }

      lieferant.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Eingangsrechnung",
      idVorgEntitaetPropertyName: "IdLieferant"
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "zahlungsausgangEditPopup") {
        this.form.models.reloadAll();
      }

      return Promise.resolve();
    });

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Eingangsrechnung") {
        return;
      }

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Eingangsrechnung);
      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Eingangsrechnung") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Eingangsrechnung"];
          if (model) {
            model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(args.value);
          }
        }
      }
    });

    this.registerWarenuebernahmeSelectAll();
  }

  async onLieferantChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();
    await this.setSteuertyp();

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }
  onDifferenzValueChanged(e): void {
    const input = <HTMLInputElement>e.element.querySelector("input:not([type ='hidden'])");
    const farbe = e.value != void (0)
      ? Math.abs(e.value) > 0.04
        ? "#C1392D"
        : "#2DCC70"
      : undefined;

    if (farbe) {
      input.style.backgroundColor = farbe;
      input.style.color = "white";
    }
  }

  private async setSteuertyp() {
    const data = this.form.models.data.$m_Eingangsrechnung;
    if (!data.IdFirma || !data.IdLieferant) {
      return;
    }

    const webEvent = new OnEvalSteuertypEvent({
      IdFirma: data.IdFirma,
      IdGeschaeftspartner: data.IdLieferant
    });

    const webEventResult: OnEvalSteuertyp = await this._webEventService.execute(webEvent, true);
    if (!webEventResult.SteuerTyp) {
      return;
    }

    data.SteuerTyp = webEventResult.SteuerTyp;
  }
  private registerWarenuebernahmeSelectAll() {
    this.form.callOnBind(() => {
      const gridOptions: DevExpress.ui.dxDataGridOptions = this.form["r_warenuebernahmepositionGridOptions"];
      const onContentReady = gridOptions.onContentReady;

      gridOptions.onContentReady = (e) => {
        if (onContentReady) {
          onContentReady(e);
        }

        if (!this._selectedWarenuebernahmepositionen) {
          return;
        }

        this._selectedWarenuebernahmepositionen = false;
        (<DevExpress.ui.dxDataGrid>e.component).selectAll();
      };
    });
  }
  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Eingangsrechnung.CanSave && this.form.models.data.$m_Eingangsrechnung.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Eingangsrechnung.IdLieferant);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Eingangsrechnung.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Eingangsrechnung",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
