import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, transient } from "aurelia-framework";
import { OnAngebotAddLieferscheinpositionEvent, OnAngebotGewichtAktualisieren, OnAngebotGewichtAktualisierenEvent, OnAngebotKondAktualisieren, OnAngebotKondAktualisierenEvent, OnAngebotPositionenSortierenEvent, OnAngebotspositionenInNeuesAngebotUebernehmen, OnAngebotspositionenInNeuesAngebotUebernehmenEvent, OnDeleteIdAngebotspositionListEvent, OnErstelleAuftragAusAngebot, OnErstelleAuftragAusAngebotEvent, OnEvalLieferRechnungsort, OnEvalLieferRechnungsortEvent, OnKopierenRequest, OnKopierenRequestEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { LocationService } from "../../../../framework/base/services/location-service";
import { ContextMenu, FormBase, ICommandData, PopupInfoService } from "../../../../framework/forms/export";
import { Geschaeftspartner } from "../../../elements/geschaeftspartner/geschaeftspartner";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent, IdxPopoverComponent } from "../../../interfaces/export";
import { AngebotService } from "../../../services/angebot-service";
import { DynFelderDataService, DynFelderService, FilialeService, FirmaService, GeschaeftspartnerSearchService, GeschaeftspartnerService, KalkulationRefreshService, LagerService, ValidationExService } from "../../../services/export";
import { PersonService } from "../../../services/person-service";
import { BelegExportService } from "./../../../services/beleg-export-service";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class AngebotEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;
  private _infobarZuletztArtikelAddSubscription: Subscription;

  constructor(
    public dynFelderData: DynFelderDataService,
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _webEventService: WebEventService,
    private _dynFelderService: DynFelderService,
    private _filialeService: FilialeService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _lagerService: LagerService,
    private _geschaeftspartnerSearchService: GeschaeftspartnerSearchService,
    private _angebotService: AngebotService,
    private _eventAggregator: EventAggregator,
    private _popupInfoService: PopupInfoService,
    private _personService: PersonService,
    private _locationService: LocationService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService,
    private _belegExportService: BelegExportService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;
  aufgabe: IdxPopoverComponent;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "beleg-export",
    sortIndex: 999,
    isVisibleExpression: "functions.$f_AngebotEditUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_AngebotEditUtils.isBelegExportMandantAktiv && models.data.$m_Angebot.Id > 0",
    title: "erp.beleg-export",
    execute: async () => {
      const id = this.form.models.data.$m_Angebot.Id;
      this._belegExportService.export(BelegImportTyp.Angebot, [id]);
    }
  };
  auftragErstellenCommand: ICommandData = {
    id: "auftragErstellen",
    idCategory: "angebot",
    icon: "plus-circle",
    title: "angebot.auftrag_erstellen",
    sortIndex: 999,
    isEnabledExpression: "models.data.$m_Angebot.CanSave && models.data.$m_Angebot.Id > 0",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Angebot.TypeName,
        this.form.models.data.$m_Angebot.Id,
        "AUFTRAG_ERSTELLEN"
      );

      if (!validationResult) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnErstelleAuftragAusAngebotEvent({
        IdAngebot: this.form.models.data.$m_Angebot.Id,
        UebernehmeAllePositionen: selectedKeys.length == 0,
        IdAngebotPosList: selectedKeys
      });

      const webEventResult: OnErstelleAuftragAusAngebot = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdAuftrag) {
        return;
      }

      const navigationUrl = `ERP/Verkauf/Auftrag/${webEventResult.IdAuftrag}`;

      if (ctrlKeyPressed) {
        this._locationService.openWindow(navigationUrl);
        this.form.models.reloadAll();
      } else {
        this._locationService.goTo({
          url: navigationUrl,
          clearStack: false
        });
      }
    }
  };
  refreshGewichtCommand: ICommandData = {
    id: "refreshGewichte",
    idCategory: "gewicht",
    title: "angebot.gewicht_aktualisieren",
    icon: "cubes",
    isEnabledExpression: "models.data.$m_Angebot.CanSave && models.data.$m_Angebot.Id > 0",
    sortIndex: 10,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const onAngebotGewichtAktualisierenEvent = new OnAngebotGewichtAktualisierenEvent({
        IdAngebot: this.form.models.data["$m_Angebot"].Id
      });

      const webEventResult: OnAngebotGewichtAktualisieren = await this._webEventService.execute(
        onAngebotGewichtAktualisierenEvent,
        true);

      return this.form.models.reloadAll();
    }
  };
  neueKonditionenLaden: ICommandData = {
    id: "neueKonditionenLaden",
    idCategory: "konditionen",
    icon: "refresh",
    title: "angebot.konditionen_laden",
    sortIndex: 1,
    isEnabledExpression: "models.data.$m_Angebot.CanSave && models.data.$m_Angebot.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const args: OnAngebotKondAktualisieren = {
        IdAngebot: this.form.models.data.$m_Angebot.Id,
        AllePositionen: selectedKeys.length === 0,
        IdAngebotPosList: selectedKeys
      };

      await this._webEventService.execute(new OnAngebotKondAktualisierenEvent(args), true);
      return this.form.models.reloadAll();
    }
  };
  positionenSortierenCommand: ICommandData = {
    id: "positionenSortieren",
    idCategory: "positionen",
    icon: "sort-alpha-asc",
    title: "erp.positionen-sortieren",
    sortIndex: 10,
    isEnabledExpression: "models.data.$m_Angebot.CanSave && models.data.$m_Angebot.Id > 0",
    execute: async (e) => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const sort = async (typ) => {
        const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
        await this._webEventService.execute(new OnAngebotPositionenSortierenEvent({
          IdAngebot: this.form.models.data.$m_Angebot.Id,
          Typ: typ
        }), true);

        grid.instance.refresh();
      };

      const contextMenu = new ContextMenu();
      contextMenu.items.push({
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelnr"),
        execute: () => {
          sort(0);
        }
      }, {
        text: this._localizationService.translateOnce("erp.sortieren-nach-artikelbezeichnung"),
        execute: () => {
          sort(1);
        }
      });

      contextMenu.show(e.event.target);
    }
  };
  angebotKopieErstellenCommand: ICommandData = {
    id: "angebotKopieErstellen",
    idCategory: "split",
    icon: "plus-circle",
    title: "angebot.angebot_erstellen",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Angebot.CanSave && models.data.$m_Angebot.Id > 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
      const selectedKeys: number[] = grid.instance.getSelectedRowKeys();

      const webEvent = new OnAngebotspositionenInNeuesAngebotUebernehmenEvent({
        IdAngebot: this.form.models.data.$m_Angebot.Id,
        IdAngebotPosList: selectedKeys
      });

      const webEventResult: OnAngebotspositionenInNeuesAngebotUebernehmen = await this._webEventService.execute(webEvent, true);
      if (!webEventResult || !webEventResult.IdAngebot) {
        return;
      }

      this._locationService.goTo({
        url: `ERP/Verkauf/Angebot/${webEventResult.IdAngebotKopie}`,
        clearStack: false
      });

      this.form.models.reloadAll();
    }
  };
  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Angebot.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
      const angebotPosGridRowData = grid.instance.getSelectedRowsData();

      if (angebotPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("angebot.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const angebotssposition of angebotPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          angebotssposition.TypeName,
          angebotssposition.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const angebotssposition of angebotPosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: angebotssposition.Id,
          CustomData: { "PosNrUeberschreiben": true }
        }),
          true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Angebot.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_angebotspositionGrid"];
      const angebotPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (angebotPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("angebot.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (angebotPosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("angebot.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const angebotsposition of angebotPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          angebotsposition.TypeName,
          angebotsposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = angebotPosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdAngebotspositionListEvent({
        IdAngebotspositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this._infobarZuletztArtikelAddSubscription = this._eventAggregator.subscribe("infobar:zuletzt-verkaufte-artikel:addArtikel", (e) => {
      if (this._popupInfoService.isPopupOpen()) {
        return;
      }
      if (!this.form.isCurrentForm) {
        return;
      }

      this.addLieferscheinposition(e);
    });

    form.focusFirstEnabledEditor = () => {
      const kunde = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_kunde'] .dx-searchbox input");
      if (!kunde) {
        return;
      }

      kunde.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Angebot",
      idArtPropertyName: "IdAngebotsart",
      idVorgEntitaetPropertyName: "IdKunde"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Angebot",
      kalkulationRef: "r_kalkulation",
      editPopups: ["angebotspositionEditPopup", "angebotsgruppeEditPopup"]
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "angebotspositionEditPopup") {
        form["r_angebotsgruppeGrid"].instance.refresh();
      } else if (r.editPopup.id == "angebotsgruppeEditPopup") {
        form["r_angebotspositionGrid"].instance.refresh();
      }

      return Promise.resolve();
    });

    form.models.onLoaded.register(async (r) => {
      if (r == void 0 || r.model.id != "$m_Angebot") {
        return;
      }

      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Angebot);
      return Promise.resolve();
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_AngebotspositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Angebot") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Angebot"];
          if (model) {
            model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);

            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Angebot",
                bindTo: "IdLieferfiliale"
              },
              value: model.IdLieferfiliale
            });
          }
        }

        if (args.binding.bindTo === "IdLieferfiliale") {
          const model = form.models.data["$m_Angebot"];
          if (model) {
            model.IdLieferlager = await this._lagerService.getIdLagerVorbelegung(args.value);

            const idWaehrung = await this._filialeService.getWaehrungIdOfFiliale(args.value);
            if (idWaehrung != null) {
              model.IdWaehrung = idWaehrung;
            } else {
              model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Angebot.IdFirma);
            }
          }
        }

        if (args.binding.bindTo === "IdAngebotsart") {
          const data = this.form.models.data.$m_Angebot;
          if (!data) {
            return;
          }

          data.GueltigBis = await this._angebotService.getGueltigBis(
            data.IdAngebotsart,
            data.GueltigBis);
        }
      }
    });
  }
  unbind() {
    if (this._infobarZuletztArtikelAddSubscription) {
      this._infobarZuletztArtikelAddSubscription.dispose();
      this._infobarZuletztArtikelAddSubscription = null;
    }
  }

  async addLieferscheinposition(item) {
    const validationResult = await this.form.saveIfDirty();
    if (!validationResult.isValid) {
      return;
    }

    let args = new OnAngebotAddLieferscheinpositionEvent({
      IdAngebot: this.form.models.data.$m_Angebot.Id,
      IdLieferscheinposition: item.idLieferscheinposition
    });

    args = await this._webEventService.execute(args, true);
    item.isHandled = true;

    this.form.models.reloadAll();
  }

  async onKundeChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    const onEvalLieferRechnungsortEvent = new OnEvalLieferRechnungsortEvent({ IdKunde: e.detail.value });
    const onEvalLieferRechnungsortData: OnEvalLieferRechnungsort = await this._webEventService.execute(onEvalLieferRechnungsortEvent);

    this.form["r_lieferort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdLieferort);
    this.form["r_rechnungsort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdRechnungsort);

    if (onEvalLieferRechnungsortData.HasMoreThanOneLieferort) {
      this._geschaeftspartnerSearchService.show((r) => {
        this.form["r_lieferort"].setGeschaeftpartnerById(r);
      }, () => { }, {
        caption: "erp.select_lieferort",
        filters: [{
          webApiCustomKey: "IdLieferortFuer",
          webApiCustomValue: e.detail.value
        }]
      });
    }

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }
  onKundeAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    const idKunde = this.form.models.data.$m_Angebot.IdKunde;
    if (idKunde == this.form.models.data.$m_Angebot.IdLieferort) {
      if (this.form.models.data.$m_Angebot.IdLieferortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Angebot.IdLieferortAnsprechperson = idPerson;
      }

      this.onLieferortAnsprechpersonChanged({
        detail: {
          value: idPerson
        }
      });
    }

    if (idKunde == this.form.models.data.$m_Angebot.IdRechnungsort) {
      if (this.form.models.data.$m_Angebot.IdRechnungsortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Angebot.IdRechnungsortAnsprechperson = idPerson;
      }
    }
  }
  async onLieferortAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    this.form.models.data.$m_Angebot.LieferortTelefon = await this._personService.getPersonTelefon(idPerson);
  }
  onKundeAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onLieferortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onRechnungsortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }

  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Angebot.CanSave && this.form.models.data.$m_Angebot.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Angebot.IdKunde);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Angebot.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Angebot",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
