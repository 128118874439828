import { autoinject, bindable } from "aurelia-framework";
import { IListViewItemsOptions } from './list-view-items-options';
import { LocalizationService } from '../../../base/services/localization-service';

@autoinject
export class ListViewItems {
  constructor(
    private localization: LocalizationService
  ) {}

  @bindable options: IListViewItemsOptions;

  loadNextButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localization.translateOnce("list_view.load_next"),
    onClick: () => {
      this.options.goToNextPage();
    }
  }
}
