import { autoinject, bindable, observable, Scope, computedFrom } from "aurelia-framework";
import { WebEventService, GlobalizationService, ScopeContainer, DataSourceService } from '../../../framework/base/export';
import { OnCalcLieferterminEvent, OnCalcLiefertermin } from '../../../framework-data/events';
import { IdxPopupComponent } from '../../interfaces/export';
import { SimpleWidgetCreatorService } from '../../../framework/forms/export';
import { LagerService, FilialeService, FirmaService, ArtikelService, StartupService } from '../../services/export';

@autoinject
export class CalcLiefertermin {
  private _idStandardlager: number;
  
  constructor(
    private _startupService: StartupService,
    private _webEventService: WebEventService,
    private _globalizationService: GlobalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _firmaService: FirmaService,
    private _filialeService: FilialeService,
    private _lagerService: LagerService,
    private _dataSourceService: DataSourceService,
    private _artikelService: ArtikelService
  ) {}

  scope: Scope;
  scopeContainer: ScopeContainer;

  idArtikel: number;
  idLager: number;
  mengeLagEH: number = 1;
  lieferdatum: Date;
  bereitstelldatum: Date;
  hasFehlendeBeschaffungsperiode: boolean;

  showLager: boolean;
  showMenge: boolean;
  openTree: boolean;

  detailList: any[];
  bestandList: any[];

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    width: "1080px",
    height: "75vh"
  }
  scrollViewOptions: DevExpress.ui.dxScrollViewOptions = {
    height: "100%"
  };
  lagerSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "BezeichnungFQ",
    valueExpr: "Id",
    width: "350px",
    bindingOptions: {
      value: "idLager"
    }
  };
  mengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    min: 1,
    format: this._globalizationService.getNumberFormat("n2"),
    bindingOptions: {
      value: "mengeLagEH"
    }
  };
  refreshButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Aktualisieren",
    onClick: () => {
      this.refresh();
    }
  };
  detailTreeListOptions: DevExpress.ui.dxTreeListOptions = {
    keyExpr: "Id",
    parentIdExpr: "IdParent",
    columns: [{
      dataField: "Nummer",
      caption: "Artikel-Nr.",
      width: "150px"
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    }, {
      dataField: "Bedarf",
      caption: "Bedarf LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Bestand",
      caption: "aus Bestand LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Reserviert",
      caption: "Reserviert LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Bestellt",
      caption: "Bestellt LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Produziert",
      caption: "Prod. LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "BedarfRest",
      caption: "Bedarf Rest LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Beschaffungsperiode",
      caption: "Beschaffungsperiode",
      width: "100px"
    }, {
      dataField: "Lieferdatum",
      caption: "Lieferdatum",
      width: "90px",
      format: this._globalizationService.getFormatterParser("d")
    }],
    bindingOptions: {
      dataSource: "detailList"
    },
    onContentReady: (e) => {
      this.openTree = false;

      if (!this.detailList) {
        return;
      }

      this.detailList
        .forEach(k => e.component.expandRow(k.Id));
    }
  }
  bestandDataGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "Nummer",
      caption: "Artikel-Nr.",
      width: "80px"
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 100
    }, {
      dataField: "Lager",
      caption: "Lager",
      width: "160px"
    }, {
      dataField: "Bestand",
      caption: "Bestand LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "Auftrag",
      caption: "Auftrag LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }, {
      dataField: "AuftragBeistell",
      caption: "Auftrag Beistell. LE",
      width: "80px",
      format: this._globalizationService.getFormatterParser("n2")
    }],
    bindingOptions: {
      dataSource: "bestandList"
    }
  }

  @computedFrom("_startupService.startupInfo.Mandant.PufferPeriodeZwischenProduktion")
  get pufferInfo() {
    const puffer = this._startupService.startupInfo.Mandant.PufferPeriodeZwischenProduktion;

    if (puffer) {
      return `sowie einen Puffer von ${puffer}`;
    } else {
      return "ohne zusätzliche Puffer";
    }
  }

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: null
    };
    this.scopeContainer = new ScopeContainer(this.scope, null);

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "calcLieferterminToolbar",
      caption: "calc-liefertermin.lieferdatum-berechnen",
      options: this.popupOptions,
      commands: [],
      scopeContainer: this.scopeContainer
    });

    this.lagerSelectBoxOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Stammdaten/Lager",
        webApiColumns: ["Id", "BezeichnungFQ"],
        webApiWhere: ["IsSchwebelager", false]
      }
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  async show(idArtikel: number, idLager?: number, menge?: number, idArtikelEinheit?: number) {
    this.idArtikel = idArtikel;
    this.idLager = idLager;

    if (idArtikelEinheit) {
      const umrechner = await this._artikelService.getUmrechner(idArtikelEinheit);
      menge = menge * umrechner;
    }

    this.mengeLagEH = menge || 1;

    this.showLager = !idLager;
    this.showMenge = !menge;
    this.openTree = true;

    if (this.showLager) {
      await this.setStandardLager();
    }

    this.popup.instance.show();
    this.refresh();
  }

  private async setStandardLager() {
    if (!this._idStandardlager) {
      const idFirma = await this._firmaService.getIdFirmaVorbelegung();
      const idFiliale = await this._filialeService.getIdFilialeVorbelegung(idFirma);
      this._idStandardlager = await this._lagerService.getIdLagerVorbelegung(idFiliale);
    }

    this.idLager = this._idStandardlager;
  }
  private async refresh() {
    this.detailList = [];
    this.bestandList = [];
    this.lieferdatum = null;
    this.bereitstelldatum = null;
    this.hasFehlendeBeschaffungsperiode = false;

    if (!this.idArtikel || !this.idLager) {
      return;
    }

    const r: OnCalcLiefertermin = await this._webEventService.execute(new OnCalcLieferterminEvent({
      IdArtikel: this.idArtikel,
      IdLager: this.idLager,
      MengeLagEH: this.mengeLagEH || 1,
      HasFehlendeBeschaffungsperiode: false
    }), true);

    this.bereitstelldatum = r.Bereitstelldatum;
    this.lieferdatum = r.Lieferdatum;
    this.hasFehlendeBeschaffungsperiode = r.HasFehlendeBeschaffungsperiode;
    this.detailList = <any>r.DetailList;
    this.bestandList = <any>r.BestandList;
  }
}