import { autoinject, transient } from "aurelia-framework";
import { OnDeleteIdWarenuebernahmepositionListEvent, OnImportBestellungspositionenInWarenuebernahme, OnImportBestellungspositionenInWarenuebernahmeEvent, OnKopierenRequest, OnKopierenRequestEvent, OnWarenuebernahmeAbschliessenEvent } from "../../../../framework-data/events";
import { WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { DynFelderDataService, DynFelderService, FilialeService, FirmaService, GeschaeftspartnerService, LagerService, ValidationExService } from "../../../services/export";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { LocalizationService } from "./../../../../framework/base/services/localization-service";

@autoinject
@transient()
export class WarenuebernahmeEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderData: DynFelderDataService,
    private _asyncService: AsyncService,
    private _dynFelderService: DynFelderService,
    private _filialeService: FilialeService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _lagerService: LagerService,
    private _localizationService: LocalizationService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;

  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "warenuebernahme",
    title: "warenuebernahme.abschliessen",
    icon: "check",
    sortIndex: 999,
    isEnabledExpression: "models.data.$m_Warenuebernahme.CanSave && models.data.$m_Warenuebernahme.Status.Typ === 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Warenuebernahme.TypeName,
        this.form.models.data.$m_Warenuebernahme.Id,
        "ABSCHLIESSEN"
      );

      if (!validationResult) {
        return;
      }

      await this._webEventService.execute(new OnWarenuebernahmeAbschliessenEvent({
        IdWarenuebernahme: this.form.models.data["$m_Warenuebernahme"].Id
      }), true);

      return this.form.models.reloadAll();
    }
  };
  showImportBestellungspositionenPopupCommand: ICommandData = {
    id: "showImportBestellungspositionen",
    icon: "plus",
    idCategory: "warenuebernahme",
    title: "warenuebernahme.bestellungspos_importieren",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Warenuebernahme.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const bestellungspositionGrid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];
      if (bestellungspositionGrid && bestellungspositionGrid.instance) {
        await this._asyncService.convertToPromise(bestellungspositionGrid.instance.deselectAll());
        bestellungspositionGrid.instance.refresh();
      }

      this.form["r_bestellungspositionEditPopup"].instance.show();
    }
  };
  importBestellungspositionenCommand: ICommandData = {
    id: "importBestellungspositionen",
    title: "warenuebernahme.mark_pos_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_bestellungspositionGrid"];

      const webEvent = new OnImportBestellungspositionenInWarenuebernahmeEvent({
        IdWarenuebernahme: this.form.models.data.$m_Warenuebernahme.Id,
        IdBestellungspositionList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportBestellungspositionenInWarenuebernahme = await this._webEventService.execute(webEvent, true);
      this.form["r_bestellungspositionEditPopup"].instance.hide();

      this.form.models.reloadAll();
    }
  };
  doCopyPosCommand: ICommandData = {
    id: "doCopyPosCommand",
    icon: "clone",
    sortIndex: 1000,
    title: "form-command.kopieren-command-icon-text",
    isEnabledExpression: "models.data.$m_Warenuebernahme.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_warenuebernahmepositionGrid"];
      const warenuebernahmePosGridRowData = grid.instance.getSelectedRowsData();

      if (warenuebernahmePosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("warenuebernahme.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const warenuebernahmeposition of warenuebernahmePosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          warenuebernahmeposition.TypeName,
          warenuebernahmeposition.Id,
          "KOPIEREN"
        );

        if (!validationResult) {
          return;
        }
      }

      for (const warenuebernahmeposition of warenuebernahmePosGridRowData) {
        const onKopierenRequest: OnKopierenRequest = await this._webEventService.execute(new OnKopierenRequestEvent({
          IdSourceOjekt: warenuebernahmeposition.Id,
          CustomData: { "PosNrUeberschreiben": true }
        }),
          true);
      }

      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Warenuebernahme.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_warenuebernahmepositionGrid"];
      const warenuebernahmePosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (warenuebernahmePosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("warenuebernahme.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (warenuebernahmePosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("warenuebernahme.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const warenuebernahmeposition of warenuebernahmePosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          warenuebernahmeposition.TypeName,
          warenuebernahmeposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = warenuebernahmePosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdWarenuebernahmepositionListEvent({
        IdWarenuebernahmepositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const lieferant = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_lieferant'] .dx-searchbox input");
      if (!lieferant) {
        return;
      }

      lieferant.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Warenuebernahme",
      idVorgEntitaetPropertyName: "IdLieferant"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Warenuebernahme",
      kalkulationRef: "r_kalkulation",
      editPopups: ["warenuebernahmepositionEditPopup"]
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_WarenuebernahmepositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Warenuebernahme") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Warenuebernahme"];
          if (model) {
            model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);
            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Warenuebernahme",
                bindTo: "IdLieferfiliale"
              },
              value: model.IdLieferfiliale
            });
          }
        }

        if (args.binding.bindTo === "IdLieferfiliale") {
          const model = form.models.data["$m_Warenuebernahme"];
          if (model) {
            model.IdLieferlager = await this._lagerService.getIdLagerVorbelegung(args.value);

            const idWaehrung = await this._filialeService.getWaehrungIdOfFiliale(args.value);
            if (idWaehrung != null) {
              model.IdWaehrung = idWaehrung;
            } else {
              model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Warenuebernahme.IdFirma);
            }
          }
        }
      }
    });
  }

  async onLieferantChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }

  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(){
    if (this.form.models.data.$m_Warenuebernahme.CanSave && this.form.models.data.$m_Warenuebernahme.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Warenuebernahme.IdLieferant);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if(idFirma == null){
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Warenuebernahme.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Warenuebernahme",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
