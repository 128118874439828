import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, transient } from "aurelia-framework";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { LocationService } from "../../../../framework/base/services/location-service";
import { FormBase, ICommandData, PopupInfoService } from "../../../../framework/forms/export";
import { IdxPopupComponent, IdxSelectBoxComponent } from "../../../interfaces/export";
import { DxCustomizeService } from "../../../services/dx-customize-service";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";
import { BriefService } from "../../../services/export";
import { OnErstelleAngebotEvent, OnErstelleAuftragEvent, OnGeschaeftspartnerartAendern, OnGeschaeftspartnerartAendernEvent } from "./../../../../framework-data/events";
import { IdxTextBoxComponent } from "./../../../interfaces/dx-components";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class GeschaeftspartnerEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderDataService: DynFelderDataService,
    public startupService: StartupService,
    private _webEventService: WebEventService,
    private _localizationService: LocalizationService,
    private _popupInfoService: PopupInfoService,
    private _locationService: LocationService,
    private _dxCustomizeService: DxCustomizeService,
    private _briefService: BriefService,
    private _eventAggregator: EventAggregator) {
    const mahnung = this.startupService.startupInfo.Lizenz.HasMahnung;
    this.hasMahnung = mahnung;
  }

  form: FormBase;
  hasMahnung: boolean;

  doCopyCommand: ICommandData = {
    id: "doCopyCommand",
    icon: "clone",
    title: "geschaeftspartner-edit-utils.geschaeftspartner-kopie-erstellen",
    isEnabledExpression: "models.data.$m_GeschaeftspartnerCopy.IdGeschaeftspartnerart",
    execute: null
  };
  doGeschaeftspartnerartAendernCommand: ICommandData = {
    id: "doGeschaeftspartnerartAendern",
    icon: "floppy-o",
    title: "base.save",
    execute: async () => {
      this.doGeschaeftspartnerartAendern();
    }
  };
  showGeschaeftspartnerartAendernCommand: ICommandData = {
    id: "showGeschaeftspartnerartAendern",
    idCategory: "geschaeftspartner_art",
    icon: "pencil-square-o",
    title: "geschaeftspartner-edit-utils.geschaeftspartnerart_aendern",
    sortIndex: 955,
    isEnabledExpression: "models.data.$m_Geschaeftspartner.Id > 0",
    execute: this.onShowGeschaeftspartnerartAendernClicked.bind(this)
  };
  gpAngebotErstellenCommand: ICommandData = {
    id: "gpAngebotErstellen",
    idCategory: "geschaeftspartner",
    icon: "plus-circle",
    title: "geschaeftspartner-edit-utils.angebot_erstellen",
    sortIndex: 980,
    isEnabledExpression: "models.data.$m_Geschaeftspartner.CanSave && models.data.$m_Geschaeftspartner.Id > 0",
    isVisibleExpression: "models.data.$m_Geschaeftspartnerart.IsKunde",
    execute: async (e) => {
      this.OnNeuErstellenClicked("Angebot", e);
    }
  };
  gpAuftragErstellenCommand: ICommandData = {
    id: "gpAuftragErstellen",
    idCategory: "geschaeftspartner",
    icon: "plus-circle",
    title: "geschaeftspartner-edit-utils.auftrag_erstellen",
    sortIndex: 990,
    isEnabledExpression: "models.data.$m_Geschaeftspartner.CanSave && models.data.$m_Geschaeftspartner.Id > 0",
    isVisibleExpression: "models.data.$m_Geschaeftspartnerart.IsKunde",
    execute: async (e) => {
      this.OnNeuErstellenClicked("Auftrag", e);
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Geschaeftspartner",
      idArtPropertyName: "IdGeschaeftspartnerart"
    });

    this.form.callOnBind(() => {
      this._dxCustomizeService.addTelefon(this.form["r_telefonOptions"]);
      this._dxCustomizeService.addHomepage(this.form["r_homepageOptions"]);
      this._dxCustomizeService.addUid(this.form["r_uidNummerOptions"]);
      this._dxCustomizeService.addBrief(this.form["r_emailOptions"], async () => {
        if (!this.form.models.data.$m_Geschaeftspartner.Email) {
          DevExpress.ui.notify(
            this._localizationService.translateOnce("geschaeftspartner-edit-utils.email-fehlt"),
            "error",
            this.NOTIFY_TIMEOUT
          );

          return;
        }

        const saveResult = await this.form.saveIfDirty();
        if (!saveResult.isValid) {
          return;
        }

        this._briefService.createAndGoToBrief(this.form.models.data.$m_Geschaeftspartner.Id);
      });
    });

    this._eventAggregator.subscribe("uid:reload", (e) => {
      if (this.form.models.data.$m_Geschaeftspartner.UIDNummer == e.UIDNummer) {
        const uidNummerField: IdxTextBoxComponent = this.form["r_uidNummer"];
        this._dxCustomizeService.reloadUid(uidNummerField.instance);
      }
    });

    this.form.onSaving.register(async (r) => {
      if (r.form.models.hasChangedData()) {
        if (r.form.models.data.$m_Geschaeftspartner.UIDNummer != null) {
          r.form.models.data.$m_Geschaeftspartner.UIDNummer = r.form.models.data.$m_Geschaeftspartner.UIDNummer.replace(new RegExp(" ", "g"), "");
        }

        return Promise.resolve();
      }
    });

    this.form.models.onLoaded.register(async (r) => {
      if (r.model.id == "$m_ProjektZuGeschaeftspartnerRel") {
        if (r.data && r.data.data && Array.isArray(r.data.data)) {
          for (const item of r.data.data) {
            if (!item.ProjektEx || !item.ProjektEx.IdStatus) {
              continue;
            }

            item["IdStatus"] = item.ProjektEx.IdStatus;
          }
        }
      }
      return Promise.resolve();
    });
  }

  copyGeschaeftspartner($delegate: { (customData: any): Promise<any> }) {
    const copyPopup: IdxPopupComponent = this.form["r_copyPopup"];

    this.form.models.data.$m_GeschaeftspartnerCopy = {
      IdGeschaeftspartnerart: this.form.models.data.$m_Geschaeftspartner.IdGeschaeftspartnerart,
      DoKopierenNotizen: true,
      DoKopierenKonditionen: false
    };

    copyPopup.instance
      .show()
      .then(() => {
        this.form["r_geschaeftspartnerCopyNummer"].instance.focus();
      });

    this.doCopyCommand.execute = () => {
      $delegate(this.form.models.data.$m_GeschaeftspartnerCopy)
        .then((r) => {
          copyPopup.instance.hide();
        });
    };
  }

  onReservierungsgradCellPrepared(e): void {
    if (e.rowType != "data" || e.data.IsBestaetigt == false || e.data.Status.Typ !== 0) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.Reservierungsgrad < 1
      ? "#C1392D"
      : (e.data.HasArtikelNichtLagerfuehrendOffen ? "#566473" : "#2DCC70");
  }
  onZahlungFaelligCellPrepared(e): void {
    if (e.rowType != "data" || e.data.BezahltTyp == void (0) || !e.data.ZahlungFaelligkeit || !e.data.Status || e.data.Status.Typ != 1) {
      return;
    }
    if (e.data.BezahltTyp != 0 && e.data.BezahltTyp != 2) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.ZahlungFaelligkeit.getTime() >= new Date().getTime()
      ? "#2DCC70"
      : "#C1392D";
  }

  async onShowGeschaeftspartnerartAendernClicked() {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const selectBox: IdxSelectBoxComponent = this.form["r_geschaeftspartnerartEdit"];
    if (selectBox) {
      const dataSource = selectBox.instance.getDataSource();
      dataSource.reload();

      selectBox.setOption({ value: null });
    }

    const popup: IdxPopupComponent = this.form["r_geschaeftspartnerartPopup"];
    popup.instance.show();
  }
  async doGeschaeftspartnerartAendern() {
    const popup: IdxPopupComponent = this.form["r_geschaeftspartnerartPopup"];
    const onGeschaeftspartnerartAendernEvent = new OnGeschaeftspartnerartAendernEvent({
      IdGeschaeftspartner: this.form.models.data.$m_Geschaeftspartner.Id,
      IdGeschaeftspartnerart: this.form["r_geschaeftspartnerartEdit"].instance.option("value")
    });

    if (!this.form["r_geschaeftspartnerartEdit"].instance.option("value")) {
      DevExpress.ui.notify(this._localizationService.translateOnce("geschaeftspartner-edit-utils.keine_geschaeftspartnerart_ausgewaehlt"), "error", this.NOTIFY_TIMEOUT);
      return;
    }

    const result: OnGeschaeftspartnerartAendern = await this._webEventService.execute(onGeschaeftspartnerartAendernEvent, true);

    this.form.models.reloadAll();

    DevExpress.ui.notify(this._localizationService.translateOnce("geschaeftspartner-edit-utils.geschaeftspartnerart_geaendert"), "SUCCESS", this.NOTIFY_TIMEOUT);
    popup.instance.hide();
  }

  private async OnNeuErstellenClicked(entitaetName: string, event: any) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const idGeschaeftspartner = this.form.models.data.$m_Geschaeftspartner.Id;
    const webEvent = this.getWebEvent(entitaetName, idGeschaeftspartner);

    const webEventResult = await this._webEventService.execute(webEvent, true);
    if (!webEventResult) {
      return;
    }

    const navigationUrl = this.getNavigationUrlFromWebEventResult(entitaetName, webEventResult);
    if (!navigationUrl) {
      return;
    }

    this._locationService.goTo({
      url: navigationUrl,
      clearStack: false
    });

    this._popupInfoService.closeAllPopups();
  }
  private getWebEvent(entitaetName: string, idGeschaeftspartner: number) {
    switch (entitaetName) {
      case "Angebot":
        return new OnErstelleAngebotEvent({ IdGeschaeftspartner: idGeschaeftspartner });
      case "Auftrag":
        return new OnErstelleAuftragEvent({ IdGeschaeftspartner: idGeschaeftspartner });
      default:
        return null;
    }
  }
  private getNavigationUrlFromWebEventResult(entitaetName: string, webEventResult: any): string {
    if (!webEventResult) {
      return null;
    }

    let result = null;
    switch (entitaetName) {
      case "Angebot":
        result = webEventResult.IdAngebot
          ? `ERP/Verkauf/${entitaetName}/${webEventResult.IdAngebot}`
          : null;
        break;
      case "Auftrag":
        result = webEventResult.IdAuftrag
          ? `ERP/Verkauf/${entitaetName}/${webEventResult.IdAuftrag}`
          : null;
        break;
    }

    return result;
  }
}
