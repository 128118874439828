import { Scope, autoinject } from "aurelia-framework";
import { LocalizationService, ScopeContainer } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/interfaces/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IdxPopupComponent } from "../../interfaces/export";
import { GlobalizationService } from "./../../../framework/base/services/globalization-service";

@autoinject
export class VariablerSetartikelKomponentenAuswahl {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IVariablerSetartikelKomponentenAuswahlPopupOptions;

  constructor(
    private _localizationService: LocalizationService,
    private _globalizationService: GlobalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService) { }

  variablerSetartikelKomponentenAuswahlPopup: IdxPopupComponent;
  variablerSetartikelKomponentenAuswahlPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "auto",
    minHeight: "600px",
  };
  variablerSetartikelKomponentenAuswahlPopupCommands: ICommandData[] = [{
    id: "uebernehmen",
    title: "variabler-setartikel-komponenten-auswahl.uebernehmen",
    icon: "save",
    execute: this.auswahlUebernehmen.bind(this)
  }];

  scope: Scope;
  scopeContainer: ScopeContainer;

  doKomponenteAuswahlCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    bindingOptions: {
      text: "komponente.Artikelbezeichnung",
      value: "komponente.IsChecked"
    },
    onContentReady: (e) => {
      if (e.model.bindingContext.komponente.IsInaktiv) {
        e.component.option("disabled", true);
      }
    }
  }

  mengeNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    bindingOptions: {
      value: "komponente.Menge"
    },
    onContentReady: (e) => {
      if (e.model.bindingContext.komponente.IsInaktiv) {
        e.component.option("disabled", true);
      }
    }
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "variablerSetartikelKomponentenAuswahlPopupToolbar",
      caption: "variabler-setartikel-komponenten-auswahl.popup-titel",
      options: this.variablerSetartikelKomponentenAuswahlPopupOptions,
      commands: this.variablerSetartikelKomponentenAuswahlPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  show(options: IVariablerSetartikelKomponentenAuswahlPopupOptions) {
    this._options = options;

    this._options.StuecklisteList.forEach(element => {
      if (element.IsInaktiv) {
        element.IsChecked = false;
      } else if (element.HasVariablerSetartikelVorbelegung) {
        element.IsChecked = true;
      } else {
        element.IsChecked = false;
      }
    });

    this.variablerSetartikelKomponentenAuswahlPopup.instance.show();
  }


  private auswahlUebernehmen() {
    const result = this._options.StuecklisteList
      .filter(c => {
        return c.IsChecked;
      })
      .map(c => {
        return {
          IdStuecklistenkomponente: c.Id,
          Menge: c.Menge
        };
      });

    if (result.length == 0) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("variabler-setartikel-komponenten-auswahl.error-keine-auswahl"),
        "ERROR",
        this.NOTIFY_TIMEOUT);
      return;
    }

    const hasMengeKleinerGleich0 = result
      .some(c => c.Menge <= 0);

    if (hasMengeKleinerGleich0) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("variabler-setartikel-komponenten-auswahl.error-menge-null"),
        "ERROR",
        this.NOTIFY_TIMEOUT);
      return;
    }

    this.variablerSetartikelKomponentenAuswahlPopup.instance.hide();
    this._options.callback(result);
  }
}

export interface IVariablerSetartikelKomponentenAuswahlPopupOptions {
  StuecklisteList: IVariableSetartikelKomponentenAuswahlStueckliste[];
  callback: {
    (auswahl: {
      IdStuecklistenkomponente: number;
      Menge: number;
    }[]): void
  };
}

export interface IVariableSetartikelKomponentenAuswahlStueckliste {
  Id: number;
  Artikelnummer?: string;
  Artikelbezeichnung?: string;
  Menge: number;
  Einheit?: string;
  Faktor?: string;
  IsInaktiv: boolean;
  IsChecked?: boolean;
  HasVariablerSetartikelVorbelegung?: boolean;
}
