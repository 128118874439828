import { autoinject, transient } from "aurelia-framework";
import { FormBase } from "../../../../framework/forms/export";
import { FirmaService } from "../../../services/export";

@autoinject
@transient()
export class GutscheinEditUtils {
  constructor(
    private _firmaService: FirmaService,) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }

  async onFirmaChanged() {
    const idWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Gutschein.IdFirma);

    if (idWaehrung != null) {
      this.form.models.data.$m_Gutschein.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Gutschein.IdFirma);
    }
  }
}
