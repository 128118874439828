import * as fwx from "../../../../framework/forms/form-export";
import {
    InventurEditUtils
} from "./inventur-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class InventurEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_InventurEditUtils: InventurEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "inventur-edit";
        this.title = "inventur-edit.inventur-edit_caption";
        this.addModel({
            "id": "$m_Inventur",
            "webApiAction": "ERP/Lager/Inventur",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Inventurposition",
            "webApiAction": "ERP/Lager/Inventurposition",
            "webApiExpand": {
                'Artikel': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_InventurpositionRel",
            "webApiAction": "ERP/Lager/Inventurposition",
            "webApiExpand": {
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Inventur.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_InventurpositionEdit",
            "webApiAction": "ERP/Lager/Inventurposition",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_InventurEditUtils.doExport",
                "bindToFQ": "functions.$f_InventurEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_InventurEditUtils.doZaehllisteErstellen",
                "bindToFQ": "functions.$f_InventurEditUtils.doZaehllisteErstellen"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_InventurEditUtils.doInventurVerbuchen",
                "bindToFQ": "functions.$f_InventurEditUtils.doInventurVerbuchen"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_InventurEditUtils.doFehlendeArtikelHinz",
                "bindToFQ": "functions.$f_InventurEditUtils.doFehlendeArtikelHinz"
            }
        });
        this.addEditPopup({
            "idContent": "inventurpositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_InventurpositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_InventurpositionEdit.Id"
                }
            }],
            "id": "inventurpositionEditPopup",
            "options": {
                "optionsName": "inventurpositionEditPopupOptions",
                "optionsNameFQ": "inventurpositionEditPopupOptions"
            },
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_InventurEditUtils", $f_InventurEditUtils, "functions.$f_InventurEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerMitFirmaFiliale",
            "filters": [],
            "caption": "inventur-edit.r_lager_caption",
            "isReadOnlyExpression": "models.data.$m_Inventur.IsZaehllisteErstellt",
            "binding": {
                "dataContext": "$m_Inventur",
                "bindTo": "IdLager",
                "bindToFQ": "models.data.$m_Inventur.IdLager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lager",
            "options": {
                "optionsName": "r_lagerOptions",
                "optionsNameFQ": "r_lagerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "inventur-edit.r_datum_caption",
            "isReadOnlyExpression": "models.data.$m_Inventur.IsZaehllisteErstellt",
            "binding": {
                "dataContext": "$m_Inventur",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Inventur.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "inventur-edit.r_verbuchtam_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Inventur",
                "bindTo": "VerbuchtAm",
                "bindToFQ": "models.data.$m_Inventur.VerbuchtAm"
            },
            "validationRules": [],
            "id": "r_verbuchtAm",
            "options": {
                "optionsName": "r_verbuchtAmOptions",
                "optionsNameFQ": "r_verbuchtAmOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "inventur-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Inventur",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Inventur.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_invpArtikelNummer",
                "caption": "inventur-edit.r_invpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_invpBezeichnung",
                "caption": "inventur-edit.r_invpbezeichnung_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_invpSollmenge",
                "caption": "inventur-edit.r_invpsollmenge_caption",
                "bindTo": "Sollmenge",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_invpDifferenz",
                "caption": "inventur-edit.r_invpdifferenz_caption",
                "bindTo": "Differenz",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_invpIstmenge",
                "caption": "inventur-edit.r_invpistmenge_caption",
                "bindTo": "Istmenge",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_invpWertEinstandspreis",
                "caption": "inventur-edit.r_invpwerteinstandspreis_caption",
                "bindTo": "EinstandspreisWert",
                "width": "80px",
                "format": "n6"
            }, {
                "id": "r_invpWertSoll",
                "caption": "inventur-edit.r_invpwertsoll_caption",
                "bindTo": "WertSoll",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_invpWertDifferenz",
                "caption": "inventur-edit.r_invpwertdifferenz_caption",
                "bindTo": "WertDifferenz",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_invpWertIst",
                "caption": "inventur-edit.r_invpwertist_caption",
                "bindTo": "WertIst",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_invLagereinheit",
                "caption": "inventur-edit.r_invlagereinheit_caption",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "width": "120px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_inventurpositionGridToolbarOptions",
                "optionsNameFQ": "r_inventurpositionGridToolbarOptions"
            },
            "caption": "inventur-edit.r_inventurpositiongrid_caption",
            "binding": {
                "dataContext": "$m_Inventur"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_InventurpositionRel",
                "bindTo": "IdInventur",
                "bindToFQ": "models.data.$m_InventurpositionRel.IdInventur"
            },
            "dataModel": "$m_Inventur",
            "editDataContext": "$m_InventurpositionEdit",
            "idEditPopup": "inventurpositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_inventurpositionGrid",
            "options": {
                "optionsName": "r_inventurpositionGridOptions",
                "optionsNameFQ": "r_inventurpositionGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}