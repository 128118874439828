import {
  autoinject
} from "aurelia-framework";
import {
  WebEventService, IViewScrollInfo
} from "../../framework/base/export";
import {
  OnBriefErstellen,
  OnBriefErstellenEvent
} from "../../framework-data/events";
import {
  BriefFormCommand
} from "../elements/brief-form-command/brief-form-command";
import {
  CustomEditPopupService
} from "./custom-edit-popup-service";

@autoinject
export class BriefService {
  constructor(
    private customEditPopup: CustomEditPopupService,
    private webEvent: WebEventService
  ) { }

  async createAndGoToBrief(idVerknuepfteEntitaet: number = null, dmsLinkList: string[] = [], isHauptdokument: boolean = false) {
    const idBrief = await this.createBrief(idVerknuepfteEntitaet, dmsLinkList, isHauptdokument);
    if (idBrief == void 0) {
      return;
    }

    this.goToBrief(idBrief);
  }
  goToBrief(id: number, viewScrollInfo?: IViewScrollInfo) {
    this.customEditPopup.briefEditPopup.show({
      mappings: { "$id": id },
      viewScrollInfo
    });
  }
  
  private async createBrief(idVerknuepfteEntitaet: number = null, dmsLinkList: string[] = [], isHauptdokument: boolean = false): Promise<number> {
    const webEvent = new OnBriefErstellenEvent({ 
      IsHauptdokument: isHauptdokument,
      DMSLinkList: dmsLinkList, 
      IdVerknuepfteEntitaet: idVerknuepfteEntitaet 
    });
    const result: OnBriefErstellen = await this.webEvent.execute(webEvent, true)

    if (result == void 0 || result.IdBrief == void 0) {
      return undefined;
    }

    return result.IdBrief;
  }
}
