import {
  autoinject,
  computedFrom,
  OverrideContext,
  Scope,
  TaskQueue
} from "aurelia-framework";
import {
  AuthorizationService,
  LocalizationService,
  ScopeContainer,
  ShortcutService
} from "../../../base/export";
import {
  HeaderService
} from "../../services/export";
import {
  CommandService,
  PopupInfoService,
  ICommandData,
  RouterService
} from "../../../forms/export"
import { EventAggregator } from 'aurelia-event-aggregator';
import { StartupService } from "../../../../erp/services/startup-service";

@autoinject
export class Header {
  constructor(
    private taskQueue: TaskQueue,
    private router: RouterService,
    private authorization: AuthorizationService,
    private header: HeaderService,
    private localization: LocalizationService,
    private command: CommandService,
    private _startupService: StartupService,
    private shortcut: ShortcutService,
    private popupInfo: PopupInfoService,
    private eventAggregator: EventAggregator
  ) {
    if (!this.header.avatarCommands.find(c => c.id === "logout")) {
      this.header.avatarCommands.push({
        id: "logout",
        title: this.localization.translateOnce("base.logout"),
        execute: () => {
          this.logout();
        }
      });
    }

    this.shortcut.bindShortcut("f6", () => {
      if (this.popupInfo.isPopupOpen()) {
        return;
      }

      const input = <HTMLInputElement>this.searchTextBox.instance.element()
        .querySelector("input:not([type ='hidden'])");

      input.focus();
      input.select();
    });
  }

  searchTextBox: any;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this.localization.translateOnce("base.search"),
    onEnterKey: (e: any) => {
      this.taskQueue.queueTask(() => {
        const value = e.component.option("value");
        if (value == void (null) || value == "") {
          return;
        }

        this.searchShowPopupButton.instance.option("disabled", false);
        this.eventAggregator.publish("header:search", {
          text: value
        });
      });
    }
  }

  searchShowPopupButton: any;
  searchShowPopupButtonOptions: DevExpress.ui.dxButtonOptions = {
    disabled: true,
    icon: "fa fa-history",
    onClick: () => {
      this.eventAggregator.publish("header:show-search-popup", {});
    }
  }

  avatar: Element;
  avatarPopover: any;
  avatarPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: "250px"
  }

  scope: Scope;
  scopeContainer: ScopeContainer;

  commands: ICommandData[];
  avatarCommands: ICommandData[];

  @computedFrom("_startupService.startupInfo.Benutzer.IsCloudAdministrator")
  get isUserCloudAdministrator() {
    return this._startupService.startupInfo.Benutzer.IsCloudAdministrator;
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.header.header = this;
    this.prepareCommands();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  logout() {
    this.authorization.logout();
  }

  onAvatarClick() {
    const popover: DevExpress.ui.dxPopover = this.avatarPopover.instance;
    popover.show(this.avatar);
  }

  private prepareCommands() {
    if (this.header.avatarCommands) {
      this.avatarCommands = this.header.avatarCommands.map(c => {
        return this.convertCommand(c);
      });
    }
    if (this.header.commands) {
      this.commands = this.header.commands.map(c => {
        return this.convertCommand(c);
      })
    }
  }
  private convertCommand(command: ICommandData): ICommandData {
    //TODO - Expression auch evaluieren + ggf. guardedExecute

    if (command.isVisible == void (0)) {
      command.isVisible = true;
    }
    if (command.isEnabled == void (0)) {
      command.isEnabled = true;
    }

    return command;
  }
}
