import { autoinject, transient } from "aurelia-framework";
import { OnOffice365AuthTokenAbfrage, OnOffice365AuthTokenAbfrageEvent, OnOffice365TokenAnfordern, OnOffice365TokenAnfordernEvent } from "../../../../framework-data/events";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { StartupService } from "../../../services/export";
import { WebEventService } from "./../../../../framework/base/services/web-event-service";

@autoinject
@transient()
export class BenutzerEinstellungenEditUtils {
  constructor(
    private _startupService: StartupService,
    private _webEventService: WebEventService
  ) {
    this.isSupport = this._startupService.startupInfo.Benutzer.IsSupport;
  }

  form: FormBase;
  isSupport = false;

  office365AuthCommand: ICommandData = {
    id: "office365Auth",
    idCategory: "benutzer",
    title: "erp_header.office365_auth",
    icon: "lock",
    sortIndex: 22,
    isVisible: false,
    execute: async () => {
      const r: OnOffice365TokenAnfordern = await this._webEventService.execute(new OnOffice365TokenAnfordernEvent({}));
      window.open(r.AuthUrl, "_blank");
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register(async (e) => {
      if (e.model.id == "$m_Benutzer" && e.data) {
        if (!this._startupService.startupInfo.Benutzer.HasOffice365MailServer) {
          this.office365AuthCommand.isVisible = false;
          return;
        }

        const r: OnOffice365AuthTokenAbfrage = await this._webEventService.execute(new OnOffice365AuthTokenAbfrageEvent({
          HasAuthToken: false
        }));

        this.office365AuthCommand.isVisible = r && !r.HasAuthToken
          ? true
          : false;
      }

      return Promise.resolve();
    });
  }
}
