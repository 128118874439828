import { ExcelExportService, IExcelExportDataGridOptions, IExcelExportPivotGridOptions } from "../../../forms/services/excel-export-service";

export default class Excel {
  constructor(
    private _excelExportService: ExcelExportService
  ) {}

  exportDataGrid(options: IExcelExportDataGridOptions) {
    this._excelExportService.exportDataGrid(options);
  }
  exportPivotGrid(options: IExcelExportPivotGridOptions) {
    this._excelExportService.exportPivotGrid(options);
  }
}