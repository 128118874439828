import { autoinject, bindable, observable } from "aurelia-framework";
import { WidgetService } from '../services/widget-service';
import { AuGrid, IAuGridCell } from 'aurelia-dashboard-grid';
import { RestService } from '../../../framework/base/export';
import { IdxTabsComponent } from '../../interfaces/export';

@autoinject
export class Viewer {
  constructor(
    private _widgetService: WidgetService,
    private _restService: RestService
  ) {}

  @bindable typ: number;
  @bindable @observable mainModel: any;
  grid: AuGrid;
  cells: IAuGridCell[] = [];

  tabDataSource: any[] = [];
  tabsOptions: DevExpress.ui.dxTabsOptions = {
    keyExpr: "id",
    onSelectionChanged: (e) => {
      
    },
    bindingOptions: {
      dataSource: "tabDataSource",
      selectedIndex: "selectedDashboardIndex"
    }
  };
  
  @observable selectedDashboardIndex: number = null;
  dashboardList: any[];
  currentDashboard: any;

  attached() {
    this.loadData();
  }

  mainModelChanged() {
    this.loadDashboard(this.currentDashboard);
  }
  selectedDashboardIndexChanged() {
    if (this.selectedDashboardIndex == void(0)) {
      return;
    }

    this.loadDashboard(this.dashboardList[this.selectedDashboardIndex]);
  }

  private async loadData() {
    this.dashboardList = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Dashboard"),
      getOptions: {
        where: ["Typ", this.typ],
        customs: [{ key: "CheckBerechtigung", value: true }],
        orderBy: [{columnName: "SortNr", sortOrder: 0}, {columnName: "Bezeichnung", sortOrder: 0}]
      }
    });

    this.tabDataSource = this.dashboardList.map(i => {
      return {
        id: i.Id,
        text: i.Bezeichnung
      }
    });

    if (this.dashboardList.length > 0) {
      this.selectedDashboardIndex = 0;
    }
  }
  private async loadDashboard(dashboard: any) {
    this.currentDashboard = dashboard;
    this.cells = this._widgetService.getCellsFromDefinition(dashboard.Definition, this.mainModel);
  }
}