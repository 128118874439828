import { RestService } from "../../framework/base/services/export";
import { autoinject } from "../../framework/forms/form-export";

@autoinject
export class BestellungService {
  constructor(
    private _restService: RestService) { }

  async getBestellungPosByIdBestellung(idBestellung: number) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Einkauf/Bestellungsposition"),
      getOptions: {
        where: [["IdBestellung", idBestellung]]
      }
    });

    if (!r) {
      return null;
    }

    return r;
  }

  async getBestellungListByIdAuftrag(idAuftrag) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Einkauf/Bestellung"),
      getOptions: {
        where: ["IdAuftrag", idAuftrag]
      }
    });
    if (!r) {
      return null;
    }

    return r;
  }

  async getIdBestellungspositionByIdAuftragsposition(idAuftragsposition) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Einkauf/Bestellungsposition"),
      getOptions: {
        where: ["IdAuftragsposition", idAuftragsposition],
        columns: ["Id"]
      }
    });
    if (!r || r.length === 0) {
      return null;
    }

    return r[0]["Id"];
  }

  async getMengeVerpEHByIdAuftragsposition(idAufragsposition){
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Einkauf/Bestellungsposition"),
      getOptions: {
        where: ["IdAuftragsposition", idAufragsposition]
      }
    });

    if (!r || r.length === 0) {
      return 0;
    }

    const sum = r.reduce((p, c) => {
      return p + c.MengeVerpEH;
    }, 0);

    return sum;
  }

  async hasBestellungsgruppePositionenZugeteilt(idBestellungsgruppe){
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Einkauf/Bestellungsposition`),
      getOptions: {
        where: ["IdBestellungsgruppe", idBestellungsgruppe]
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}
