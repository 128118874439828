import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { LocalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitLohnexportErstellen {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IArbeitszeitLohnexportErstellenOptions;

  constructor(
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  arbeitszeitLohnexportInfoList: any[];
  arbeitszeitLohnexportInfoKalkulierbarList: any[];
  arbeitszeitLohnexportInfoNichtKalkulierbarList: any[];
  stichtag: Date;
  showLohnexportSettingInfo: boolean;
  idLohnexportInfo: number;
  jahr: number;
  monat: any;
  months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

  arbeitszeitLohnexportErstellenPopup: Interfaces.IdxPopupComponent;
  arbeitszeitLohnexportErstellenPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 600,
    height: "auto"
  };

  monatBox: Interfaces.IdxSelectBoxComponent;
  monatBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    bindingOptions: {
      dataSource: "months",
      value: "monat"
    },
    onValueChangedByUser: (e) => {
      if (this.jahr != null && this.monat != null) {
        this.loadData();
      }
    }
  }

  jahrBox: Interfaces.IdxNumberBoxComponent;
  jahrBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: "####",
    min: 2000,
    bindingOptions: {
      value: "jahr"
    },
    onValueChangedByUser: () => {
      if (this.jahr != null && this.monat != null) {
        this.loadData();
      }
    }
  }

  arbeitszeitLohnexportInfoBox: Interfaces.IdxSelectBoxComponent;
  arbeitszeitLohnexportInfoOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "arbeitszeitLohnexportInfoList",
      value: "idLohnexportInfo"
    },
    onValueChangedByUser: (e) => {
      const lohnexportInfo = this.arbeitszeitLohnexportInfoList
        .filter(c => c.Id == e.value)[0] || null;

      if (lohnexportInfo == null) {
        return;
      }

      this.showLohnexportSettingInfo = true;
      this.arbeitszeitLohnexportInfoKalkulierbarList = lohnexportInfo.LohndatenberechnungEinstellungenKalkulierbarList;
      this.arbeitszeitLohnexportInfoNichtKalkulierbarList = lohnexportInfo.LohndatenberechnungEinstellungenNichtKalkulierbarList;
    }
  }

  arbeitszeitLohnexportErstellenPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitLohnexportErstellen",
    icon: "save",
    title: "base.save",
    isEnabledExpression: "",
    execute: async () => {
      if (this.jahr == null || this.monat == null) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("arbeitszeit-lohnexport-erstellen.error-datum"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }
      if (this.idLohnexportInfo == null) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("arbeitszeit-lohnexport-erstellen.error-einstellung"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const datum = new Date(this.jahr, this.months.indexOf(this.monat), 1);
      datum.setHours(0, 0, 0, 0)

      const arbeitszeitLohnexportErstellen: IArbeitszeitLohnexportErstellen = {
        Id: this.idLohnexportInfo,
        Stichtag: datum
      };
      this.arbeitszeitLohnexportErstellenPopup.instance.hide();
      this._options.callback(arbeitszeitLohnexportErstellen);
    }
  }];

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitLohnexportErstellenToolbar",
      caption: "arbeitszeit-lohnexport-erstellen.arbeitszeit-lohnexport-erstellen",
      options: this.arbeitszeitLohnexportErstellenPopupOptions,
      commands: this.arbeitszeitLohnexportErstellenPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  async show(options: IArbeitszeitLohnexportErstellenOptions) {
    this._options = options;

    this.showLohnexportSettingInfo = false;
    this.idLohnexportInfo = null;
    this.arbeitszeitLohnexportInfoKalkulierbarList = null;
    this.arbeitszeitLohnexportInfoNichtKalkulierbarList = null;
    this.arbeitszeitLohnexportErstellenPopup.instance.show();
  }

  private loadData() {
    this.showLohnexportSettingInfo = false;
    this.idLohnexportInfo = null;
    this.arbeitszeitLohnexportInfoKalkulierbarList = null;
    this.arbeitszeitLohnexportInfoNichtKalkulierbarList = null;

    const datum = new Date(this.jahr, this.months.indexOf(this.monat), 1);
    datum.setHours(0, 0, 0, 0)

    this.loadLohnexportInfoLaden(datum);
  }

  private async loadLohnexportInfoLaden(datum: Date) {
    this.arbeitszeitLohnexportInfoList = await this._restService.post({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitLohnexport/einstellung`),
      data: {
        Stichtag: datum
      }
    });
  }
}

export interface IArbeitszeitLohnexportErstellenOptions {
  callback: { (arbeitszeitLohnexportErstellen: IArbeitszeitLohnexportErstellen): void };
}

export interface IArbeitszeitLohnexportErstellen {
  Id: number;
  Stichtag: Date;
}
