import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator,
  Subscription
} from 'aurelia-event-aggregator';
import {
  DataSourceService,
  WebEventService,
  IViewScrollInfo
} from "../../../framework/base/export";
import {
  OnBriefAnzahlRequestEvent
} from "../../../framework-data/events";
import {
  FormBase,
  ICommandData
} from "../../../framework/forms/export";
import {
  ListView,
  IListViewOptions
} from "../../../framework/forms/elements/list-view/export";
import {
  BriefService,
  FormCommandService
} from "../../services/export";
import {
  NotificationTyp
} from "../../enumerations/export";
import {
  IdxPopoverComponent
} from "../../interfaces/export";
import { ScopeContainer } from '../../../framework/base/classes/scope-container';

@autoinject
export class BriefFormCommand {
  private _availableForms: FormBase[] = [];
  private _briefFormCommandSubscription: Subscription;
  private _detachedAddCommand: { (): void };
  private _detachedShowCommand: { (): void };
  private _idVerknuepfteEntitaet: number;

  constructor(
    private _briefService: BriefService,
    private _dataSourceService: DataSourceService,
    private _eventAggregator: EventAggregator,
    private _formCommandService: FormCommandService,
    private _webEventService: WebEventService
  ) { }

  showBriefFormCommand: ICommandData = {
    id: "showBriefFormCommand",
    idCategory: "brief",
    title: "form-command.brief-command-icon-text",
    icon: "envelope-open-o",
    isEnabled: false,
    badgeText: null,
    badgeColor: "#E77E22",
    sortIndex: 7000
  }

  addBriefFormCommand: ICommandData = {
    id: "$addBrief",
    idCategory: "brief",
    title: "form-command.brief-command-neu",
    icon: "plus",
    isEnabled: false,
    badgeText: null,
    sortIndex: 7000
  }

  briefPopover: IdxPopoverComponent;
  briefPopoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
    width: 400,
    onShowing: (e) => {
      this.briefListView.refresh();
    }
  };

  briefListView: ListView;
  briefListViewOptions: IListViewOptions = {
    showReloadButton: false,
    height: "350px",
    pageSize: 15,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this._briefService.goToBrief(
        e.item.Id,
        this.getViewScrollInfo(e.item));

      this.briefPopover.instance.hide();
    }
  }

  scopeContainer: ScopeContainer;

  showPopover(idVerknuepfteEntitaet: number, target: Element) {
    this._idVerknuepfteEntitaet = idVerknuepfteEntitaet;
    this.briefPopover.instance.show(target);
  }

  attached() {
    this._briefFormCommandSubscription = this._eventAggregator
      .subscribe(
        NotificationTyp.briefAnzahlChanged,
        (data) => {
          if (data == null) {
            return;
          }
          this.loadBriefBadge({ Id: data.IdVerknuepfteEntitaet });
        });

    this._detachedShowCommand = this._formCommandService.attachFormCommand(
      this.showBriefFormCommand,
      {
        execute: (e) => {
          const element = this._formCommandService.getCommandTarget(e.options.event);

          this.showPopover(e.modelData.Id, element);
        },
        isCommandVisibleModelProperty: "HasBriefEmpfaenger",
        onFormModelLoaded: this.loadBriefBadge.bind(this),
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.deleteAvailableForm.bind(this)
      });

    this._detachedAddCommand = this._formCommandService.attachFormCommand(
      this.addBriefFormCommand,
      {
        saveModelOnExecute: true,
        execute: (e) => {
          this._idVerknuepfteEntitaet = e.modelData.Id;
          this._briefService.createAndGoToBrief(this._idVerknuepfteEntitaet)
            .then(r => {
              this.briefPopover.instance.hide();
            });
        },
        isCommandVisibleModelProperty: "HasBriefEmpfaenger"
      }
    )
  }
  detached() {
    this._briefFormCommandSubscription.dispose();
    this._detachedAddCommand();
    this._detachedShowCommand();
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.briefListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Brief",
        webApiExpand: {
          Sachbearbeiter: {
            columns: ["Id", "Name"]
          }
        },
        filters: [{
          webApiCustomKey: "AddTags",
          webApiCustomValue: "true"
        }]
      },
      {
        getCustomWhere: () => {
          if (this._idVerknuepfteEntitaet == void 0) {
            return null;
          }

          return ["IdVerknuepfteEntitaet", this._idVerknuepfteEntitaet];
        }
      }
    );

  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  private loadBriefBadge(data, command?: ICommandData) {
    if (data == void 0 || data.Id == void 0) {
      return;
    }

    const commandToReload = command || this._formCommandService.getReloadCommand(this.showBriefFormCommand, this._availableForms, {
      modelPropertyName: "Id",
      modelPropertyValue: data.Id
    })

    if (!commandToReload) {
      return;
    }

    const aufgabeAnzahlEvent = new OnBriefAnzahlRequestEvent({
      IdVerknuepfteEntitaet: data.Id
    });
    this._webEventService
      .execute(aufgabeAnzahlEvent)
      .then(r => {
        if (r == void 0 || r.IdVerknuepfteEntitaet == void 0) {
          return;
        }
        if (r.Anzahl == 0 || r.Anzahl == void 0) {
          commandToReload.isEnabled = false;
          commandToReload.badgeText = null;
          return;
        }

        commandToReload.isEnabled = true;
        commandToReload.badgeText = {
          key: "base.param_1",
          parameters: [r.Anzahl]
        };
      });

  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private deleteAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1)
  }
  private getViewScrollInfo(brief): IViewScrollInfo {
    const dataSource = this.briefListViewOptions.dataSource;
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(brief);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
}
