import { autoinject } from 'aurelia-framework';
import { EventAggregator } from "aurelia-event-aggregator";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { RouterService } from '../../framework/forms/export';
import { AuthorizationService, LocalizationService } from '../../framework/base/export';
import { StartupService } from './startup-service';


@autoinject
export class AppInsightsService {
  private _instance: ApplicationInsights;

  constructor(
    private _routerService: RouterService,
    private _eventAggregator: EventAggregator,
    private _authorizationService: AuthorizationService,
    private _startupService: StartupService,
    private _localizationService: LocalizationService
  ) {    
    this._instance = new ApplicationInsights({ config: {
      instrumentationKey: "eeff1df6-f6b6-4e1c-ac88-76066b5a5814",
      enableCorsCorrelation: true,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true
    } });

    this._instance.loadAppInsights();

    this._eventAggregator.subscribe("authorization:change-app", () => {
      if (!this._authorizationService.isLoggedIn) {
        this._instance.clearAuthenticatedUserContext();
      }
    });
    this._eventAggregator.subscribe("startupInfo:loaded", () => {
      this._instance.setAuthenticatedUserContext(this._startupService.startupInfo.Benutzer.Id.toString());
    });
  }
}