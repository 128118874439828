import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";

import {
  SpracheUebersetzung
} from "../elements/sprache-uebersetzung/sprache-uebersetzung";

@autoinject
export class SpracheUebersetzungService {
  constructor(
    private rest: RestService
  ) { }

  spracheUebersetzung: SpracheUebersetzung

  loadSprachen(whereOptions: any[] = []): Promise<any[]> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Stammdaten/Sprache"),
      getOptions: {
        where: whereOptions
      }
    });
  }

  show(modelData: any){
    this.spracheUebersetzung.show(modelData);
  }
}