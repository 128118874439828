import * as fwx from "../../../../framework/forms/form-export";
import {
    FakturagruppeEditUtils
} from "./fakturagruppe-edit-utils";

@fwx.autoinject
export class FakturagruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_FakturagruppeEditUtils: FakturagruppeEditUtils) {
        super(element, formBaseImport);
        this.id = "fakturagruppe-edit";
        this.title = "fakturagruppe-edit.fakturagruppe-edit_caption";
        this.addModel({
            "id": "$m_Fakturagruppe",
            "webApiAction": "ERP/Verkauf/Fakturagruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Faktura.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_Faktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Fakturagruppe.IdFaktura",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_FakturagruppeEditUtils", $f_FakturagruppeEditUtils, "functions.$f_FakturagruppeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "fakturagruppe-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Fakturagruppe",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Fakturagruppe.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "fakturagruppe-edit.r_posnr_caption",
            "binding": {
                "dataContext": "$m_Fakturagruppe",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Fakturagruppe.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}