import { autoinject, transient } from "aurelia-framework";
import { OnPrepareIntrastatSync, OnPrepareIntrastatSyncEvent } from "../../../../framework-data/events";
import { WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";

@autoinject
@transient()
export class IntrastatSyncEditUtils {
  constructor(
    private webEvent: WebEventService) { }

  form: FormBase;

  doSynchronisierungStarten: ICommandData = {
    id: "doSynchronisierungStarten",
    idCategory: "fibu-sync-job",
    title: "intrastat-sync.do_synchronisierung_starten",
    icon: "refresh",
    isEnabledExpression: "models.data.$m_IntrastatSync.CanSave && models.data.$m_IntrastatSync.Status.Typ == 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnPrepareIntrastatSyncEvent({
        IdIntrastatSync: this.form.models.data.$m_IntrastatSync.Id
      });

      const webEventResult: OnPrepareIntrastatSync = await this.webEvent.execute(webEvent, true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
