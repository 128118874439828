import { AsyncService } from './../../../framework/base/services/async-service';
import { StartupService } from './../../services/startup-service';
import {
  autoinject,
  bindable,
  observable
} from "aurelia-framework";

import {
  LocalizationService,
  RestService
} from "../../../framework/base/export";
import { IdxButtonComponent } from '../../interfaces/export';

@autoinject
export class AufgabeKommentar {
  constructor(
    private localizationService: LocalizationService,
    private restService: RestService,
    private startupService: StartupService,
    private asyncService: AsyncService,
    private element: Element
  ) {
    this.isCloudAdministrator = this.startupService.startupInfo.Benutzer.IsCloudAdministrator;
  }

  @bindable @observable idAufgabe: number;

  kommentar: string;
  kommentarList: any[];
  isCloudAdministrator: boolean;
  showKommentareButtonVisible = false;
  showKommentareAbIndex = 0;
  showKommentareStandard = 5;

  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };
  showKommentarButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "",
    onClick: () => {
      if (this.showKommentareAbIndex) {
        this.showKommentareAbIndex = 0;
      } else {
        this.showKommentareAbIndex = this.kommentarList.length - this.showKommentareStandard;
      }
      this.showKommentarButtonText();
    }
  }
  showKommentarButton: IdxButtonComponent;
  saveAufgabeKommentarButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: this.localizationService.translateOnce("aufgabe_kommentar.senden"),
    type: "default",
    onClick: () => {
      this.save();
    },
    bindingOptions: {
      disabled: "!kommentar"
    }
  };

  bind() {
    this.loadKommentar();
  }

  idAufgabeChanged() {
    this.kommentar = null;
    this.loadKommentar();
  }

  async delete(kommentar: any) {
    const doDelete = await this.asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
      this.localizationService.translateOnce("base.sure_delete_question"),
      this.localizationService.translateOnce("base.question")));

    if (!doDelete) {
      return;
    }

    await this.restService.delete({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/AufgabeKommentar"),
      id: kommentar.Id
    });

    this.loadKommentar();
    this.dispatchKommentarChanged();
  }

  private async loadKommentar() {
    this.kommentarList = await this.restService.get({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/AufgabeKommentar"),
      getOptions: {
        where: ["IdAufgabe", this.idAufgabe || -1],
        orderBy: [{ columnName: "AnlageDatum", sortOrder: 0 }],
        customs: [{ key: "AenderungInfo", value: true }]
      }
    });

    this.showKommentareButtonVisible = this.kommentarList.length > this.showKommentareStandard;
    this.showKommentareAbIndex = this.kommentarList.length - this.showKommentareStandard;
    this.showKommentarButtonText();
  }
  private dispatchKommentarChanged() {
    const event = new CustomEvent('aufgabe-kommentar-changed', {
      detail: {
        sender: this,
        value: this.kommentar
      },
      bubbles: true
    });

    this.element.dispatchEvent(event);
  }
  private reset() {
    this.kommentar = null;
  }
  private async save() {
    if (!this.idAufgabe) {
      return;
    }

    if (!this.kommentar) {
      return;
    }

    await this.restService.post({
      url: this.restService.getWebApiUrl("ERP/Stammdaten/AufgabeKommentar"),
      data: {
        IdAufgabe: this.idAufgabe,
        KommentarHtml: this.kommentar
      },
      increaseLoadingCount: true
    });

    this.loadKommentar();
    this.reset();
    DevExpress.ui.notify(this.localizationService.translateOnce("base.save_success"), "SUCCESS", 3000);
    this.dispatchKommentarChanged();

  }
  private showKommentarButtonText() {
    const anzahl = this.kommentarList.length - this.showKommentareStandard;

    const text = this.showKommentareAbIndex <= 0
      ? `Verstecke alte Kommentar (${anzahl})`
      : `Alte Kommentare anzeigen (${anzahl})`;

    if (this.showKommentarButton && this.showKommentarButton.instance) {
      this.showKommentarButton.setOption({ text: text });
    } else {
      this.showKommentarButtonOptions.text = text;
    }
  }
}
