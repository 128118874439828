import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class MailImportItemListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "mail-import-item-list";
        this.title = "mail-import-item-list.mail-import-item-list_caption";
        this.addModel({
            "id": "$m_MailImportItem",
            "webApiAction": "ERP/Stammdaten/MailImportItem",
            "webApiExpand": {
                'MailImportDefinition': null,
                Status: null,
                Geschaeftspartner: {
                    expand: {
                        Adresse: null
                    }
                },
                Person: null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_mailImportItemGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_maItMailImportDefinition",
                "caption": "mail-import-item-list.r_maitmailimportdefinition_caption",
                "bindTo": "MailImportDefinition.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_maItDatum",
                "caption": "mail-import-item-list.r_maitdatum_caption",
                "bindTo": "Datum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_maItBetreff",
                "caption": "mail-import-item-list.r_maitbetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_maItGpNummer",
                "caption": "mail-import-item-list.r_maitgpnummer_caption",
                "bindTo": "Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_maItFirmenbez",
                "caption": "mail-import-item-list.r_maitfirmenbez_caption",
                "bindTo": "Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_maItPerson",
                "caption": "mail-import-item-list.r_maitperson_caption",
                "bindTo": "Person.Name",
                "width": "140px"
            }, {
                "id": "r_maItStatus",
                "caption": "mail-import-item-list.r_maitstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_mailImportItemGridToolbarOptions",
                "optionsNameFQ": "r_mailImportItemGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_MailImportItem"
            },
            "dataModel": "$m_MailImportItem",
            "editUrl": "ERP/Stammdaten/MailImportItem",
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_mailImportItemGrid",
            "options": {
                "optionsName": "r_mailImportItemGridOptions",
                "optionsNameFQ": "r_mailImportItemGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}