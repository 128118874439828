import * as fwx from "../../../../framework/forms/form-export";
import {
    ImpressumUtils
} from "./impressum-utils";

@fwx.autoinject
export class ImpressumForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ImpressumUtils: ImpressumUtils) {
        super(element, formBaseImport);
        this.id = "impressum-form";
        this.title = "impressum-form.impressum-form_caption";
        this.addFunction("$f_ImpressumUtils", $f_ImpressumUtils, "functions.$f_ImpressumUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        super.onConstructionFinished();
    }
}