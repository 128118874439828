import { autoinject, bindable, Container } from "aurelia-framework";
import { FormBase, SimpleWidgetCreatorService, ICommandData } from "../../../framework/forms/export";
import { DynFelderDataService, IDynamischesFeldAnzeige } from "../../services/dyn-felder-data-service";
import { IDynamischesFeld } from "../../services/dyn-felder-service";
import { ScopeContainer, LocalizationService, WebEventService } from "../../../framework/base/export";
import { IdxPopupComponent, IdxValidationGroup } from "../../interfaces/export";
import { DynFeldTyp } from "../../enumerations/export";
import { OnDynamischesFeldUpdateWertEvent, OnDynamischesFeldUpdateWert } from "../../../framework-data/events";

@autoinject
export class DynFeldContainer {
  private _form: FormBase;
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _localizationService: LocalizationService,
    private _webEventService: WebEventService
  ) { }

  scopeContainer: ScopeContainer;

  @bindable dynFelderDataService: DynFelderDataService;
  @bindable isDefault: boolean;
  @bindable anzeigeort: string;

  @bindable idMainModel: string;
  @bindable idArtPropertyName: string;
  @bindable idVorgEntitaetPropertyName: string;

  dynFeldList: IDynamischesFeldAnzeige[];

  wertAendernValidationGroup: IdxValidationGroup;

  wertAendernPopup: IdxPopupComponent;
  wertAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "500px",
    height: "auto",
    onShown: () => {
      if (this.wertAendernOptions.wertEditor) {
        this.wertAendernOptions.wertEditor.instance.focus();
      }
    },
    onHidden: (e) => {
      this.wertAendernOptions = null;
    }
  };
  wertAendernSpeichernCommand: ICommandData = {
    id: "$save",
    icon: "floppy-o",
    title: "base.save",
    tooltip: "base.save_tooltip",
    sortIndex: 10,
    execute: () => {
      this.saveWertAendern();
    }
  };
  wertAendernOptions: IWertAendernOptions;

  bind(bindingContext) {
    this._form = bindingContext;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    if (!this.dynFelderDataService) {
      this.dynFelderDataService = Container.instance.get(DynFelderDataService);

      this.dynFelderDataService.register(
        this._form, {
        idMainModel: this.idMainModel,
        idArtPropertyName: this.idArtPropertyName,
        idVorgEntitaetPropertyName: this.idVorgEntitaetPropertyName
      });
    }

    this.dynFelderDataService.onDynFelderDataReloadRequested.register(async (r) => {
      const dynFeldList = await this.dynFelderDataService.getDynFeldAnzeigeList(
        this.anzeigeort,
        this.isDefault
      );
     
      dynFeldList.forEach((f) => f.options["DynFeldContainer"] = this);
      this.dynFeldList = dynFeldList;
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "updateWertToolbar",
      scopeContainer: this.scopeContainer,
      options: this.wertAendernPopupOptions,
      caption: "dyn-feld-container.wert-aendern",
      commands: [this.wertAendernSpeichernCommand]
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  showWertAendern(feld: IDynamischesFeld, feldAnzeige: IDynamischesFeldAnzeige) {
    const feldOptions = this.dynFelderDataService.getDynFeldAnzeige(feld).options;

    switch (feld.Typ) {
      case DynFeldTyp.text: {
        feldOptions.value = feldAnzeige.eintrag.WertText;
        break;
      }
      case DynFeldTyp.nummer: {
        feldOptions.value = feldAnzeige.eintrag.WertNummer;
        break;
      }
      case DynFeldTyp.datum: {
        feldOptions.value = feldAnzeige.eintrag.WertDatum;
        break;
      }
      case DynFeldTyp.auswahlliste: {
        feldOptions.value = feldAnzeige.eintrag.IdWertAuswahlliste;
        break;
      }
    }

    this.wertAendernOptions = {
      feld: feld,
      feldAnzeige: feldAnzeige,

      wertOptions: feldOptions
    };

    this.wertAendernPopup.instance.show();
  }
  async saveWertAendern() {
    const validationResult = this.wertAendernValidationGroup.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    const wert = this.wertAendernOptions.wertEditor.instance.option("value");

    const eintrag = {};

    switch (this.wertAendernOptions.feld.Typ) {
      case DynFeldTyp.text: {
        eintrag["WertText"] = wert;
        break;
      }
      case DynFeldTyp.nummer: {
        eintrag["WertNummer"] = wert;
        break;
      }
      case DynFeldTyp.datum: {
        eintrag["WertDatum"] = wert;
        break;
      }
      case DynFeldTyp.auswahlliste: {
        eintrag["IdWertAuswahlliste"] = wert;
        break;
      }
    }

    const args: OnDynamischesFeldUpdateWert = {
      IdDynamischesFeldContainer: this.wertAendernOptions.feldAnzeige.eintrag.IdDynamischesFeldContainer,
      IdDynamischesFeld: this.wertAendernOptions.feldAnzeige.eintrag.IdDynamischesFeld,
    };
    Object.assign(args, eintrag);

    await this._webEventService.execute(new OnDynamischesFeldUpdateWertEvent(args), true);

    Object.assign(this.wertAendernOptions.feldAnzeige.eintrag, eintrag);

    this._form.reloadIfServerHasNewVersion();

    DevExpress.ui.notify(
      this._localizationService.translateOnce("base.save_success"),
      "SUCCESS",
      this.NOTIFY_TIMEOUT);

    this.wertAendernPopup.instance.hide();
  }
}

interface IWertAendernOptions {
  feld: IDynamischesFeld;
  feldAnzeige: IDynamischesFeldAnzeige;

  wertOptions: DevExpress.ui.EditorOptions;
  wertEditor?: { instance: DevExpress.ui.Editor };
}
