import { 
  FrameworkConfiguration,
  PLATFORM
} from "aurelia-framework";
import "./styles/styles.less";
import "./styles/dashboard.less";

export function configure(config: FrameworkConfiguration) {
  config
    .globalResources(PLATFORM.moduleName("./elements/kalkulation/kalkulation"))
    .globalResources(PLATFORM.moduleName("./elements/adresse/adresse"))
    .globalResources(PLATFORM.moduleName("./elements/projekt/projekt"))
    .globalResources(PLATFORM.moduleName("./elements/geschaeftspartner/geschaeftspartner"))
    .globalResources(PLATFORM.moduleName("./elements/artikel/artikel"))
    .globalResources(PLATFORM.moduleName("./elements/dyn-feld-container/dyn-feld-container"))
    .globalResources(PLATFORM.moduleName("./elements/filter/filter"))
    .globalResources(PLATFORM.moduleName("./elements/infobar/infobar"))
    .globalResources(PLATFORM.moduleName("./elements/avatar/avatar"))
    .globalResources(PLATFORM.moduleName("./elements/tags/tags"))
    .globalResources(PLATFORM.moduleName("./elements/tags-list/tags-list"))
    .globalResources(PLATFORM.moduleName("./elements/entitaet-menu/entitaet-menu"))
    .globalResources(PLATFORM.moduleName("./elements/mhd-charge-menge-erf/mhd-charge-menge-erf"))
    .globalResources(PLATFORM.moduleName("./elements/mhd-charge-menge-ausw/mhd-charge-menge-ausw"))
    .globalResources(PLATFORM.moduleName("./attributes/open-edit-attribute"))
    .globalResources(PLATFORM.moduleName("./value-converters/type-display-name-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/ticket-kategorie-name-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/ticket-status-name-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/ticket-typ-name-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/ticket-status-farbe-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/industriezeit-zu-zeit-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/entitaet-full-name-zu-name-value-converter"))
    .globalResources(PLATFORM.moduleName("./value-converters/stueckliste-faktor-value-converter"));
}
