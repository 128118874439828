import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FehlerListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fehler-list";
        this.title = "fehler-list.fehler-list_caption";
        this.addModel({
            "id": "$m_Fehler",
            "webApiAction": "ERP/Cloud/Fehler",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "allowSave": "false",
            "allowDelete": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_feDatenbank",
                "caption": "fehler-list.r_fedatenbank_caption",
                "bindTo": "Datenbank",
                "width": "150px"
            }, {
                "id": "r_feIdMandant",
                "caption": "fehler-list.r_feidmandant_caption",
                "bindTo": "IdMandant",
                "width": "100px"
            }, {
                "id": "r_feIdBenutzer",
                "caption": "fehler-list.r_feidbenutzer_caption",
                "bindTo": "IdBenutzer",
                "width": "100px"
            }, {
                "id": "r_feFehlerinformation",
                "caption": "fehler-list.r_fefehlerinformation_caption",
                "bindTo": "Fehlerinformation",
                "minWidth": 100
            }, {
                "id": "r_feKommentar",
                "caption": "fehler-list.r_fekommentar_caption",
                "bindTo": "Kommentar",
                "minWidth": 100
            }, {
                "id": "r_feAnlagedatum",
                "caption": "fehler-list.r_feanlagedatum_caption",
                "bindTo": "AnlageDatum",
                "width": "110px",
                "format": "g"
            }, {
                "id": "r_feIsBearbeitet",
                "caption": "fehler-list.r_feisbearbeitet_caption",
                "bindTo": "IsBearbeitet",
                "width": "90px"
            }],
            "optionsToolbar": {
                "optionsName": "r_fehlerGridToolbarOptions",
                "optionsNameFQ": "r_fehlerGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Fehler"
            },
            "dataModel": "$m_Fehler",
            "editUrl": "ERP/Cloud/Fehler",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fehlerGrid",
            "options": {
                "optionsName": "r_fehlerGridOptions",
                "optionsNameFQ": "r_fehlerGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}