import {
  autoinject
} from "aurelia-framework";
import {
  GeschaeftspartnerSearchResult
} from "../elements/geschaeftspartner-search-result/geschaeftspartner-search-result";
import {
  ISearchOptions
} from "../interfaces/export";

@autoinject
export class GeschaeftspartnerSearchService {
  constructor() { }

  geschaeftspartnerSearchResult: GeschaeftspartnerSearchResult;
  
  show(callbackSuccess: { (idGp): void }, callbackCancel: { (): void }, options?: ISearchOptions) {
    this.geschaeftspartnerSearchResult.showPopup(callbackSuccess, callbackCancel, options)
  }
}