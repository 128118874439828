import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    FakturaListUtils
} from "./faktura-list-utils";

@fwx.autoinject
export class FakturaListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_FakturaListUtils: FakturaListUtils) {
        super(element, formBaseImport);
        this.id = "faktura-list";
        this.title = "faktura-list.faktura-list_caption";
        this.addModel({
            "id": "$m_Faktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null,
                'Rechnungsort': null,
                'RechnungsortAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_fakturaGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaListUtils.doExport",
                "bindToFQ": "functions.$f_FakturaListUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_FakturaListUtils.doAbschliessenCommand",
                "bindToFQ": "functions.$f_FakturaListUtils.doAbschliessenCommand"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_FakturaListUtils", $f_FakturaListUtils, "functions.$f_FakturaListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fakNummer",
                "caption": "faktura-list.r_faknummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_fakDatum",
                "caption": "faktura-list.r_fakdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_fakProjekt",
                "caption": "faktura-list.r_fakprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_fakKundeNummer",
                "caption": "faktura-list.r_fakkundenummer_caption",
                "bindTo": "Rechnungsort.Nummer",
                "width": "80px"
            }, {
                "id": "r_fakFirmenbez1",
                "caption": "faktura-list.r_fakfirmenbez1_caption",
                "bindTo": "RechnungsortAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_fakStrasse",
                "caption": "faktura-list.r_fakstrasse_caption",
                "bindTo": "RechnungsortAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_fakLand",
                "caption": "faktura-list.r_fakland_caption",
                "bindTo": "RechnungsortAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_fakOrt",
                "caption": "faktura-list.r_fakort_caption",
                "bindTo": "RechnungsortAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_fakWertNetto",
                "caption": "faktura-list.r_fakwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_fakWaehrung",
                "caption": "faktura-list.r_fakwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_fakAbschlagsfaktura",
                "caption": "faktura-list.r_fakabschlagsfaktura_caption",
                "bindTo": "IsAbschlagsfaktura",
                "width": "120px"
            }, {
                "id": "r_fakZahlungFaellig",
                "caption": "faktura-list.r_fakzahlungfaellig_caption",
                "bindTo": "ZahlungFaelligkeit",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_FakturaListUtils.onZahlungFaelligCellPrepared"
            }, {
                "id": "r_mahnstufe",
                "caption": "faktura-list.r_mahnstufe_caption",
                "bindTo": "Mahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_fakStatus",
                "caption": "faktura-list.r_fakstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_fakturaGridToolbarOptions",
                "optionsNameFQ": "r_fakturaGridToolbarOptions"
            },
            "caption": "faktura-list.r_fakturagrid_caption",
            "binding": {
                "dataContext": "$m_Faktura"
            },
            "dataModel": "$m_Faktura",
            "editUrl": "ERP/Verkauf/Faktura",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fakturaGrid",
            "options": {
                "optionsName": "r_fakturaGridOptions",
                "optionsNameFQ": "r_fakturaGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}