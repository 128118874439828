import { autoinject, computedFrom } from "aurelia-framework";
import { WebValidationResult } from '../../../framework-data/events';
import { ValidationExService } from '../../services/export';
import { ScopeContainer } from '../../../framework/base/classes/scope-container';
import { IdxPopupComponent } from '../../interfaces/dx-components';
import { SimpleWidgetCreatorService, ICommandData } from '../../../framework/forms/export';

@autoinject
export class ValidationInfo {
  private _callback: {(ok: boolean): void};
  private _result: boolean;

  constructor(
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _validationExService: ValidationExService
  ) {
    this._validationExService.validationInfo = this;
  }

  scopeContainer: ScopeContainer;
  validationResultList: WebValidationResult[];
  hasNoErrors: boolean = false;
  
  continueCommand: ICommandData = {
    id: "continueCommand",
    icon: "check",
    sortIndex: 101,
    isVisibleExpression: "hasNoErrors",
    title: "validation-info.continue",
    execute: async () => {
      this._result = true;
      this.popup.instance.hide();
    }
  };

  popup: IdxPopupComponent;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "auto",
    onHidden: () => {
      this._callback(this._result);
    }
  };

  bind() {    
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "validationInfo",
      scopeContainer: this.scopeContainer,
      options: this.popupOptions,
      caption: "validation-info.title",
      commands: [this.continueCommand]
    });
  }

  showPopup(validationResultList: WebValidationResult[], callback: {(ok: boolean): void}) {
    this.validationResultList = validationResultList;
    this._callback = callback;
    this._result = false;

    this.hasNoErrors = !validationResultList.some(i => i.IsError);

    this.popup.instance.show();
  }
}