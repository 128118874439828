import {
  autoinject
} from "aurelia-framework";
import {
  OnPeriodeRequest,
  OnPeriodeRequestEvent
} from "../../framework-data/events";
import { 
  RestService,
  WebEventService
} from "./../../framework/base/services/export";
import * as moment from "moment";

@autoinject
export class AngebotService {
  constructor(
    private rest: RestService,
    private webEvent: WebEventService
  ) { }

  async getGueltigBis(idAngebotsart: number, current: Date): Promise<Date> {
    if (!idAngebotsart) {
      return current;
    }

    const angebotsart = await this.rest.get({
      url: this.rest.getWebApiUrl(`ERP/Stammdaten/Angebotsart/${idAngebotsart}`),
      getOptions: {
        columns: ["VorbelegungGueltigBisPeriode"]
      }
    });

    if (!angebotsart || !angebotsart["VorbelegungGueltigBisPeriode"]) {
      return current;
    }

    const result: OnPeriodeRequest = await this.webEvent.execute(new OnPeriodeRequestEvent({
      Datum: moment().startOf("day").toDate(),
      Periode: angebotsart["VorbelegungGueltigBisPeriode"]
    }));

    if (!result) {
      return current;
    }

    return result.Ergebnis;
  }

  async hasAngebotsgruppePositionenZugeteilt(idAngebotsgruppe){
    const r = await this.rest.get({
      url: this.rest.getWebApiUrl(`ERP/Verkauf/Angebotsposition`),
      getOptions: {
        where: ["IdAngebotsgruppe", idAngebotsgruppe]
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}
