import { autoinject, transient } from "aurelia-framework";
import { RestService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IArbeitszeitLohnexportErstellen } from "../../../elements/arbeitszeit-lohnexport-erstellen/arbeitszeit-lohnexport-erstellen";

@autoinject
@transient()
export class ArbeitszeitLohnexportListUtils {
  constructor(
    private _restService: RestService) { }

  form: FormBase;

  bind(form: FormBase) {
    this.form = form;
  }

  showLohnexportErstellenPopupCommand: ICommandData = {
    id: "showLohnexportErstellenPopup",
    idCategory: "arbeitszeit",
    title: "lohnexport.lohnexport-erstellen",
    sortIndex: 10,
    icon: "plus",
    execute: async () => {
      const lohnexportPopup = this.form["r_lohnexportPopup"];
      lohnexportPopup.show({
        callback: async (arbeitszeitLohnexportErstellen: IArbeitszeitLohnexportErstellen) => {
          const r = await this._restService.post({
            url: this._restService.getApiUrl(`ERP/ArbeitszeitLohnexport/lohnexport`),
            data: {
              Id: arbeitszeitLohnexportErstellen.Id,
              Stichtag: arbeitszeitLohnexportErstellen.Stichtag,
            }
          });

          this.form.models.reloadAll();
        }
      });
    }
  }
}
