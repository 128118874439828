import * as fwx from "../../../../framework/forms/form-export";
import {
    BestellungsgruppeEditUtils
} from "./bestellungsgruppe-edit-utils";

@fwx.autoinject
export class BestellungsgruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BestellungsgruppeEditUtils: BestellungsgruppeEditUtils) {
        super(element, formBaseImport);
        this.id = "bestellungsgruppe-edit";
        this.title = "bestellungsgruppe-edit.bestellungsgruppe-edit_caption";
        this.addModel({
            "id": "$m_Bestellungsgruppe",
            "webApiAction": "ERP/Einkauf/Bestellungsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Bestellung.CanSave && !models.data.$m_Bestellung.HasStreckengeschaeftAuftrag && !models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Bestellungsgruppe.IdBestellung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_BestellungsgruppeEditUtils", $f_BestellungsgruppeEditUtils, "functions.$f_BestellungsgruppeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "bestellungsgruppe-edit.r_bezeichnung_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsgruppe",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Bestellungsgruppe.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "bestellungsgruppe-edit.r_posnr_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellungsgruppe",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Bestellungsgruppe.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}