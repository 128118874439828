import { autoinject, transient } from "aurelia-framework";
import { DynFelderService, IDynamischesFeld } from "./dyn-felder-service";
import { FormBase, ModelUtilsService, IHtmlEditorOptions, ICommandData, ValidationService } from "../../framework/forms/export";
import { IDynFelderDataServiceOptions } from "../interfaces/export";
import { DynFeldTyp } from "../enumerations/export";
import { GlobalizationService, DataSourceService, CustomEvent } from "../../framework/base/export";
import { EntitaetInfoService } from "./entitaet-info-service";
import { IDynFelderDataReloadRequestedEventArgs } from "../event-args/dyn-felder-data-reload-requested";
import { ScopeContainer } from '../../framework/base/classes/scope-container';
import { DxCustomizeService } from './dx-customize-service';
import { DynFeldContainer } from '../elements/dyn-feld-container/dyn-feld-container';

@autoinject
@transient()
export class DynFelderDataService {
  private _form: FormBase;
  private _options: IDynFelderDataServiceOptions;

  constructor(
    private _dynFelderService: DynFelderService,
    private _globalizationService: GlobalizationService,
    private _entitaetInfoService: EntitaetInfoService,
    private _dataSourceService: DataSourceService,
    private _modelUtilsService: ModelUtilsService,
    private _dxCustomizeService: DxCustomizeService,
    private _validationService: ValidationService,
    public onDynFelderDataReloadRequested: CustomEvent<IDynFelderDataReloadRequestedEventArgs>
  ) { }

  dynFeldList: IDynamischesFeld[];
  mainModel: any;

  register(form: FormBase, options: IDynFelderDataServiceOptions) {
    this._form = form;
    this._options = options;

    form.models.onLoaded.register(async r => {
      if (r.model.id == options.idMainModel && r.data) {
        await this.loadDynFeldList();
        if (!await this.loadDynFeldContainer()) {
          return;
        }

        this.mainModel = r.data;

        if (!r.data[r.model.keyProperty]) {
          this.loadVorbelegungen();
        }
      }
    });

    form.onEditorValueChanged.register(r => {
      if (r.binding && r.binding.dataContext == this._options.idMainModel) {
        const bindTo = r.binding.bindTo;

        if (bindTo == this._options.idArtPropertyName) {
          this.loadVorbelegungen();
        }
      }

      return Promise.resolve();
    });
  }

  async getDynFeldAnzeigeList(anzeigeort: string, isDefault: boolean): Promise<IDynamischesFeldAnzeige[]> {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return [];
    }

    const container = this._form.models.data[this._options.idMainModel]["DynamischesFeldContainer"];
    if (!container) {
      return [];
    }

    const elemente = {};
    container.DynamischesFeldEintraege.forEach(dynFeldEintrag => {
      elemente[dynFeldEintrag.IdDynamischesFeld] = dynFeldEintrag;
    });

    return this.getFilteredDynFeldList()
      .filter(feld => {
        return feld.DynamischesFeldZuEntitaeten[0].Anzeigeort == void 0 || feld.DynamischesFeldZuEntitaeten[0].Anzeigeort == ""
          ? isDefault === true
          : feld.DynamischesFeldZuEntitaeten[0].Anzeigeort === anzeigeort;
      })
      .map(feld => this.getDynFeldAnzeige(feld, container, elemente));
  }
  getDynFeldAnzeige(feld: IDynamischesFeld, container?: any, elemente?: any): IDynamischesFeldAnzeige {
    const editorType = this.getWidgetType(feld);
    const isHtmlEditor = /^htmlEditor$/.test(editorType);
    const isDxEditor = /^dx.*$/.test(editorType);
    const feldAnzeige: IDynamischesFeldAnzeige = {
      colClass: (feld.BreiteMD ? `col-md-${feld.BreiteMD}` : "")
        .concat(" ")
        .concat(feld.BreiteLG ? `col-lg-${feld.BreiteLG}` : ""),
      data: feld,
      editorType: editorType,
      name: null,
      options: null,
      useDxWidget: isDxEditor,
      useHtmlEditor: isHtmlEditor,
      isReadOnly: this.isReadOnly(feld),
      eintrag: null
    }

    if (isDxEditor) {
      feldAnzeige.name = editorType;
    }

    const addBinding = !!container
      && !!elemente;

    feldAnzeige.options = this.getWidgetOptions(feld, feldAnzeige, addBinding);

    if (addBinding) {
      feldAnzeige.eintrag = elemente[feld.Id];
      if (!feldAnzeige.eintrag) {
        feldAnzeige.eintrag = {
          IdDynamischesFeld: feld.Id
        };

        if (container.Id) {
          feldAnzeige.eintrag.IdDynamischesFeldContainer = container.Id;
        }

        if (editorType == "dxCheckBox") {
          feldAnzeige.eintrag.WertNummer = 0;
        }

        container.DynamischesFeldEintraege.push(feldAnzeige.eintrag);
      }
    }

    return feldAnzeige;
  }
  getDataSource(auswahllisteDatenquelle: string, idDynFeld: number, scopeContainer: ScopeContainer = null, returnStore?: boolean): any {
    scopeContainer = scopeContainer || this._form.scopeContainer;

    const action = (returnStore
      ? this._dataSourceService.createDataStore
      : this._dataSourceService.createDataSource).bind(this._dataSourceService);

    const filters = idDynFeld
      ? [{ webApiCustomKey: "idDynFeld", webApiCustomValue: idDynFeld }]
      : null;

    if (auswahllisteDatenquelle.startsWith("AEMDT")) {
      const tokens = auswahllisteDatenquelle.split(";");
      const entitaetInfo = this._entitaetInfoService.getEntitaetInfoByTypeName(tokens[1]);
      const hasVolltext = entitaetInfo.HasVolltext;

      return action(scopeContainer, {
        webApiAction: entitaetInfo.WebApiUrl,
        webApiColumns: ["Id", "DisplayText"],
        webApiOrderBy: entitaetInfo.WebApiOrderBy,
        webApiSearchtextEnabled: hasVolltext,
        filters: filters,
        keyProperty: "Id"
      });
    } else if (auswahllisteDatenquelle.startsWith("ADE")) {
      return action(scopeContainer, {
        webApiAction: "ERP/DynamischesFeld/DynamischeEntitaetElement",
        webApiColumns: ["Id", "DisplayText"],
        webApiOrderBy: [{ columnName: "SortNr", sortOrder: 0 }, { columnName: "DisplayText", sortOrder: 0 }],
        webApiWhere: ["IdDynamischeEntitaet", parseInt(auswahllisteDatenquelle.substring(4))],
        filters: filters,
        keyProperty: "Id"
      });
    } else {
      throw new Error(`${auswahllisteDatenquelle} not supported`);
    }
  }

  async loadDynFeldList() {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return;
    }

    this.dynFeldList = await this._dynFelderService.loadDynFelder(data.TypeName);
  }
  async loadDynFeldContainer() {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return false;
    }

    const id = this.getIdDynFeldContainer();
    let container;

    if (id) {
      container = await this._dynFelderService.loadDynFelderContainer(id);
    } else {
      container = {};
    }

    if (id != this.getIdDynFeldContainer()) {
      return false;
    }

    if (!container.DynamischesFeldEintraege) {
      container.DynamischesFeldEintraege = [];
    }

    data["DynamischesFeldContainer"] = container;
    this.onDynFelderDataReloadRequested.fire({});

    return true;
  }
  async loadVorbelegungen() {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return;
    }

    const container = this._form.models.data[this._options.idMainModel]["DynamischesFeldContainer"];
    if (!container) {
      return;
    }

    const elemente = {};
    container.DynamischesFeldEintraege.forEach(dynFeldEintrag => {
      elemente[dynFeldEintrag.IdDynamischesFeld] = dynFeldEintrag;
    });

    const vorbelegung = await this._dynFelderService.loadVorbelegungDynFelder({
      TypeName: data.TypeName,
      IdArt: this._options.idArtPropertyName ? data[this._options.idArtPropertyName] : null,
      IdVorgEntitaet: this._options.idVorgEntitaetPropertyName ? data[this._options.idVorgEntitaetPropertyName] : null,
      ItemList: this.getFilteredDynFeldList().map((feld) => {
        return {
          IdDynamischesFeld: feld.DynamischesFeldZuEntitaeten[0].IdDynamischesFeld
        };
      })
    });

    vorbelegung.ItemList.forEach(i => {
      let item = elemente[i.IdDynamischesFeld];

      if (!item) {
        item = {
          IdDynamischesFeld: i.IdDynamischesFeld
        };

        container.DynamischesFeldEintraege.push(item);
      } else {
        const hasValue = i.VorbIdWertAuswahlliste != void (0)
          || i.VorbWertText != void (0)
          || i.VorbWertDatum != void (0)
          || i.VorbWertNummer != void (0);

        if (!hasValue) {
          return;
        }
      }

      item.IdWertAuswahlliste = i.VorbIdWertAuswahlliste;
      item.WertText = i.VorbWertText;
      item.WertDatum = i.VorbWertDatum;
      item.WertNummer = i.VorbWertNummer;
    });

    this.onDynFelderDataReloadRequested.fire({});
  }

  private getFilteredDynFeldList(): IDynamischesFeld[] {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return [];
    }
    const idArt = this._options.idArtPropertyName ? data[this._options.idArtPropertyName] : "null";

    return this.dynFeldList.filter(feld => {
      return feld.DynamischesFeldZuEntitaeten.some(zu => {
        return !zu.IdArt || zu.IdArt == idArt;
      });
    });
  }
  private getIdDynFeldContainer(): number {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return null;
    }

    return data["IdDynamischesFeldContainer"];
  }
  private getWidgetType(feld: IDynamischesFeld): string {
    switch (feld.Typ) {
      case DynFeldTyp.text:
        return "dxTextBox";
      case DynFeldTyp.mehrzeiligerText:
        return "dxTextArea";
      case DynFeldTyp.datum:
        return "dxDateBox";
      case DynFeldTyp.nummer:
        return "dxNumberBox";
      case DynFeldTyp.checkbox:
        return "dxCheckBox";
      case DynFeldTyp.auswahlliste:
        return "dxSelectBox";
      case DynFeldTyp.formatiertenText:
        return "htmlEditor"
      default:
        return "dxTextBox";
    }
  }
  private getWidgetOptions(feld: IDynamischesFeld, feldAnzeige: IDynamischesFeldAnzeige, addBinding: boolean) {
    let options;

    switch (feld.Typ) {
      case DynFeldTyp.text: {
        options = <DevExpress.ui.dxTextBoxOptions>{
          bindingOptions: {
            value: "feld.eintrag.WertText"
          }
        };

        if (feld.LogikTyp == 20) {
          this._dxCustomizeService.addTelefon(options);
        } else if (feld.LogikTyp == 21) {
          this._dxCustomizeService.addHomepage(options);
        } else if (feld.LogikTyp == 22) {
          options["validators"] = [this._validationService.getValidator(
            null,
            "email",
            "erp.email",
            null,
          )];
        } else if (feld.LogikTyp == 24) {
          options["validators"] = [this._validationService.getValidator(
            null,
            "duration",
            "erp.duration",
            [{ name: "durationPeriodType", value: 2 }],
          )];
        }
        break;
      }
      case DynFeldTyp.mehrzeiligerText: {
        options = <DevExpress.ui.dxTextAreaOptions>{
          height: feld.Hoehe || "120px",
          bindingOptions: {
            value: "feld.eintrag.WertText"
          }
        };
        break;
      }
      case DynFeldTyp.datum: {
        options = <DevExpress.ui.dxDateBoxOptions>{
          displayFormat: this._globalizationService.getFormatterParser(feld.Format || "d"),
          bindingOptions: {
            value: "feld.eintrag.WertDatum"
          }
        };
        break;
      }
      case DynFeldTyp.nummer: {
        options = <DevExpress.ui.dxNumberBoxOptions>{
          format: this._globalizationService.getNumberFormat(feld.Format || "f0"),
          showClearButton: true,
          bindingOptions: {
            value: "feld.eintrag.WertNummer"
          }
        };
        break;
      }
      case DynFeldTyp.auswahlliste: {
        options = <DevExpress.ui.dxSelectBoxOptions>{
          valueExpr: "Id",
          displayExpr: "DisplayText",
          showClearButton: true,
          searchEnabled: true,
          dataSource: this.getDataSource(feld.AuswahllisteDatenquelle, feld.Id),
          bindingOptions: {
            value: "feld.eintrag.IdWertAuswahlliste"
          }
        }
        break;
      }
      case DynFeldTyp.checkbox: {
        options = <DevExpress.ui.dxCheckBoxOptions>{
          bindingOptions: {
            value: "feld.eintrag.WertNummer"
          }
        };
        break;
      }
      case DynFeldTyp.formatiertenText: {
        options = <IHtmlEditorOptions>{
          height: feld.Hoehe || "150px",
          showToolbar: true,
          isReadOnly: feldAnzeige.isReadOnly
        };

        if (feld.HasWertVorlagen) {
          options.commandData = <ICommandData[]>[{
            id: "vorlage",
            icon: "file-text-o",
            execute: (e) => {
              const htmlEditor = e.details ? e.details.htmlEditor : null;
              if (!htmlEditor) {
                return;
              }

              this._dynFelderService.showWertVorlageAuswahlPopup(feld.Id, htmlEditor);
            }
          }];
        }
        break;
      }
      default: {
        throw new Error(`${feld.Typ} not implementiert`);
      }
    }

    const data = this._form.models.data[this._options.idMainModel];
    const idArt = this._options.idArtPropertyName ? data[this._options.idArtPropertyName] : "null";

    var dynFeldZuEntitaet = feld.DynamischesFeldZuEntitaeten.filter(zu => {
        return !zu.IdArt || zu.IdArt == idArt;
      })[0] || null;

    if (dynFeldZuEntitaet != null && dynFeldZuEntitaet.IsPflicht) {
      options["validators"] = options["validators"] || [];
      options["validators"].push({ type: "required" });
    }

    if (addBinding) {
      options.readOnly = data && data.CanSave != undefined ? !data.CanSave : false;
      options.onValueChangedByUser = this.onValueChangedByUser.bind(this);

      if (feldAnzeige.isReadOnly) {
        options.readOnly = true;
      }
      if (this.canWertAendernReadOnly(feld)) {
        const onOptionChanged = (<DevExpress.ui.EditorOptions>options).onOptionChanged;
        options.onOptionChanged = (e) => {
          if (onOptionChanged) {
            onOptionChanged(e);
          }

          if (e.name == "readOnly") {
            this.updateCanWertAendernButtons(feld, feldAnzeige, e.component, options);
          }
        }

        let onInitialized = (<DevExpress.ui.EditorOptions>options).onInitialized;
        options.onInitialized = (e) => {
          if (onInitialized) {
            onInitialized(e);
            onInitialized = null;
          }

          this.updateCanWertAendernButtons(feld, feldAnzeige, e.component, options);
        }
      }
    } else {
      delete options.bindingOptions;
    }

    return options;
  }

  private isReadOnly(feld: IDynamischesFeld): boolean {
    return !!feld.DynamischesFeldZuEntitaeten
      && feld.DynamischesFeldZuEntitaeten.length > 0
      && feld.DynamischesFeldZuEntitaeten[0].IsReadOnly == true;
  }
  private canWertAendernReadOnly(feld: IDynamischesFeld): boolean {
    return !!feld.DynamischesFeldZuEntitaeten
      && feld.DynamischesFeldZuEntitaeten.length > 0
      && feld.DynamischesFeldZuEntitaeten[0].CanAenderWennDatensatzErledigt == true;
  }
  private updateCanWertAendernButtons(feld: IDynamischesFeld, feldAnzeige: IDynamischesFeldAnzeige, editor: DevExpress.ui.Editor, options: DevExpress.ui.EditorOptions) {
    const isReadOnly = editor.option("readOnly");
    const buttons: DevExpress.ui.dxTextEditorButton[] = editor.option("buttons") || [];
    const indexOf = buttons.findIndex(b => b.name == "updateValue");

    const dynFeldContainer: DynFeldContainer = options["DynFeldContainer"];
    if (!dynFeldContainer) {
      return;
    }

    if (isReadOnly) {
      if (indexOf < 0) {
        buttons.push({
          name: "updateValue",
          location: "after",
          options: {
            icon: "fa fa-pencil",
            disabled: false,
            stylingMode: "text",
            onClick: (e) => {
              dynFeldContainer.showWertAendern(feld, feldAnzeige);
            }
          }
        });
        editor.option("buttons", buttons);
      }
    } else {
      if (indexOf >= 0) {
        buttons.splice(indexOf, 1);
        editor.option("buttons", buttons);
      }
    }
  }

  private onValueChangedByUser(e) {
    const data = this._form.models.data[this._options.idMainModel];
    if (!data) {
      return;
    }

    if (this._modelUtilsService.isDirty(data)) {
      return;
    }

    this._modelUtilsService.setDirty(data)
  }
}

export interface IDynamischesFeldAnzeige {
  colClass: string;
  data: IDynamischesFeld;
  editorType: string;
  name: string;
  options: any;
  useDxWidget: boolean;
  useHtmlEditor: boolean;
  isReadOnly: boolean;
  eintrag: IDynamischesFeldEintrag;
}
export interface IDynamischesFeldEintrag {
  IdDynamischesFeldContainer?: number;
  IdDynamischesFeld: number;
  WertText?: string;
  WertNummer?: number;
  WertDatum?: Date;
  IdWertAuswahlliste?: number;
}
