import { autoinject, transient } from "aurelia-framework";
import { OnDeleteIdLieferscheinpositionListEvent, OnEvalLieferRechnungsort, OnEvalLieferRechnungsortEvent, OnImportAuftragInLieferschein, OnImportAuftragInLieferscheinEvent, OnLieferscheinAbschliessenEvent, OnLieferscheinFakturaStatusAendernEvent, OnLieferscheinGewichtAktualisieren, OnLieferscheinGewichtAktualisierenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { ContextMenu, FormBase, ICommandData } from "../../../../framework/forms/export";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { DynFelderDataService, FilialeService, FormCommandService, ValidationExService } from "../../../services/export";
import { KalkulationRefreshService } from "../../../services/kalkulation-refresh-service";
import { PersonService } from "../../../services/person-service";

@autoinject
@transient()
export class LieferscheinEditUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public dynFelderData: DynFelderDataService,
    private _webEventService: WebEventService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _filialeService: FilialeService,
    private _personService: PersonService,
    private _asyncService: AsyncService,
    private _localizationService: LocalizationService,
    private _formCommandService: FormCommandService,
    private _validationExService: ValidationExService) { }

  form: FormBase;

  fakturaStatusAendernCommand: ICommandData = {
    id: "fakturaStatusAendern",
    idCategory: "lieferschein",
    title: "lieferschein.faktura_status_aendern",
    icon: "bullseye",
    isVisibleExpression: "models.data.$m_Lieferschein.FakturaStatus == 3 && !models.data.$m_Lieferschein.CanSave",
    sortIndex: 998,
    execute: (e) => {
      const ctxMenu = new ContextMenu();
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("lieferschein.faktura_neu_erstellen"),
        execute: () => {
          this.changeFakturaStatus(1);
        }
      });
      ctxMenu.items.push({
        text: this._localizationService.translateOnce("lieferschein.faktura_nicht_notwendig"),
        execute: () => {
          this.changeFakturaStatus(2);
        }
      });

      ctxMenu.show(this._formCommandService.getCommandTarget(e.event));
    }
  };
  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "lieferschein",
    title: "lieferschein.abschliessen",
    icon: "check",
    isEnabledExpression: "models.data.$m_Lieferschein.CanSave && models.data.$m_Lieferschein.Status && models.data.$m_Lieferschein.Status.Typ === 0",
    sortIndex: 999,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Lieferschein.TypeName,
        this.form.models.data.$m_Lieferschein.Id,
        "ABSCHLIESSEN"
      );

      if (!validationResult) {
        return;
      }

      await this._webEventService.execute(new OnLieferscheinAbschliessenEvent({
        IdLieferschein: this.form.models.data["$m_Lieferschein"].Id
      }), true);

      return this.form.models.reloadAll();
    }
  };
  refreshGewichtCommand: ICommandData = {
    id: "refreshGewichte",
    idCategory: "gewicht",
    title: "lieferschein.gewicht_aktualisieren",
    icon: "cubes",
    isEnabledExpression: "models.data.$m_Lieferschein.CanSave && models.data.$m_Lieferschein.Id > 0",
    sortIndex: 10,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const onLieferscheinGewichtAktualisierenEvent = new OnLieferscheinGewichtAktualisierenEvent({
        IdLieferschein: this.form.models.data["$m_Lieferschein"].Id
      });

      const webEventResult: OnLieferscheinGewichtAktualisieren = await this._webEventService.execute(
        onLieferscheinGewichtAktualisierenEvent,
        true);

      return this.form.models.reloadAll();
    }
  };
  showImportAuftragPopupCommand: ICommandData = {
    id: "showImportAuftrag",
    icon: "plus",
    idCategory: "lieferschein",
    title: "lieferschein.auftrag_importieren",
    sortIndex: 100,
    isEnabledExpression: "models.data.$m_Lieferschein.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const auftragGrid: IdxDataGridComponent = this.form["r_auftragGrid"];
      if (auftragGrid && auftragGrid.instance) {
        await this._asyncService.convertToPromise(auftragGrid.instance.deselectAll());
        auftragGrid.instance.refresh();
      }

      this.form["r_auftragEditPopup"].instance.show();
    }
  };
  importAuftraegeCommand: ICommandData = {
    id: "importAuftraege",
    title: "lieferschein.mark_auftraege_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_auftragGrid"];

      const webEvent = new OnImportAuftragInLieferscheinEvent({
        IdLieferschein: this.form.models.data.$m_Lieferschein.Id,
        IdAuftragList: grid.instance.getSelectedRowKeys()
      });

      const webEventResult: OnImportAuftragInLieferschein = await this._webEventService.execute(webEvent, true);

      this.form["r_auftragEditPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  };
  doDeletePosCommand: ICommandData = {
    id: "$delete",
    icon: "times",
    title: "base.delete",
    tooltip: "base.delete_tooltip",
    sortIndex: 1001,
    isEnabledExpression: "models.data.$m_Lieferschein.CanDelete",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_lieferscheinpositionGrid"];
      const lieferscheinPosGridRowData = grid.instance.getSelectedRowsData();
      grid.instance.clearSelection();

      if (lieferscheinPosGridRowData.length == 0) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("lieferschein.fehler_keine_auswahl"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      if (lieferscheinPosGridRowData.some((c) => c.CanDelete == false)) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("lieferschein.fehler_loeschen"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      for (const lieferscheinposition of lieferscheinPosGridRowData) {
        const validationResult = await this._validationExService.executeServerValidation(
          lieferscheinposition.TypeName,
          lieferscheinposition.Id,
          "LOESCHEN"
        );

        if (!validationResult) {
          return;
        }
      }

      const idList = lieferscheinPosGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnDeleteIdLieferscheinpositionListEvent({
        IdLieferscheinpositionList: idList
      }), true);

      this.form.models.reloadAll();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const kunde = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_kunde'] .dx-searchbox input");
      if (!kunde) {
        return;
      }

      kunde.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Lieferschein",
      idVorgEntitaetPropertyName: "IdKunde"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Lieferschein",
      kalkulationRef: "r_kalkulation",
      editPopups: ["lieferscheinpositionEditPopup", "lieferscheingruppeEditPopup"]
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_LieferscheinpositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.editPopups.onEditPopupHidden.register((r) => {
      if (r.hasDataReloaded) {
        return Promise.resolve();
      }

      if (r.editPopup.id == "lieferscheinpositionEditPopup") {
        form["r_lieferscheingruppeGrid"].instance.refresh();
      } else if (r.editPopup.id == "lieferscheingruppeEditPopup") {
        form["r_lieferscheinpositionGrid"].instance.refresh();
      }

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Lieferschein") {
        if (args.binding.bindTo === "IdFirma") {
          if (args.value) {
            const model = form.models.data["$m_Lieferschein"];
            if (model) {
              model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);
            }
          }
        }
      }
    });
  }

  async onKundeChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    const onEvalLieferRechnungsortEvent = new OnEvalLieferRechnungsortEvent({ IdKunde: e.detail.value });
    const onEvalLiefeRechnungsortData: OnEvalLieferRechnungsort = await this._webEventService.execute(onEvalLieferRechnungsortEvent);

    this.form["r_lieferort"].setGeschaeftpartnerById(onEvalLiefeRechnungsortData.IdLieferort);
    this.form["r_rechnungsort"].setGeschaeftpartnerById(onEvalLiefeRechnungsortData.IdRechnungsort);
  }
  onKundeAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    const idKunde = this.form.models.data.$m_Lieferschein.IdKunde;
    if (idKunde == this.form.models.data.$m_Lieferschein.IdLieferort) {
      if (this.form.models.data.$m_Lieferschein.IdLieferortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Lieferschein.IdLieferortAnsprechperson = idPerson;
      }

      this.onLieferortAnsprechpersonChanged({
        detail: {
          value: idPerson
        }
      });
    }

    if (idKunde == this.form.models.data.$m_Lieferschein.IdRechnungsort) {
      if (this.form.models.data.$m_Lieferschein.IdRechnungsortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Lieferschein.IdRechnungsortAnsprechperson = idPerson;
      }
    }
  }
  async onLieferortAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    this.form.models.data.$m_Lieferschein.LieferortTelefon = await this._personService.getPersonTelefon(idPerson);
  }
  async changeFakturaStatus(fakturaStatus) {
    await this._webEventService.execute(new OnLieferscheinFakturaStatusAendernEvent({
      IdLieferschein: this.form.models.data.$m_Lieferschein.Id,
      Status: fakturaStatus
    }), true);

    this.form.reloadIfServerHasNewVersion();
  }
}
