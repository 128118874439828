import * as fwx from "../../../../framework/forms/form-export";
import {
    AuftragsgruppeEditUtils
} from "./auftragsgruppe-edit-utils";

@fwx.autoinject
export class AuftragsgruppeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_AuftragsgruppeEditUtils: AuftragsgruppeEditUtils) {
        super(element, formBaseImport);
        this.id = "auftragsgruppe-edit";
        this.title = "auftragsgruppe-edit.auftragsgruppe-edit_caption";
        this.addModel({
            "id": "$m_Auftragsgruppe",
            "webApiAction": "ERP/Verkauf/Auftragsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Auftrag.CanSave",
            "filters": []
        });
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Auftragsgruppe.IdAuftrag",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_AuftragsgruppeEditUtils", $f_AuftragsgruppeEditUtils, "functions.$f_AuftragsgruppeEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "auftragsgruppe-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Auftragsgruppe",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Auftragsgruppe.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "auftragsgruppe-edit.r_posnr_caption",
            "binding": {
                "dataContext": "$m_Auftragsgruppe",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Auftragsgruppe.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        super.onConstructionFinished();
    }
}