import { autoinject, bindable, OverrideContext, observable } from "aurelia-framework";
import { IListViewOptions, ListView } from "../../../../framework/forms/elements/list-view/export";
import { DataSourceService, ScopeContainer, LocationService } from "../../../../framework/base/export";
import { PopupInfoService } from "../../../../framework/forms/export";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class ZuletztGekaufteArtikel {
  private timeout: any;

  constructor(
    private dataSource: DataSourceService,
    private location: LocationService,
    private popupInfo: PopupInfoService,
    private eventAggregator: EventAggregator
  ) {}

  @bindable @observable data;
  @bindable @observable idKunde;
  
  scopeContainer: ScopeContainer;

  zuletztArtikelListView: ListView;
  zuletztArtikelListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.addArtikel(e.item);
    }
  }

  isVisible: boolean = false;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });

    this.zuletztArtikelListViewOptions.dataSource = this.dataSource.createDataSource(
      this.scopeContainer, {
        webApiAction: "ERP/Verkauf/Lieferscheinposition",
        webApiWhere: [["Lieferschein.Status.Typ", 1], ["MengeIstVerpEH", ">", 0], ["IsVariablerSetartikel", false], ["IsPositionStuecklistenkomponente", false]],
        webApiExpand: {
          Artikel: {
            columns: ["Id", "Nummer"]
          },
          Lieferschein: {
            columns: ["Id", "Versanddatum"]
          },
          Einheit: {
            columns: ["Id", "IdEinheit"],
            expand: {
              Einheit: {
                columns: ["Id", "Code"]
              }
            }
          }
        },
        webApiOrderBy: [{columnName: "Lieferschein.Versanddatum", sortOrder: 1}]
      }, {
        canLoad: () => {
          return this.idKunde
            && this.data
            && (this.data.TypeName == "TIP.ERP.Business.Entitaeten.Verkauf.Auftrag" || this.data.TypeName == "TIP.ERP.Business.Entitaeten.Verkauf.Angebot");
        },
        getCustomWhere: () => {
          return ["Lieferschein.Kunde.Id", this.idKunde];
        },
        resultInterceptor: (data) => {
          this.isVisible = data.count > 0;
          return data;
        }
      });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idKundeChanged() {
    this.refreshListView();
  }
  dataChanged() {
    this.refreshListView();
  }
  addArtikel(item) {
    const idData = this.data.Id;
    const idLieferscheinposition = item.Id;

    const args = {
      idLieferscheinposition: idLieferscheinposition,
      isHandled: false
    };

    this.eventAggregator.publish("infobar:zuletzt-verkaufte-artikel:addArtikel", args);
  }

  private refreshListView() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      if (this.zuletztArtikelListView) {
        this.zuletztArtikelListView.refresh(false);
      }
    }, 100);
  }
}
