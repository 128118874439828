import { autoinject } from "aurelia-framework";
import { LocalizationService, RestService } from "../../framework/base/export";
import { AsyncService } from "../../framework/base/services/async-service";
import { DmsViewer } from "../elements/dms-viewer/dms-viewer";
import { IDmsViewerShowOptions } from "../interfaces/dms-viewer-show-options";
import { IFormCommandExecuteOptions } from "../interfaces/export";

@autoinject
export class DmsViewerService {
  constructor(
    private _restService: RestService,
    private _localizationService: LocalizationService,
    private _asyncService: AsyncService) { }

  dmsViewer: DmsViewer;

  async deleteDokument(idDokument: number | string, showConfirmation: boolean = true): Promise<any> {
    if (showConfirmation) {
      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("base.sure_delete_question");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }
    }

    return this._restService.delete({
      id: idDokument,
      url: this._restService.getWebApiUrl("ERP/DMS/Dokument")
    });

  }
  saveDokument(dmsDokument: any): Promise<any> {
    return this._restService.post({
      url: this._restService.getWebApiUrl("ERP/DMS/Dokument"),
      data: dmsDokument,
      increaseLoadingCount: true
    });
  }

  saveDokumentartToDokument(idDokument, idDokumentenart): Promise<any> {
    return this._restService.post({
      url: this._restService.getWebApiUrl("ERP/DMS/Dokument"),
      data: {
        Id: idDokument,
        IdDokumentenart: idDokumentenart
      }
    });
  }

  getAnzahlDokumente(idDokumentContainer): Promise<string> {
    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/DMS/DokumentContainer")}/${idDokumentContainer}`,
      getOptions: {
        columns: ["AnzahlDokumente"]
      }
    }).then((r) => {
      if (r && r.AnzahlDokumente) {
        return r.AnzahlDokumente.toString();
      } else {
        return "";
      }
    });
  }

  showDmsViewerPopupFromFormCommand(e: IFormCommandExecuteOptions) {
    this.showDmsViewerPopup({
      id: e.modelData.Id,
      idDokumentContainer: e.modelData.IdDokumentContainer,
      typeName: e.modelData.TypeName,
      callback: e.options.callBack
    });
  }

  showDmsViewerPopup(options: IDmsViewerShowOptions) {
    this.dmsViewer.show(options);
  }
}
