import { IAuGridCell } from 'aurelia-dashboard-grid';
import { autoinject, PLATFORM } from "aurelia-framework";
import { StatistikVerkaufWertTyp, StatistikVerkaufZeitraumTyp } from '../../../framework-data/events';
import { IWidget } from '../interfaces/widget';
import { IWidgetModel } from '../interfaces/widget-model';
import { IAuswertungSettings } from '../widgets/auswertung/auswertung-settings';
import { IEntitaetTagsSettings } from '../widgets/entitaet-tags/entitaet-tags-settings';
import { IKennzahlenSettings } from '../widgets/kennzahlen/kennzahlen-settings';
import { IOffeneAuftraegeSettings } from '../widgets/offene-auftraege/offene-auftraege-settings';
import { ITopArtikelSettings } from '../widgets/top-artikel/top-artikel-settings';
import { ITopKundenSettings, TopKundenGPArt } from '../widgets/top-kunden/top-kunden-settings';
import { IUmsatzAktuellSettings } from '../widgets/umsatz-aktuell/umsatz-aktuell-settings';
import { IUmsatzLandSettings } from '../widgets/umsatz-land/umsatz-land-settings';
import { IUmsatzMonatSettings } from '../widgets/umsatz-monat/umsatz-monat-settings';
import { SupportedDashboardTyp } from './../interfaces/widget';
import { IStatistikGruppiertSettings } from './../widgets/statistik-gruppiert/statistik-gruppiert-settings';

@autoinject
export class WidgetService {
  constructor() {
    this.registerWidgets();
  }

  widgets: IWidget[] = [];

  getCellsFromDefinition(definition: string, mainModel?: any): IAuGridCell[] {
    if (definition) {
      return (<any[]>JSON.parse(definition)).map(c => {
        const widget = this.widgets.find(w => w.viewModel == c.viewModel);
        if (!widget) {
          return null;
        }

        const settings = Object.assign({}, widget.settings || {}, c.settings || {});

        return <IAuGridCell>{
          x: c.x,
          y: c.y,
          width: c.width,
          height: c.height,
          viewModel: c.viewModel,
          minHeight: widget.minHeight,
          minWidth: widget.minWidth,
          model: <IWidgetModel>{
            isDesignMode: true,
            settings: settings,
            mainModel: mainModel,
            widget: widget
          }
        }
      }).filter(c => !!c);
    } else {
      return [];
    }
  }

  getWidgets(dashboardTyp: DashboardTyp) {
    return this.widgets
      .filter(w => {
        const supportedDashboardTyp = (dashboardTyp == DashboardTyp.Allgemein ? SupportedDashboardTyp.Allgemein : (dashboardTyp == DashboardTyp.Geschaeftspartner
          ? SupportedDashboardTyp.Geschaeftspartner
          : (dashboardTyp == DashboardTyp.Artikel
            ? SupportedDashboardTyp.Artikel
            : 0)));

        return w.supportedDashboardTyp.includes(supportedDashboardTyp);
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  private registerWidgets() {
    this.widgets.push({
      title: "Kennzahlen",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/kennzahlen/kennzahlen"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/kennzahlen/kennzahlen-settings"),
      settings: <IKennzahlenSettings>{
        ueberschrift: "Kennzahlen",
        showNewsletterAbmeldungen: true,
        showNewsletterAktivierungen: true,
        showOffeneAngebote: true,
        showOffeneAuftraege: true,
        showOffeneBestellung: true,
        showOffeneEingangsrechnungen: true,
        showOffeneFakturen: true,
        showOffeneKundenreklamationen: true,
        showOffeneLieferscheine: true,
        showOffenePosten: true,
        showOffeneProduktionen: true,
        showOffeneWarenuebernahmen: true
      },
      minWidth: 4,
      minHeight: 16,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Geschaeftspartner, SupportedDashboardTyp.Artikel]
    });

    this.widgets.push({
      title: "Top Artikel",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/top-artikel/top-artikel"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/top-artikel/top-artikel-settings"),
      settings: <ITopArtikelSettings>{
        anzahl: 10
      },
      minWidth: 4,
      minHeight: 12,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Geschaeftspartner]
    });

    this.widgets.push({
      title: "Top Kunden/Lieferanten",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/top-kunden/top-kunden"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/top-kunden/top-kunden-settings"),
      settings: <ITopKundenSettings>{
        anzahl: 10,
        art: TopKundenGPArt.Kunde
      },
      minWidth: 4,
      minHeight: 12,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Artikel]
    });

    this.widgets.push({
      title: "Umsatz aktuell",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-aktuell/umsatz-aktuell"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-aktuell/umsatz-aktuell-settings"),
      settings: <IUmsatzAktuellSettings>{
        showDB: true,
        showAuftragseingang: true,
        showNetto: true,
        standard: StatistikVerkaufWertTyp.Netto
      },
      minWidth: 2,
      minHeight: 12,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Geschaeftspartner, SupportedDashboardTyp.Artikel]
    });

    this.widgets.push({
      title: "Umsatz Land",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-land/umsatz-land"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-land/umsatz-land-settings"),
      settings: <IUmsatzLandSettings>{
        showNettoJahr: true,
        showNettoYTD: true,
        standard: StatistikVerkaufZeitraumTyp.YTD
      },
      minWidth: 3,
      minHeight: 10,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Artikel]
    });

    this.widgets.push({
      title: "Umsatz Monat",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-monat/umsatz-monat"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/umsatz-monat/umsatz-monat-settings"),
      settings: <IUmsatzMonatSettings>{
        showAuftragseingang: true,
        showDB: true,
        showEinsatz: true,
        showNetto: true,
        showNettoMitForecast: true,
        standard: StatistikVerkaufWertTyp.Netto
      },
      minWidth: 4,
      minHeight: 8,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Geschaeftspartner, SupportedDashboardTyp.Artikel]
    });

    this.widgets.push({
      title: "Entität mit Tag",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/entitaet-tags/entitaet-tags"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/entitaet-tags/entitaet-tags-settings"),
      settings: <IEntitaetTagsSettings>{
        nurAktiveDatensaetze: false
      },
      minWidth: 4,
      minHeight: 8,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein]
    });

    this.widgets.push({
      title: "Meine Aufgaben",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/aufgabe-tags/aufgabe-tags"),
      settings: {},
      minWidth: 4,
      minHeight: 12,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein]
    });

    this.widgets.push({
      title: "Zu liefernde Aufträge in 5 Tagen",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/offene-auftraege/offene-auftraege"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/offene-auftraege/offene-auftraege-settings"),
      settings: <IOffeneAuftraegeSettings>{},
      minWidth: 4,
      minHeight: 18,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein]
    });

    this.widgets.push({
      title: "Statistik gruppiert",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/statistik-gruppiert/statistik-gruppiert"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/statistik-gruppiert/statistik-gruppiert-settings"),
      settings: <IStatistikGruppiertSettings>{
        anzahl: 5,
        zeitraumTyp: StatistikVerkaufZeitraumTyp.YTD
      },
      minWidth: 4,
      minHeight: 12,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein, SupportedDashboardTyp.Geschaeftspartner, SupportedDashboardTyp.Artikel]
    });
    this.widgets.push({
      title: "Auswertung",
      viewModel: PLATFORM.moduleName("erp/dashboard/widgets/auswertung/auswertung"),
      settingViewModel: PLATFORM.moduleName("erp/dashboard/widgets/auswertung/auswertung-settings"),
      settings: <IAuswertungSettings>{},
      minWidth: 4,
      minHeight: 8,
      supportedDashboardTyp: [SupportedDashboardTyp.Allgemein]
    });
  }
}

export enum DashboardTyp {
  Allgemein = 0,
  Geschaeftspartner = 1,
  Artikel = 2
}
