import * as fwx from "../../../../framework/forms/form-export";
import {
    ZahlungEditUtils
} from "./zahlung-edit-utils";

@fwx.autoinject
export class ZahlungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ZahlungEditUtils: ZahlungEditUtils) {
        super(element, formBaseImport);
        this.id = "zahlung-edit";
        this.title = "zahlung-edit.zahlung-edit_caption";
        this.addModel({
            "id": "$m_Zahlung",
            "webApiAction": "ERP/Verkauf/Zahlung",
            "webApiExpand": {
                'Firma': null,
                'Faktura': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                },
                'Eingangsrechnung': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                },
                'KassaBeleg': {
                    'columns': [
                        'Id',
                        'Nummer',
                        'Datum'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Firma",
            "webApiAction": "ERP/Stammdaten/Firma",
            "webApiExpand": {
                'Waehrung': null,
                'Adresse': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addFunction("$f_ZahlungEditUtils", $f_ZahlungEditUtils, "functions.$f_ZahlungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "zahlung-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Zahlung.Faktura != null || models.data.$m_Zahlung.Eingangsrechnung != null",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Zahlung.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zahlungTyp",
            "filters": [],
            "caption": "zahlung-edit.r_zahlungtyp_caption",
            "isReadOnlyExpression": "models.data.$m_Zahlung.Faktura != null || models.data.$m_Zahlung.Eingangsrechnung != null",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_Zahlung.Typ"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_zahlungTyp",
            "options": {
                "optionsName": "r_zahlungTypOptions",
                "optionsNameFQ": "r_zahlungTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zahlungsart",
            "filters": [],
            "caption": "zahlung-edit.r_zahlungsart_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "IdZahlungsart",
                "bindToFQ": "models.data.$m_Zahlung.IdZahlungsart"
            },
            "validationRules": [],
            "id": "r_zahlungsart",
            "options": {
                "optionsName": "r_zahlungsartOptions",
                "optionsNameFQ": "r_zahlungsartOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "zahlung-edit.r_fakturanr_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Faktura.Nummer",
                "bindToFQ": "models.data.$m_Zahlung.Faktura.Nummer",
                "propertyPrefix": "Faktura"
            },
            "validationRules": [],
            "id": "r_fakturaNr",
            "options": {
                "optionsName": "r_fakturaNrOptions",
                "optionsNameFQ": "r_fakturaNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlung-edit.r_fakturadatum_caption",
            "isReadOnly": true,
            "isReadOnlyExpression": "models.data.$m_Zahlung.Faktura.Nummer",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Faktura.Datum",
                "bindToFQ": "models.data.$m_Zahlung.Faktura.Datum",
                "propertyPrefix": "Faktura"
            },
            "validationRules": [],
            "id": "r_fakturaDatum",
            "options": {
                "optionsName": "r_fakturaDatumOptions",
                "optionsNameFQ": "r_fakturaDatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "zahlung-edit.r_eingangsrechnungnr_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Eingangsrechnung.Nummer",
                "bindToFQ": "models.data.$m_Zahlung.Eingangsrechnung.Nummer",
                "propertyPrefix": "Eingangsrechnung"
            },
            "validationRules": [],
            "id": "r_eingangsrechnungNr",
            "options": {
                "optionsName": "r_eingangsrechnungNrOptions",
                "optionsNameFQ": "r_eingangsrechnungNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlung-edit.r_eingangsrechnungdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Eingangsrechnung.Datum",
                "bindToFQ": "models.data.$m_Zahlung.Eingangsrechnung.Datum",
                "propertyPrefix": "Eingangsrechnung"
            },
            "validationRules": [],
            "id": "r_eingangsrechnungDatum",
            "options": {
                "optionsName": "r_eingangsrechnungDatumOptions",
                "optionsNameFQ": "r_eingangsrechnungDatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "zahlung-edit.r_kassabelegnr_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "KassaBeleg.Nummer",
                "bindToFQ": "models.data.$m_Zahlung.KassaBeleg.Nummer",
                "propertyPrefix": "KassaBeleg"
            },
            "validationRules": [],
            "id": "r_kassaBelegNr",
            "options": {
                "optionsName": "r_kassaBelegNrOptions",
                "optionsNameFQ": "r_kassaBelegNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlung-edit.r_kassabelegdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "KassaBeleg.Datum",
                "bindToFQ": "models.data.$m_Zahlung.KassaBeleg.Datum",
                "propertyPrefix": "KassaBeleg"
            },
            "validationRules": [],
            "id": "r_kassaBelegDatum",
            "options": {
                "optionsName": "r_kassaBelegDatumOptions",
                "optionsNameFQ": "r_kassaBelegDatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "zahlung-edit.r_belegnr_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "BelegNr",
                "bindToFQ": "models.data.$m_Zahlung.BelegNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_belegNr",
            "options": {
                "optionsName": "r_belegNrOptions",
                "optionsNameFQ": "r_belegNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlung-edit.r_belegdatum_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Belegdatum",
                "bindToFQ": "models.data.$m_Zahlung.Belegdatum"
            },
            "validationRules": [],
            "id": "r_belegdatum",
            "options": {
                "optionsName": "r_belegdatumOptions",
                "optionsNameFQ": "r_belegdatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "zahlung-edit.r_betrag_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Betrag",
                "bindToFQ": "models.data.$m_Zahlung.Betrag"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_betrag",
            "options": {
                "optionsName": "r_betragOptions",
                "optionsNameFQ": "r_betragOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "zahlung-edit.r_skonto_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Skonto",
                "bindToFQ": "models.data.$m_Zahlung.Skonto"
            },
            "validationRules": [],
            "id": "r_skonto",
            "options": {
                "optionsName": "r_skontoOptions",
                "optionsNameFQ": "r_skontoOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "zahlung-edit.r_waehrung_caption",
            "isReadOnlyExpression": "models.data.$m_Zahlung.Faktura != null || models.data.$m_Zahlung.Eingangsrechnung != null",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Zahlung.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "zahlung-edit.r_zahlungsgrund_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "Zahlungsgrund",
                "bindToFQ": "models.data.$m_Zahlung.Zahlungsgrund"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_zahlungsgrund",
            "options": {
                "optionsName": "r_zahlungsgrundOptions",
                "optionsNameFQ": "r_zahlungsgrundOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "zahlung-edit.r_betragfirma_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "BetragWaehrungFirma",
                "bindToFQ": "models.data.$m_Zahlung.BetragWaehrungFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_betragFirma",
            "options": {
                "optionsName": "r_betragFirmaOptions",
                "optionsNameFQ": "r_betragFirmaOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "zahlung-edit.r_skontofirma_caption",
            "binding": {
                "dataContext": "$m_Zahlung",
                "bindTo": "SkontoWaehrungFirma",
                "bindToFQ": "models.data.$m_Zahlung.SkontoWaehrungFirma"
            },
            "validationRules": [],
            "id": "r_skontoFirma",
            "options": {
                "optionsName": "r_skontoFirmaOptions",
                "optionsNameFQ": "r_skontoFirmaOptions"
            }
        });
        super.onConstructionFinished();
    }
}