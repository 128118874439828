import { autoinject, transient } from "aurelia-framework";
import { OnNewsletterTestVersendenEvent, OnNewsletterVersendenEvent } from '../../../../framework-data/events';
import { LocalizationService } from '../../../../framework/base/export';
import { WebEventService } from '../../../../framework/base/services/web-event-service';
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { ExcelExportService } from "../../../../framework/forms/services/excel-export-service";
import { IdxPopupComponent, IdxSelectBoxComponent, IdxTextBoxComponent } from '../../../interfaces/export';

@autoinject
@transient()
export class NewsletterEditUtils {
  constructor(
    private _localization: LocalizationService,
    private _webEventService: WebEventService,
    private _excelExportService: ExcelExportService
  ) { }

  form: FormBase;

  layoutOeffnenCommand: ICommandData = {
    id: "layoutOeffnen",
    title: "newsletter.layout_oeffnen",
    sortIndex: 49,
    icon: "newspaper-o",
    idCategory: "newsletter",
    isVisibleExpression: "!!models.data.$m_Newsletter._NewsletterUrl",
    execute: async () => {
      window.open(this.form.models.data.$m_Newsletter._NewsletterUrl, "_blank");
    }
  }
  versendenCommand: ICommandData = {
    id: "versenden",
    title: "newsletter.versenden",
    icon: "paper-plane",
    sortIndex: 51,
    idCategory: "newsletter",
    isEnabledExpression: "models.data.$m_Newsletter.CanSave && models.data.$m_Newsletter.Status.Typ == 0",
    execute: async () => {
      const empfaenger: IdxSelectBoxComponent = this.form["r_empfaenger"];
      const idEmpfaenger = empfaenger.instance.option("value");

      if (!idEmpfaenger) {
        DevExpress.ui.notify(
          this._localization.translateOnce("newsletter.empfaenger_auswaehlen"),
          "error",
          3000);

        return;
      }

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      await this._webEventService.execute(new OnNewsletterVersendenEvent({
        IdNewsletter: this.form.models.data.$m_Newsletter.Id
      }), true);

      this.form.models.reloadAll();
    }
  }
  testVersendenCommand: ICommandData = {
    id: "testVersenden",
    title: "newsletter.test_versenden",
    sortIndex: 50,
    idCategory: "newsletter",
    isEnabledExpression: "models.data.$m_Newsletter.CanSave && models.data.$m_Newsletter.Status.Typ == 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const popup: IdxPopupComponent = this.form["r_testMailPopup"];
      popup.instance.show();
    }
  }
  testVersendenNowCommand: ICommandData = {
    id: "testVersendenNow",
    title: "newsletter.test_versenden",
    execute: async () => {
      const emailTextBox: IdxTextBoxComponent = this.form["r_testMailEmpfaenger"];
      const email = emailTextBox.instance.option("value");

      if (!email) {
        DevExpress.ui.notify(this._localization.translateOnce("newsletter.email_erfassen"), "error", 3000);
        return;
      }

      await this._webEventService.execute(new OnNewsletterTestVersendenEvent({
        IdNewsletter: this.form.models.data.$m_Newsletter.Id,
        Email: email
      }), true);

      DevExpress.ui.notify(this._localization.translateOnce("newsletter.test_email_wird_versandt"), "success", 3000);

      const popup: IdxPopupComponent = this.form["r_testMailPopup"];
      popup.instance.hide();
    }
  }
  doExportGridCommand: ICommandData = {
    id: "doExportGrid",
    idCategory: "statistik",
    title: "erp-statistik.export-grid",
    icon: "file-excel-o",
    sortIndex: 101,
    execute: () => {
      this._excelExportService.exportDataGrid({
        component: this.form["r_versGrid"].instance,
        selectedRowsOnly: false
      });
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }
}
