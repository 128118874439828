import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, observable, TaskQueue } from "aurelia-framework";
import { RestService } from '../../../framework/base/export';
import { ModelUtilsService } from '../../../framework/forms/export';
import { IdxPopoverComponent, IdxTagboxComponent } from '../../interfaces/export';
import { TagService } from '../../services/tag-service';

@autoinject
export class Tags {
  private _tagRefreshedSubscription: Subscription;

  constructor(
    private _tagService: TagService,
    private _modelUtilsService: ModelUtilsService,
    private _restService: RestService,
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue) { }

  @bindable @observable mainModel: any;
  @bindable webApiAction: string;

  tagList: any[];
  value: any[];

  currentItem: any;

  farben = ["#E5213B", "#FD4E13", "#E48A00", "#D5AE00",
    "#A4CF30", "#4ECC5D", "#31B19A", "#159DDC", "#2B78DC",
    "#7A6FF0", "#9E4CDF", "#DF4CDF", "#E73790", "#FB789A",
    "#7F979B", "#917F9B"
  ];

  tagBox: IdxTagboxComponent;
  tagBoxOptions: DevExpress.ui.dxTagBoxOptions = {
    acceptCustomValue: true,
    valueExpr: "Id",
    displayExpr: "Bezeichnung",
    showSelectionControls: true,
    searchEnabled: true,
    searchExpr: "Bezeichnung",
    placeholder: "Hier können Sie Tags auswählen",
    itemTemplate: "itemTemplate",
    tagTemplate: "tagTemplate",
    onCustomItemCreating: (e) => {
      const promise = this._tagService.createTag(e.text, this.mainModel.TypeName);
      e.customItem = promise;

      promise.then(r => {
        const exists = this.tagList.some(t => t.Id == r.Id);
        if (exists) {
          return;
        }

        this.tagList = [...this.tagList, r];
      });
    },
    onValueChangedByUser: (e) => {
      const values: number[] = e.value || [];
      this.updateTagContainer(values);
    },
    bindingOptions: {
      items: "tagList",
      value: "value"
    }
  };

  popover: IdxPopoverComponent;
  popoverOptions: DevExpress.ui.dxPopoverOptions = {
    contentTemplate: "contentTemplate",
  };
  bezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    maxLength: 100,
    bindingOptions: {
      value: "currentItem.Bezeichnung"
    }
  };
  farbeOptions: DevExpress.ui.dxColorBoxOptions = {
    bindingOptions: {
      value: "currentItem.Farbe"
    }
  };
  saveSettingsOptions: DevExpress.ui.dxButtonOptions = {
    text: "Speichern",
    onClick: async () => {
      if (!this.currentItem.Bezeichnung) {
        DevExpress.ui.notify(
          "Bitte erfassen Sie eine Bezeichnung",
          "error",
          3000
        );
        return;
      }
      if (!this.currentItem.Farbe) {
        DevExpress.ui.notify(
          "Bitte wählen Sie eine Farbe aus",
          "error",
          3000
        );
        return;
      }

      await this._tagService.updateTag(
        this.currentItem.Id,
        this.currentItem.Bezeichnung,
        this.currentItem.Farbe
      );

      this.popover.instance.hide();
    }
  };

  bind() {
    this.mainModelChanged();

    this._tagRefreshedSubscription = this._eventAggregator.subscribe("tag:refreshed", () => {
      //DX mit 19.2. sollte es nicht mehr notwendig sein, den value auf leer zu setzen
      const oldValues = this.value;
      this.value = [];
      this.loadTags();

      this._taskQueue.queueMicroTask(() => {
        this.value = oldValues;
      });
    });
  }
  unbind() {
    this._tagRefreshedSubscription.dispose();
    this._tagRefreshedSubscription = null;
  }

  async mainModelChanged() {
    await this.loadTags();
    await this.loadData();
  }

  onContextMenu(e: any, data) {
    e.stopPropagation();
    e.preventDefault();

    this.currentItem = Object.assign({}, data);
    this.popover.instance.show(e.target);
  }
  onTagDeleteClick(e: Event, data) {
    e.stopPropagation();
    e.preventDefault();

    const values: number[] = this.tagBox.instance.option("value");
    const indexOf = values.indexOf(data.Id);
    if (indexOf < 0) {
      return;
    }
    values.splice(indexOf, 1);
    this.tagBox.instance.option("value", values);

    this.updateTagContainer(values);
  }
  onColorClick(e: CustomEvent) {
    this.currentItem.Farbe = e.detail.farbe;
  }

  private loadTags() {
    if (!this.mainModel) {
      this.tagList = [];
      return;
    }

    this.tagList = this._tagService.getTagList(this.mainModel.TypeName);
  }
  private async loadData(increaseLoadingCount: boolean = false) {
    if (!this.mainModel) {
      this.value = [];
      return;
    }

    const mainModel = this.mainModel;
    if (mainModel.IdTagContainer) {
      mainModel.TagContainer = await this._tagService.getTagContainer(mainModel.IdTagContainer, increaseLoadingCount);
    } else {
      mainModel.TagContainer = {
        TagEintraege: []
      };
    }

    this.value = (<ITagContainer>mainModel.TagContainer).TagEintraege.map(t => t.IdTag);
  }
  private updateTagContainer(values: number[]) {
    const tagContainer: ITagContainer = this.mainModel.TagContainer;

    if (!tagContainer) {
      return;
    }

    let hasChanges = false;
    for (let v of values) {
      const exists = tagContainer.TagEintraege.some(e => e.IdTag == v);
      if (exists) {
        continue;
      }

      hasChanges = true;
      tagContainer.TagEintraege.push({
        IdTag: v
      });
    }

    for (let v of tagContainer.TagEintraege.map(e => e.IdTag)) {
      const exists = values.includes(v);
      if (exists) {
        continue;
      }

      const indexOf = tagContainer.TagEintraege.findIndex(e => e.IdTag == v);
      if (indexOf < 0) {
        continue;
      }

      hasChanges = true;
      tagContainer.TagEintraege.splice(indexOf, 1);
    }

    if (hasChanges) {
      this.save();
    }
  }
  private async save() {
    if (!this.mainModel) {
      return;
    }

    if (this.mainModel.CanSave) {
      this._modelUtilsService.setDirty(this.mainModel);
    } else if (this.webApiAction) {
      const r = await this._restService.post({
        url: this._restService.getWebApiUrl(this.webApiAction),
        data: {
          Id: this.mainModel.Id,
          IdTagContainer: this.mainModel.IdTagContainer,
          TagContainer: this.mainModel.TagContainer
        },
        increaseLoadingCount: true
      });

      this.mainModel.IdTagContainer = r.IdTagContainer;
      this.loadData(true);
    }
  }
}

interface ITagContainer {
  IdObjekt?: number;
  TagEintraege: ITagEintrag[];
}
interface ITagEintrag {
  IdTagContainer?: number;
  IdTag?: number;
}
