import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { OnUIDValidierenEvent } from "../../framework-data/events";
import { ObjectService, WebEventService } from "../../framework/base/export";
import * as DxLoader from "../../framework/dx/dx-loader";

@autoinject
export class DxCustomizeService {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _eventAggregator: EventAggregator,
    private _objectService: ObjectService,
    private _webEventService: WebEventService
  ) {
    this._eventAggregator.subscribe("dx-widget:attaching", (attaching) => {
      switch (attaching.name) {
        case "dxFilterBuilder": {
          this.checkFilterBuilderFieldOperators(attaching.options.fields);
          this.checkFilterBuilderAnyOfSupported(attaching.options);
          break;
        }
      }
    });
  }

  checkFilterBuilderFieldOperators(fields: DevExpress.ui.dxFilterBuilderField[]) {
    if (!fields) {
      return;
    }

    for (const field of fields) {
      if (field.filterOperations && field.filterOperations.length > 0) {
        continue;
      }

      if (!field.dataType) {
        continue;
      }

      switch (field.dataType) {
        case "string": {
          field.filterOperations = ["=", "<>", "contains", "notcontains", "isblank", "isnotblank"];
          break;
        }
        case "number": {
          field.filterOperations = field.lookup
            ? ["=", "<>", "isblank", "isnotblank"]
            : ["=", "<>", "isblank", "isnotblank", ">", ">=", "<", "<=", "between"];
          break;
        }
        case "date":
        case "datetime": {
          field.filterOperations = ["=", "<>", "isblank", "isnotblank", ">", ">=", "<", "<=", "between"];
          break;
        }
        case "boolean": {
          field.filterOperations = ["="];
        }
      }

      if (field.lookup && field.filterOperations) {
        field.filterOperations.push("anyof", "noneof");
      }
    }
  }
  private checkFilterBuilderAnyOfSupported(options: DevExpress.ui.dxFilterBuilderOptions) {
    if (!options.customOperations) {
      options.customOperations = [];
    }

    const displayInfo = {};

    const customizeText = (info) => {
      const value: any = info.value;
      if (displayInfo[value]) {
        return displayInfo[value];
      }

      return info.valueText;
    };
    const editorTemplate = (conditionInfo) => {
      const options: DevExpress.ui.dxTagBoxOptions = {
        value: <any>conditionInfo.value,
        onSelectionChanged: (e) => {
          const keyExpr = <string>conditionInfo.field.lookup.valueExpr;
          const displayExpr = <string>conditionInfo.field.lookup.displayExpr;

          for (const item of e.addedItems) {
            const key = this._objectService.getValue(item, keyExpr);
            if (displayInfo[key]) {
              continue;
            }

            displayInfo[key] = this._objectService.getValue(item, displayExpr);
          }
        },
        onValueChanged: (e) => {
          conditionInfo.setValue(e.value && e.value.length ? e.value : null);
        },
        valueExpr: conditionInfo.field.lookup.valueExpr,
        displayExpr: conditionInfo.field.lookup.displayExpr,
        dataSource: conditionInfo.field.lookup.dataSource,
        width: "100%"
      };

      const div = document.createElement("div");
      DxLoader.createInstance("dxTagBox", div, options);
      return div;
    };

    options.customOperations.push({
      name: "anyof",
      caption: "einer aus",
      customizeText,
      editorTemplate
    });

    options.customOperations.push({
      name: "noneof",
      caption: "keiner aus",
      customizeText,
      editorTemplate
    });
  }

  addTelefon(options: DevExpress.ui.dxTextBoxOptions) {
    let onInitialized = options.onInitialized;

    options.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
        onInitialized = null;
      }

      e.component.on("valueChanged", (e) => this.updateTelefonButton(e.component));
      this.updateTelefonButton(e.component);
    };
  }
  private updateTelefonButton(editor: DevExpress.ui.dxTextBox) {
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];
    const telefon: string = editor.option("value");

    if (telefon) {
      buttons.push({
        location: "after",
        name: "telefon",
        options: {
          disabled: false,
          icon: "fa fa-phone",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            location.href = `tel:${telefon.replace(/[^\+0-9.]/g, "")}`;
          }
        }
      });
    }

    this.updateEditorButtons(editor, buttons);
  }

  addHomepage(options: DevExpress.ui.dxTextBoxOptions) {
    let onInitialized = options.onInitialized;

    options.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
        onInitialized = null;
      }

      e.component.on("valueChanged", (e) => this.updateHomepageButton(e.component));
      this.updateTelefonButton(e.component);
    };
  }
  private updateHomepageButton(editor: DevExpress.ui.dxTextBox) {
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];
    let homepage = editor.option("value");

    if (homepage) {
      buttons.push({
        location: "after",
        name: "telefon",
        options: {
          disabled: false,
          icon: "fa fa-home",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            if (!homepage.match(/^https?:\/\//i)) {
              homepage = `http://${homepage}`;
            }

            window.open(`${homepage}`, "_blank");
          }
        }
      });
    }

    this.updateEditorButtons(editor, buttons);
  }

  addBrief(options: DevExpress.ui.dxTextBoxOptions, callback: { (): void }) {
    let onInitialized = options.onInitialized;

    options.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
        onInitialized = null;
      }

      e.component.on("valueChanged", (e) => this.updateBriefButton(e.component, callback));
      this.updateBriefButton(e.component, callback);
    };
  }
  private updateBriefButton(editor: DevExpress.ui.dxTextBox, callback: { (): void }) {
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];
    const email = editor.option("value");

    if (email) {
      buttons.push({
        location: "after",
        name: "brief",
        options: {
          disabled: false,
          icon: "fa fa-envelope-open-o",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            callback();
          }
        }
      });
    }

    this.updateEditorButtons(editor, buttons);
  }

  addUid(options: DevExpress.ui.dxTextBoxOptions) {
    let onInitialized = options.onInitialized;

    options.onInitialized = (e) => {
      if (onInitialized) {
        onInitialized(e);
        onInitialized = null;
      }

      e.component.on("valueChanged", (e) => this.updateUidButton(e.component, !!e.event));
      this.updateUidButton(e.component);
    };
  }
  reloadUid(editor: DevExpress.ui.dxTextBox) {
    this.updateUidButton(editor);
  }
  private async updateUidButton(editor: DevExpress.ui.dxTextBox, byUser: boolean = false) {
    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (byUser) {
      this.updateEditorButtons(editor, [{
        location: "after",
        name: "uid",
        options: {
          icon: "fa fa-spinner",
          stylingMode: "text",
          hint: "wird überprüft",
          tabIndex: -1,
          disabled: true
        }
      }]);
    }

    const uidNummer = editor.option("value");

    if (uidNummer) {
      const r = await this.validateUidNummer(uidNummer);

      if (r.Exists == true) {
        buttons.push({
          location: "after",
          name: "uid",
          options: {
            disabled: false,
            icon: "fa fa-" + (r.IsGueltig ? "check" : "exclamation"),
            type: r.IsGueltig ? "success" : "danger",
            stylingMode: "contained",
            hint: r.Text,
            tabIndex: -1,
            onClick: async (e) => {
              const v = await this.validateUidNummer(uidNummer, true);
              e.component.option("icon", "fa fa-" + (v.IsGueltig ? "check" : "exclamation"));
              e.component.option("type", v.IsGueltig ? "success" : "danger");
  
              DevExpress.ui.notify(
                v.Text,
                v.IsGueltig ? "success" : "error",
                this.NOTIFY_TIMEOUT
              );
            }
          }
        });
      } else {
        buttons.push({
          location: "after",
          name: "uid",
          options: {
            disabled: false,
            icon: "fa fa-question",
            stylingMode: "contained",
            hint: r.Text,
            tabIndex: -1
          }
        });
      }
    }
    this.updateEditorButtons(editor, buttons);
  }

  updateEditorButtons(editor: DevExpress.ui.Editor, buttons: DevExpress.ui.dxTextEditorButton[]) {
    editor.option("buttons", buttons);
  }

  private async validateUidNummer(uidNummer: string, neuValidieren: boolean = false) {
    return await this._webEventService.execute(new OnUIDValidierenEvent({
      UIDNummer: uidNummer,
      NeuValidieren: neuValidieren,
      IsGueltig: false,
      Exists: false
    }));
  }
}
