import {
  autoinject,
  transient,
  TaskQueue
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  OnArtikelverleihAbholungEvent, OnArtikelverleihRueckgabeEvent
} from "../../../../framework-data/events";
import {
  FilialeService, LagerService
} from "../../../services/export";

import * as moment from "moment";

@autoinject
@transient()
export class ArtikelverleihEditUtils {
  constructor(
    private _webEventService: WebEventService,
    private _filialeService: FilialeService,
    private _lagerService: LagerService,
    private _taskQueue: TaskQueue
  ) { }

  form: FormBase;

  doAbholen: ICommandData = {
    id: "doAbholen",
    idCategory: "artikelverleih",
    title: "artikelverleih.abholung",
    icon: "sign-out",
    isVisibleExpression: "!models.data.$m_Artikelverleih.IsAbgeholt && !models.data.$m_Artikelverleih.Status.Typ",
    execute: async () => {
      this.form.models.data.$m_Artikelverleih.IsVorlaeufigeReservierung = false;
      
      const result = await this.form.saveIfDirty();
      if (!result.isValid) {
        return;
      }

      await this._webEventService
        .execute(new OnArtikelverleihAbholungEvent({
          IdArtikelverleih: this.form.models.data.$m_Artikelverleih.Id
        }), true)
        .then(() => {
          this.form.models.reloadAll();
        });
  
      this.form.models.reloadAll();
    }
  }
  doRueckgabe: ICommandData = {
    id: "doRueckgabe",
    idCategory: "artikelverleih",
    title: "artikelverleih.rueckgabe",
    icon: "sign-in",
    isVisibleExpression: "models.data.$m_Artikelverleih.IsAbgeholt && !models.data.$m_Artikelverleih.Status.Typ",
    execute: async () => {
      const result = await this.form.saveIfDirty();
      if (!result.isValid) {
        return;
      }

      const data: any = this.form.models.data.$m_Artikelverleih;

      await this._webEventService
        .execute(new OnArtikelverleihRueckgabeEvent({
          IdArtikelverleih: data.Id
        }), true)
        .then(() => {
          this.form.models.reloadAll();
        });

      this.form.models.reloadAll();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const kunde = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_kunde'] .dx-searchbox input");
      if (!kunde) {
        return;
      }

      kunde.focus();
    };

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Artikelverleih") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Artikelverleih"];
          if (model) {
            model.IdFiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);

            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Artikelverleih",
                bindTo: "IdFiliale"
              },
              value: model.IdFiliale
            });
          }
        } else if (args.binding.bindTo === "IdFiliale") {
          const model = form.models.data["$m_Artikelverleih"];
          if (model) {
            model.IdLager = await this._lagerService.getIdLagerVorbelegung(args.value);
          }
        } else if (args.binding.bindTo == "GeplanteRueckgabe") {
          if (args.value) {
            const m = moment(args.value);
            const hours = m.hours();
            const minutes = m.minutes();

            if (hours == 0 && minutes == 0) {
              this._taskQueue.queueTask(() => {
                form.models.data.$m_Artikelverleih.GeplanteRueckgabe = m.add(23, "h").add(59, "minutes").toDate();
              });
            }
          }
        }
      }
    });
  }
}
