import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    MahnungEditUtils
} from "./mahnung-edit-utils";

@fwx.autoinject
export class MahnungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_MahnungEditUtils: MahnungEditUtils) {
        super(element, formBaseImport);
        this.id = "mahnung-edit";
        this.title = "mahnung-edit.mahnung-edit_caption";
        this.addModel({
            "id": "$m_Mahnung",
            "webApiAction": "ERP/Verkauf/Mahnung",
            "webApiExpand": {
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_MahnungspositionRel",
            "webApiAction": "ERP/Verkauf/Mahnungsposition",
            "webApiExpand": {
                'Faktura': {
                    'expand': {
                        'Status': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_MahnungEditUtils.doMahnungVersenden",
                "bindToFQ": "functions.$f_MahnungEditUtils.doMahnungVersenden"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_MahnungEditUtils", $f_MahnungEditUtils, "functions.$f_MahnungEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "mahnung-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Mahnung.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "mahnung-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Mahnung.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "mahnung-edit.r_datum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Mahnung.Datum"
            },
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "mahnung-edit.r_betragoffen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "BetragOffen",
                "bindToFQ": "models.data.$m_Mahnung.BetragOffen"
            },
            "validationRules": [],
            "id": "r_betragOffen",
            "options": {
                "optionsName": "r_betragOffenOptions",
                "optionsNameFQ": "r_betragOffenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "mahnung-edit.r_zinsen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "Zinsen",
                "bindToFQ": "models.data.$m_Mahnung.Zinsen"
            },
            "validationRules": [],
            "id": "r_zinsen",
            "options": {
                "optionsName": "r_zinsenOptions",
                "optionsNameFQ": "r_zinsenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "mahnung-edit.r_spesen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "Mahnspesen",
                "bindToFQ": "models.data.$m_Mahnung.Mahnspesen"
            },
            "validationRules": [],
            "id": "r_spesen",
            "options": {
                "optionsName": "r_spesenOptions",
                "optionsNameFQ": "r_spesenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "mahnung-edit.r_mahnstufe_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "MaxMahnstufe",
                "bindToFQ": "models.data.$m_Mahnung.MaxMahnstufe"
            },
            "validationRules": [],
            "id": "r_mahnstufe",
            "options": {
                "optionsName": "r_mahnstufeOptions",
                "optionsNameFQ": "r_mahnstufeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "mahnung-edit.r_betraggesamt_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "BetragGesamt",
                "bindToFQ": "models.data.$m_Mahnung.BetragGesamt"
            },
            "validationRules": [],
            "id": "r_betragGesamt",
            "options": {
                "optionsName": "r_betragGesamtOptions",
                "optionsNameFQ": "r_betragGesamtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "mahnung-edit.r_waehrung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Mahnung.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "mahnung-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Mahnung",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Mahnung.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_mahnposFakturaNummer",
                "caption": "mahnung-edit.r_mahnposfakturanummer_caption",
                "bindTo": "Faktura.Nummer",
                "width": "80px"
            }, {
                "id": "r_mahnposFakturaDatum",
                "caption": "mahnung-edit.r_mahnposfakturadatum_caption",
                "bindTo": "Faktura.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_mahnposFakturaFaelligkeit",
                "caption": "mahnung-edit.r_mahnposfakturafaelligkeit_caption",
                "bindTo": "Faktura.ZahlungFaelligkeit",
                "width": "90px",
                "sortOrder": "asc",
                "format": "d"
            }, {
                "id": "r_mahnposBetragOffen",
                "caption": "mahnung-edit.r_mahnposbetragoffen_caption",
                "bindTo": "BetragOffen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnposZinsen",
                "caption": "mahnung-edit.r_mahnposzinsen_caption",
                "bindTo": "Zinsen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnposMahnstufe",
                "caption": "mahnung-edit.r_mahnposmahnstufe_caption",
                "bindTo": "Mahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_mahnposTageVerzug",
                "caption": "mahnung-edit.r_mahnpostageverzug_caption",
                "bindTo": "TageVerzug",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_mahnposStatus",
                "caption": "mahnung-edit.r_mahnposstatus_caption",
                "bindTo": "Faktura.Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_mahnpositionenGridToolbarOptions",
                "optionsNameFQ": "r_mahnpositionenGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Mahnung"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_MahnungspositionRel",
                "bindTo": "IdMahnung",
                "bindToFQ": "models.data.$m_MahnungspositionRel.IdMahnung"
            },
            "dataModel": "$m_Mahnung",
            "editUrl": "ERP/Verkauf/Faktura",
            "editUrlIdProperty": "IdFaktura",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_mahnpositionenGrid",
            "options": {
                "optionsName": "r_mahnpositionenGridOptions",
                "optionsNameFQ": "r_mahnpositionenGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}