import { autoinject, TaskQueue, View } from "aurelia-framework";

@autoinject
export class TemplatingExService {
  constructor(
    private taskQueue: TaskQueue
  ) {}

  waitForViewModel(item: any, counter: number = 1000): Promise<any> {
    return new Promise((resolve, reject) => {
      let index = 0;

      if (item.currentViewModel) {
        resolve(item.currentViewModel);
      } else {
        const queue = () => {
          this.taskQueue.queueTask(() => {
            index++;
  
            if (item.currentViewModel) {
              resolve(item.currentViewModel);
            } else if (index >= counter) {
              console.log("currentViewModel not found");
              reject();
            } else {
              queue();
            }
          });
        };
        
        queue();
      }
    });
  }
  destroyView(view: View) {
    view.detached();
    view.unbind();
  }
}
