import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class NewsletterListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "newsletter-list";
        this.title = "newsletter-list.newsletter-list_caption";
        this.addModel({
            "id": "$m_Newsletter",
            "webApiAction": "ERP/Stammdaten/Newsletter",
            "webApiExpand": {
                'NewsletterEmpfaenger': null,
                'MailVorlage': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_newsletterBezeichnung",
                "caption": "newsletter-list.r_newsletterbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_newsletterMailVorlage",
                "caption": "newsletter-list.r_newslettermailvorlage_caption",
                "bindTo": "MailVorlage.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_newsletterEmpfaenger",
                "caption": "newsletter-list.r_newsletterempfaenger_caption",
                "bindTo": "NewsletterEmpfaenger.Bezeichnung"
            }, {
                "id": "r_newsletterStatus",
                "caption": "newsletter-list.r_newsletterstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_newsletterGridToolbarOptions",
                "optionsNameFQ": "r_newsletterGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Newsletter"
            },
            "dataModel": "$m_Newsletter",
            "editUrl": "ERP/Stammdaten/Newsletter",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_newsletterGrid",
            "options": {
                "optionsName": "r_newsletterGridOptions",
                "optionsNameFQ": "r_newsletterGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}