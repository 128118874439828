import { ModelUtilsService } from "./../../services/model-utils-service";
import {
  autoinject,
  bindable,
  bindingMode,
  observable,
  TaskQueue,
  computedFrom
} from "aurelia-framework";

declare const monaco: any;

@autoinject
export class CodeEditor {
  private static _jsLoadedPromise: Promise<any>;
  private static _vsLoadedPromise: Promise<any>;

  constructor(
    private element: Element,
    private taskQueue: TaskQueue,
    private modelUtils: ModelUtilsService
  ) { }

  @bindable caption: string;
  @bindable @observable mainModel: any;
  @bindable language: string;
  @bindable height: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable value: any;

  host: Element;
  editor: any;
  editorDidChangeModelContentEvent: any;

  @computedFrom("height")
  get heightEx(): string {
    if (this.height) {
      return this.height;
    }

    return "250px";
  }

  bind() {
    this.createEditorOnMonacoLoaded();

    if (this.value) {
      this.valueChanged(this.value);
    }
  }
  unbind() {
    if (this.editorDidChangeModelContentEvent) {
      this.editorDidChangeModelContentEvent.dispose();
      this.editorDidChangeModelContentEvent = null;
    }

    if (this.editor) {
      this.editor.getModel().dispose();
      this.editor.dispose();
      this.editor = null;
    }
  }

  insert(text: string) {
    const position = this.editor.getPosition();
    this.editor.executeEdits("", [{
        range: new monaco.Range(position.lineNumber,
          position.column,
          position.lineNumber,
          position.column),
        text: text
      }]);
  }

  mainModelChanged() {
    this.taskQueue.queueMicroTask(() => {
      this.valueChanged(this.value);
    })
  }
  valueChanged(newVal) {
    if (!this.editor) {
      return;
    }

    if (newVal == this.editor.getValue()) {
      return;
    }

    this.editor.setValue(newVal || "");
  }

  private createEditorOnMonacoLoaded() {
    if ((<any>window).require) {
      this.createEditorOnMonacoLoaded2();
    } else if (CodeEditor._jsLoadedPromise) {
      CodeEditor._jsLoadedPromise.then(() => {
        this.createEditorOnMonacoLoaded2();
      });
    }
    else {
      CodeEditor._jsLoadedPromise = new Promise<any>((resolve, reject) => {
        var loaderScript = document.createElement("script");
        loaderScript.type = "text/javascript";
        loaderScript.src = "vs/loader.js";
        loaderScript.addEventListener("load", () => {
          resolve(true);
          this.createEditorOnMonacoLoaded2();
        });
        document.body.appendChild(loaderScript);
      });
    }
  }
  private createEditorOnMonacoLoaded2() {
    if (!CodeEditor._vsLoadedPromise) {
      CodeEditor._vsLoadedPromise = new Promise((resolve, reject) => {
        (<any>window).require(["vs/editor/editor.main"], () => {
          resolve(true);
        });
      });
    }

    CodeEditor._vsLoadedPromise.then(() => {
      this.createEditor();
    });
  }
  private createEditor() {
    this.editor = monaco.editor.create(
      this.host, {
        autoIndent: true,
        automaticLayout: true,
        fontSize: "12px",
        minimap: {
          enabled: false
        },
        language: this.language || "csharp",
        value: this.value
      });

    this.editorDidChangeModelContentEvent = this.editor.onDidChangeModelContent(() => {
      const newVal = this.editor.getValue();

      if ((newVal || "") == (this.value || "")) {
        return;
      }

      this.value = newVal;

      if (this.mainModel) {
        this.modelUtils.setDirty(this.mainModel);
      }
    });
  }
}
