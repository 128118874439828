import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  RestService
} from "../../../../framework/base/export";
import {
  FormBase
} from "../../../../framework/forms/export";

@autoinject
@transient()
export class EinstellungEditUtils {
  constructor(
    private rest: RestService
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Cloud/Einstellung"),
      getOptions: {
        columns: ["Id"]
      }
    }).then(r => {
      if (r && r.length === 1) {
        form.variables.data.$idEinstellung = r[0].Id;
      }
    });
  }
}