import * as fwx from "../../../../framework/forms/form-export";
import {
    MandantWechselnUtils
} from "./mandant-wechseln-utils";

@fwx.autoinject
export class MandantWechselnForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_mandantWechselenUtils: MandantWechselnUtils) {
        super(element, formBaseImport);
        this.id = "mandant-wechseln";
        this.title = "mandant-wechseln.mandant-wechseln_caption";
        this.addModel({
            "id": "$m_Mandant",
            "webApiAction": "ERP/Stammdaten/Mandant",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "allowNew": "false",
            "allowSave": "false",
            "allowDelete": "false",
            "custom": {
                'showAendinfo': false
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_mandantWechselenUtils.mandantWechselnCommand",
                "bindToFQ": "functions.$f_mandantWechselenUtils.mandantWechselnCommand"
            }
        });
        this.addFunction("$f_mandantWechselenUtils", $f_mandantWechselenUtils, "functions.$f_mandantWechselenUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mandant",
            "filters": [{
                "webApiCustomKey": "IdBenutzer",
                "webApiCustomValue": "functions.$f_mandantWechselenUtils.startupService.startupInfo.Benutzer.Id"
            }],
            "binding": {
                "dataContext": "$m_Mandant",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Mandant.Id"
            },
            "validationRules": [],
            "id": "r_mandant_to_wechseln",
            "options": {
                "optionsName": "r_mandant_to_wechselnOptions",
                "optionsNameFQ": "r_mandant_to_wechselnOptions"
            }
        });
        super.onConstructionFinished();
    }
}