import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class EingangsrechnungszuschlagEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "eingangsrechnungszuschlag-edit";
        this.title = "eingangsrechnungszuschlag-edit.eingangsrechnungszuschlag-edit_caption";
        this.addModel({
            "id": "$m_Eingangsrechnungszuschlag",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungszuschlag",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Eingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Eingangsrechnungszuschlag.IdEingangsrechnung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zuschlagsart",
            "filters": [],
            "caption": "eingangsrechnungszuschlag-edit.r_zuschlagsart_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungszuschlag",
                "bindTo": "IdZuschlagsart",
                "bindToFQ": "models.data.$m_Eingangsrechnungszuschlag.IdZuschlagsart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_zuschlagsart",
            "options": {
                "optionsName": "r_zuschlagsartOptions",
                "optionsNameFQ": "r_zuschlagsartOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungszuschlag-edit.r_wertnetto_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungszuschlag",
                "bindTo": "WertNetto",
                "bindToFQ": "models.data.$m_Eingangsrechnungszuschlag.WertNetto"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wertNetto",
            "options": {
                "optionsName": "r_wertNettoOptions",
                "optionsNameFQ": "r_wertNettoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungszuschlag-edit.r_steuersatz_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungszuschlag",
                "bindTo": "Steuersatz",
                "bindToFQ": "models.data.$m_Eingangsrechnungszuschlag.Steuersatz"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_steuersatz",
            "options": {
                "optionsName": "r_steuersatzOptions",
                "optionsNameFQ": "r_steuersatzOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "eingangsrechnungszuschlag-edit.r_kommentar_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungszuschlag",
                "bindTo": "Kommentar",
                "bindToFQ": "models.data.$m_Eingangsrechnungszuschlag.Kommentar"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_kommentar",
            "options": {
                "optionsName": "r_kommentarOptions",
                "optionsNameFQ": "r_kommentarOptions"
            }
        });
        super.onConstructionFinished();
    }
}