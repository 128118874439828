import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IArbeitszeitMitarbeiterstatus } from "../../interfaces/arbeitszeit-mitarbeiterstatus";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitKalenderZuteilungEdit {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IArbeitszeitKalenderZuteilungEditOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  kalenderList: any[];
  arbeitszeitKalender: IArbeitszeitKalender;

  arbeitszeitKalenderZuteilungEditPopup: Interfaces.IdxPopupComponent;
  arbeitszeitKalenderZuteilungEditPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitKalenderZuteilungEditPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitMitarbeiterstatusDetail",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitKalenderZuteilungEditPopup.instance.hide();
      this._options.callback(this.arbeitszeitKalender);
    }
  }];

  arbeitszeitKalenderZuteilungEditValidation: any;
  arbeitszeitKalenderZuteilungEditValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  gueltigVonDateBox: Interfaces.IdxDateBoxComponent;
  gueltigVonDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "arbeitszeitKalender.GueltigVon",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  kalenderSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    bindingOptions: {
      dataSource: "kalenderList",
      value: "arbeitszeitKalender.IdKalender",
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitKalenderZuteilungEditToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.kalender",
      options: this.arbeitszeitKalenderZuteilungEditPopupOptions,
      commands: this.arbeitszeitKalenderZuteilungEditPopupCommands,
      scopeContainer: this.scopeContainer
    });

    this.loadKalenderDatasource();
  }


  async show(options: IArbeitszeitKalenderZuteilungEditOptions) {
    this._options = options;
    this.arbeitszeitKalender = null;
    this.arbeitszeitKalenderZuteilungEditPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitKalenderZuteilungEditValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }
    if (this.arbeitszeitKalender.GueltigVon < this._options.mitarbeiterstatus.Eintrittsdatum) {
      DevExpress.ui.notify(
        "Gültig von Datum darf nicht kleiner als Eintrittsdatum sein",
        "error",
        this.NOTIFY_TIMEOUT);
      return false;
    }

    return true;
  }
  private async loadKalenderDatasource() {
    this.kalenderList = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Arbeitszeit/kalender")
    });
  }
}

export interface IArbeitszeitKalenderZuteilungEditOptions {
  mitarbeiterstatus: IArbeitszeitMitarbeiterstatus,
  callback: { (arbeitszeitKalender: IArbeitszeitKalender): void };
}

export interface IArbeitszeitKalender {
  IdKalender: number;
  GueltigVon: Date;
}
