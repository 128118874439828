import { autoinject, bindable, observable } from "aurelia-framework";
import { GlobalizationService, RestService } from '../../../../../../framework/base/export';
import { ArbeitszeitMitarbeiterstatusAustritt } from "../../../../../elements/arbeitszeit-mitarbeiterstatus-austritt/arbeitszeit-mitarbeiterstatus-austritt";
import { ArbeitszeitMitarbeiterstatus } from "../../../../../elements/arbeitszeit-mitarbeiterstatus/arbeitszeit-mitarbeiterstatus";
import { IArbeitszeitBenutzerZuteilung } from './../../../../../interfaces/arbeitszeit-benutzer-zuteilung';
import { IArbeitszeitMitarbeiterstatus } from "../../../../../interfaces/arbeitszeit-mitarbeiterstatus";

@autoinject
export class ArbeitszeitBenutzerZuteilung {

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  @bindable @observable id: any;
  @bindable idPartei: any;
  hasDataChanged: boolean;
  arbeitszeitBenutzerzuteilung: IArbeitszeitBenutzerZuteilung;

  @observable idMitarbeiterstatus: number;
  mitarbeiterstatusList: IArbeitszeitMitarbeiterstatus[];
  mitarbeiterstatus: IArbeitszeitMitarbeiterstatus;
  arbeitszeitMitarbeiterstatus: ArbeitszeitMitarbeiterstatus;
  arbeitszeitMitarbeiterstatusAustritt: ArbeitszeitMitarbeiterstatusAustritt;

  mitarbeiterstatusSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "IdMitarbeiterstatus",
    showClearButton: true,
    bindingOptions: {
      dataSource: "mitarbeiterstatusList",
      value: "idMitarbeiterstatus",
    }
  };

  vordienstzeitenMonateNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    readOnly: true,
    bindingOptions: {
      value: "mitarbeiterstatus.VordienstzeitenMonate"
    }
  }

  vordienstzeitenTageNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n0"),
    readOnly: true,
    bindingOptions: {
      value: "mitarbeiterstatus.VordienstzeitenTage"
    }
  }

  austrittMitarbeiterstatusButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Austritt",
    onClick: () => {
      this.arbeitszeitMitarbeiterstatusAustritt.show({
        callback: (austrittsDatum: Date) => {
          this.mitarbeiterstatusAbgrenzen(austrittsDatum);
        }
      })
    }
  }

  idChanged(e) {
    if (this.id > 0) {
      this.loadMitarbeiterstatus();
    } else {
      this.arbeitszeitBenutzerzuteilung = {};
      this.idPartei = null;
      this.idMitarbeiterstatus = null;
      this.mitarbeiterstatus = null;
    }
  }

  idMitarbeiterstatusChanged(e) {
    if (this.idMitarbeiterstatus > 0) {
      this.mitarbeiterstatus = this.mitarbeiterstatusList
        .filter(c => c.IdMitarbeiterstatus == this.idMitarbeiterstatus)[0] || null;
    } else {
      this.mitarbeiterstatus = null;
    }
  }

  doNeuerMitarbeiterstatus() {
    this.arbeitszeitMitarbeiterstatus.show({
      idArbeitszeitBenutzerZuteilung: this.id,
      callback: async (arbeitszeitBenutzerZuteilung: IArbeitszeitBenutzerZuteilung) => {
        this.mitarbeiterstatusErstellen(arbeitszeitBenutzerZuteilung);
      }
    });
  }

  private async mitarbeiterstatusAbgrenzen(austrittsDatum: Date) {
    this.mitarbeiterstatus.Austrittsdatum = austrittsDatum;

    await this._restService.post({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/austritt`),
      data: {
        IdMitarbeiterstatus: this.idMitarbeiterstatus,
        Austrittsdatum: austrittsDatum
      }
    });

    this.loadMitarbeiterstatus();
  }

  private async loadMitarbeiterstatus() {
    this.mitarbeiterstatusList = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/${this.id}`)
    });

    if (this.mitarbeiterstatusList.length > 0) {
      this.idMitarbeiterstatus = this.mitarbeiterstatusList[0].IdMitarbeiterstatus;
    }
  }

  private async mitarbeiterstatusErstellen(arbeitszeitBenutzerZuteilung: IArbeitszeitBenutzerZuteilung) {
    const r = await this._restService.post({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitMitarbeiterstatus/benutzerzuteilung`),
      data: {
        IdArbeitszeitBenutzerZuteilung: this.id,
        Eintrittsdatum: arbeitszeitBenutzerZuteilung.Eintrittsdatum,
        IdArbeitszeitmuster: arbeitszeitBenutzerZuteilung.IdArbeitszeitmuster,
        IdAbrechnungsmodell: arbeitszeitBenutzerZuteilung.IdAbrechnungsmodell,
        IdKostenstelle: arbeitszeitBenutzerZuteilung.IdKostenstelle,
        IdAnspruchsgruppe: arbeitszeitBenutzerZuteilung.IdAnspruchsgruppe,
        IdGehaltsSchema: arbeitszeitBenutzerZuteilung.IdGehaltsSchema,
        IdKalender: arbeitszeitBenutzerZuteilung.IdKalender,
        BeschaeftigungsausmassProzent: arbeitszeitBenutzerZuteilung.BeschaeftigungsausmassProzent,
        Personalnummer: arbeitszeitBenutzerZuteilung.Personalnummer,
        VordienstzeitenMonate: arbeitszeitBenutzerZuteilung.VordienstzeitenMonate,
        VordienstzeitenTage: arbeitszeitBenutzerZuteilung.VordienstzeitenTage
      }
    });

    await this.loadMitarbeiterstatus();
    this.idMitarbeiterstatus = r.IdMitarbeiterstatus;
  }
}
