import { IViewModel } from '../../interfaces/view-model';
import { EditorService } from '../../services/editor-service';
import { DataGridService } from '../../services/data-grid-service';
import { ChartService } from '../../services/chart-service';
import { PivotService } from '../../services/pivot-services';

export default class UI {
  constructor(
    private _viewModel: IViewModel,
    private _editorService: EditorService,
    private _dataGridService: DataGridService,
    private _chartService: ChartService,
    private _pivotService: PivotService) {
  }

  button(options: DevExpress.ui.dxButtonOptions) {
    return Object.assign({
      text: "Default"
    }, options);
  }
  calendar(idDataSource: string, dataField: string, options: DevExpress.ui.dxCalendarOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  checkBox(idDataSource: string, dataField: string, options: DevExpress.ui.dxCheckBoxOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  dateBox(idDataSource: string, dataField: string, options: DevExpress.ui.dxDateBoxOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  numberBox(idDataSource: string, dataField: string, options: DevExpress.ui.dxNumberBoxOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  textBox(idDataSource: string, dataField: string, options: DevExpress.ui.dxTextBoxOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  textArea(idDataSource: string, dataField: string, options: DevExpress.ui.dxTextAreaOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  switch(idDataSource: string, dataField: string, options: DevExpress.ui.dxSwitchOptions) {
    return this._editorService.createOptions(this._viewModel, idDataSource, dataField, options);
  }
  grid(idDataSource: string, options: DevExpress.ui.dxDataGridOptions) {
    return this._dataGridService.createOptions(this._viewModel, idDataSource, options);
  }
  pivot(idDataSource: string, options: DevExpress.ui.dxPivotGridOptions) {
    return this._pivotService.createOptions(this._viewModel, idDataSource, options);
  }
  chart(idDataSource: string, options: DevExpress.viz.dxChartOptions) {
    return this._chartService.createOptions(this._viewModel, idDataSource, options);
  }
}