import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, TaskQueue } from "aurelia-framework";
import * as mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind";
import { WindowService } from './window-service';

@autoinject
export class ShortcutService {
  constructor(
    private _eventAggregator: EventAggregator,
    private _taskQueue: TaskQueue,
    private _windowService: WindowService) { }

  bindShortcut(sequence: string, idCommand: string | { (): void }, bindGlobal: boolean = true) {
    if (bindGlobal) {
      mousetrap.bindGlobal(sequence, e => this.fire(idCommand));
    } else {
      mousetrap.bind(sequence, e => this.fire(idCommand));
    }
  }

  private fire(idCommand: string | { (): void }): boolean {
    this._windowService.blurCurrentElement();

    this._taskQueue.queueTask(() => {
      if (typeof idCommand === "function") {
        idCommand();
      } else {
        this._eventAggregator.publish("shortcut:execute", {
          idCommand: idCommand
        });
      }
    });

    return false;
  }
}
