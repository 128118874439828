
import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/export";

@autoinject
export class LieferscheinService {
  constructor(
    private _restService: RestService
  ) { }

  async canAuftragMerge(auftrag: any): Promise<boolean> {
    const whereOptions = [
      ["Lieferschein.IdKunde", auftrag.IdKunde],
      ["Lieferschein.MergeKey", auftrag.MergeKey],
      ["Lieferschein.Status.Typ", 0]
    ];

    if (auftrag.IsStreckengeschaeft || auftrag.IsIcAuftrag) {
      whereOptions.push(["Auftragsposition.IdAuftrag", auftrag.Id]);
    }

    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Verkauf/Lieferscheinposition"),
      getOptions: {
        where: whereOptions
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  async hasLieferscheingruppePositionenZugeteilt(idLieferscheingruppe){
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Verkauf/Lieferscheinposition`),
      getOptions: {
        where: ["IdLieferscheingruppe", idLieferscheingruppe]
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}
