import { autoinject, bindable, observable } from "aurelia-framework";
import { GlobalizationService, RestService } from "../../../framework/base/export";
import { IdxDataGridComponent } from "../../interfaces/dx-components";
import { ArbeitszeitFreieInformationEdit, IArbeitszeitFreieInformation } from "../arbeitszeit-freie-information-edit/arbeitszeit-freie-information-edit";


@autoinject
export class ArbeitszeitFreieInformationList {

  constructor(
    private _globalizationService: GlobalizationService,
    private _restService: RestService) { }

  @bindable @observable idPartei: number;
  arbeitszeitArbeitszeitFreieInformationEdit: ArbeitszeitFreieInformationEdit;

  grid: IdxDataGridComponent;
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    allowColumnResizing: true,
    columnResizingMode: "widget",
    paging: {
      pageSize: 20
    },
    hoverStateEnabled: true,
    headerFilter: {
      visible: true
    },
    filterRow: {
      visible: true
    },
    columns: [{
      dataField: "GueltigVon",
      caption: "Gültig von",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "GueltigBis",
      caption: "Gültig bis",
      width: "120px",
      format: this._globalizationService.getFormatterParser("d")
    }, {
      dataField: "Bezeichnung",
      caption: "Bezeichnung",
      minWidth: 50
    },
    {
      dataField: "Wert",
      caption: "Wert",
      minWidth: 50
    }],
    onRowClick: (e) => {
      this.arbeitszeitArbeitszeitFreieInformationEdit.show({
        arbeitszeitFreieInformation: {
          Id: e.data.Id,
          IdFreieInformationDetail: e.data.IdFreieInformationDetail,
          Wert: e.data.Wert,
          GueltigVon: e.data.GueltigVon,
          GueltigBis: e.data.GueltigBis
        },
        callback: async (arbeitszeitFreieInformation: IArbeitszeitFreieInformation) => {
          this.saveFreieInformation(arbeitszeitFreieInformation);
        }
      });
    },
  };

  onNeuButtonClick() {
    this.arbeitszeitArbeitszeitFreieInformationEdit.show({
      arbeitszeitFreieInformation: {
        GueltigVon: new Date()
      },
      callback: async (arbeitszeitFreieInformation: IArbeitszeitFreieInformation) => {
        this.saveFreieInformation(arbeitszeitFreieInformation);
      }
    });
  }

  idParteiChanged(e) {
    if (this.idPartei > 0) {
      this.loadParteiFreieInformationen();
    }
  }

  private async saveFreieInformation(arbeitszeitFreieInformation: IArbeitszeitFreieInformation) {
    const r = await this._restService.post({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitPartei/freieinformation`),
      data: {
        Id: arbeitszeitFreieInformation.Id,
        IdPartei: this.idPartei,
        IdFreieInformationDetail: arbeitszeitFreieInformation.IdFreieInformationDetail,
        Wert: arbeitszeitFreieInformation.Wert,
        GueltigVon: arbeitszeitFreieInformation.GueltigVon,
        GueltigBis: arbeitszeitFreieInformation.GueltigBis
      }
    });

    this.loadParteiFreieInformationen();
  }

  private async loadParteiFreieInformationen() {
    const r = await this._restService.get({
      url: this._restService.getApiUrl(`ERP/ArbeitszeitPartei/freieinformation/${this.idPartei}`)
    });

    this.grid.instance.option("dataSource", r);
  }
}
