import { Scope, autoinject } from "aurelia-framework";
import { LocalizationService, ScopeContainer } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/interfaces/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { IdxPopupComponent } from "../../interfaces/export";
import { GlobalizationService } from "./../../../framework/base/services/globalization-service";

@autoinject
export class VariablerSetartikelPreisAendern {
  private NOTIFY_TIMEOUT: number = 3000;
  private _options: IVariablerSetartikelPreisAendernPopupOptions;

  constructor(
    private _localizationService: LocalizationService,
    private _globalizationService: GlobalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  variablerSetartikelPreisAendernResult: IVariablerSetartikelPreisAendernResult;

  variablerSetartikelPreisAendernPopup: IdxPopupComponent;
  variablerSetartikelPreisAendernPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: "600px",
    height: "auto"
  };
  variablerSetartikelPreisAendernPopupCommands: ICommandData[] = [{
    id: "uebernehmen",
    title: "variabler-setartikel-preis-aendern.uebernehmen",
    icon: "save",
    execute: this.auswahlUebernehmen.bind(this)
  }, {
    id: "rabattEntfernen",
    title: "variabler-setartikel-preis-aendern.rabatt-entfernen",
    icon: "money",
    execute: this.rabattEntfernen.bind(this)
  }, {
    id: "manuellerPreisEntfernen",
    title: "variabler-setartikel-preis-aendern.manueller-preis-entfernen",
    icon: "money",
    execute: this.manuellerPreisEntfernen.bind(this)
  }];

  wertNumberBoxOptions: DevExpress.ui.dxNumberBoxOptions = {
    format: this._globalizationService.getNumberFormat("n2"),
    bindingOptions: {
      value: "variablerSetartikelPreisAendernResult.Wert"
    }
  }

  konditionsFeldSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Typ",
    dataSource: [
      { Typ: 0, Bezeichnung: "Rabatt" },
      { Typ: 1, Bezeichnung: "Manueller Preis pro Einheit" }
    ],
    bindingOptions: {
      value: "variablerSetartikelPreisAendernResult.KalkulationsFeldTyp"
    }
  };

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "variablerSetartikelPreisAendernPopupToolbar",
      caption: "variabler-setartikel-preis-aendern.popup-titel",
      options: this.variablerSetartikelPreisAendernPopupOptions,
      commands: this.variablerSetartikelPreisAendernPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  show(options: IVariablerSetartikelPreisAendernPopupOptions) {
    this._options = options;

    this.modelZuruecksetzen();

    this.variablerSetartikelPreisAendernPopup.instance.show();
  }

  private modelZuruecksetzen(){
    this.variablerSetartikelPreisAendernResult = {
      KalkulationsFeldTyp: null,
      Wert: null,
      RabattEntfernen: false,
      ManuellerPreisEntfernen: false
    };
  }

  private auswahlUebernehmen() {
    if (this.variablerSetartikelPreisAendernResult.KalkulationsFeldTyp == null) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("variabler-setartikel-preis-aendern.error-kein-konditionsfeld"),
        "ERROR",
        this.NOTIFY_TIMEOUT);
      return;
    }

    if (!this.variablerSetartikelPreisAendernResult.Wert) {
      DevExpress.ui.notify(
        this._localizationService.translateOnce("variabler-setartikel-preis-aendern.error-kein-wert"),
        "ERROR",
        this.NOTIFY_TIMEOUT);
      return;
    }

    this.variablerSetartikelPreisAendernPopup.instance.hide();
    this._options.callback(this.variablerSetartikelPreisAendernResult);
  }
  private rabattEntfernen() {
    this.modelZuruecksetzen();
    this.variablerSetartikelPreisAendernResult.RabattEntfernen = true;

    this.variablerSetartikelPreisAendernPopup.instance.hide();
    this._options.callback(this.variablerSetartikelPreisAendernResult);
  }
  private manuellerPreisEntfernen() {
    this.modelZuruecksetzen();
    this.variablerSetartikelPreisAendernResult.ManuellerPreisEntfernen = true;

    this.variablerSetartikelPreisAendernPopup.instance.hide();
    this._options.callback(this.variablerSetartikelPreisAendernResult);
  }
}

export interface IVariablerSetartikelPreisAendernPopupOptions {
  callback: {
    (result: IVariablerSetartikelPreisAendernResult): void
  };
}

export interface IVariablerSetartikelPreisAendernResult {
  KalkulationsFeldTyp: number;
  Wert: number;
  RabattEntfernen: boolean;
  ManuellerPreisEntfernen: boolean;
}
