import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FehlerEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fehler-edit";
        this.title = "fehler-edit.fehler-edit_caption";
        this.addModel({
            "id": "$m_Fehler",
            "webApiAction": "ERP/Cloud/Fehler",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "true",
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "fehler-edit.r_datenbank_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "Datenbank",
                "bindToFQ": "models.data.$m_Fehler.Datenbank"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_datenbank",
            "options": {
                "optionsName": "r_datenbankOptions",
                "optionsNameFQ": "r_datenbankOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "fehler-edit.r_idmandant_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "IdMandant",
                "bindToFQ": "models.data.$m_Fehler.IdMandant"
            },
            "validationRules": [],
            "id": "r_idMandant",
            "options": {
                "optionsName": "r_idMandantOptions",
                "optionsNameFQ": "r_idMandantOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "fehler-edit.r_idbenutzer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "IdBenutzer",
                "bindToFQ": "models.data.$m_Fehler.IdBenutzer"
            },
            "validationRules": [],
            "id": "r_idBenutzer",
            "options": {
                "optionsName": "r_idBenutzerOptions",
                "optionsNameFQ": "r_idBenutzerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "fehler-edit.r_isbearbeitet_caption",
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "IsBearbeitet",
                "bindToFQ": "models.data.$m_Fehler.IsBearbeitet"
            },
            "validationRules": [],
            "id": "r_isBearbeitet",
            "options": {
                "optionsName": "r_isBearbeitetOptions",
                "optionsNameFQ": "r_isBearbeitetOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "400px",
            "caption": "fehler-edit.r_fehlerinformation_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "Fehlerinformation",
                "bindToFQ": "models.data.$m_Fehler.Fehlerinformation"
            },
            "validationRules": [],
            "id": "r_fehlerinformation",
            "options": {
                "optionsName": "r_fehlerinformationOptions",
                "optionsNameFQ": "r_fehlerinformationOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "400px",
            "caption": "fehler-edit.r_kommentar_caption",
            "binding": {
                "dataContext": "$m_Fehler",
                "bindTo": "Kommentar",
                "bindToFQ": "models.data.$m_Fehler.Kommentar"
            },
            "validationRules": [],
            "id": "r_kommentar",
            "options": {
                "optionsName": "r_kommentarOptions",
                "optionsNameFQ": "r_kommentarOptions"
            }
        });
        super.onConstructionFinished();
    }
}