import {
  autoinject,
  OverrideContext,
  Scope
} from "aurelia-framework";
import {
  DataSourceService,
  ScopeContainer,
  WebEventService,
  LocalizationService
} from "../../../framework/base/export";
import {
  ICommandData,
  HistoryService,
  SimpleWidgetCreatorService
} from "../../../framework/forms/export";
import {
  OnSpracheUebersetzen,
  OnSpracheUebersetzenEvent,
  SpracheItemInfo
} from "../../../framework-data/events";

import {
  FormCommandService,
  SpracheUebersetzungService
} from "../../services/export";
import {
  IdxPopupComponent,
  IdxSelectBoxComponent
} from "../../interfaces/export";

@autoinject
export class SpracheUebersetzung {
  private _availableSprachen: any[];
  private _detachedCommand: { (): void };
  private _uebersetzungData: OnSpracheUebersetzen;

  constructor(
    private dataSource: DataSourceService,
    private formCommand: FormCommandService,
    private localization: LocalizationService,
    private simpleWidgetCreator: SimpleWidgetCreatorService,
    private spracheUebersetzung: SpracheUebersetzungService,
    private webEvent: WebEventService
  ) { }

  scope: Scope;
  scopeContainer: ScopeContainer;

  idSpracheContainer: number;
  spracheItemList: SpracheItemInfo[];
  currentSprache: any;

  spracheUebersetzungPopupCommands: ICommandData[] = [
    {
      id: "$save",
      title: "base.save",
      icon: "floppy-o",
      execute: this.setUebersetzungData.bind(this)
    }
  ];
  spracheUebersetzungPopup: IdxPopupComponent;
  spracheUebersetzungPopupOptions: DevExpress.ui.dxPopupOptions = {
    onHidden: this.resetUebersetzungData.bind(this),
    maxWidth: "90%"
  };

  spracheSelectBox: IdxSelectBoxComponent;
  spracheSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    onValueChangedByUser: this.onSpracheChanged.bind(this),
    bindingOptions: {
      value: "currentSprache.Id"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.spracheUebersetzung.spracheUebersetzung = this;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this.spracheSelectBoxOptions.dataSource = this.dataSource.createDataSource(this.scopeContainer, {
      webApiAction: "ERP/Stammdaten/Sprache",
      webApiColumns: ["Id", "Bezeichnung"],
      webApiWhere: ["CanUebersetzen", true],
      keyProperty: "Id"
    });

    this.simpleWidgetCreator.updatePopupOptions({
      idToolbar: "spracheUebersetzungPopupToolbar",
      caption: "sprache-uebersetzung.uebersetzungen",
      options: this.spracheUebersetzungPopupOptions,
      commands: this.spracheUebersetzungPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  attached() {
    const uebersetzungCommand: ICommandData =
      {
        id: "uebersetzung",
        idCategory: "sprache",
        title: "sprache-uebersetzung.uebersetzungen",
        icon: "globe",
        isEnabled: false
      };
    this._detachedCommand = this.formCommand.attachFormCommand(
      uebersetzungCommand,
      {
        isCommandVisibleModelProperty: "HasSpracheContainer",
        execute: (e) => {
          this.spracheUebersetzung.show(e.modelData);
        }
      });
  }
  detached() {
    this._detachedCommand();
  }

  show(modelData: any) {
    this.idSpracheContainer = modelData.IdSpracheContainer;
    this.loadUebersetzungData()

    this.spracheUebersetzungPopup.instance.show();
  }
  private onSpracheChanged(e) {
    if (e.value == void 0) {
      return;
    }

    this.spracheItemList = this._uebersetzungData.ItemList.filter(c => c.IdSprache === e.value);
    this.currentSprache = this._availableSprachen.find(c => c.Id === e.value);
  }
  private async loadUebersetzungData(): Promise<any> {
    const webEvent = new OnSpracheUebersetzenEvent({ IdSpracheContainer: this.idSpracheContainer, DoSave: false });
    this._uebersetzungData = await this.webEvent.execute(webEvent);
    this._availableSprachen = await this.spracheUebersetzung.loadSprachen(["CanUebersetzen", true]);

    const vorbelegungSprache = this._availableSprachen.find(c => c.IsVorbelegungUebersetzung === true);

    this.onSpracheChanged({ value: vorbelegungSprache.Id });
  }

  private setUebersetzungData(): void {
    const webEvent = new OnSpracheUebersetzenEvent({ IdSpracheContainer: this.idSpracheContainer, DoSave: true, ItemList: this._uebersetzungData.ItemList });
    this.webEvent.execute(webEvent, true)
      .then(() => {
        DevExpress.ui.notify(
          this.localization.translateOnce("base.save_success"),
          "SUCCESS",
          3000);
      });
  }

  private resetUebersetzungData() {
    this._uebersetzungData = undefined;
    this.spracheItemList = undefined;
    this.spracheSelectBox.setOption({ value: null })
  }
}
