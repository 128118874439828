import * as fwx from "../../../../framework/forms/form-export";
import {
    BenachrichtigungEditUtils
} from "./benachrichtigung-edit-utils";

@fwx.autoinject
export class BenachrichtigungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BenachrichtigungUtils: BenachrichtigungEditUtils) {
        super(element, formBaseImport);
        this.id = "benachrichtigung-edit";
        this.title = "benachrichtigung-edit.benachrichtigung-edit_caption";
        this.addModel({
            "id": "$m_Benachrichtigung",
            "webApiAction": "ERP/Stammdaten/Benachrichtigung",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "allowDelete": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BenachrichtigungUtils.verknuepfteEntitaetCommand",
                "bindToFQ": "functions.$f_BenachrichtigungUtils.verknuepfteEntitaetCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BenachrichtigungUtils.doBenachrichtigungGelesen",
                "bindToFQ": "functions.$f_BenachrichtigungUtils.doBenachrichtigungGelesen"
            }
        });
        this.addFunction("$f_BenachrichtigungUtils", $f_BenachrichtigungUtils, "functions.$f_BenachrichtigungUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "benachrichtigung-edit.r_benutzervon_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "IdBenutzerVon",
                "bindToFQ": "models.data.$m_Benachrichtigung.IdBenutzerVon"
            },
            "validationRules": [],
            "id": "r_benutzerVon",
            "options": {
                "optionsName": "r_benutzerVonOptions",
                "optionsNameFQ": "r_benutzerVonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "benachrichtigung-edit.r_benutzer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "IdBenutzer",
                "bindToFQ": "models.data.$m_Benachrichtigung.IdBenutzer"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_benutzer",
            "options": {
                "optionsName": "r_benutzerOptions",
                "optionsNameFQ": "r_benutzerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "benachrichtigung-edit.r_datum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Benachrichtigung.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "benachrichtigung-edit.r_isgelesen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "IsGelesen",
                "bindToFQ": "models.data.$m_Benachrichtigung.IsGelesen"
            },
            "validationRules": [],
            "id": "r_isGelesen",
            "options": {
                "optionsName": "r_isGelesenOptions",
                "optionsNameFQ": "r_isGelesenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "benachrichtigung-edit.r_betreff_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_Benachrichtigung.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "benachrichtigung-edit.r_isprioritaet_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "IsPrioritaet",
                "bindToFQ": "models.data.$m_Benachrichtigung.IsPrioritaet"
            },
            "validationRules": [],
            "id": "r_isPrioritaet",
            "options": {
                "optionsName": "r_isPrioritaetOptions",
                "optionsNameFQ": "r_isPrioritaetOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "benachrichtigung-edit.r_text_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Benachrichtigung",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_Benachrichtigung.Text"
            },
            "validationRules": [],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        super.onConstructionFinished();
    }
}