import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class InventurListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "inventur-list";
        this.title = "inventur-list.inventur-list_caption";
        this.addModel({
            "id": "$m_Inventur",
            "webApiAction": "ERP/Lager/Inventur",
            "webApiExpand": {
                'Status': null,
                'Lager': {
                    'expand': {
                        'Filiale': {
                            'expand': {
                                'Firma': null
                            }
                        }
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_invDatum",
                "caption": "inventur-list.r_invdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_invFirma",
                "caption": "inventur-list.r_invfirma_caption",
                "bindTo": "Lager.Filiale.Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_invFiliale",
                "caption": "inventur-list.r_invfiliale_caption",
                "bindTo": "Lager.Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_invLager",
                "caption": "inventur-list.r_invlager_caption",
                "bindTo": "Lager.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_wertSoll",
                "caption": "inventur-list.r_wertsoll_caption",
                "bindTo": "WertSoll",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_wertDifferenz",
                "caption": "inventur-list.r_wertdifferenz_caption",
                "bindTo": "WertDifferenz",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_wertIst",
                "caption": "inventur-list.r_wertist_caption",
                "bindTo": "WertIst",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_invVerbuchtAm",
                "caption": "inventur-list.r_invverbuchtam_caption",
                "bindTo": "VerbuchtAm",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_invStatus",
                "caption": "inventur-list.r_invstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_inventurGridToolbarOptions",
                "optionsNameFQ": "r_inventurGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Inventur"
            },
            "dataModel": "$m_Inventur",
            "editUrl": "ERP/Lager/Inventur",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_inventurGrid",
            "options": {
                "optionsName": "r_inventurGridOptions",
                "optionsNameFQ": "r_inventurGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}