import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";

@autoinject
export class ProdAuftragService {
  constructor(
    private rest: RestService
  ) { }

  getProdAuftragById(idAufgrag: number): Promise<any> {
    if (!idAufgrag) {
      return Promise.resolve(false);
    }

    return this.rest.get({
      url: `${this.rest.getWebApiUrl("ERP/Produktion/ProdAuftrag")}/${idAufgrag}`,
      getOptions: {
        expand: {
          Artikel: null
        },
      }
    });
  }
  getProdAuftragByNummer(nummer: string): Promise<any> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Produktion/ProdAuftrag"),
      getOptions: {
        where: ["Nummer", nummer]
      }
    });
  }
  getProdAuftragtBySearchtextEindeutig(searchtext: string): Promise<any[]> {
    const getOptions = {
      searchtext: searchtext,
      columns: ["Id"],
      take: 2
    };

    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Produktion/ProdAuftrag"),
      getOptions: getOptions
    });
  }
}