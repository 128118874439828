import {
    autoinject,
    transient
} from "aurelia-framework";
import {
    ICommandData,
    FormBase
} from "../../../../framework/forms/export";
import {
    WebEventService
  } from "../../../../framework/base/export";
import {
    OnPrepareFibuSyncJobEvent,
    OnPrepareFibuSyncJob
} from "../../../../framework-data/events";


@autoinject
@transient()
export class FibuSyncJobEditUtils {
    constructor(
        private webEvent: WebEventService
    ) { }

    form: FormBase;

    bind(form: FormBase, namespace: string) {
        this.form = form;
    }

    doSynchronisierungStarten: ICommandData = {
        id: "doSynchronisierungStarten",
        idCategory: "fibu-sync-job",
        title: "fibu-sync-job.do_synchronisierung_starten",
        icon: "refresh",
        isEnabledExpression: "models.data.$m_FibuSyncJob.CanSave && models.data.$m_FibuSyncJob.Status.Typ == 0",
        execute: async () => {
            const saveResult = await this.form.saveIfDirty();
            if (!saveResult.isValid) {
                return;
            }

            const webEvent = new OnPrepareFibuSyncJobEvent({
                IdFibuSyncJob: this.form.models.data.$m_FibuSyncJob.Id
            });

            const webEventResult: OnPrepareFibuSyncJob = await this.webEvent.execute(webEvent, true);

            this.form.models.reloadAll();
        }
    }
}
