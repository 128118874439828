import { autoinject } from "aurelia-framework";

@autoinject
export class ModelUtilsService {
  private _isDirtyProperty = "_IsDirty";

  constructor() {}

  clearDirty(modelData: any) {
    delete modelData[this._isDirtyProperty];
  }
  setDirty(modelData: any) {
    modelData[this._isDirtyProperty] = true;
  }
  isDirty(modelData: any): boolean {
    return !!modelData[this._isDirtyProperty];
  }
}
