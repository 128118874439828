import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ProvisionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "provision-edit";
        this.title = "provision-edit.provision-edit_caption";
        this.addModel({
            "id": "$m_Provision",
            "webApiAction": "ERP/Provision/Provision",
            "webApiExpand": {
                'Vertreter': null,
                'Firma': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_ProvisionspositionRel",
            "webApiAction": "ERP/Provision/Provisionsposition",
            "webApiExpand": {
                'Fakturaposition': {
                    'columns': [
                        'Id',
                        'IdFaktura',
                        'Bezeichnung',
                        'IdArtikel'
                    ],
                    'expand': {
                        'Faktura': {
                            'columns': [
                                'Id',
                                'IdRechnungsortAdresse',
                                'Nummer',
                                'Datum'
                            ],
                            'expand': {
                                'RechnungsortAdresse': {
                                    'columns': [
                                        'Firmenbez1',
                                        'Ort'
                                    ]
                                }
                            }
                        },
                        'Artikel': {
                            'columns': [
                                'Id',
                                'Nummer'
                            ]
                        }
                    }
                },
                'Vertreter': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_ProvisionspositionEdit",
            "webApiAction": "ERP/Provision/Provisionsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addEditPopup({
            "idContent": "provisionspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ProvisionspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ProvisionspositionEdit.Id"
                }
            }],
            "id": "provisionspositionEditPopup",
            "options": {
                "optionsName": "provisionspositionEditPopupOptions",
                "optionsNameFQ": "provisionspositionEditPopupOptions"
            },
            "caption": "provision-edit.provisionspositioneditpopup_caption",
            "height": "750px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "provision-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provision",
                "bindTo": "Firma.Bezeichnung",
                "bindToFQ": "models.data.$m_Provision.Firma.Bezeichnung",
                "propertyPrefix": "Firma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "provision-edit.r_wertnetto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provision",
                "bindTo": "WertNetto",
                "bindToFQ": "models.data.$m_Provision.WertNetto"
            },
            "validationRules": [],
            "id": "r_wertNetto",
            "options": {
                "optionsName": "r_wertNettoOptions",
                "optionsNameFQ": "r_wertNettoOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "provision-edit.r_bis_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provision",
                "bindTo": "Bis",
                "bindToFQ": "models.data.$m_Provision.Bis"
            },
            "validationRules": [],
            "id": "r_bis",
            "options": {
                "optionsName": "r_bisOptions",
                "optionsNameFQ": "r_bisOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "provision-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provision",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Provision.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_provposFakturaNr",
                "caption": "provision-edit.r_provposfakturanr_caption",
                "bindTo": "Fakturaposition.Faktura.Nummer",
                "width": "80px"
            }, {
                "id": "r_provposFakturaDatum",
                "caption": "provision-edit.r_provposfakturadatum_caption",
                "bindTo": "Fakturaposition.Faktura.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_provposRechnungsortFirmenbez1",
                "caption": "provision-edit.r_provposrechnungsortfirmenbez1_caption",
                "bindTo": "Fakturaposition.Faktura.RechnungsortAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_provposRechnungsortOrt",
                "caption": "provision-edit.r_provposrechnungsortort_caption",
                "bindTo": "Fakturaposition.Faktura.RechnungsortAdresse.Ort",
                "minWidth": 100
            }, {
                "id": "r_provposArtikelNr",
                "caption": "provision-edit.r_provposartikelnr_caption",
                "bindTo": "Fakturaposition.Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_provposArtikelBezeichnung",
                "caption": "provision-edit.r_provposartikelbezeichnung_caption",
                "bindTo": "Fakturaposition.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_provposBasis",
                "caption": "provision-edit.r_provposbasis_caption",
                "bindTo": "Basis",
                "width": "100px",
                "format": "n4"
            }, {
                "id": "r_provposMultiplikator",
                "caption": "provision-edit.r_provposmultiplikator_caption",
                "bindTo": "Multiplikator",
                "width": "100px",
                "format": "n4"
            }, {
                "id": "r_provposWertNetto",
                "caption": "provision-edit.r_provposwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_provisionspositionGridToolbarOptions",
                "optionsNameFQ": "r_provisionspositionGridToolbarOptions"
            },
            "caption": "provision-edit.r_provisionspositiongrid_caption",
            "binding": {
                "dataContext": "$m_Provision"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ProvisionspositionRel",
                "bindTo": "IdProvision",
                "bindToFQ": "models.data.$m_ProvisionspositionRel.IdProvision"
            },
            "dataModel": "$m_Provision",
            "editDataContext": "$m_ProvisionspositionEdit",
            "idEditPopup": "provisionspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_provisionspositionGrid",
            "options": {
                "optionsName": "r_provisionspositionGridOptions",
                "optionsNameFQ": "r_provisionspositionGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}