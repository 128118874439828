import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  OnEvalLieferRechnungsort,
  OnEvalLieferRechnungsortEvent,
  OnImportLieferscheinpositionenInKundenreklamationEvent,
  OnKundenreklamationAbschliessenEvent,
  OnKundenreklamationErsatzauftragErstellen,
  OnKundenreklamationErsatzauftragErstellenEvent,
  OnKundenreklamationWarenuebernahmeErstellen,
  OnKundenreklamationWarenuebernahmeErstellenEvent
} from "../../../../framework-data/events";
import {
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase,
  ICommandData
} from "../../../../framework/forms/export";

import { AsyncService } from '../../../../framework/base/services/async-service';
import { LocationService } from '../../../../framework/base/services/location-service';
import { Geschaeftspartner } from '../../../elements/geschaeftspartner/geschaeftspartner';
import {
  IdxDataGridComponent
} from "../../../interfaces/export";
import {
  DynFelderDataService,
  DynFelderService,
  FilialeService, FirmaService,
  GeschaeftspartnerSearchService,
  GeschaeftspartnerService,
  KalkulationRefreshService,
  LagerService,
  PersonService, ValidationExService
} from "../../../services/export";

@autoinject
@transient()
export class KundenreklamationEditUtils {
  constructor(
    public dynFelderData: DynFelderDataService,
    private _asyncService: AsyncService,
    private _dynFelderService: DynFelderService,
    private _filialeService: FilialeService,
    private _firmaService: FirmaService,
    private _geschaeftspartnerSearchService: GeschaeftspartnerSearchService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _kalkulationRefreshService: KalkulationRefreshService,
    private _lagerService: LagerService,
    private _locationService: LocationService,
    private _personService: PersonService,
    private _validationExService: ValidationExService,
    private _webEventService: WebEventService) { }

  form: FormBase;

  importLieferscheinpositionenCommand: ICommandData = {
    id: "importLieferscheinpositionen",
    title: "kundenreklamation.mark_lieferscheinpositionen_importieren",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_lieferscheinpositionenGrid"];

      const importLieferscheinpositionenEvent = new OnImportLieferscheinpositionenInKundenreklamationEvent({
        IdKundenreklamation: this.form.models.data.$m_Kundenreklamation.Id,
        IdLieferscheinpositionList: grid.instance.getSelectedRowKeys()
      });

      await this._webEventService.execute(importLieferscheinpositionenEvent, true);

      this.form["r_lieferscheinpositionenEditPopup"].instance.hide();
      this.form.models.reloadAll();
    }
  }
  showImportLieferscheinpositionenPopupCommand: ICommandData = {
    id: "showImportLieferscheinpositionen",
    icon: "plus",
    idCategory: "kundenreklamation",
    title: "kundenreklamation.lieferscheinpositionen_importieren",
    sortIndex: 0,
    isEnabledExpression: "models.data.$m_Kundenreklamation.CanSave",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const lieferscheinpositionenGrid: IdxDataGridComponent = this.form["r_lieferscheinpositionenGrid"];
      if (lieferscheinpositionenGrid && lieferscheinpositionenGrid.instance) {
        await this._asyncService.convertToPromise(lieferscheinpositionenGrid.instance.deselectAll());
        lieferscheinpositionenGrid.instance.refresh();
      }

      this.form["r_lieferscheinpositionenEditPopup"].instance.show();
    }
  }
  reklamationAbschliessenCommand: ICommandData = {
    id: "reklamationAbschliessen",
    idCategory: "kundenreklamation",
    icon: "check-square-o",
    title: "kundenreklamation.reklamation_abschliessen",
    sortIndex: 3,
    isEnabledExpression: `models.data.$m_Kundenreklamation.CanSave 
      && models.data.$m_Kundenreklamation.Status.Typ == 0`,
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const validationResult = await this._validationExService.executeServerValidation(
        this.form.models.data.$m_Kundenreklamation.TypeName,
        this.form.models.data.$m_Kundenreklamation.Id,
        "ABSCHLIESSEN"
      );

      if (!validationResult) {
        return;
      }

      await this._webEventService.execute(new OnKundenreklamationAbschliessenEvent({
        IdKundenreklamation: this.form.models.data.$m_Kundenreklamation.Id
      }), true);

      return this.form.models.reloadAll();
    }
  }
  warenuebernahmeErstellenCommand: ICommandData = {
    id: "warenuebernahmeErstellenCommand",
    idCategory: "kundenreklamation",
    icon: "plus-square",
    title: "kundenreklamation.warenuebernahme_erstellen",
    sortIndex: 2,
    isEnabledExpression: `models.data.$m_Kundenreklamation.CanSave 
      && models.data.$m_Kundenreklamation.Status.Typ == 0
      && models.data.$m_Kundenreklamation.WarenuebernahmeStatus == 1`,
    execute: (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      this.warenuebernahmeErstellen(ctrlKeyPressed);
    }
  }
  ersatzlieferscheinErstellenCommand: ICommandData = {
    id: "ersatzlieferscheinErstellenCommand",
    idCategory: "kundenreklamation",
    icon: "plus-square",
    title: "kundenreklamation.ersatzauftrag_erstellen",
    sortIndex: 1,
    isEnabledExpression: "models.data.$m_Kundenreklamation.CanSave",
    execute: (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      this.ersatzauftragErstellen(ctrlKeyPressed);
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.focusFirstEnabledEditor = () => {
      const kunde = <HTMLInputElement>form.element.querySelector("geschaeftspartner[view-model\\.ref='r_kunde'] .dx-searchbox input");
      if (!kunde) {
        return;
      }

      kunde.focus();
    };

    this.dynFelderData.register(this.form, {
      idMainModel: "$m_Kundenreklamation",
      idVorgEntitaetPropertyName: "IdKunde"
    });

    this._kalkulationRefreshService.registerRefresh({
      form: form,
      modelName: "$m_Kundenreklamation",
      kalkulationRef: "r_kalkulation",
      editPopups: ["kundenreklamationspositionEditPopup"]
    });

    form.onSaved.register((r) => {
      this.form.models.onLoadRequired.fire({
        model: this.form.models.getInfo("$m_KundenreklamationspositionRel"),
        onlyCurrentPage: true
      });

      return Promise.resolve();
    });

    form.onEditorValueChanged.register(async (args) => {
      if (args.binding.dataContext === "$m_Kundenreklamation") {
        if (!args.value) {
          return;
        }

        if (args.binding.bindTo === "IdFirma") {
          const model = form.models.data["$m_Kundenreklamation"];
          if (model) {
            model.IdLieferfiliale = await this._filialeService.getIdFilialeVorbelegung(args.value);

            form.onEditorValueChanged.fire({
              binding: {
                dataContext: "$m_Kundenreklamation",
                bindTo: "IdLieferfiliale"
              },
              value: model.IdLieferfiliale
            });
          }
        }

        if (args.binding.bindTo === "IdLieferfiliale") {
          const model = form.models.data["$m_Kundenreklamation"];
          if (model) {
            model.IdLieferlager = await this._lagerService.getIdLagerVorbelegung(args.value);

            const idWaehrung = await this._filialeService.getWaehrungIdOfFiliale(args.value);
            if (idWaehrung != null) {
              model.IdWaehrung = idWaehrung;
            } else {
              model.IdWaehrung = await this._firmaService.getWaehrungIdOfFirma(this.form.models.data.$m_Kundenreklamation.IdFirma);
            }
          }
        }
      }
    });
  }

  async onKundeChanged(e: any) {
    await this.dynFelderData.loadVorbelegungen();

    const onEvalLieferRechnungsortEvent = new OnEvalLieferRechnungsortEvent({ IdKunde: e.detail.value });
    const onEvalLieferRechnungsortData: OnEvalLieferRechnungsort = await this._webEventService.execute(onEvalLieferRechnungsortEvent);

    this.form["r_lieferort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdLieferort);
    this.form["r_rechnungsort"].setGeschaeftpartnerById(onEvalLieferRechnungsortData.IdRechnungsort);

    if (onEvalLieferRechnungsortData.HasMoreThanOneLieferort) {
      this._geschaeftspartnerSearchService.show(r => {
        this.form["r_lieferort"].setGeschaeftpartnerById(r);
      }, () => { }, {
        caption: "erp.select_lieferort",
        filters: [{
          webApiCustomKey: "IdLieferortFuer",
          webApiCustomValue: e.detail.value
        }]
      });
    }

    this.setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen();
  }
  onKundeAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    const idKunde = this.form.models.data.$m_Kundenreklamation.IdKunde;
    if (idKunde == this.form.models.data.$m_Kundenreklamation.IdLieferort) {
      if (this.form.models.data.$m_Kundenreklamation.IdLieferortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Kundenreklamation.IdLieferortAnsprechperson = idPerson;
      }

      this.onLieferortAnsprechpersonChanged({
        detail: {
          value: idPerson
        }
      });
    }

    if (idKunde == this.form.models.data.$m_Kundenreklamation.IdRechnungsort) {
      if (this.form.models.data.$m_Kundenreklamation.IdRechnungsortAnsprechperson == e.detail.previousValue) {
        this.form.models.data.$m_Kundenreklamation.IdRechnungsortAnsprechperson = idPerson;
      }
    }
  }
  async onLieferortAnsprechpersonChanged(e: any) {
    const idPerson = e.detail.value;
    if (!idPerson) {
      return;
    }

    this.form.models.data.$m_Kundenreklamation.LieferortTelefon = await this._personService.getPersonTelefon(idPerson);
  }
  onKundeAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onLieferortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_rechnungsort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }
  onRechnungsortAdresseChanged(e: any) {
    const idGeschaeftspartner = e.detail.value;

    (<Geschaeftspartner>this.form["r_kunde"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
    (<Geschaeftspartner>this.form["r_lieferort"]).refreshGeschaeftspartnerById(idGeschaeftspartner);
  }

  private async warenuebernahmeErstellen(openInNewWindow: boolean) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const args: OnKundenreklamationWarenuebernahmeErstellen = {
      IdKundenreklamation: this.form.models.data.$m_Kundenreklamation.Id
    }
    const webEventResult: OnKundenreklamationWarenuebernahmeErstellen = await this._webEventService.execute(new OnKundenreklamationWarenuebernahmeErstellenEvent(args), true);
    if (!webEventResult || !webEventResult.IdWarenuebernahme) {
      return;
    }

    const navigationUrl = `ERP/Einkauf/Warenuebernahme/${webEventResult.IdWarenuebernahme}`;

    if (openInNewWindow) {
      this._locationService.openWindow(navigationUrl);
      this.form.models.reloadAll();
    } else {
      this._locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  }
  private async ersatzauftragErstellen(openInNewWindow: boolean) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const args: OnKundenreklamationErsatzauftragErstellen = {
      IdKundenreklamation: this.form.models.data.$m_Kundenreklamation.Id
    }
    const webEventResult: OnKundenreklamationErsatzauftragErstellen = await this._webEventService.execute(new OnKundenreklamationErsatzauftragErstellenEvent(args), true);
    if (!webEventResult || !webEventResult.IdAuftrag) {
      return;
    }

    const navigationUrl = `ERP/Verkauf/Auftrag/${webEventResult.IdAuftrag}`;

    if (openInNewWindow) {
      this._locationService.openWindow(navigationUrl);
      this.form.models.reloadAll();
    } else {
      this._locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  }

  private async setFirmaVorbelegungDynFeldLogikFirmaUmstellungBeiMehrerenFirmen() {
    if (this.form.models.data.$m_Kundenreklamation.CanSave && this.form.models.data.$m_Kundenreklamation.Id > 0) {
      return;
    }

    const geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.form.models.data.$m_Kundenreklamation.IdKunde);
    let idFirma = await this._dynFelderService.getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(geschaeftspartner.IdDynamischesFeldContainer);

    if (idFirma == null) {
      idFirma = await this._firmaService.getIdFirmaVorbelegung();
    }

    this.form.models.data.$m_Kundenreklamation.IdFirma = idFirma;

    this.form.onEditorValueChanged.fire({
      binding: {
        dataContext: "$m_Kundenreklamation",
        bindTo: "IdFirma"
      },
      value: idFirma
    });
  }
}
