import { autoinject, valueConverter } from "aurelia-framework";
import { StuecklisteFaktorTyp } from '../enumerations/stueckliste-faktor-typ';

@autoinject
@valueConverter("stuecklisteValueConverter")
export class StuecklisteFaktorValueConverter {
  constructor(
  ) { }

  toView(value: any) {
    if (value == null) {
      return "";
    }

    let faktor = value;

    switch (faktor) {
      case StuecklisteFaktorTyp.ProFW:
        value = "pro Fertigartikel";
        break;
      case StuecklisteFaktorTyp.ProProduktion:
        value = "pro Produktion";
        break;
    }

    return value;
  }
}
