import * as fwx from "../../../../framework/forms/form-export";
import {
    DatenbankInfoListUtils
} from "./datenbank-info-list-utils";

@fwx.autoinject
export class DatenbankInfoListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_DatenbankInfoListUtils: DatenbankInfoListUtils) {
        super(element, formBaseImport);
        this.id = "datenbank-info-list";
        this.title = "datenbank-info-list.datenbank-info-list_caption";
        this.addCommand({
            "binding": {
                "bindTo": "$f_DatenbankInfoListUtils.doAktualisierenCommand",
                "bindToFQ": "functions.$f_DatenbankInfoListUtils.doAktualisierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DatenbankInfoListUtils.doKillSessionsCommand",
                "bindToFQ": "functions.$f_DatenbankInfoListUtils.doKillSessionsCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_DatenbankInfoListUtils.doStatistikenAktualisierenCommand",
                "bindToFQ": "functions.$f_DatenbankInfoListUtils.doStatistikenAktualisierenCommand"
            }
        });
        this.addFunction("$f_DatenbankInfoListUtils", $f_DatenbankInfoListUtils, "functions.$f_DatenbankInfoListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_text",
                "caption": "datenbank-info-list.r_text_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_sessionId",
                "caption": "datenbank-info-list.r_sessionid_caption",
                "bindTo": "SessionId",
                "width": "80px"
            }, {
                "id": "r_status",
                "caption": "datenbank-info-list.r_status_caption",
                "bindTo": "Status",
                "width": "120px"
            }, {
                "id": "r_cpuTime",
                "caption": "datenbank-info-list.r_cputime_caption",
                "bindTo": "CpuTime",
                "width": "120px"
            }, {
                "id": "r_totalElapsedTime",
                "caption": "datenbank-info-list.r_totalelapsedtime_caption",
                "bindTo": "TotalElapsedTime",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_queryGridToolbarOptions",
                "optionsNameFQ": "r_queryGridToolbarOptions"
            },
            "binding": {},
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_queryGrid",
            "options": {
                "optionsName": "r_queryGridOptions",
                "optionsNameFQ": "r_queryGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}