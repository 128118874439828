import { autoinject, bindable, OverrideContext, observable } from "aurelia-framework";
import { IListViewOptions, ListView } from "../../../../framework/forms/elements/list-view/export";
import { ScopeContainer, GlobalizationService, RestService, LocalizationService, WebEventService } from "../../../../framework/base/export";
import { OnLagerbestandRequestEvent, OnLagerbestandRequest } from '../../../../framework-data/events';

@autoinject
export class Lagerbestand {
  constructor(
    private rest: RestService,
    private globalization: GlobalizationService,
    private localization: LocalizationService,
    private webEvent: WebEventService
  ) { }

  @bindable @observable idArtikel;
  @bindable @observable idFiliale;

  scopeContainer: ScopeContainer;

  itemList: any[];

  isVisible: boolean = false;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idFilialeChanged() {
    this.loadData();
  }
  idArtikelChanged() {
    this.loadData();
  }

  private async loadData() {
    if (this.idArtikel) {
      const result: OnLagerbestandRequest = await this.webEvent.execute(new OnLagerbestandRequestEvent({
        IdArtikel: this.idArtikel,
        IdFiliale: this.idFiliale || 0
      }));

      if (result.LagerbestandList.length > 0) {
        result.LagerbestandList.forEach(item => {
          const lines = [];
          if (item.Bestand) {
            lines.push(`${this.localization.translateOnce("infobar.bestand")}: ${this.globalization.format(item.Bestand, "n2")}`);
          }
          if (item.Bestellt) {
            lines.push(`${this.localization.translateOnce("infobar.bestellt")}: ${this.globalization.format(item.Bestellt, "n2")}`);
          }
          if (item.Produktion) {
            lines.push(`${this.localization.translateOnce("infobar.produktion")}: ${this.globalization.format(item.Produktion, "n2")}`);
          }
          if (item.Auftrag) {
            lines.push(`${this.localization.translateOnce("infobar.auftrag")}: ${this.globalization.format(item.Auftrag, "n2")}`);
          }
          if (item.Reserviert) {
            lines.push(`${this.localization.translateOnce("infobar.reserviert")}: ${this.globalization.format(item.Reserviert, "n2")}`);
          }
          if (item.Frei) {
            lines.push(`${this.localization.translateOnce("infobar.frei")}: ${this.globalization.format(item.Frei, "n2")}`);
          }

          item["_info"] = lines.join("\n");
        });

        this.itemList = result.LagerbestandList;
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    } else {
      this.isVisible = false;
    }
  }
}
