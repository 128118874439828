import * as fwx from "../../../../framework/forms/form-export";
import {
    KonditionsfeldEditUtils
} from "./konditionsfeld-edit-utils";

@fwx.autoinject
export class KonditionsfeldEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_KonditionsfeldEditUtils: KonditionsfeldEditUtils) {
        super(element, formBaseImport);
        this.id = "konditionsfeld-edit";
        this.title = "konditionsfeld-edit.konditionsfeld-edit_caption";
        this.addModel({
            "id": "$m_Konditionsfeld",
            "webApiAction": "ERP/Kalkulation/Konditionsfeld",
            "webApiExpand": {
                'KonditionsfeldWooCommerce': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addFunction("$f_KonditionsfeldEditUtils", $f_KonditionsfeldEditUtils, "functions.$f_KonditionsfeldEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "konditionsfeld-edit.r_code_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "Code",
                "bindToFQ": "models.data.$m_Konditionsfeld.Code"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 10
                    }]
                }
            }],
            "id": "r_code",
            "options": {
                "optionsName": "r_codeOptions",
                "optionsNameFQ": "r_codeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "konditionsfeld-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Konditionsfeld.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "konditionsfeld-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_Konditionsfeld.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "konditionsfeldTyp",
            "filters": [],
            "caption": "konditionsfeld-edit.r_typ_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_Konditionsfeld.Typ"
            },
            "validationRules": [],
            "id": "r_typ",
            "options": {
                "optionsName": "r_typOptions",
                "optionsNameFQ": "r_typOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerTyp",
            "filters": [],
            "caption": "konditionsfeld-edit.r_geschaeftspartnertyp_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "GeschaeftspartnerTyp",
                "bindToFQ": "models.data.$m_Konditionsfeld.GeschaeftspartnerTyp"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnertyp",
            "options": {
                "optionsName": "r_geschaeftspartnertypOptions",
                "optionsNameFQ": "r_geschaeftspartnertypOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "konditionsfeld-edit.r_anzahlkommastellen_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "AnzKommastellen",
                "bindToFQ": "models.data.$m_Konditionsfeld.AnzKommastellen"
            },
            "validationRules": [],
            "id": "r_anzahlKommastellen",
            "options": {
                "optionsName": "r_anzahlKommastellenOptions",
                "optionsNameFQ": "r_anzahlKommastellenOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "konditionsfeldWaehrung",
            "filters": [],
            "caption": "konditionsfeld-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "Waehrung",
                "bindToFQ": "models.data.$m_Konditionsfeld.Waehrung"
            },
            "validationRules": [],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "konditionsfeld-edit.r_haswertper_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "HasWertPer",
                "bindToFQ": "models.data.$m_Konditionsfeld.HasWertPer"
            },
            "validationRules": [],
            "id": "r_hasWertPer",
            "options": {
                "optionsName": "r_hasWertPerOptions",
                "optionsNameFQ": "r_hasWertPerOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "konditionsfeld-edit.r_hasstaffel_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "HasStaffel",
                "bindToFQ": "models.data.$m_Konditionsfeld.HasStaffel"
            },
            "validationRules": [],
            "id": "r_hasStaffel",
            "options": {
                "optionsName": "r_hasStaffelOptions",
                "optionsNameFQ": "r_hasStaffelOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "konditionsfeld-edit.r_vorzeichenwechselbeistorno_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "DoVorzeichenwechselBeiStorno",
                "bindToFQ": "models.data.$m_Konditionsfeld.DoVorzeichenwechselBeiStorno"
            },
            "validationRules": [],
            "id": "r_vorzeichenwechselBeiStorno",
            "options": {
                "optionsName": "r_vorzeichenwechselBeiStornoOptions",
                "optionsNameFQ": "r_vorzeichenwechselBeiStornoOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "konditionsfeld-edit.r_isartikelpflicht_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "IsArtikelPflicht",
                "bindToFQ": "models.data.$m_Konditionsfeld.IsArtikelPflicht"
            },
            "validationRules": [],
            "id": "r_isArtikelPflicht",
            "options": {
                "optionsName": "r_isArtikelPflichtOptions",
                "optionsNameFQ": "r_isArtikelPflichtOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "konditionsfeld-edit.r_supportsexim_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "SupportsExim",
                "bindToFQ": "models.data.$m_Konditionsfeld.SupportsExim"
            },
            "validationRules": [],
            "id": "r_supportsExim",
            "options": {
                "optionsName": "r_supportsEximOptions",
                "optionsNameFQ": "r_supportsEximOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "konditionsfeld-edit.r_vorbelegungwert_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "VorbWert",
                "bindToFQ": "models.data.$m_Konditionsfeld.VorbWert"
            },
            "validationRules": [],
            "id": "r_vorbelegungWert",
            "options": {
                "optionsName": "r_vorbelegungWertOptions",
                "optionsNameFQ": "r_vorbelegungWertOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "customs": [{
                "key": "Waehrung",
                "value": "models.data.$m_Konditionsfeld.Waehrung"
            }],
            "filters": [],
            "caption": "konditionsfeld-edit.r_vorbelegungwaehrung_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "IdVorbWaehrung",
                "bindToFQ": "models.data.$m_Konditionsfeld.IdVorbWaehrung"
            },
            "validationRules": [],
            "id": "r_vorbelegungWaehrung",
            "options": {
                "optionsName": "r_vorbelegungWaehrungOptions",
                "optionsNameFQ": "r_vorbelegungWaehrungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "konditionsfeld-edit.r_vorbelegungwertper_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "VorbWertPer",
                "bindToFQ": "models.data.$m_Konditionsfeld.VorbWertPer"
            },
            "validationRules": [],
            "id": "r_vorbelegungWertPer",
            "options": {
                "optionsName": "r_vorbelegungWertPerOptions",
                "optionsNameFQ": "r_vorbelegungWertPerOptions"
            },
            "isDisabledExpression": "models.data.$m_Konditionsfeld.HasWertPer != true"
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "konditionsfeld-edit.r_vorbelegungstaffel_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "VorbStaffel",
                "bindToFQ": "models.data.$m_Konditionsfeld.VorbStaffel"
            },
            "validationRules": [],
            "id": "r_vorbelegungStaffel",
            "options": {
                "optionsName": "r_vorbelegungStaffelOptions",
                "optionsNameFQ": "r_vorbelegungStaffelOptions"
            },
            "isDisabledExpression": "models.data.$m_Konditionsfeld.HasStaffel != true"
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "vorbGueltigVon",
            "filters": [],
            "caption": "konditionsfeld-edit.r_vorbelegunggueltigvon_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "VorbGueltigVon",
                "bindToFQ": "models.data.$m_Konditionsfeld.VorbGueltigVon"
            },
            "validationRules": [],
            "id": "r_vorbelegungGueltigVon",
            "options": {
                "optionsName": "r_vorbelegungGueltigVonOptions",
                "optionsNameFQ": "r_vorbelegungGueltigVonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "vorbGueltigBis",
            "filters": [],
            "caption": "konditionsfeld-edit.r_vorbelegunggueltigbis_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "VorbGueltigBis",
                "bindToFQ": "models.data.$m_Konditionsfeld.VorbGueltigBis"
            },
            "validationRules": [],
            "id": "r_vorbelegungGueltigBis",
            "options": {
                "optionsName": "r_vorbelegungGueltigBisOptions",
                "optionsNameFQ": "r_vorbelegungGueltigBisOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "konditionsfeldWooCommerceTyp",
            "filters": [],
            "caption": "konditionsfeld-edit.r_woocommercetyp_caption",
            "binding": {
                "dataContext": "$m_Konditionsfeld",
                "bindTo": "KonditionsfeldWooCommerce.Typ",
                "bindToFQ": "models.data.$m_Konditionsfeld.KonditionsfeldWooCommerce.Typ",
                "propertyPrefix": "KonditionsfeldWooCommerce"
            },
            "validationRules": [],
            "id": "r_wooCommerceTyp",
            "options": {
                "optionsName": "r_wooCommerceTypOptions",
                "optionsNameFQ": "r_wooCommerceTypOptions"
            }
        });
        super.onConstructionFinished();
    }
}