import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    AuftragspositionEditUtils
} from "./auftragsposition-edit-utils";

@fwx.autoinject
export class AuftragspositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_AuftragspositionEditUtils: AuftragspositionEditUtils) {
        super(element, formBaseImport);
        this.id = "auftragsposition-edit";
        this.title = "auftragsposition-edit.auftragsposition-edit_caption";
        this.addModel({
            "id": "$m_Auftragsposition",
            "webApiAction": "ERP/Verkauf/Auftragsposition",
            "webApiExpand": {
                'Artikel': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Auftrag.CanSave && !models.data.$m_Auftrag.IsIcAuftrag && models.data.$m_Auftragsposition.IsPositionStuecklistenkomponente == false",
            "custom": {
                'copyFunc': '$f_AuftragspositionEditUtils.copyAuftragsposition'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Auftragsposition.IdAuftrag",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragsgruppeEdit",
            "webApiAction": "ERP/Verkauf/Auftragsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragspositionStuecklisteRel",
            "webApiAction": "ERP/Verkauf/Auftragsposition",
            "webApiExpand": {
                'Artikel': null,
                'KalkulationContainer': null,
                'Auftragsgruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
                'AuftragspositionStuecklisteInfo': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_AuftragspositionStuecklisteEdit",
            "webApiAction": "ERP/Verkauf/Auftragsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragspositionEditUtils.showArtikelErsetzenCommand",
                "bindToFQ": "functions.$f_AuftragspositionEditUtils.showArtikelErsetzenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragspositionEditUtils.calcLieferdatumCommand",
                "bindToFQ": "functions.$f_AuftragspositionEditUtils.calcLieferdatumCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragspositionEditUtils.recalcLieferdatumCommand",
                "bindToFQ": "functions.$f_AuftragspositionEditUtils.recalcLieferdatumCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragspositionEditUtils.showVariablerSetartikelKomponentenAuswahlCommand",
                "bindToFQ": "functions.$f_AuftragspositionEditUtils.showVariablerSetartikelKomponentenAuswahlCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AuftragspositionEditUtils.showVariablerSetartikelPreisAendernCommand",
                "bindToFQ": "functions.$f_AuftragspositionEditUtils.showVariablerSetartikelPreisAendernCommand"
            }
        });
        this.addEditPopup({
            "idContent": "auftragspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AuftragspositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AuftragspositionStuecklisteEdit.Id"
                }
            }],
            "id": "auftragspositionEditPopup",
            "options": {
                "optionsName": "auftragspositionEditPopupOptions",
                "optionsNameFQ": "auftragspositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "auftragsgruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_AuftragsgruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_AuftragsgruppeEdit.Id"
                }
            }],
            "id": "auftragsgruppeEditPopup",
            "options": {
                "optionsName": "auftragsgruppeEditPopupOptions",
                "optionsNameFQ": "auftragsgruppeEditPopupOptions"
            },
            "caption": "auftragsposition-edit.auftragsgruppeeditpopup_caption",
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AuftragspositionEditUtils", $f_AuftragspositionEditUtils, "functions.$f_AuftragspositionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "auftragsposition-edit.r_menge_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "MengeVerpEH",
                "bindToFQ": "models.data.$m_Auftragsposition.MengeVerpEH"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_AuftragspositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_menge",
            "options": {
                "optionsName": "r_mengeOptions",
                "optionsNameFQ": "r_mengeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": [
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Auftragsposition.IdArtikel"
                }],
                ["IsVerkaufseinheit", true]
            ],
            "filters": [],
            "caption": "auftragsposition-edit.r_einheit_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Auftragsposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_AuftragspositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_aufpPosNr",
                "caption": "auftragsposition-edit.r_aufpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_aufpArtikelNr",
                "caption": "auftragsposition-edit.r_aufpartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_aufpBezeichnung",
                "caption": "auftragsposition-edit.r_aufpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_aufpMengeVerpEH",
                "caption": "auftragsposition-edit.r_aufpmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpMengeOffenVerpEH",
                "caption": "auftragsposition-edit.r_aufpmengeoffenverpeh_caption",
                "bindTo": "MengeOffenVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpMengeResVerpEH",
                "caption": "auftragsposition-edit.r_aufpmengeresverpeh_caption",
                "bindTo": "MengeReserviertVerpEH",
                "width": "80px",
                "format": "n2",
                "onCellPrepared": "functions.$f_AuftragspositionEditUtils.onMengeReserviertCellPrepared"
            }, {
                "id": "r_aufpMengeLieferscheinVerpEH",
                "caption": "auftragsposition-edit.r_aufpmengelieferscheinverpeh_caption",
                "bindTo": "MengeLieferscheinVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpMengeGeliefertVerpEH",
                "caption": "auftragsposition-edit.r_aufpmengegeliefertverpeh_caption",
                "bindTo": "MengeGeliefertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpEinheit",
                "caption": "auftragsposition-edit.r_aufpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_aufpFaktor",
                "caption": "auftragsposition-edit.r_aufpfaktor_caption",
                "bindTo": "AuftragspositionStuecklisteInfo.Faktor",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.StuecklisteFaktorTyp"
            }, {
                "id": "r_aufpWertNetto",
                "caption": "auftragsposition-edit.r_aufpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpWertSteuer",
                "caption": "auftragsposition-edit.r_aufpwertsteuer_caption",
                "bindTo": "KalkulationContainer.WertSteuer",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufpWertBrutto",
                "caption": "auftragsposition-edit.r_aufpwertbrutto_caption",
                "bindTo": "KalkulationContainer.WertBrutto",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_auftragspositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_auftragspositionStuecklistenGridToolbarOptions"
            },
            "caption": "auftragsposition-edit.r_auftragspositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Auftragsposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_AuftragspositionStuecklisteRel",
                "bindTo": "IdSetartikelAuftragsposition",
                "bindToFQ": "models.data.$m_AuftragspositionStuecklisteRel.IdSetartikelAuftragsposition"
            },
            "dataModel": "$m_Auftragsposition",
            "editDataContext": "$m_AuftragspositionStuecklisteEdit",
            "idEditPopup": "auftragspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_auftragspositionStuecklistenGrid",
            "options": {
                "optionsName": "r_auftragspositionStuecklistenGridOptions",
                "optionsNameFQ": "r_auftragspositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "auftragsposition-edit.r_isvariablersetartikel_caption",
            "isReadOnlyExpression": "models.data.$m_Auftragsposition.Id > 0",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IsVariablerSetartikel",
                "bindToFQ": "models.data.$m_Auftragsposition.IsVariablerSetartikel"
            },
            "validationRules": [],
            "id": "r_isVariablerSetartikel",
            "options": {
                "optionsName": "r_isVariablerSetartikelOptions",
                "optionsNameFQ": "r_isVariablerSetartikelOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "auftragsgruppe",
            "filter": ["IdAuftrag", {
                "isBound": true,
                "expression": "models.data.$m_Auftragsposition.IdAuftrag"
            }],
            "filters": [],
            "caption": "auftragsposition-edit.r_auftragsgruppe_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IdAuftragsgruppe",
                "bindToFQ": "models.data.$m_Auftragsposition.IdAuftragsgruppe"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_AuftragspositionEditUtils.onAuftragsgruppeChanged",
            "id": "r_auftragsgruppe",
            "options": {
                "optionsName": "r_auftragsgruppeOptions",
                "optionsNameFQ": "r_auftragsgruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "auftragsposition-edit.r_posnr_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Auftragsposition.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "auftragsposition-edit.r_kundeartikelnr_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "KundeArtikelNr",
                "bindToFQ": "models.data.$m_Auftragsposition.KundeArtikelNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_kundeArtikelNr",
            "options": {
                "optionsName": "r_kundeArtikelNrOptions",
                "optionsNameFQ": "r_kundeArtikelNrOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "auftragsposition-edit.r_kundeartikelbezeichnung_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "KundeArtikelbezeichnung",
                "bindToFQ": "models.data.$m_Auftragsposition.KundeArtikelbezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_kundeArtikelbezeichnung",
            "options": {
                "optionsName": "r_kundeArtikelbezeichnungOptions",
                "optionsNameFQ": "r_kundeArtikelbezeichnungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "positionsart",
            "filters": [],
            "caption": "auftragsposition-edit.r_positionsart_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IdPositionsart",
                "bindToFQ": "models.data.$m_Auftragsposition.IdPositionsart"
            },
            "validationRules": [],
            "id": "r_positionsart",
            "options": {
                "optionsName": "r_positionsartOptions",
                "optionsNameFQ": "r_positionsartOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Auftrag.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "auftragsposition-edit.r_lieferlager_caption",
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsStreckengeschaeft || models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Auftragsposition.IdLieferlager"
            },
            "validationRules": [],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "auftragsposition-edit.r_status_caption",
            "isReadOnly": true,
            "isReadOnlyExpression": "models.data.$m_Auftrag.IsIcAuftrag",
            "binding": {
                "dataContext": "$m_Auftragsposition",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Auftragsposition.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_artikelErsetzenPopup",
            "options": {
                "optionsName": "r_artikelErsetzenPopupOptions",
                "optionsNameFQ": "r_artikelErsetzenPopupOptions"
            },
            "caption": "auftragsposition-edit.r_artikelersetzenpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_AuftragspositionEditUtils.doArtikelErsetzenCommand",
                    "bindToFQ": "functions.$f_AuftragspositionEditUtils.doArtikelErsetzenCommand"
                }
            }]
        });
        super.onConstructionFinished();
    }
}