import * as fwx from "../../../../framework/forms/form-export";
import {
    NewsletterEmpfaengerEditUtils
} from "./newsletter-empfaenger-edit-utils";

@fwx.autoinject
export class NewsletterEmpfaengerEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_NewsletterEmpfaengerEditUtils: NewsletterEmpfaengerEditUtils) {
        super(element, formBaseImport);
        this.id = "newsletter-empfaenger-edit";
        this.title = "newsletter-empfaenger-edit.newsletter-empfaenger-edit_caption";
        this.addModel({
            "id": "$m_Empfaenger",
            "webApiAction": "ERP/Stammdaten/NewsletterEmpfaenger",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_EmpfaengerElementRel",
            "webApiAction": "ERP/Stammdaten/NewsletterEmpfaengerElement",
            "webApiExpand": {
                'Person': {
                    'expand': {
                        'Geschaeftspartner': {
                            'expand': {
                                'Adresse': null
                            }
                        },
                        'Anrede': null
                    }
                }
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addFunction("$f_NewsletterEmpfaengerEditUtils", $f_NewsletterEmpfaengerEditUtils, "functions.$f_NewsletterEmpfaengerEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "newsletter-empfaenger-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Empfaenger",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Empfaenger.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_persAnrede",
                "caption": "newsletter-empfaenger-edit.r_persanrede_caption",
                "bindTo": "Person.Anrede.Bezeichnung",
                "width": "80px"
            }, {
                "id": "r_persTitelVorne",
                "caption": "newsletter-empfaenger-edit.r_perstitelvorne_caption",
                "bindTo": "TitelVorne",
                "width": "80px"
            }, {
                "id": "r_persVorname",
                "caption": "newsletter-empfaenger-edit.r_persvorname_caption",
                "bindTo": "Vorname"
            }, {
                "id": "r_persNachname",
                "caption": "newsletter-empfaenger-edit.r_persnachname_caption",
                "bindTo": "Nachname"
            }, {
                "id": "r_persTitelHinten",
                "caption": "newsletter-empfaenger-edit.r_perstitelhinten_caption",
                "bindTo": "TitelHinten",
                "width": "80px"
            }, {
                "id": "r_persEmail",
                "caption": "newsletter-empfaenger-edit.r_persemail_caption",
                "bindTo": "Email",
                "minWidth": 100
            }, {
                "id": "r_persGPNummer",
                "caption": "newsletter-empfaenger-edit.r_persgpnummer_caption",
                "bindTo": "Person.Geschaeftspartner.Nummer",
                "width": "80px"
            }, {
                "id": "r_persGPFirmenbez1",
                "caption": "newsletter-empfaenger-edit.r_persgpfirmenbez1_caption",
                "bindTo": "Person.Geschaeftspartner.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_persGPOrt",
                "caption": "newsletter-empfaenger-edit.r_persgport_caption",
                "bindTo": "Person.Geschaeftspartner.Adresse.Ort",
                "minWidth": 100
            }, {
                "id": "r_persBriefanrede",
                "caption": "newsletter-empfaenger-edit.r_persbriefanrede_caption",
                "bindTo": "Briefanrede",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_ElementGridToolbarOptions",
                "optionsNameFQ": "r_ElementGridToolbarOptions"
            },
            "caption": "newsletter-empfaenger-edit.r_elementgrid_caption",
            "binding": {
                "dataContext": "$m_Empfaenger"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EmpfaengerElementRel",
                "bindTo": "IdNewsletterEmpfaenger",
                "bindToFQ": "models.data.$m_EmpfaengerElementRel.IdNewsletterEmpfaenger"
            },
            "dataModel": "$m_Empfaenger",
            "onItemClick": "functions.$f_NewsletterEmpfaengerEditUtils.onPersonClick($event)",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Empfaenger",
                    "bindTo": "$f_NewsletterEmpfaengerEditUtils.deleteSelectedCommand",
                    "bindToFQ": "functions.$f_NewsletterEmpfaengerEditUtils.deleteSelectedCommand"
                }
            }],
            "id": "r_ElementGrid",
            "options": {
                "optionsName": "r_ElementGridOptions",
                "optionsNameFQ": "r_ElementGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}