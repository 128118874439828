import {
  autoinject
} from "aurelia-framework";
import {
  RestService
} from "../../framework/base/export";

@autoinject
export class FilialeService {
  constructor(
    private _restService: RestService) { }

  async getIdFilialeVorbelegung(idFirma: number): Promise<number> {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Filiale"),
      getOptions: {
        columns: ["Id"],
        where: [["IdFirma", idFirma], ["IsVorbelegung", true]]
      }
    });

    if (r && r.length > 0) {
      return r[0].Id;
    } else {
      return null;
    }
  }

  async getWaehrungIdOfFiliale(idFiliale: number): Promise<number> {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Filiale/${idFiliale}`)
    });

    if (r) {
      return r.IdWaehrung;
    } else {
      return null;
    }
  }
}
