import {
  autoinject,
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";

import {
  RestService
} from "../../framework/base/export";

import {
  NotificationTyp
} from "../enumerations/export";

@autoinject
export class KonditionService {
  private _konditionFeld;

  constructor(
    private eventAggregator: EventAggregator,
    private rest: RestService
  ) { 
    this.initializeSubscribers();
  }

  reload(): Promise<any> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Kalkulation/Konditionsfeld")
    }).then((r: any[]) => {
      this._konditionFeld = {};

      if (r == void 0 || r.length == 0) {
        return;
      }

      r.forEach(i => {
        this._konditionFeld[i.Id] = i;
      });
    });
  }

  getKonditionFeldById(idKonditionFeld: number): any {
    return idKonditionFeld == void 0
      ? null
      : this._konditionFeld[idKonditionFeld];
  }

  getKalkulationInputItems(idKalkulationContainer: number): Promise<any[]> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Kalkulation/KalkulationInput"),
      getOptions: {
        where: ["IdKalkulationContainer", idKalkulationContainer]
      }
    });
  }
  getKalkulationOutputItems(idKalkulationContainer: number): Promise<any[]> {
    return this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Kalkulation/KalkulationOutput"),
      getOptions: {
        where: ["IdKalkulationContainer", idKalkulationContainer]
      }
    });
  }

  private initializeSubscribers() {
    this.eventAggregator
      .subscribe(
        NotificationTyp.konditionsfeldChanged,
        this.reload.bind(this)
      );
  }
}