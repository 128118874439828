import {
  autoinject,
  bindable,
  createOverrideContext,
  OverrideContext,
  TemplatingEngine,
  TaskQueue,
  observable,
  View
} from "aurelia-framework";
import {
  ScopeContainer
} from "../../../base/export";
import {
  FormBase
} from "../../classes/form-base";
import {
  ToolbarService
} from "../../services/toolbar-service";
import {
  PopupInfoService
} from "../../services/popup-info-service";
import {
  ICustomEditPopupShowOptions
} from "./custom-edit-popup-show-options";
import { ObjectService } from '../../../base/services/object-service';
import { TemplatingExService } from '../../../base/services/templating-ex-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class CustomEditPopup {
  private _isInitialized: boolean = false;
  private _showOptions: ICustomEditPopupShowOptions;
  private _idToolbar = "popupToolbar";
  private _createdToolbarView: View;

  constructor(
    private toolbar: ToolbarService,
    private popupInfo: PopupInfoService,
    private objectService: ObjectService,
    private templatingEngine: TemplatingEngine,
    private taskQueue: TaskQueue,
    private templatingExService: TemplatingExService,
    private eventAggregator: EventAggregator
  ) { }

  scope: any;
  scopeContainer: ScopeContainer;

  popup: any;
  popupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    titleTemplate: (container: Element) => {
      const toolbar = document.createElement("toolbar");
      toolbar.setAttribute("options.bind", this._idToolbar);

      container.appendChild(toolbar);

      this._createdToolbarView = this.templatingEngine.enhance({
        element: toolbar,
        bindingContext: this.scopeContainer.scope.bindingContext,
        overrideContext: this.scopeContainer.scope.overrideContext
      });
    },
    showCloseButton: false,
    onShowing: (e) => {
      this.popupInfo.onShowPopup({
        owner: this,
        popup: <DevExpress.ui.dxPopup>e.component,
        form: this.form.currentViewModel,
        executeCommand: (idCommand) => {
          this.form.currentViewModel.executeCommand(idCommand);
        },
        handleChangedData: () => {
          return this.form.currentViewModel.handleChangedData();
        },
        hasChangedData: () => {
          return this.form.currentViewModel.models.hasChangedData();
        }
      });
    },
    onShown: (e) => {
      e.component.repaint();
    },
    onHiding: (e) => {
      this.popupInfo.onHidePopup(<DevExpress.ui.dxPopup>e.component);
    },
    onDisposing: () => {
      if (this._createdToolbarView) {
        this.templatingExService.destroyView(this._createdToolbarView);
        this._createdToolbarView = null;
      }
    }
  };

  @bindable idForm: string;
  @bindable height: string;
  @bindable maxWidth: string;
  @bindable maxHeight: string;
  @bindable delayedInitialize: boolean;
  @observable form: any;

  get isVisible() {
    return this.popup
      && this.popup.instance
      && this.popup.instance.option("visible");
  }

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    if (this.height != void (0)) {
      this.popupOptions.height = this.height;
    }
    if (this.maxWidth != void (0)) {
      this.popupOptions.maxWidth = this.maxWidth;
    }
    if (this.maxHeight != void(0)) {
      this.popupOptions.maxHeight = this.maxHeight;
    }

    (<any>this.popupOptions).onContentTemplateRendered = (e) => {
      if (this.delayedInitialize === false) {
        return;
      }

      this.taskQueue.queueTask(() => {
        e.instance.show();
      })
    }

    if (this.delayedInitialize === false) {
      this.taskQueue.queueTask(() => {
        setTimeout(() => {
          this.initializeContent();
        }, 1500);
      });
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  show(options: ICustomEditPopupShowOptions) {
    this._showOptions = options;

    if (!this._isInitialized) {
      this.initializeContent();
      //in onContentTemplateRendered wird das show gemacht!

      return;
    }

    this.form.viewScrollInfo = this._showOptions.viewScrollInfo;
    this.popup.instance.show();
  }
  hide() {
    this.popup.instance.hide();
  }
  closeCurrentPopup() {
    this.hide();
  }

  private initializeContent() {
    this._isInitialized = true;

    const popup: DevExpress.ui.dxPopup = this.popup.instance;
    popup.option("deferRendering", false);

    let firstShownAction = (content: FormBase) => {
      content.callOnCommandsLoaded(() => {
        const toolbarOptions = this.toolbar.createToolbarOptions(
          content.scopeContainer,
          content.title,
          content.commands.getCommands());

        content.binding.observe({
          scopeContainer: content.scopeContainer,
          expression: "title",
          callback: (newVal) => {
            toolbarOptions.title = newVal;
          }
        });

        this[this._idToolbar] = toolbarOptions;
      });

      content.models.onLoaded.register(e => {
        if (e.model !== content.models.modelWithKeyId) {
          return Promise.resolve();
        }
        if (!e.data) {
          return Promise.resolve();
        }

        if (!this._showOptions || !this._showOptions.setValuesOnModelWithKeyIdLoaded) {
          return Promise.resolve();
        }

        const setValues = this._showOptions.setValuesOnModelWithKeyIdLoaded;

        for (let key in setValues) {
          this.objectService.setValue(e.data, key, setValues[key]);
          e.data[key] = setValues[key];
        }

        return Promise.resolve();
      });

      content.models.onSaved.register(e => {
        if (this._showOptions && this._showOptions.savedCallback) {
          this._showOptions.savedCallback(content);
        }

        return Promise.resolve();
      });
    };

    popup.on({
      showing: e => {
        const content: Element = e.component.content();
        content.parentElement.classList.add("t--popup");
      },
      shown: async () => {
        const content: FormBase = await this.templatingExService.waitForViewModel(this.form);

        content.viewScrollInfo = this._showOptions.viewScrollInfo;

        if (firstShownAction) {
          firstShownAction(content);
          firstShownAction = null;
        }

        if (this._showOptions && this._showOptions.mappings) {
          for (let key in this._showOptions.mappings) {
            content.variables.data[key] = this._showOptions.mappings[key];
          }
        }
        this.eventAggregator.publish("editpopup:shown", {
          form: content
        });

        content.dispatchOnReady();
      },
      hiding: () => {
        if (!this._showOptions || !this._showOptions.closeCallback) {
          return;
        }

        this._showOptions.closeCallback(this.form.currentViewModel);
      },
      hidden: () => {
        if (this._showOptions && this._showOptions.mappings) {
          const content: FormBase = this.form.currentViewModel;
          for (let key in this._showOptions.mappings) {
            content.variables.data[key] = null;
          }
        }
        this.eventAggregator.publish("editpopup:hidden", {
          form: this.form.currentController.viewModel
        });

        this._showOptions = null;
      }
    })
  }
}
