import { IdxTreeViewComponent } from './../../interfaces/dx-components';
import { RestService } from './../../../framework/base/services/rest-service';
import { ScopeContainer } from './../../../framework/base/classes/scope-container';
import { autoinject, Scope, createOverrideContext, bindable, observable } from 'aurelia-framework';

@autoinject
export class Artikelkategorie {
  constructor(
    private restService: RestService
  ) { }

  @bindable @observable mainModel: any;
  scope: Scope;
  scopeContainer: ScopeContainer;
  treeViewDataSource: any[];
  nodeKey: string;

  artikelkategorieTreeView: IdxTreeViewComponent;
  artikelkategorieTreeViewOptions: DevExpress.ui.dxTreeViewOptions = {
    displayExpr: "Bezeichnung",
    keyExpr: "Id",
    parentIdExpr: "IdUebergeordneteArtikelkategorie",
    dataStructure: "plain",
    showCheckBoxesMode: "normal",
    selectNodesRecursive: false,
    width: 320,
    onItemSelectionChanged: (e) => {
      this.changeSelection(e.node, e.node.selected);
    }
  }

  bind() {
    this.scope = {
      bindingContext: this,
      overrideContext: createOverrideContext(this, null)
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.loadTreeViewDataSource();
  }

  unbind() {
    this.scopeContainer.disposeAll();
  }

  mainModelChanged() {
    if (!this.mainModel) {
      return;
    }

    this.artikelkategorieTreeView.instance.unselectAll();

    if (this.mainModel.Artikelkategorien && this.mainModel.Artikelkategorien.length > 0) {
      for (let item of this.mainModel.Artikelkategorien) {
        this.artikelkategorieTreeView.instance.selectItem(item.IdArtikelkategorie);
      }
    }

    this.artikelkategorieTreeView.instance.expandAll();
  }

  async loadTreeViewDataSource(): Promise<any> {
    this.treeViewDataSource = await this.restService.get({
      url: `${this.restService.getWebApiUrl("ERP/Stammdaten/Artikelkategorie")}`,
      getOptions: {
        columns: ["Id", "Bezeichnung", "IdUebergeordneteArtikelkategorie", "SortNr"],
        orderBy: [{ columnName: "SortNr", sortOrder: 0 }]
      }
    });

    if (this.artikelkategorieTreeView) {
      this.artikelkategorieTreeView.setOption({
        dataSource: this.treeViewDataSource
      });
    } else {
      this.artikelkategorieTreeViewOptions.dataSource = this.treeViewDataSource;
    }
  }

  private changeSelection(node: DevExpress.ui.dxTreeViewNode, isSelected: boolean) {
    if (!this.mainModel.Artikelkategorien) {
      this.mainModel.Artikelkategorien = [];
    }

    const artKatList: any[] = this.mainModel.Artikelkategorien;

    let selectedKeysList = {
      IdArtikel: this.mainModel.Id,
      IdArtikelkategorie: node.key
    }

    if (isSelected) {
      const idArtikelkategorie = artKatList.some(c => c.IdArtikelkategorie == node.key);
      if (!idArtikelkategorie) {
        artKatList.push(selectedKeysList);
      }
    } else {
      artKatList.splice(artKatList.findIndex(c => c.IdArtikelkategorie == node.key), 1);
    }
  }
}
