import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ArtikelEinheitEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "artikel-einheit-edit";
        this.title = "artikel-einheit-edit.artikel-einheit-edit_caption";
        this.addModel({
            "id": "$m_ArtikelEinheit",
            "webApiAction": "ERP/Stammdaten/ArtikelEinheit",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "einheit",
            "filters": [],
            "caption": "artikel-einheit-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_ArtikelEinheit.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_umrechner_caption",
            "isReadOnlyExpression": "models.data.$m_ArtikelEinheit.Id > 0",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "Umrechner",
                "bindToFQ": "models.data.$m_ArtikelEinheit.Umrechner"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_umrechner",
            "options": {
                "optionsName": "r_umrechnerOptions",
                "optionsNameFQ": "r_umrechnerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 18,
            "caption": "artikel-einheit-edit.r_ean_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "EAN",
                "bindToFQ": "models.data.$m_ArtikelEinheit.EAN"
            },
            "validationRules": [{
                "item": {
                    "type": "ean",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 18
                    }]
                }
            }],
            "id": "r_ean",
            "options": {
                "optionsName": "r_eanOptions",
                "optionsNameFQ": "r_eanOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-einheit-edit.r_isverkaufseinheit_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "IsVerkaufseinheit",
                "bindToFQ": "models.data.$m_ArtikelEinheit.IsVerkaufseinheit"
            },
            "validationRules": [],
            "id": "r_isVerkaufseinheit",
            "options": {
                "optionsName": "r_isVerkaufseinheitOptions",
                "optionsNameFQ": "r_isVerkaufseinheitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_gewichtnetto_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "GewichtNetto",
                "bindToFQ": "models.data.$m_ArtikelEinheit.GewichtNetto"
            },
            "validationRules": [],
            "id": "r_gewichtNetto",
            "options": {
                "optionsName": "r_gewichtNettoOptions",
                "optionsNameFQ": "r_gewichtNettoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_gewichtbrutto_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "GewichtBrutto",
                "bindToFQ": "models.data.$m_ArtikelEinheit.GewichtBrutto"
            },
            "validationRules": [],
            "id": "r_gewichtBrutto",
            "options": {
                "optionsName": "r_gewichtBruttoOptions",
                "optionsNameFQ": "r_gewichtBruttoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_laenge_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "Laenge",
                "bindToFQ": "models.data.$m_ArtikelEinheit.Laenge"
            },
            "validationRules": [],
            "id": "r_laenge",
            "options": {
                "optionsName": "r_laengeOptions",
                "optionsNameFQ": "r_laengeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_breite_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "Breite",
                "bindToFQ": "models.data.$m_ArtikelEinheit.Breite"
            },
            "validationRules": [],
            "id": "r_breite",
            "options": {
                "optionsName": "r_breiteOptions",
                "optionsNameFQ": "r_breiteOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n0",
            "caption": "artikel-einheit-edit.r_hoehe_caption",
            "binding": {
                "dataContext": "$m_ArtikelEinheit",
                "bindTo": "Hoehe",
                "bindToFQ": "models.data.$m_ArtikelEinheit.Hoehe"
            },
            "validationRules": [],
            "id": "r_hoehe",
            "options": {
                "optionsName": "r_hoeheOptions",
                "optionsNameFQ": "r_hoeheOptions"
            }
        });
        super.onConstructionFinished();
    }
}