import * as fwx from "../../../../framework/forms/form-export";
import {
    BenachrichtigungService
} from "../../../services/benachrichtigung-service";

@fwx.autoinject
export class BenachrichtigungListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_BenachrichtigungService: BenachrichtigungService) {
        super(element, formBaseImport);
        this.id = "benachrichtigung-list";
        this.title = "benachrichtigung-list.benachrichtigung-list_caption";
        this.addModel({
            "id": "$m_Benachrichtigung",
            "webApiAction": "ERP/Stammdaten/Benachrichtigung",
            "webApiExpand": {
                'Benutzer': null,
                'BenutzerVon': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "allowDelete": "false",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.addFunction("$f_BenachrichtigungService", $f_BenachrichtigungService, "functions.$f_BenachrichtigungService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_benaIsPrioritaet",
                "caption": "benachrichtigung-list.r_benaisprioritaet_caption",
                "bindTo": "IsPrioritaet",
                "width": "80px",
                "onCellPrepared": "functions.$f_BenachrichtigungService.onIsPrioritaetCellPrepared"
            }, {
                "id": "r_benaDatum",
                "caption": "benachrichtigung-list.r_benadatum_caption",
                "bindTo": "Datum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_benaBenutzerVon",
                "caption": "benachrichtigung-list.r_benabenutzervon_caption",
                "bindTo": "BenutzerVon.Name",
                "width": "140px"
            }, {
                "id": "r_benaBenutzer",
                "caption": "benachrichtigung-list.r_benabenutzer_caption",
                "bindTo": "Benutzer.Name",
                "width": "140px"
            }, {
                "id": "r_benaBetreff",
                "caption": "benachrichtigung-list.r_benabetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_benaIsGelesen",
                "caption": "benachrichtigung-list.r_benaisgelesen_caption",
                "bindTo": "IsGelesen",
                "width": "100px"
            }],
            "optionsToolbar": {
                "optionsName": "r_benachrichtigungGridToolbarOptions",
                "optionsNameFQ": "r_benachrichtigungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Benachrichtigung"
            },
            "dataModel": "$m_Benachrichtigung",
            "editUrl": "ERP/Stammdaten/Benachrichtigung",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_benachrichtigungGrid",
            "options": {
                "optionsName": "r_benachrichtigungGridOptions",
                "optionsNameFQ": "r_benachrichtigungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}