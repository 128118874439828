import { autoinject, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../framework/base/classes/scope-container";
import { GlobalizationService } from "../../../framework/base/export";
import { ICommandData } from "../../../framework/forms/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import * as Interfaces from "../../interfaces/export";

@autoinject
export class ArbeitszeitMitarbeiterstatusAustritt {
  private _options: IArbeitszeitMitarbeiterstatusAustrittOptions;

  constructor(
    private _globalizationService: GlobalizationService,
    private _simpleWidgetCreator: SimpleWidgetCreatorService) { }

  scope: any;
  scopeContainer: ScopeContainer;
  austrittDatum: Date;

  arbeitszeitMitarbeiterstatusAustrittPopup: Interfaces.IdxPopupComponent;
  arbeitszeitMitarbeiterstatusAustrittPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    width: 800,
    height: "auto"
  };

  arbeitszeitMitarbeiterstatusAustrittPopupCommands: ICommandData[] = [{
    id: "doArbeitszeitMitarbeiterstatusAustritt",
    icon: "save",
    title: "base.save",
    execute: () => {
      if (!this.isDataValid()) {
        return;
      }
      this.arbeitszeitMitarbeiterstatusAustrittPopup.instance.hide();
      this._options.callback(this.austrittDatum);
    }
  }];

  arbeitszeitMitarbeiterstatusAustrittValidation: any;
  arbeitszeitMitarbeiterstatusAustrittValidationGroup: DevExpress.ui.dxValidationGroupOptions = {};
  requiredOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "required",
        message: "Pflichtfeld",
      }]
  };

  austrittDateBox: Interfaces.IdxDateBoxComponent;
  austrittDateBoxOptions: DevExpress.ui.dxDateBoxOptions = {
    bindingOptions: {
      value: "austrittDatum",
    },
    displayFormat: this._globalizationService.getFormatterParser("d")
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._simpleWidgetCreator.updatePopupOptions({
      idToolbar: "arbeitszeitMitarbeiterstatusToolbar",
      caption: "arbeitszeit-benutzer-zuteilung.austritt",
      options: this.arbeitszeitMitarbeiterstatusAustrittPopupOptions,
      commands: this.arbeitszeitMitarbeiterstatusAustrittPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  async show(options: IArbeitszeitMitarbeiterstatusAustrittOptions) {
    this._options = options;
    this.austrittDateBox = null;
    this.arbeitszeitMitarbeiterstatusAustrittPopup.instance.show();
  }

  private isDataValid() {
    const result = this.arbeitszeitMitarbeiterstatusAustrittValidation.instance.validate()
    if (!result.isValid) {
      return false;
    }
    return true;
  }
}

export interface IArbeitszeitMitarbeiterstatusAustrittOptions {
  callback: { (austrittDatum: Date): void };
}
