import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  OnErstelleAngebotAusMailImportItemEvent,
  OnErstelleAuftragAusMailImportItemEvent, OnErstelleBestellungAusMailImportItemEvent, OnErstelleKundenreklamationAusMailImportItemEvent, OnVerknuepfenEntitaeten,
  OnVerknuepfenEntitaetenEvent
} from "../../../../framework-data/events";
import { LocationService } from "../../../../framework/base/services/location-service";
import { ContextMenu, FormBase } from "../../../../framework/forms/export";
import { BriefService } from "../../../services/export";
import {
  FileService, IViewScrollInfo, LocalizationService, WebEventService
} from "./../../../../framework/base/export";
import {
  ICommandData
} from "./../../../../framework/forms/interfaces/command-data";
import {
  IdxDataGridComponent
} from "./../../../interfaces/dx-components";

@autoinject
@transient()
export class MailImportItemEditUtils {
  private _windowResizeSubscription: Subscription;

  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private file: FileService,
    private webEvent: WebEventService,
    private eventAggregator: EventAggregator,
    private locationService: LocationService,
    private localizationService: LocalizationService,
    private briefService: BriefService
  ) { }

  currentDokument: any;
  form: FormBase;

  herunterladenCommand: ICommandData = {
    id: "herunterladen",
    title: "mail-item.herunterladen-command-text",
    icon: "download",
    isEnabledExpression: "functions.$f_MailImportItemUtils.currentDokument.DMSLink",
    execute: this.downloadCurrentDokument.bind(this)
  };
  versendenCommand: ICommandData = {
    id: "versenden",
    title: "mail-item.versenden-command-text",
    icon: "share-alt",
    isEnabledExpression: "functions.$f_MailImportItemUtils.currentDokument.DMSLink",
    execute: this.sendCurrentDokument.bind(this)
  };
  dokumentenartZuteilenCommand: ICommandData = {
    id: "zuteilen",
    title: "mail-item.dokumentenart-zuteilen",
    icon: "file-o",
    isEnabledExpression: "functions.$f_MailImportItemUtils.currentDokument.DMSLink",
    execute: this.dokumentenartZuteilen.bind(this)
  };
  neuCommand: ICommandData = {
    id: "neuCommand",
    title: "mail-item.neu-command-text",
    icon: "plus-circle",
    idCategory: "mail",
    sortIndex: 60,
    isEnabledExpression: "models.data.$m_MailImportItem.CanSave && models.data.$m_MailImportItem.IdGeschaeftspartner",
    execute: async (e) => {
      const ctxMenu = new ContextMenu();
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.angebot"),
        execute: () => {
          this.OnNeuErstellenClicked("Angebot", e);
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.auftrag"),
        execute: () => {
          this.OnNeuErstellenClicked("Auftrag", e);
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.kundenreklamation"),
        execute: () => {
          this.OnNeuErstellenClicked("Kundenreklamation", e);
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.bestellung"),
        execute: () => {
          this.OnNeuErstellenClicked("Bestellung", e);
        }
      });

      ctxMenu.show(e.event && e.event.target ? e.event.target : null);
    }
  };
  verknuepfenCommand: ICommandData = {
    id: "mitVerknuepfen",
    title: "mail-item.verknuepften-command-text",
    icon: "link",
    idCategory: "mail",
    sortIndex: 61,
    isEnabledExpression: "models.data.$m_MailImportItem.IdGeschaeftspartner",
    execute: async (e) => {
      const ctxMenu = new ContextMenu();
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.angebot"),
        execute: () => {
          this.onVerknuepfenClicked("Angebot");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.auftrag"),
        execute: () => {
          this.onVerknuepfenClicked("Auftrag");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.lieferschein"),
        execute: () => {
          this.onVerknuepfenClicked("Lieferschein");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.faktura"),
        execute: () => {
          this.onVerknuepfenClicked("Faktura");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.kundenreklamation"),
        execute: () => {
          this.onVerknuepfenClicked("Kundenreklamation");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.bestellung"),
        execute: () => {
          this.onVerknuepfenClicked("Bestellung");
        }
      });
      ctxMenu.items.push({
        text: this.localizationService.translateOnce("erp.eingangsrechnung"),
        execute: () => {
          this.onVerknuepfenClicked("Eingangsrechnung");
        }
      });


      ctxMenu.show(e.event && e.event.target ? e.event.target : null);
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.models.onLoaded.register((e) => {
      if (e.model.id != "$m_MailImportItem" || !e.data.MailImportItemAnhaenge.length) {
        return;
      }

      this.setCurrentDokument(e.data.MailImportItemAnhaenge[0]);
      this.selectCurrentDokument();

      return Promise.resolve();
    });

    this._windowResizeSubscription = this.eventAggregator.subscribe("window:resize", () => {
      this.setViewerHeight();
    });
    this.form.onReady.register((r) => {
      this.setViewerHeight();
      return Promise.resolve();
    });
  }
  unbind() {
    this._windowResizeSubscription.dispose();
  }

  onGeschaeftspartnerChanged() {
    this.form.models.data.$m_MailImportItem.IdPerson = null;
  }
  onAnhangClicked(e: any) {
    this.setCurrentDokument(e.data);
    this.selectCurrentDokument();
  }
  async onVerknuepfenItemClicked(idPopup: string, e: any) {
    await this.doVerknuepfen(e.data.Id);
    this.form[idPopup].instance.hide();
  }

  private async OnNeuErstellenClicked(entitaetName: string, event: any) {
    const ctrlKeyPressed = event
      && event.event
      && event.event.ctrlKey;

    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const idMailImportItem = this.form.models.data.$m_MailImportItem.Id;
    const webEvent = this.getWebEvent(entitaetName, idMailImportItem);

    const webEventResult = await this.webEvent.execute(webEvent, true);
    if (!webEventResult) {
      return;
    }

    const navigationUrl = this.getNavigationUrlFromWebEventResult(entitaetName, webEventResult);
    if (!navigationUrl) {
      return;
    }

    if (ctrlKeyPressed) {
      this.locationService.openWindow(navigationUrl);
      this.form.models.reloadAll();
    } else {
      this.locationService.goTo({
        url: navigationUrl,
        clearStack: false
      });
    }
  }
  private async onVerknuepfenClicked(entitaetName: string) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    let idGrid: string;
    let idPopup: string;

    switch (entitaetName) {
      case "Angebot": {
        idGrid = "r_angebotGrid";
        idPopup = "r_angebotPopup";
        break;
      }
      case "Auftrag": {
        idGrid = "r_auftragGrid";
        idPopup = "r_auftragPopup";
        break;
      }
      case "Lieferschein": {
        idGrid = "r_lieferscheinGrid";
        idPopup = "r_lieferscheinPopup";
        break;
      }
      case "Faktura": {
        idGrid = "r_fakturaGrid";
        idPopup = "r_fakturaPopup";
        break;
      }
      case "Kundenreklamation": {
        idGrid = "r_kundenreklamationGrid";
        idPopup = "r_kundenreklamationPopup";
        break;
      }
      case "Bestellung": {
        idGrid = "r_bestellungGrid";
        idPopup = "r_bestellungPopup";
        break;
      }
      case "Eingangsrechnung": {
        idGrid = "r_eingangsrechnungGrid";
        idPopup = "r_eingangsrechnungPopup";
        break;
      }
      default: {
        break;
      }
    }

    if (!idGrid || !idPopup) {
      return;
    }

    const grid: IdxDataGridComponent = this.form[idGrid];
    if (grid && grid.instance) {
      grid.instance.refresh();
    }

    this.form[idPopup].instance.show();
  }

  private downloadCurrentDokument() {
    const grid: IdxDataGridComponent = this.form["r_mailImportItemAnhangGrid"];

    const selectedRows = grid.instance.getSelectedRowsData();

    if (selectedRows && Array.isArray(selectedRows) && selectedRows.length > 0) {
      for (const row of selectedRows) {
        const url = this.file.getDownloadUrl(row.DMSLink);
        window.open(url, "_blank");
      }
    } else {
      const url = this.file.getDownloadUrl(this.currentDokument.DMSLink);
      window.open(url, "_blank");
    }
  }
  private async sendCurrentDokument() {
    const grid: IdxDataGridComponent = this.form["r_mailImportItemAnhangGrid"];

    let selectedRows: any[] = grid.instance.getSelectedRowsData();

    if (!selectedRows || !Array.isArray(selectedRows) || selectedRows.length == 0) {
      if (!this.currentDokument) {
        return;
      }

      selectedRows = [this.currentDokument];
    }

    const dmsLinkList = selectedRows
      .map((c) => c.DMSLink)
      .filter((c) => !!c);

    if (dmsLinkList.length == 0) {
      DevExpress.ui.notify(
        this.localizationService.translateOnce("mail-item.keine-dok-zum-versenden"),
        "error",
        this.NOTIFY_TIMEOUT);

      return;
    }

    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    await this.briefService.createAndGoToBrief(
      this.form.models.data.$m_MailImportItem.Id,
      dmsLinkList,
    );
  }
  private dokumentenartZuteilen() {
    const grid: IdxDataGridComponent = this.form["r_mailImportItemAnhangGrid"];

    const selectedRows: any[] = grid.instance.getSelectedRowsData();

    if (!selectedRows || !Array.isArray(selectedRows) || selectedRows.length == 0) {
      DevExpress.ui.notify(
        this.localizationService.translateOnce("mail-item.kein-dok-gewaehlt"),
        "error",
        this.NOTIFY_TIMEOUT);
        return;
    } else if (selectedRows.length > 1) {
      DevExpress.ui.notify(
        this.localizationService.translateOnce("mail-item.ein-dok-waehlen"),
        "error",
        this.NOTIFY_TIMEOUT);
        return;
    }

    this.form.editPopups.showEx({
      idEditPopup: "mailImportItemAnhangEditPopup",
      viewScrollInfo: this.getViewScrollInfoMailImportItemAnhang(selectedRows[0]),
      modelLoaded: (form, ev) => {
        if (form.models.data.$m_MailImportItemAnhang) {
          return;
        }
        form.models.data.$m_MailImportItemAnhang = selectedRows[0];
      },
      closeCallback: async (form) => {
        this.form["r_mailImportItemAnhangGrid"].instance.getDataSource().reload();
      }
    });
  }

  private getViewScrollInfoMailImportItemAnhang(mailImportItemAnhang): IViewScrollInfo {
    const dataSource = this.form["r_mailImportItemAnhangGrid"].instance.getDataSource();
    if (!dataSource) {
      return null;
    }

    const lastLoadInfo = (<any>dataSource).lastLoadInfo;
    if (!lastLoadInfo) {
      return null;
    }

    const totalCount = dataSource.totalCount();
    const pageIndex = dataSource.pageIndex();
    const pageSize = dataSource.pageSize();
    const items = dataSource.items();
    const indexOf = items.indexOf(mailImportItemAnhang);

    return {
      lastLoadInfo: lastLoadInfo,
      index: (pageIndex * pageSize) + indexOf,
      maxCount: totalCount
    };
  }
  private selectCurrentDokument() {
    const anhangGrid: IdxDataGridComponent = this.form["r_mailImportItemAnhangGrid"];
    anhangGrid.instance.selectRows([this.currentDokument.Id], false);
  }
  private setCurrentDokument(dokument) {
    this.currentDokument = dokument;
  }
  private getWebEvent(entitaetName: string, idMailImportItem: number) {
    switch (entitaetName) {
      case "Angebot":
        return new OnErstelleAngebotAusMailImportItemEvent({ IdMailImportItem: idMailImportItem });
      case "Auftrag":
        return new OnErstelleAuftragAusMailImportItemEvent({ IdMailImportItem: idMailImportItem });
      case "Kundenreklamation":
        return new OnErstelleKundenreklamationAusMailImportItemEvent({ IdMailImportItem: idMailImportItem });
      case "Bestellung":
        return new OnErstelleBestellungAusMailImportItemEvent({ IdMailImportItem: idMailImportItem });
      default:
        return null;
    }
  }
  private getNavigationUrlFromWebEventResult(entitaetName: string, webEventResult: any): string {
    if (!webEventResult) {
      return null;
    }

    let result = null;
    switch (entitaetName) {
      case "Angebot":
        result = webEventResult.IdAngebot
          ? `ERP/Verkauf/${entitaetName}/${webEventResult.IdAngebot}`
          : null;
        break;
      case "Auftrag":
        result = webEventResult.IdAuftrag
          ? `ERP/Verkauf/${entitaetName}/${webEventResult.IdAuftrag}`
          : null;
        break;
      case "Kundenreklamation":
        result = webEventResult.IdKundenreklamation
          ? `ERP/Verkauf/${entitaetName}/${webEventResult.IdKundenreklamation}`
          : null;
        break;
      case "Bestellung":
        result = webEventResult.IdBestellung
          ? `ERP/Einkauf/${entitaetName}/${webEventResult.IdBestellung}`
          : null;
        break;
    }

    return result;
  }
  private async doVerknuepfen(id: number) {
    const idMail = this.form.models.data.$m_MailImportItem.Id;

    const webEventResult: OnVerknuepfenEntitaeten = await this.webEvent.execute(
      new OnVerknuepfenEntitaetenEvent({
        IdEntitaet1: idMail,
        IdEntitaet2: id
      }), true);

    this.eventAggregator.publish("verknuepfte-entitaet:refresh", {
      id: idMail
    });
  }
  private setViewerHeight() {
    const viewer = <HTMLElement>this.form.element.querySelector(".mail-item-viewer");
    if (!viewer) {
      return;
    }

    let formContent = viewer;
    while (formContent) {
      if (formContent.classList.contains("t--form-content")) {
        break;
      }

      formContent = formContent.parentElement;
    }

    if (!formContent) {
      return;
    }

    let height = formContent.clientHeight - 77 - 12;
    if (height < 500) {
      height = 500;
    }

    viewer.style.height = `${height}px`;
  }
}
