import { autoinject, observable, OverrideContext, Scope } from "aurelia-framework";
import { LocalizationService, RestService, ScopeContainer } from "../../../framework/base/export";
import { IListViewOptions, ListView } from "../../../framework/forms/elements/list-view/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import * as Interfaces from "../../interfaces/export";
import { EntitaetInfoService, SearchResultSearchService } from "../../services/export";

@autoinject
export class SearchResult {
  private _onSearchResultSelected: { (urlToNavigate: string, customOptions?: any): void };
  private _onSearchResultCancel: { (): void };

  constructor(
    private _entitaetInfoService: EntitaetInfoService,
    private _localizationService: LocalizationService,
    private _restService: RestService,
    private _searchResultSearchService: SearchResultSearchService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService) { }

  scope: Scope;
  scopeContainer: ScopeContainer;
  searchValue: string;
  searchDataSource: DevExpress.data.DataSource;
  hasMoreData: boolean;
  selectedTypeName: string;
  sucheTypeNameList: any[];
  @observable showAktiveDatensaetze: boolean = true;

  showAktiveDatensaetzeCheckboxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "nur aktive Datensätze",
    bindingOptions: {
      value: "showAktiveDatensaetze"
    }
  };

  searchResultListView: ListView;
  searchResultListViewOptions: IListViewOptions = {
    height: "100%",
    pageSize: 200,
    selectionMode: "single",
    showReloadButton: false,
    showPagerInfo: false,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this.onSearchResultItemClick(e.item);
    }
  };
  searchResultPopup: any;
  searchResultPopupOptions: DevExpress.ui.dxPopupOptions = {
    contentTemplate: "contentTemplate",
    showCloseButton: false
  };
  searchTextBox: any;
  searchTextBoxOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    placeholder: this._localizationService.translateOnce("base.search"),
    onValueChangedByUser: (e) => {
      this.selectedTypeName = null;
      this.reloadData();
      this.focusSearchTextBox();
    },
    bindingOptions: {
      value: "searchValue"
    }
  };
  goToListButtonOptions: DevExpress.ui.dxButtonOptions = {
    icon: "fa fa-fw fa-caret-right",
    onClick: async (e) => {
      e.event.preventDefault();
      e.event.stopPropagation();

      this.openListSuche(e.model.bindingContext.item.TypeName, this.searchValue);
      this.searchResultPopup.instance.hide();
    }
  };

  onSucheTypeNameClick(item) {
    this.selectedTypeName = item.TypeName;
    this.loadSucheResult();
  }

  onSearchResultItemClick(itemData) {
    this.openSingleItem(itemData);
  }

  showAktiveDatensaetzeChanged(e) {
    if (this.searchResultListView == null) {
      return;
    }

    this.reloadData();
  }

  async bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };
    this.scopeContainer = new ScopeContainer(this.scope);
    this._searchResultSearchService.searchResult = this;
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "searchResultPopupToolbar",
      caption: "base.search",
      scopeContainer: this.scopeContainer,
      options: this.searchResultPopupOptions
    });

    this.sucheTypeNameList = [];
    this.selectedTypeName = null;
    this.hasMoreData = false;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  async showSearchPopup(
    onSearchResultSelected: { (urlToNavigate: string, customOptions?: any): void },
    onSearchResultCancel: { (): void },
    options: Interfaces.ISearchOptions) {
    this._onSearchResultSelected = onSearchResultSelected;
    this._onSearchResultCancel = onSearchResultCancel;

    if (options) {
      this.searchValue = options.searchValue;
      this.selectedTypeName = null;
      await this.reloadData();
    } else {
      this.showPopup();
    }
  }

  private async reloadData() {
    await this.loadSuchtextTypeNames();
  }

  private async loadSuchtextTypeNames() {
    if (this.searchValue != null) {
      const r = await this._restService.post({
        url: this._restService.getApiUrl("ERP/Suche/TypeNames"),
        data: <ISucheTypeNameRequest>{
          Suchtext: this.searchValue,
          InaktiveDatensaetzeLaden: !this.showAktiveDatensaetze,
          SelectedTypeName: this.selectedTypeName
        }
      });

      (<any[]>r).forEach((c) => {
        const entitaetInfo = this._entitaetInfoService.getEntitaetInfoByTypeName(c.TypeName);
        if (!entitaetInfo) {
          return;
        }

        c.Bezeichnung = entitaetInfo.Bezeichnung;
      });

      var sortedList = (<any[]>r).filter((c) => {
        return !!c.Bezeichnung;
      })
        .sort((a: any, b: any) => {
          const bezA: string = a.Bezeichnung;
          const bezB: string = b.Bezeichnung;

          return bezA.localeCompare(bezB);
        });

      this.sucheTypeNameList = sortedList;

      if (this.sucheTypeNameList.length > 0) {
        this.selectedTypeName = this.sucheTypeNameList[0].TypeName;
      }
      await this.loadSucheResult();
    }
  }

  private async loadSucheResult() {
    if ((this.searchResultListView == null || this.searchResultListViewOptions.dataSource == null) && this.searchValue != null) {
      this.searchDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
        cacheRawData: true,
        loadMode: "raw",
        load: async () => {
          if (!this.selectedTypeName) {
            if (!isPopupVisible) {
              this.showPopup();
            }
            this.hasMoreData = false;
            return [];
          }

          let r = await this._restService.post({
            url: this._restService.getApiUrl("ERP/Suche/Suche"),
            data: <ISucheTypeNameRequest>{
              Suchtext: this.searchValue,
              InaktiveDatensaetzeLaden: !this.showAktiveDatensaetze,
              SelectedTypeName: this.selectedTypeName
            }
          });

          this.hasMoreData = r.ItemList.length > 100;

          if (r.ItemList.length > 100) {
            r.ItemList = r.ItemList.splice(0, 100);
          }

          (<any[]>r.ItemList).forEach((c) => {
            c.TypeBezeichnung = this._entitaetInfoService.getBezeichnung(c.TypeName);
          });

          var isPopupVisible = this.searchResultPopup.instance.option("visible");

          if (!isPopupVisible && r.ItemList.length == 1 && this.sucheTypeNameList.length == 1) {
            this.openSingleItem(r.ItemList[0]);
          } else if (!isPopupVisible && r.TypeName != null) {
            this.openListSuche(r.TypeName, r.SuchePlainText);
          } else if (!isPopupVisible) {
            this.showPopup();
          }
          return r.ItemList;
        }
      }));

      this.searchResultListViewOptions.dataSource = this.searchDataSource;
      await this.searchDataSource.reload();
    } else {
      if (this.searchResultListView.options.dataSource.pageIndex() > 0) {
        this.searchResultListView.goToPage(0);
      }
      await this.searchDataSource.reload();
    }
  }

  private showPopup() {
    const instance: DevExpress.ui.dxPopup = this.searchResultPopup.instance;
    instance.show()
      .then((r) => {
        this.focusSearchTextBox();
      });
  }

  private openSingleItem(itemData) {
    this._entitaetInfoService
      .getUrl(itemData.TypeName, itemData.Id)
      .then((r) => {
        if (!r) {
          DevExpress.ui.notify("Die Entität ist nicht definiert");
          return;
        }
        this.searchResultPopup.instance.hide();

        this._onSearchResultSelected(r);
      });
  }

  private async openListSuche(typeName, suchtext) {
    const url = await this._entitaetInfoService.getUrl(typeName, null);
    this._onSearchResultSelected(url, {
      searchtext: suchtext
    });
  }

  private focusSearchTextBox() {
    const element: Element = this.searchTextBox.instance.element();
    const input = element.querySelector("input");

    input.focus();
    input.select();
  }
}

interface ISucheTypeNameRequest {
  Suchtext: string;
  InaktiveDatensaetzeLaden: boolean;
  SelectedTypeName?: string;
}
