import { WebEventService } from './../../../../framework/base/services/web-event-service';
import { OnMahnlaufEvent, OnMahnlauf, OnMahnungenVersendenEvent, OnMahnungenVersenden } from './../../../../framework-data/events';
import { LocalizationService } from './../../../../framework/base/services/localization-service';
import { ICommandData } from './../../../../framework/forms/interfaces/command-data';
import { FormBase } from './../../../../framework/forms/classes/form-base';
import { autoinject, transient } from 'aurelia-framework';
import { __await } from 'tslib';

@autoinject
@transient()
export class MahnungListUtils {
  constructor(
    private localizationService: LocalizationService,
    private webEventService: WebEventService
  ) { }

  form: FormBase;

  doMahnungErstellen: ICommandData = {
    id: "doMahnungErstellen",
    idCategory: "mahnung",
    title: "mahnung.mahnungen_erstellen",
    icon: "plus-circle",
    sortIndex: 100,
    execute: async () => {
      const webEvent = new OnMahnlaufEvent({});

      const result: OnMahnlauf = await this.webEventService.execute(webEvent);
      if (result == void 0) {
        return;
      }
      DevExpress.ui.notify(this.localizationService.translateOnce("mahnung.mahnung_erfolgreich_erstellt"), "SUCCESS", 3000);
    }
  };

  doMahnungenVersenden: ICommandData = {
    id: "doMahnungenVersenden",
    idCategory: "mahnung",
    title: "mahnung.mahnung_versenden",
    icon: "paper-plane",
    sortIndex: 110,
    execute: async () => {
      const webEvent = new OnMahnungenVersendenEvent({});

      const result: OnMahnungenVersenden = await this.webEventService.execute(webEvent);
      if (result == void 0) {
        return;
      }
      DevExpress.ui.notify(this.localizationService.translateOnce("mahnung.mahnung_erfolgreich_versendet"), "SUCCESS", 3000);
    }
  };

  bind(form: FormBase) {
    this.form = form;
  }
}
