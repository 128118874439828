import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  FileService,
  WebEventService
} from "../../../../framework/base/export";
import {
  OnBelegImportFreigebenEvent
} from "../../../../framework-data/events";
import {
  StatusService
} from "../../../services/export";

@autoinject
@transient()
export class BelegImportEditUtils {
  constructor(
    private webEvent: WebEventService,
    private file: FileService,
    private status: StatusService
  ) { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.binding.observe({
      scopeContainer: form.scopeContainer,
      expression: "models.data.$m_BelegImport.CanSave",
      callback: () => {
        const data: any = this.form.models.data.$m_BelegImport;
        this.doFreigebenCommand.isEnabled = data && data.CanSave;
      }
    });
  }

  doFreigebenCommand: ICommandData = {
    id: "doFreigeben",
    idCategory: "import",
    title: "beleg-import-edit-utils.do-freigeben",
    icon: "check",
    isEnabled: false,
    execute: () => {
      const data: any = this.form.models.data.$m_BelegImport;
      if (data && data.CanSave) {
        this.form.save()
          .then((r) => {
            if (!r.isValid) {
              return;
            }

            return this.webEvent
              .execute(new OnBelegImportFreigebenEvent({
                IdBelegImport: this.form.models.data.$m_BelegImport.Id
              }), true)
              .then(() => {
                return this.form.models.reloadAll();
              });
          });
      }
    }
  }
}
