import {
  autoinject,
  valueConverter
} from "aurelia-framework";
import { TicketTyp } from '../enumerations/ticket-typ';

@autoinject
@valueConverter("ticketTypName")
export class TicketTypNameValueConverter {
  constructor(
  ) { }

  toView(value: any) {
    if (value == null) {
      return "";
    }

    let ticketTyp = value;

    switch (ticketTyp) {
      case TicketTyp.Frage:
        value = "Frage";
        break;
      case TicketTyp.Wunsch:
        value = "Wunsch";
        break;
      case TicketTyp.Fehler:
        value = "Fehler";
        break;
      case TicketTyp.Beschreibung:
        value = "Beschreibung";
        break;
      case TicketTyp.Neuigkeit:
        value = "Neuigkeit";
        break;
    }

    return value;
  }
}
