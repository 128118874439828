import { autoinject, noView } from "aurelia-framework";

@autoinject
@noView
export class PopupHider {
  private _onHideKeyDown: any;
  private _onHideKeyUp: any;

  private _hiddenPopups = [];
  private _timerId: any;

  constructor() {}

  popupsHidden: boolean = false;

  bind() {
    this._onHideKeyDown = this.onHideKeyDown.bind(this);
    this._onHideKeyUp = this.onHideKeyUp.bind(this);

    window.addEventListener("keydown", this._onHideKeyDown);
    window.addEventListener("keyup", this._onHideKeyUp);
  }
  unbind() {
    window.removeEventListener("keydown", this._onHideKeyDown);
    window.removeEventListener("keyup", this._onHideKeyUp);
  }

  onHideKeyDown(e) {
    if (this._timerId) {
      return;
    }
    if (e.key != "h") {
      return;
    }
    if (this.popupsHidden) {
      return;
    }
    if (!this.canHidePopups(e.target)) {
      return;
    }

    this._timerId = setTimeout(() => {
      this._timerId = null;
      this.changeState(true);
    }, 400);
  }
  onHideKeyUp(e) {
    if (this._timerId) {
      clearTimeout(this._timerId);
      this._timerId = null;
    }

    if (e.key != "h") {
      return;
    }
    if (!this.popupsHidden) {
      return;
    }

    this.changeState(false);
  }

  private changeState(activate: boolean) {
    this.popupsHidden = activate;

    if (activate) {
      this._hiddenPopups = Array.from(document.querySelectorAll(".dx-overlay-wrapper"));

      this._hiddenPopups.forEach((popup: HTMLElement) => {
        popup.style.opacity = "0";
        
        const firstChild = <HTMLElement>popup.firstChild;
        firstChild.style.display = "none";
      });
    } else {
      this._hiddenPopups.forEach((popup: HTMLElement) => {
        popup.style.opacity = "";
        
        const firstChild = <HTMLElement>popup.firstChild;
        firstChild.style.display = "";
      });

      this._hiddenPopups = [];
    }
  }
  private canHidePopups(target) {
    if (!target) {
      return true;
    }

    const tagName = target.tagName;
    if (!tagName) {
      return true;
    }

    switch (tagName) {
      case "INPUT":
      case "TEXTAREA": {
        return false;
      }
      default: {
        return true;
      }
    }
  }
}
