import { CustomEditPopup } from './../../../framework/forms/elements/custom-edit-popup/custom-edit-popup';
import { ICommand } from './../../../framework/forms/interfaces/command';
import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator,
  Subscription
} from 'aurelia-event-aggregator';
import {
  WebEventService
} from "../../../framework/base/export";
import {
  OnAufgabeAnzahlRequestEvent, OnAufgabeAnzahlRequest
} from "../../../framework-data/events";
import {
  FormBase
} from "../../../framework/forms/export";
import {
  ICommandData
} from "../../../framework/forms/interfaces/export";

import {
  FormCommandService
} from "../../services/export";

import {
  CustomEditPopupService,
  HeaderCommandService
} from "../../services/export";
import {
  NotificationTyp
} from "../../enumerations/export";

@autoinject
export class AufgabeFormCommand {
  private _aufgabeFormCommandSubscription: Subscription;
  private _availableForms: FormBase[] = [];
  private _detachedAddCommand: { (): void };
  private _detachedShowCommand: { (): void };
  constructor(
    private customEditPopup: CustomEditPopupService,
    private formCommand: FormCommandService,
    private eventAggregator: EventAggregator,
    private headerCommand: HeaderCommandService,
    private webEvent: WebEventService
  ) { }

  showAufgabeFormCommand: ICommandData = {
    id: "showAufgabeFormCommand",
    idCategory: "aufgaben",
    title: "form-command.aufgabe-command-icon-text",
    icon: "tasks",
    isEnabled: false,
    badgeText: null,
    badgeColor: "#C1392D",
    sortIndex: 8000
  }

  addAufgabeFormCommand: ICommandData = {
    id: "$addAufgabe",
    idCategory: "aufgaben",
    icon: "plus",
    title: "form-command.aufgabe-command-neu",
    isEnabled: false,
    sortIndex: 8001
  }

  attached() {
    this._aufgabeFormCommandSubscription = this.eventAggregator
      .subscribe(
        NotificationTyp.aufgabeAnzahlChanged,
        (data) => {
          if (data == null) {
            return;
          }
          this.loadAufgabeBadge({ Id: data.IdVerknuepfteEntitaet });
        }
      );

    this._detachedShowCommand = this.formCommand.attachFormCommand(
      this.showAufgabeFormCommand,
      {
        isCommandVisibleModelProperty: "SupportsAufgabe",
        execute: (e) => {
          const element = this.formCommand.getCommandTarget(e.options.event);

          this.headerCommand.aufgabe.showPopover(element, e.modelData);
        },
        onFormModelLoaded: this.loadAufgabeBadge.bind(this),
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.deleteAvailableForm.bind(this)
      });
    this._detachedAddCommand = this.formCommand.attachFormCommand(
      this.addAufgabeFormCommand,
      {
        isCommandVisibleModelProperty: "SupportsAufgabe",
        execute: (e) => {
          let idCboProjekt = e.modelData.IdCboProjekt;
          if (!idCboProjekt && e.modelData.TypeName === "TIP.ERP.Business.Entitaeten.Stammdaten.Projekt") {
            idCboProjekt = e.modelData.Id;
          }

          this.customEditPopup.aufgabeEditPopup.show({
            mappings: { "$id": 0 },
            setValuesOnModelWithKeyIdLoaded: { IdVerknuepfteEntitaet: e.modelData.Id, IdCboProjekt: idCboProjekt }
          });
        }
      }
    )
  }
  detached() {
    this._aufgabeFormCommandSubscription.dispose();
    this._detachedAddCommand();
    this._detachedShowCommand();
  }

  private loadAufgabeBadge(data, command?: ICommandData) {
    if (data == void 0 || data.Id == void 0) {
      return;
    }

    const commandToReload = command || this.formCommand.getReloadCommand(this.showAufgabeFormCommand, this._availableForms, {
      modelPropertyName: "Id",
      modelPropertyValue: data.Id
    })

    if (!commandToReload) {
      return;
    }

    const aufgabeAnzahlEvent = new OnAufgabeAnzahlRequestEvent({
      IdVerknuepfteEntitaet: data.Id,
      AufgabeBenutzer: false
    });
    this.webEvent
      .execute(aufgabeAnzahlEvent)
      .then((r: OnAufgabeAnzahlRequest) => {
        if (r == void 0 || r.IdVerknuepfteEntitaet == void 0) {
          return;
        }
        if (r.Anzahl == 0 || r.Anzahl == void 0) {
          commandToReload.isEnabled = false;
          commandToReload.badgeText = null;
          return;
        }

        let color = "white";
        let textColor = "black";
        let borderColor = "#C1392D";

        let anzahl = "";
        if (r.AnzahlErinnernFaellig) {
          anzahl += r.AnzahlErinnernFaellig + "/";

          color = "#C1392D";
          textColor = "white";
          borderColor = "transparent";
        }
        anzahl += r.Anzahl;

        commandToReload.isEnabled = true;
        commandToReload.badgeColor = color;
        commandToReload.badgeTextColor = textColor;
        commandToReload.badgeBorderColor = borderColor;
        commandToReload.badgeText = {
          key: "base.param_1",
          parameters: [anzahl]
        };
      });

  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private deleteAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1)
  }
}
