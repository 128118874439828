import {
  autoinject
} from "aurelia-framework";
import {
  EventAggregator
} from "aurelia-event-aggregator";
import {
  ViewItem
} from "../classes/view-item";
import {
  LocalizationService
} from "../../base/services/export";
import * as Interfaces from "../interfaces/export";
import { FormBase } from '../classes/form-base';
import { IHistoryState } from '../export';
import { TemplatingExService } from '../../base/services/templating-ex-service';
import { INavigationRoute } from '../interfaces/export';

@autoinject
export class RouterService {
  private routes: Interfaces.IRoute[] = [];
  private fallbackRoute: string;
  private routeInfoId = 0;

  constructor(
    private localization: LocalizationService,
    private eventAggregator: EventAggregator,
    private templatingExService: TemplatingExService
  ) {
    this.registerAppChanging();
    this.registerShortcuts();
    this.registerTitleChanged();
  }

  navigationRoutes: Interfaces.INavigationRoute[];
  viewStack: ViewItem[] = [];
  currentViewItem: ViewItem;

  constructUrl(route: Interfaces.IRoute, parameters: any): string {
    let url = Array.isArray(route.route)
      ? route.route[0]
      : route.route;

    for (let parameter in parameters) {
      url = url.replace(new RegExp(`/:${parameter}`), `/${parameters[parameter]}`)
    }

    return url;
  }
  async canDeactivateCurrentView(): Promise<boolean> {
    if (!this.currentViewItem || !this.currentViewItem.controller) {
      return true;
    }

    const currentViewModel = this.currentViewItem.controller["currentViewModel"];
    if (currentViewModel && typeof currentViewModel.handleChangedData === "function") {
      const result = await currentViewModel.handleChangedData();

      return result == void (0) || result;
    } else {
      return true;
    }
  }
  deactivate() {
  }
  existsNavigationRoute(url: string) {
    return this.navigationRoutes.some(r => {
      return this.existsNavigationRouteEx(url, r);
    })
  }
  getCurrentHistoryState(): IHistoryState {
    if (!this.currentViewItem) {
      return null;
    }

    return {
      id: this.currentViewItem.model.routeInfo.id,
      url: this.currentViewItem.model.routeInfo.url,
      caption: this.currentViewItem.title
    }
  }
  getRoute(url: string): Interfaces.IRouteInfo {
    const indexQuestionMark = url.indexOf("?");
    const defaultParameters = {};
    if (indexQuestionMark >= 0) {
      url = url.substr(0, indexQuestionMark);
    }

    const searchRouteInfo = (routes: Interfaces.IRoute[]) => {
      for (const route of routes) {
        const routeInfo = this.isRoute(route, url)
          || searchRouteInfo(route.children);

        if (routeInfo == void (0)) {
          continue;
        }

        return routeInfo;
      }

      return null;
    }

    const routeInfo = searchRouteInfo(this.routes);
    if (routeInfo != void (0)) {
      return routeInfo;
    }

    const fallbackRoute = this.getFallbackRoute();
    const route = Array.isArray(fallbackRoute.route)
      ? fallbackRoute.route[0]
      : fallbackRoute.route;

    return {
      id: this.routeInfoId++,
      route: fallbackRoute,
      parameters: {},
      url: route,
      isFallback: true
    };
  }
  hasRouteWithId(id: number): boolean {
    return this.viewStack.some(v => {
      return v.model.routeInfo.id == id;
    });
  }
  async navigate(navigationArgs: Interfaces.INavigationArgs): Promise<boolean> {
    if (navigationArgs.clearStack || navigationArgs.replace) {
      const canDeactivate = await this.canDeactivateCurrentView();

      if (!canDeactivate) {
        return false;
      }
    }

    const routeInfo = this.getRoute(navigationArgs.url);
    if (routeInfo == void (0)) {
      return false;
    }

    routeInfo.setValuesOnModelWithKeyIdLoaded = navigationArgs.setValuesOnModelWithKeyIdLoaded;
    routeInfo.customOptions = navigationArgs.customOptions;

    const routerInterceptorArgs = {
      routeInfo: routeInfo,
      moduleIdPromise: <Promise<string>>null
    };

    Object.assign(routeInfo.parameters, this.getParameters(navigationArgs.url));
    
    if (navigationArgs.historyState) {
      routeInfo.id = navigationArgs.historyState.id;
    }
    
    this.eventAggregator.publish("router:intercept-route", routerInterceptorArgs);

    let moduleId = routeInfo.route.moduleId;
    if (routerInterceptorArgs.moduleIdPromise) {
      moduleId = await routerInterceptorArgs.moduleIdPromise;
      if (!moduleId) {
        return false;
      }
    }

    const routerCanNavigateArgs = {
      routeInfo: routeInfo,
      moduleId: moduleId,
      cancel: false
    };

    this.eventAggregator.publish("router:can-navigate", routerCanNavigateArgs);
    if (routerCanNavigateArgs.cancel) {
      return false;
    }

    navigationArgs.routeInfo = routeInfo;

    if (navigationArgs.clearStack) {
      this.viewStack.splice(0, this.viewStack.length);
    } else if (this.viewStack.length > 1 && this.viewStack[this.viewStack.length - 2].model.routeInfo.id === routeInfo.id) {
      this.removeLastViewItem();
      navigationArgs.didReplace = true;
      return true;
    } else if (this.viewStack.length > 0 && navigationArgs.replace) {
      this.viewStack.splice(this.viewStack.length - 1, 1);
      navigationArgs.didReplace = true;
    }

    const newViewItem = new ViewItem({
      routeInfo: routeInfo,
      moduleId: moduleId,
      viewScrollInfo: navigationArgs.viewScrollInfo
    });
    this.addViewItem(newViewItem);

    this.eventAggregator.publish("router:view-item-created", {
      viewItem: newViewItem
    });

    return true;
  }
  registerRoutes(routes: Interfaces.IRoute[], fallbackRoute: string) {
    routes = routes || [];

    this.routes = this.validateRoutes(routes);
    this.fallbackRoute = fallbackRoute;

    this.navigationRoutes = this.getNavigationRoutes(routes);
  }
  reset() {
    this.viewStack.splice(0, this.viewStack.length);
    this.navigationRoutes = [];
  }
  removeLastViewItem() {
    const length = this.viewStack.length;
    const view = this.viewStack[length - 1];
    this.viewStack.splice(length - 1);
    
    this.setCurrentViewItem();

    if (this.currentViewItem) {
      const currentViewModel = this.currentViewItem.controller["currentViewModel"];
      if (currentViewModel && typeof currentViewModel.reactivate === "function") {
        currentViewModel.reactivate();
      }
    }

    this.eventAggregator.publish("router:view-item-removed", {
      viewItem: view
    });
  }
  updateCurrentUrl(url: string) {
    const canUpdateUrl = this.currentViewItem
      && this.currentViewItem.model
      && this.currentViewItem.model.routeInfo;

    if (!canUpdateUrl) {
      return;
    }

    this.currentViewItem.model.routeInfo.url = url;
  }

  private addViewItem(viewItem: ViewItem) {
    this.viewStack.push(viewItem);
    this.setCurrentViewItem();

    setTimeout(() => {
      viewItem.isAttached = true;
    }, 100);
  }
  private getFallbackRoute(): Interfaces.IRoute {
    if (!this.fallbackRoute) {
      return null;
    }

    const getRoute = (routes: Interfaces.IRoute[]): Interfaces.IRoute => {
      for (const route of routes) {
        if ((<string[]>route.route).some(r => r === this.fallbackRoute)) {
          return route;
        }

        const childRoute = getRoute(route.children);
        if (childRoute) {
          return childRoute;
        }
      }
    };

    const fallbackRoute = getRoute(this.routes);
    if (!fallbackRoute) {
      throw new Error("Fallback route not found");
    }

    return fallbackRoute;
  }
  private getNavigationRoutes(routes: Interfaces.IRoute[]): Interfaces.INavigationRoute[] {
    const result: Interfaces.INavigationRoute[] = [];

    for (const route of routes) {
      if (!route.navigation) {
        continue;
      }
      if (!route.canActivate()) {
        continue;
      }

      const navigationRoute: Interfaces.INavigationRoute = {
        caption: route.caption,
        route: route.route[0],
        navigation: route.navigation,
        children: this.getNavigationRoutes(route.children)
      };

      if (navigationRoute.route || (navigationRoute.children && navigationRoute.children.length > 0)) {
        result.push(navigationRoute);
      }
    }

    return result;
  }
  private getParameters(url: string): any {
    const result = {};

    const indexQuestionMark = url.indexOf("?");
    if (indexQuestionMark < 0) {
      return result;
    }

    const parameterString = url.substr(indexQuestionMark + 1);
    const parameters = parameterString.split("&");

    for (const parameter of parameters) {
      const parts = parameter.split("=");

      if (parts.length == 1) {
        result[parts[0]] = true;
      } else {
        result[parts[0]] = parts[1];
      }
    }

    return result;
  }
  private isRoute(route: Interfaces.IRoute, url: string): Interfaces.IRouteInfo {
    if (route.route == void (0)) {
      return null;
    }

    if (Array.isArray(route.route)) {
      for (const part of route.route) {
        const result = this.isRoutePattern(part, url);

        if (result == void (0)) {
          continue;
        } else if (!route.canActivate()) {
          continue;
        } else {
          return {
            id: this.routeInfoId++,
            route: route,
            parameters: result,
            url: url
          };
        }
      }

      return null;
    } else {
      throw new Error()
    }
  }
  private isRoutePattern(route: string, url: string): any {
    if (!route) {
      return null;
    }

    const routeParts = route.split("/");
    const urlParts = url.split("/");
    const parameters: any = {};

    if (routeParts.length !== urlParts.length) {
      return null;
    }

    for (let i = 0; i < urlParts.length; i++) {
      if (routeParts[i].startsWith(":")) {
        let routePart = routeParts[i];
        const indexOfBracket = routePart.indexOf("{");
        const lastIndexOfBrack = routePart.lastIndexOf("}");

        if (indexOfBracket >= 0 && lastIndexOfBrack >= 0) {
          let r = routePart.substring(indexOfBracket + 1, lastIndexOfBrack);
          routePart = routePart.substr(0, indexOfBracket);

          if (!new RegExp(`^${r}$`).test(urlParts[i])) {
            return null;
          }
        }

        parameters[routePart.substr(1)] = urlParts[i];
      }
      else if (urlParts[i] !== routeParts[i]) {
        return null;
      }
    }

    return parameters;
  }
  private validateRoutes(routes: Interfaces.IRoute[]): Interfaces.IRoute[] {
    for (const route of routes) {
      if (route.route == void (0)) {
        route.route = "";
      }

      if (typeof route.route === "string") {
        route.route = [route.route];
      }

      if (route.canActivate == void (0)) {
        route.canActivate = () => {
          return true;
        };
      }

      route.children = route.children || [];
      this.validateRoutes(route.children);
    }

    return routes;
  }
  private registerAppChanging() {
    this.eventAggregator.subscribe("authorization:changing-app", () => {
      this.viewStack.splice(0);
    });
  }
  private registerShortcuts() {
    this.eventAggregator.subscribe("shortcut:execute", e => {
      if (!this.currentViewItem) {
        return Promise.resolve();
      }

      const args = {
        isOpen: false
      };

      //Muss so gemacht werden, da sonst Ringverweis
      this.eventAggregator.publish("popup-info:popup-open", args);
      if (args.isOpen) {
        return Promise.resolve();
      }

      const currentViewModel = this.currentViewItem.controller["currentViewModel"];
      if (!currentViewModel.executeCommand) {
        return;
      }

      currentViewModel.executeCommand(e.idCommand);
      return Promise.resolve();
    });
  }
  private registerTitleChanged() {
    this.eventAggregator.subscribe("form:title-changed", (e) => {
      this.setFormTitle(e.form);
    });
  }
  private setCurrentViewItem() {
    let newCurrentViewItem;

    if (this.viewStack.length === 0) {
      newCurrentViewItem = null;
    } else {
      newCurrentViewItem = this.viewStack[this.viewStack.length - 1];
      newCurrentViewItem.isCurrent = true;

      if (this.viewStack.length > 1) {
        this.viewStack[this.viewStack.length - 2].isCurrent = false;
      }

      if (this.currentViewItem != newCurrentViewItem) {
        this.currentViewItem = newCurrentViewItem;

        this.eventAggregator.publish("router:current-view-item-changed", {
          currentViewItem: newCurrentViewItem
        });
      }
    }
  }
  private async setFormTitle(form: FormBase) {
    if (!this.currentViewItem) {
      return;
    }

    const controller: any = this.currentViewItem.controller;
    if (!controller) {
      return;
    }

    const viewModel = await this.templatingExService.waitForViewModel(controller);
    if (!viewModel) {
      return;
    }

    if (viewModel != form) {
      return;
    }

    document.title = this.localization.translateOnce(form.title, form.scopeContainer);
  }
  private existsNavigationRouteEx(url: string, navigationRoute: INavigationRoute): boolean {
    if (navigationRoute.route == url) {
      return true;
    }
    if (!navigationRoute.children) {
      return false;
    }

    return navigationRoute.children.some(r => {
      return this.existsNavigationRouteEx(url, r);
    })
  }
}
