import {
  autoinject,
  computedFrom
} from "aurelia-framework";
import {
  LayoutService
} from "../../services/layout-service";
import { RouterService, INavigationRoute } from "../../../forms/export";

@autoinject
export class Sidebar {
  private readonly sidebarExpandedProp = "sidebarExpanded";
  private routeExpanded: INavigationRoute;

  constructor(
    private layout: LayoutService,
    private router: RouterService
  ) { }

  @computedFrom("layout.isSidebarCollapsed")
  get headerIcon(): string {
    if (this.layout.isSidebarCollapsed) {
      return "bars";
    } else {
      return "arrow-circle-left"
    }
  }

  attached() {
    window.addEventListener("click", this.onWindowClick.bind(this));
  }
  detached() {
    window.removeEventListener("click", this.onWindowClick.bind(this));
  }

  onHeaderClicked() {
    this.layout.isSidebarCollapsed = !this.layout.isSidebarCollapsed;
  }
  onRouteClicked(route: INavigationRoute) {
    if (this.routeExpanded) {
      this.routeExpanded[this.sidebarExpandedProp] = false;
      this.routeExpanded = null;
    }

    if (route.children.length === 0) {
      return;
    }

    this.routeExpanded = route;
    this.routeExpanded[this.sidebarExpandedProp] = true;
  }

  onWindowClick(e) {
    if (!this.routeExpanded) {
      return;
    }

    const target: Element = e.target;

    if (target.classList.contains("t--sidebar-item") || this.getClosest(target, "t--sidebar-item")) {
      return;
    }

    this.routeExpanded[this.sidebarExpandedProp] = false;
    this.routeExpanded = null;
  }

  private getClosest(e: Element, classString: string) {
    if(e.classList.contains(classString)){
      return e;
    }
 
     let parent = null;
     while (e) {
       parent = e.parentElement;
       if (parent && parent.classList.contains(classString)) {
         return parent;
       }
 
       e = parent;
     }
 
     return null;
   }
}
