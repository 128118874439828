import { autoinject, transient } from "aurelia-framework";
import { OnFakturaListAbschliessenEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { IdxDataGridComponent } from "../../../interfaces/dx-components";
import { ValidationExService } from "../../../services/validation-ex-service";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { BelegExportService } from "../../../services/beleg-export-service";
import { StartupService } from "../../../services/startup-service";

@autoinject
@transient()
export class FakturaListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    private _belegExportService: BelegExportService,
    private _webEventService: WebEventService,
    private _asyncService: AsyncService,
    private _localizationService: LocalizationService,
    private _startupService: StartupService,
    private _validationExService: ValidationExService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_FakturaListUtils._startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_FakturaListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_fakturaGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Faktura, keys);
    }
  };

  doAbschliessenCommand: ICommandData = {
    id: "doAbschliessen",
    idCategory: "faktura",
    title: "faktura.abschliessen",
    icon: "check",
    sortIndex: 999,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_fakturaGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const fakturaGridRowData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (fakturaGridRowData.length == 0) {
        return;
      }

      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("faktura.sure_abschliessen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasErledigteFakturenInList = fakturaGridRowData
        .some((c) => c.Status.Typ != 0);

      if (hasErledigteFakturenInList) {
        DevExpress.ui.notify(
          this._localizationService.translateOnce("faktura.fehler_abschliessen_status"),
          "error",
          this.NOTIFY_TIMEOUT);
        return;
      }

      fakturaGridRowData.forEach(async (faktura) => {
        const validationResult = await this._validationExService.executeServerValidation(
          faktura.TypeName,
          faktura.Id,
          "ABSCHLIESSEN"
        );

        if (!validationResult) {
          return;
        }
      });

      const idFakturaList = fakturaGridRowData.map((c) => c.Id);

      await this._webEventService.execute(new OnFakturaListAbschliessenEvent({
        IdFakturaList: idFakturaList
      }), true);

      return this.form.models.reloadAll();
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Faktura);
      return Promise.resolve();
    });
  }

  onZahlungFaelligCellPrepared(e): void {
    if (e.rowType != "data" || e.data.BezahltTyp == void (0) || !e.data.ZahlungFaelligkeit || !e.data.Status || e.data.Status.Typ != 1) {
      return;
    }
    if (e.data.BezahltTyp != 0 && e.data.BezahltTyp != 2) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.ZahlungFaelligkeit.getTime() >= new Date().getTime()
      ? "#2DCC70"
      : "#C1392D";
  }
}
