import {
  autoinject, transient
} from "aurelia-framework";
import {
  FormBase
} from "../../../../framework/forms/export";
import * as moment from "moment";

@autoinject
@transient()
export class AufgabeListUtils {
  constructor() { }

  form: FormBase;

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }

  onFaelligAmCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    const rowData = e.data;
    if (rowData.FaelligAm == null) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;
    const isVorbei = moment().isSameOrAfter(rowData.FaelligAm);

    if (isVorbei) {
      cell.style.backgroundColor = "#C1392D";
      cell.style.color = "White";
    }
  }
}
