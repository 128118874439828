import * as fwx from "../../../../framework/forms/form-export";
import {
    BriefEditUtils
} from "./brief-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BriefEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_briefEditUtils: BriefEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "brief-edit";
        this.title = "brief-edit.brief-edit_caption";
        this.addModel({
            "id": "$m_Brief",
            "webApiAction": "ERP/Stammdaten/Brief",
            "webApiExpand": {
                'BriefZuEmpfaenger': {
                    'where': [
                        'IdEmpfaenger',
                        '!=',
                        null
                    ]
                },
                'BriefZuCC': null,
                'BriefZuBCC': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_EmpfaengerAll",
            "webApiAction": "ERP/Stammdaten/BriefZuEmpfaenger",
            "webApiWhere": [
                ["IdBrief", {
                    "isBound": true,
                    "expression": "models.data.$m_Brief.Id"
                }],
                ["VersandStatus", "!=", 0]
            ],
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": [{
                "webApiCustomKey": "AddEmpfaengerCboBezeichnung",
                "webApiCustomValue": "true"
            }]
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_briefEditUtils.doVersendenEmail",
                "bindToFQ": "functions.$f_briefEditUtils.doVersendenEmail"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_briefEditUtils.doDrucken",
                "bindToFQ": "functions.$f_briefEditUtils.doDrucken"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_briefEditUtils.doDruckvorschau",
                "bindToFQ": "functions.$f_briefEditUtils.doDruckvorschau"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_briefEditUtils.showDivAdresse",
                "bindToFQ": "functions.$f_briefEditUtils.showDivAdresse"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_briefEditUtils.hideDivAdresse",
                "bindToFQ": "functions.$f_briefEditUtils.hideDivAdresse"
            }
        });
        this.addFunction("$f_briefEditUtils", $f_briefEditUtils, "functions.$f_briefEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "brief-edit.r_firma_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Brief.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "brief-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Brief.IdSachbearbeiter"
            },
            "validationRules": [],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "brief-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Brief.Datum"
            },
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "brief-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Brief.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "brief-edit.r_signaturunterdruecken_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "SignaturUnterdruecken",
                "bindToFQ": "models.data.$m_Brief.SignaturUnterdruecken"
            },
            "validationRules": [],
            "id": "r_signaturUnterdruecken",
            "options": {
                "optionsName": "r_signaturUnterdrueckenOptions",
                "optionsNameFQ": "r_signaturUnterdrueckenOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_briefPage",
                "caption": "brief-edit.r_briefpage_caption"
            }, {
                "id": "r_briefVersandStatus",
                "caption": "brief-edit.r_briefversandstatus_caption"
            }],
            "id": "r_briefTabs",
            "options": {
                "optionsName": "r_briefTabsOptions",
                "optionsNameFQ": "r_briefTabsOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "brief-edit.r_empfaengeran_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "AnManuell",
                "bindToFQ": "models.data.$m_Brief.AnManuell"
            },
            "validationRules": [{
                "item": {
                    "type": "emailMultiple",
                    "parameters": []
                }
            }],
            "id": "r_empfaengerAn",
            "options": {
                "optionsName": "r_empfaengerAnOptions",
                "optionsNameFQ": "r_empfaengerAnOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "brief-edit.r_ccmanuell_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "CCManuell",
                "bindToFQ": "models.data.$m_Brief.CCManuell"
            },
            "validationRules": [{
                "item": {
                    "type": "emailMultiple",
                    "parameters": []
                }
            }],
            "id": "r_ccManuell",
            "options": {
                "optionsName": "r_ccManuellOptions",
                "optionsNameFQ": "r_ccManuellOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "brief-edit.r_bccmanuell_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "BCCManuell",
                "bindToFQ": "models.data.$m_Brief.BCCManuell"
            },
            "validationRules": [{
                "item": {
                    "type": "emailMultiple",
                    "parameters": []
                }
            }],
            "id": "r_bccManuell",
            "options": {
                "optionsName": "r_bccManuellOptions",
                "optionsNameFQ": "r_bccManuellOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "brief-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_Brief.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addHtmlEditor(this, {
            "height": "30vh",
            "allowImages": true,
            "caption": "brief-edit.r_texthtml_caption",
            "binding": {
                "dataContext": "$m_Brief",
                "bindTo": "TextHtml",
                "bindToFQ": "models.data.$m_Brief.TextHtml"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_textHtml",
            "options": {
                "optionsName": "r_textHtmlOptions",
                "optionsNameFQ": "r_textHtmlOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_verEmpf",
                "caption": "brief-edit.r_verempf_caption",
                "bindTo": "_AddEmpfaengerCboBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_verEmail",
                "caption": "brief-edit.r_veremail_caption",
                "bindTo": "Email",
                "minWidth": 100
            }, {
                "id": "r_verVersandStatus",
                "caption": "brief-edit.r_verversandstatus_caption",
                "bindTo": "VersandStatus",
                "width": "150px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.BriefEmpfaengerVersandStatus"
            }, {
                "id": "r_verVersandtAm",
                "caption": "brief-edit.r_verversandtam_caption",
                "bindTo": "VersandDatum",
                "width": "120px",
                "format": "g"
            }],
            "optionsToolbar": {
                "optionsName": "r_versandStatusGridToolbarOptions",
                "optionsNameFQ": "r_versandStatusGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_EmpfaengerAll"
            },
            "dataModel": "$m_EmpfaengerAll",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_versandStatusGrid",
            "options": {
                "optionsName": "r_versandStatusGridOptions",
                "optionsNameFQ": "r_versandStatusGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_briefTabsSelected = 0;
}