import { autoinject, bindable, observable } from "aurelia-framework";
import { WebEventService } from '../../../../framework/base/export';
import { OnAuftragRequestStatistikEvent, OnAuftragRequestStatistik } from '../../../../framework-data/events';

@autoinject
export class StatistikAuftrag {
  constructor(
    private webEventService: WebEventService
  ) {}

  @bindable @observable idAuftrag: number;
  
  isVisible: boolean = false;
  data: OnAuftragRequestStatistik;

  bind() {
    this.loadData();
  }

  idAuftragChanged() {
    this.loadData();
  }

  private async loadData() {
    if (!this.idAuftrag) {
      this.isVisible = false;
      return;
    }

    const r: OnAuftragRequestStatistik = await this.webEventService.execute(new OnAuftragRequestStatistikEvent({
      IdAuftrag: this.idAuftrag
    }));

    this.isVisible = r.HasData;
    this.data = r;
  }
}