import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    BestellungEditUtils
} from "./bestellung-edit-utils";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class BestellungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_BestellungEditUtils: BestellungEditUtils,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "bestellung-edit";
        this.title = "bestellung-edit.bestellung-edit_caption";
        this.addModel({
            "id": "$m_Bestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "webApiExpand": {
                'Status': null,
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungspositionRel",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "webApiExpand": {
                'Artikel': null,
                'Auftragsposition': {
                    columns: ["IdAuftrag"],
                    'expand': {
                        'Auftrag': {
                            columns: ["Nummer"]
                        }
                    }
                },
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Positionsart': null,
                'Status': null,
                'Bestellungsgruppe': null
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Bestellung.CanSave && !models.data.$m_Bestellung.HasStreckengeschaeftAuftrag && !models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "filters": [{
                "webApiCustomKey": "AddMehrfachbearbeitungTypeName",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_BestellungspositionEdit",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungsgruppeEdit",
            "webApiAction": "ERP/Einkauf/Bestellungsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BestellungsgruppeRel",
            "webApiAction": "ERP/Einkauf/Bestellungsgruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Bestellung.CanSave && !models.data.$m_Bestellung.HasStreckengeschaeftAuftrag && !models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.doWarenuebernahmeErstellenCommand",
                "bindToFQ": "functions.$f_BestellungEditUtils.doWarenuebernahmeErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.icAuftragErstellenCommand",
                "bindToFQ": "functions.$f_BestellungEditUtils.icAuftragErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.doExport",
                "bindToFQ": "functions.$f_BestellungEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.bestaetigenCommand",
                "bindToFQ": "functions.$f_BestellungEditUtils.bestaetigenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.bestellungKopieErstellenCommand",
                "bindToFQ": "functions.$f_BestellungEditUtils.bestellungKopieErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.neueKonditionenLaden",
                "bindToFQ": "functions.$f_BestellungEditUtils.neueKonditionenLaden"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_BestellungEditUtils.positionenSortierenCommand",
                "bindToFQ": "functions.$f_BestellungEditUtils.positionenSortierenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "bestellungspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BestellungspositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BestellungspositionEdit.Id"
                }
            }],
            "id": "bestellungspositionEditPopup",
            "options": {
                "optionsName": "bestellungspositionEditPopupOptions",
                "optionsNameFQ": "bestellungspositionEditPopupOptions"
            },
            "caption": "bestellung-edit.bestellungspositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "bestellungsgruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BestellungsgruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BestellungsgruppeEdit.Id"
                }
            }],
            "id": "bestellungsgruppeEditPopup",
            "options": {
                "optionsName": "bestellungsgruppeEditPopupOptions",
                "optionsNameFQ": "bestellungsgruppeEditPopupOptions"
            },
            "caption": "bestellung-edit.bestellungsgruppeeditpopup_caption",
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_BestellungEditUtils", $f_BestellungEditUtils, "functions.$f_BestellungEditUtils");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "bestellung-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Bestellung.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "bestellung-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Bestellung.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bestellungsart",
            "filters": [],
            "caption": "bestellung-edit.r_bestellungsart_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdBestellungsart",
                "bindToFQ": "models.data.$m_Bestellung.IdBestellungsart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bestellungsart",
            "options": {
                "optionsName": "r_bestellungsartOptions",
                "optionsNameFQ": "r_bestellungsartOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellung-edit.r_datum_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Bestellung.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellung-edit.r_preisdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Bestellung.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bestellung-edit.r_lieferdatum_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "Lieferdatum",
                "bindToFQ": "models.data.$m_Bestellung.Lieferdatum"
            },
            "validationRules": [],
            "id": "r_lieferdatum",
            "options": {
                "optionsName": "r_lieferdatumOptions",
                "optionsNameFQ": "r_lieferdatumOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "bestellung-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabGruppen",
                "caption": "bestellung-edit.r_tabgruppen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "bestellung-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_bespPosNr",
                "caption": "bestellung-edit.r_bespposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_bespArtikelNr",
                "caption": "bestellung-edit.r_bespartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_bespBezeichnung",
                "caption": "bestellung-edit.r_bespbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bespAuftragsNummer",
                "caption": "bestellung-edit.r_bespauftragsnummer_caption",
                "bindTo": "Auftragsposition.Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_bespMengeVerpEH",
                "caption": "bestellung-edit.r_bespmengeverpeh_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespMengeWarenuebernahmeVerpEH",
                "caption": "bestellung-edit.r_bespmengewarenuebernahmeverpeh_caption",
                "bindTo": "MengeWarenuebernahmeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespMengeGeliefertVerpEH",
                "caption": "bestellung-edit.r_bespmengegeliefertverpeh_caption",
                "bindTo": "MengeGeliefertVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespEinheit",
                "caption": "bestellung-edit.r_bespeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespPositionsart",
                "caption": "bestellung-edit.r_besppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespBestellungsgruppe",
                "caption": "bestellung-edit.r_bespbestellungsgruppe_caption",
                "bindTo": "Bestellungsgruppe.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bespWertNetto",
                "caption": "bestellung-edit.r_bespwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "120px",
                "format": "n2"
            }, {
                "id": "r_bespStatus",
                "caption": "bestellung-edit.r_bespstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_bestellungspositionGridToolbarOptions",
                "optionsNameFQ": "r_bestellungspositionGridToolbarOptions"
            },
            "caption": "bestellung-edit.r_bestellungspositiongrid_caption",
            "binding": {
                "dataContext": "$m_Bestellung"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_BestellungspositionRel",
                "bindTo": "IdBestellung",
                "bindToFQ": "models.data.$m_BestellungspositionRel.IdBestellung"
            },
            "dataModel": "$m_Bestellung",
            "editDataContext": "$m_BestellungspositionEdit",
            "idEditPopup": "bestellungspositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Bestellung",
                    "bindTo": "$f_BestellungEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_BestellungEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Bestellung",
                    "bindTo": "$f_BestellungEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_BestellungEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_bestellungspositionGrid",
            "options": {
                "optionsName": "r_bestellungspositionGridOptions",
                "optionsNameFQ": "r_bestellungspositionGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_besgPosNr",
                "caption": "bestellung-edit.r_besgposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_besgBezeichnung",
                "caption": "bestellung-edit.r_besgbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_besgWertnetto",
                "caption": "bestellung-edit.r_besgwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_bestellungsgruppeGridToolbarOptions",
                "optionsNameFQ": "r_bestellungsgruppeGridToolbarOptions"
            },
            "caption": "bestellung-edit.r_bestellungsgruppegrid_caption",
            "binding": {
                "dataContext": "$m_Bestellung"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_BestellungsgruppeRel",
                "bindTo": "IdBestellung",
                "bindToFQ": "models.data.$m_BestellungsgruppeRel.IdBestellung"
            },
            "dataModel": "$m_Bestellung",
            "editDataContext": "$m_BestellungsgruppeEdit",
            "idEditPopup": "bestellungsgruppeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellungsgruppeGrid",
            "options": {
                "optionsName": "r_bestellungsgruppeGridOptions",
                "optionsNameFQ": "r_bestellungsgruppeGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "bestellung-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.Id > 0",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Bestellung.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Bestellung.IdFirma"
            }],
            "filters": [],
            "caption": "bestellung-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.Id > 0",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Bestellung.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerEinkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Bestellung.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "bestellung-edit.r_lieferlager_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.IsIntercompany",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Bestellung.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "bestellung-edit.r_sachbearbeiter_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Bestellung.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "bestellung-edit.r_waehrung_caption",
            "isReadOnlyExpression": "models.data.$m_Bestellung.HasStreckengeschaeftAuftrag || models.data.$m_Bestellung.HasIntercompanyKundenAuftrag",
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Bestellung.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "intercompany",
            "filters": [],
            "caption": "bestellung-edit.r_intercompany_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "IdIntercompany",
                "bindToFQ": "models.data.$m_Bestellung.IdIntercompany"
            },
            "validationRules": [],
            "id": "r_intercompany",
            "options": {
                "optionsName": "r_intercompanyOptions",
                "optionsNameFQ": "r_intercompanyOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "bestellung-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Bestellung",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Bestellung.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}