import * as fwx from "../../../../framework/forms/form-export";
import {
    WarenuebernahmeEditUtils
} from "./warenuebernahme-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    UpdateManuelleTexteUtils
} from "../../../utils/update-manuelle-texte-utils";

@fwx.autoinject
export class WarenuebernahmeEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_WarenuebernahmeEditUtils: WarenuebernahmeEditUtils,
        private $f_StatusService: StatusService,
        private $f_UpdateManuelleTexteUtils: UpdateManuelleTexteUtils) {
        super(element, formBaseImport);
        this.id = "warenuebernahme-edit";
        this.title = "warenuebernahme-edit.warenuebernahme-edit_caption";
        this.addModel({
            "id": "$m_Warenuebernahme",
            "webApiAction": "ERP/Einkauf/Warenuebernahme",
            "webApiExpand": {
                'Status': null,
                'Steuer': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_WarenuebernahmepositionRel",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "webApiExpand": {
                'Artikel': null,
                'Bestellungsposition': {
                    columns: ["IdBestellung"],
                    'expand': {
                        'Bestellung': {
                            columns: ["Nummer"]
                        }
                    }
                },
                'Einheit': {
                    'columns': [
                        'IdEinheit'
                    ],
                    'expand': {
                        'Einheit': {
                            'columns': [
                                'Bezeichnung'
                            ]
                        }
                    }
                },
                'Positionsart': {
                    'columns': [
                        'Bezeichnung'
                    ]
                }
            },
            "webApiWhere": ["IsPositionStuecklistenkomponente", false],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "models.data.$m_Warenuebernahme.CanSave",
            "filters": [{
                "webApiCustomKey": "AddMehrfachbearbeitungTypeName",
                "webApiCustomValue": "true"
            }]
        });
        this.addModel({
            "id": "$m_WarenuebernahempositionEdit",
            "webApiAction": "ERP/Einkauf/Warenuebernahmeposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bestellungsposition",
            "webApiAction": "ERP/Einkauf/Bestellungsposition",
            "webApiExpand": {
                'Bestellung': {
                    'expand': {
                        'Projekt': null
                    }
                },
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Positionsart': null,
                'Status': null
            },
            "webApiWhere": [
                ["Bestellung.IdLieferant", {
                    "isBound": true,
                    "expression": "models.data.$m_Warenuebernahme.IdLieferant"
                }],
                ["Bestellung.IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Warenuebernahme.IdFirma"
                }],
                ["Status.Typ", "0"],
                ["MengeOffenVerpEH", ">", 0],
                ["IsPositionStuecklistenkomponente", false]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_WarenuebernahmeEditUtils.doAbschliessenCommand",
                "bindToFQ": "functions.$f_WarenuebernahmeEditUtils.doAbschliessenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_WarenuebernahmeEditUtils.showImportBestellungspositionenPopupCommand",
                "bindToFQ": "functions.$f_WarenuebernahmeEditUtils.showImportBestellungspositionenPopupCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateKopftextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateKopftextCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_UpdateManuelleTexteUtils.updateFusstextCommand",
                "bindToFQ": "functions.$f_UpdateManuelleTexteUtils.updateFusstextCommand"
            }
        });
        this.addEditPopup({
            "idContent": "warenuebernahmepositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_WarenuebernahempositionEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_WarenuebernahempositionEdit.Id"
                }
            }],
            "id": "warenuebernahmepositionEditPopup",
            "options": {
                "optionsName": "warenuebernahmepositionEditPopupOptions",
                "optionsNameFQ": "warenuebernahmepositionEditPopupOptions"
            },
            "caption": "warenuebernahme-edit.warenuebernahmepositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addFunction("$f_WarenuebernahmeEditUtils", $f_WarenuebernahmeEditUtils, "functions.$f_WarenuebernahmeEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_UpdateManuelleTexteUtils", $f_UpdateManuelleTexteUtils, "functions.$f_UpdateManuelleTexteUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "warenuebernahme-edit.r_nummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Warenuebernahme.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "warenuebernahme-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Warenuebernahme.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "warenuebernahme-edit.r_datum_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_Warenuebernahme.Datum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "warenuebernahme-edit.r_lieferdatum_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "Lieferdatum",
                "bindToFQ": "models.data.$m_Warenuebernahme.Lieferdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferdatum",
            "options": {
                "optionsName": "r_lieferdatumOptions",
                "optionsNameFQ": "r_lieferdatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "warenuebernahme-edit.r_lieferantlieferscheinnr_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "LieferantLieferscheinNr",
                "bindToFQ": "models.data.$m_Warenuebernahme.LieferantLieferscheinNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_lieferantLieferscheinNr",
            "options": {
                "optionsName": "r_lieferantLieferscheinNrOptions",
                "optionsNameFQ": "r_lieferantLieferscheinNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "warenuebernahme-edit.r_lieferantlieferscheindatum_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "LieferantLieferscheindatum",
                "bindToFQ": "models.data.$m_Warenuebernahme.LieferantLieferscheindatum"
            },
            "validationRules": [],
            "id": "r_lieferantLieferscheindatum",
            "options": {
                "optionsName": "r_lieferantLieferscheindatumOptions",
                "optionsNameFQ": "r_lieferantLieferscheindatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "warenuebernahme-edit.r_lieferantrechnungnr_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "LieferantRechnungNr",
                "bindToFQ": "models.data.$m_Warenuebernahme.LieferantRechnungNr"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_lieferantRechnungNr",
            "options": {
                "optionsName": "r_lieferantRechnungNrOptions",
                "optionsNameFQ": "r_lieferantRechnungNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "warenuebernahme-edit.r_lieferantrechnungsdatum_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "LieferantRechnungsdatum",
                "bindToFQ": "models.data.$m_Warenuebernahme.LieferantRechnungsdatum"
            },
            "validationRules": [],
            "id": "r_lieferantRechnungsdatum",
            "options": {
                "optionsName": "r_lieferantRechnungsdatumOptions",
                "optionsNameFQ": "r_lieferantRechnungsdatumOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPositionen",
                "caption": "warenuebernahme-edit.r_tabpositionen_caption"
            }, {
                "id": "r_tabWeitere",
                "caption": "warenuebernahme-edit.r_tabweitere_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_warpArtikelNummer",
                "caption": "warenuebernahme-edit.r_warpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_warpBezeichnung",
                "caption": "warenuebernahme-edit.r_warpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_warpBestellungsNummer",
                "caption": "warenuebernahme-edit.r_warpbestellungsnummer_caption",
                "bindTo": "Bestellungsposition.Bestellung.Nummer",
                "width": "80px"
            }, {
                "id": "r_warpMengeSollVerpEH",
                "caption": "warenuebernahme-edit.r_warpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_warpMengeIstVerpEH",
                "caption": "warenuebernahme-edit.r_warpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_warpEinheit",
                "caption": "warenuebernahme-edit.r_warpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_warpPositionsart",
                "caption": "warenuebernahme-edit.r_warppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_warenuebernahmepositionGridToolbarOptions",
                "optionsNameFQ": "r_warenuebernahmepositionGridToolbarOptions"
            },
            "caption": "warenuebernahme-edit.r_warenuebernahmepositiongrid_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_WarenuebernahmepositionRel",
                "bindTo": "IdWarenuebernahme",
                "bindToFQ": "models.data.$m_WarenuebernahmepositionRel.IdWarenuebernahme"
            },
            "dataModel": "$m_Warenuebernahme",
            "editDataContext": "$m_WarenuebernahempositionEdit",
            "idEditPopup": "warenuebernahmepositionEditPopup",
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_Warenuebernahme",
                    "bindTo": "$f_WarenuebernahmeEditUtils.doCopyPosCommand",
                    "bindToFQ": "functions.$f_WarenuebernahmeEditUtils.doCopyPosCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_Warenuebernahme",
                    "bindTo": "$f_WarenuebernahmeEditUtils.doDeletePosCommand",
                    "bindToFQ": "functions.$f_WarenuebernahmeEditUtils.doDeletePosCommand"
                }
            }],
            "id": "r_warenuebernahmepositionGrid",
            "options": {
                "optionsName": "r_warenuebernahmepositionGridOptions",
                "optionsNameFQ": "r_warenuebernahmepositionGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "warenuebernahme-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Warenuebernahme.Id > 0",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Warenuebernahme.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "warenuebernahme-edit.r_waehrung_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Warenuebernahme.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "warenuebernahme-edit.r_preisdatum_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "Preisdatum",
                "bindToFQ": "models.data.$m_Warenuebernahme.Preisdatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_preisdatum",
            "options": {
                "optionsName": "r_preisdatumOptions",
                "optionsNameFQ": "r_preisdatumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Warenuebernahme.IdFirma"
            }],
            "filters": [],
            "caption": "warenuebernahme-edit.r_lieferfiliale_caption",
            "isReadOnlyExpression": "models.data.$m_Warenuebernahme.Id > 0",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "IdLieferfiliale",
                "bindToFQ": "models.data.$m_Warenuebernahme.IdLieferfiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferfiliale",
            "options": {
                "optionsName": "r_lieferfilialeOptions",
                "optionsNameFQ": "r_lieferfilialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerEinkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Warenuebernahme.IdLieferfiliale"
            }],
            "filters": [],
            "caption": "warenuebernahme-edit.r_lieferlager_caption",
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Warenuebernahme.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuerTyp",
            "filters": [],
            "caption": "warenuebernahme-edit.r_steuersteuertyp_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Warenuebernahme",
                "bindTo": "Steuer.SteuerTyp",
                "bindToFQ": "models.data.$m_Warenuebernahme.Steuer.SteuerTyp",
                "propertyPrefix": "Steuer"
            },
            "validationRules": [],
            "id": "r_steuerSteuertyp",
            "options": {
                "optionsName": "r_steuerSteuertypOptions",
                "optionsNameFQ": "r_steuerSteuertypOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bestellungspositionEditPopup",
            "options": {
                "optionsName": "r_bestellungspositionEditPopupOptions",
                "optionsNameFQ": "r_bestellungspositionEditPopupOptions"
            },
            "caption": "warenuebernahme-edit.r_bestellungspositioneditpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_WarenuebernahmeEditUtils.importBestellungspositionenCommand",
                    "bindToFQ": "functions.$f_WarenuebernahmeEditUtils.importBestellungspositionenCommand"
                }
            }]
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_bespBestellungNummer",
                "caption": "warenuebernahme-edit.r_bespbestellungnummer_caption",
                "bindTo": "Bestellung.Nummer",
                "width": "80px"
            }, {
                "id": "r_bespDatum",
                "caption": "warenuebernahme-edit.r_bespdatum_caption",
                "bindTo": "Bestellung.Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_bespProjekt",
                "caption": "warenuebernahme-edit.r_bespprojekt_caption",
                "bindTo": "Bestellung.Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_bespArtikelNummer",
                "caption": "warenuebernahme-edit.r_bespartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_bespArtikel",
                "caption": "warenuebernahme-edit.r_bespartikel_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bespBezeichnung",
                "caption": "warenuebernahme-edit.r_bespbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bespMengeOffenVerpEH",
                "caption": "warenuebernahme-edit.r_bespmengeoffenverpeh_caption",
                "bindTo": "MengeOffenVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_bespEinheit",
                "caption": "warenuebernahme-edit.r_bespeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespPositionsart",
                "caption": "warenuebernahme-edit.r_besppositionsart_caption",
                "bindTo": "Positionsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_bespStatus",
                "caption": "warenuebernahme-edit.r_bespstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellungspositionGridToolbarOptions",
                "optionsNameFQ": "r_bestellungspositionGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bestellungsposition"
            },
            "dataModel": "$m_Bestellungsposition",
            "selectionMode": 2,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellungspositionGrid",
            "options": {
                "optionsName": "r_bestellungspositionGridOptions",
                "optionsNameFQ": "r_bestellungspositionGridOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}