import { autoinject, transient } from "aurelia-framework";
import { OnAuftragListBestaetigt, OnAuftragListBestaetigtEvent, OnAuftragListLieferdatumAendern, OnAuftragListLieferdatumAendernEvent } from "../../../../framework-data/events";
import { LocalizationService, WebEventService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent, IdxDateBoxComponent, IdxPopupComponent } from "../../../interfaces/dx-components";
import { AsyncService } from "./../../../../framework/base/services/async-service";
import { BelegExportService } from "./../../../services/beleg-export-service";
import { StartupService } from "./../../../services/startup-service";

@autoinject
@transient()
export class AuftragListUtils {
  private NOTIFY_TIMEOUT: number = 3000;

  constructor(
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService,
    private _localizationService: LocalizationService,
    private _webEventService: WebEventService) { }

  form: FormBase;
  isBelegExportMandantAktiv: boolean;
  auftragGridRowDataList: any[];

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_AuftragListUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_AuftragListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_auftragGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Auftrag, keys);
    }
  };

  bestaetigenSetzenCommand: ICommandData = {
    id: "bestaetigenSetzen",
    idCategory: "auftrag",
    title: "auftrag.bestaetigen",
    icon: "star-o",
    sortIndex: 10,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_auftragGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const auftragGridData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (auftragGridData.length == 0) {
        return;
      }

      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("auftrag.sure_bestaetigen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasBestaetigteEintraege = auftragGridData
        .some((c) => c.IsBestaetigt);

      if (hasBestaetigteEintraege) {
        const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
          this._localizationService.translateOnce("auftrag.hinweis_bestaetigt"),
          this._localizationService.translateOnce("base.question")));

        if (!doExecute) {
          return;
        }
      }

      const idAuftragList = auftragGridData.map(c => c.Id);

      const args: OnAuftragListBestaetigt = {
        IdAuftragList: idAuftragList,
        IsBestaetigt: true
      };

      const webEventResult: OnAuftragListBestaetigt = await this._webEventService.execute(new OnAuftragListBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };

  bestaetigenEntfernenCommand: ICommandData = {
    id: "bestaetigenEntfernen",
    idCategory: "auftrag",
    title: "auftrag.nicht_bestaetigen",
    icon: "star",
    sortIndex: 10,
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_auftragGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const auftragGridData = await this._asyncService.convertToPromise(grid.instance.getSelectedRowsData());

      if (auftragGridData.length == 0) {
        return;
      }

      grid.instance.clearSelection();

      const title = this._localizationService.translateOnce("base.question");
      const question = this._localizationService.translateOnce("auftrag.sure_nicht_bestaetigen");

      const isConfirmed = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(question, title));
      if (!isConfirmed) {
        return;
      }

      const hasBestaetigteEintraege = auftragGridData
        .some((c) => !c.IsBestaetigt);

      if (hasBestaetigteEintraege) {
        const doExecute = await this._asyncService.convertToPromise(DevExpress.ui.dialog.confirm(
          this._localizationService.translateOnce("auftrag.hinweis_nicht_bestaetigt"),
          this._localizationService.translateOnce("base.question")));

        if (!doExecute) {
          return;
        }
      }

      const idAuftragList = auftragGridData.map(c => c.Id);

      const args: OnAuftragListBestaetigt = {
        IdAuftragList: idAuftragList,
        IsBestaetigt: false
      };

      const webEventResult: OnAuftragListBestaetigt = await this._webEventService.execute(new OnAuftragListBestaetigtEvent(args), true);
      return this.form.models.reloadAll();
    }
  };

  lieferdatumAendernCommand: ICommandData = {
    id: "lieferdatumAendern",
    idCategory: "auftrag",
    title: "auftrag.lieferdatum_aendern",
    icon: "calculator",
    sortIndex: 10,
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_auftragLieferdatumAendernPopup"];

      popup.instance.show();

      const dateBox: IdxDateBoxComponent = this.form["r_datum"];
      dateBox.setOption({
        value: null
      });

      return this.form.models.reloadAll();
    }
  };

  doLieferdatumAendernCommand: ICommandData = {
    id: "doLieferdatumAendern",
    title: "auftrag.lieferdatum_aendern",
    icon: "save",
    execute: async () => {
      const popup: IdxPopupComponent = this.form["r_auftragLieferdatumAendernPopup"];
      const dateBox: IdxDateBoxComponent = this.form["r_datum"];

      const datum = dateBox.instance.option("value");
      if (!datum) {
        DevExpress.ui.notify(this._localizationService.translateOnce("auftrag.datum_auswaehlen"), "error", this.NOTIFY_TIMEOUT);
        return;
      }

      const grid: IdxDataGridComponent = this.form["r_auftragGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const idAuftragList = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      const args: OnAuftragListLieferdatumAendern = {
        IdAuftragList: idAuftragList,
        Lieferdatum: datum
      };

      const webEventResult: OnAuftragListLieferdatumAendern = await this._webEventService.execute(new OnAuftragListLieferdatumAendernEvent(args), true);
      this.form.models.reloadAll();
      popup.instance.hide();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Auftrag);
      return Promise.resolve();
    });
  }

  onReservierungsgradCellPrepared(e): void {
    if (e.rowType != "data" || e.data.IsBestaetigt == false || e.data.Status.Typ !== 0) {
      return;
    }

    const cell: HTMLTableCellElement = e.cellElement;

    cell.style.color = "white";
    cell.style.backgroundColor = e.data.Reservierungsgrad < 1
      ? "#C1392D"
      : (e.data.HasArtikelNichtLagerfuehrendOffen ? "#566473" : "#2DCC70");
  }
}
