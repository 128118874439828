import * as fwx from "../../../../framework/forms/form-export";
import {
    BelegImportEditUtils
} from "./beleg-import-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class BelegImportEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_belegImportEditUtils: BelegImportEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "beleg-import-edit";
        this.title = "beleg-import-edit.beleg-import-edit_caption";
        this.addModel({
            "id": "$m_BelegImport",
            "webApiAction": "ERP/Import/BelegImport",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_belegImportEditUtils.doFreigebenCommand",
                "bindToFQ": "functions.$f_belegImportEditUtils.doFreigebenCommand"
            }
        });
        this.addFunction("$f_belegImportEditUtils", $f_belegImportEditUtils, "functions.$f_belegImportEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "beleg-import-edit.r_dateiname_caption",
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "Dateiname",
                "bindToFQ": "models.data.$m_BelegImport.Dateiname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_dateiname",
            "options": {
                "optionsName": "r_dateinameOptions",
                "optionsNameFQ": "r_dateinameOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "beleg-import-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_BelegImport.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "beleg-import-edit.r_firma_caption",
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_BelegImport.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mandantZuBelegImportTyp",
            "filters": [],
            "caption": "beleg-import-edit.r_typ_caption",
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_BelegImport.Typ"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_typ",
            "options": {
                "optionsName": "r_typOptions",
                "optionsNameFQ": "r_typOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 4,
            "showClearButton": false,
            "caption": "beleg-import-edit.r_dmslink_caption",
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "DMSLink",
                "bindToFQ": "models.data.$m_BelegImport.DMSLink"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_dmsLink",
            "options": {
                "optionsName": "r_dmsLinkOptions",
                "optionsNameFQ": "r_dmsLinkOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "beleg-import-edit.r_fehlerinfo_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_BelegImport",
                "bindTo": "Fehlerinfo",
                "bindToFQ": "models.data.$m_BelegImport.Fehlerinfo"
            },
            "validationRules": [],
            "id": "r_fehlerInfo",
            "options": {
                "optionsName": "r_fehlerInfoOptions",
                "optionsNameFQ": "r_fehlerInfoOptions"
            }
        });
        super.onConstructionFinished();
    }
}