import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from "aurelia-framework";
import * as moment from "moment";
import { OnStatistikGruppiertDashboardEvent, StatistikVerkaufZeitraumTyp, UmsatzWhereTyp } from '../../../../framework-data/events';
import { GlobalizationService, WebEventService } from '../../../../framework/base/export';
import { IUmsatzWhereItem, StatistikVerkaufDetailViewTyp } from '../../../elements/statistik-verkauf-detail/statistik-verkauf-detail';
import { IdxChartComponent } from '../../../interfaces/export';
import { DynFelderService, FirmaService } from '../../../services/export';
import { StatistikService } from '../../../services/statistik-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { ScopeContainer } from './../../../../framework/base/classes/scope-container';
import { IStatistikGruppiertSettings } from './statistik-gruppiert-settings';

@autoinject
export class StatistikGruppiert {
  private _windowResizeSubscription: Subscription;

  constructor(
    private _element: Element,
    private _firmaService: FirmaService,
    private _globalizationService: GlobalizationService,
    private _webEventService: WebEventService,
    private _dynFelderService: DynFelderService,
    private _eventAggregator: EventAggregator,
    private _statistikVerkaufService: StatistikService) { }

  scopeContainer: ScopeContainer;

  widgetModel: IWidgetModel;
  settings: IStatistikGruppiertSettings;

  ueberschrift: string;
  zeitraum: string;
  firmaBezeichnung: string;
  dataSource: any[];

  pieChart: IdxChartComponent;
  pieChartOptions: DevExpress.viz.dxPieChartOptions = {
    bindingOptions: {
      dataSource: "dataSource"
    },
    legend: {
      orientation: "horizontal",
      itemTextPosition: "right",
      horizontalAlignment: "center",
      verticalAlignment: "bottom",
      columnCount: 4
    },
    resolveLabelOverlapping: "shift",
    series: [{
      argumentField: "Bezeichnung",
      valueField: "Wert",
      label: {
        visible: true,
        connector: {
          visible: true,
          width: 1
        },
        position: "columns",
        format: this._globalizationService.getFormatterParser("n0"),
      }
    }],
    onPointClick: (e) => {
      this.showStatistikDetail(e.target.data);
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;
  }

  bind() {
    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });
  }
  attached() {
    this.loadData();

    this._windowResizeSubscription = this._eventAggregator.subscribe("window:resize", () => {
      this.updateChartHeight();
    });
  }
  detached() {
    this._windowResizeSubscription.dispose();
    this._windowResizeSubscription = null;
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scopeContainer = null;
  }

  onWertClick(e: Event, item) {
    e.preventDefault();
    e.stopPropagation();

    this.showStatistikDetail(item);
  }

  private showStatistikDetail(item) {
    let zeitraumTypValue = moment().year();
    if (this.settings.zeitraumTyp == StatistikVerkaufZeitraumTyp.Vorjahr) {
      zeitraumTypValue = zeitraumTypValue - 1;
    }

    let zeitraumTyp = UmsatzWhereTyp.Jahr;
    if (this.settings.zeitraumTyp == StatistikVerkaufZeitraumTyp.YTD) {
      zeitraumTyp = UmsatzWhereTyp.YTD;
    }

    const where: IUmsatzWhereItem = {
      Typ: zeitraumTyp,
      IdGeschaeftspartner: this.getIdGeschaeftspartner(),
      IdArtikel: this.getIdArtikel(),
      IdFirma: this.settings.idFirma,
      Data: {
        Jahr: zeitraumTypValue,
        Datum: moment(new Date())
      }
    }

    if (this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Projekt") {
      where.IdProjekt = item.Id;
    } else if (item.Id != -1) {
      where.Data.ArtikelGeschaeftspartner = {
        EntitaetFullName: this.settings.entitaetFullname,
        IdIncludeList: [item.Id],
        IdDynFeld: this.settings.idDynFeld
      }
    } else if (item.Id == -1) {
      let idList = this.dataSource.map(c => c.Id);
      where.Data.ArtikelGeschaeftspartner = {
        EntitaetFullName: this.settings.entitaetFullname,
        IdDynFeld: this.settings.idDynFeld,
        IdExcludeList: idList,
      }
    } else {
      return;
    }

    this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
  }

  async loadData() {
    this.setUeberschrift();
    this.setZeitraumTypUeberschrift();
    this.updateChartHeight();
    this.getFirmaBezeichnung();

    if (!this.settings.entitaetFullname || !this.settings.darstellungTyp) {
      this.dataSource = [];
      return;
    }
    if ((this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel" || this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner") && !this.settings.idDynFeld) {
      this.dataSource = [];
      return;
    }

    const result = await this._webEventService.execute(new OnStatistikGruppiertDashboardEvent({
      EntitaetFullName: this.settings.entitaetFullname,
      IdDynFeld: this.settings.idDynFeld,
      IdFirma: this.settings.idFirma,
      ZeitraumTyp: this.settings.zeitraumTyp,
      Anzahl: this.settings.anzahl,
      ShowRest: this.settings.showRest,
      IdGeschaeftspartner: this.getIdGeschaeftspartner(),
      IdArtikel: this.getIdArtikel()
    }), true);

    if (!result) {
      return;
    }
    this.dataSource = result.DataList;
  }

  private setZeitraumTypUeberschrift() {
    if (this.settings.zeitraumTyp == StatistikVerkaufZeitraumTyp.YTD) {
      this.zeitraum = "YTD";
    } else if (this.settings.zeitraumTyp == StatistikVerkaufZeitraumTyp.Jahr) {
      this.zeitraum = "Aktuelles Jahr";
    } else if (this.settings.zeitraumTyp == StatistikVerkaufZeitraumTyp.Vorjahr) {
      this.zeitraum = "Vorjahr";
    }
  }

  private async setUeberschrift() {
    if (!this.settings.entitaetFullname) {
      return;
    }
    if (this.settings.entitaetFullname == "TIP.ERP.Business.Entitaeten.Stammdaten.Projekt") {
      this.ueberschrift = "Statistik gruppiert nach Projekt";
    }
    else if (this.settings.idDynFeld) {
      var dynFeld = await this._dynFelderService.getDynFeldById(this.settings.idDynFeld);

      if (!dynFeld) {
        return;
      }
      this.ueberschrift = "Statistik gruppiert nach ";
      this.ueberschrift = this.ueberschrift.concat(dynFeld.Bezeichnung);
    }
  }

  private updateChartHeight() {
    const container = <HTMLDivElement>this._element.querySelector(".statistik-gruppiert__container__content");
    const chartHeight = container.clientHeight;

    this.pieChartOptions.size = {
      height: chartHeight
    };

    if (this.pieChart && this.pieChart.instance) {
      this.pieChart.setOption({
        size: {
          height: chartHeight
        }
      });
    }
  }

  private getIdGeschaeftspartner() {
    return this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
      ? this.widgetModel.mainModel.Id
      : null;

  }

  private getIdArtikel() {
    return this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
      ? this.widgetModel.mainModel.Id
      : null;
  }

  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
