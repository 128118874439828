import { autoinject } from "aurelia-framework";
import { IWidgetModel } from '../../interfaces/widget-model';
import { IAuswertungSettings } from './auswertung-settings';
import { Report } from '../../../../framework/reports/elements/report/report';
import { RestService } from '../../../../framework/base/export';

@autoinject
export class Auswertung {
  constructor(
    private _restService: RestService
  ) { }

  widgetModel: IWidgetModel;
  settings: IAuswertungSettings;
  report: Report;
  widgetUeberschrift: string;

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;

    this.loadData();
  }

  async loadData() {
    if (!this.settings.idAuswertung) {
      return;
    }

    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`Framework/Report/Definition/${this.settings.idAuswertung}`),
      getOptions: {
        columns: ["Id", "Name"]
      }
    });

    if (!r) {
      return;
    }
    this.widgetUeberschrift = r.Name;
  }
}
