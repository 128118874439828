import {
  autoinject,
  valueConverter
} from "aurelia-framework";

@autoinject
@valueConverter("industriezeitZuZeit")
export class IndustriezeitZuZeitValueConverter {
  constructor(
  ) { }

  toView(value: any) {
    if (value == null) {
      return "";
    }
    if (typeof value !== "number") {
      return value;
    }

    let stundenZahl = Math.floor(value);
    let stunden = stundenZahl.toString();
    if (stunden.length < 2) {
      stunden = "0" + stunden;
    }

    let minuten = Math.round((value - stundenZahl) * 60).toString();
    if (minuten.length < 2) {
      minuten = "0" + minuten;
    }

    return stunden.concat(":").concat(minuten);
  }
}
