import { autoinject, elementConfig } from 'aurelia-framework';
import { CustomEditPopupService } from '../services/export';
import { CustomEditPopup } from '../../framework/forms/export';

@autoinject
export class OpenEditCustomAttribute {
  private _onElementClicked: any;
  private _isClickRegistered: boolean = false;

  constructor(
    private customEditPopup: CustomEditPopupService,
    private element: Element
  ) {
    this._onElementClicked = this.onElementClicked.bind(this);
  }

  value: any;
  bind() {
    const el: HTMLElement = <any>this.element;

    const canOpen = this.value
      && (this.value.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Person"
      || this.value.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner");

    if (canOpen) {
      el.style.display = null;
      this._isClickRegistered = true;
      this.element.addEventListener("click", this._onElementClicked);
    } else {
      el.style.display = "none";
      this._isClickRegistered = false;
    }
  }
  unbind() {
    if (this._isClickRegistered) {
      this.element.removeEventListener("click", this._onElementClicked);
    }
  }

  onElementClicked(e: MouseEvent) {
    if (!this.value || !this.value.TypeName) {
      return;
    }

    const editPopup = this.getEditPopup();
    if (!editPopup) {
      return;
    }

    e.stopPropagation();

    this.openEditPopup(editPopup);
  }

  private getEditPopup(): CustomEditPopup {
    switch (this.value.TypeName) {
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Person":
        return this.customEditPopup.personEditPopup;
      case "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner":
        return this.customEditPopup.geschaeftspartnerEditPopup
      default:
        return null;
    }
  }
  private openEditPopup(editPopup: CustomEditPopup) {
    editPopup.show({
      mappings: {
        $id: this.value.Id
      }
    });
  }
}
