import * as fwx from "../../../../framework/forms/form-export";
import {
    ArtikelverleihEditUtils
} from "./artikelverleih-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ArtikelverleihEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ArtikelverleihEditUtils: ArtikelverleihEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "artikel-verleih-edit";
        this.title = "artikel-verleih-edit.artikel-verleih-edit_caption";
        this.addModel({
            "id": "$m_Artikelverleih",
            "webApiAction": "ERP/Lager/Artikelverleih",
            "webApiExpand": {
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Artikel",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "webApiColumns": ["Id", "IdEinheit"],
            "webApiExpand": {
                'Einheit': {
                    'columns': [
                        'Id',
                        'Bezeichnung'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Artikelverleih.IdArtikel",
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelverleihEditUtils.doAbholen",
                "bindToFQ": "functions.$f_ArtikelverleihEditUtils.doAbholen"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelverleihEditUtils.doRueckgabe",
                "bindToFQ": "functions.$f_ArtikelverleihEditUtils.doRueckgabe"
            }
        });
        this.addFunction("$f_ArtikelverleihEditUtils", $f_ArtikelverleihEditUtils, "functions.$f_ArtikelverleihEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "artikel-verleih-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.Id > 0",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Artikelverleih.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "filiale",
            "filter": ["IdFirma", {
                "isBound": true,
                "expression": "models.data.$m_Artikelverleih.IdFirma"
            }],
            "filters": [],
            "caption": "artikel-verleih-edit.r_filiale_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.IsAbgeholt",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IdFiliale",
                "bindToFQ": "models.data.$m_Artikelverleih.IdFiliale"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_filiale",
            "options": {
                "optionsName": "r_filialeOptions",
                "optionsNameFQ": "r_filialeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filter": ["IdFiliale", {
                "isBound": true,
                "expression": "models.data.$m_Artikelverleih.IdFiliale"
            }],
            "filters": [],
            "caption": "artikel-verleih-edit.r_lager_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.IsAbgeholt",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IdLager",
                "bindToFQ": "models.data.$m_Artikelverleih.IdLager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lager",
            "options": {
                "optionsName": "r_lagerOptions",
                "optionsNameFQ": "r_lagerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "artikel-verleih-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Artikelverleih.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "benutzer",
            "filters": [],
            "caption": "artikel-verleih-edit.r_sachbearbeiter_caption",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IdSachbearbeiter",
                "bindToFQ": "models.data.$m_Artikelverleih.IdSachbearbeiter"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_sachbearbeiter",
            "options": {
                "optionsName": "r_sachbearbeiterOptions",
                "optionsNameFQ": "r_sachbearbeiterOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-verleih-edit.r_vorlreservierung_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.IsAbgeholt",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "IsVorlaeufigeReservierung",
                "bindToFQ": "models.data.$m_Artikelverleih.IsVorlaeufigeReservierung"
            },
            "validationRules": [],
            "id": "r_vorlReservierung",
            "options": {
                "optionsName": "r_vorlReservierungOptions",
                "optionsNameFQ": "r_vorlReservierungOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "artikel-verleih-edit.r_geplabhol_caption",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "GeplanteAbholung",
                "bindToFQ": "models.data.$m_Artikelverleih.GeplanteAbholung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geplAbhol",
            "options": {
                "optionsName": "r_geplAbholOptions",
                "optionsNameFQ": "r_geplAbholOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "artikel-verleih-edit.r_tatsabhol_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.IsAbgeholt",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "TatsaechlicheAbholung",
                "bindToFQ": "models.data.$m_Artikelverleih.TatsaechlicheAbholung"
            },
            "validationRules": [],
            "id": "r_tatsAbhol",
            "options": {
                "optionsName": "r_tatsAbholOptions",
                "optionsNameFQ": "r_tatsAbholOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "artikel-verleih-edit.r_geplrueck_caption",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "GeplanteRueckgabe",
                "bindToFQ": "models.data.$m_Artikelverleih.GeplanteRueckgabe"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_geplRueck",
            "options": {
                "optionsName": "r_geplRueckOptions",
                "optionsNameFQ": "r_geplRueckOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "artikel-verleih-edit.r_tatsrueck_caption",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "TatsaechlicheRueckgabe",
                "bindToFQ": "models.data.$m_Artikelverleih.TatsaechlicheRueckgabe"
            },
            "validationRules": [],
            "id": "r_tatsRueck",
            "options": {
                "optionsName": "r_tatsRueckOptions",
                "optionsNameFQ": "r_tatsRueckOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "artikel-verleih-edit.r_mengelageh_caption",
            "isReadOnlyExpression": "models.data.$m_Artikelverleih.IsAbgeholt",
            "binding": {
                "dataContext": "$m_Artikelverleih",
                "bindTo": "MengeLagEH",
                "bindToFQ": "models.data.$m_Artikelverleih.MengeLagEH"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_mengeLagEH",
            "options": {
                "optionsName": "r_mengeLagEHOptions",
                "optionsNameFQ": "r_mengeLagEHOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "artikel-verleih-edit.r_lagereinheit_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Einheit.Bezeichnung",
                "bindToFQ": "models.data.$m_Artikel.Einheit.Bezeichnung",
                "propertyPrefix": "Einheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_lagereinheit",
            "options": {
                "optionsName": "r_lagereinheitOptions",
                "optionsNameFQ": "r_lagereinheitOptions"
            }
        });
        super.onConstructionFinished();
    }
}