import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class ProvisionspositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "provisionsposition-edit";
        this.title = "provisionsposition-edit.provisionsposition-edit_caption";
        this.addModel({
            "id": "$m_Provisionsposition",
            "webApiAction": "ERP/Provision/Provisionsposition",
            "webApiExpand": {
                'Fakturaposition': {
                    'expand': {
                        'Faktura': {
                            'expand': {
                                'RechnungsortAdresse': null
                            }
                        },
                        'Artikel': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "provisionsposition-edit.r_fakturanummer_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provisionsposition",
                "bindTo": "Fakturaposition.Faktura.Nummer",
                "bindToFQ": "models.data.$m_Provisionsposition.Fakturaposition.Faktura.Nummer",
                "propertyPrefix": "Fakturaposition.Faktura"
            },
            "validationRules": [],
            "id": "r_fakturaNummer",
            "options": {
                "optionsName": "r_fakturaNummerOptions",
                "optionsNameFQ": "r_fakturaNummerOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "provisionsposition-edit.r_fakturadatum_caption",
            "isReadOnly": true,
            "isReadOnlyExpression": "models.data.$m_Provisionsposition.Fakturaposition.Faktura.Nummer",
            "binding": {
                "dataContext": "$m_Provisionsposition",
                "bindTo": "Fakturaposition.Faktura.Datum",
                "bindToFQ": "models.data.$m_Provisionsposition.Fakturaposition.Faktura.Datum",
                "propertyPrefix": "Fakturaposition.Faktura"
            },
            "validationRules": [],
            "id": "r_fakturaDatum",
            "options": {
                "optionsName": "r_fakturaDatumOptions",
                "optionsNameFQ": "r_fakturaDatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n4",
            "caption": "provisionsposition-edit.r_basis_caption",
            "binding": {
                "dataContext": "$m_Provisionsposition",
                "bindTo": "Basis",
                "bindToFQ": "models.data.$m_Provisionsposition.Basis"
            },
            "validationRules": [],
            "id": "r_basis",
            "options": {
                "optionsName": "r_basisOptions",
                "optionsNameFQ": "r_basisOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n4",
            "caption": "provisionsposition-edit.r_multiplikator_caption",
            "binding": {
                "dataContext": "$m_Provisionsposition",
                "bindTo": "Multiplikator",
                "bindToFQ": "models.data.$m_Provisionsposition.Multiplikator"
            },
            "validationRules": [],
            "id": "r_multiplikator",
            "options": {
                "optionsName": "r_multiplikatorOptions",
                "optionsNameFQ": "r_multiplikatorOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "provisionsposition-edit.r_wertnetto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Provisionsposition",
                "bindTo": "WertNetto",
                "bindToFQ": "models.data.$m_Provisionsposition.WertNetto"
            },
            "validationRules": [],
            "id": "r_wertNetto",
            "options": {
                "optionsName": "r_wertNettoOptions",
                "optionsNameFQ": "r_wertNettoOptions"
            }
        });
        super.onConstructionFinished();
    }
}