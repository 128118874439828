import {
  autoinject,
  customAttribute,
  TaskQueue
} from "aurelia-framework";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { WindowService } from '../../export';

@autoinject
@customAttribute("gr-size")
export class GridSizeAttribute {
  private _windowResizeSubscription: Subscription;
  private _checkClassSubscription: Subscription;
  private _currentStyle;
  private _detached = false;
  private _setClassCounter = 0;

  constructor(
    private element: Element,
    private taskQueue: TaskQueue,
    private eventAggregator: EventAggregator,
    private windowService: WindowService
  ) { }

  bind() {
    this._checkClassSubscription = this.eventAggregator.subscribe("grid:check-class", (e) => {
      if (e.element) {
        if (!this.isParent(e.element)) {
          return;
        }
      }
      this.setClass();
    });
    this._windowResizeSubscription = this.eventAggregator.subscribe("window:resize", () => {
      this.setClass();
    });
  }
  unbind() {
    this._checkClassSubscription.dispose();
    this._windowResizeSubscription.dispose();
  }
  attached() {
    this.element.classList.add("gr");

    this._detached = false;
    this.setClass();
  }
  detached() {
    this._detached = true;
  }

  private isParent(parent): boolean {
    let child = this.element;

    while (child != null) {
      if (child == parent) {
        return true;
      }

      child = child.parentElement;
    }

    return false;
  }
  private setClass(start: boolean = true) {
    if (start) {
      this._setClassCounter = 0;
    }
    if (this._detached || this._setClassCounter > 60) {
      return;
    }

    const element  = this.element;
    let widthElement = <HTMLElement>element;
    while (widthElement && this.isInline(widthElement)) {
      widthElement = widthElement.parentElement;
    }

    const styles = ["xs"];
    const width = widthElement
      ? widthElement.clientWidth
      : 0;

    if (!width) {
      setTimeout(() => {
        this._setClassCounter++;
        this.setClass(false)
      }, 50);
    }

    if (width >= 768) {
      styles.push("sm");
    }
    if (width >= 992) {
      styles.push("md");
    }
    if (width >= 1200) {
      styles.push("lg");
    }

    const newStyle = styles.join(" ");
    if (this._currentStyle == newStyle) {
      return;
    }

    this._currentStyle = newStyle;

    element.classList.add("gr-xs");

    if (width >= 768) {
      element.classList.add("gr-sm");
    } else {
      element.classList.remove("gr-sm");
    }

    if (width >= 992) {
      element.classList.add("gr-md");
    } else {
      element.classList.remove("gr-md");
    }

    if (width >= 1200) {
      element.classList.add("gr-lg");
    } else {
      element.classList.remove("gr-lg");
    }

    if (width >= 1500) {
      element.classList.add("gr-xl");
    } else {
      element.classList.remove("gr-xl");
    }
  }

  private isInline(element: HTMLElement): boolean {
    return window.getComputedStyle(element).display == "inline";
  }
}
