import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { TaskQueue, autoinject, computedFrom } from "aurelia-framework";
import * as moment from "moment";
import { OnUmsatzMonat, OnUmsatzMonatEvent, StatistikVerkaufDetailViewTyp, StatistikVerkaufWertTyp } from '../../../../framework-data/events';
import { GlobalizationService, WebEventService } from '../../../../framework/base/export';
import { ExcelExportService } from "../../../../framework/forms/services/excel-export-service";
import { IdxChartComponent, IdxDataGridComponent } from '../../../interfaces/export';
import { FirmaService, StartupService } from '../../../services/export';
import { StatistikService } from '../../../services/statistik-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IUmsatzMonatSettings } from './umsatz-monat-settings';

@autoinject
export class UmsatzMonat {
  private _windowResizeSubscription: Subscription;

  constructor(
    private _firmaService: FirmaService,
    private _globalizationService: GlobalizationService,
    private _webEventService: WebEventService,
    private _startupService: StartupService,
    private _taskQueue: TaskQueue,
    private _statistikVerkaufService: StatistikService,
    private _eventAggregator: EventAggregator,
    private _excelExportService: ExcelExportService) { }

  widgetModel: IWidgetModel;
  settings: IUmsatzMonatSettings;
  firmaBezeichnung: string;
  displayType: DisplayType = DisplayType.chart;
  statistikWertTyp: StatistikVerkaufWertTyp = StatistikVerkaufWertTyp.Netto;

  instance: IdxChartComponent;
  options: DevExpress.viz.charts.dxChartOptions = {
    commonSeriesSettings: {
      argumentField: "Name",
      type: "bar"
    },
    tooltip: {
      format: this._globalizationService.getFormatterParser("n0"),
      zIndex: 1505,
      customizeTooltip: (e) => {
        return {
          text: `${e.argumentText} ${e.seriesName}: ${this._startupService.startupInfo.Mandant.WaehrungCode} ${e.valueText}`
        };
      },
      enabled: true,
    },
    series: [{
      valueField: "Vergleich2", name: moment().subtract(2, "year").year().toString()
    }, {
      valueField: "Vergleich", name: moment().subtract(1, "year").year().toString()
    }, {
      valueField: "Wert", name: moment().year().toString()
    }],
    valueAxis: [{
      label: { format: this._globalizationService.getFormatterParser("n0") }
    }],
    onPointClick: (e) => {
      if (!e.target || !e.target.data) {
        return;
      }
      if (!e.target.series.getValueFields) {
        return;
      }

      const valueFields = e.target.series.getValueFields();
      if (valueFields.length != 1) {
        return;
      }

      const valueField = valueFields[0];
      const where = e.target.data["Where".concat(valueField)];
      if (!where) {
        return;
      }

      if (this.statistikWertTyp == StatistikVerkaufWertTyp.Auftragseingang) {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Auftrag, where);
      } else if (this.statistikWertTyp == StatistikVerkaufWertTyp.NettoMitForecast) {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Forecast, where);
      } else {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
      }
    }
  };

  gridInstance: IdxDataGridComponent
  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [
      { caption: "Jahr", dataField: "Jahr", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Jan", dataField: "Jan", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Feb", dataField: "Feb", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Mrz", dataField: "Mär", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Apr", dataField: "Apr", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Mai", dataField: "Mai", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Jun", dataField: "Jun", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Jul", dataField: "Jul", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Aug", dataField: "Aug", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Sep", dataField: "Sep", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Okt", dataField: "Okt", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Nov", dataField: "Nov", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Dez", dataField: "Dez", cellTemplate: this.getCellValue.bind(this) },
      { caption: "Gesamt", dataField: "Gesamt", cellTemplate: this.getCellValue.bind(this) }
    ],
    showRowLines: true,
    sorting: {
      mode: "none"
    },
    export: {
      customizeExcelCell: (e) => {
        if (e.gridCell.rowType === "data") {
          if (e.gridCell.data.IsDiff) {
            e.fillPatternColor = "#EEEEEE";
            e.fillPatternType = "solid";
            e.font.bold = true;
          }

          if (e.gridCell.column.dataField == "Jahr") {
            e.font.bold = true;
            return;
          }
          if (e.gridCell.column.dataField == "Gesamt") {
            e.font.bold = true;
          }

          if (e.gridCell.data.IsDiff) {
            e.numberFormat = "#,##0 %";

            if (e.value > 0) {
              e.fillPatternColor = "#27AE60";
              e.fillPatternType = "solid";
              e.font.color = "#FFFFFF";
            } else if (e.value < 0) {
              e.fillPatternColor = "#C0392B";
              e.fillPatternType = "solid";
              e.font.color = "#FFFFFF";
            } else {
              e.numberFormat = "#,##0 %";
            }
          } else {
            e.numberFormat = "#,##0";
          }
        }
      }
    },
    onCellClick: (e) => {
      if (!e.data) {
        return;
      }
      if (e.data.IsDiff) {
        return;
      }
      if (!e.column) {
        return;
      }

      let where = null;
      if (e.column.dataField == "Gesamt") {
        where = {
          Typ: 2,
          Data: {
            Jahr: e.data.Jahr
          },
          IdGeschaeftspartner: this.getIdGeschaeftspartner(),
          IdArtikel: this.getIdArtikel()
        };
      } else {
        where = e.data["Where".concat(e.column.dataField)];
      }

      if (!where) {
        return;
      }

      if (this.statistikWertTyp == StatistikVerkaufWertTyp.Auftragseingang) {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Auftrag, where);
      } else if (this.statistikWertTyp == StatistikVerkaufWertTyp.NettoMitForecast) {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Forecast, where);
      } else {
        this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, where);
      }
    }
  };

  wertNettoMitForecastButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Umsatz mit Forecast",
    hint: "Umsatz aus Statistik kombiniert mit offenen Aufträgen",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.NettoMitForecast;
      this.loadData(true);
    }
  }
  wertAuftragseingangButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Auftragseingang",
    hint: "bereinigter Nettoumsatz aus Auftragseingang",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.Auftragseingang;
      this.loadData(true);
    }
  }
  wertNettoButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Umsatz",
    hint: "bereinigter Nettoumsatz aus Statistik",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.Netto;
      this.loadData(true);
    }
  }
  wertEinsatzButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Einsatz",
    hint: "Einstandswert aus Statistik",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.Einsatz;
      this.loadData(true);
    }
  }
  wertDBButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "DB",
    hint: "Deckungsbeitrag aus Statistik",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.DB;
      this.loadData(true);
    }
  }

  diagrammButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Diagramm",
    onClick: () => {
      this.displayType = DisplayType.chart;
      this._taskQueue.queueMicroTask(() => {
        if (this.instance && this.instance.instance) {
          this.instance.instance.render();
        }
      });
    }
  }
  gridButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Tabelle",
    onClick: () => {
      this.displayType = DisplayType.grid;
    }
  }
  exportGridButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Export",
    onClick: () => {
      this._excelExportService.exportDataGrid({
        component: this.gridInstance.instance,
        selectedRowsOnly: false
      });
    }
  }

  @computedFrom("statistikWertTyp")
  get statistikWertTypBez() {
    switch (this.statistikWertTyp) {
      case StatistikVerkaufWertTyp.Netto: {
        return "Umsatz";
      }
      case StatistikVerkaufWertTyp.Einsatz: {
        return "Einsatz";
      }
      case StatistikVerkaufWertTyp.DB: {
        return "DB";
      }
      case StatistikVerkaufWertTyp.Auftragseingang: {
        return "Auftragseingang";
      }
      case StatistikVerkaufWertTyp.NettoMitForecast: {
        return "Umsatz mit Forecast";
      }
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;

    this.statistikWertTyp = this.settings.standard;
  }

  bind() {
    this.loadData(false);
  }
  attached() {
    this._windowResizeSubscription = this._eventAggregator.subscribe("window:resize", () => {
      if (this.instance && this.instance.instance) {
        this.instance.instance.render();
      }
    });
  }
  detached() {
    this._windowResizeSubscription.dispose();
    this._windowResizeSubscription = null;
  }

  private async loadData(showLoading: boolean) {
    const result: OnUmsatzMonat = await this._webEventService.execute(new OnUmsatzMonatEvent({
      IdGeschaeftspartner: this.getIdGeschaeftspartner(),
      IdArtikel: this.getIdArtikel(),
      WertTyp: this.statistikWertTyp,
      IdFirma: this.settings.idFirma
    }), showLoading);
    if (!result) {
      return;
    }

    const data: any = result.Data;

    const chartOptions = {
      series: [{
        valueField: "Vergleich2", name: data.JahrVergleich2
      }, {
        valueField: "Vergleich", name: data.JahrVergleich
      }, {
        valueField: "Wert", name: data.Jahr
      }],
      dataSource: data.Data
    };

    if (this.instance && this.instance.instance) {
      this.instance.instance.option(chartOptions);
    } else {
      Object.assign(this.options, chartOptions);
    }

    const gridData = [
      { Jahr: data.JahrVergleich2 },
      { Jahr: data.JahrVergleich },
      { Jahr: data.Jahr }
    ];

    data.Data.forEach(c => {
      gridData[0][c.Name] = c.Vergleich2;
      gridData[1][c.Name] = c.Vergleich;
      gridData[2][c.Name] = c.Wert;

      gridData[0]["Where".concat(c.Name)] = c.WhereVergleich2;
      gridData[1]["Where".concat(c.Name)] = c.WhereVergleich;
      gridData[2]["Where".concat(c.Name)] = c.WhereWert;
    });

    const sum = data.Data.reduce((p, c) => {
      return {
        Wert: p.Wert + c.Wert,
        Vergleich: p.Vergleich + c.Vergleich,
        Vergleich2: p.Vergleich2 + c.Vergleich2
      };
    }, { Wert: 0, Vergleich: 0, Vergleich2: 0 });

    gridData[0]["Gesamt"] = sum.Vergleich2;
    gridData[1]["Gesamt"] = sum.Vergleich;
    gridData[2]["Gesamt"] = sum.Wert;

    //Differenzen
    const diff1 = this.calcDiff(gridData[0], gridData[1]);
    const diff2 = this.calcDiff(gridData[1], gridData[2]);
    gridData.splice(2, 0, diff1);
    gridData.splice(4, 0, diff2);

    if (this.gridInstance && this.gridInstance.instance) {
      this.gridInstance.instance.option("dataSource", gridData);
    } else {
      this.gridOptions.dataSource = gridData;
    }

    this.getFirmaBezeichnung();
  }

  private calcDiff(obj1, obj2) {
    const result = {
      Jahr: `Diff ${obj1.Jahr}/${obj2.Jahr}`,
      IsDiff: true
    };

    for (let key in obj1) {
      if (key == "Jahr") {
        continue;
      }

      if (!obj1[key]) {
        if (obj2[key]) {
          result[key] = 1;
        } else {
          result[key] = 0;
        }
        continue;
      }

      result[key] = ((obj2[key] / obj1[key]) - 1);
    }

    return result;
  }
  private getCellValue(e: HTMLElement, d) {
    const data = d.data;

    if (data.IsDiff) {
      e.style.backgroundColor = "#eee";
      e.style.fontWeight = "bold";
    }

    if (d.column.dataField == "Jahr") {
      e.style.fontWeight = "bold";
      e.innerText = d.value;
      return;
    }
    if (d.column.dataField == "Gesamt") {
      e.style.fontWeight = "bold";
    }

    if (data.IsDiff) {
      if (d.value > 0) {
        e.innerText = "+" + this._globalizationService.format(d.value, "p0");
        e.style.backgroundColor = "#27AE60";
        e.style.color = "white";
      } else if (d.value < 0) {
        e.innerText = "-" + this._globalizationService.format(Math.abs(d.value), "p0");
        e.style.backgroundColor = "#C0392B";
        e.style.color = "white";
      } else {
        e.innerText = this._globalizationService.format(Math.abs(d.value), "p0");
      }
    } else {
      e.innerText = this._globalizationService.format(d.value, "n0");
    }
  }
  private getIdGeschaeftspartner() {
    return this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
      ? this.widgetModel.mainModel.Id
      : null;
  }
  private getIdArtikel() {
    return this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
      ? this.widgetModel.mainModel.Id
      : null;
  }
  private async getFirmaBezeichnung(){
    if(this.settings.idFirma == null){
      this.firmaBezeichnung = null;
      return;
    }
    
    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}

enum DisplayType {
  chart = 0,
  grid = 1
}
