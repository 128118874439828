import { BrowserService, LocalizationService } from '../../base/export';
import { TaskQueue, autoinject } from 'aurelia-framework';
import * as DxLoader from "../../dx/dx-loader";

@autoinject
export class DialogConfirmService {
  constructor(
    private browserService: BrowserService,
    private taskQueue: TaskQueue,
    private localizationService: LocalizationService
  ) { }

  show(options: IDialogConfirmOptions): Promise<any> {
    let returnTrueFalse = false;
    
    if (!options.buttons || options.buttons.length == 0) {
      options.buttons = [{
        id: "yes",
        text: this.localizationService.translateOnce("base.yes")
      }, {
        id: "no",
        text: this.localizationService.translateOnce("base.no")
      }];

      returnTrueFalse = true;
    }

    return new Promise((resolve, reject) => {
      if (this.browserService.isMobile) {
        const actionSheet = document.createElement("div");
        document.body.appendChild(actionSheet);

        const instance: DevExpress.ui.dxActionSheet = DxLoader.createInstance("dxActionSheet", actionSheet, <DevExpress.ui.dxActionSheetOptions>{
          title: options.message,
          showCancelButton: false,
          onOptionChanged: (e) => {
            if (e.name == "visible" && !e.value) {
              this.taskQueue.queueTask(() => {
                e.component.dispose();
                document.body.removeChild(actionSheet);
              });
            }
          },
          dataSource: options.buttons.map(button => {
            return {
              text: button.text,
              onClick: () => {
                if (button.onClick) {
                  button.onClick();
                }

                if (returnTrueFalse && button.id == "yes") {
                  resolve(true);
                } else if (returnTrueFalse && button.id == "no") {
                  resolve(false);
                } else {
                  resolve(button.id);
                }
              }
            };
          })
        }).show();
      } else {
        DevExpress.ui.dialog.custom({
          messageHtml: options.message,
          title: options.title,
          buttons: options.buttons.map(button => {
            return {
              text: button.text,
              onClick: () => {
                if (button.onClick) {
                  button.onClick();
                }

                if (returnTrueFalse && button.id == "yes") {
                  resolve(true);
                } else if (returnTrueFalse && button.id == "no") {
                  resolve(false);
                } else {
                  resolve(button.id);
                }
              }
            }
          })
        }).show();
      }
    });
  }
}

export interface IDialogConfirmOptions {
  title: string;
  message: string;
  buttons?: IDialogConfirmButton[];
}
export interface IDialogConfirmButton {
  id: string;
  text: string;
  onClick?: { (): void };
}
