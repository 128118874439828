import {
  autoinject,
  Scope
} from "aurelia-framework";
import {
  ICommandData
} from "../interfaces/export";
import {
  BindingService
} from "../../base/services/export";
import { IItemExecuteOptions } from "../elements/toolbar/item-execute-options";

@autoinject
export class CommandService {
  private isCommandExecuting = false;

  constructor(
    private binding: BindingService
  ) { }

  isVisible(command: ICommandData): boolean {
    if (command.isVisible != undefined) {
      return command.isVisible;
    } else if (command.isVisibleExpression) {
      return this.binding.evaluate(command.expressionScope, command.isVisibleExpression);
    }

    return true;
  }
  isEnabled(command: ICommandData): boolean {
    if (command.isEnabled != undefined) {
      return command.isEnabled;
    } else if (command.isEnabledExpression) {
      return this.binding.evaluate(command.expressionScope, command.isEnabledExpression);
    }

    return true;
  }
  isVisibleAndEnabled(command: ICommandData): boolean {
    return this.isVisible(command)
      && this.isEnabled(command);
  }

  execute(scope: Scope, command: ICommandData, options: IItemExecuteOptions): boolean {
    if (this.isCommandExecuting) {
      return;
    }

    if (!this.isVisibleAndEnabled(command)) {
      return false;
    }
    if (!command.execute) {
      return false;
    }

    this.isCommandExecuting = true;
    const result = command.execute.bind(scope.bindingContext)(options);

    if (result && result.then && result.catch) {
      result
        .catch(() => {
        })
        .then(() => {
          this.isCommandExecuting = false;
        });
    } else {
      this.isCommandExecuting = false;
    }
    return true;
  }
}
