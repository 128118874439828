import * as fwx from "../../../../framework/forms/form-export";
import {
    EingangsrechnungspositionEditUtils
} from "./eingangsrechnungsposition-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class EingangsrechnungspositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_EingangsrechnungspositionEditUtils: EingangsrechnungspositionEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "eingangsrechnungsposition-edit";
        this.title = "eingangsrechnungsposition-edit.eingangsrechnungsposition-edit_caption";
        this.addModel({
            "id": "$m_Eingangsrechnungsposition",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Eingangsrechnung.CanSave && !models.data.$m_Eingangsrechnung.IsIntercompany",
            "filters": []
        });
        this.addModel({
            "id": "$m_Eingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Eingangsrechnungsposition.IdEingangsrechnung",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EingangsrechnungspositionStuecklisteRel",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungsposition",
            "webApiExpand": {
                'Artikel': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'EingangsrechnungspositionStuecklisteInfo': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_EingangsrechnungspositionStuecklisteEdit",
            "webApiAction": "ERP/Einkauf/Eingangsrechnungsposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addVariable({
            "id": "$v_idGeschaeftspartner"
        });
        this.addEditPopup({
            "idContent": "eingangsrechungspositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_EingangsrechnungspositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_EingangsrechnungspositionStuecklisteEdit.Id"
                }
            }],
            "id": "eingangsrechungspositionEditPopup",
            "options": {
                "optionsName": "eingangsrechungspositionEditPopupOptions",
                "optionsNameFQ": "eingangsrechungspositionEditPopupOptions"
            },
            "width": "85%",
            "commands": []
        });
        this.addFunction("$f_EingangsrechnungspositionEditUtils", $f_EingangsrechnungspositionEditUtils, "functions.$f_EingangsrechnungspositionEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungsposition-edit.r_mengesollverpeh_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "MengeSollVerpEH",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.MengeSollVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeSollVerpEH",
            "options": {
                "optionsName": "r_mengeSollVerpEHOptions",
                "optionsNameFQ": "r_mengeSollVerpEHOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungsposition-edit.r_mengeistverpeh_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "MengeIstVerpEH",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.MengeIstVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeIstVerpEH",
            "options": {
                "optionsName": "r_mengeIstVerpEHOptions",
                "optionsNameFQ": "r_mengeIstVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": ["IdArtikel", {
                "isBound": true,
                "expression": "models.data.$m_Eingangsrechnungsposition.IdArtikel"
            }],
            "filters": [],
            "caption": "eingangsrechnungsposition-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungsposition-edit.r_wertnettosoll_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "WertNettoSoll",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.WertNettoSoll"
            },
            "validationRules": [],
            "id": "r_wertNettoSoll",
            "options": {
                "optionsName": "r_wertNettoSollOptions",
                "optionsNameFQ": "r_wertNettoSollOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungsposition-edit.r_wertnettoist_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "WertNettoIst",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.WertNettoIst"
            },
            "validationRules": [],
            "id": "r_wertNettoIst",
            "options": {
                "optionsName": "r_wertNettoIstOptions",
                "optionsNameFQ": "r_wertNettoIstOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "eingangsrechnungsposition-edit.r_steuersatz_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "Steuersatz",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.Steuersatz"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_steuersatz",
            "options": {
                "optionsName": "r_steuersatzOptions",
                "optionsNameFQ": "r_steuersatzOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "eingangsrechnungsposition-edit.r_isdiffakzeptiert_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "IsDiffAkzeptiert",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.IsDiffAkzeptiert"
            },
            "validationRules": [],
            "id": "r_isDiffAkzeptiert",
            "options": {
                "optionsName": "r_isDiffAkzeptiertOptions",
                "optionsNameFQ": "r_isDiffAkzeptiertOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einpArtikelNummer",
                "caption": "eingangsrechnungsposition-edit.r_einpartikelnummer_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_einpBezeichnung",
                "caption": "eingangsrechnungsposition-edit.r_einpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einpMengeSollVerpEH",
                "caption": "eingangsrechnungsposition-edit.r_einpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpMengeIstVerpEH",
                "caption": "eingangsrechnungsposition-edit.r_einpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpEinheit",
                "caption": "eingangsrechnungsposition-edit.r_einpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }],
            "optionsToolbar": {
                "optionsName": "r_eingangsrechungspositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_eingangsrechungspositionStuecklistenGridToolbarOptions"
            },
            "caption": "eingangsrechnungsposition-edit.r_eingangsrechungspositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EingangsrechnungspositionStuecklisteRel",
                "bindTo": "IdSetartikelEingangsrechnungsposition",
                "bindToFQ": "models.data.$m_EingangsrechnungspositionStuecklisteRel.IdSetartikelEingangsrechnungsposition"
            },
            "dataModel": "$m_Eingangsrechnungsposition",
            "editDataContext": "$m_EingangsrechnungspositionStuecklisteEdit",
            "idEditPopup": "eingangsrechungspositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eingangsrechungspositionStuecklistenGrid",
            "options": {
                "optionsName": "r_eingangsrechungspositionStuecklistenGridOptions",
                "optionsNameFQ": "r_eingangsrechungspositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuKonto",
            "filters": [{
                "webApiWhere": ["IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdFirma"
                }]
            }, {
                "webApiWhere": ["IdFirma", {
                    "isBound": true,
                    "expression": "models.data.$m_Eingangsrechnung.IdFirma"
                }]
            }],
            "caption": "eingangsrechnungsposition-edit.r_fibukonto_caption",
            "binding": {
                "dataContext": "$m_Eingangsrechnungsposition",
                "bindTo": "IdFibuKonto",
                "bindToFQ": "models.data.$m_Eingangsrechnungsposition.IdFibuKonto"
            },
            "validationRules": [],
            "id": "r_fibuKonto",
            "options": {
                "optionsName": "r_fibuKontoOptions",
                "optionsNameFQ": "r_fibuKontoOptions"
            }
        });
        super.onConstructionFinished();
    }
}