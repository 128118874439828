import { autoinject } from "aurelia-framework";
import { GlobalizationService } from "../../framework/base/export";
import { RestService } from "../../framework/base/services/rest-service";

@autoinject
export class FakturaService {
  constructor(
    private rest: RestService,
    private globalizationService: GlobalizationService
  ) { }

  getOffenePostenOptions(idGeschaeftspartner: number): any {
    return {
      columns: ["Id", "Nummer", "Datum", "WertBrutto", "BetragOffen", "ZahlungFaelligkeit", "Mahnstufe"],
      where: [["IdRechnungsort", idGeschaeftspartner], ["Status.Typ", 1], [["BezahltTyp", 0], "or", ["BezahltTyp", 2]]],
      orderBy: [{ columnName: "Datum", sortOrder: 0 }, { columnName: "Nummer", sortOrder: 1 }]
    };
  }
  async getOffenePosten(idGeschaeftspartner: number): Promise<any[]> {
    const r = await this.rest.get({
      url: this.rest.getWebApiUrl("ERP/Verkauf/Faktura"),
      getOptions: this.getOffenePostenOptions(idGeschaeftspartner)
    });

    const now = new Date().getTime();
    for (const item of r) {
      const tooltipArr = [];

      if (item.ZahlungFaelligkeit && item.ZahlungFaelligkeit.getTime() < now) {
        item.IsFaellig = true;
        tooltipArr.push(`Fälligkeit: ${this.globalizationService.format(item.ZahlungFaelligkeit, "d")}`);
      }

      if (item.Mahnstufe) {
        tooltipArr.push(`Mahnstufe: ${this.globalizationService.format(item.Mahnstufe, "n0")}`);
      }

      if (tooltipArr.length > 0) {
        item.Tooltip = tooltipArr.join("\n");
      }
    }

    return r;
  }

  async hasFakturagruppePositionenZugeteilt(idFakturagruppe){
    const r = await this.rest.get({
      url: this.rest.getWebApiUrl(`ERP/Verkauf/Fakturaposition`),
      getOptions: {
        where: ["IdFakturagruppe", idFakturagruppe]
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}
