import { autoinject } from "aurelia-framework";
import { RestService, WebEventService } from '../../framework/base/export';
import { OnTagCreateEvent, OnTagUpdateEvent } from '../../framework-data/events';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EntitaetInfoService } from './entitaet-info-service';
import { FormBase } from '../../framework/forms/classes/form-base';
import * as DxLoader from "../../framework/dx/dx-loader";
import { SelectItemService } from '../../framework/forms/export';

@autoinject
export class TagService {
  private _tagCache = {};

  constructor(
    private _restService: RestService,
    private _webEventService: WebEventService,
    private _eventAggregator: EventAggregator,
    private _entitaetInfoService: EntitaetInfoService,
    private _selectItemService: SelectItemService
  ) {
    _eventAggregator.subscribe("form:bind", e => {
      e.form.callOnBind(() => {
        this.checkTagInjection(e.form);
      });
    });

    _eventAggregator.subscribe("tag:changed", () => {
      this.fillTagCache();
    });

    _eventAggregator.subscribe("startupInfo:loaded", () => {
      this.fillTagCache();
    });

    this.addSelectItem();
  }

  async createTag(bezeichnung: string, entitaetFullName: string) {
    const r = await this._webEventService.execute(new OnTagCreateEvent({
      Bezeichnung: bezeichnung,
      EntitaetFullName: entitaetFullName
    }), true);

    if (!r) {
      return null;
    }

    return r.Tag;
  }
  async updateTag(id: number, bezeichnung: string, farbe: string) {
    await this._webEventService.execute(new OnTagUpdateEvent({
      IdTag: id,
      Bezeichnung: bezeichnung,
      Farbe: farbe
    }), true);
  }

  getEntitaetList(idTag: number) {
    const tag = this._tagCache[idTag];
    if (!tag) {
      return [];
    }

    return tag.TagZuEntitaet.map(e => e.Entitaet);
  }
  getTagList(entitaetFullName: string) {
    const result = [];

    for (let key in this._tagCache) {
      const exists = this._tagCache[key].TagZuEntitaet.some(e => e.Entitaet == entitaetFullName);
      if (!exists) {
        continue;
      }

      result.push(this._tagCache[key]);
    }

    result.sort((a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung));

    return result;
  }

  getAllTagList(){
    const result = [];
    for (let key in this._tagCache) {
      result.push(this._tagCache[key]);
    }
    
    result.sort((a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung));
    return result;
  }

  async getTagContainer(idTagContainer: number, increaseLoadingCount: boolean = false) {
    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Tag/TagContainer/".concat(idTagContainer.toString())),
      getOptions: {
        expand: {
          "TagEintraege": null
        }
      },
      increaseLoadingCount: increaseLoadingCount
    })
  }

  async fillTagCache() {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Tag/Tag"),
      getOptions: {
        expand: {
          TagZuEntitaet: null
        }
      }
    });

    this._tagCache = {};
    for (let tag of r) {
      this._tagCache[tag.Id] = tag;
    }

    this._eventAggregator.publish("tag:refreshed", {});
  }

  renderTagList(tags: string | number, addWrap: boolean):  HTMLDivElement {
    const container = document.createElement("div");
    container.className = "tags-grid__container";

    if (addWrap) {
      container.className += " tags-grid__container--wrap"
    }

    if (!tags) {
      tags = "";
    } else if (typeof tags !== "string") {
      tags = tags.toString();
    }

    const tokens = (tags || "").split("|");
    for (let token of tokens) {
      const tag = this._tagCache[token.toString()];
      if (!tag) {
        continue;
      }

      const tagElement = document.createElement("div");
      tagElement.className = "tags-grid__item";
      tagElement.innerText = tag.Bezeichnung;
      tagElement.style.backgroundColor = tag.Farbe;
      tagElement.style.color = "white";
      container.appendChild(tagElement);
    }

    return container;
  }

  private addSelectItem() {
    this._selectItemService.addSelectItem("tags", {
      id: "tags",
      elementname: "select-box",
      valueMember: "Id",
      displayMember: "Bezeichnung",
      action: "ERP/Tag/Tag",
      columns: ["Id", "Bezeichnung"]
    });
  }
  private checkTagInjection(form: FormBase) {
    const modelWithTag = form.models
      .getModels()
      .filter(r => r.custom && r.custom.addTagToDataGrids && r.custom.addTagToDataGrids.length > 0);

    for (let model of modelWithTag) {
      if (!model.webApiAction) {
        continue;
      }

      model.filters.push({
        webApiCustomKey: "AddTags",
        webApiCustomValue: "true"
      });

      const entitaetInfo = this._entitaetInfoService.getEntitaetInfoByWebApiAction(model.webApiAction);

      for (let idDataGrid of model.custom.addTagToDataGrids) {
        const options: DevExpress.ui.dxDataGridOptions = form[idDataGrid.concat("Options")];
        
        const columnOptions: DevExpress.ui.dxDataGridColumn = {
          allowSorting: false,
          allowHeaderFiltering: true,
          caption: "Tags",
          minWidth: 100,
          name: "tags",
          cellTemplate: (e: HTMLElement, i) => {
            const container = this.renderTagList(i.data._Tags, false);

            container.style.position = "absolute";
            container.style.visibility = "none";
            document.body.appendChild(container);

            const restElement = document.createElement("div");
            restElement.className = "tags-grid__item--last";

            let count = 0;
            while (container.offsetWidth > e.offsetWidth) {
              const items = container.querySelectorAll(".tags-grid__item");
              if (items.length == 0) {
                break;
              }

              const lastChild = items[items.length - 1];
              container.removeChild(lastChild);
              count++;

              restElement.innerText = "+" + count.toString();
              if (!restElement.parentElement) {
                container.appendChild(restElement);
              }
            }

            document.body.removeChild(container);
            container.style.position = "";
            container.style.visibility = "";
            e.appendChild(container);
            e.style.padding = "0";
          },
          calculateFilterExpression: (value, op, target) => {
            if (target == "headerFilter" || target == "filterBuilder") {
              return ["TagContainer.TagEintraege", ["IdTag", value]];
            } else if (target == "filterRow") {
              return ["TagContainer.TagEintraege", ["Tag.Bezeichnung", "contains", value]];
            }
          },
          headerFilter: {
            dataSource: this.getTagList(entitaetInfo.FullName).map(t => {
              return {
                value: t.Id,
                text: t.Bezeichnung
              };
            })
          }
        };

        options.columns.push(columnOptions);

        let tooltipElement: HTMLDivElement;
        let tooltipWidget: DevExpress.ui.dxTooltip;

        const onCellHoverChanged = options.onCellHoverChanged;
        options.onCellHoverChanged = (e) => {
          if (onCellHoverChanged) {
            onCellHoverChanged(e);
          }

          if (!e.column || !e.column.name || !e.data || !e.data._Tags) {
            return;
          }
          if (e.cellElement.querySelectorAll(".tags-grid__item--last").length == 0) {
            return;
          }

          if (e.column.name == "tags") {
            if (e.eventType == "mouseover" && e.data._Tags) {
              tooltipElement = document.createElement("div");
              document.body.appendChild(tooltipElement);
              tooltipWidget = DxLoader.createInstance("dxTooltip", tooltipElement, <DevExpress.ui.dxTooltipOptions>{
                contentTemplate: (ct) => {
                  const container = this.renderTagList(e.data._Tags, true);
                  container.style.padding = "6px";
                  ct.append(container);
                }
              });

              tooltipWidget.show(e.cellElement);
            } else if (tooltipWidget) {
              tooltipWidget.hide();
              tooltipWidget.dispose();
              tooltipWidget = null;

              tooltipElement.parentElement.removeChild(tooltipElement);
            }
          }
        }
      }
    }
  }
}
