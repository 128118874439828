import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  RestService
} from "../../../../framework/base/export";
import {
  FormBase, ICommandData
} from "../../../../framework/forms/export";

@autoinject
@transient()
export class StatusListUtils {
  constructor(
    private _restService: RestService
  ) { }

  form: FormBase;
  
  status: any[];
  
  doAktualisierenCommand: ICommandData = {
    id: "doAktualisieren",
    idCategory: "instanz-status",
    title: "instanz-status.aktualisieren",
    sortIndex: 10,
    icon: "refresh",
    execute: () => {
      this.loadData();
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.loadData();
  }
  
  private async loadData() {
    this.status = await this._restService.get({
      url: this._restService.getApiUrl("ERP/Status")
    });
  }
}
