import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class BankverbindungEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "bankverbindung-edit";
        this.title = "bankverbindung-edit.bankverbindung-edit_caption";
        this.addModel({
            "id": "$m_Bankverbindung",
            "webApiAction": "ERP/Stammdaten/Bankverbindung",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "bankverbindung-edit.r_iban_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "IBAN",
                "bindToFQ": "models.data.$m_Bankverbindung.IBAN"
            },
            "validationRules": [{
                "item": {
                    "type": "iban",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 35
                    }]
                }
            }],
            "id": "r_iban",
            "options": {
                "optionsName": "r_ibanOptions",
                "optionsNameFQ": "r_ibanOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "bankverbindung-edit.r_bic_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "BIC",
                "bindToFQ": "models.data.$m_Bankverbindung.BIC"
            },
            "validationRules": [{
                "item": {
                    "type": "bic",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 11
                    }]
                }
            }],
            "id": "r_bic",
            "options": {
                "optionsName": "r_bicOptions",
                "optionsNameFQ": "r_bicOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bankverbindung-edit.r_gueltigvon_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "GueltigVon",
                "bindToFQ": "models.data.$m_Bankverbindung.GueltigVon"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigVon",
            "options": {
                "optionsName": "r_gueltigVonOptions",
                "optionsNameFQ": "r_gueltigVonOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "bankverbindung-edit.r_gueltigbis_caption",
            "binding": {
                "dataContext": "$m_Bankverbindung",
                "bindTo": "GueltigBis",
                "bindToFQ": "models.data.$m_Bankverbindung.GueltigBis"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_gueltigBis",
            "options": {
                "optionsName": "r_gueltigBisOptions",
                "optionsNameFQ": "r_gueltigBisOptions"
            }
        });
        super.onConstructionFinished();
    }
}