import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  BriefVersendenTyp,
  OnBriefVersendenEvent
} from "../../../../framework-data/events";
import {
  RestService,
  WebEventService
} from "../../../../framework/base/export";
import {
  FormBase,
  ICommandData,
  ModelUtilsService,
  PopupInfoService
} from "../../../../framework/forms/export";
import { IFormCommandExecuteOptions } from "../../../interfaces/export";
import {
  DruckenService, StartupService
} from "../../../services/export";

@autoinject
@transient()
export class BriefEditUtils {
  constructor(
    private _webEventService: WebEventService,
    private _popupInfoService: PopupInfoService,
    private _druckenService: DruckenService,
    private _startupService: StartupService,
    private _restService: RestService,
    private _modelUtilsService: ModelUtilsService) { }

  form: FormBase;
  showEditor: boolean = true;
  briefEmpfaengerDataSource: DevExpress.data.DataSource;
  briefEmpfaengerCCDataSource: DevExpress.data.DataSource;
  briefEmpfaengerBCCDataSource: DevExpress.data.DataSource;

  briefEmpfaengerTagBoxOptions: DevExpress.ui.dxTagBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "models.data.$m_Brief.__BriefZuEmpfaenger_Empfaenger",
    },
    itemTemplate: (data) => {
      return this.getTagBoxItemTemplate(data);
    },
    tagTemplate: (data) => {
      return this.getTagBoxTagTemplate(data);
    },
    onSelectionChanged: (e) => {
      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief == null) {
        return;
      }

      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuEmpfaenger == null) {
        e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuEmpfaenger = [];
      }

      this.addItemOfEmpfaengerList(e.addedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuEmpfaenger);
      this.removeItemOfEmpfaengerList(e.removedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuEmpfaenger);
    }
  };
  briefEmpfaengerCCTagBoxOptions: DevExpress.ui.dxTagBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "models.data.$m_Brief.__BriefZuCC_Empfaenger",
    },
    itemTemplate: (data) => {
      return this.getTagBoxItemTemplate(data);
    },
    tagTemplate: (data) => {
      return this.getTagBoxTagTemplate(data);
    },
    onSelectionChanged: (e) => {
      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief == null) {
        return;
      }

      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuCC == null) {
        e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuCC = [];
      }

      this.addItemOfEmpfaengerList(e.addedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuCC);
      this.removeItemOfEmpfaengerList(e.removedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuCC);
    }
  };
  briefEmpfaengerBCCTagBoxOptions: DevExpress.ui.dxTagBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    searchEnabled: true,
    bindingOptions: {
      value: "models.data.$m_Brief.__BriefZuBCC_Empfaenger",
    },
    itemTemplate: (data) => {
      return this.getTagBoxItemTemplate(data);
    },
    tagTemplate: (data) => {
      return this.getTagBoxTagTemplate(data);
    },
    onSelectionChanged: (e) => {
      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief == null) {
        return;
      }

      if (e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuBCC == null) {
        e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuBCC = [];
      }

      this.addItemOfEmpfaengerList(e.addedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuBCC);
      this.removeItemOfEmpfaengerList(e.removedItems, e.model.bindingContext.$f_briefEditUtils.form.models.data.$m_Brief.BriefZuBCC);
    }
  };

  showDivAdresse: ICommandData = {
    id: "showDivAdresse",
    idCategory: "brief-entitaet",
    title: "brief.show_div_adresse",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Brief.CanSave && !models.data.$m_Brief.HasDiverseAdresse",
    execute: (options) => {
      const model = this.form.models.data.$m_Brief;

      if (!model.IdAdresse && !model.Adresse) {
        model.Adresse = {};
      }

      model.HasDiverseAdresse = true;
    }
  };
  hideDivAdresse: ICommandData = {
    id: "hideDivAdresse",
    idCategory: "brief-entitaet",
    title: "brief.hide_div_adresse",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Brief.CanSave && models.data.$m_Brief.HasDiverseAdresse",
    execute: (options) => {
      const model = this.form.models.data.$m_Brief;
      model.HasDiverseAdresse = false;
    }
  };
  doVersendenEmail: ICommandData = {
    id: "doVersendenEmail",
    idCategory: "brief-entitaet",
    title: "brief.send_email",
    icon: "envelope-o",
    sortIndex: 20,
    isEnabledExpression: "models.data.$m_Brief.CanSave",
    execute: (options) => {
      this.execute(BriefVersendenTyp.Email, options);
    }
  };
  doDrucken: ICommandData = {
    id: "doDrucken",
    idCategory: "brief-entitaet",
    title: "brief.print",
    icon: "print",
    sortIndex: 21,
    isEnabled: true,
    isVisible: true,
    execute: (options) => {
      this.execute(BriefVersendenTyp.Drucken, options);
    }
  };
  doDruckvorschau: ICommandData = {
    id: "doDruckvorschau",
    idCategory: "brief-entitaet",
    title: "brief.print-preview",
    icon: "file-text-o",
    sortIndex: 22,
    isEnabled: true,
    execute: (options) => {
      this.preview(options);
    }
  };
  showHtmlBriefOptions: DevExpress.ui.dxButtonOptions = {
    text: "Brief in neuem Fenster öffnen",
    onClick: () => {
      const w = window.open();
      w.document.write(this.form.models.data.$m_Brief.TextHtml);
      w.document.close();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    form.models.onLoaded.register(e => {
      if (e.model.id == "$m_Brief" && e.data) {
        this.showEditor = !e.data.TextHtml
          || e.data.TextHtml.indexOf("</body>") < 0;

        if (e.data.BriefZuEmpfaenger != null) {
          e.data.__BriefZuEmpfaenger_Empfaenger = e.data.BriefZuEmpfaenger.map(c => c.IdEmpfaenger);
        }
        if (e.data.BriefZuCC != null) {
          e.data.__BriefZuCC_Empfaenger = e.data.BriefZuCC.map(c => c.IdEmpfaenger);
        }
        if (e.data.BriefZuBCC != null) {
          e.data.__BriefZuBCC_Empfaenger = e.data.BriefZuBCC.map(c => c.IdEmpfaenger);
        }
      }

      return Promise.resolve();
    });

    this.doDrucken.isVisible = this._startupService.startupInfo.Mandant.IsDruckJobAktiv;

    this.briefEmpfaengerDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
      cacheRawData: true,
      load: async (loadOptions) => {
        let filter = this.getBriefEmpfaengerFilterList(loadOptions.filter);

        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/BriefEmpfaenger"),
          data: {
            Suchtext: loadOptions.searchValue,
            IdList: filter,
            skip: loadOptions.skip,
            take: loadOptions.take
          }
        });

        return r.ItemList;
      }
    }));
    this.briefEmpfaengerCCDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
      cacheRawData: true,
      load: async (loadOptions) => {
        let filter = this.getBriefEmpfaengerFilterList(loadOptions.filter);

        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/BriefEmpfaenger"),
          data: {
            Suchtext: loadOptions.searchValue,
            IdList: filter,
            skip: loadOptions.skip,
            take: loadOptions.take
          }
        });

        return r.ItemList;
      }
    }));
    this.briefEmpfaengerBCCDataSource = new DevExpress.data.DataSource(new DevExpress.data.CustomStore({
      cacheRawData: true,
      load: async (loadOptions) => {
        let filter = this.getBriefEmpfaengerFilterList(loadOptions.filter);

        let r = await this._restService.post({
          url: this._restService.getApiUrl("ERP/BriefEmpfaenger"),
          data: {
            Suchtext: loadOptions.searchValue,
            IdList: filter,
            skip: loadOptions.skip,
            take: loadOptions.take
          }
        });

        return r.ItemList;
      }
    }));

    this.briefEmpfaengerTagBoxOptions.dataSource = this.briefEmpfaengerDataSource;
    this.briefEmpfaengerCCTagBoxOptions.dataSource = this.briefEmpfaengerCCDataSource;
    this.briefEmpfaengerBCCTagBoxOptions.dataSource = this.briefEmpfaengerBCCDataSource;
  }

  private getBriefEmpfaengerFilterList(loadOptionsFilter) {
    let filter = [];

    if (loadOptionsFilter != null) {
      loadOptionsFilter.forEach(element => {
        if (Array.isArray(element)) {
          element.forEach(el => {
            if (typeof el === "number") {
              filter.push(el);
            }
          });
        } else {
          if (typeof element === "number") {
            filter.push(element);
          }
        }
      });
    }
    return filter;
  }
  private getTagBoxTagTemplate(data) {
    return `<div class="erp--text-with-leading-label-badge erp--background-ddd erp--margin-top-left-2px">
        <div class="${data.TypeName ? '' : 'erp--empty-badge'}">
          ${data.TypeName}
        </div>
        <div class="erp--margin-right-36px">
         ${data.Bezeichnung}
        </div>
        <div class="dx-tag-remove-button"></div>
      </div>`;
  }
  private getTagBoxItemTemplate(data) {
    return `<div class="erp--text-with-leading-label-badge erp--margin-top-left-2px">
        <div class="${data.TypeName ? '' : 'erp--empty-badge'}">
          ${data.TypeName}
        </div>
        <div class="erp--margin-right-36px">
          ${data.Bezeichnung}
        </div>
      </div>`;
  }
  private addItemOfEmpfaengerList(addedItems, list) {
    addedItems.forEach(c => {
      const hasEntry = list
        .some(el => c.Id == el.IdEmpfaenger);

      if (!hasEntry) {
        list.push({ IdEmpfaenger: c.Id });
        this._modelUtilsService.setDirty(this.form.models.data.$m_Brief);
      }
    });
  }
  private removeItemOfEmpfaengerList(removedItems, list) {
    removedItems.forEach(c => {
      var entry = list
        .filter(el => el.IdEmpfaenger == c.Id)[0] || null;

      if (entry != null) {
        var index = list.indexOf(entry);
        list.splice(index, 1);
        this._modelUtilsService.setDirty(this.form.models.data.$m_Brief);
      }
    });
  }

  private async execute(versandTyp: BriefVersendenTyp, options) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const data: any = this.form.models.data;

    return this._webEventService
      .execute(new OnBriefVersendenEvent({
        IdBrief: this.form.models.data.$m_Brief.Id,
        IdVerknuepfteEntitaet: this.form.models.data.$m_Brief.IdVerknuepfteEntitaet,
        VersandTyp: versandTyp
      }), true)
      .then(() => {
        return this.form.models.reloadAll();
      })
      .then(() => {
        if (this._popupInfoService.isPopupOpen()) {
          this._popupInfoService.closeCurrentPopup();
        }
      });
  }
  private async preview(options) {
    const saveResult = await this.form.saveIfDirty();
    if (!saveResult.isValid) {
      return;
    }

    const data: any = this.form.models.data;

    const execOptions: IFormCommandExecuteOptions = {
      form: this.form,
      modelData: this.form.models.data.$m_Brief,
      options: { event: options.event }
    };

    this._druckenService.executeDruckCommand(execOptions);
  }
}
