import {
  autoinject,
  bindable,
  bindingMode,
  computedFrom,
  OverrideContext,
  observable,
  Scope,
  TaskQueue
} from "aurelia-framework";
import {
  DataSourceService,
  LocalizationService,
  ScopeContainer,
  WebEventService,
  RestService
} from "../../../framework/base/export";
import {
  FormBase,
  ModelUtilsService
} from "../../../framework/forms/export";
import {
  CustomEditPopupService,
  GeschaeftspartnerService,
  GeschaeftspartnerSearchService,
  PersonService
} from "../../services/export";
import {
  Adresse
} from "../adresse/adresse";

import * as Interfaces from "../../interfaces/export";
import { OnIsDiverserGeschaeftspartner, OnIsDiverserGeschaeftspartnerEvent, PersonRequestTyp } from "../../../framework-data/events";
import { DxCustomizeService } from "../../services/dx-customize-service";

@autoinject
export class Geschaeftspartner {
  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _element: Element,
    private _dataSourceService: DataSourceService,
    private _geschaeftspartnerService: GeschaeftspartnerService,
    private _personService: PersonService,
    private _geschaeftspartnerSearchService: GeschaeftspartnerSearchService,
    private _localizationService: LocalizationService,
    private _taskQueueService: TaskQueue,
    private _modelUtilsService: ModelUtilsService,
    private _webEventService: WebEventService,
    private _restService: RestService,
    private _dxCustomizeService: DxCustomizeService
  ) { }

  @bindable mainModel: any;
  @bindable showAnsprechperson: boolean;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) idAdresse: number;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable idAnsprechperson: number;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable idGeschaeftspartner: number;
  @bindable isReadOnly: boolean;
  @bindable isRequired: boolean = true;
  @bindable validationFieldName: string = "Geschäftspartner";

  /** Muss leider so sein - durch mehrfaches Binding und öftere Änderung führt dies sonst zu Problemen */
  @bindable adressePropertyName: string;

  @bindable where: any;
  @bindable personTyp: PersonRequestTyp;

  @computedFrom("isReadOnly", "mainModel.CanSave")
  get isReadOnlyChecked() {
    return this.isReadOnly || !this.mainModel || this.mainModel.CanSave === false;
  }
  @computedFrom("isReadOnlyChecked", "idGeschaeftspartner")
  get isReadOnlyAnsprechperson() {
    return this.isReadOnlyChecked || !this.idGeschaeftspartner;
  }
  @computedFrom("isReadOnlyChecked", "isDiverserGeschaeftspartner")
  get isAdresseReadOnly() {
    return this.isReadOnlyChecked || !this.isDiverserGeschaeftspartner;
  }

  form: FormBase;

  scope: Scope;
  scopeContainer: ScopeContainer;

  adresseElement: Adresse;
  telefon: string;
  telefonAnsprechperson: string;
  isDiverserGeschaeftspartner: boolean;

  geschaeftspartnerValidationOptions: DevExpress.ui.dxValidatorOptions = {
    validationRules: [
      {
        type: "custom",
        reevaluate: true,
        validationCallback: (e) => {
          e.rule.message = `${this.validationFieldName} ist ein Pflichtfeld`;
          return this.isRequired
            ? !!e.value
            : true;
        }
      }
    ]
  };

  geschaeftspartnerNummer: Interfaces.IdxTextBoxComponent;
  geschaeftspartnerNummerOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    onInitialized: (e) => {
      this.updateGeschaeftspartnerButtons(e.component);
    },
    onValueChangedByUser: (e) => {
      if (!e.value) {
        this.resetGeschaeftspartner();
        return;
      }

      const isValidNumber: boolean = /^[0-9]+$/.test(e.value);

      if (isValidNumber) {
        this.onGeschaeftspartnerChangedNummer(e.value);
      } else {
        this.onGeschaeftspartnerChangedText(e.value);
      }
    },
    bindingOptions: {
      readOnly: {
        expression: "isReadOnlyChecked",
        readOnly: true
      }
    }
  };
  
  ansprechperson: Interfaces.IdxSelectBoxComponent;
  ansprechpersonOptions: DevExpress.ui.dxSelectBoxOptions = {
    valueExpr: "Id",
    displayExpr: "Name",
    searchEnabled: true,
    showClearButton: true,
    placeholder: this._localizationService.translateOnce("adresse.ansprechperson"),
    onInitialized: (e) => {
      this.updateAnsprechpersonButtons(e.component);
    },
    onValueChanged: (e) => {
      this.updateAnsprechpersonButtons(e.component);
    },
    onValueChangedByUser: (e) => {
      this.dispatchAnsprechpersonChanged(e.value, e.previousValue);
    },
    bindingOptions: {
      value: "idAnsprechperson",
      readOnly: "isReadOnlyAnsprechperson"
    }
  };

  bind(bindingContext: any, OverrideContext: OverrideContext) {
    this.form = bindingContext.form || bindingContext;

    this.scope = {
      bindingContext: bindingContext,
      overrideContext: OverrideContext
    };
    this.scopeContainer = new ScopeContainer(this.scope);

    this.ansprechpersonOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        webApiAction: "ERP/Stammdaten/Person",
        webApiColumns: ["Id", "IdGeschaeftspartner", "Vorname", "Nachname", "Name"],
        keyProperty: "Id"
      },
      {
        getCustomWhere: () => {
          if (this.idGeschaeftspartner == void 0) {
            return null;
          }
          return ["IdGeschaeftspartner", this.idGeschaeftspartner]
        }
      }
    );
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }

  focus() {
    this.geschaeftspartnerNummer.instance.focus();
  }
  async idGeschaeftspartnerChanged(newVal) {
    await this.setGeschaeftspartnerDataById(newVal);

    if (this.showAnsprechperson) {
      (<DevExpress.data.DataSource>this.ansprechpersonOptions.dataSource).reload();
    }
    if (!this.adressePropertyName) {
      if (newVal) {
        this.updateAdresse(newVal);
      } else {
        this.adresseElement.resetAdresse();
      }
    }

    this.updateAnsprechpersonButtons();
  }
  idAnsprechpersonChanged(newVal) {
    this.setAnsprechpersonDataById(newVal);
  }
  onGeschaeftspartnerChangedNummer(searchValue) {
    if (!searchValue) {
      return;
    }

    this._geschaeftspartnerService.getGeschaeftspartnerByNummer(searchValue, true, this.where)
      .then(r => {
        if (!r || !r.length) {
          this.showGeschaeftspartnerSearchPopup(searchValue);
        } else {
          this.setGeschaeftspartner(r[0]);
        }
      });
  }
  onGeschaeftspartnerChangedText(searchValue) {
    if (!searchValue) {
      return;
    }

    this._geschaeftspartnerService.getGeschaeftspartnerBySearchtextEindeutig(searchValue, true, this.where)
      .then(r => {
        if (r && r.length === 1) {
          this.setGeschaeftspartner(r[0]);
        } else {
          this.showGeschaeftspartnerSearchPopup(searchValue);
        }
      });
  }
  showGeschaeftspartnerSearchPopup(searchValue) {
    this._geschaeftspartnerSearchService.show((idGeschaeftspartner) => {
      this._geschaeftspartnerService.getGeschaeftspartnerById(idGeschaeftspartner, true)
        .then(r => {
          this.setGeschaeftspartner(r);
        })
    },
      () => {
        this.resetGeschaeftspartner();
        this.geschaeftspartnerNummer.instance.focus();
      },
      { searchValue: searchValue, where: this.where }
    );
  }
  refreshGeschaeftspartnerById(idGeschaeftspartner: number = null) {
    if (idGeschaeftspartner && idGeschaeftspartner != this.idGeschaeftspartner) {
      return;
    }
    if (this.isReadOnlyChecked) {
      return;
    }
    if (this.isDiverserGeschaeftspartner) {
      return;
    }

    if (this.adressePropertyName) {
      this.updateAdresse(this.idGeschaeftspartner);
    } else {
      this.setGeschaeftpartnerById(this.idGeschaeftspartner);
    }
  }
  setGeschaeftpartnerById(idGeschaeftspartner: number) {
    this._geschaeftspartnerService
      .getGeschaeftspartnerById(idGeschaeftspartner, true)
      .then(r => {
        this.setGeschaeftspartner(r);
      })
  }

  private resetGeschaeftspartner() {
    this.idGeschaeftspartner = null;
    this.adresseElement.resetAdresse();
    this.idAnsprechperson = null;
    this.telefon = null;
    this.telefonAnsprechperson = null;

    this.setGeschaeftspartnerNummer(null);
    this.updateAnsprechpersonButtons();
  }
  private setGeschaeftspartner(geschaeftspartner: any) {
    this.idGeschaeftspartner = geschaeftspartner.Id;
    this.idAnsprechperson = null;

    this.adresseElement.setAdresseByGeschaeftspartner(geschaeftspartner);

    this._taskQueueService.queueMicroTask(() => {
      const event = new CustomEvent("id-geschaeftspartner-changed", {
        detail: {
          sender: this,
          value: geschaeftspartner.Id
        },
        bubbles: true
      });

      this._element.dispatchEvent(event);
    });

    this.setMainModelDirty();
    this.loadAnsprechperson(geschaeftspartner.Id);
  }
  private async updateAdresse(idGeschaeftspartner: any) {
    const data = await this._geschaeftspartnerService.getGeschaeftspartnerById(idGeschaeftspartner);
    this.adresseElement.setAdresseByGeschaeftspartner(data);
  }
  private async loadAnsprechperson(idGeschaeftspartner: number) {
    if (!this.showAnsprechperson) {
      return;
    }

    if (this.personTyp != void(0)) {
      this.idAnsprechperson = await this._personService.getIdPersonVorbelegung(idGeschaeftspartner, this.personTyp);
      this.dispatchAnsprechpersonChanged(this.idAnsprechperson, null);
    }
  }
  private async setGeschaeftspartnerDataById(idGeschaeftspartner: number) {
    this.isDiverserGeschaeftspartner = false;

    if (idGeschaeftspartner == void 0) {
      this.telefon = null;
      this.setGeschaeftspartnerNummer(null);
      return;
    }

    const data = await this
      ._geschaeftspartnerService
      .getGeschaeftspartnerBasicDataById(idGeschaeftspartner);

    this.telefon = data.Telefon;
    this.setGeschaeftspartnerNummer(data.Nummer);

    const isDiversResult: OnIsDiverserGeschaeftspartner = await this._webEventService.execute(new OnIsDiverserGeschaeftspartnerEvent({
      IdGeschaeftspartner: idGeschaeftspartner
    }));

    this.isDiverserGeschaeftspartner = isDiversResult.IsDivers;
  }
  private async setAnsprechpersonDataById(idAnsprechperson: number) {
    if (idAnsprechperson == void (0)) {
      this.telefonAnsprechperson = null;
      this.updateAnsprechpersonButtons();
      return;
    }

    const data = await this
      ._personService
      .getPersonBasicDataById(idAnsprechperson);

    this.telefonAnsprechperson = data.Telefon;
    this.updateAnsprechpersonButtons();
  }
  private dispatchAnsprechpersonChanged(idAnsprechperson: number, idAnsprechpersonOld?: number) {
    this._modelUtilsService.setDirty(this.mainModel);

    const event = new CustomEvent("id-ansprechperson-changed", {
      detail: {
        sender: this,
        value: idAnsprechperson,
        previousValue: idAnsprechpersonOld
      },
      bubbles: true
    });

    this._element.dispatchEvent(event);
  }
  private setGeschaeftspartnerNummer(nummer: any) {
    if (nummer) {
      nummer = nummer.toString();
    }
    else {
      nummer = "";
    }

    if (this.geschaeftspartnerNummer) {
      this.geschaeftspartnerNummer.setOption({
        value: nummer,
        isValid: true
      });
    } else {
      this.geschaeftspartnerNummerOptions.value = nummer;
    }

    this.updateGeschaeftspartnerButtons();
  }
  private setMainModelDirty() {
    if (!this.mainModel) {
      return;
    }

    this._modelUtilsService.setDirty(this.mainModel);
  }

  private updateGeschaeftspartnerButtons(editor: DevExpress.ui.dxTextBox = null) {
    if (!editor) {
      if (this.geschaeftspartnerNummer && this.geschaeftspartnerNummer.instance) {
        editor = this.geschaeftspartnerNummer.instance;
      }
    }

    if (!editor) {
      return;
    }

    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (this.idGeschaeftspartner) {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-pencil",
          stylingMode: "text",
          tabIndex: -1,
          onClick: (e) => {
            if (e.event && (<any>e.event).shiftKey) {
              this.openGeschaeftspartnerInNewTab();
              return;
            }

            this.openGeschaeftspartnerPopup();
          }
        }
      });
    } else {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            this.openGeschaeftspartnerPopup();
          }
        }
      });
    }

    if (this.telefon) {
      buttons.push({
        location: "after",
        name: "telefon",
        options: {
          disabled: false,
          icon: "fa fa-phone",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            location.href = `tel:${this.telefon.replace(/[^\+0-9.]/g, "")}`;
          }
        }
      });
    }

    if (editor.option("value")) {
      buttons.push({
        location: "after",
        name: "clear"
      });
    }

    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
  private openGeschaeftspartnerInNewTab() {
    const url = this._restService.getViewUrl(
      "#ERP/Stammdaten/Geschaeftspartner/"
      .concat(this.idGeschaeftspartner.toString()));

    window.open(url, "_blank");
  }
  private openGeschaeftspartnerPopup() {
    if (this._customEditPopupService.geschaeftspartnerEditPopup.isVisible) {
      this.openGeschaeftspartnerInNewTab();
      return;
    }

    this._customEditPopupService.geschaeftspartnerEditPopup.show({
      mappings: { "$id": this.idGeschaeftspartner || 0 },
      savedCallback: async (form) => {
        if (this.idGeschaeftspartner) {
          await this.idGeschaeftspartnerChanged(this.idGeschaeftspartner);
          
          if (!this.isReadOnlyChecked && !this.isDiverserGeschaeftspartner && this.adressePropertyName) {
            this.updateAdresse(this.idGeschaeftspartner);
            this.setMainModelDirty();

            const event = new CustomEvent("geschaeftspartner-data-changed", {
              detail: {
                sender: this,
                value: this.idGeschaeftspartner
              },
              bubbles: true
            });
      
            this._element.dispatchEvent(event);
          }

          return;
        }

        const model = form.models.modelWithKeyId;
        const data = form.models.data[model.id];

        if (!data || !data.Id) {
          return;
        }

        this.setGeschaeftpartnerById(data.Id);
      }
    });
  }

  private updateAnsprechpersonButtons(editor: DevExpress.ui.dxSelectBox = null) {
    if (!editor) {
      if (this.ansprechperson && this.ansprechperson.instance) {
        editor = this.ansprechperson.instance;
      }
    }

    if (!editor) {
      return;
    }

    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (this.idAnsprechperson) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-pencil",
          stylingMode: "text",
          tabIndex: -1,
          onClick: (e) => {
            if (e.event && (<any>e.event).shiftKey) {
              this.openAnsprechpersonInNewTab();
              return;
            }

            this.openAnsprechpersonPopup();
          }
        }
      });
    } else if (this.idGeschaeftspartner && !this.isReadOnlyAnsprechperson) {
      buttons.push({
        location: "after",
        name: "ap",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            this.openAnsprechpersonPopup();
          }
        }
      });
    }

    if (this.telefonAnsprechperson) {
      buttons.push({
        location: "after",
        name: "telefon",
        options: {
          disabled: false,
          icon: "fa fa-phone",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            location.href = `tel:${this.telefonAnsprechperson.replace(/[^\+0-9.]/g, "")}`;
          }
        }
      });
    }
    
    if (editor.option("value")) {
      buttons.push({
        location: "after",
        name: "clear"
      });
    }

    buttons.push({
      location: "after",
      name: "dropDown"
    });
    
    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
  private openAnsprechpersonInNewTab() {
    const url = this._restService.getViewUrl(
      "#ERP/Stammdaten/Person/"
      .concat(this.idAnsprechperson.toString()));

    window.open(url, "_blank");
  }
  private openAnsprechpersonPopup() {
    if (this._customEditPopupService.personEditPopup.isVisible) {
      this.openAnsprechpersonInNewTab();
      return;
    }

    this._customEditPopupService.personEditPopup.show({
      mappings: { "$id": this.idAnsprechperson || 0 },
      setValuesOnModelWithKeyIdLoaded: {
        IdGeschaeftspartner: this.idGeschaeftspartner
      },
      closeCallback: (form) => {
        (<DevExpress.data.DataSource>this.ansprechpersonOptions.dataSource).reload();
        
        if (this.idAnsprechperson) {
          this.idAnsprechpersonChanged(this.idAnsprechperson);
          return;
        }

        const model = form.models.modelWithKeyId;
        const data = form.models.data[model.id];

        if (!data || !data.Id) {
          return;
        }

        this.idAnsprechperson = data.Id;
      }
    });
  }
}
