import { RestService } from "./../../framework/base/services/rest-service";
import { autoinject } from "aurelia-framework";
import { OnBelegExport, OnBelegExportEvent } from "../../framework-data/events";
import { FileService } from "../../framework/base/services/file-service";
import { WebEventService } from "../../framework/base/services/web-event-service";
import { BelegImportTyp } from "./../enumerations/beleg-import-typ";

@autoinject
export class BelegExportService {
  constructor(
    private _fileService: FileService,
    private _restService: RestService,
    private _webEventService: WebEventService
  ) { }

  async export(typ: BelegImportTyp, keys: number[]): Promise<any> {
    const result: OnBelegExport = await this._webEventService.execute(new OnBelegExportEvent({
      BelegImportTyp: typ,
      IdList: keys
    }), true);

    if (!result.DMSLink) {
      return;
    }

    this._fileService.download(result.DMSLink);
  }

  async isBelegExportMandantAktiv(typ: BelegImportTyp) {
    const result = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/MandantZuBelegImportTyp"),
      getOptions: {
        where: ["Typ", typ],
        columns: ["Typ"]
      }
    });

    if (result.length == 0) {
      return false;
    } else {
      return true;
    }
  }
}
