import { autoinject, computedFrom } from "aurelia-framework";
import { OnUmsatzAktuell, OnUmsatzAktuellEvent, StatistikVerkaufDetailViewTyp, StatistikVerkaufWertTyp } from '../../../../framework-data/events';
import { WebEventService } from '../../../../framework/base/export';
import { FirmaService } from "../../../services/firma-service";
import { StatistikService } from '../../../services/statistik-service';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IUmsatzAktuellSettings } from './umsatz-aktuell-settings';

@autoinject
export class UmsatzAktuell {
  constructor(
    private _firmaService: FirmaService,
    private _webEventService: WebEventService,
    private _statistikVerkaufService: StatistikService) { }

  widgetModel: IWidgetModel;
  settings: IUmsatzAktuellSettings;

  itemList: any[];
  firmaBezeichnung: string;
  statistikWertTyp: StatistikVerkaufWertTyp = StatistikVerkaufWertTyp.Netto;

  wertAuftragseingangButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Auftragseingang",
    hint: "bereinigter Nettoumsatz aus Auftragseingang",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.Auftragseingang;
      this.loadData(true);
    }
  }
  wertNettoButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Umsatz",
    hint: "bereinigter Nettoumsatz aus Statistik",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.Netto;
      this.loadData(true);
    }
  }
  wertDBButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "DB",
    hint: "Deckungsbeitrag aus Statistik",
    onClick: () => {
      this.statistikWertTyp = StatistikVerkaufWertTyp.DB;
      this.loadData(true);
    }
  }

  @computedFrom("statistikWertTyp")
  get statistikWertTypBez() {
    switch (this.statistikWertTyp) {
      case StatistikVerkaufWertTyp.Netto: {
        return "Umsatz";
      }
      case StatistikVerkaufWertTyp.DB: {
        return "DB";
      }
      case StatistikVerkaufWertTyp.Auftragseingang: {
        return "Auftragseingang";
      }
    }
  }

  activate(widgetModel: IWidgetModel) {
    this.widgetModel = widgetModel;
    this.settings = widgetModel.settings;

    this.statistikWertTyp = this.settings.standard;
  }

  bind() {
    this.loadData(false);
  }

  onWertClick(item) {
    if (this.statistikWertTyp == StatistikVerkaufWertTyp.Netto || this.statistikWertTyp == StatistikVerkaufWertTyp.DB) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, item.WhereWert);
    } else if (this.statistikWertTyp == StatistikVerkaufWertTyp.Auftragseingang) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Auftrag, item.WhereWert);
    }
  }
  onWertVergleichClick(item) {
    if (this.statistikWertTyp == StatistikVerkaufWertTyp.Netto || this.statistikWertTyp == StatistikVerkaufWertTyp.DB) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, item.WhereVergleich);
    } else if (this.statistikWertTyp == StatistikVerkaufWertTyp.Auftragseingang) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Auftrag, item.WhereVergleich);
    }
  }
  onWertVergleich2Click(item) {
    if (this.statistikWertTyp == StatistikVerkaufWertTyp.Netto || this.statistikWertTyp == StatistikVerkaufWertTyp.DB) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Statistik, item.WhereVergleich2);
    } else if (this.statistikWertTyp == StatistikVerkaufWertTyp.Auftragseingang) {
      this._statistikVerkaufService.showVerkaufDetail(StatistikVerkaufDetailViewTyp.Auftrag, item.WhereVergleich2);
    }
  }

  private async loadData(showLoading: boolean) {
    const idGeschaeftspartner = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Geschaeftspartner"
      ? this.widgetModel.mainModel.Id
      : null;

    const idArtikel = this.widgetModel
      && this.widgetModel.mainModel
      && this.widgetModel.mainModel.TypeName == "TIP.ERP.Business.Entitaeten.Stammdaten.Artikel"
      ? this.widgetModel.mainModel.Id
      : null;

    const result: OnUmsatzAktuell = await this._webEventService.execute(new OnUmsatzAktuellEvent({
      IdGeschaeftspartner: idGeschaeftspartner,
      IdArtikel: idArtikel,
      WertTyp: this.statistikWertTyp,
      IdFirma: this.settings.idFirma
    }), showLoading);
    if (!result) {
      return;
    }

    this.getFirmaBezeichnung();

    this.itemList = result.ItemList;
  }
  private async getFirmaBezeichnung() {
    if (this.settings.idFirma == null) {
      this.firmaBezeichnung = null;
      return;
    }

    this.firmaBezeichnung = await this._firmaService.getFirmaBezeichnungById(this.settings.idFirma);
  }
}
