import { autoinject } from "aurelia-framework";
import { IVariableOperator } from '../interfaces/variable-operator';
import { IVariableType } from '../interfaces/variable-type';
import { IStateVariable } from '../interfaces/state-variable';
import { IVariable } from '../interfaces/variable';
import { GlobalizationService, RestService, JsonService } from '../../base/export';
import { IVariableEditor } from '../interfaces/variable-editor';

@autoinject
export class VariableService {
  constructor(
    private globalizationService: GlobalizationService,
    private restService: RestService,
    private jsonService: JsonService
  ) { }

  getAllOperators(): IVariableOperator[] {
    return <IVariableOperator[]>[
      { key: "=", text: "gleich" },
      { key: "contains", text: "enthält" },
      { key: "!=", text: "ungleich" },
      { key: ">", text: "größer" },
      { key: ">=", text: "größer gleich" },
      { key: "<", text: "kleiner" },
      { key: "<=", text: "kleiner gleich" },
      { key: "between", text: "zwischen" },
      { key: "notbetween", text: "nicht zwischen" },
      { key: "in", text: "einer aus" },
      { key: "notin", text: "keiner aus" },
      { key: "isnull", text: "leer" },
      { key: "isnotnull", text: "nicht leer" }
    ]
  }
  getAllTypes(): IVariableType[] {
    return <IVariableType[]>[
      { key: "text", text: "Text" },
      { key: "number", text: "Nummer" },
      { key: "date", text: "Datum" },
      { key: "bool", text: "Ja/Nein" },
      { key: "lookup", text: "Auswahl" }
    ]
  }

  getTextEditor(): IVariableEditor[] {
    return [
      { key: "dxTextBox", text: "dxTextBox" },
      { key: "dxRadioGroup", text: "dxRadioGroup" }
    ];
  }
  getDateEditor(): IVariableEditor[] {
    return [
      { key: "dxDateBox", text: "dxDateBox" },
      { key: "dxCalendar", text: "dxCalendar" },
    ];
  }
  getNumberEditor(): IVariableEditor[] {
    return [
      { key: "dxNumberBox", text: "dxNumberBox" },
      { key: "dxRadioGroup", text: "dxRadioGroup" }
    ];
  }
  getBoolEditor(): IVariableEditor[] {
    return [
      { key: "dxSwitch", text: "dxSwitch" },
      { key: "dxCheckBox", text: "dxCheckBox" },
    ];
  }
  
  checkVariableValue(stateVariable: IStateVariable) {
    if (!stateVariable) {
      return;
    }
    if (!stateVariable.operator) {
      stateVariable.value = null;
      return;
    }

    switch (stateVariable.operator) {
      case "=":
      case "contains":
      case "!=":
      case ">":
      case ">=":
      case "<":
      case "<=": {
        if (this.hasBetweenValue(stateVariable) || this.hasInValue(stateVariable)) {
          stateVariable.value = null;
        }
        return;
      }
      case "between":
      case "notbetween": {
        if (!this.hasBetweenValue(stateVariable)) {
          stateVariable.value = {
            value1: null,
            value2: null
          };
        }
        return;
      }
      case "in":
      case "notin": {
        if (!this.hasInValue(stateVariable)) {
          stateVariable.value = [];
        }
        return;
      }
      case "isnull":
      case "isnotnull": {
        if (stateVariable.value != void (0)) {
          stateVariable.value = null;
        }
        return;
      }
      default: {
        throw new Error(stateVariable.operator.concat(" nicht implementiert"));
      }
    }
  }
  hasVariableValue(stateVariable: IStateVariable): boolean {
    if (!stateVariable) {
      return false;
    }
    if (!stateVariable.operator) {
      return false;
    }

    switch (stateVariable.operator) {
      case "=":
      case "contains":
      case "!=":
      case ">":
      case ">=":
      case "<":
      case "<=": {
        return stateVariable.value != void (0)
          && stateVariable.value.toString() != "";
      }
      case "between":
      case "notbetween": {
        return stateVariable.value
          && typeof stateVariable.value == "object"
          && stateVariable.value.value1 != void (0)
          && stateVariable.value.value2 != void (0);
      }
      case "in":
      case "notin": {
        return stateVariable.value
          && Array.isArray(stateVariable.value)
          && stateVariable.value.length > 0;
      }
      case "isnull":
      case "isnotnull": {
        return true;
      }
      default: {
        throw new Error(stateVariable.operator.concat(" nicht implementiert"));
      }
    }
  }
  async getVariableDisplayText(variable: IVariable, value: any): Promise<string> {
    switch (variable.type) {
      case "text": {
        return value;
      }
      case "bool": {
        return value
          ? "Ja"
          : "Nein";
      }
      case "date": {
        return this.globalizationService.format(value, variable.format || "d");
      }
      case "number": {
        return this.globalizationService.format(value, variable.format || "n2");
      }
      case "lookup": {
        let r = await this.restService.get({
          url: this.restService.getWebApiUrl(variable.lookupAction).concat("/").concat(value),
          getOptions: this.jsonService.parse(variable.lookupGetOptions || "{}")
        });

        const tokens = variable.lookupDisplayExpr.split(".");
        for (let token of tokens) {
          r = r[token];

          if (!r) {
            break;
          }
        }

        return r;
      }
      default: {
        throw new Error();
      }
    }
  }
  getVariableValue(stateVariable: IStateVariable, valueKey: string): any {
    if (!stateVariable) {
      return;
    }
    if (!stateVariable.operator) {
      return;
    }
    if (stateVariable.value == void (0)) {
      return;
    }

    switch (stateVariable.operator) {
      case "=":
      case "contains":
      case "!=":
      case ">":
      case ">=":
      case "<":
      case "<=": {
        return stateVariable.value;
      }
      case "between":
      case "notbetween": {
        switch (valueKey) {
          case "value": {
            return stateVariable.value.value1;
          }
          case "value2": {
            return stateVariable.value.value2;
          }
          default: {
            throw new Error();
          }
        }
      }
      case "in":
      case "notin": {
        return stateVariable.value;
      }
      case "isnull":
      case "isnotnull": {
        return null;
      }
      default: {
        throw new Error(stateVariable.operator.concat(" nicht implementiert"));
      }
    }
  }
  setVariableValue(stateVariable: IStateVariable, valueKey: string, value: any) {
    if (!stateVariable) {
      return;
    }
    if (!stateVariable.operator) {
      return;
    }

    switch (stateVariable.operator) {
      case "=":
      case "contains":
      case "!=":
      case ">":
      case ">=":
      case "<":
      case "<=": {
        stateVariable.value = value;
        return;
      }
      case "between":
      case "notbetween": {
        switch (valueKey) {
          case "value": {
            stateVariable.value.value1 = value;
            return;
          }
          case "value2": {
            stateVariable.value.value2 = value;
            return;
          }
          default: {
            throw new Error();
          }
        }
      }
      case "in":
      case "notin": {
        if (stateVariable.value.indexOf(value) >= 0) {
          return;
        }

        stateVariable.value.push(value);
        return;
      }
      case "isnull":
      case "isnotnull": {
        return;
      }
      default: {
        throw new Error(stateVariable.operator.concat(" nicht implementiert"));
      }
    }
  }

  private hasBetweenValue(stateVariable: IStateVariable) {
    return stateVariable.value
      && typeof stateVariable.value == "object"
      && "value1" in stateVariable.value
      && "value2" in stateVariable.value;
  }
  private hasInValue(stateVariable: IStateVariable) {
    return stateVariable.value
      && Array.isArray(stateVariable.value);
  }
}
