import { autoinject } from "aurelia-framework";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

@autoinject
export class ExcelExportService {
  constructor() {}

  async exportDataGrid(options: IExcelExportDataGridOptions) {
    this.onExporting(options);
    this.prepareOptions(options);

    await DevExpress.excelExporter.exportDataGrid(options);
    await this.onExported(options);
  }
  async exportPivotGrid(options: IExcelExportPivotGridOptions) {
    this.onExporting(options);
    this.prepareOptions(options);

    await DevExpress.excelExporter.exportPivotGrid(options);
    await this.onExported(options);
  }

  private prepareOptions(options: IExcelExportBaseOptions) {
    if (!options.workbookName) {
      options.workbookName = "export";
    }

    if (!options.workbook) {
      options.workbook = new ExcelJS.Workbook();
    }

    if (!options.worksheetName) {
      options.worksheetName = "Daten";
    }

    if (!options.worksheet) {
      options.worksheet = options.workbook.addWorksheet(options.worksheetName);
    }

    if (options.saveAndOpenWorkbookAfterExport == void(0)) {
      options.saveAndOpenWorkbookAfterExport = true;
    }
  }
  private async onExporting(options: IExcelExportBaseOptions) {
    if (options.exporting) {
      options.exporting(options);
    }
  }
  private async onExported(options: IExcelExportBaseOptions) {
    if (options.saveAndOpenWorkbookAfterExport) {
      if (options.saving) {
        options.saving(options);
      }

      const buffer = await options.workbook.xlsx.writeBuffer();
      
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        options.workbookName.concat(".xlsx"));
    }
  }
}

interface IExcelExportBaseOptions extends DevExpress.excelExporter.ExcelExportBaseProps {
  workbook?: ExcelJS.Workbook;
  workbookName?: string;
  worksheetName?: string;
  saveAndOpenWorkbookAfterExport?: boolean;

  exporting?: {(options: IExcelExportBaseOptions): void};
  saving?: {(options: IExcelExportBaseOptions): void};
}
export interface IExcelExportDataGridOptions extends DevExpress.excelExporter.ExcelExportDataGridProps, IExcelExportBaseOptions {
}
export interface IExcelExportPivotGridOptions extends DevExpress.excelExporter.ExcelExportPivotGridProps, IExcelExportBaseOptions {
}