import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ProjektTaetigkeitEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "projekt-taetigkeit-edit";
        this.title = "projekt-taetigkeit-edit.projekt-taetigkeit-edit_caption";
        this.addModel({
            "id": "$m_Taetigkeit",
            "webApiAction": "ERP/Stammdaten/ProjektTaetigkeit",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "models.data.$m_Taetigkeit.CanSave",
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "projekt-taetigkeit-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Taetigkeit.Id > 0",
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Taetigkeit.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "projekt-taetigkeit-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_Taetigkeit.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "taetigkeit",
            "filters": [],
            "caption": "projekt-taetigkeit-edit.r_taetigkeit_caption",
            "isReadOnlyExpression": "models.data.$m_Taetigkeit.Id > 0",
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "IdTaetigkeit",
                "bindToFQ": "models.data.$m_Taetigkeit.IdTaetigkeit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_taetigkeit",
            "options": {
                "optionsName": "r_taetigkeitOptions",
                "optionsNameFQ": "r_taetigkeitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "projekt-taetigkeit-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Taetigkeit.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "max": 100000.0,
            "format": "n2",
            "caption": "projekt-taetigkeit-edit.r_stundengeplant_caption",
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "StundenGeplant",
                "bindToFQ": "models.data.$m_Taetigkeit.StundenGeplant"
            },
            "validationRules": [],
            "id": "r_stundenGeplant",
            "options": {
                "optionsName": "r_stundenGeplantOptions",
                "optionsNameFQ": "r_stundenGeplantOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "projekt-taetigkeit-edit.r_erledigeninappsperren_caption",
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "ErledigenInAppSperren",
                "bindToFQ": "models.data.$m_Taetigkeit.ErledigenInAppSperren"
            },
            "validationRules": [],
            "id": "r_erledigenInAppSperren",
            "options": {
                "optionsName": "r_erledigenInAppSperrenOptions",
                "optionsNameFQ": "r_erledigenInAppSperrenOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "projekt-taetigkeit-edit.r_stundengeleistet_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Taetigkeit",
                "bindTo": "StundenGeleistet",
                "bindToFQ": "models.data.$m_Taetigkeit.StundenGeleistet"
            },
            "validationRules": [],
            "id": "r_stundenGeleistet",
            "options": {
                "optionsName": "r_stundenGeleistetOptions",
                "optionsNameFQ": "r_stundenGeleistetOptions"
            }
        });
        super.onConstructionFinished();
    }
}