export enum NotificationTyp {
  aufgabeAnzahlChanged = "aufgabe:anzahlchanged",
  benachrichtigungAnzahlChanged = "benachrichtigung:anzahlchanged",
  briefAnzahlChanged = "brief:anzahlchanged",
  konditionsfeldChanged = "konditionsfeld:changed",
  statusChanged = "status:changed",
  waehrungChanged = "waehrung:changed",
  filtergruppeChanged = "filtergruppe:changed",
  filterelementChanged = "filterelement:changed",
  notizAnzahlChanged = "notiz:anzahlchanged",
  mailAnzahlChanged = "mail:anzahlchanged",
  benutzerAvatarUrlChanged = "benutzer:avatarUrlChanged",
  mandantChanged = "mandant:changed",
  mandantSwitched = "mandant:switched",
  startupInfoLoaded = "startupInfo:loaded",
  notizShowEditPopup = "notiz:showEditPopup",
  notizShowListPopover = "notiz:showListPopover",
  dataReload = "data:reload",
  office365TokenChanged = "office365:tokenChanged",
  benutzerCanMandantWechselnChanged = "benutzer:canMandantWechselnChanged"
}
