import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    AngebotListUtils
} from "./angebot-list-utils";

@fwx.autoinject
export class AngebotListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_AngebotListUtils: AngebotListUtils) {
        super(element, formBaseImport);
        this.id = "angebot-list";
        this.title = "angebot-list.angebot-list_caption";
        this.addModel({
            "id": "$m_Angebot",
            "webApiAction": "ERP/Verkauf/Angebot",
            "webApiExpand": {
                'Angebotsart': null,
                'Projekt': null,
                'Status': null,
                'Waehrung': null,
                'Kunde': null,
                'KundeAdresse': {
                    'expand': {
                        'Land': null
                    }
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_angebotGrid'
                ]
            },
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_AngebotListUtils.doExport",
                "bindToFQ": "functions.$f_AngebotListUtils.doExport"
            }
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AngebotListUtils", $f_AngebotListUtils, "functions.$f_AngebotListUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_angNummer",
                "caption": "angebot-list.r_angnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_angDatum",
                "caption": "angebot-list.r_angdatum_caption",
                "bindTo": "Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angProjekt",
                "caption": "angebot-list.r_angprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_angGueltigBis",
                "caption": "angebot-list.r_anggueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angKundeNummer",
                "caption": "angebot-list.r_angkundenummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_angFirmenbez1",
                "caption": "angebot-list.r_angfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_angStrasse",
                "caption": "angebot-list.r_angstrasse_caption",
                "bindTo": "KundeAdresse.Strasse",
                "width": "120px"
            }, {
                "id": "r_angLand",
                "caption": "angebot-list.r_angland_caption",
                "bindTo": "KundeAdresse.Land.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_angOrt",
                "caption": "angebot-list.r_angort_caption",
                "bindTo": "KundeAdresse.Ort",
                "width": "120px"
            }, {
                "id": "r_angWertNetto",
                "caption": "angebot-list.r_angwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_angWaehrung",
                "caption": "angebot-list.r_angwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_angStatus",
                "caption": "angebot-list.r_angstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_angebotGridToolbarOptions",
                "optionsNameFQ": "r_angebotGridToolbarOptions"
            },
            "caption": "angebot-list.r_angebotgrid_caption",
            "binding": {
                "dataContext": "$m_Angebot"
            },
            "dataModel": "$m_Angebot",
            "editUrl": "ERP/Verkauf/Angebot",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_angebotGrid",
            "options": {
                "optionsName": "r_angebotGridOptions",
                "optionsNameFQ": "r_angebotGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}