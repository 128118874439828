import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class GutscheinListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "gutschein-list";
        this.title = "gutschein-list.gutschein-list_caption";
        this.addModel({
            "id": "$m_Gutschein",
            "webApiAction": "ERP/Verkauf/Gutschein",
            "webApiExpand": {
                'Geschaeftspartner': {
                    'columns': [
                        'Id',
                        'EntitaetBezeichnung'
                    ]
                },
                'Waehrung': null,
                'Status': null,
                'Folgegutschein': {
                    'columns': [
                        'Id',
                        'Code'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_gutscheinGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_gutsCode",
                "caption": "gutschein-list.r_gutscode_caption",
                "bindTo": "Code",
                "width": "100px"
            }, {
                "id": "r_gutsFolgegutschein",
                "caption": "gutschein-list.r_gutsfolgegutschein_caption",
                "bindTo": "Folgegutschein.Code",
                "width": "100px"
            }, {
                "id": "r_gutsGeschaeftspartner",
                "caption": "gutschein-list.r_gutsgeschaeftspartner_caption",
                "bindTo": "Geschaeftspartner.EntitaetBezeichnung",
                "minWidth": 100
            }, {
                "id": "r_gutsWertGesamt",
                "caption": "gutschein-list.r_gutswertgesamt_caption",
                "bindTo": "WertGesamt",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_gutsWertReserviert",
                "caption": "gutschein-list.r_gutswertreserviert_caption",
                "bindTo": "WertReserviert",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_gutsWertEingeloest",
                "caption": "gutschein-list.r_gutswerteingeloest_caption",
                "bindTo": "WertEingeloest",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_gutsWertOffen",
                "caption": "gutschein-list.r_gutswertoffen_caption",
                "bindTo": "WertOffen",
                "width": "80px",
                "format": "n2",
                "showTotalAggSum": true
            }, {
                "id": "r_gutsWertWaehrung",
                "caption": "gutschein-list.r_gutswertwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_gutsStatus",
                "caption": "gutschein-list.r_gutsstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_gutscheinGridToolbarOptions",
                "optionsNameFQ": "r_gutscheinGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Gutschein"
            },
            "dataModel": "$m_Gutschein",
            "editUrl": "ERP/Verkauf/Gutschein",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_gutscheinGrid",
            "options": {
                "optionsName": "r_gutscheinGridOptions",
                "optionsNameFQ": "r_gutscheinGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}