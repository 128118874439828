import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class InstanzEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "instanz-edit";
        this.title = "instanz-edit.instanz-edit_caption";
        this.addModel({
            "id": "$m_Instanz",
            "webApiAction": "ERP/Cloud/Instanz",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_firmenbez1_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Firmenbez1",
                "bindToFQ": "models.data.$m_Instanz.Firmenbez1"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firmenbez1",
            "options": {
                "optionsName": "r_firmenbez1Options",
                "optionsNameFQ": "r_firmenbez1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_firmenbez2_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Firmenbez2",
                "bindToFQ": "models.data.$m_Instanz.Firmenbez2"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firmenbez2",
            "options": {
                "optionsName": "r_firmenbez2Options",
                "optionsNameFQ": "r_firmenbez2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_firmenbez3_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Firmenbez3",
                "bindToFQ": "models.data.$m_Instanz.Firmenbez3"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_firmenbez3",
            "options": {
                "optionsName": "r_firmenbez3Options",
                "optionsNameFQ": "r_firmenbez3Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_strasse_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Strasse",
                "bindToFQ": "models.data.$m_Instanz.Strasse"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_strasse",
            "options": {
                "optionsName": "r_strasseOptions",
                "optionsNameFQ": "r_strasseOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 2,
            "caption": "instanz-edit.r_landiso3166a2_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "LandISO3166A2",
                "bindToFQ": "models.data.$m_Instanz.LandISO3166A2"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 2
                    }]
                }
            }],
            "id": "r_landISO3166A2",
            "options": {
                "optionsName": "r_landISO3166A2Options",
                "optionsNameFQ": "r_landISO3166A2Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "instanz-edit.r_plz_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "PLZ",
                "bindToFQ": "models.data.$m_Instanz.PLZ"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 10
                    }]
                }
            }],
            "id": "r_plz",
            "options": {
                "optionsName": "r_plzOptions",
                "optionsNameFQ": "r_plzOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_ort_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Ort",
                "bindToFQ": "models.data.$m_Instanz.Ort"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_ort",
            "options": {
                "optionsName": "r_ortOptions",
                "optionsNameFQ": "r_ortOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_emailadministrator_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "EmailAdministrator",
                "bindToFQ": "models.data.$m_Instanz.EmailAdministrator"
            },
            "validationRules": [{
                "item": {
                    "type": "email",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_emailAdministrator",
            "options": {
                "optionsName": "r_emailAdministratorOptions",
                "optionsNameFQ": "r_emailAdministratorOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_server_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Server",
                "bindToFQ": "models.data.$m_Instanz.Server"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_Server",
            "options": {
                "optionsName": "r_ServerOptions",
                "optionsNameFQ": "r_ServerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "instanz-edit.r_datenbank_caption",
            "isReadOnlyExpression": "models.data.$m_Instanz.Id > 0",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Datenbank",
                "bindToFQ": "models.data.$m_Instanz.Datenbank"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_Datenbank",
            "options": {
                "optionsName": "r_DatenbankOptions",
                "optionsNameFQ": "r_DatenbankOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "instanz-edit.r_benutzersuffix_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "BenutzerSuffix",
                "bindToFQ": "models.data.$m_Instanz.BenutzerSuffix"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_benutzerSuffix",
            "options": {
                "optionsName": "r_benutzerSuffixOptions",
                "optionsNameFQ": "r_benutzerSuffixOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "instanz-edit.r_isazure_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "IsAzure",
                "bindToFQ": "models.data.$m_Instanz.IsAzure"
            },
            "validationRules": [],
            "id": "r_isAzure",
            "options": {
                "optionsName": "r_isAzureOptions",
                "optionsNameFQ": "r_isAzureOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "200px",
            "caption": "instanz-edit.r_lizenz_caption",
            "binding": {
                "dataContext": "$m_Instanz",
                "bindTo": "Lizenz",
                "bindToFQ": "models.data.$m_Instanz.Lizenz"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lizenz",
            "options": {
                "optionsName": "r_lizenzOptions",
                "optionsNameFQ": "r_lizenzOptions"
            }
        });
        super.onConstructionFinished();
    }
}