import { DynFeldWertVorlage } from './../elements/dyn-feld-wert-vorlage/dyn-feld-wert-vorlage';
import {
  autoinject
} from "aurelia-framework";
import {
  RestService,
  WebEventService
} from "../../framework/base/export";
import {
  OnDynamischesFeldEvalVorbelegungEvent,
  OnDynamischesFeldEvalVorbelegung,
  OnDynamischesFeldHasManuellerText,
  OnDynamischesFeldHasManuellerTextEvent,
  OnDynamischesFeldUpdateManuellerTextEvent
} from "../../framework-data/events";
import { DynFeldTyp } from '../enumerations/export';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DynFeldLogikTyp } from '../enumerations/dyn-feld-logik-typ';

@autoinject
export class DynFelderService {
  private _manuellerTextCache = {};
  private _dynFeldCache: IDynamischesFeld[];

  constructor(
    private _restService: RestService,
    private _webEventService: WebEventService,
    private _eventAggregator: EventAggregator
  ) {
    this._eventAggregator.subscribe("dynamisches-feld:changed", () => {
      this._dynFeldCache = null;
    });
    this._eventAggregator.subscribe("startupInfo:loaded", () => {
      this._dynFeldCache = null;
    });
  }

  dynFeldWertVorlage: DynFeldWertVorlage;

  async hasManuellerText(typeName: string): Promise<OnDynamischesFeldHasManuellerText> {
    if (this._manuellerTextCache[typeName]) {
      return this._manuellerTextCache[typeName];
    }

    const result: OnDynamischesFeldHasManuellerText = await this._webEventService.execute(new OnDynamischesFeldHasManuellerTextEvent({
      TypeName: typeName
    }), false);

    this._manuellerTextCache[typeName] = result;
    return result;
  }
  updateManuelleTexte(id: number, kopftext: boolean, fusstext: boolean): Promise<any> {
    return this._webEventService.execute(new OnDynamischesFeldUpdateManuellerTextEvent({
      Id: id,
      Kopftext: kopftext,
      Fusstext: fusstext
    }), true);
  }

  async showWertVorlageAuswahlPopup(id: number, htmlEditor: any) {
    await this.dynFeldWertVorlage.showPopup(id, htmlEditor);
  }

  async getDynFeldById(id: number) {
    return this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/DynamischesFeld/DynamischesFeld/${id}`),
      getOptions: this.getDynFeldOptions()
    });
  }

  async loadDynFelder(typeName: string): Promise<IDynamischesFeld[]> {
    if (!this._dynFeldCache) {
      this._dynFeldCache = await this._restService.get({
        url: this._restService.getWebApiUrl("ERP/DynamischesFeld/DynamischesFeld"),
        getOptions: this.getDynFeldOptions()
      })
    }

    return this._dynFeldCache
      .filter(d => d.DynamischesFeldZuEntitaeten.some(e => e.TypeName == typeName))
      .map(d => {
        const r = Object.assign({}, d);
        r.DynamischesFeldZuEntitaeten = d.DynamischesFeldZuEntitaeten.filter(e => e.TypeName == typeName).map(e => Object.assign({}, e));

        return r;
      });
  }
  loadDynFelderContainer(id: number): Promise<any> {
    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/DynamischesFeld/DynamischesFeldContainer")}/${id}`,
      getOptions: {
        expand: { DynamischesFeldEintraege: null }
      },
      increaseLoadingCount: true
    });
  }
  loadDynFeldEintragByLogikttyp(idDynFeldContainer: number, logikTyp){
    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/DynamischesFeld/DynamischesFeldEintrag")}`,
      getOptions: {
        where: [["IdDynamischesFeldContainer", idDynFeldContainer], ["DynamischesFeld.LogikTyp", logikTyp]],
        expand: { DynamischesFeldEintraege: null }
      }
    });
  }
  loadVorbelegungDynFelder(options: OnDynamischesFeldEvalVorbelegung): Promise<OnDynamischesFeldEvalVorbelegung> {
    const vorbelegungEvent = new OnDynamischesFeldEvalVorbelegungEvent(options);
    return this._webEventService.execute(vorbelegungEvent, true);
  }

  async getIdFirmaDynFeldLogikFirmaUmstellungBeiMehrerenFirmen(idDynamischesFeldContainer: number) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/DynamischesFeld/DynamischesFeldEintrag"),
      getOptions: {
        where: [["IdDynamischesFeldContainer", idDynamischesFeldContainer], ["DynamischesFeld.LogikTyp", DynFeldLogikTyp.FirmaUmstellungMehrereFirmen]]
      }
    });

    if (r && r.length > 0) {
      const idWertAuswahlliste = r[0].IdWertAuswahlliste;
      if (idWertAuswahlliste == null) {
        return null;
      } else {
        const dynEntitaetElement = await this.getDynamischeEntitaetElementById(idWertAuswahlliste);
        return parseInt(dynEntitaetElement.Code);
      }
    } else {
      return null;
    }
  }

  private async getDynamischeEntitaetElementById(IdDynamischeEntitaetElement: number) {
    return await this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/DynamischesFeld/DynamischeEntitaetElement")}/${IdDynamischeEntitaetElement}`
    });
  }

  private getDynFeldOptions() {
    return {
      columns: ["Id", "Bezeichnung", "BreiteMD", "BreiteLG", "Code", "Typ", "AuswahllisteDatenquelle", "Format", "SortNr", "HasWertVorlagen", "Hoehe", "LogikTyp"],
      orderBy: [{ columnName: "SortNr", sortOrder: 0 }],
      expand: {
        DynamischesFeldZuEntitaeten: {
          columns: ["Id", "IdDynamischesFeld", "Anzeigeort", "TypeName", "IsPflicht", "IsReadOnly", "CanAenderWennDatensatzErledigt", "IdArt", "IdVorbWertAuswahlliste", "VorbWertText", "VorbWertDatum", "VorbWertNummer"]
        }
      }
    }
  }
}

export interface IDynamischesFeld {
  Id: number;
  SortNr: number;
  Code: string;
  Typ: DynFeldTyp;
  Bezeichnung: string;
  BreiteMD: number;
  BreiteLG: number;
  AuswahllisteDatenquelle: string;
  Format: string;
  HasWertVorlagen: boolean;
  Hoehe: string;
  LogikTyp: number;
  DynamischesFeldZuEntitaeten: IDynamischesFeldZuEntitaet[];
}
export interface IDynamischesFeldZuEntitaet {
  Id: number;
  IdDynamischesFeld: number;
  IdArt: number;
  IdVorbWertAuswahlliste: number;
  Anzeigeort: string;
  TypeName: string;
  IsPflicht: boolean;
  IsReadOnly: boolean;
  CanAenderWennDatensatzErledigt: boolean;
  VorbWertText: string;
  VorbWertDatum: Date;
  VorbWertNummer: number;
}
