import {
  autoinject
} from "aurelia-framework";
import {
  OnPeriodeRequest,
  OnPeriodeRequestEvent
} from "../../framework-data/events";
import {
  RestService,
  WebEventService
} from "./../../framework/base/services/export";
import * as moment from "moment";
import { StatusService } from './status-service';

@autoinject
export class AuftragService {
  constructor(
    private _restService: RestService,
    private _webEventService: WebEventService,
    private _statusService: StatusService) { }

  async getLieferdatum(idAuftragsart: number, current: Date): Promise<Date> {
    if (!idAuftragsart) {
      return current;
    }

    const auftragsart = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Auftragsart/${idAuftragsart}`),
      getOptions: {
        columns: ["VorbelegungLieferdatumPeriode"]
      }
    });

    if (!auftragsart || !auftragsart["VorbelegungLieferdatumPeriode"]) {
      return current;
    }

    const result: OnPeriodeRequest = await this._webEventService.execute(new OnPeriodeRequestEvent({
      Datum: moment().startOf("day").toDate(),
      Periode: auftragsart["VorbelegungLieferdatumPeriode"]
    }));

    if (!result) {
      return current;
    }

    return result.Ergebnis;
  }

  onLieferdatumCellPrepared(e): void {
    if (e.rowType != "data") {
      return;
    }

    if (!e.data || !e.data.Lieferdatum || !e.data._LieferdatumTyp) {
      return;
    }

    if (e.data.IdStatus) {
      if (!this._statusService.isOffen(e.data.IdStatus)) {
        return;
      }
    }

    const cell: HTMLTableDataCellElement = e.cellElement;

    cell.style.backgroundColor = this.getLieferdatumTypFarbe(e.data._LieferdatumTyp);
    cell.style.color = "white";
  }

  private getLieferdatumTypFarbe(typ): string {
    switch (typ) {
      case 1: {
        return "#C0392B";
      }
      case 2: {
        return "#F39C12";
      }
      default: {
        return "";
      }
    }
  }

  async getAuftragspositionByIdIcBestellungsposition(idBestellungsposition) {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Verkauf/Auftragsposition"),
      getOptions: {
        where: ["IdIcBestellungsposition", idBestellungsposition]
      }
    });

    if (!r || r.length === 0) {
      return null;
    }

    return r[0];
  }

  async hasAuftragsgruppePositionenZugeteilt(idAuftragsgruppe){
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Verkauf/Auftragsposition`),
      getOptions: {
        where: ["IdAuftragsgruppe", idAuftragsgruppe]
      }
    });

    if (r && r.length > 0) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}

