import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ArtikelverleihListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "artikel-verleih-list";
        this.title = "artikel-verleih-list.artikel-verleih-list_caption";
        this.addModel({
            "id": "$m_Artikelverleih",
            "webApiAction": "ERP/Lager/Artikelverleih",
            "webApiExpand": {
                'Status': null,
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Filiale': null,
                'Lager': null,
                'Kunde': null,
                'KundeAdresse': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_artikelVerleihGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_geplAbhol",
                "caption": "artikel-verleih-list.r_geplabhol_caption",
                "bindTo": "GeplanteAbholung",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_tatsAbhol",
                "caption": "artikel-verleih-list.r_tatsabhol_caption",
                "bindTo": "TatsaechlicheAbholung",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_geplRueck",
                "caption": "artikel-verleih-list.r_geplrueck_caption",
                "bindTo": "GeplanteRueckgabe",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_tatsRueck",
                "caption": "artikel-verleih-list.r_tatsrueck_caption",
                "bindTo": "TatsaechlicheRueckgabe",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_kundeNummer",
                "caption": "artikel-verleih-list.r_kundenummer_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_kundeFirmenbez1",
                "caption": "artikel-verleih-list.r_kundefirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_kundeOrt",
                "caption": "artikel-verleih-list.r_kundeort_caption",
                "bindTo": "Kunde.Adresse.Ort",
                "minWidth": 100
            }, {
                "id": "r_lagkArtikelnr",
                "caption": "artikel-verleih-list.r_lagkartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_lagkArtikel",
                "caption": "artikel-verleih-list.r_lagkartikel_caption",
                "bindTo": "Artikel.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_menge",
                "caption": "artikel-verleih-list.r_menge_caption",
                "bindTo": "MengeLagEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einheit",
                "caption": "artikel-verleih-list.r_einheit_caption",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_status",
                "caption": "artikel-verleih-list.r_status_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_artikelVerleihGridToolbarOptions",
                "optionsNameFQ": "r_artikelVerleihGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Artikelverleih"
            },
            "dataModel": "$m_Artikelverleih",
            "editUrl": "ERP/Lager/Artikelverleih",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_artikelVerleihGrid",
            "options": {
                "optionsName": "r_artikelVerleihGridOptions",
                "optionsNameFQ": "r_artikelVerleihGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}