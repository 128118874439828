import {
  autoinject
} from "aurelia-framework";
import {
  RestService, WebEventService
} from "../../framework/base/export";
import { PersonRequestTyp, OnPersonRequestEvent, OnPersonRequest } from '../../framework-data/events';

@autoinject
export class PersonService {
  constructor(
    private _restService: RestService,
    private _webEventService: WebEventService
  ) { }

 
  getPersonBasicDataById(idPerson: number): Promise<any>{
    const getOptions = {
      columns: ["Id", "Telefon"]
    };

    return this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Person/${idPerson}`),
      getOptions: getOptions
    });
  }
  async getIdPersonVorbelegung(idGeschaeftspartner: number, requestTyp: PersonRequestTyp) {
    const r: OnPersonRequest = await this._webEventService.execute(new OnPersonRequestEvent({
      IdGeschaeftspartner: idGeschaeftspartner,
      Typ: requestTyp
    }));

    return r.IdPerson;
  }
  getPersonTelefon(idPerson: number): Promise<string> {
    return this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Person/${idPerson}`),
      getOptions: {
        columns: ["Id", "Telefon", "Mobiltelefon"]
      }
    }).then(r => {
      if (!r) {
        return null;
      }

      return r.Telefon || r.Mobiltelefon;
    })
  }
}
