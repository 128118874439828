import { autoinject, transient } from "aurelia-framework";
import { FormBase, ICommandData } from "../../../../framework/forms/export";
import { DynFelderDataService } from "../../../services/dyn-felder-data-service";

@autoinject
@transient()
export class ZahlungsverkehrEditUtils {
  constructor(
    public dynFelderDataService: DynFelderDataService) { }

  form: FormBase;

  showZahlungsverkehrSyncJobCommand: ICommandData = {
    id: "showDokumentErstellenPopup",
    idCategory: "zahlungsverkehr",
    icon: "file",
    title: "zahlungsverkehr.sync_oeffnen",
    sortIndex: 10,
    isVisibleExpression: "models.data.$m_Zahlungsverkehr.IdZahlungsverkehrSyncJob != null",
    execute: async (e) => {
      this.form.editPopups.show("zahlungsverkehrSyncJobEditPopup", null);
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.dynFelderDataService.register(this.form, {
      idMainModel: "$m_Zahlungsverkehr"
    });
  }
}
