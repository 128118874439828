import * as fwx from "../../../../framework/forms/form-export";
import {
    MailImportItemEditUtils
} from "./mail-import-item-edit-utils";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class MailImportItemEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_MailImportItemEditUtils: MailImportItemEditUtils,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "mail-import-item-edit";
        this.title = "mail-import-item-edit.mail-import-item-edit_caption";
        this.addModel({
            "id": "$m_MailImportItem",
            "webApiAction": "ERP/Stammdaten/MailImportItem",
            "webApiExpand": {
                'MailImportItemAnhaenge': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_MailImportItemAnhangRel",
            "webApiAction": "ERP/Stammdaten/MailImportItemAnhang",
            "webApiExpand": {
                'Dokumentenart': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_MailImportItemAnhangEdit",
            "webApiAction": "ERP/Stammdaten/MailImportItemAnhang",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Auftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Kunde': null,
                'KundeAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdKunde", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Kunde': null,
                'KundeAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdKunde", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Faktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "webApiExpand": {
                'Rechnungsort': null,
                'RechnungsortAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": [
                ["IdRechnungsort", {
                    "isBound": true,
                    "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
                }],
                ["Status.Typ", 1]
            ],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Angebot",
            "webApiAction": "ERP/Verkauf/Angebot",
            "webApiExpand": {
                'Kunde': null,
                'KundeAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdKunde", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Kundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "webApiExpand": {
                'Kunde': null,
                'KundeAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdKunde", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Bestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "webApiExpand": {
                'Lieferant': null,
                'LieferantAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdLieferant", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Eingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "webApiExpand": {
                'Lieferant': null,
                'LieferantAdresse': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["IdLieferant", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_MailImportItemEditUtils.neuCommand",
                "bindToFQ": "functions.$f_MailImportItemEditUtils.neuCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_MailImportItemEditUtils.verknuepfenCommand",
                "bindToFQ": "functions.$f_MailImportItemEditUtils.verknuepfenCommand"
            }
        });
        this.addEditPopup({
            "idContent": "mailImportItemAnhangEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_MailImportItemAnhangEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_MailImportItemAnhangEdit.Id"
                }
            }],
            "id": "mailImportItemAnhangEditPopup",
            "options": {
                "optionsName": "mailImportItemAnhangEditPopupOptions",
                "optionsNameFQ": "mailImportItemAnhangEditPopupOptions"
            },
            "caption": "mail-import-item-edit.mailimportitemanhangeditpopup_caption",
            "height": "auto",
            "width": "700px",
            "commands": []
        });
        this.addFunction("$f_MailImportItemEditUtils", $f_MailImportItemEditUtils, "functions.$f_MailImportItemEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "mailImportDefinition",
            "filters": [],
            "caption": "mail-import-item-edit.r_mailimportdef_caption",
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "IdMailImportDefinition",
                "bindToFQ": "models.data.$m_MailImportItem.IdMailImportDefinition"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_mailImportDef",
            "options": {
                "optionsName": "r_mailImportDefOptions",
                "optionsNameFQ": "r_mailImportDefOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "g",
            "caption": "mail-import-item-edit.r_datum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "Datum",
                "bindToFQ": "models.data.$m_MailImportItem.Datum"
            },
            "validationRules": [],
            "id": "r_datum",
            "options": {
                "optionsName": "r_datumOptions",
                "optionsNameFQ": "r_datumOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "mail-import-item-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_MailImportItem.IdStatus"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "mail-import-item-edit.r_absenderemail_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "AbsenderEmail",
                "bindToFQ": "models.data.$m_MailImportItem.AbsenderEmail"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_absenderEmail",
            "options": {
                "optionsName": "r_absenderEmailOptions",
                "optionsNameFQ": "r_absenderEmailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "mail-import-item-edit.r_emfpaengeremail_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "EmpfaengerEmail",
                "bindToFQ": "models.data.$m_MailImportItem.EmpfaengerEmail"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_emfpaengerEmail",
            "options": {
                "optionsName": "r_emfpaengerEmailOptions",
                "optionsNameFQ": "r_emfpaengerEmailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "mail-import-item-edit.r_betreff_caption",
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "Betreff",
                "bindToFQ": "models.data.$m_MailImportItem.Betreff"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_betreff",
            "options": {
                "optionsName": "r_betreffOptions",
                "optionsNameFQ": "r_betreffOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "person",
            "filter": ["IdGeschaeftspartner", {
                "isBound": true,
                "expression": "models.data.$m_MailImportItem.IdGeschaeftspartner"
            }],
            "filters": [],
            "caption": "mail-import-item-edit.r_person_caption",
            "binding": {
                "dataContext": "$m_MailImportItem",
                "bindTo": "IdPerson",
                "bindToFQ": "models.data.$m_MailImportItem.IdPerson"
            },
            "validationRules": [],
            "id": "r_person",
            "options": {
                "optionsName": "r_personOptions",
                "optionsNameFQ": "r_personOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_anhangBezeichnung",
                "caption": "mail-import-item-edit.r_anhangbezeichnung_caption",
                "bindTo": "Bezeichnung"
            }, {
                "id": "r_anhangDokumentenart",
                "caption": "mail-import-item-edit.r_anhangdokumentenart_caption",
                "bindTo": "Dokumentenart.Bezeichnung"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_mailImportItemAnhangGridToolbarOptions",
                "optionsNameFQ": "r_mailImportItemAnhangGridToolbarOptions"
            },
            "caption": "mail-import-item-edit.r_mailimportitemanhanggrid_caption",
            "binding": {
                "dataContext": "$m_MailImportItem"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_MailImportItemAnhangRel",
                "bindTo": "IdMailImportItem",
                "bindToFQ": "models.data.$m_MailImportItemAnhangRel.IdMailImportItem"
            },
            "dataModel": "$m_MailImportItem",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onAnhangClicked($event)",
            "showToolbarTitle": true,
            "selectionMode": 2,
            "listEdits": [],
            "filters": [],
            "commands": [{
                "binding": {
                    "dataContext": "$m_MailImportItem",
                    "bindTo": "$f_MailImportItemEditUtils.herunterladenCommand",
                    "bindToFQ": "functions.$f_MailImportItemEditUtils.herunterladenCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_MailImportItem",
                    "bindTo": "$f_MailImportItemEditUtils.versendenCommand",
                    "bindToFQ": "functions.$f_MailImportItemEditUtils.versendenCommand"
                }
            }, {
                "binding": {
                    "dataContext": "$m_MailImportItem",
                    "bindTo": "$f_MailImportItemEditUtils.dokumentenartZuteilenCommand",
                    "bindToFQ": "functions.$f_MailImportItemEditUtils.dokumentenartZuteilenCommand"
                }
            }],
            "id": "r_mailImportItemAnhangGrid",
            "options": {
                "optionsName": "r_mailImportItemAnhangGridOptions",
                "optionsNameFQ": "r_mailImportItemAnhangGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_angebotPopup",
            "options": {
                "optionsName": "r_angebotPopupOptions",
                "optionsNameFQ": "r_angebotPopupOptions"
            },
            "caption": "mail-import-item-edit.r_angebotpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_angAngebotNummer",
                "caption": "mail-import-item-edit.r_angangebotnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_angDatum",
                "caption": "mail-import-item-edit.r_angdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_angProjekt",
                "caption": "mail-import-item-edit.r_angprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_angKundeNr",
                "caption": "mail-import-item-edit.r_angkundenr_caption",
                "bindTo": "Kunde.Nummer",
                "width": "90px"
            }, {
                "id": "r_angFirmenbez1",
                "caption": "mail-import-item-edit.r_angfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_angStatus",
                "caption": "mail-import-item-edit.r_angstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_angebotGridToolbarOptions",
                "optionsNameFQ": "r_angebotGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Angebot"
            },
            "dataModel": "$m_Angebot",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_angebotPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_angebotGrid",
            "options": {
                "optionsName": "r_angebotGridOptions",
                "optionsNameFQ": "r_angebotGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_auftragPopup",
            "options": {
                "optionsName": "r_auftragPopupOptions",
                "optionsNameFQ": "r_auftragPopupOptions"
            },
            "caption": "mail-import-item-edit.r_auftragpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_auftAuftragNummer",
                "caption": "mail-import-item-edit.r_auftauftragnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_auftDatum",
                "caption": "mail-import-item-edit.r_auftdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_auftProjekt",
                "caption": "mail-import-item-edit.r_auftprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_auftKundeNr",
                "caption": "mail-import-item-edit.r_auftkundenr_caption",
                "bindTo": "Kunde.Nummer",
                "width": "90px"
            }, {
                "id": "r_auftFirmenbez1",
                "caption": "mail-import-item-edit.r_auftfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_auftStatus",
                "caption": "mail-import-item-edit.r_auftstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_auftragGridToolbarOptions",
                "optionsNameFQ": "r_auftragGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Auftrag"
            },
            "dataModel": "$m_Auftrag",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_auftragPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_auftragGrid",
            "options": {
                "optionsName": "r_auftragGridOptions",
                "optionsNameFQ": "r_auftragGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_lieferscheinPopup",
            "options": {
                "optionsName": "r_lieferscheinPopupOptions",
                "optionsNameFQ": "r_lieferscheinPopupOptions"
            },
            "caption": "mail-import-item-edit.r_lieferscheinpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_lieferscheinNummer",
                "caption": "mail-import-item-edit.r_lieferscheinnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_liefDatum",
                "caption": "mail-import-item-edit.r_liefdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_liefProjekt",
                "caption": "mail-import-item-edit.r_liefprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_liefKundeNr",
                "caption": "mail-import-item-edit.r_liefkundenr_caption",
                "bindTo": "Kunde.Nummer",
                "width": "90px"
            }, {
                "id": "r_liefFirmenbez1",
                "caption": "mail-import-item-edit.r_lieffirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_liefStatus",
                "caption": "mail-import-item-edit.r_liefstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_lieferscheinGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Lieferschein"
            },
            "dataModel": "$m_Lieferschein",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_lieferscheinPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheinGrid",
            "options": {
                "optionsName": "r_lieferscheinGridOptions",
                "optionsNameFQ": "r_lieferscheinGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_fakturaPopup",
            "options": {
                "optionsName": "r_fakturaPopupOptions",
                "optionsNameFQ": "r_fakturaPopupOptions"
            },
            "caption": "mail-import-item-edit.r_fakturapopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_faktNummer",
                "caption": "mail-import-item-edit.r_faktnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_faktDatum",
                "caption": "mail-import-item-edit.r_faktdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_faktProjekt",
                "caption": "mail-import-item-edit.r_faktprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_faktKundeNr",
                "caption": "mail-import-item-edit.r_faktkundenr_caption",
                "bindTo": "Rechnungsort.Nummer",
                "width": "90px"
            }, {
                "id": "r_faktFirmenbez1",
                "caption": "mail-import-item-edit.r_faktfirmenbez1_caption",
                "bindTo": "RechnungsortAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_faktStatus",
                "caption": "mail-import-item-edit.r_faktstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_fakturaGridToolbarOptions",
                "optionsNameFQ": "r_fakturaGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Faktura"
            },
            "dataModel": "$m_Faktura",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_fakturaPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fakturaGrid",
            "options": {
                "optionsName": "r_fakturaGridOptions",
                "optionsNameFQ": "r_fakturaGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_kundenreklamationPopup",
            "options": {
                "optionsName": "r_kundenreklamationPopupOptions",
                "optionsNameFQ": "r_kundenreklamationPopupOptions"
            },
            "caption": "mail-import-item-edit.r_kundenreklamationpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_kurlNummer",
                "caption": "mail-import-item-edit.r_kurlnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_kurlDatum",
                "caption": "mail-import-item-edit.r_kurldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_kurlProjekt",
                "caption": "mail-import-item-edit.r_kurlprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kurlKundeNr",
                "caption": "mail-import-item-edit.r_kurlkundenr_caption",
                "bindTo": "Kunde.Nummer",
                "width": "90px"
            }, {
                "id": "r_kurlFirmenbez1",
                "caption": "mail-import-item-edit.r_kurlfirmenbez1_caption",
                "bindTo": "KundeAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_kurlStatus",
                "caption": "mail-import-item-edit.r_kurlstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kundenreklamationGridToolbarOptions",
                "optionsNameFQ": "r_kundenreklamationGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Kundenreklamation"
            },
            "dataModel": "$m_Kundenreklamation",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_kundenreklamationPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kundenreklamationGrid",
            "options": {
                "optionsName": "r_kundenreklamationGridOptions",
                "optionsNameFQ": "r_kundenreklamationGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_bestellungPopup",
            "options": {
                "optionsName": "r_bestellungPopupOptions",
                "optionsNameFQ": "r_bestellungPopupOptions"
            },
            "caption": "mail-import-item-edit.r_bestellungpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_bestNummer",
                "caption": "mail-import-item-edit.r_bestnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_bestDatum",
                "caption": "mail-import-item-edit.r_bestdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_bestProjekt",
                "caption": "mail-import-item-edit.r_bestprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_bestLieferantNr",
                "caption": "mail-import-item-edit.r_bestlieferantnr_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "90px"
            }, {
                "id": "r_bestFirmenbez1",
                "caption": "mail-import-item-edit.r_bestfirmenbez1_caption",
                "bindTo": "LieferantAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_bestStatus",
                "caption": "mail-import-item-edit.r_beststatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bestellungGridToolbarOptions",
                "optionsNameFQ": "r_bestellungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Bestellung"
            },
            "dataModel": "$m_Bestellung",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_bestellungPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bestellungGrid",
            "options": {
                "optionsName": "r_bestellungGridOptions",
                "optionsNameFQ": "r_bestellungGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_eingangsrechnungPopup",
            "options": {
                "optionsName": "r_eingangsrechnungPopupOptions",
                "optionsNameFQ": "r_eingangsrechnungPopupOptions"
            },
            "caption": "mail-import-item-edit.r_eingangsrechnungpopup_caption",
            "height": "800px",
            "width": "1300px",
            "commands": []
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_eingrNummer",
                "caption": "mail-import-item-edit.r_eingrnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_eingrDatum",
                "caption": "mail-import-item-edit.r_eingrdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_eingrRENummer",
                "caption": "mail-import-item-edit.r_eingrrenummer_caption",
                "bindTo": "LieferantRechnungNr",
                "width": "80px"
            }, {
                "id": "r_eingrREDatum",
                "caption": "mail-import-item-edit.r_eingrredatum_caption",
                "bindTo": "LieferantRechnungsdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_eingrProjekt",
                "caption": "mail-import-item-edit.r_eingrprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_eingrLieferantNr",
                "caption": "mail-import-item-edit.r_eingrlieferantnr_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "90px"
            }, {
                "id": "r_eingrFirmenbez1",
                "caption": "mail-import-item-edit.r_eingrfirmenbez1_caption",
                "bindTo": "LieferantAdresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_eingrStatus",
                "caption": "mail-import-item-edit.r_eingrstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_eingangsrechnungGridToolbarOptions",
                "optionsNameFQ": "r_eingangsrechnungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Eingangsrechnung"
            },
            "dataModel": "$m_Eingangsrechnung",
            "onItemClick": "functions.$f_MailImportItemEditUtils.onVerknuepfenItemClicked('r_eingangsrechnungPopup', $event)",
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_eingangsrechnungGrid",
            "options": {
                "optionsName": "r_eingangsrechnungGridOptions",
                "optionsNameFQ": "r_eingangsrechnungGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}