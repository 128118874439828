export enum BelegImportTyp {
  Eingangsrechnung = 0,
  EingangsrechnungDokument = 1,
  QRDokument = 2,
  Dokument = 3,
  Angebot = 4,
  Auftrag = 5,
  Bestellung = 6,
  Artikel = 7,
  Faktura = 9
}
