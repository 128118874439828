import { autoinject, bindable } from "aurelia-framework";
import { GlobalizationService } from "../../../../../framework/base/services/globalization-service";

@autoinject
export class HealthChecks {
  constructor(
    private _globalizationService: GlobalizationService
  ) {}
  
  @bindable status;

  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      caption: "Host",
      dataField: "Hostname",
      width: 120
    }, {
      caption: "Name",
      dataField: "Name",
      width: 240
    }, {
      caption: "Prüfdatum",
      dataField: "CheckDate",
      format: this._globalizationService.getFormatter("g"),
      width: 120
    }, {
      caption: "Info",
      dataField: "Info"
    }, {
      caption: "Status",
      dataField: "State",
      width: 120,
      calculateCellValue: (row) => {
        switch (row.State) {
          case 0: {
            return "OK";
          }
          case 1: {
            return "Kritisch";
          }
          default: {
            return "Fehler";
          }
        }
      }
    }],
    allowColumnReordering: true,
    allowColumnResizing: true,
    filterRow: {
      visible: true
    },
    headerFilter: {
      visible: true
    },
    bindingOptions: {
      "dataSource": "status"
    }
  }
}
