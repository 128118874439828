import {
  autoinject
} from "aurelia-framework";
import {
  ProdAuftragSearchResult
} from "../elements/prod-auftrag-search-result/prod-auftrag-search-result";
import {
  ISearchOptions
} from "../interfaces/export";

@autoinject
export class ProdAuftragSearchService {
  constructor() { }

  prodAuftragSearchResult: ProdAuftragSearchResult;

  show(callbackSuccess: { (idProjekt): void }, callbackCancel: { (): void }, options?: ISearchOptions) {
    this.prodAuftragSearchResult.showPopup(callbackSuccess, callbackCancel, options)
  }
}