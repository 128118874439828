import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class ProjektListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "projekt-list";
        this.title = "projekt-list.projekt-list_caption";
        this.addModel({
            "id": "$m_Projekt",
            "webApiAction": "ERP/Stammdaten/Projekt",
            "webApiExpand": {
                'Projektart': null,
                'Status': null,
                'Sachbearbeiter': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_projektGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_proNummer",
                "caption": "projekt-list.r_pronummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_proProjektart",
                "caption": "projekt-list.r_proprojektart_caption",
                "bindTo": "Projektart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_proBezeichnung",
                "caption": "projekt-list.r_probezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_proKunde",
                "caption": "projekt-list.r_prokunde_caption",
                "bindTo": "Kunde",
                "minWidth": 100
            }, {
                "id": "r_proSachbearbeiter",
                "caption": "projekt-list.r_prosachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_proStatus",
                "caption": "projekt-list.r_prostatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_projektGridToolbarOptions",
                "optionsNameFQ": "r_projektGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Projekt"
            },
            "dataModel": "$m_Projekt",
            "editUrl": "ERP/Stammdaten/Projekt",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_projektGrid",
            "options": {
                "optionsName": "r_projektGridOptions",
                "optionsNameFQ": "r_projektGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}