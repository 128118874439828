import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    LieferscheinpositionEditUtils
} from "./lieferscheinposition-edit-utils";

@fwx.autoinject
export class LieferscheinpositionEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_LieferscheinpositionEditUtils: LieferscheinpositionEditUtils) {
        super(element, formBaseImport);
        this.id = "lieferscheinposition-edit";
        this.title = "lieferscheinposition-edit.lieferscheinposition-edit_caption";
        this.addModel({
            "id": "$m_Lieferscheinposition",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "webApiExpand": {
                'Auftragsposition': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_Lieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Lieferscheinposition.IdLieferschein",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_LieferscheingruppeEdit",
            "webApiAction": "ERP/Verkauf/Lieferscheingruppe",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_LieferscheinpositionStuecklisteRel",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "webApiExpand": {
                'Artikel': null,
                'KalkulationContainer': null,
                'Lieferscheingruppe': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'Status': null,
                'Positionsart': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "allowNew": "false",
            "filters": []
        });
        this.addModel({
            "id": "$m_LieferscheinpositionStuecklisteEdit",
            "webApiAction": "ERP/Verkauf/Lieferscheinposition",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_LieferscheinpositionEditUtils.showVariablerSetartikelPreisAendernCommand",
                "bindToFQ": "functions.$f_LieferscheinpositionEditUtils.showVariablerSetartikelPreisAendernCommand"
            }
        });
        this.addEditPopup({
            "idContent": "lieferscheinpositionEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_LieferscheinpositionStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_LieferscheinpositionStuecklisteEdit.Id"
                }
            }],
            "id": "lieferscheinpositionEditPopup",
            "options": {
                "optionsName": "lieferscheinpositionEditPopupOptions",
                "optionsNameFQ": "lieferscheinpositionEditPopupOptions"
            },
            "caption": "lieferscheinposition-edit.lieferscheinpositioneditpopup_caption",
            "width": "85%",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "lieferscheingruppeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_LieferscheingruppeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_LieferscheingruppeEdit.Id"
                }
            }],
            "id": "lieferscheingruppeEditPopup",
            "options": {
                "optionsName": "lieferscheingruppeEditPopupOptions",
                "optionsNameFQ": "lieferscheingruppeEditPopupOptions"
            },
            "caption": "lieferscheinposition-edit.lieferscheingruppeeditpopup_caption",
            "height": "500px",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_LieferscheinpositionEditUtils", $f_LieferscheinpositionEditUtils, "functions.$f_LieferscheinpositionEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "lieferscheinposition-edit.r_mengesollverpeh_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "MengeSollVerpEH",
                "bindToFQ": "models.data.$m_Lieferscheinposition.MengeSollVerpEH"
            },
            "validationRules": [],
            "id": "r_mengeSollVerpEH",
            "options": {
                "optionsName": "r_mengeSollVerpEHOptions",
                "optionsNameFQ": "r_mengeSollVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": [
                ["IdArtikel", {
                    "isBound": true,
                    "expression": "models.data.$m_Lieferscheinposition.IdArtikel"
                }],
                ["IsVerkaufseinheit", true]
            ],
            "filters": [],
            "caption": "lieferscheinposition-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_Lieferscheinposition.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_LieferscheinpositionEditUtils.onReloadStuecklistenkomponentenChanged",
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_liefpPosNr",
                "caption": "lieferscheinposition-edit.r_liefpposnr_caption",
                "bindTo": "PosNr",
                "width": "40px",
                "format": "n0"
            }, {
                "id": "r_liefpArtikelNr",
                "caption": "lieferscheinposition-edit.r_liefpartikelnr_caption",
                "bindTo": "Artikel.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefpBezeichnung",
                "caption": "lieferscheinposition-edit.r_liefpbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_liefpMengeSollVerpEH",
                "caption": "lieferscheinposition-edit.r_liefpmengesollverpeh_caption",
                "bindTo": "MengeSollVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpMengeIstVerpEH",
                "caption": "lieferscheinposition-edit.r_liefpmengeistverpeh_caption",
                "bindTo": "MengeIstVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpEinheit",
                "caption": "lieferscheinposition-edit.r_liefpeinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_liefpWertNetto",
                "caption": "lieferscheinposition-edit.r_liefpwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpWertSteuer",
                "caption": "lieferscheinposition-edit.r_liefpwertsteuer_caption",
                "bindTo": "KalkulationContainer.WertSteuer",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefpWertBrutto",
                "caption": "lieferscheinposition-edit.r_liefpwertbrutto_caption",
                "bindTo": "KalkulationContainer.WertBrutto",
                "width": "80px",
                "format": "n2"
            }],
            "optionsToolbar": {
                "optionsName": "r_lieferscheinpositionStuecklistenGridToolbarOptions",
                "optionsNameFQ": "r_lieferscheinpositionStuecklistenGridToolbarOptions"
            },
            "caption": "lieferscheinposition-edit.r_lieferscheinpositionstuecklistengrid_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_LieferscheinpositionStuecklisteRel",
                "bindTo": "IdSetartikelLieferscheinposition",
                "bindToFQ": "models.data.$m_LieferscheinpositionStuecklisteRel.IdSetartikelLieferscheinposition"
            },
            "dataModel": "$m_Lieferscheinposition",
            "editDataContext": "$m_LieferscheinpositionStuecklisteEdit",
            "idEditPopup": "lieferscheinpositionEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferscheinpositionStuecklistenGrid",
            "options": {
                "optionsName": "r_lieferscheinpositionStuecklistenGridOptions",
                "optionsNameFQ": "r_lieferscheinpositionStuecklistenGridOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lieferscheingruppe",
            "filter": ["IdLieferschein", {
                "isBound": true,
                "expression": "models.data.$m_Lieferscheinposition.IdLieferschein"
            }],
            "filters": [],
            "caption": "lieferscheinposition-edit.r_lieferscheingruppe_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "IdLieferscheingruppe",
                "bindToFQ": "models.data.$m_Lieferscheinposition.IdLieferscheingruppe"
            },
            "validationRules": [],
            "onValueChanged": "functions.$f_LieferscheinpositionEditUtils.onLieferscheingruppeChanged",
            "id": "r_lieferscheingruppe",
            "options": {
                "optionsName": "r_lieferscheingruppeOptions",
                "optionsNameFQ": "r_lieferscheingruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferscheinposition-edit.r_posnr_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "PosNr",
                "bindToFQ": "models.data.$m_Lieferscheinposition.PosNr"
            },
            "validationRules": [],
            "id": "r_posNr",
            "options": {
                "optionsName": "r_posNrOptions",
                "optionsNameFQ": "r_posNrOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "positionsart",
            "filters": [],
            "caption": "lieferscheinposition-edit.r_positionsart_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "IdPositionsart",
                "bindToFQ": "models.data.$m_Lieferscheinposition.IdPositionsart"
            },
            "validationRules": [],
            "id": "r_positionsart",
            "options": {
                "optionsName": "r_positionsartOptions",
                "optionsNameFQ": "r_positionsartOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "lagerVerkauf",
            "filters": [],
            "caption": "lieferscheinposition-edit.r_lieferlager_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "IdLieferlager",
                "bindToFQ": "models.data.$m_Lieferscheinposition.IdLieferlager"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_lieferlager",
            "options": {
                "optionsName": "r_lieferlagerOptions",
                "optionsNameFQ": "r_lieferlagerOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferscheinposition-edit.r_gewichtnetto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "GewichtNetto",
                "bindToFQ": "models.data.$m_Lieferscheinposition.GewichtNetto"
            },
            "validationRules": [],
            "id": "r_gewichtNetto",
            "options": {
                "optionsName": "r_gewichtNettoOptions",
                "optionsNameFQ": "r_gewichtNettoOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferscheinposition-edit.r_gewichtverpackung_caption",
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "GewichtVerpackung",
                "bindToFQ": "models.data.$m_Lieferscheinposition.GewichtVerpackung"
            },
            "validationRules": [],
            "id": "r_gewichtVerpackung",
            "options": {
                "optionsName": "r_gewichtVerpackungOptions",
                "optionsNameFQ": "r_gewichtVerpackungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n0",
            "caption": "lieferscheinposition-edit.r_gewichtbrutto_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Lieferscheinposition",
                "bindTo": "GewichtBrutto",
                "bindToFQ": "models.data.$m_Lieferscheinposition.GewichtBrutto"
            },
            "validationRules": [],
            "id": "r_gewichtBrutto",
            "options": {
                "optionsName": "r_gewichtBruttoOptions",
                "optionsNameFQ": "r_gewichtBruttoOptions"
            }
        });
        super.onConstructionFinished();
    }
}