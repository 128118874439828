import { autoinject, bindable, observable, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../../framework/base/export";
import { LocationService } from "../../../../framework/base/services/location-service";
import { EntitaetInfoService } from "../../../services/export";
import { FakturaService } from "../../../services/faktura-service";

@autoinject
export class OffenePosten {
  constructor(
    private fakturaService: FakturaService,
    private entitaetInfoService: EntitaetInfoService,
    private locationService: LocationService) { }

  @bindable @observable idGeschaeftspartner: number;

  isVisible: boolean = false;
  itemList: any[] = [];
  betragOffenGesamt: number;
  scopeContainer: ScopeContainer;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idGeschaeftspartnerChanged() {
    this.loadData();
  }

  async onFakturaClick(item) {
    const getOptions = this.fakturaService.getOffenePostenOptions(this.idGeschaeftspartner);

    const viewScrollInfo = await this.entitaetInfoService.createViewScrollInfo(
      "TIP.ERP.Business.Entitaeten.Verkauf.Faktura",
      getOptions.where,
      null,
      getOptions.orderBy,
      item.Id
    );

    this.locationService.goTo({
      url: `ERP/Verkauf/Faktura/${item.Id}`,
      viewScrollInfo: viewScrollInfo
    });
  }

  private async loadData() {
    if (this.idGeschaeftspartner) {
      //TODO - Aufsplittung auf verschiedene Währungen wird vorerst ignoriert, da aktuell keine Kunden in verschiedenen Währungen verrechnen
      this.itemList = await this.fakturaService.getOffenePosten(this.idGeschaeftspartner);
      this.betragOffenGesamt = this.itemList.reduce((p, c) => p + c.BetragOffen, 0);
    } else {
      this.itemList = [];
      this.betragOffenGesamt = 0;
    }

    this.isVisible = this.itemList && this.itemList.length > 0;
  }
}
