import {
  autoinject,
  valueConverter
} from "aurelia-framework";
import { FileService } from '../../services/file-service';

@autoinject
@valueConverter("fileDownload")
export class FileDownloadValueConverter{
  constructor(
    private fileService: FileService
  ) {}

  toView(value: string) {
    if (!value) {
      return "";
    }

    if (value.startsWith("http:") || value.startsWith("https:")) {
      return value;
    }

    return this.fileService.getDownloadUrl(value);
  }
}
