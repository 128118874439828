import { autoinject } from 'aurelia-framework';
import { StatistikVerkaufWertTyp } from '../../../../framework-data/events';
import { RestService } from '../../../../framework/base/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';

@autoinject
export class UmsatzMonatSettings {
  constructor(
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IUmsatzMonatSettings;
  firmaDataSource: any[];
  dataSource: any[] = [];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    },
    onValueChangedByUser: () => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  };

  doShowNettoMitForecaseCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Umsatz mit Forecast anzeigen",
    bindingOptions: {
      value: "settings.showNettoMitForecast"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }
  doShowAuftragseingangCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Auftragseingang anzeigen",
    bindingOptions: {
      value: "settings.showAuftragseingang"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }
  doShowNettoCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Umsatz anzeigen",
    bindingOptions: {
      value: "settings.showNetto"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }
  doShowEinsatzCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Einsatz anzeigen",
    bindingOptions: {
      value: "settings.showEinsatz"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }
  doShowDBCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "DB anzeigen",
    bindingOptions: {
      value: "settings.showDB"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }

  selectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    bindingOptions: {
      dataSource: "dataSource",
      value: "settings.standard",
    }
  };

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.createDataSource();
    this.setValueSelectBox();
    this.loadFirmaDataSource();
  }

  private createDataSource() {
    const dataSource = [];

    if (this.settings.showNettoMitForecast) {
      dataSource.push({
        Bezeichnung: "Umsatz mit Forecast",
        Value: StatistikVerkaufWertTyp.NettoMitForecast
      });
    }
    if (this.settings.showAuftragseingang) {
      dataSource.push({
        Bezeichnung: "Auftragseingang",
        Value: StatistikVerkaufWertTyp.Auftragseingang
      });
    }
    if (this.settings.showNetto) {
      dataSource.push({
        Bezeichnung: "Umsatz",
        Value: StatistikVerkaufWertTyp.Netto
      });
    }
    if (this.settings.showEinsatz) {
      dataSource.push({
        Bezeichnung: "Einsatz",
        Value: StatistikVerkaufWertTyp.Einsatz
      });
    }
    if (this.settings.showDB) {
      dataSource.push({
        Bezeichnung: "DB",
        Value: StatistikVerkaufWertTyp.DB
      });
    }

    this.dataSource = dataSource;
  }

  private setValueSelectBox() {
    if (this.dataSource.some(i => i.Value == this.settings.standard)) {
      return;
    }

    if (this.dataSource.length == 0) {
      this.settings.standard = null;
    } else {
      this.settings.standard = this.dataSource[0].Value;
    }
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      }
    });
  }
}

export interface IUmsatzMonatSettings {
  showNettoMitForecast?: boolean;
  showAuftragseingang?: boolean;
  showNetto?: boolean;
  showEinsatz?: boolean;
  showDB?: boolean;
  idFirma?: number;

  standard?: StatistikVerkaufWertTyp;
}
