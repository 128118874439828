import { GlobalizationService } from './../../base/services/globalization-service';
import { autoinject, computedFrom } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { LayoutService } from './layout-service';
import { DxWidget } from '../../dx/elements/dx-widget';
import { LocalizationService, IDataSourceCustomizationOptions } from '../../base/export';
import { FormBase } from '../form-export';
import { GridLayoutColumns } from '../elements/grid-layout-columns/grid-layout-columns';

@autoinject
export class GridLayoutService {
  constructor(
    private eventAggregator: EventAggregator,
    private layoutService: LayoutService,
    private globalizationService: GlobalizationService,
    private localizationService: LocalizationService
  ) {
    this.eventAggregator.subscribe("data-grid:customize-data-source", e => {
      const gridOptions: DevExpress.ui.dxDataGridOptions = e.dataGridOptions;
      const modelInfo = this.getMainModelFromCustomizeEvent(e);

      const r = this.addGridColumnExtender({
        gridOptions: gridOptions,
        webApiAction: modelInfo ? modelInfo.webApiAction : null,
        viewUrl: e.form.viewUrl,
        id: e.options.id
      });

      const customizationOptions: IDataSourceCustomizationOptions = e.customizationOptions;
      customizationOptions.getCustomFilters = () => {
        const columns = r.getDynamicColumns();
        if (columns.length === 0) {
          return null;
        }

        return [{
          webApiCustomKey: "ExtendedLoadProperties",
          webApiCustomValue: JSON.stringify(columns)
        }];
      };
    });
    this.eventAggregator.subscribe("dx-widget:attaching", e => {
      const widget: DxWidget = e.widget;
      if (widget.name != "dxDataGrid" || !widget.id || !widget.parentViewUrl) {
        return;
      }

      const gridOptions: DevExpress.ui.dxDataGridOptions = e.options;

      gridOptions.allowColumnReordering = gridOptions.allowColumnReordering == void(0)
        ? true
        : gridOptions.allowColumnReordering;

      gridOptions.allowColumnResizing = gridOptions.allowColumnResizing == void(0)
        ? true
        : gridOptions.allowColumnResizing;

      const layout = this.layoutService.getLayout(this.GRID_LAYOUT, widget.parentViewUrl, widget.id);
      if (!layout) {
        return;
      }

      this.assignLayoutToColumnsInt(layout.columns, <DevExpress.ui.dxDataGridColumn[]>gridOptions.columns);
    });
    this.eventAggregator.subscribe("dx-widget:attached", e => {
      const widget: DxWidget = e.widget;
      if (widget.name != "dxDataGrid" || !widget.id || !widget.parentViewUrl) {
        return;
      }

      const instance = e.instance;
      instance.on("contextMenuPreparing", c => {
        if (!c || !c.row || c.row.rowType != "header") {
          return;
        }

        this.loadContexMenuItems(widget, c);
      });
    });
  }

  gridLayoutColumnsElement: GridLayoutColumns;

  get GRID_LAYOUT() {
    return "grid-layout";
  }
  get GRID_COLUMNS() {
    return "grid-columns";
  }

  addGridColumnExtender(options: IGridColumnExtenderOptions): IGridColumnExtenderResult {
    const dynColumns: any[] = this.layoutService.getLayout(this.GRID_COLUMNS, options.viewUrl, options.id);
    if (dynColumns && dynColumns.length) {
      this.setDynColumns(options.gridOptions, dynColumns)
    }

    options.gridOptions.onContextMenuPreparing = (c) => {
      if (!c || !c.row || c.row.rowType != "header") {
        return;
      }
      if (!options.viewUrl || !options.id) {
        return;
      }
      if (!options.webApiAction) {
        return;
      }

      const insertAt = c.items.findIndex(c => c.beginGroup);
      c.items[insertAt].beginGroup = false;
      c.items.splice(insertAt, 0, {
        text: this.localizationService.translateOnce("forms.layout_spalten_hinzufuegen"),
        icon: "fa fa-plus fa-fw",
        beginGroup: true,
        onItemClick: () => {
          const component = <DevExpress.ui.dxDataGrid>c.component;

          this.gridLayoutColumnsElement.showPopup({
            webApiAction: options.webApiAction,
            formViewUrl: options.viewUrl,
            idGrid: options.id,
            gridInstance: component,
            onColumnsAdded: () => {
              if (options.onColumnsAdded) {
                options.onColumnsAdded();
              } else {
                component.refresh();
              }
            }
          });
        }
      });
    };

    return {
      getDynamicColumns: () => {
        const layout = this.layoutService.getLayout(this.GRID_COLUMNS, options.viewUrl, options.id);
        if (!layout) {
          return [];
        }

        return layout.map(c => c.dataField);
      }
    };
  }

  assignLayoutToColumns(dataGridWidget: DxWidget, columns: DevExpress.ui.dxDataGridColumn[]) {
    if (dataGridWidget.name != "dxDataGrid" || !dataGridWidget.id || !dataGridWidget.parentViewUrl) {
      return;
    }
    
    const layout = this.layoutService.getLayout(this.GRID_LAYOUT, dataGridWidget.parentViewUrl, dataGridWidget.id);
    if (!layout) {
      return;
    }

    this.assignLayoutToColumnsInt(layout.columns, columns);
  }
  
  loadLayout(dataGridWidget: DxWidget) {
    if (dataGridWidget.name != "dxDataGrid" || !dataGridWidget.id || !dataGridWidget.parentViewUrl) {
      return;
    }
    
    const layout = this.layoutService.getLayout(this.GRID_LAYOUT, dataGridWidget.parentViewUrl, dataGridWidget.id);
    if (!layout) {
      return;
    }

    const dataGrid: DevExpress.ui.dxDataGrid = dataGridWidget.instance;
    
    const columns = dataGrid.option("columns");
    this.assignLayoutToColumnsInt(layout.columns, columns);

    dataGrid.option("columns", columns);
  }

  private getMainModelFromCustomizeEvent(e) {
    const options = e.options;

    const model = options.dataModel;
    const relationModel = options.isRelation
      ? options.relationBinding.dataContext
      : null;
    const mainModel: string = relationModel || model;

    const form: FormBase = e.form;
    return form.models.getInfo(mainModel);
  }

  private getColumnsLayout(dataGrid: DevExpress.ui.dxDataGrid): DevExpress.ui.dxDataGridColumn[] {
    const layout: DevExpress.ui.dxDataGridColumn[] = [];

    dataGrid.getVisibleColumns().forEach(c => {
      layout.push({
        dataField: c.dataField,
        visibleIndex: c.visibleIndex,
        width: c.width,
        minWidth: c.minWidth,
        sortIndex: c.sortIndex,
        sortOrder: c.sortOrder
      });
    });

    return layout;
  }
  private assignLayoutToColumnsInt(layoutColumns: DevExpress.ui.dxDataGridColumn[], columns: DevExpress.ui.dxDataGridColumn[]) {
    if (!columns) {
      return;
    }

    layoutColumns.forEach(lc => {
      const c = columns.find(dc => dc.dataField === lc.dataField);
      if (!c) {
        return;
      }

      c.dataField = lc.dataField;
      c.visibleIndex = lc.visibleIndex;
      c.visible = true;
      c.width = lc.width;
      c.minWidth = lc.minWidth;
      c.sortIndex = lc.sortIndex;
      c.sortOrder = lc.sortOrder;
    });

    columns
      .filter(column => !layoutColumns.some(lc => lc.dataField == column.dataField))
      .forEach(column => column.visible = false);
  }
  private setDynColumns(dataGrid: DevExpress.ui.dxDataGridOptions, layoutColumns: DevExpress.ui.dxDataGridColumn[]) {
    layoutColumns.forEach(c => {
      const newColumn = Object.assign({}, c);

      if (c.format) {
        newColumn.format = this.globalizationService.getFormatterParser(<string>c.format);
      }

      dataGrid.columns.push(newColumn);
    });
  }

  private loadContexMenuItems(widget: DxWidget, ev: any) {
    const canDeleteLayout = this.layoutService.getLayout(this.GRID_LAYOUT, widget.parentViewUrl, widget.id) != void 0
      ? true
      : false;

    ev.items = ev.items || [];

    ev.items.push({
      text: this.localizationService.translateOnce("forms.layout_spalten_auswahl"),
      icon: "fa fa-columns fa-fw",
      beginGroup: true,
      onItemClick: () => {
        const grid: DevExpress.ui.dxDataGrid = ev.component;

        grid.showColumnChooser();
      }
    });

    ev.items.push({
      text: this.localizationService.translateOnce("forms.layout_speichern"),
      icon: "fa fa-floppy-o fa-fw",
      onItemClick: () => {
        const layout = {
          columns: this.getColumnsLayout(ev.component)
        };

        this.layoutService.saveLayout(this.GRID_LAYOUT, widget.parentViewUrl, widget.id, layout);
      }
    });

    if (canDeleteLayout) {
      ev.items.push({
        text: this.localizationService.translateOnce("forms.layout_loeschen"),
        icon: "fa fa-times fa-fw",
        onItemClick: () => {
          this.layoutService.deleteLayout(this.GRID_LAYOUT, widget.parentViewUrl, widget.id);
        }
      });
    }
  }
}

export interface IGridColumnExtenderOptions {
  gridOptions: DevExpress.ui.dxDataGridOptions;
  webApiAction: string;
  viewUrl: string;
  id: string;
  onColumnsAdded?: {(): void}
}
export interface IGridColumnExtenderResult {
  getDynamicColumns: {(): string[]};
}
