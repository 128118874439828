import { StartupService } from "./../../../services/startup-service";
import { autoinject, transient } from "aurelia-framework";
import { AsyncService } from "../../../../framework/base/services/async-service";
import { FormBase } from "../../../../framework/forms/classes/form-base";
import { ICommandData } from "../../../../framework/forms/interfaces/command-data";
import { BelegImportTyp } from "../../../enumerations/beleg-import-typ";
import { IdxDataGridComponent } from "../../../interfaces/export";
import { BelegExportService } from "./../../../services/beleg-export-service";

@autoinject
@transient()
export class AngebotListUtils {
  constructor(
    public startupService: StartupService,
    private _asyncService: AsyncService,
    private _belegExportService: BelegExportService
  ) {}
  
  form: FormBase;
  isBelegExportMandantAktiv: boolean;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    isVisibleExpression: "functions.$f_AngebotListUtils.startupService.startupInfo.Lizenz.HasBelegImportExport && functions.$f_AngebotListUtils.isBelegExportMandantAktiv",
    title: "erp.beleg-export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_angebotGrid"];
      await this._asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this._asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this._belegExportService.export(BelegImportTyp.Angebot, keys);
    }
  };

  bind(form: FormBase) {
    this.form = form;

    this.form.onAttached.register(async (r) => {
      this.isBelegExportMandantAktiv = await this._belegExportService.isBelegExportMandantAktiv(BelegImportTyp.Angebot);
      return Promise.resolve();
    });
  }
}
