import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class FibuKontogruppeListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "fibu-kontogruppe-list";
        this.title = "fibu-kontogruppe-list.fibu-kontogruppe-list_caption";
        this.addModel({
            "id": "$m_FibuKontogruppe",
            "webApiAction": "ERP/Fibu/Kontogruppe",
            "webApiExpand": {
                'Firma': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true
            },
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fibuKgBezeichnung",
                "caption": "fibu-kontogruppe-list.r_fibukgbezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fibuKgKontoTyp",
                "caption": "fibu-kontogruppe-list.r_fibukgkontotyp_caption",
                "bindTo": "KontoTyp",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.FibuKontoTyp"
            }],
            "optionsToolbar": {
                "optionsName": "r_fibuKontogruppeGridToolbarOptions",
                "optionsNameFQ": "r_fibuKontogruppeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_FibuKontogruppe"
            },
            "dataModel": "$m_FibuKontogruppe",
            "editUrl": "ERP/Fibu/Kontogruppe",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fibuKontogruppeGrid",
            "options": {
                "optionsName": "r_fibuKontogruppeGridOptions",
                "optionsNameFQ": "r_fibuKontogruppeGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}