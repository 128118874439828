import { autoinject } from 'aurelia-framework';
import { StatistikVerkaufZeitraumTyp } from '../../../../framework-data/events';
import { RestService } from '../../../../framework/base/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';

@autoinject
export class UmsatzLandSettings {
  constructor(
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IUmsatzLandSettings;
  firmaDataSource: any[];
  dataSource: any[] = [];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    },
    onValueChangedByUser: () => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  };

  doShowNettoYTDCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Umsatz YTD",
    bindingOptions: {
      value: "settings.showNettoYTD"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }

  doShowNettoJahrCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Umsatz Jahr",
    bindingOptions: {
      value: "settings.showNettoJahr"
    },
    onValueChangedByUser: (e) => {
      this.createDataSource();
      this.setValueSelectBox();
    }
  }

  selectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Value",
    bindingOptions: {
      dataSource: "dataSource",
      value: "settings.standard",
    }
  }

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.createDataSource();
    this.setValueSelectBox();
    this.loadFirmaDataSource();
  }

  private createDataSource() {
    const dataSource = [];

    if (this.settings.showNettoYTD) {
      dataSource.push({
        Bezeichnung: "YTD",
        Value: StatistikVerkaufZeitraumTyp.YTD
      });
    }
    if (this.settings.showNettoJahr) {
      dataSource.push({
        Bezeichnung: "Jahr",
        Value: StatistikVerkaufZeitraumTyp.Jahr
      });
    }

    this.dataSource = dataSource;
  }

  private setValueSelectBox() {
    if (this.dataSource.some(i => i.Value == this.settings.standard)) {
      return;
    }

    if (this.dataSource.length == 0) {
      this.settings.standard = null;
    } else {
      this.settings.standard = this.dataSource[0].Value;
    }
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      }
    });
  }
}
export interface IUmsatzLandSettings {
  showNettoYTD?: boolean;
  showNettoJahr?: boolean;
  idFirma?: number;

  standard?: StatistikVerkaufZeitraumTyp;
}
