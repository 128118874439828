import { autoinject, bindable, observable, OverrideContext } from "aurelia-framework";
import { DataSourceService, LocalizationService, LocationService, ScopeContainer, WebEventService } from "../../../../framework/base/export";
import { IListViewOptions, ListView } from "../../../../framework/forms/elements/list-view/export";
import { PopupInfoService } from "../../../../framework/forms/export";
import { IdxSelectBoxComponent } from '../../../interfaces/export';
import { EntitaetInfoService } from "../../../services/entitaet-info-service";
import { OnCreateLogging, OnCreateLoggingEvent } from "../../../../framework-data/events";

@autoinject
export class LetzteAktivitaeten {
  private _currentTypeFilter: string;

  constructor(
    private _dataSourceService: DataSourceService,
    private _entitaetInfoService: EntitaetInfoService,
    private _locationService: LocationService,
    private _popupInfoService: PopupInfoService,
    private _localizationService: LocalizationService,
    private _webEventService: WebEventService) { }

  @bindable @observable idGeschaeftspartner;
  @bindable @observable idArtikel;
  @observable showLetzteAktivitaeten: boolean;

  scopeContainer: ScopeContainer;


  letzteAktivitaetenLadenButtonOptions: DevExpress.ui.dxButtonOptions = {
    text: "Letzte Aktivitäten laden",
    onClick: (e) => {
      this.showLetzteAktivitaeten = true;

      const args: OnCreateLogging = {
        Beschreibung: "Letzte Aktivitäten Button geklickt",
        Ausgangspunkt: this.scopeContainer.scope.bindingContext.form.id
      };

      this._webEventService.execute(new OnCreateLoggingEvent(args));
    }
  };

  typeFilterSelectBox: IdxSelectBoxComponent;
  typeFilterSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "TypeName",
    placeholder: this._localizationService.translateOnce("infobar.filter_auswaehlen"),
    showClearButton: true,
    items: [
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Angebot"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Auftrag"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Lieferschein"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Faktura"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Zahlung"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Mahnung"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Verkauf.Kundenreklamation"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Kassen.KassaBeleg"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Einkauf.Bestellung"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahme"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnung"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Lager.Artikelverleih"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Produktion.ProdAuftrag"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Stammdaten.MailImportItem"),
      this.getTypeInfo("TIP.ERP.Business.Entitaeten.Stammdaten.BriefZuEmpfaenger")
    ],
    onValueChangedByUser: (e) => {
      this._currentTypeFilter = e.value;
      this.refreshListView(false);
    }
  };

  letzteAktivitaetenListView: ListView;
  letzteAktivitaetenListViewOptions: IListViewOptions = {
    showReloadButton: false,
    showPagerInfo: false,
    pageSize: 5,
    hoverStateEnabled: true,
    onItemClick: (e) => {
      this._entitaetInfoService
        .getUrl(e.item.TypeName, e.item.Id)
        .then(r => {
          this._popupInfoService.closeAllPopups().then(async pr => {
            if (!pr) {
              return;
            }

            const viewScrollInfo = await this.getViewScrollInfo(e);

            this._locationService.goTo({
              url: r,
              viewScrollInfo: viewScrollInfo
            });
          });
        });
    }
  };

  isVisible: boolean = false;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });

    this.showLetzteAktivitaeten = false;
    this.isVisible = true;

    this.letzteAktivitaetenListViewOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer, {
      webApiAction: "ERP/Stammdaten/Entitaet",
      webApiColumns: ["Id", "TypeName", "CboBezeichnung"],
      filters: [
        { webApiCustomKey: "InklIdStatusFarbe", webApiCustomValue: "true" },
        { webApiCustomKey: "AddCboBezeichnung", webApiCustomValue: "true" },
        { webApiCustomKey: "AddCboGeschaeftspartner", webApiCustomValue: "true" },
        { webApiCustomKey: "AddCboArtikel", webApiCustomValue: "true" }],
      webApiOrderBy: [{ columnName: "CboBezeichnung", sortOrder: 1 }],
    }, {
      canLoad: () => {
        return this.idGeschaeftspartner
          || this.idArtikel;
      },
      getCustomWhere: () => {
        const result = [];
        
        if (this.idGeschaeftspartner) {
          result.push(["IdCboGeschaeftspartner", this.idGeschaeftspartner]);
        }
        else if (this.idArtikel) {
          result.push(["IdCboArtikel", this.idArtikel]);
        }

        if (this._currentTypeFilter) {
          result.push(this.getTypeNameFilter());
        }

        return result;
      },
      resultInterceptor: (data) => {
        this.isVisible = !!this._currentTypeFilter
          || data.count > 0;

        return data;
      }
    });
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idGeschaeftspartnerChanged() {
    this.isVisible = true;
    this.showLetzteAktivitaeten = false;
  }
  idArtikelChanged() {
    this.isVisible = true;
    this.showLetzteAktivitaeten = false;
  }

  showLetzteAktivitaetenChanged() {
    if (!this.showLetzteAktivitaeten) {
      return;
    }
    this.refreshListView();
  }

  private getTypeInfo(typeName: string): any {
    const info = this._entitaetInfoService.getEntitaetInfoByTypeName(typeName);

    return {
      TypeName: info.FullName,
      Bezeichnung: info.Bezeichnung
    };
  }
  private getTypeNameFilter(): any {
    if (!this._currentTypeFilter) {
      return;
    }

    const arr = [this._currentTypeFilter];

    switch (this._currentTypeFilter) {
      case "TIP.ERP.Business.Entitaeten.Verkauf.Angebot": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Angebotsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Auftrag": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Auftragsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Lieferschein": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Lieferscheinposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Faktura": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Fakturaposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Mahnung": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Mahnungsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Verkauf.Kundenreklamation": {
        arr.push("TIP.ERP.Business.Entitaeten.Verkauf.Kundenreklamationsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Bestellung": {
        arr.push("TIP.ERP.Business.Entitaeten.Einkauf.Bestellungsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahme": {
        arr.push("TIP.ERP.Business.Entitaeten.Einkauf.Warenuebernahmeposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnung": {
        arr.push("TIP.ERP.Business.Entitaeten.Einkauf.Eingangsrechnungsposition");
        break;
      }
      case "TIP.ERP.Business.Entitaeten.Kassen.KassaBeleg": {
        arr.push("TIP.ERP.Business.Entitaeten.Kassen.KassaBelegposition");
        break;
      }
      default: {
        break;
      }
    }

    const r: any = arr
      .map(i => ["TypeName", i]);

    if (r.length == 1) {
      return r[0];
    }

    for (let i = 0; i < r.length - 1; i = i + 2) {
      r.splice(i + 1, 0, "or");
    }

    return r;
  }
  private async getViewScrollInfo(e): Promise<any> {
    const id = e.item.Id;
    const typeName = e.item.TypeName;
    const orderBy = [{ columnName: "Aenderungsdatum", sortOrder: 0 }];
    const where = [];

    // if (this.idGeschaeftspartner) {
    //   where.push(["IdCboGeschaeftspartner", this.idGeschaeftspartner]);
    // }
    // else if (this.idArtikel) {
    //   where.push(["IdCboArtikel", this.idArtikel]);
    // }

    const viewScrollInfo = await this._entitaetInfoService.createViewScrollInfo(
      typeName,
      where,
      null,
      orderBy,
      id
    );

    return viewScrollInfo;
  }
  private refreshListView(clearCurrentTypeFilter: boolean = true) {
    if (clearCurrentTypeFilter) {
      this._currentTypeFilter = null;

      if (this.typeFilterSelectBox && this.typeFilterSelectBox.instance) {
        this.typeFilterSelectBox.setOption({
          value: null
        });
      }
    }

    if (this.letzteAktivitaetenListView) {
      this.letzteAktivitaetenListView.refresh(false);
    }
  }
}
