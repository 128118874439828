import { autoinject, bindable } from "aurelia-framework";
import { GlobalizationService } from "../../../../../framework/base/services/globalization-service";

@autoinject
export class ConnectedClients {
  constructor(
    private _globalizationService: GlobalizationService
  ) {}

  @bindable status;

  gridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      caption: "Datenbank",
      dataField: "DatabaseKey",
      width: 120
    }, {
      caption: "Benutzer",
      dataField: "Name"
    }, {
      caption: "Anzahl",
      dataField: "Anzahl",
      width: 100
    }],
    allowColumnReordering: true,
    allowColumnResizing: true,
    filterRow: {
      visible: true
    },
    headerFilter: {
      visible: true
    },
    bindingOptions: {
      "dataSource": "status"
    }
  }
}
