import { autoinject, bindable, observable } from "aurelia-framework";
import { WebEventService, GlobalizationService, LocationService } from '../../../framework/base/export';
import { OnArtikelverleihVerfuegbar, OnArtikelverleihVerfuegbarEvent } from '../../../framework-data/events';

import * as moment from "moment";

@autoinject
export class ArtikelverleihVerfuegbar {
  private _refreshTimeout;

  constructor(
    private _webEventService: WebEventService,
    private _globalizationService: GlobalizationService,
    private _locationService: LocationService
  ) {}

  @bindable @observable idArtikel: number;
  @bindable @observable idLager: number;
  @bindable @observable idArtikelverleih: number;
  @bindable @observable datumVon: Date;
  @bindable @observable datumBis: Date;
  @bindable menge: number;

  verfuegbarList: IVerfuegbar[];
  reserviertList: IReserviert[];

  reserviertGridOptions: DevExpress.ui.dxDataGridOptions = {
    columns: [{
      dataField: "Abholung",
      caption: "Abholung",
      format: this._globalizationService.getFormatterParser("g"),
      width: "120px"
    }, {
      dataField: "Rueckgabe",
      caption: "Rückgabe",
      format: this._globalizationService.getFormatterParser("g"),
      width: "120px"
    }, {
      dataField: "Firmenbez1",
      caption: "Firmenbez. 1",
      minWidth: 100
    }, {
      dataField: "Ort",
      caption: "Ort",
      width: "120px"
    }, {
      dataField: "MengeLagEH",
      caption: "Mge. LE",
      format: this._globalizationService.getFormatterParser("n2"),
      width: "80px"
    }, {
      dataField: "Einheit",
      caption: "Einheit",
      width: "120px"
    }],
    hoverStateEnabled: true,
    onRowPrepared: (e) => {
      if (e.rowType != "data") {
        return;
      }

      if (!e.data.IsGleichzeitig) {
        const el: any = e.rowElement;
        el.style.color = "#888";
        el.style.fontStyle = "italic";
      }
    },
    onRowClick: (e) => {
      this._locationService.goTo({
        url: "ERP/Lager/Artikelverleih/".concat(e.data.IdArtikelverleih),
        viewScrollInfo: {
          index: this.reserviertList.indexOf(e.data),
          maxCount: this.reserviertList.length,
          getNextKey: (index) => {
            return this.reserviertList[index].IdArtikelverleih;
          }
        }
      })
    },
    bindingOptions: {
      dataSource: "reserviertList"
    }
  }

  bind() {
    this.refreshNow();
  }

  idArtikelChanged() {
    this.refresh();
  }
  idLagerChanged() {
    this.refresh();
  }
  idArtikelverleihChanged() {
    this.refresh();
  }
  datumVonChanged() {
    this.refresh();
  }
  datumBisChanged() {
    this.refresh();
  }

  private refresh() {
    if (this._refreshTimeout) {
      clearTimeout(this._refreshTimeout);
      this._refreshTimeout = null;
    }

    this._refreshTimeout = setTimeout(() => {
      this.refreshNow();
    }, 100)
  }
  private async refreshNow() {
    if (!this.idArtikel || !this.idLager) {
      this.verfuegbarList = [];
      this.reserviertList = [];
      return;
    }

    const r: OnArtikelverleihVerfuegbar = await this._webEventService.execute(new OnArtikelverleihVerfuegbarEvent({
      IdArtikel: this.idArtikel,
      IdLager: this.idLager,
      IdArtikelverleih: this.idArtikelverleih || null,
      DatumVon: this.datumVon || moment().startOf("day").toDate(),
      DatumBis: this.datumBis || moment().startOf("day").add(20, "day").toDate()
    }));

    this.verfuegbarList = r.VerfuegbarList;
    this.reserviertList = r.ReserviertList;
  }
}

interface IVerfuegbar {
  Datum: Date;
  VerfuegbarLagEH: number;
}
interface IReserviert {
  IdArtikelverleih: number;
  Firmenbez1?: string;
  Ort?: string;
  MengeLagEH?: number;
  Einheit?: string;
  Abholung?: Date;
  Rueckgabe?: Date;
}