import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  ICommandData,
  FormBase
} from "../../../../framework/forms/export";
import {
  WebEventService,
  LocalizationService
} from "../../../../framework/base/export";
import {
  OnBVArtikelBestellenEvent,
  OnBVArtikelBestellen,
  OnBVArtikelIgnorierenEvent,
  OnBVArtikelIgnorieren,
  OnBVRechnenEvent,
  OnBVRechnen
} from "../../../../framework-data/events";
import {
  VerknuepfteEntitaetService
} from "../../../services/export";
import {
  IdxDataGridComponent
} from "../../../interfaces/export";
import { LocationService } from '../../../../framework/base/services/location-service';

@autoinject
@transient()
export class BestellvorschlagEditUtils {

  constructor(
    private localization: LocalizationService,
    private verknuepfteEntitaet: VerknuepfteEntitaetService,
    private webEvent: WebEventService,
    private locationService: LocationService
  ) { }

  form: FormBase;

  doBestellvorschlagRechnenCommand: ICommandData = {
    id: "doBestellvorschlagRechnenCommand",
    icon: "calculator",
    sortIndex: 900,
    idCategory: "bestellvorschlag",
    title: "bestellvorschlag.bestellvorschlag_rechnen",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnBVRechnenEvent({
        IdArtikelList: [this.form.models.data.$m_Bestellvorschlag.IdArtikel]
      });

      const webEventResult: OnBVRechnen = await this.webEvent.execute(webEvent, true);
      DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.berechn_ausführ_weitergeleitet"), "SUCCESS", 3000);

      this.form.models.reloadAll();
    }
  }
  doArtikelBestellenCommand: ICommandData = {
    id: "doArtikelBestellen",
    icon: "plus-circle",
    idCategory: "bestellvorschlag",
    isEnabledExpression: "models.data.$m_Bestellvorschlag.Status.Typ == 0",
    sortIndex: 997,
    title: "bestellvorschlag.artikel_bestellen",
    execute: async (e: any) => {
      const ctrlKeyPressed = e
        && e.event
        && e.event.ctrlKey;

      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnBVArtikelBestellenEvent({
        IdBestellvorschlagList: [this.form.models.data.$m_Bestellvorschlag.Id],
        DoAbschliessen: false
      });

      const webEventResult: OnBVArtikelBestellen = await this.webEvent.execute(webEvent, true);
      if (!webEventResult) {
        return;
      }

      let navigationUrl: string = null;

      if (webEventResult.IdBestellung) {
        navigationUrl = `ERP/Einkauf/Bestellung/${webEventResult.IdBestellung}`;
      } else if (webEventResult.IdProdAuftrag) {
        navigationUrl = `ERP/Produktion/ProdAuftrag/${webEventResult.IdProdAuftrag}`;
      } else {
        DevExpress.ui.notify(this.localization.translateOnce("bestellvorschlag.bestellung_erstellt"), "SUCCESS", 3000);
      }

      if (navigationUrl) {
        if (ctrlKeyPressed) {
          this.locationService.openWindow(navigationUrl);
          this.form.models.reloadAll;
        } else {
          this.locationService.goTo({
            url: navigationUrl,
            clearStack: false
          });
        }
      }

      this.form.models.reloadAll();
    }
  }
  doArtikelBestellenUndVersendenCommand: ICommandData = {
    id: "doArtikelBestellenAndVersenden",
    icon: "plus-circle",
    idCategory: "bestellvorschlag",
    isEnabledExpression: "models.data.$m_Bestellvorschlag.Status.Typ == 0",
    sortIndex: 998,
    title: "bestellvorschlag.artikel_bestellen_und_versenden",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnBVArtikelBestellenEvent({
        IdBestellvorschlagList: [this.form.models.data.$m_Bestellvorschlag.Id],
        DoAbschliessen: true
      });

      const webEventResult: OnBVArtikelBestellen = await this.webEvent.execute(webEvent, true);
      if (!webEventResult) {
        return;
      }

      this.form.models.reloadAll();
    }
  }
  doArtikelIgnorierenCommand: ICommandData = {
    id: "doArtikelIgnorieren",
    icon: "ban",
    idCategory: "bestellvorschlag",
    sortIndex: 999,
    title: "bestellvorschlag.artikel_ignorieren",
    isEnabledExpression: "models.data.$m_Bestellvorschlag.Status.Typ == 0",
    execute: async () => {
      const saveResult = await this.form.saveIfDirty();
      if (!saveResult.isValid) {
        return;
      }

      const webEvent = new OnBVArtikelIgnorierenEvent({
        IdBestellvorschlagList: [this.form.models.data.$m_Bestellvorschlag.Id]
      });

      const webEventResult: OnBVArtikelIgnorieren = await this.webEvent.execute(webEvent, true);

      this.locationService.goTo({
        url: "ERP/Einkauf/Bestellvorschlag",
        clearStack: true
      });
    }
  }

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }

  async onBestellvorschlagBedarfClick(e: any) {
    if (!e.data || !e.data.IdVerknuepfteEntitaet) {
      return;
    }

    this.verknuepfteEntitaet
      .goTo(e.data.IdVerknuepfteEntitaet);
  }
}
