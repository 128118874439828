import { autoinject, bindable, bindingMode, computedFrom, observable } from "aurelia-framework";
import { RestService } from "../../../framework/base/export";
import { ModelUtilsService } from "../../../framework/forms/export";
import { IdxTextBoxComponent } from "../../interfaces/export";
import { DxCustomizeService } from '../../services/dx-customize-service';
import { CustomEditPopupService, ProjektSearchService, ProjektService } from "../../services/export";


@autoinject
export class Projekt {
  constructor(
    private _customEditPopupService: CustomEditPopupService,
    private _projektService: ProjektService,
    private _projektSearchService: ProjektSearchService,
    private _modelUtilsService: ModelUtilsService,
    private _restService: RestService,
    private _dxCustomizeService: DxCustomizeService) { }

  @bindable @observable mainModel: any;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) @observable idProjekt: number;
  @bindable @observable isReadOnly: boolean;

  @computedFrom("isReadOnly", "mainModel.CanSave")
  get isReadOnlyChecked() {
    return this.isReadOnly || !this.mainModel || this.mainModel.CanSave === false;
  }

  projektNummer: IdxTextBoxComponent;
  projektNummerOptions: DevExpress.ui.dxTextBoxOptions = {
    mode: "search",
    onInitialized: (e) => {
      this.updateProjektButtons(e.component);
    },
    onValueChangedByUser: (e) => {
      if (!e.value) {
        this.setProjektById(null);
        this.resetData();
        return;
      }

      this.onProjektChanged(e.value);
    },
    bindingOptions: {
      readOnly: {
        expression: "isReadOnlyChecked",
        readOnly: true
      }
    }
  }

  projektBezeichnung: IdxTextBoxComponent;
  projektBezeichnungOptions: DevExpress.ui.dxTextBoxOptions = {
    readOnly: true,
    tabIndex: -1
  }

  mainModelChanged() {
    this.updateProjektButtons();
  }
  isReadOnlyChanged() {
    this.updateProjektButtons();
  }
  idProjektChanged(newValue) {
    if (newValue == void 0) {
      this.resetData();
      return;
    }
    this._projektService.getProjektById(newValue)
      .then(projekt => {
        this.setNummer(projekt.Nummer);
        this.setBezeichnung(projekt.Bezeichnung);
      })
  }
  onProjektChanged(searchValue: string) {
    const isValidNumber: boolean = /^[0-9]+$/.test(searchValue);

    if (isValidNumber) {
      this._projektService.getProjektByNummer(searchValue)
        .then(r => {
          if (r == void 0 || !r.length) {
            this.showProjektSearchPopup(searchValue);
          } else {
            this.setProjektById(r[0].Id);
          }
        });
    } else {
      this._projektService.getProjektBySearchtextEindeutig(searchValue)
        .then(r => {
          if (r && r.length === 1) {
            this.setProjektById(r[0].Id);
          } else {
            this.showProjektSearchPopup(searchValue);
          }
        });
    }
  }
  showProjektSearchPopup(searchValue: string) {
    this._projektSearchService.show(
      (idProjekt) => {
        this._projektService.getProjektById(idProjekt)
          .then(projekt => {
            this.setProjektById(projekt.Id);
          })
      },
      () => {
        this.resetData();
        this.projektNummer.instance.focus();
      },
      { searchValue: searchValue }
    );
  }
  resetData() {
    this.setNummer(null);
    this.setBezeichnung(null);
  }
  setBezeichnung(bezeichnung: string) {
    if (this.projektBezeichnung) {
      this.projektBezeichnung.setOption({ value: bezeichnung });
    } else {
      this.projektBezeichnungOptions.value = bezeichnung;
    }
  }
  setNummer(nummer: number | string) {
    if (nummer != void 0) {
      nummer = nummer.toString();
    }

    if (this.projektNummer) {
      this.projektNummer.setOption({ value: nummer });
    } else {
      this.projektNummerOptions.value = <string>nummer;
    }

    this.updateProjektButtons();
  }
  setProjektById(idProjekt: number) {
    this.idProjekt = idProjekt;
    this._modelUtilsService.setDirty(this.mainModel);
  }
  private updateProjektButtons(editor: DevExpress.ui.dxTextBox = null) {
    if (!editor) {
      if (this.projektNummer && this.projektNummer.instance) {
        editor = this.projektNummer.instance;
      }
    }

    if (!editor) {
      return;
    }

    const buttons: DevExpress.ui.dxTextEditorButton[] = [];

    if (this.idProjekt) {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-pencil",
          stylingMode: "text",
          tabIndex: -1,
          onClick: (e) => {
            if (e.event && (<any>e.event).shiftKey) {
              this.openProjektInNewTab();
              return;
            }

            this.openProjektPopup();
          }
        }
      });
    } else if (!this.isReadOnlyChecked) {
      buttons.push({
        location: "after",
        name: "gp",
        options: {
          disabled: false,
          icon: "fa fa-plus",
          stylingMode: "text",
          tabIndex: -1,
          onClick: () => {
            this.openProjektPopup();
          }
        }
      });
    }


    if (editor.option("value")) {
      buttons.push({
        location: "after",
        name: "clear"
      });
    }
    this._dxCustomizeService.updateEditorButtons(editor, buttons);
  }
  private openProjektInNewTab() {
    const url = this._restService.getViewUrl(
      "#ERP/Stammdaten/Projekt/"
        .concat(this.idProjekt.toString()));

    window.open(url, "_blank");
  }
  private openProjektPopup() {
    if (this._customEditPopupService.projektEditPopup.isVisible) {
      this.openProjektInNewTab();
      return;
    }

    this._customEditPopupService.projektEditPopup.show({
      mappings: { "$id": this.idProjekt || 0 },
      savedCallback: (form) => {
        if (this.idProjekt) {
          this.idProjektChanged(this.idProjekt);
          return;
        }

        const model = form.models.modelWithKeyId;
        const data = form.models.data[model.id];

        if (!data || !data.Id) {
          return;
        }

        this.setProjektById(data.Id);
      }
    });
  }
}
