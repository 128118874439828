import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/export";

@autoinject
export class ProjektService {
  constructor(
    private _restService: RestService) { }

  getProjektById(idProjekt: number): Promise<any> {
    if (!idProjekt) {
      return Promise.resolve(false);
    }

    return this._restService.get({
      url: `${this._restService.getWebApiUrl("ERP/Stammdaten/Projekt")}/${idProjekt}`,
      increaseLoadingCount: true
    });
  }
  getProjektByNummer(nummer: string): Promise<any> {
    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Projekt"),
      getOptions: {
        where: ["Nummer", nummer]
      },
      increaseLoadingCount: true
    });
  }
  getProjektBySearchtextEindeutig(searchtext: string): Promise<any[]> {
    const getOptions = {
      searchtext: searchtext,
      columns: ["Id"],
      take: 2
    };

    return this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Projekt"),
      getOptions: getOptions,
      increaseLoadingCount: true
    });
  }
}
