import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";

@fwx.autoinject
export class LadelisteListForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService) {
        super(element, formBaseImport);
        this.id = "ladeliste-list";
        this.title = "ladeliste-list.ladeliste-list_caption";
        this.addModel({
            "id": "$m_Ladeliste",
            "webApiAction": "ERP/Verkauf/Ladeliste",
            "webApiExpand": {
                'Spediteur': null,
                'Firma': null,
                'Status': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addFilter': true,
                'addTagToDataGrids': [
                    'r_ladelisteGrid'
                ]
            },
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_ladeNummer",
                "caption": "ladeliste-list.r_ladenummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_ladeDatum",
                "caption": "ladeliste-list.r_ladedatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_ladeSpediteur",
                "caption": "ladeliste-list.r_ladespediteur_caption",
                "bindTo": "Spediteur.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_ladeFirma",
                "caption": "ladeliste-list.r_ladefirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_ladeStatus",
                "caption": "ladeliste-list.r_ladestatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_ladelisteGridToolbarOptions",
                "optionsNameFQ": "r_ladelisteGridToolbarOptions"
            },
            "caption": "ladeliste-list.r_ladelistegrid_caption",
            "binding": {
                "dataContext": "$m_Ladeliste"
            },
            "dataModel": "$m_Ladeliste",
            "editUrl": "ERP/Verkauf/Ladeliste",
            "addShortscuts": true,
            "isMainList": true,
            "height": "100%",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_ladelisteGrid",
            "options": {
                "optionsName": "r_ladelisteGridOptions",
                "optionsNameFQ": "r_ladelisteGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}