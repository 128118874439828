import * as fwx from "../../../../framework/forms/form-export";
import {
    ZahlungsverkehrEditUtils
} from "./zahlungsverkehr-edit-utils";

@fwx.autoinject
export class ZahlungsverkehrEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ZahlungsverkehrEditUtils: ZahlungsverkehrEditUtils) {
        super(element, formBaseImport);
        this.id = "zahlungsverkehr-edit";
        this.title = "zahlungsverkehr-edit.zahlungsverkehr-edit_caption";
        this.addModel({
            "id": "$m_Zahlungsverkehr",
            "webApiAction": "ERP/Zahlungsverkehr/Zahlungsverkehr",
            "webApiExpand": {
                'Geschaeftspartner': null,
                'Eingangsrechnung': null,
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "allowNew": "false",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ZahlungsverkehrEditUtils.showZahlungsverkehrSyncJobCommand",
                "bindToFQ": "functions.$f_ZahlungsverkehrEditUtils.showZahlungsverkehrSyncJobCommand"
            }
        });
        this.addEditPopup({
            "idContent": "zahlungsverkehrSyncJobEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_Zahlungsverkehr",
                    "bindTo": "IdZahlungsverkehrSyncJob",
                    "bindToFQ": "models.data.$m_Zahlungsverkehr.IdZahlungsverkehrSyncJob"
                }
            }],
            "id": "zahlungsverkehrSyncJobEditPopup",
            "options": {
                "optionsName": "zahlungsverkehrSyncJobEditPopupOptions",
                "optionsNameFQ": "zahlungsverkehrSyncJobEditPopupOptions"
            },
            "height": "auto",
            "width": "1000px",
            "commands": []
        });
        this.addFunction("$f_ZahlungsverkehrEditUtils", $f_ZahlungsverkehrEditUtils, "functions.$f_ZahlungsverkehrEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "zahlungsverkehr-edit.r_firma_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firmaZahlungsverkehr",
            "customs": [{
                "key": "Firma",
                "value": "models.data.$m_Zahlungsverkehr.IdFirma"
            }],
            "filters": [],
            "caption": "zahlungsverkehr-edit.r_firmazahlungsverkehr_caption",
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "IdFirmaZahlungsverkehr",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.IdFirmaZahlungsverkehr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firmaZahlungsverkehr",
            "options": {
                "optionsName": "r_firmaZahlungsverkehrOptions",
                "optionsNameFQ": "r_firmaZahlungsverkehrOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "zahlungsverkehr-edit.r_eingangsrechnungnr_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "Eingangsrechnung.Nummer",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.Eingangsrechnung.Nummer",
                "propertyPrefix": "Eingangsrechnung"
            },
            "validationRules": [],
            "id": "r_eingangsrechnungNr",
            "options": {
                "optionsName": "r_eingangsrechnungNrOptions",
                "optionsNameFQ": "r_eingangsrechnungNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlungsverkehr-edit.r_eingangsrechnungdatum_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "Eingangsrechnung.Datum",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.Eingangsrechnung.Datum",
                "propertyPrefix": "Eingangsrechnung"
            },
            "validationRules": [],
            "id": "r_eingangsrechnungDatum",
            "options": {
                "optionsName": "r_eingangsrechnungDatumOptions",
                "optionsNameFQ": "r_eingangsrechnungDatumOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 50,
            "caption": "zahlungsverkehr-edit.r_rechnungnr_caption",
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "RechnungNr",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.RechnungNr"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 50
                    }]
                }
            }],
            "id": "r_rechnungNr",
            "options": {
                "optionsName": "r_rechnungNrOptions",
                "optionsNameFQ": "r_rechnungNrOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlungsverkehr-edit.r_rechnungdatum_caption",
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "RechnungDatum",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.RechnungDatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_rechnungDatum",
            "options": {
                "optionsName": "r_rechnungDatumOptions",
                "optionsNameFQ": "r_rechnungDatumOptions"
            }
        });
        this.widgetCreator.addDateBox(this, {
            "format": "d",
            "caption": "zahlungsverkehr-edit.r_valutadatum_caption",
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "ValutaDatum",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.ValutaDatum"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_valutaDatum",
            "options": {
                "optionsName": "r_valutaDatumOptions",
                "optionsNameFQ": "r_valutaDatumOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "zahlungsverkehr-edit.r_zahlbetrag_caption",
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "Zahlbetrag",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.Zahlbetrag"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_zahlbetrag",
            "options": {
                "optionsName": "r_zahlbetragOptions",
                "optionsNameFQ": "r_zahlbetragOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "zahlungsverkehr-edit.r_waehrung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "zahlungsverkehrTyp",
            "filters": [],
            "caption": "zahlungsverkehr-edit.r_typ_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Zahlungsverkehr",
                "bindTo": "Typ",
                "bindToFQ": "models.data.$m_Zahlungsverkehr.Typ"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_typ",
            "options": {
                "optionsName": "r_typOptions",
                "optionsNameFQ": "r_typOptions"
            }
        });
        super.onConstructionFinished();
    }
}