import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, computedFrom, OverrideContext, PLATFORM, Scope } from "aurelia-framework";
import { DataSourceService, FileService, ILocalizationItem, ScopeContainer } from "../../../framework/base/export";
import { AsyncService } from "../../../framework/base/services/async-service";
import { FormBase } from "../../../framework/forms/export";
import { ICommandData } from "../../../framework/forms/interfaces/export";
import { SimpleWidgetCreatorService } from "../../../framework/forms/widget-services/simple-widget-creator-service";
import { NotificationTyp } from "../../enumerations/notification-typ";
import { IDmsViewerShowOptions } from "../../interfaces/dms-viewer-show-options";
import * as Interfaces from "../../interfaces/export";
import { BriefService, DmsViewerService, DruckenService, FormCommandService, StartupService } from "../../services/export";
import { AnzAusdrucke } from "../anz-ausdrucke/anz-ausdrucke";
import { GlobalizationService } from "./../../../framework/base/services/globalization-service";

@autoinject
export class DmsViewer {
  private _detachedCommand: { (): void };
  private _availableForms: FormBase[] = [];
  private _isDokumentVersion: boolean = false;
  private _selectAfterContentReady: boolean = false;

  constructor(
    private _briefService: BriefService,
    private _dataSourceService: DataSourceService,
    private _dmsViewerService: DmsViewerService,
    private _fileService: FileService,
    private _formCommandService: FormCommandService,
    private _globalizationService: GlobalizationService,
    private _simpleWidgetCreatorService: SimpleWidgetCreatorService,
    private _druckenService: DruckenService,
    private _startupService: StartupService,
    private _eventAggregator: EventAggregator,
    private _asyncService: AsyncService) { }

  @computedFrom("_isDokumentVersion")
  get currentGrid() {
    return this._isDokumentVersion
      ? this.versionGrid
      : this.dokumentGrid;
  }

  options: IDmsViewerShowOptions;

  currentDokument: Interfaces.IDMSDokument = { DMSLink: null, MimeType: "application/pdf" };
  currentVersionDokument: Interfaces.IDMSDokument = { DMSLink: null, MimeType: "application/pdf" };

  hasSelectedDokument: boolean = false;
  anzAusdrucke: AnzAusdrucke;

  dmsViewerPopupCommands: ICommandData[] = [
    {
      id: "deleteDocument",
      title: "dms-viewer.delete-dokument",
      icon: "times",
      isVisibleExpression: "!_isDokumentVersion",
      isEnabledExpression: "hasSelectedDokument && !currentDokument.IsGesichert",
      execute: this.deleteSelectedDokumente.bind(this)
    }, {
      id: "downloadDocument",
      title: "dms-viewer.download-dokument",
      icon: "download",
      isEnabledExpression: "hasSelectedDokument",
      execute: this.downloadSelectedDokumente.bind(this)
    }, {
      id: "sendDocument",
      title: "dms-viewer.send-dokument",
      icon: "share-alt",
      badgeText: null,
      isEnabled: false,
      isVisibleExpression: "!_isDokumentVersion",
      execute: this.sendSelectedDokumente.bind(this)
    }, {
      id: "anzAusdrucke",
      title: "dms-viewer.anzahl-ausdrucke",
      isVisible: this.isDruckJobAktiv(),
      template: {
        moduleId: PLATFORM.moduleName("erp/elements/anz-ausdrucke/anz-ausdrucke"),
        options: {
          options: {
            onInitialized: (e) => {
              this.anzAusdrucke = e.component;
              e.options.value = 1;
            }
          }
        }
      }
    }, {
      id: "sendDruckJob",
      title: "dms-viewer.send-druck-job",
      icon: "print",
      isEnabledExpression: "hasSelectedDokument",
      isVisible: this.isDruckJobAktiv(),
      execute: this.onSendDruckJob.bind(this)
    }, {
      id: "versionHochladen",
      idCategory: "versionierung",
      title: "dms-viewer.version-hochladen",
      icon: "cloud-upload",
      isEnabledExpression: "hasSelectedDokument && !currentDokument.IsGesichert",
      execute: () => {
        this.neueVersionPopup.instance.show();
      }
    }, {
      id: "versionenAnzeigen",
      idCategory: "versionierung",
      title: "dms-viewer.version-anzeigen",
      icon: "external-link",
      isEnabledExpression: "hasSelectedDokument && currentDokument.AnzahlVersionen > 1",
      isVisibleExpression: "!_isDokumentVersion",
      execute: () => {
        this.versionPopup.instance.show();
      }
    }
  ];

  dmsViewerPopup: Interfaces.IdxPopupComponent;
  dmsViewerPopupOptions: DevExpress.ui.dxPopupOptions = {
    onHidden: () => {
      this.dokumentGrid.instance.deselectAll();

      if (this.options.callback) {
        this.options.callback(this.options.idDokumentContainer);
      }

      this.options = null;
    }
  };

  neueVersionPopup: Interfaces.IdxPopupComponent;
  neueVersionPopupOptions: DevExpress.ui.dxPopupOptions = {
    maxWidth: 500,
    height: "auto"
  };

  versionPopup: Interfaces.IdxPopupComponent;
  versionPopupOptions: DevExpress.ui.dxPopupOptions = {
    onShowing: () => {
      this._isDokumentVersion = true;
      this.selectCurrentDokument(this.currentDokument);
    },
    onHiding: () => {
      this._isDokumentVersion = false;
      const needsReload = this.currentDokument && this.currentVersionDokument && this.currentDokument.DMSLink != this.currentVersionDokument.DMSLink;

      if (needsReload) {
        this.selectCurrentDokument(this.currentDokument);
      }
    }
  };

  scope: Scope;
  scopeContainer: ScopeContainer;

  dokumentGridDataSource: DevExpress.data.DataSource;
  dokumentGrid: Interfaces.IdxDataGridComponent;
  dokumentGridOptions = this.getDmsGridOptions([
    { dataField: "AnlageDatum", caption: "Anlagedatum", width: "90px", format: this._globalizationService.getFormatterParser("d") },
    { dataField: "BezeichnungDateiname", caption: "Bezeichnung" },
    { dataField: "Dokumentenart.Bezeichnung", caption: "Dokumentenart" },
    { dataField: "AnzahlVersionen", caption: "V", width: "30px" }
  ]);

  versionGridDataSource: DevExpress.data.DataSource;
  versionGrid: Interfaces.IdxDataGridComponent;
  versionGridOptions = this.getDmsGridOptions([
    { dataField: "AnlageDatum", caption: "Anlagedatum", width: "90px", format: this._globalizationService.getFormatterParser("d") },
    { dataField: "VersionNr", width: "80px" },
    { dataField: "Dateiname" }
  ]);

  fileUploaderOptions: any = {
    clearCurrentValueAfterUpload: true,
    showViewer: false,
    onFileKeyChanged: this.onFileKeyChanged.bind(this)
  };
  neueVersionUploaderOptions: any = {
    clearCurrentValueAfterUpload: true,
    showViewer: false,
    onFileKeyChanged: (value) => {
      this.onFileKeyChanged(value, true);
    }
  };

  dokumentenartSelectBox: Interfaces.IdxSelectBoxComponent;
  dokumentenartSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    onValueChanged: (e) => {
      if (this.currentDokument == void 0) {
        return;
      }

      this._dmsViewerService.saveDokument(this.currentDokument)
        .then((r) => {
          this.currentGrid.instance.refresh();
        });
    },
    bindingOptions: {
      value: "currentDokument.IdDokumentenart",
      readOnly: "!currentDokument"
    }
  };

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scope = {
      bindingContext: bindingContext,
      overrideContext: overrideContext
    };

    this._dmsViewerService.dmsViewer = this;

    this.scopeContainer = new ScopeContainer({
      bindingContext: this,
      overrideContext: null
    });

    this._eventAggregator.subscribe(NotificationTyp.startupInfoLoaded, async () => {
      this.dmsViewerPopupCommands.forEach((cmd) => {
        if (cmd.id == "anzAusdrucke" || cmd.id == "sendDruckJob") {
          cmd.isVisible = this.isDruckJobAktiv();
        }
      });
    });

    this.setDataSources();
    this.updatePopupOptions();
  }
  unbind() {
    this.scopeContainer.disposeAll();
    this.scope = null;
  }
  attached() {
    const dokumentCommand: ICommandData = {
      id: "addDokument",
      idCategory: "dms",
      title: "dms-viewer.button-text",
      tooltip: "dms-viewer.button-text-tooltip",
      icon: "file",
      isEnabled: false,
      badgeText: null,
      badgeColor: "#8D44AD",
      sortIndex: 6000
    };
    const updateAddDokumentCommandBadge = async (containerOrIdDokumentContainer: any) => {
      if (!containerOrIdDokumentContainer) {
        return;
      }

      const idDokumentContainer = typeof containerOrIdDokumentContainer == "number"
        ? containerOrIdDokumentContainer
        : containerOrIdDokumentContainer?.IdDokumentContainer;

      if (idDokumentContainer == void 0) {
        dokumentCommand.badgeText = null;
        return;
      }

      const commandToReload = this._formCommandService.getReloadCommand(
        dokumentCommand,
        this._availableForms,
        {
          modelPropertyName: "IdDokumentContainer",
          modelPropertyValue: idDokumentContainer
        }
      );

      if (commandToReload == null) {
        return;
      }

      const anzahl = await this._dmsViewerService.getAnzahlDokumente(idDokumentContainer);
      const localizationAnzahl = commandToReload.badgeText as ILocalizationItem;

      if (localizationAnzahl && anzahl == localizationAnzahl.parameters[0]) {
        return;
      }

      if (anzahl) {
        commandToReload.badgeText = {
          key: "base.param_1",
          parameters: [anzahl]
        };
      } else {
        if (commandToReload.badgeText) {
          commandToReload.badgeText = null;
        }
      }
    };
    this._detachedCommand = this._formCommandService.attachFormCommand(
      dokumentCommand,
      {
        isCommandVisibleModelProperty: "HasDokumentContainer",
        execute: this._dmsViewerService.showDmsViewerPopupFromFormCommand.bind(this._dmsViewerService),
        onFormModelLoaded: updateAddDokumentCommandBadge.bind(this),
        onCommandAttached: this.addAvailableForm.bind(this),
        onCommandDetached: this.deleteAvailableForm.bind(this)
      });
  }
  detached() {
    this._detachedCommand();
  }

  show(options: IDmsViewerShowOptions) {
    this._selectAfterContentReady = true;
    this.options = options;

    this.dmsViewerPopup.instance.show();
  }

  private getDokumentToSave(dmsLinkValue: string, setVersion: boolean) {
    return setVersion
      ? Object.assign({}, this.currentDokument, { DMSLink: dmsLinkValue })
      : {
        IdDokumentContainer: this.options.idDokumentContainer,
        DMSLink: dmsLinkValue
      };
  }
  private async onFileKeyChanged(value: string, setVersion: boolean = false) {
    if (value == void 0) {
      return;
    }

    const toSave = this.getDokumentToSave(value, setVersion);
    const savedDokument = await this._dmsViewerService.saveDokument(toSave);

    this.onNewDokumentSaved(savedDokument, setVersion);
  }
  private async onNewDokumentSaved(savedDokument: Interfaces.IDMSDokument, setVersion: boolean = false) {
    if (savedDokument == void 0) {
      return;
    }

    if (setVersion) {
      this.neueVersionPopup.instance.hide();
    }

    this.setCurrentDokument(savedDokument);
    this.selectCurrentDokument(savedDokument);
  }

  private onSelectionChanged(e) {
    const length = e.selectedRowKeys.length;
    const currentDokument = length === 1
      ? e.selectedRowsData[0]
      : null;

    this.hasSelectedDokument = length > 0;

    this.setCurrentDokument(currentDokument);
    this.updateSendDokumentCommand(length);
  }
  private async selectCurrentDokument(dokument: Interfaces.IDMSDokument) {
    this.currentGrid.instance.deselectAll();
    await this._asyncService.convertToPromise(this.currentGrid.instance.refresh());

    if (dokument == void 0 || dokument.Id == void 0) {
      this.currentGrid.instance.selectRowsByIndexes([0]);
      return;
    }

    const dokumentKey = this._isDokumentVersion
      ? this.getDokumentKeyByAnzahlVersion(dokument.AnzahlVersionen)
      : dokument.Id;
    this.currentGrid.instance.selectRows([dokumentKey], false);
  }
  private deleteSelectedDokumente() {
    const dokumentKeyList = this.dokumentGrid.instance.getSelectedRowKeys();

    const toDelete: Promise<any>[] = dokumentKeyList
      .map((id, index) => {
        return this._dmsViewerService.deleteDokument(id, index == 0);
      });

    Promise.all(toDelete)
      .then(() => {
        this.dokumentGrid.instance.refresh();
      });
  }
  private downloadSelectedDokumente() {
    const dmsLinkList = this.getSelectedDMSLinkList();
    dmsLinkList
      .forEach((dmsLInk, index) => {
        const url = this._fileService.getDownloadUrl(dmsLInk);
        window.open(url, "_blank");
      });
  }
  private async sendSelectedDokumente() {
    const dmsLinkList = this.getSelectedDMSLinkList();
    if (dmsLinkList == void 0) {
      return;
    }
    await this._briefService.createAndGoToBrief(this.options.id, dmsLinkList);

    const popup = this._isDokumentVersion ? this.neueVersionPopup : this.dmsViewerPopup;
    popup.instance.hide();
  }

  private getSelectedDMSLinkList(): string[] {
    const dmsLinkList: any[] = this.currentGrid.instance.getSelectedRowsData();

    return dmsLinkList.length
      ? dmsLinkList.map((dokument) => {
        return dokument.DMSLink;
      })
      : null;
  }
  private getDokumentKeyByAnzahlVersion(versionNr: number): number {
    if (versionNr == void 0) {
      return;
    }
    const dokument = this.versionGridDataSource
      .items()
      .find((d) => d.VersionNr == versionNr);

    return dokument
      ? dokument.Id
      : null;
  }

  private updateSendDokumentCommand(count: number) {
    const cmdId = this._isDokumentVersion ? "sendVersionDocument" : "sendDocument";
    const cmd = this.dmsViewerPopupCommands.find((cmd) => cmd.id == cmdId);

    if (cmd == void 0) {
      return;
    }

    if (count == 0) {
      cmd.badgeText = null;
      cmd.isEnabled = false;
      return;
    }

    cmd.isEnabled = true;

    cmd.badgeText = {
      key: "base.param_1",
      parameters: [count.toString()]
    };
  }

  private setCurrentDokument(dokument: any) {
    if (this._isDokumentVersion) {
      this.currentVersionDokument = dokument;
    } else {
      this.currentDokument = dokument;
    }
  }
  private setDataSources() {
    this.dokumentGridDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        keyProperty: "Id",
        webApiAction: "ERP/DMS/Dokument",
        webApiWhere: ["IdDokumentContainer", { expression: "options.idDokumentContainer", isBound: true }],
        webApiExpand: { Dokumentenart: null }
      }
    );
    this.dokumentenartSelectBoxOptions.dataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        keyProperty: "Id",
        webApiAction: "ERP/Stammdaten/Dokumentenart",
        webApiWhere: ["DokumentenartZuEntitaeten", ["TypeName", { expression: "options.typeName", isBound: true }]]
      }
    );
    this.versionGridDataSource = this._dataSourceService.createDataSource(
      this.scopeContainer,
      {
        keyProperty: "Id",
        webApiAction: "ERP/DMS/DokumentVersion",
        webApiWhere: ["IdDokument", { expression: "currentDokument.Id", isBound: true }]
      }
    );

    this.dokumentGridOptions.dataSource = this.dokumentGridDataSource;
    this.versionGridOptions.dataSource = this.versionGridDataSource;
  }
  private updatePopupOptions() {
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dmsViewerPopupToolbar",
      caption: "dms-viewer.popup-titel",
      options: this.dmsViewerPopupOptions,
      commands: this.dmsViewerPopupCommands,
      scopeContainer: this.scopeContainer
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dmsViewerVersionHochladenPopupToolbar",
      caption: "dms-viewer.version-hochladen-popup-titel",
      options: this.neueVersionPopupOptions,
      commands: [],
      scopeContainer: this.scopeContainer
    });
    this._simpleWidgetCreatorService.updatePopupOptions({
      idToolbar: "dmsViewerVersionPopupToolbar",
      caption: "dms-viewer.version-popup-titel",
      options: this.versionPopupOptions,
      commands: this.dmsViewerPopupCommands,
      scopeContainer: this.scopeContainer
    });
  }

  private getDmsGridOptions(columns: DevExpress.ui.dxDataGridColumn[]): DevExpress.ui.dxDataGridOptions {
    return {
      allowColumnReordering: true,
      allowColumnResizing: true,
      height: "100%",
      columns: columns,
      selection: {
        mode: "multiple"
      },
      searchPanel: {
        visible: true,
        width: 350,
        placeholder: "Suchen...",
      },
      onContentReady: () => {
        if (!this._selectAfterContentReady) {
          return;
        }
        this._selectAfterContentReady = false;

        const items = this.dokumentGridDataSource.items();
        if (items.length == 0) {
          return;
        }

        this.dokumentGrid.instance.selectRowsByIndexes([0]);
      },
      onSelectionChanged: this.onSelectionChanged.bind(this),
      remoteOperations: {
        filtering: true,
        paging: true,
        sorting: true
      }
    };
  }

  private addAvailableForm(form: FormBase) {
    this._availableForms.push(form);
  }
  private deleteAvailableForm(form: FormBase) {
    const position = this._availableForms.indexOf(form);
    if (position == void 0 || position < 0) {
      return;
    }

    this._availableForms.splice(position, 1);
  }

  private isDruckJobAktiv() {
    return this._startupService.startupInfo.Mandant.IsDruckJobAktiv;
  }

  private async onSendDruckJob() {
    const dmsLinkList = this.getSelectedDMSLinkList();

    for (const dmsLink of dmsLinkList) {
      await this._druckenService.executeDruckJob(dmsLink, this.anzAusdrucke.getValue());
    }
  }
}
