/** in stack-router angesiedelt, da dieser den view enthält und die toolbar dort benötigt wird */

import { IPage } from "../toolbar/page";
import { autoinject, computedFrom, bindable, TaskQueue, observable, BindingEngine, Disposable } from "aurelia-framework";
import { IToolbarOptions } from "../toolbar/toolbar-options";
import { ToolbarService } from "../../services/toolbar-service";
import { IItem } from "../toolbar/item";
import { ICategory } from "../toolbar/category";
import { BindingService } from "../../../base/export";
import { IBadge } from "../toolbar/badge";
import { updateLocale } from 'moment';
import { IToolbarModel } from '../toolbar/toolbar-model';

@autoinject
export class RibbonToolbar {
  private _itemSubscriptionDisposable: Disposable[];

  constructor(
    private toolbar: ToolbarService,
    private taskQueue: TaskQueue,
    private bindingEngine: BindingEngine,
    private binding: BindingService
  ) {
  }

  @bindable @observable options: IToolbarOptions;

  @computedFrom("options.smallToolbar")
  get heightToolbar() {
    if (this.options && this.options.smallToolbar) {
      return "35px";
    } else {
      return "90px";
    }
  }
  get classToolbar() {
    if (this.options && this.options.smallToolbar) {
      return "t--ribbon-toolbar-small";
    }

    return "";
  }

  pages: IPage[];
  afterPage: IPage;
  currentPage: IPage;
  model: IToolbarModel;

  activate(model: IToolbarModel) {
    this.model = model;
    
    const updateOptions = () => {
      this.options = this.model.options;
    }
    model.optionsChanged = () => {
      updateOptions();
    }
    updateOptions();
  }
  bind() {
    if (this.options) {
      this.constructPages();
    }
  }
  unbind() {
    this.disposeOldSubscriber();
  }

  optionsChanged(newVal) {
    this.constructPages();
  }

  onPageClick(page: IPage) {
    if (this.currentPage) {
      this.currentPage.isActive = false;
    }

    this.checkPage(page);
    page.isActive = true;

    this.currentPage = page;
  }

  private constructPages() {
    this.disposeOldSubscriber();
    this._itemSubscriptionDisposable = [];

    const pages = this.toolbar.createPageCategoryItemStructure(this.options.items);
    this.pages = pages.filter(p => p.id !== "$after");

    const afterPages = pages.filter(p => p.id === "$after");
    if (afterPages.length === 1) {
      this.afterPage = afterPages[0];
    }

    for (let page of pages) {
      for (let category of page.categories) {
        for (let item of category.items) {
          if (item.isVisible == void(0)) {
            item.isVisible = true;
          }
          if (item.isEnabled == void(0)) {
            item.isEnabled = true;
          }

          this.observe(item, "isVisible", (newVal) => {
            this.checkCategoryVisible(page, category);
          });

          this.observe(item, "badgeText", (newVal) => {
            this.checkBadge(page);
          });
          this.observe(item, "badgeColor", (newVal) => {
            this.checkBadge(page);
          });          
          this.observe(item, "badgeBorderColor", (newVal) => {
            this.checkBadge(page);
          });
        }

        this.checkCategoryVisible(page, category);
      }

      this.checkBadge(page);
    }

    if (this.pages && this.pages.length > 0) {
      this.onPageClick(this.pages[0]);
    }
    if (this.afterPage) {
      this.checkPage(this.afterPage);
    }
  }
  private checkCategoryVisible(page: IPage, category: ICategory) {
    category.isVisible = category.items.some(c => c.isVisible);
    page.isVisible = page.categories.some(c => c.isVisible);
  }
  private checkBadge(page: IPage) {
    const badges = new Set();

    page.categories.forEach(c => {
      c.items
        .filter(i => !!i.badgeText)
        .forEach(i => badges.add(i.badgeColor || "#C1392D"));
    });

    const badgesArr: IBadge[] = [];
    let i = 0;
    badges.forEach((v: any, k: any) => {
      badgesArr.push({
        color: k,
        right: i * 6 + 4
      });

      i++;
    });

    page.badges = badgesArr;
  }
  private checkPage(page: IPage) {
    page.categories.forEach(c => {
      let countSmall = 0;

      let setSmallToBig = (index) => {
        if (index < 0) {
          return;
        }
        if (countSmall % 2 == 0) {
          return;
        }

        c.items[index].smallChecked = false;
      }

      c.items.forEach((i, index) => {
        i.smallChecked = i.small;

        if (!i.small) {
          setSmallToBig(index - 1);
          countSmall = 0;
          return;
        }

        countSmall++;
      });

      setSmallToBig(c.items.length - 1);

      c.items.forEach(i => {
        if (this.options.smallToolbar) {
          i.class = "t--ribbon-toolbar-item-big";

          if (i.title == void (0)) {
            i.class += " t--ribbon-toolbar-item-icon-only";
          }
        } else {
          i.class = i.smallChecked
            ? "t--ribbon-toolbar-item-small"
            : "t--ribbon-toolbar-item-big";

          if (i.title == void (0)) {
            i.class += " t--ribbon-toolbar-item-icon-only";
          }
        }
      });
    });
  }
  private observe(item: IItem, propertyName: string, action: {(newVal): void}) {
    const disposable = this.bindingEngine
      .expressionObserver(item, propertyName)
      .subscribe((newVal) => action(newVal));

    this._itemSubscriptionDisposable.push(disposable);
  }
  private disposeOldSubscriber() {
    if (!this._itemSubscriptionDisposable) {
      return;
    }

    this._itemSubscriptionDisposable.forEach(c => c.dispose());
    this._itemSubscriptionDisposable = [];
  }
}
