import { autoinject, bindable, observable, OverrideContext } from "aurelia-framework";
import { ScopeContainer } from "../../../../framework/base/export";
import { GeschaeftspartnerService } from "./../../../services/geschaeftspartner-service";

@autoinject
export class GeschaeftspartnerInfo {
  constructor(
    private _geschaeftspartnerService: GeschaeftspartnerService) { }

  @bindable @observable idGeschaeftspartner: number;
  geschaeftspartner: any;
  isVisible: boolean = false;
  bankverbindungList: any[] = [];
  scopeContainer: ScopeContainer;

  bind(bindingContext: any, overrideContext: OverrideContext) {
    this.scopeContainer = new ScopeContainer({
      bindingContext: bindingContext,
      overrideContext: overrideContext
    });
    if (this.idGeschaeftspartner) {
      this.loadData();
    }
  }
  unbind() {
    this.scopeContainer.disposeAll();
  }

  idGeschaeftspartnerChanged() {
    this.loadData();
  }

  private async loadData() {
    if (this.idGeschaeftspartner) {
      this.geschaeftspartner = await this._geschaeftspartnerService.getGeschaeftspartnerById(this.idGeschaeftspartner, false);
      const bankverbindungWhere = [["GueltigVon", "<=", new Date()], ["GueltigBis", ">=", new Date()]];
      this.bankverbindungList = await this._geschaeftspartnerService.getGeschaeftspartnerBankverbindungList(this.idGeschaeftspartner, bankverbindungWhere);
    } else {
      this.bankverbindungList = [];
    }
    this.isVisible = this.geschaeftspartner.UIDNummer || (this.bankverbindungList && this.bankverbindungList.length > 0);
  }
}
