import { autoinject } from 'aurelia-framework';
import { RestService } from '../../../../framework/base/export';
import { IWidgetModel } from '../../interfaces/widget-model';
import { IWidgetSettings } from '../../interfaces/widget-settings';

@autoinject
export class KennzahlenSettings {
  constructor(
    private _restService: RestService) { }

  widgetModel: IWidgetModel;
  widgetViewModel: any;
  settings: IKennzahlenSettings;
  firmaDataSource: any[];

  firmaSelectBoxOptions: DevExpress.ui.dxSelectBoxOptions = {
    displayExpr: "Bezeichnung",
    valueExpr: "Id",
    showClearButton: true,
    bindingOptions: {
      dataSource: "firmaDataSource",
      value: "settings.idFirma",
    }
  };

  doShowOffeneBestellungCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Bestellungen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneBestellung"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneEingangsrechnungCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Eingangsrechnungen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneEingangsrechnungen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneAuftraegeCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Aufträge anzeigen",
    bindingOptions: {
      value: "settings.showOffeneAuftraege"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneFakturenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Fakturen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneFakturen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneKundenreklamationCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Kundenreklamationen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneKundenreklamationen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneWarenuebernahmeCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Warenübernahmen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneWarenuebernahmen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneAngeboteCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Angebote anzeigen",
    bindingOptions: {
      value: "settings.showOffeneAngebote"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneLieferscheineCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Lieferscheine anzeigen",
    bindingOptions: {
      value: "settings.showOffeneLieferscheine"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffenePostenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Posten anzeigen",
    bindingOptions: {
      value: "settings.showOffenePosten"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowOffeneProduktionenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Offene Produktionen anzeigen",
    bindingOptions: {
      value: "settings.showOffeneProduktionen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowNewsletterAktivierungenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Newsletter-Aktivierungen anzeigen",
    bindingOptions: {
      value: "settings.showNewsletterAktivierungen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }
  doShowNewsletterAbmeldungenCheckBoxOptions: DevExpress.ui.dxCheckBoxOptions = {
    text: "Newsletter-Abmeldungen anzeigen",
    bindingOptions: {
      value: "settings.showNewsletterAbmeldungen"
    },
    onValueChangedByUser: () => {
      this.reloadData();
    }
  }

  activate(widgetSettings: IWidgetSettings) {
    this.widgetModel = widgetSettings.widgetModel;
    this.settings = widgetSettings.widgetModel.settings;
    this.widgetViewModel = widgetSettings.widgetViewModel;

    this.loadFirmaDataSource();
  }

  private reloadData() {
    if (!this.widgetViewModel) {
      return;
    }
    if (!this.widgetViewModel.loadData) {
      return;
    }

    this.widgetViewModel.loadData();
  }

  private async loadFirmaDataSource() {
    this.firmaDataSource = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id", "Bezeichnung"]
      },
      increaseLoadingCount: true
    });
  }
}
export interface IKennzahlenSettings {
  ueberschrift?: string;
  showOffeneBestellung?: boolean;
  showOffeneEingangsrechnungen?: boolean;
  showOffeneAuftraege?: boolean;
  showOffeneFakturen?: boolean;
  showOffeneKundenreklamationen?: boolean;
  showOffeneWarenuebernahmen?: boolean;
  showOffeneAngebote?: boolean;
  showOffeneLieferscheine?: boolean;
  showOffenePosten?: boolean;
  showOffeneProduktionen?: boolean;
  showNewsletterAktivierungen?: boolean;
  showNewsletterAbmeldungen?: boolean;
  idFirma?: number;
}
