import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  AuthorizationService,
  LocalizationService
} from "../../../base/export";
import { 
  FormBase,
  ICommandData
} from "../../../forms/export";
import { LocationService } from '../../../base/services/location-service';

@autoinject
@transient()
export class PasswordResetFuncs {
  private form: FormBase;
  
  constructor(
    private authorization: AuthorizationService,
    private locationService: LocationService,
    private localization: LocalizationService
  ) { }

  bind(form: FormBase) {
    this.form = form;

    form.models.data.$m_Password = {
    };
  }

  resetPasswordCommand: ICommandData = {
    id: "$resetPassword",
    title: "password-reset-funcs.reset_caption",
    execute: () => {
      if (!this.form.models.data.$m_Password.Username) {
        DevExpress.ui.notify(this.localization.translateOnce("password-reset-funcs.enter_username"), "error", 3000);
        return;
      }

      this.authorization
        .resetPassword(this.form.models.data.$m_Password)
        .then(r => {
          if (r) {
            this.locationService.goTo({
              url: "login",
              clearStack: true
            });
          }
        });
    }
  };
  backToLoginCommand: ICommandData = {
    id: "$backToLogin",
    title: "password-reset-funcs.back_to_login_caption",
    execute: () => {
      this.locationService.goTo({
        url: "login",
        clearStack: true
      });
    }
  };
}
