import * as fwx from "../../../../framework/forms/form-export";
import {
    ArtikelService
} from "../../../services/artikel-service";
import {
    ArtikelStuecklisteEditUtils
} from "./artikel-stueckliste-edit-utils";

@fwx.autoinject
export class ArtikelStuecklisteEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_ArtikelService: ArtikelService,
        private $f_ArtikelStuecklisteEditUtils: ArtikelStuecklisteEditUtils) {
        super(element, formBaseImport);
        this.id = "artikel-stueckliste-edit";
        this.title = "artikel-stueckliste-edit.artikel-stueckliste-edit_caption";
        this.addModel({
            "id": "$m_ArtikelStueckliste",
            "webApiAction": "ERP/Stammdaten/ArtikelStueckliste",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_Artikel",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "webApiColumns": ["Id", "BezugTyp", "IdDynamischesFeldContainer"],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_ArtikelStueckliste.IdArtikelFW",
            "keyProperty": "Id",
            "filters": []
        });
        this.addVariable({
            "id": "$id"
        });
        this.addFunction("$f_ArtikelService", $f_ArtikelService, "functions.$f_ArtikelService");
        this.addFunction("$f_ArtikelStuecklisteEditUtils", $f_ArtikelStuecklisteEditUtils, "functions.$f_ArtikelStuecklisteEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 1.0,
            "format": "n2",
            "caption": "artikel-stueckliste-edit.r_mengeverpeh_caption",
            "binding": {
                "dataContext": "$m_ArtikelStueckliste",
                "bindTo": "MengeVerpEH",
                "bindToFQ": "models.data.$m_ArtikelStueckliste.MengeVerpEH"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_mengeVerpEH",
            "options": {
                "optionsName": "r_mengeVerpEHOptions",
                "optionsNameFQ": "r_mengeVerpEHOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelEinheit",
            "filter": ["IdArtikel", {
                "isBound": true,
                "expression": "models.data.$m_ArtikelStueckliste.IdArtikelRW"
            }],
            "filters": [],
            "caption": "artikel-stueckliste-edit.r_einheit_caption",
            "binding": {
                "dataContext": "$m_ArtikelStueckliste",
                "bindTo": "IdEinheit",
                "bindToFQ": "models.data.$m_ArtikelStueckliste.IdEinheit"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_einheit",
            "options": {
                "optionsName": "r_einheitOptions",
                "optionsNameFQ": "r_einheitOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "stuecklisteFaktorTyp",
            "filters": [],
            "caption": "artikel-stueckliste-edit.r_faktor_caption",
            "binding": {
                "dataContext": "$m_ArtikelStueckliste",
                "bindTo": "Faktor",
                "bindToFQ": "models.data.$m_ArtikelStueckliste.Faktor"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_faktor",
            "options": {
                "optionsName": "r_faktorOptions",
                "optionsNameFQ": "r_faktorOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-stueckliste-edit.r_isbeistellartikel_caption",
            "binding": {
                "dataContext": "$m_ArtikelStueckliste",
                "bindTo": "IsBeistellartikel",
                "bindToFQ": "models.data.$m_ArtikelStueckliste.IsBeistellartikel"
            },
            "validationRules": [],
            "id": "r_isBeistellartikel",
            "options": {
                "optionsName": "r_isBeistellartikelOptions",
                "optionsNameFQ": "r_isBeistellartikelOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-stueckliste-edit.r_hasvariablersetartikelvorbelegung_caption",
            "binding": {
                "dataContext": "$m_ArtikelStueckliste",
                "bindTo": "HasVariablerSetartikelVorbelegung",
                "bindToFQ": "models.data.$m_ArtikelStueckliste.HasVariablerSetartikelVorbelegung"
            },
            "validationRules": [],
            "id": "r_hasVariablerSetartikelVorbelegung",
            "options": {
                "optionsName": "r_hasVariablerSetartikelVorbelegungOptions",
                "optionsNameFQ": "r_hasVariablerSetartikelVorbelegungOptions"
            }
        });
        super.onConstructionFinished();
    }
}