import { autoinject } from "aurelia-framework";
import { RestService } from "../../../../framework/base/export";
import { FormBase, ICommandData } from "../../../../framework/forms/export";

@autoinject
export class ArbeitszeitBenutzerZuteilungEditUtils {

  constructor(
    private _restService: RestService) { }

  form: FormBase;

  doNeuerMitarbeiterstatus: ICommandData = {
    id: "doNeuerMitarbeiterstatus",
    idCategory: "arbeitszeit",
    title: "arbeitszeit-benutzer-zuteilung.mitarbeiterstatus_erstellen",
    isEnabledExpression: "models.data.$m_ArbeitszeitBenutzerZuteilung.Id != null",
    icon: "plus",
    sortIndex: 100,
    execute: () => {
      const arbeitszeitBenutzerZuteilung = this.form["r_arbeitszeitBenutzerZuteilung"];
      arbeitszeitBenutzerZuteilung.doNeuerMitarbeiterstatus();
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;

    this.form.models.onLoaded.register(async r => {
      if (r == void 0 || r.model.id != "$m_ArbeitszeitBenutzerZuteilung") {
        return;
      }

      const arbeitszeitBenutzerZuteilung = this.form["r_arbeitszeitBenutzerZuteilung"];
      arbeitszeitBenutzerZuteilung.id = form.models.data.$m_ArbeitszeitBenutzerZuteilung.Id;
      arbeitszeitBenutzerZuteilung.idPartei = form.models.data.$m_ArbeitszeitBenutzerZuteilung.IdArbeitszeitPartei;
      arbeitszeitBenutzerZuteilung.idMitarbeiterstatus = null;
    });

    this.form.models.onSaved.register(async (e) => {
      const arbeitszeitBenutzerZuteilung = this.form["r_arbeitszeitBenutzerZuteilung"];
      arbeitszeitBenutzerZuteilung.idMitarbeiterstatus = null;

      if (this.form.models.data.$m_ArbeitszeitBenutzerZuteilung.IdArbeitszeitPartei == 0) {
        const r = await this._restService.post({
          url: this._restService.getApiUrl(`ERP/ArbeitszeitPartei/partei`),
          data: {
            IdArbeitszeitBenutzerZuteilung: this.form.models.data.$m_ArbeitszeitBenutzerZuteilung.Id
          }
        });
        this.form.models.reloadAll();
      }
    });
  }
}
