import {
  autoinject,
  transient
} from "aurelia-framework";
import {
  FormBase, ICommandData
} from "../../../../framework/forms/export";
import { IdxPopupComponent, IdxValidationGroup, IdxTextBoxComponent, IdxDataGridComponent } from '../../../interfaces/export';
import { WebEventService } from '../../../../framework/base/services/web-event-service';
import { OnCreateNewsletterEmpfaengerEvent } from '../../../../framework-data/events';
import { DataSourceService } from '../../../../framework/base/services/data-source-service';
import { LocalizationService } from '../../../../framework/base/services/localization-service';
import { LoadingService } from '../../../../framework/default-ui/services/loading-service';
import { AsyncService } from '../../../../framework/base/services/async-service';
import { EximService } from '../../../services/exim-service';

@autoinject
@transient()
export class PersonListUtils {
  constructor(
    private webEventService: WebEventService,
    private dataSourceService: DataSourceService,
    private localizationService: LocalizationService,
    private loadingService: LoadingService,
    private asyncService: AsyncService,
    private eximService: EximService
  ) { }

  form: FormBase;

  doExport: ICommandData = {
    id: "doExport",
    icon: "download",
    idCategory: "$export",
    sortIndex: 50,
    title: "erp.export",
    execute: async () => {
      const grid: IdxDataGridComponent = this.form["r_personGrid"];
      await this.asyncService.convertToPromise(grid.instance.selectAll());
      const keys = await this.asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

      grid.instance.clearSelection();

      this.eximService.export("PersonExim", keys);
    }
  };

  newsletterEmpfErstellenCommand: ICommandData = {
    id: "newsletterEmpfErstellen",
    title: "person.newsletter_empf_erstellen",
    sortIndex: 100,
    idCategory: "$export",
    icon: "rocket",
    execute: async () => {
      await this.asyncService.convertToPromise(this.getNewsletterEmpfPopup().instance.show());

      const bezeichnungTextBox = this.getNewsletterEmpfBezeichnungTextBox();
      bezeichnungTextBox.setOption({
        "value": ""
      });
      bezeichnungTextBox.instance.focus();
    }
  };
  newsletterEmpfErstellenExCommand: ICommandData = {
    id: "newsletterEmpfErstellenEx",
    title: "base.save",
    icon: "floppy-o",
    execute: async () => {
      const r = this.getNewsletterEmpfValidationGroup().instance.validate();

      if (!r.isValid) {
        return;
      }

      try {
        this.loadingService.beginLoading();

        const grid = this.getPersonGrid();
        await this.asyncService.convertToPromise(grid.instance.selectAll());
        const keys = await this.asyncService.convertToPromise(grid.instance.getSelectedRowKeys());

        grid.instance.clearSelection();

        const bezeichnung = this.getNewsletterEmpfBezeichnungTextBox().instance.option("value");

        const args = new OnCreateNewsletterEmpfaengerEvent({
          Bezeichnung: bezeichnung,
          IdPersonList: keys
        });

        await this.webEventService.execute(args, true);

        this.getNewsletterEmpfPopup().instance.hide();

        DevExpress.ui.notify(
          this.localizationService.translateOnce("person.newsletter_empf_erstellt"),
          "success",
          3000);
      } finally {
        this.loadingService.endLoading();
      }
    }
  };

  bind(form: FormBase, namespace: string) {
    this.form = form;
  }

  private getNewsletterEmpfPopup(): IdxPopupComponent {
    return this.form["r_newsletterEmpf"];
  }
  private getNewsletterEmpfBezeichnungTextBox(): IdxTextBoxComponent {
    return this.form["r_newsletterEmpfBezeichnung"];
  }
  private getNewsletterEmpfValidationGroup(): IdxValidationGroup {
    return this.form["r_newsletterEmpfValidationGroup"];
  }
  private getPersonGrid(): IdxDataGridComponent {
    return this.form["r_personGrid"];
  }
}
