import * as fwx from "../../../../framework/forms/form-export";

@fwx.autoinject
export class VolltextThesaurusEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport) {
        super(element, formBaseImport);
        this.id = "volltext-thesaurus-edit";
        this.title = "volltext-thesaurus-edit.volltext-thesaurus-edit_caption";
        this.addModel({
            "id": "$m_VolltextThesaurus",
            "webApiAction": "ERP/Volltext/Thesaurus",
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "volltext-thesaurus-edit.r_textquelle_caption",
            "binding": {
                "dataContext": "$m_VolltextThesaurus",
                "bindTo": "TextQuelle",
                "bindToFQ": "models.data.$m_VolltextThesaurus.TextQuelle"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_textQuelle",
            "options": {
                "optionsName": "r_textQuelleOptions",
                "optionsNameFQ": "r_textQuelleOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "volltext-thesaurus-edit.r_textziel_caption",
            "binding": {
                "dataContext": "$m_VolltextThesaurus",
                "bindTo": "TextZiel",
                "bindToFQ": "models.data.$m_VolltextThesaurus.TextZiel"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_textZiel",
            "options": {
                "optionsName": "r_textZielOptions",
                "optionsNameFQ": "r_textZielOptions"
            }
        });
        super.onConstructionFinished();
    }
}