import { autoinject } from "aurelia-framework";
import { RestService } from "../../framework/base/export";

@autoinject
export class FirmaService {
  constructor(
    private _restService: RestService
  ) { }

  async getIdFirmaVorbelegung() {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/Firma"),
      getOptions: {
        columns: ["Id"],
        where: ["IsVorbelegung", true]
      }
    });

    if (r && r.length > 0) {
      return r[0].Id;
    } else {
      return null;
    }
  }

  async getFirmaBezeichnungById(idFirma: number){
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Firma/${idFirma}`)
    });

    if (r) {
      return r.Bezeichnung;
    } else {
      return null;
    }
  }

  async getFirmaSteuerInformationListByIdFirma(idFirma: number) {
    return await this._restService.get({
      url: this._restService.getWebApiUrl("ERP/Stammdaten/FirmaSteuerinformation"),
      getOptions: {
        where: ["IdFirma", idFirma]
      }
    });
  }

  async getWaehrungIdOfFirma(idFirma: number): Promise<number> {
    const r = await this._restService.get({
      url: this._restService.getWebApiUrl(`ERP/Stammdaten/Firma/${idFirma}`)
    });

    if (r) {
      return r.IdWaehrung;
    } else {
      return null;
    }
  }
}
