export enum NummernkreisTyp {
    Geschaeftspartner = 0,
    Artikel = 1,
    Angebot = 2,
    Auftrag = 3,
    Lieferschein = 4,
    Faktura = 5,
    Bestellung = 6,
    Warenuebernahme = 7,
    Eingangsrechnung = 8,
    Projekt = 9,
    ProdAuftrag = 10,
    Kundenreklamation = 11,
    Ladeliste = 12
}
